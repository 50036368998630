export const mediaTypes: string[] = ['Manual', 'Accessories', 'TechnicalFile', 'SpareParts', 'Certification']

export interface FileAttachment {
  documentType: string
  label: string
  languages: string[]
  lastEdit: string
  link: string
  name: string
  translations: { [k: string]: string }
  version: string
}

export interface MediaVideo {
  label: string
  link: string
  mediaType: 'YouTubeLink' | ''
  translations: { [k: string]: string }
}
