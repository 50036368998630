import * as React from 'react'
import { MaintenanceColors, MaintenanceLegendItem } from '../../../../types'
import { withTranslation, WithTranslation } from 'react-i18next'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface Props {
  hidden?: MaintenanceLegendItem[]
}

interface DefaultProps {
  hidden: MaintenanceLegendItem[]
}

type PropsWithDefaults = Props & DefaultProps & WithTranslation

class Legend extends React.Component<Props & WithTranslation> {
  public static defaultProps: Props = {
    hidden: [],
  }

  public render() {
    const { hidden, t } = this.props as PropsWithDefaults

    return (
      <div className="legend mb-4">
        <div className="legend__item">{t('maintenance.calendar.legend.legend')}</div>
        {!hidden.includes('done') && (
          <div className="legend__item">
            <span className="legend__icon" style={{ color: MaintenanceColors.DONE }}>
              <IconComponent icon={'square'} />
            </span>
            {t('maintenance.calendar.legend.done')}
          </div>
        )}
        {!hidden.includes('rejected') && (
          <div className="legend__item">
            <span className="legend__icon" style={{ color: MaintenanceColors.REJECTED }}>
              <IconComponent icon={'square'} />
            </span>
            {t('maintenance.calendar.legend.rejected')}
          </div>
        )}
        {!hidden.includes('overdue') && (
          <div className="legend__item">
            <span className="legend__icon" style={{ color: MaintenanceColors.OVERDUE }}>
              <IconComponent icon={'square'} />
            </span>
            {t('maintenance.calendar.legend.overdue')}
          </div>
        )}
        {!hidden.includes('todo') && (
          <div className="legend__item">
            <span className="legend__icon" style={{ color: MaintenanceColors.TODO }}>
              <IconComponent icon={'square'} />
            </span>
            {t('maintenance.calendar.legend.todo')}
          </div>
        )}
        {!hidden.includes('unhandled') && (
          <div className="legend__item">
            <span className="legend__icon" style={{ color: MaintenanceColors.UNHANDLED }}>
              <IconComponent icon={'square'} />
            </span>
            {t('maintenance.calendar.legend.unhandled')}
          </div>
        )}
        {!hidden.includes('periodic') && (
          <div className="legend__item">
            <span className="legend__icon">
              <IconComponent icon={'sync-alt'} />
              {/* <IconComponent icon={'sync-alt'} rotation={90} /> */}
            </span>
            {t('maintenance.calendar.legend.periodic')}
          </div>
        )}
        {!hidden.includes('anticipated') && (
          <div className="legend__item">
            <span className="legend__icon">
              <IconComponent icon={'backward'} />
            </span>
            {t('maintenance.calendar.legend.anticipated')}
          </div>
        )}
        {!hidden.includes('postponed') && (
          <div className="legend__item">
            <span className="legend__icon">
              <IconComponent icon={'forward'} />
            </span>
            {t('maintenance.calendar.legend.postponed')}
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation()(Legend)
