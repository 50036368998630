import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import {
  MaintenanceJob,
  MaintenanceJobParams,
  MaintenanceJobPostParams,
  MaintenanceJobPatchParams,
  MaintenanceJobDeleteParams,
} from '../../types'
import * as queryString from 'query-string'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { MaintenanceClientFiltersParams } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'

export const fetchJobs = (
  pageSlug?: string | null,
  clientFilters?: MaintenanceClientFiltersParams | null,
): Function => {
  return async (dispatch: Dispatch<AnyAction>, getState: Function) => {
    let qs = ''
    const queryStringParams: any = {}

    if (pageSlug) {
      queryStringParams.pageSlug = pageSlug
    }

    if (getState().config.maintenance.additionalLevels && getState().config.maintenance.additionalLevels.length > 0) {
      const levels = getState().config.maintenance.additionalLevels.map((item: any) => item.level)
      queryStringParams.additionalLevels = levels
    }

    qs = queryString.stringify(queryStringParams, { arrayFormat: 'index' })

    if (clientFilters) {
      qs += qs.length > 0 ? '&' : ''
      qs += 'clientFilters=' + JSON.stringify(clientFilters)
    }

    if (qs.length > 0) {
      qs = '?' + qs
    }


    try {
      const response = await API().request(`/maintenances${qs}`)
      return  response.map((params: MaintenanceJobParams) => new MaintenanceJob(params))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const addJob = (params: MaintenanceJobPostParams): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await API().request(`/maintenances`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          targetId: params.targetId,
          operation: params.operation,
          typeId: params.typeId,
          periodicity: params.periodicity,
          hours: params.hours,
          notes: params.notes,
          lastDoneDate: params.lastDoneDate,
          isSpecialOperation: params.isSpecialOperation
        }),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const patchJob = (params: MaintenanceJobPatchParams): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await API().request(`/maintenances/${params.id}`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify({
          periodicity: params.periodicity,
          hours: params.hours,
          notes: params.notes,
          isSpecialOperation: params.isSpecialOperation
        }),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const deleteJob = (params: MaintenanceJobDeleteParams): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await API().request(`/maintenances/${params.id}`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'DELETE',
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}
