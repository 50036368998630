import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface OwnStateProps {
  selected: boolean
  clickCallback: Function
  name: string
  unit: string
  value: number
  // t: TranslationFunction
}

export interface ButtonProps {
  name: string
  unit: string
  value: number
}

class Button extends React.Component<OwnStateProps & WithTranslation> {
  public render() {
    const name = this.props.t('i4environment.metrics.' + this.props.name, { defaultValue: this.props.name })
    return (
      <button
        type="button"
        className={'btn btn-' + (this.props.selected ? 'warning' : 'secondary')}
        onClick={() => this.props.clickCallback(this.props.name)}
      >
        <span className="value">
          {this.props.value ? this.props.value.toFixed(2) : 0} <small>{this.props.unit}</small>
        </span>
        <span className="label">{name.length > 16 ? name.substr(0, 16) + '.' : name}</span>
      </button>
    )
  }
}

export default withTranslation()(Button)
