import * as React from 'react'
import ButtonsGroup from '../ButtonGroup/ButtonsGroup'
import { withTranslation, WithTranslation } from 'react-i18next'
import { isJSON } from '@mv-submodules/inplant-core-fe/functions'
import { Button, Loader } from '@mv-submodules/inplant-components-fe'
import { connect } from 'react-redux'

interface OwnStateProps {
  clickCallback: Function
  buttons: any
  selecteds: string[]
  carbonFootprint?: number
  plantStructure: any
  activeSection: undefined | number
  changeSectionFn: Function
  openModalFn: Function
  isLoading: boolean
  // t: TranslationFunction
}

interface ConsumptionWidgetStateProps {
  config?: {
    disabledActions?: string
  } | null
}

type Props = OwnStateProps & ConsumptionWidgetStateProps & WithTranslation

const mapStateToProps = (state: any): ConsumptionWidgetStateProps => {
  return {
    config: state.config.costs || null,
  }
}

class ConsumptionWidget extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    const disabledActions =
      this.props.config && this.props.config.disabledActions && isJSON(this.props.config.disabledActions)
        ? JSON.parse(this.props.config.disabledActions)
        : []

    return (
      <div className="WidgetButtons cliccable">
        <div className="card mb-3">
          <div className="card-header">{this.props.t('i4environment.averageDailyConsumption')}</div>
          <div className="card-body">
            <div className="container chart-container">
              <div className="text-center">
                {this.props.plantStructure.map((section: any) => (
                  <div
                    className={'buttons-section' + (this.props.activeSection === section.id ? ' active' : '')}
                    key={section.id}
                  >
                    <a className="button-header" onClick={e => this.changeSection(e, section.id)}>
                      {this.props.t('i4environment.sections.' + section.label)}
                    </a>
                    <div className="buttons-section-content">
                      {this.props.activeSection === section.id && !this.props.isLoading && this.props.buttons && (
                        <ButtonsGroup
                          buttons={this.props.buttons}
                          selecteds={this.props.selecteds}
                          clickCallback={this.props.clickCallback}
                        />
                      )}
                      {this.props.activeSection === section.id && this.props.isLoading && <Loader />}
                    </div>
                  </div>
                ))}

                {!disabledActions.includes('manual-data-loading') && this.props.activeSection === 1 && (
                  <Button
                    variant="primary"
                    onClick={() => this.props.openModalFn()}
                    label={this.props.t('i4environment.consumptionWidget.button.label')}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="card-footer text-muted text-right small">&nbsp;</div>
        </div>
      </div>
    )
  }

  private changeSection(e: React.MouseEvent<HTMLAnchorElement>, id: number) {
    this.props.changeSectionFn(e, id)
  }
}

export default connect(mapStateToProps, null)(withTranslation()(ConsumptionWidget))
