import {
  // AnyAction,
  combineReducers,
  ReducersMapObject,
} from 'redux'
import { smartdocs } from './smartdocs'
import { storage } from './storage'
import { spareParts } from './spareParts'

const smartdocsReducersMap: ReducersMapObject = {
  smartdocs,
  storage,
  spareParts,
}

export default combineReducers(smartdocsReducersMap)
