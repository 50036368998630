import * as React from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {fetchQualityData} from '../../../../redux/actions/graphs'
import * as moment from 'moment'
import {Bar, ComposedChart, Label, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import DownloadDataButton
  from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import {timeRangeFileString, TPRNCAlarmsData} from '../../../../functions/shared'
import TPRNCWarnArea
  from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/ChartUtils/TPRNCWarnAreaLoader'
import {AlertNoData} from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/SharedComponents/sharedComponents';
import {Card, CardBody, CardFooter, CardHeader} from '@mv-submodules/inplant-components-fe';
import {saveDataAsCSV} from "@mv-submodules/inplant-core-fe/functions";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface GraphQualityStateProps {
  range: null | string
  data?: any
  lineData?: any
  lineDataPieces?: any
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastDateUpdate: number | null
  dateStart: string | null
  dateEnd: string | null,
  measureUnits: object
  cliccable?: boolean
  TPRNCData: any
  TPRNCDataErrors: boolean
  TPRNCDataIsLoading: boolean
}

interface GraphQualityDispatchProps {
  fetchData: () => Function
}

interface GraphQualityOwnProps {
  // t: TranslationFunction
  history: any
  match: any
}

interface GraphQualityOwnState {
  lastUpdate: number | null
  legend: any | null
}

const mapStateToProps = (state: any): GraphQualityStateProps => ({
  range: state.dateFilters.range,
  data: state.graphQualityData.data,
  lineData: state.graphQualityData.lineData,
  lineDataPieces: state.graphQualityData.lineDataPieces,
  hasErrored: state.graphQualityData.hasErrored,
  isLoading: state.graphQualityData.isLoading,
  columns: state.graphQualityData.columns,
  lastDateUpdate: state.dateFilters.lastUpdate,
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
  measureUnits: state.config.generic.measureUnits || {},
  TPRNCData: state.graphTPRNCData.data,
  TPRNCDataErrors: state.graphTPRNCData.hasErrored,
  TPRNCDataIsLoading: state.graphTPRNCData.isLoading,
})

const mapDispatchToProps = (dispatch: Function): GraphQualityDispatchProps => ({
  fetchData: () => dispatch(fetchQualityData()),
})

const COLORS = {
  Scrap1: '#923534',
  Scrap2: '#66c2a5',
  Scrap3: '#4b70cb',
  Scrap4: '#fc8d62',
  Scrap5: '#8da0cb',
  Scrap6: '#e78ac3',
  Scrap7: '#a6d854',
  Scrap8: '#b4561e',
  Scrap9: '#0bb1c5',
  Scrap10: '#921f2f',
}

type GraphQualityProps = GraphQualityStateProps & GraphQualityDispatchProps & GraphQualityOwnProps & WithTranslation

class GraphQuality extends React.Component<GraphQualityProps, GraphQualityOwnState> {
  constructor(props: GraphQualityProps) {
    super(props)
    this.state = {
      lastUpdate: null,
      legend: null,
    }
    this.handleClick = this.handleClick.bind(this)
    this.CustomTooltip = this.CustomTooltip.bind(this)
  }

  public componentWillMount() {
    this.props.fetchData()
  }

  public componentWillReceiveProps(newProps: GraphQualityStateProps) {
    if (newProps.lastDateUpdate !== null && this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
      this.props.fetchData()
    }
  }

  public shouldComponentUpdate(nextProps: GraphQualityProps) {
    return this.props.data !== nextProps.data || this.props.match !== nextProps.match
  }

  public handleClick() {
    // this.props.history.push('/i4efficiency/quality-details')
  }

  public render() {
    const {dateStart, dateEnd, TPRNCData} = this.props
    const legendSrc: Array<{ name: string; color: string; label: string }> = []
    let legend

    const timeFormat =
      this.props.range &&
      ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(this.props.range)
        ? 'MM/YYYY'
        : 'DD/MM'

    const data =
      this.props.data &&
      this.props.data.length > 0 &&
      // this.props.lineData &&
      //  this.props.lineDataPieces &&
      this.props.data[0].values.map((singleDay: any, i: number) => {
        const out: { time: string; quality: number | null; pl?: number | null } = {
          time: singleDay[0],
          quality: this.props.lineData && this.props.lineData[i] ? this.props.lineData[i][1] * 100 : null,
        }

        this.props.data[0].columns.forEach((c: string, ci: number) => {
          if (c !== 'time') {
            const colName = c.split('_')[0]
            out[colName] = singleDay[ci]
          }
        })

        const pl = this.props.lineDataPieces ? this.props.lineDataPieces.find((pi: any) => pi[0] === out.time) : null
        out.pl = pl ? pl[1] : null

        return out
      })

    const dataLabels =
      data && data.length > 0 ? Object.keys(data[0]).filter(e => e !== 'time' && e !== 'quality' && e !== 'pl') : []

    dataLabels.map((label: string) => {
      legendSrc.push({
        color: label.toLowerCase(),
        label,
        name: this.props.t('i4efficiency.quality.scraps.' + label, {defaultValue: label}),
      })
    })

    if (legendSrc && legendSrc.length > 0) {
      legend = legendSrc.map((i: { name: string; color: string; label: string }) => {
        return (
          <li className="filter-trigger" key={i.name} data-filter={i.color}>
            <span className={'legend-color'} style={{backgroundColor: COLORS[i.label]}}/> {i.name}
          </li>
        )
      })
    }
    let downloadData: string[][] =
      this.props.data && /*this.props.lineData &&*/ this.props.data.length > 0
        ? this.props.data[0].values.map((e: any, i: number) => {
          const pl = (this.props.lineDataPieces && this.props.lineDataPieces.find((pi: any) => pi[0] === e[0])) || []
          const out = [
            moment(e[0]).format(
              this.props.range &&
              ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(
                this.props.range,
              )
                ? 'MM/YY'
                : 'DD/MM',
            ),
            this.props.lineData && this.props.lineData[i] ? this.props.lineData[i][1] : null,
            pl ? pl[1] : null,
          ]

          this.props.data.map((s: { columns: string[]; name: string; values: any[] }) => {
            out.push(s.values[i][1])
          })

          return out
        })
        : []

    downloadData = [
      ['time', 'quality', this.props.t('i4efficiency.quality.scraps.pl')]
        .concat(dataLabels.map(l => this.props.t('i4efficiency.quality.scraps.' + l)))
        .map((c: string) => `${c}${this.props.measureUnits[c] ? ` (${this.props.measureUnits[c]})` : ''}`),
    ].concat(downloadData)

    const tprncData = TPRNCAlarmsData(dateStart, dateEnd, data, TPRNCData)

    return (
      <div className={`DashboardWidget WidgetQuality WidgetH50 ${this.props.cliccable ? 'cliccable' : ''}`}>
        <Card marginBottom={3}>
          <CardHeader>
            {this.props.t('i4efficiency.cardTitle.quality')}
            {data && downloadData && (
              <DownloadDataButton
                saveDataAsCSV={saveDataAsCSV}
                data={downloadData}
                type={'csv'}
                label={'i4efficiency.download.buttonLabel'}
                className={'float-right'}
                fileName={'quality' + timeRangeFileString(this.props.dateStart, this.props.dateEnd)}
                addTime={false}
              />
            )}
          </CardHeader>

          <CardBody>
            <div className="container-fluid chart-container">
              <div className="full-h">
                <Row verticalAlignment={"center"}>
                  <Column sm={12}>
                    <div className="text-center full-h">
                      <div className="chart-container chartsPlanRunHome">
                        <TPRNCWarnArea/>
                        {data ? (
                          <ResponsiveContainer>
                            <ComposedChart
                              width={700}
                              height={450}
                              data={data}
                              margin={{
                                top: 45,
                                right: 70,
                                left: 0,
                                bottom: 0,
                              }}
                              className={this.props.cliccable ? 'cliccable' : ''}
                            >
                              <XAxis
                                dataKey="time"
                                tickFormatter={e => {
                                  return moment(e).format(timeFormat)
                                }}
                                axisLine={false}
                              />
                              <YAxis axisLine={false} yAxisId={'left'}>
                                <Label position={'top'} offset={30}>
                                  Qta
                                </Label>
                              </YAxis>
                              <YAxis
                                axisLine={false}
                                yAxisId={'right'}
                                orientation={'right'}
                                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                width={20}
                                interval={0}
                              >
                                <Label position={'top'} offset={30}>
                                  %
                                </Label>
                              </YAxis>
                              <Tooltip content={<this.CustomTooltip/>} cursor={{fill: 'none'}}/>
                              {
                                tprncData && tprncData.map(d => <ReferenceArea
                                  key={d.key}
                                  x1={d.x1}
                                  x2={d.x2}
                                  yAxisId={'right'}
                                  stroke={d.stroke}
                                  strokeOpacity={d.strokeOpacity}
                                  fill={d.fill}
                                  fillOpacity={d.fillOpacity}
                                />)
                              }
                              {legendSrc &&
                              legendSrc.map(l => (
                                <Bar
                                  key={l.label}
                                  dataKey={l.label}
                                  fill={COLORS[l.label]}
                                  stackId={'stack'}
                                  onMouseOver={(a: any, b: any, c: any) => {
                                    if (c && c.native) {
                                      c.native.target.opacity = 0.3
                                    }
                                  }}
                                  yAxisId={'left'}
                                />
                              ))}
                              <Line
                                dataKey={'quality'}
                                stroke="#d0743c"
                                yAxisId={'right'}
                                strokeWidth={2}
                                isAnimationActive={false}
                                dot={false}
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        ) : (
                          <AlertNoData message={this.props.t('i4efficiency.fetch.noData')}/>
                        )}
                      </div>
                    </div>
                  </Column>
                </Row>
              </div>
            </div>
          </CardBody>

          <CardFooter
            textMuted={true}
            textAlign={'right'}
            textSizeSmall={true}
          >
            <ul className="legend float-left">{legend}</ul>
            <ul className="legend float-right">
              <li>
                <span className="legend-color legend-quality-color"/> QUALITY
              </li>
            </ul>
          </CardFooter>
        </Card>
      </div>
    )
  }

  private CustomTooltip({active, payload, label}: any) {
    if (active) {
      const payloadData = payload[0].payload

      let totalScraps = 0
      Object.keys(payloadData)
        .filter(e => e !== 'time')
        .map((line: any, index: number) => {
          if (line !== 'time' && line !== 'pl' && line !== 'quality' && payloadData[line] !== null) {
            totalScraps += payloadData[line]
          }
        })
      return (
        <div className="recharts-default-tooltip">
          <ul>
            <li className="title">
              <strong>
                {moment(label).format(
                  this.props.range &&
                  ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(
                    this.props.range,
                  )
                    ? 'MM/YY'
                    : 'DD/MM',
                )}
              </strong>
            </li>
            {Object.keys(payloadData)
              .filter(e => e !== 'time')
              .map((line: any, index: number) => (
                <li key={index}>
                  <strong>
                    {this.props.t('i4efficiency.quality.scraps.' + line, {defaultValue: line})}
                    {line === 'quality' ? ' (%)' : ''}
                  </strong>
                  <span className="value float-right">
                    {line !== 'time' && line !== 'pl' && payloadData[line] !== null
                      ? payloadData[line].toFixed(2)
                      : payloadData[line]}
                  </span>
                </li>
              ))}
            <li>
              <strong>{this.props.t('i4efficiency.quality.total')}</strong>
              <span className={'value float-right'}>{totalScraps.toFixed(2)}</span>
            </li>
          </ul>
        </div>
      )
    }

    return null
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(GraphQuality)),
)
