import { AnyAction } from 'redux'

interface DeadlineInterface {
  ids: string | []
  isFetching: boolean
  response?: Response
  error?: Error
}

const initialState: DeadlineInterface = {
  isFetching: false,
  ids: '',
}

export function deadlineActions(state: DeadlineInterface = initialState, action: AnyAction): DeadlineInterface {
  switch (action.type) {
    case 'SENDING_UNDO':
      return {
        ...state,
        isFetching: true,
        ids: action.payload.ids,
      }
    case 'SUCCESSFUL_UNDO':
      return {
        ...state,
        isFetching: false,
        response: action.payload.res,
      }
    case 'UNDO_ERROR':
      return {
        ...state,
        isFetching: false,
        error: action.payload.err,
      }
    case 'SENDING_VALIDATE_TASK':
      return {
        ...state,
        isFetching: true,
        ids: action.payload.ids,
      }
    case 'SUCCESFUL_VALIDATE_TASK':
      return {
        ...state,
        isFetching: false,
        response: action.payload.res,
      }
    case 'VALIDATE_TASK_ERROR':
      return {
        ...state,
        isFetching: false,
        error: action.payload.err,
      }
    case 'SENDING_FAILED_TO_VALIDATE_TASK':
      return {
        ...state,
        isFetching: true,
        ids: action.payload.ids,
      }
    case 'SUCCESFUL_VALIDATE_TASK':
      return {
        ...state,
        isFetching: false,
        response: action.payload.res,
      }
    case 'VALIDATE_TASK_ERROR':
      return {
        ...state,
        isFetching: false,
        error: action.payload.err,
      }
    default:
      return state
  }
}
