import * as moment from 'moment'
export const mvDate = moment
export type MVDate = moment.Moment

// * extend moment class and expose all with date variable
declare module 'moment' {
  interface Moment {   //tslint:disable-line
    /* myFunc(): moment.Moment */
  }
}

/* (moment as any).fn.myFunc = (): moment.Moment => {
  consoleLog('Called myFunc!')
  return moment()
}
 */

// * can be called with date().muFunc() 