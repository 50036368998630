import * as React from 'react'
import {withTranslation, WithTranslation} from 'react-i18next'
import {Alert, Loader} from '@mv-submodules/inplant-components-fe'
import {ManualDataLoadingLoadMaterial} from '../../../../redux/types'
import moment from 'moment'
import {
  fetchManualDataLoadings,
  loadManualDataLoading,
} from '../../../../redux/actions/manualDataLoading'
import {connect} from 'react-redux'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import {FormModal} from "@mv-submodules/inplant-components-fe/ui/components/Modal/Modal";


interface Mdl {
  id: string,
  name: string,
  um: string
}

interface OwnState {
  data: ManualDataLoadingLoadMaterial
  manualDataLoading: Mdl[]
  isFetching: boolean
  fetchErrors?: number
  isSubmitting: boolean
}

interface StateProps {
  visible: boolean
  closeModal: (update?: boolean) => void
}

interface ReduxProps {
  dateStart: string | null
  dateEnd: string | null,
}

const mapStateToProps = (state: any): ReduxProps => ({
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
})


interface LoadMaterialDispatchProps {
  fetchManualDataLoadings: (year: number) => Promise<any>,
  loadManualDataLoading: (data: ManualDataLoadingLoadMaterial) => Promise<any>
}

const mapDispatchToProps = (dispatch: Function): LoadMaterialDispatchProps => ({
  fetchManualDataLoadings: (year: number) => dispatch(fetchManualDataLoadings(year)),
  // fetchSmartdocData: () => dispatch(fetchI4SmartdocData()),
  loadManualDataLoading: (data: ManualDataLoadingLoadMaterial) => dispatch(loadManualDataLoading(data)),
})

type Props = StateProps & WithTranslation & ReduxProps & LoadMaterialDispatchProps

class LoadManualDataLoadingMaterialModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: {
        date: moment().format('YYYY-MM-DD'),
        measureId: '',
        quantity: undefined,
      },
      isFetching: false,
      manualDataLoading: [],
      isSubmitting: false,
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.handleChangeInput = this.handleChangeInput.bind(this)
  }

  public componentDidMount() {
    this.fetchData()
  }

  public render() {
    const {visible, t} = this.props
    const {data, manualDataLoading, isFetching, fetchErrors, isSubmitting} = this.state
    const currentMdl = data.measureId && manualDataLoading.find(value => value.id === data.measureId)
    const currentUm = currentMdl && currentMdl.um || '-'
    return (
      <FormModal visible={visible} title={t('i4environment.modal.titleLoadMaterial')} closeLabel={t('i4environment.actions.cancel')}
                 onClose={this.closeModal} onSubmit={this.onSubmit} submitButton={{
        disabled: (isSubmitting || isFetching || fetchErrors !== undefined),
        label: t('i4environment.actions.save'),
        isLoading: isSubmitting
      }}>
        {isFetching && (
          <Loader/>
        )}
        {!isFetching && fetchErrors === undefined && (
          <Row>
            <Column md={4} formGroup={true}>
              <label className="text-left">{t('i4environment.modal.fields.name')} <abbr
                className="text-danger ml-1">*</abbr></label>
              <select
                value={data.measureId}
                name="measureId"
                className="form-control"
                required={true}
                onChange={this.handleChangeInput}
              >
                {manualDataLoading.map(mdl => (
                  <option value={mdl.id} key={`mdl_key_${mdl.id}`}>
                    {mdl.name}
                  </option>
                ))}
              </select>
            </Column>
            <Column md={4} formGroup={true}>
              <label>{t('i4environment.modal.fields.quantityLiteral')} <abbr className="text-danger ml-1">*</abbr></label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  name="quantity"
                  value={this.state.data.quantity}
                  onChange={this.handleChangeInput}
                  required={true}
                  step={0.01}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{currentUm}</span>
                </div>
              </div>
            </Column>
            <Column md={4} formGroup={true}>
              <label>{t('i4environment.modal.fields.data')} <abbr className="text-danger ml-1">*</abbr></label>
              <input
                type="date"
                className="form-control"
                name="date"
                value={this.state.data.date && moment(this.state.data.date).format('YYYY-MM-DD') || ''}
                onChange={this.handleChangeInput}
                required={true}
              />
            </Column>
          </Row>
        )}
        {(fetchErrors === 400 || fetchErrors === 404) ? (
          <Alert type={"warning"}>{this.props.t('i4environment.fetch.noDataMdl')}</Alert>
        ) : fetchErrors !== undefined && (
          <Alert type={"danger"}>{this.props.t('i4environment.fetch.error')}</Alert>
        )}
      </FormModal>
    )
  }

  private onSubmit(event: React.FormEvent) {
    event.preventDefault()
    this.setState({isSubmitting: true})
    this.props.loadManualDataLoading(this.state.data)
      .then(() => {
        this.setState({isSubmitting: false})
      })
      .catch(e => {
        console.log(e) //tslint:disable-line
      })
      .finally(() => {
        this.props.closeModal(true)
      })
  }

  private fetchData() {
    if (!this.state.isFetching && this.props.dateStart) {
      this.setState({isFetching: true, fetchErrors: undefined})
      const year = moment(this.props.dateStart).get('year')
      this.props.fetchManualDataLoadings(year)
        .then((data: any) => {
          const manualDataLoading: Mdl[] = data.reduce((acc: Mdl[], curr: any) => {
            acc.push({
              id: curr.influxMeasureId,
              name: curr.name,
              um: curr.um,
            })
            return acc
          }, [])
          this.setState({
            manualDataLoading,
            data: {
              ...this.state.data,
              measureId: manualDataLoading && manualDataLoading[0].id,
            },
          })
        }).catch((e) => {
        this.setState({
          fetchErrors: e.statusCode
        })
        console.log(e) //tslint:disable-line
      })
        .finally(() => {
          this.setState({isFetching: false})
        })
    }
  }

  private handleChangeInput(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const {name, value} = event.target
    const {data} = this.state
    switch (name) {
      case 'quantity':
        const num = parseFloat(value)
        data[name] = !isNaN(num) && num || undefined
        break
      case 'date':
        data[name] = moment(value).format('YYYY-MM-DD')
        break
      default:
        data[name] = value
    }
    this.setState({data})
  }

  private closeModal() {
    this.props.closeModal()
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(LoadManualDataLoadingMaterialModal))
