// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

interface Props {
  type: 'title',
  label: string | null
}

class Heading extends React.Component<Props>{

  public render(){
    switch (this.props.type) {
      case 'title':
        return (
          <h1 className="title">{this.props.label}</h1>
        )
      default:
        return null
    }
  }
}

export default Heading