import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { MaintenancePageFilters } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'

export const isFetching = (): AnyAction => {
  return {
    type: 'MAINTENANCE_PAGE_FILTERS_IS_FETCHING',
  }
}

const fetchSuccess = (slug: string, filters: MaintenancePageFilters[]): AnyAction => {
  return {
    type: 'MAINTENANCE_PAGE_FILTERS_FETCH_SUCCESS',
    payload: {
      slug,
      filters,
    },
  }
}

const fetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'MAINTENANCE_PAGE_FILTERS_FETCH_ERROR',
  }
}

export const fetchPageFilters = (slug: string): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isFetching())
    try {
      let response = (await API().request(`/maintenances/page-filters/${slug}`)) as MaintenancePageFilters[]
      response = response.map((item: MaintenancePageFilters) => {
        item.selected = item.default
        return item
      })
      dispatch(fetchSuccess(slug, response))
      return response
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(fetchError(new Error(error)))
      throw error
    }
  }
}

export const setPageFilterSelectedValues = (slug: string, name: string, selected: null | string[]): AnyAction => {
  return {
    type: 'MAINTENANCE_PAGE_FILTERS_SET_SELECTED_VALUES',
    payload: { slug, name, selected },
  }
}
