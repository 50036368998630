import { createRef } from 'react'
import { FormData, FieldsetField, FormFieldsMap } from '../../../../types/pageForm'

export interface RelFields {
  [fieldName: string]: FieldsetField[]
}

export const appendRelFields = (acc: RelFields, field: any): RelFields => {
  if (field.hasOwnProperty.relSlug && field.relSlug) {
    return {
      ...acc,
      [field.relSlug]: [...(acc[field.relSlug] || []), field],
    }
  } else if (field.hasOwnProperty('fields') && field.fields && field.fields.length) {
    return {
      ...acc,
      ...field.fields.reduce(appendRelFields, {}),
    }
  }
  return acc
}

export const clearRelFields = (acc: RelFields, field: any): FormData => {
  if (field.hasOwnProperty('slug') && field.slug) {
    return parseFieldValue(field.slug, undefined, acc)
  } else if (field.hasOwnProperty('fields') && field.fields && field.fields.length) {
    return {
      ...acc,
      ...field.fields.reduce(clearRelFields, {}),
    }
  }
  return acc
}

export const parseFieldValue = (slug: string, value: any, data: FormData = {}): FormData => {
  const slugPath = slug.split('.')
  return {
    ...data,
    [slugPath[0]]:
      slugPath.length > 1
        ? {
            ...data[slugPath[0]],
            [slugPath[1]]: value,
          }
        : value,
  }
}

export const getInputValueFromData = (slug: string, data: FormData = {}) => {
  return (
    data &&
    slug
      .split('.')
      .reduce((acc: any, inputName: string) => (acc && acc[inputName] != null ? acc[inputName] : undefined), data)
  )
}

export const stringifyData = (data: FormData, parentKey?: string): FormData =>
  Object.keys(data).reduce((acc, key) => {
    const dataKey = parentKey ? `${parentKey}.${key}` : key
    if (data[key] instanceof Object && !Array.isArray(data[key])) {
      return { ...acc, ...stringifyData(data[key], dataKey) }
    } else {
      acc[dataKey] = data[key]
      return acc
    }
  }, {})

export const getInputFields = (fields: FieldsetField[]): FormFieldsMap => {
  return fields.reduce((acc: FormFieldsMap, field: any) => {
    if (field.type === 'row') {
      return {
        ...acc,
        ...getInputFields(field.fields),
      }
    } else if (field.hasOwnProperty('slug')) {
      acc[field.slug] = field
      return acc
    }
    return acc
  }, {})
}

export const getInputFieldsRefs = (fields: FieldsetField[]): FormFieldsMap<React.RefObject<any>> => {
  return fields.reduce((acc: FormFieldsMap, field: any) => {
    if (field.type === 'row') {
      return {
        ...acc,
        ...getInputFieldsRefs(field.fields),
      }
    } else if (field.type === 'automaticRenewal') {
      acc['repetitionRule.method'] = createRef()
      acc['repetitionRule.period'] = createRef()
      acc['repetitionRule.days'] = createRef()
      acc['repetitionRule.hours'] = createRef()
      return acc
    } else if (field.hasOwnProperty('slug')) {
      acc[field.slug] = createRef()
      return acc
    }
    return acc
  }, {})
}
