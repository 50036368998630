import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import {
  MaintenanceScheduledJob,
  MaintenanceScheduledJobParams,
  MaintenanceScheduledJobPatchParams,
  MaintenanceScheduledJobPostParams,
  MaintenanceScheduledJobBulkActionParams,
  MaintenancePlanPdfParams,
} from '../../types'
import * as queryString from 'query-string'
import { FetchError } from '@mv-submodules/inplant-maintenance-fe/types/error'
import { MaintenanceClientFiltersParams } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'



export const fetchScheduledJobDetailDataNoRedux = (id: string) => {
  return (dispatch: Dispatch<AnyAction>, getState: Function) => {
    let qs = ''
    if (getState().config.maintenance.additionalLevels && getState().config.maintenance.additionalLevels.length > 0) {
      const levels = getState().config.maintenance.additionalLevels.map((item: any) => item.level)
      qs = `?${queryString.stringify({ additionalLevels: levels }, { arrayFormat: 'index' })}`
    }
    return API().request(`/maintenances/scheduled-jobs/${id}${qs}`)
      .then((response: any) => new MaintenanceScheduledJob(response, getState().config))
      .catch((error: FetchError) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw  error
      })
  }
}

export const markScheduledJobAsDone = (
  params: MaintenanceScheduledJobPatchParams,
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await API().request(`/maintenances/scheduled-jobs/${params.id}/done`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify({
          date: params.date!.format('YYYY-MM-DD'),
          notes: params.notes,
          cost: params.cost,
          time: params.time,
        }),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const rescheduleScheduledJob = (
  params: MaintenanceScheduledJobPatchParams,
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const payload = { notes: params.notes } as any
    if (params.date) {
      payload.date = params.date.format('YYYY-MM-DD')
    }

    try {
      await API().request(`/maintenances/scheduled-jobs/${params.id}/rescheduled`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify(payload),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const rejectScheduledJob = (
  params: MaintenanceScheduledJobPatchParams,
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await API().request(`/maintenances/scheduled-jobs/${params.id}/rejected`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify({
          notes: params.notes,
        }),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const addScheduledJob = (
  params: MaintenanceScheduledJobPostParams,
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await API().request(`/maintenances/scheduled-jobs`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          targetId: params.targetId,
          operation: params.operation,
          typeId: params.typeId,
          dueDate: params.dueDate.format('YYYY-MM-DD'),
          notes: params.notes,
        }),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const getImage = (
  filePath: string,
  options?: { preview: boolean },
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const qs = options && options.preview ? '?w=640&h=480' : ''
      return await API().request(`/${filePath}${qs}`)
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const bulkActions = (
  params: MaintenanceScheduledJobBulkActionParams,
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    let payload = {
      ids: params.ids,
      action: params.action,
    }

    if (params.date) {
      payload = Object.assign(payload, { date: params.date.format('YYYY-MM-DD') })
    }

    if (params.notes) {
      payload = Object.assign(payload, { notes: params.notes })
    }

    try {
      await API().request(`/maintenances/scheduled-jobs/actions`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify(payload),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const ackScheduledJobSuccess = (scheduledJob: MaintenanceScheduledJob): AnyAction => {
  return {
    type: 'MAINTENANCE_SCHEDULED_JOB_ACK_SUCCESS',
    payload: {
      scheduledJob,
    },
  }
}

export const ackScheduledJob = (id: string): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await API().request(`/maintenances/scheduled-jobs/${id}/ack`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'PATCH',
      })
      dispatch(ackScheduledJobSuccess(new MaintenanceScheduledJob(response as MaintenanceScheduledJobParams)))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const fetchScheduledJobs  = (
  pageSlug?: string | null,
  clientFilters?: MaintenanceClientFiltersParams | null,
) => (dispatch: Dispatch<AnyAction>, getState: Function) => {
  let qs = ''
  const queryStringParams: any = {}

  if (pageSlug) {
    queryStringParams.pageSlug = pageSlug
  }

  if (getState().config.maintenance.additionalLevels && getState().config.maintenance.additionalLevels.length > 0) {
    const levels = getState().config.maintenance.additionalLevels.map((item: any) => item.level)
    queryStringParams.additionalLevels = levels
  }

  qs = queryString.stringify(queryStringParams, { arrayFormat: 'index' })

  if (clientFilters) {
    qs += qs.length > 0 ? '&' : ''
    qs += 'clientFilters=' + JSON.stringify(clientFilters)
  }

  if (qs.length > 0) {
    qs = '?' + qs
  }
  return API().request(`/maintenances/scheduled-jobs${qs}`)
    .then((response: MaintenanceScheduledJobParams[]) => response.map(
      (params: MaintenanceScheduledJobParams) => new MaintenanceScheduledJob(params, getState().config),
    ))
    .catch((error: FetchError) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const fetchPlanPdf = (
  params: MaintenancePlanPdfParams,
): ((dispatch: Dispatch<AnyAction>) => Promise<Blob | null>) => {
  return async (dispatch) => {
    try {
      const blobData = await API().request(`/maintenances/scheduled-jobs/pdf`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          targetIds: params.targetIds,
          additionalLevels: params.additionalLevels,
          filters: params.filters,
        }),
      })
      return blobData
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

