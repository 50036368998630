import { AnyAction } from 'redux'
import { DueDate } from '../../types/dueDates'

interface PageDetailState {
  isFetching: boolean
  isUpdating: boolean
  data?: DueDate
  formData?: DueDate
  error?: Error
}

const initialState: PageDetailState = {
  isFetching: false,
  isUpdating: false,
}

export function pageDetail(state: PageDetailState = initialState, action: AnyAction): PageDetailState {
  switch (action.type) {
    case 'TASK_DETAIL_IS_FETCHING':
      return {
        ...state,
        isFetching: true,
        data: undefined,
        formData: undefined,
        error: undefined,
      }

    case 'TASK_DETAIL_FETCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        data: action.data,
        formData: action.formData,
      }

    case 'TASK_DETAIL_FETCH_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }

    case 'TASKS_ASSIGNING':
    case 'TASKS_STATUS_ARE_CHANGING':
      if (state.data && action.ids.includes(state.data.id)) {
        return {
          ...state,
          isUpdating: true,
        }
      }
      return state

    case 'TASKS_ASSIGNMENT_SUCCESS':
      if (state.data && action.ids.includes(state.data.id)) {
        return {
          ...state,
          isUpdating: false,
          data: { ...state.data, assigneeUser: action.assigneeUser },
          formData: { ...state.data, assigneeUser: action.assigneeUser.id },
        }
      }
      return state

    case 'TASKS_STATUS_CHANGE_SUCCESS':
      if (state.data && action.ids.includes(state.data.id)) {
        return {
          ...state,
          isUpdating: false,
          // data: { ...state.data, status: action.status },
          // formData: { ...state.data, status: action.status },
        }
      }
      return state

    case 'TASKS_ASSIGNMENT_FAILURE':
    case 'TASKS_STATUS_CHANGE_FAILURE':
      if (state.data && action.ids.includes(state.data.id)) {
        return {
          ...state,
          isUpdating: false,
        }
      }
      return state

    case 'TASK_FIELD_SUBMIT_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        // formData: { ...state.formData, ...action.additionalData },
      }

    default:
      return state
  }
}
