// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { RowInfo } from 'react-table'

// * -------------------------------- MODULE --------------------------------------
import Table, { Props as TableProps } from './Table'
import ActionDropdown from './ActionDropdown'
import { DropdownActions } from '../Button/DropdownButton'

interface Props extends TableProps {
  rowKey?: string
  actions: DropdownCheckedAction[]
  checkAllLabel: string
  uncheckAllLabel: string
  actionsText: string
}

interface OwnState {
  allChecked: boolean
  checkedValues: string[]
  actions: DropdownActions
}

export interface DropdownCheckedAction {
  label: string
  onClick: (checkedValues: string[]) => void
}

export const thActionClassName = 'rt-checkbox-header'

class TableWithActions extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      allChecked: false,
      checkedValues: [],
      actions: this.getActions(props, false, []),
    }
    this.handleChecked = this.handleChecked.bind(this)
    this.handleCheckAll = this.handleCheckAll.bind(this)
    this.checkAll = this.checkAll.bind(this)
    this.uncheckAll = this.uncheckAll.bind(this)
    this.getActions = this.getActions.bind(this)
  }

  public render() {
    const { columns, rowKey = 'id', ...props } = this.props
    const { allChecked, checkedValues, actions } = this.state

    return (
      <>
        <ActionDropdown
          actionsText={props.actionsText}
          ofText={props.ofText || ''}
          rowsText={props.rowsText || ''}
          actions={actions}
          selectedItems={checkedValues.length}
          items={props.data && props.data.length}
        />
        <Table
          {...props}
          columns={[
            {
              Header: <input type="checkbox" checked={allChecked} onChange={this.handleCheckAll} />,
              Cell: ({ original }: RowInfo) => (
                <input
                  type="checkbox"
                  value={original[rowKey]}
                  checked={checkedValues.includes(original[rowKey])}
                  onChange={this.handleChecked}
                />
              ),
              sortable: false,
              resizable: false,
              width: 40,
              headerClassName: thActionClassName,
            },
            ...columns,
          ]}
        />
      </>
    )
  }

  private handleChecked(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, checked } = event.currentTarget
    this.setState(currentState => {
      const checkedValues = currentState.checkedValues.filter(checkedValue => checkedValue !== value)
      if (checked) {
        checkedValues.push(value)
      }
      const allChecked = checkedValues.length === this.props.data!.length

      return {
        allChecked,
        checkedValues,
        actions: this.getActions(this.props, allChecked, checkedValues),
      }
    })
  }

  private handleCheckAll(event: React.ChangeEvent<HTMLInputElement>) {
    event.currentTarget.checked ? this.checkAll() : this.uncheckAll()
  }

  private checkAll() {
    const allChecked = true
    const checkedValues: string[] =
      (this.props.data && this.props.data.map((row: any) => row[this.props.rowKey || 'id'])) || []

    this.setState({
      allChecked,
      checkedValues,
      actions: this.getActions(this.props, allChecked, checkedValues),
    })
  }

  private uncheckAll() {
    const allChecked = false
    const checkedValues: string[] = []

    this.setState({
      allChecked,
      checkedValues,
      actions: this.getActions(this.props, allChecked, checkedValues),
    })
  }

  private getActions(props: Props, allChecked: boolean, checkedValues: string[]) {
    const actions: DropdownActions = checkedValues.length
      ? [
          ...props.actions.map(action =>
            action instanceof Object
              ? {
                  ...action,
                  onClick: () => action.onClick(checkedValues),
                }
              : action
          ),
          '-',
        ]
      : []
    if (!allChecked) {
      actions.push({
        label: props.checkAllLabel,
        onClick: this.checkAll.bind(this),
      })
    }
    if (checkedValues.length) {
      actions.push({
        label: props.uncheckAllLabel,
        onClick: this.uncheckAll.bind(this),
      })
    }
    return actions
  }
}

export default TableWithActions
