// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

export interface ActionLogData {
  title: string
  text?: string
  user: string
  date: string
}

interface Props {
  values: ActionLogData[]
}

class ActionLog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {values} = this.props
    return (
      <div>
        {values.map((option,index) => (
          <React.Fragment key={index}>
            <label>{option.title.toUpperCase()} {option.text && (<span>{option.text}</span>)}</label>
            <p>{option.user}  -  {option.date}</p>
            <hr/>
          </React.Fragment>
        ))}
      </div>
    )
  }
}

export default ActionLog