const en = {
  plantStatusOverTime: {
    title: 'PLANT STATUS OVER TIME',
    fetch: {
      noData: 'No data available',
    },
    timeRange: 'Time range must be less or equal to a week',
    labels: {
      TPRON: 'Production On',
      TPP: 'Pause production',
      TPE: 'End Production',
      TPLON: 'Start',
      TPLRDY: 'Ready',
      TPLWU: 'Warm Up',
      TPS: 'Stop production',
      TPROFF: 'Off production',
      TPLUP: 'Up time',
      TPLMNT: 'Maintenance',
      TSON: 'Switch On',
      TPRNC: 'T. Prod. Non Conforme',
    },
  },
}

export default en
