import * as React from 'react'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {MaintenanceScheduledJob, MaintenanceBulkAction, ModuleConfig} from '../../../../types'
import ActionModal from './ActionModal'
import {getConfigParam} from '../../../../functions/shared'
import ActionDropdown from '@mv-submodules/inplant-components-fe/ui/components/Table/ActionDropdown'

export interface StateProps {
    config: ModuleConfig
    label: 'left' | 'right'
}

interface OwnStateProps {
    showActionModal: boolean
    bulkActionType: MaintenanceBulkAction
    actions: string[]
}

interface OwnProps {
    // t: TranslationFunction
    scheduledJobs: MaintenanceScheduledJob[]
    selection: MaintenanceScheduledJob[]
    onClose: Function | null
}

export type Props = StateProps & OwnProps & WithTranslation

export const mapStateToProps = (state: any) => ({
    config: state.config,
})

class ActionButtons extends React.Component<Props, OwnStateProps> {
    constructor(props: Props) {
        super(props)
        this.state = this.getInitialState(props)
        this.selectionIsEmpty = this.selectionIsEmpty.bind(this)
        this.handleOpenActionModal = this.handleOpenActionModal.bind(this)
        this.handleCloseActionModal = this.handleCloseActionModal.bind(this)
    }

    private getInitialState(props: Props) {
        const initialState = {
            showActionModal: false,
            bulkActionType: null,
            actions: ['done', 'rescheduled'],
        }
        const enableScheduledJobRejectAction: boolean = getConfigParam(
            props.config,
            'enableScheduledJobRejectAction',
            false
        )
        if (enableScheduledJobRejectAction) {
            initialState.actions.push('rejected')
        }

        const enableAcknowledgment: boolean = getConfigParam(props.config, 'enableAcknowledgment', false)
        if (enableAcknowledgment) {
            initialState.actions.push('acknowledged')
        }

        return initialState
    }

    private selectionIsEmpty() {
        return this.props.selection.length === 0
    }

    private handleOpenActionModal(action: MaintenanceBulkAction) {
        if (!this.selectionIsEmpty()) {
            this.setState({
                showActionModal: true,
                bulkActionType: action,
            })
        }
    }

    private handleCloseActionModal(reload?: boolean) {
        this.setState({
            showActionModal: false,
            bulkActionType: null,
        })
        if (this.props.onClose) {
            this.props.onClose(reload)
        }
    }

    public render() {
        return (
            <React.Fragment>
                <ActionDropdown
                    actionsText={this.props.t(`maintenance.bulkActions.button`)}
                    ofText={"di"}
                    rowsText={"righe"}
                    tooltipContent={this.selectionIsEmpty() && "Seleziona almeno un elemento nella lista" || undefined}
                    tooltipPosition="bottom"
                    selectedItems={this.props.selection.length}
                    items={this.props.scheduledJobs.length}
                    variant={"primary"}
                    labelPosition={this.props.label}
                    actions={this.state.actions.map((action: string) => ({
                        onClick: () => this.handleOpenActionModal(action as MaintenanceBulkAction),
                        label: this.props.t(`maintenance.bulkActions.actions.${action}`)
                    }))}
                    disabled={this.selectionIsEmpty()}

                />
                {this.state.showActionModal && <ActionModal
                  isVisible={this.state.showActionModal}
                  onClose={this.handleCloseActionModal}
                  scheduledJobs={this.props.selection}
                  action={this.state.bulkActionType}
                />}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withTranslation()(ActionButtons))
