import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
/*tslint:disable*/
// const DateRangePickerImport = require('react-bootstrap-daterangepicker')
// const DateRangePicker = DateRangePickerImport.default
/*tslint:enable*/
import * as moment from 'moment'
import { MaintenanceScheduledJob, MaintenanceScheduledJobPatchParams, ModuleConfig } from '../../../../types'
import { getConfigParam, getDateFormat } from '../../../../functions/shared'
import { rescheduleScheduledJob } from '../../../../redux/actions'
import { DateInput, Modal, TextArea } from '@mv-submodules/inplant-components-fe'

export interface StateProps {
  config: ModuleConfig
}

interface OwnStateProps {
  rescheduleDate: moment.Moment
  rescheduleNotes: string
  isSubmitting: boolean
}

interface OwnProps {
  isVisible: boolean
  scheduledJob: MaintenanceScheduledJob
  onClose?: Function | null
  // t: TranslationFunction
}

export const mapDispatchToProps = (dispatch: Function) => ({
  rescheduleScheduledJob: (params: MaintenanceScheduledJobPatchParams) => dispatch(rescheduleScheduledJob(params)),
})

export const mapStateToProps = (state: any) => ({
  config: state.config,
})

export interface DispatchProps {
  rescheduleScheduledJob: (params: MaintenanceScheduledJobPatchParams) => Promise<any>
}

// export type Props = StateProps & OwnProps
export type Props = DispatchProps & OwnProps & WithTranslation & StateProps

class RescheduleModal extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = this.getInitialState()
    this.onSubmit = this.onSubmit.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
  }

  private getInitialState() {
    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const initialState = {
      rescheduleDate: today.clone(),
      rescheduleNotes: '',
      isSubmitting: false,
    }

    return initialState
  }

  private resetState() {
    this.setState(this.getInitialState())
  }

  private onSubmit(e: any) {
    e.preventDefault()
    this.setState({ isSubmitting: true })
    if (!this.props.scheduledJob) {
      return
    }

    const id = this.props.scheduledJob.id
    const params: MaintenanceScheduledJobPatchParams = {
      id,
      date: this.state.rescheduleDate,
      notes: this.state.rescheduleNotes,
    }

    this.props
      .rescheduleScheduledJob(params)
      .then(() => {
        this.onModalClose(true)
      })
      .catch(error => {
        console.error(error) // tslint:disable-line
      })
      .finally(() => {
        this.setState({ isSubmitting: false })
      })
  }

  public onModalClose(reload: boolean = false) {
    this.resetState()
    if (this.props.onClose) {
      this.props.onClose(reload)
    }
  }

  public render() {
    const { t, config } = this.props
    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const notesRequired = getConfigParam(config, 'requiredNote', false)

    return (
      <form onSubmit={this.onSubmit}>
        <Modal
          visible={this.props.isVisible}
          title={t('maintenance.scheduledJobDetail.modals.reschedule.title')}
          onClose={() => this.onModalClose()}
          additionalFooterButtons={[
            {
              variant: 'primary',
              type: 'submit',
              label: t('maintenance.scheduledJobDetail.modals.reschedule.confirm'),
            },
          ]}
          closeLabel={t('maintenance.close')}
          width={50}
        >
          <>
            <div className="form-group">
              <DateInput
                min={today.toString()}
                onChange={value => {
                  this.setState({ rescheduleDate: moment(value) })
                }}
                value={this.state.rescheduleDate.format(getDateFormat())}
                id="rescheduleDate"
              />
            </div>
            <div className="form-group">
              <TextArea
                label={t('maintenance.scheduledJobDetail.modals.reschedule.notes')}
                id="rescheduleNotes"
                value={this.state.rescheduleNotes}
                onChange={value => this.setState({ rescheduleNotes: value as string })}
                rows={3}
                required={notesRequired}
              />
            </div>
          </>
        </Modal>
      </form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RescheduleModal))
