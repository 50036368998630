import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { MaintenanceScheduledJob, MaintenanceScheduledJobPatchParams, ModuleConfig } from '../../../../types'
import { rejectScheduledJob } from '../../../../redux/actions'
import { getConfigParam } from '@mv-submodules/inplant-maintenance-fe/functions/shared'
import { Modal, TextArea } from '@mv-submodules/inplant-components-fe'
interface OwnStateProps {
  rejectedNotes: string
}

export interface StateProps {
  config: ModuleConfig
}

interface OwnProps {
  isVisible: boolean
  scheduledJob: MaintenanceScheduledJob
  onClose?: Function | null
  // t: TranslationFunction
}

export const mapDispatchToProps = (dispatch: Function) => ({
  rejectScheduledJob: (params: MaintenanceScheduledJobPatchParams) => dispatch(rejectScheduledJob(params)),
})

export const mapStateToProps = (state: any) => ({
  config: state.config,
})

export interface DispatchProps {
  rejectScheduledJob: (params: MaintenanceScheduledJobPatchParams) => Promise<any>
}

export type Props = DispatchProps & OwnProps & WithTranslation & StateProps

class RejectModal extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = this.getInitialState()
    this.onSubmit = this.onSubmit.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
  }

  private getInitialState() {
    const initialState = {
      rejectedNotes: '',
    }

    return initialState
  }

  private resetState() {
    this.setState(this.getInitialState())
  }

  private onSubmit(e: any) {
    e.preventDefault()

    if (!this.props.scheduledJob) {
      return
    }

    const id = this.props.scheduledJob.id
    const params: MaintenanceScheduledJobPatchParams = {
      id,
      notes: this.state.rejectedNotes,
    }

    this.props
      .rejectScheduledJob(params)
      .then(() => {
        this.onModalClose(true)
      })
      .catch(error => {
        console.error(error) // tslint:disable-line
      })
  }

  public onModalClose(reload: boolean = false) {
    this.resetState()
    if (this.props.onClose) {
      this.props.onClose(reload)
    }
  }

  public render() {
    const { t, config } = this.props
    const notesRequired = getConfigParam(config, 'requiredNote', false)

    return (
      <form onSubmit={this.onSubmit}>
        <Modal
          visible={this.props.isVisible}
          width={50}
          additionalFooterButtons={[
            {
              variant: 'primary',
              type: 'submit',
              label: t('maintenance.scheduledJobDetail.modals.reject.confirm'),
            },
          ]}
          title={t('maintenance.scheduledJobDetail.modals.reject.title')}
          closeLabel={t('maintenance.close')}
          onClose={() => this.onModalClose()}
        >
          <>
            <div className="form-group">
              <TextArea
                id="rescheduleNotes"
                // className="form-control"
                value={this.state.rejectedNotes}
                onChange={(event: any) => this.setState({ rejectedNotes: event.target.value })}
                rows={3}
                required={notesRequired}
                label={t('maintenance.scheduledJobDetail.modals.reject.notes')}
              />
            </div>
          </>
        </Modal>
      </form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RejectModal))
