import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { serviceShowNewItemMessage, itemsFetchAttributes } from '../../../../redux/actions'
import { withRouter } from 'react-router'
import { History } from 'history'
import { IdNameType } from '../../../../types'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import {
  Button,
  Card,
  CardBody,
  Input,
  Loader,
  PageHeader,
  Select,
  TextArea
} from '@mv-submodules/inplant-components-fe';
import { ReactText } from 'react';
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column"
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

const API = () => FetchWrapper.getInstance('service')

export interface NewOwnProps {
  // t: TranslationFunction
  history: History
}

export interface ServiceUploadedFile {
  name: string
  success: boolean
  token: string
  type: string
}

export interface NewOwnState {
  subject: string
  phone: string
  priority: string
  status: string
  description: string
  files: any
  uploadedFiles: null | ServiceUploadedFile[]
  garanzia: boolean
  tracker: string
}

export interface UserState {
  uid: string
  roles: string[]
  username: string
  displayName: string
  redmine_project_id: number
}

export interface NewStateProps {
  hasErrored: boolean
  isLoading: boolean
  trackers: IdNameType[]
  priorities: IdNameType[]
  user: UserState
}

const mapStateToProps = (state: any): NewStateProps => {
  return {
    hasErrored: state.service.service.hasErrored,
    isLoading: state.service.service.isLoading,
    trackers: state.service.service.trackers,
    priorities: state.service.service.priorities,
    user: state.auth.user,
  }
}

const customFieldIds = [
  { id: 2, name: 'Referente' },
  { id: 5, name: 'Garanzia' },
  { id: 6, name: 'Causa prevista' },
  { id: 7, name: 'Tipo di azione' },
  { id: 8, name: 'Intervento Onsite' },
  { id: 9, name: 'Costruttore' },
  { id: 10, name: 'Provvedimento adottato' },
  { id: 11, name: 'Data provvedimento' },
  { id: 12, name: 'Data Intervento ' },
  { id: 13, name: 'Note di chiusura' },
  { id: 14, name: 'Causa' },
  { id: 15, name: 'Data chiusura' },
  { id: 16, name: 'Competenza' },
  { id: 17, name: 'Commessa imputazione costi' },
  { id: 20, name: 'Telefono referente' },
]

const findCustomFieldId = (s: string) => {
  const field = customFieldIds.find(e => e.name === s)
  return field ? field.id : null
}
/*
const Input = (props: any) => {
  return (
    <div className="form-group">
      <label htmlFor={props.name}>{props.title}</label>
      <input
        className={props.class}
        id={props.id}
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        required={props.required}
      />
    </div>
  )
}

const Textarea = (props: any) => {
  return (
    <div className="form-group">
      <label htmlFor={props.name}>{props.title}</label>
      <textarea
        className="form-control"
        id={props.id}
        name={props.name}
        rows={props.rows ? props.rows : 10}
        required={props.required}
        onChange={props.handleChange}
        value={props.value}
      />
    </div>
  )
}
*/
const FileUpload = (props: any) => {
  return (
    <div className="form-group">
      <label htmlFor={props.name}>{props.title}</label>
      <input
        type="file"
        className={props.class}
        id={props.id}
        multiple={props.multiple}
        onChange={props.handleChange}
        accept=".jpg, .jpeg, .png, .doc, .docx, .xls, .odt, .ods, .pdf"
      />
    </div>
  )
}
/*
interface SelectOption {
  id: number
  name: string
}

const Select = (props: any) => {
  return (
    <div className="form-group">
      <label htmlFor={props.name}>{props.title}</label>
      <select
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        className={props.class}
        required={props.required}
      >
        <option value="" selected={true}>
          {props.placeholder}
        </option>
        {props.options &&
          props.options.map((option: SelectOption) => {
            return (
              <option key={option.id} value={option.id !== -1 ? option.id : ''} label={option.name}>
                {option.name}
              </option>
            )
          })}
      </select>
    </div>
  )
}
 */
interface NewDispatchProps {
  toggleNewItemMessage: Function
  fetchAttributes: Function
  logoutUser: Function
}

const mapDispatchToProps = (dispatch: Function): NewDispatchProps => {
  return {
    toggleNewItemMessage: (show: boolean) => dispatch(serviceShowNewItemMessage(show)),
    fetchAttributes: () => dispatch(itemsFetchAttributes()),
    logoutUser: () => dispatch(logoutUser()),
  }
}

type NewProps = NewStateProps & NewDispatchProps & NewOwnProps & WithTranslation

class NewPageView extends React.Component<NewProps, NewOwnState> {
  constructor(props: NewProps) {
    super(props)
    this.state = {
      subject: '',
      phone: '',
      priority: '',
      status: '',
      description: '',
      files: null,
      uploadedFiles: null,
      garanzia: false,
      tracker: '',
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleFilesInput = this.handleFilesInput.bind(this)
    this.handleCheckBoxList = this.handleCheckBoxList.bind(this)
  }

  public componentDidMount() {
    if (!this.props.trackers || this.props.trackers.length < 1 || this.props.priorities.length < 1) {
      this.props.fetchAttributes()
    }
  }

  private handleSubmit(event: React.SyntheticEvent<EventTarget>) {
    event.preventDefault()
    const filesToUpload: any = []
    const fileUploadResult: any = []

    if (this.state.files && this.state.files.length > 0) {
      for (const file of this.state.files) {
        const data = new FormData()
        data.append('files[]', file, file.name)
        filesToUpload.push(
          API().request('/attachments', {
            method: 'POST',
            body: data,
          })
            .then((result: any) => {
              if (!result.success) {
                throw Error(result.statusText)
              }
              return result
            })
            .then((result: any) => {
              fileUploadResult.push({
                token: result.token,
                filename: result.name,
                content_type: result.type,
              })
            })
            .catch((error: any) => {
              if (error.name === 'FetchError' && error.statusCode === 401) {
                this.props.logoutUser()
                return null
              } else {
                throw Error(error.toString())
              }
            })
        )
      }
    } else {
      filesToUpload.push(Promise.resolve(true))
    }

    Promise.all(filesToUpload).then(() => {
      this.setState(() => {
        return {
          uploadedFiles: fileUploadResult,
        }
      })

      API().request('/issues', {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject: this.state.subject,
          description: this.state.description,
          tracker_id: this.state.tracker,
          priority_id: this.state.priority,
          uploads: this.state.uploadedFiles,
          custom_fields: [
            {
              id: findCustomFieldId('Garanzia'),
              value: false,
            },
            {
              id: findCustomFieldId('Telefono referente'),
              value: this.state.phone,
            },
            {
              id: findCustomFieldId('Referente'),
              value: this.props.user.displayName,
            },
          ],
        }),
      })
        .then((results: any) => {
          if (!results && !results.issue.id) {
            throw Error(results.statusText)
          }
          return results
        })
        .then(() => {
          this.props.toggleNewItemMessage(true)
          this.props.history.push('/service/list')
        })
        .catch((error: any) => {
          if (error.name === 'FetchError' && error.statusCode === 401) {
            this.props.logoutUser()
            return null
          } else {
            throw Error(error.toString())
          }
        })
    })
  }

  private handleInput(inputName: string, inputValue: ReactText, inputType: string) {
    const value = inputType === 'checkbox' ? Boolean(inputValue) : String(inputValue)

    this.setState(prevState => {
      return {
        ...prevState,
        [inputName]: value,
      }
    })
  }

  private handleCheckBoxList(e: React.FormEvent<HTMLInputElement>) {
    const newSelection = e.currentTarget.value
    let newSelectionArray: any
    const name = e.currentTarget.name

    if (this.state[name].indexOf(newSelection) > -1) {
      newSelectionArray = this.state[name].filter((s: string) => s !== newSelection)
    } else {
      newSelectionArray = [...this.state[name], newSelection]
    }

    this.setState(prevState => ({
      ...prevState,
      [name]: newSelectionArray,
    }))
  }

  private handleFilesInput(event: React.FormEvent<HTMLInputElement>) {
    const files = event.currentTarget.files

    this.setState(prevState => {
      return {
        ...prevState,
        files,
      }
    })
  }

  public render() {
    if (this.props.hasErrored) {
      return (
        <Row>
          <Column lg={12}>
            <div className={"text-center"}>
              <IconComponent icon={'exclamation-triangle'} size="2x" className="text-danger"/>
            </div>
          </Column>
        </Row>
      )
    }
    if (this.props.isLoading) {
      return (
        <Row>
          <Column lg={12}>
            <Loader/>
          </Column>
          <Loader/>
        </Row>
      )
    }

    return (
      <div className="inplant-service">
        <PageHeader title={this.props.t('service.form.newTicket')} />
        <div className="content">
          <form onSubmit={this.handleSubmit}>
            <Card bgLight={true}>
              <CardBody>
                <Input
                  id={'subject'}
                  required={true}
                  type={'text'}
                  title={this.props.t('service.form.subject')}
                  name={'subject'}
                  value={this.state.subject}
                  placeholder={this.props.t('service.form.subject')}
                  onChange={value => this.handleInput('subject', value, 'text')}
                />

                <TextArea
                  id={'description'}
                  required={true}
                  title={this.props.t('service.form.description')}
                  name={'description'}
                  value={this.state.description}
                  placeholder={this.props.t('service.form.description')}
                  onChange={value => this.handleInput('description', value, 'textarea')}
                  rows={10}
                />

                <Row>
                  <Column md={4}>
                    <Row>
                      <Column xs={12}>
                        <FileUpload
                          id={'attachments'}
                          name={'attachments'}
                          title={this.props.t('service.form.attachments')}
                          handleChange={this.handleFilesInput}
                          class={'form-control'}
                          multiple={true}
                        />
                      </Column>
                      <Column xs={12}>
                        <div className={"attachments-preview"}>
                          {this.state.files && this.state.files.length > 0 && (
                            <React.Fragment>
                              <h4>{this.props.t('service.attachments.preview.title')}</h4>
                              <ul>
                                {this.state.files &&
                                Array.from(this.state.files).map((f: any, id: number) => (
                                  <li key={id}>
                                    <span>
                                      {f.type.indexOf('image/') !== -1 && <IconComponent icon={'file-image'} />}
                                      {f.type.indexOf('image/') < 0 && <IconComponent icon={'file'} />}
                                    </span>{' '}
                                    {f.name}
                                  </li>
                                ))}
                              </ul>
                            </React.Fragment>
                          )}
                        </div>
                      </Column>
                    </Row>
                  </Column>
                  <Column md={8}>
                    <Row >
                      <Column md={6}>
                        <Select
                          name={'priority'}
                          id={'priority'}
                          required={true}
                          title={this.props.t('service.form.priority')}
                          onChange={value => this.handleInput('priority', value, 'select-one')}
                          options={{
                            defaultOption: this.props.t('service.form.select'),
                            items: this.props.priorities.map(priority => ({
                              label: priority.name,
                              value: String(priority.id)
                            }))
                          }}
                          value={this.state.priority}
                        />
                      </Column>
                      <Column md={6}>
                        <Select
                          name={'tracker'}
                          id={'tracker'}
                          required={true}
                          title={this.props.t('service.form.requestType')}
                          onChange={value => this.handleInput('tracker', value, 'select-one')}
                          options={{
                            defaultOption: this.props.t('service.form.select'),
                            items: this.props.trackers.map(tracker => ({
                              label: tracker.name,
                              value: String(tracker.id)
                            }))
                          }}
                          value={this.state.tracker}
                        />
                      </Column>
                      <Column md={6} >
                        <Input
                          id={'phone'}
                          required={true}
                          type={'text'}
                          title={this.props.t('service.form.phone')}
                          name={'phone'}
                          value={this.state.phone}
                          placeholder={this.props.t('service.form.phone')}
                          onChange={value => this.handleInput('phone', value, 'textarea')}
                        />
                      </Column>
                    </Row>
                  </Column>
                </Row>
              </CardBody>
            </Card>

            <div className="text-right mt-3">
              <Button type={"submit"} variant={"primary"} label={this.props.t('service.form.create')} />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(NewPageView))
)
