import * as React from 'react'
import 'd3-transition'
import 'd3-selection-multi'
import * as jquery from 'jquery'
import {fetchOeeData} from '../../../../redux/actions/graphs'
import {withTranslation, WithTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {gauge} from '../../../../types/Graphs'
import {Card, CardBody, CardFooter, CardHeader} from '@mv-submodules/inplant-components-fe';

const graphLayout = {
  small: {
    width: 65,
    height: 65,
  },
  big: {
    width: 90,
    height: 90,
  },
}

interface GraphGaugesDispatchProps {
  fetchData: () => Promise<void>
  // graphIsInitialized: (bool: boolean) => Function
}

interface GraphGaugesStateProps {
  data?: number[]
  hasErrored: boolean
  isLoading: boolean
  columns?: string[]
  range: null | string
  prevRange: string | null
  dateStart: string | null
  dateEnd: string | null
  lastDateUpdate: number | null
}

// interface GraphGaugesOwnProps {
//   t: TranslationFunction
// }

interface GraphGaugesOwnState {
  lastUpdate: number | null
}

type GraphGaugesProps = GraphGaugesDispatchProps & GraphGaugesStateProps & WithTranslation

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchData: () => dispatch(fetchOeeData()),
  }
}

const mapStateToProps = (state: any): GraphGaugesStateProps => {
  return {
    data: state.graphGaugesData.data,
    hasErrored: state.graphGaugesData.hasErrored,
    isLoading: state.graphGaugesData.isLoading,
    columns: state.graphGaugesData.columns,
    range: state.dateFilters.range,
    prevRange: state.dateFilters.prevRange,
    dateStart: state.dateFilters.dateStart,
    dateEnd: state.dateFilters.dateEnd,
    lastDateUpdate: state.dateFilters.lastUpdate,
  }
}

class GraphGauges extends React.Component<GraphGaugesProps, GraphGaugesOwnState> {
  constructor(props: GraphGaugesProps) {
    super(props)
    this.state = {
      lastUpdate: null,
    }
  }

  public componentWillMount() {
    this.props.fetchData().then(() => {
      this.draw()
    })
  }

  public componentWillReceiveProps(newProps: GraphGaugesStateProps) {
    if (newProps.lastDateUpdate !== null && this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
      this.props.fetchData().then(() => {
        this.draw()
      })
    }
  }

  public draw() {
    if (this.props.data !== undefined && this.props.data.length > 0) {
      jquery('.single-gauge-small .power-gauge').each((i: number, e: object) => {
        const $ele = jquery(e)

        /* tslint:disable- */
        const powerGauge = gauge('#' + $ele.attr('id'), {
          size: graphLayout.small.width,
          clipWidth: graphLayout.small.width,
          clipHeight: graphLayout.small.height,
          ringInset: 0,
          // @ts-ignore
          ringWidth: graphLayout.small.width / 7,
          maxValue: 100,
          transitionMs: 0,
        })
        powerGauge.render($ele.data('percent'))
        $ele
          .parents('.single-gauge-small')
          .find('.gauge-value')
          .text($ele.data('percent').toFixed(2) + '%')
      })

      let bigGaugeValue = 1

      jquery('.single-gauge-small').each((i: number, e: object) => {
        const $ele = jquery(e).find('.power-gauge')
        bigGaugeValue = bigGaugeValue * ($ele.data('percent') / 100)
      })

      bigGaugeValue = Math.round(bigGaugeValue * 100)
      jquery('.single-gauge-big .power-gauge').each((i: number, e: object) => {
        const $ele = jquery(e)
        const powerGauge = gauge('#' + $ele.attr('id'), {
          size: graphLayout.big.width,
          clipWidth: graphLayout.big.width,
          clipHeight: graphLayout.big.height,
          ringInset: 0,
          // @ts-ignore
          ringWidth: graphLayout.big.width / 7,
          maxValue: 100,
          transitionMs: 0,
        })

        powerGauge.render(bigGaugeValue)
        $ele
          .parents('.single-gauge')
          .find('.gauge-value')
          .text(bigGaugeValue.toFixed(2) + '%')
      })
    }
  }

  public render() {
    let smallGauges

    if (this.props.data && this.props.data.length > 0) {
      smallGauges = this.props.data.map((item: number, index: number) => {
        let name = ''
        if (this.props && this.props.columns && this.props.columns.length > 0) {
          const label = this.props.columns[index]
          if (label) {
            name = this.props.t(label)
          }
        }
        return (
          <div  className="single-gauge single-gauge-small" key={index}>
            <div id={'power-gauge-' + index} className="power-gauge" data-percent={item * 100}/>
            <h4 className="power-gauge-label">{name}</h4>
            <span className="gauge-value"/>
          </div>
        )
      })
    }

    return (
      <div className="DashboardWidget WidgetRound WidgetGauges">
        <Card marginBottom={3}>
          <CardHeader>Average OEE</CardHeader>
          <CardBody>
            <div className="container">
              {this.props.hasErrored ? (
                <p>Sorry! There was an error loading data</p>
              ) : this.props.isLoading ? (
                <p>Loading…</p>
              ) : (
                <div className={"text-center"}>
                  <div ref={`big-gauge`} className="text-center single-gauge single-gauge-big">
                    <div id="power-gauge" className="power-gauge"/>
                    <h3 className="power-gauge-label">OEE</h3>
                    <span className="gauge-value"/>
                  </div>
                  {smallGauges}
                </div>
              )}
            </div>
          </CardBody>
          <CardFooter
            textMuted={true}
            textAlign={'right'}
            textSizeSmall={true}
          >&nbsp;</CardFooter>
        </Card>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GraphGauges))
