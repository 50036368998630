import * as React from 'react'
import * as moment from 'moment'
import 'moment/locale/it'
import { MaintenanceScheduledJob, MaintenanceStatus } from '../../../../types'
// TODO temp!
const i18n = { language: 'en' }

interface Props {
  message: string
  scheduledJobs: MaintenanceScheduledJob[]
  onMouthClick?: (mouth: moment.Moment) => void
}

interface PastMonth {
  key: string
  mountStart: moment.Moment
}

const getPastUndoneScheduledJobs = (scheduledJobs: MaintenanceScheduledJob[]): MaintenanceScheduledJob[] => {
  const startOfMonth = moment().startOf('month')
  return scheduledJobs.filter((job: MaintenanceScheduledJob) => {
    return (
      job.status !== MaintenanceStatus.DONE &&
      job.status !== MaintenanceStatus.REJECTED &&
      job.dueDate.unix() < startOfMonth.unix()
    )
  })
}

const getScheduledJobsMonths = (scheduledJobs: MaintenanceScheduledJob[]): PastMonth[] => {
  const months: PastMonth[] = []
  scheduledJobs.forEach((job: MaintenanceScheduledJob) => {
    const jobMonth = job.dueDate.clone()
    jobMonth.locale(i18n.language)
    const key = jobMonth.format('YYYYMM')
    if (months.map((m: any) => m.key).indexOf(key) === -1) {
      months.push({
        key,
        mountStart: jobMonth.startOf('month'),
      })
    }
  })
  return months
}

class PastScheduledJobsAlert extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.handleMouthClick = this.handleMouthClick.bind(this)
  }

  private handleMouthClick(mouth: moment.Moment) {
    const { onMouthClick } = this.props
    if (onMouthClick) {
      onMouthClick(mouth)
    }
  }

  public render() {
    const { scheduledJobs, message } = this.props
    const pastScheduledJobs = getPastUndoneScheduledJobs(scheduledJobs)

    return pastScheduledJobs.length > 0 ? (
      <div className="alert alert-warning" role="alert">
        {message}{' '}
        {getScheduledJobsMonths(pastScheduledJobs)
          .map((mouth: PastMonth, index: number) => (
            <a
              key={index}
              href="#"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => this.handleMouthClick(mouth.mountStart)}
            >
              {mouth.mountStart.format('MMMM')}
            </a>
          ))
          .reduce((accu: any, elem) => {
            return accu.length === 0 ? [elem] : [...accu, ', ', elem]
          }, [])}
      </div>
    ) : null
  }
}

export default PastScheduledJobsAlert
