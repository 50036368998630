import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import * as arrayMove from 'array-move'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { popupNotification } from '@mv-submodules/inplant-core-fe/functions/notifications'
import { Loader } from '@mv-submodules/inplant-components-fe'
import Modal from 'react-bootstrap4-modal'
import * as moment from 'moment'
import { cleanMemoryAddress } from '@mv-submodules/inplant-designer-fe/utils/nodes'

export interface StateProps {
  edit: Function
  remove: Function
  editable: boolean
  current?: any
  reorderedData?: Function
}

export interface OwnProps extends RouteComponentProps<any> {
  data: any
}

export interface OwnState {
  items: any
  previewData: any[] | null
  previewName: string | null
  previewMetric: string | null
  fetchingPreviewData: boolean
  showPreviewData: boolean
}

export type Props = StateProps & OwnProps & WithTranslation

const APIInflux = () => FetchWrapper.getInstance('influx')

class MetricsListComponent extends React.Component<Props, OwnState> {

  constructor(props: Props) {
    super(props)

    this.state = {
      items: [],
      previewData: null,
      previewName: null,
      previewMetric: null,
      showPreviewData: false,
      fetchingPreviewData: false,
    }

    this.onSortEnd = this.onSortEnd.bind(this)
    this.previewData = this.previewData.bind(this)
  }

  public componentDidMount(): void {
    this.setState({
      items: this.props.data,
    })
  }

  public componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<OwnState>, nextContext: any): void {
    if (this.state.items !== nextProps.data) {
      this.setState({
        items: nextProps.data,
      })
    }
  }

  public render() {
    if (!this.state.items || this.state.items.length < 1) {
      return null
    }

    const SortableItem = SortableElement((props: any) => {
      const data = props.value.data ? props.value.data : props.value
      return (
        <li
          className={
            ((this.props.current && props.value.id && props.value.id === this.props.current.id) ||
            (props.value.data && props.value.data.id === this.props.current.data.id)
              ? 'active'
              : '') + (this.props.editable ? ' editable' : ' read-only')
          }
          key={data.id}
        >
          <span
            className="metric-label"
            title={data.label + ' - ' + this.props.t('designer.node.metric.previewAction')}
            onClick={this.previewData}
            data-metric={data.id}
            data-name={data.label}
          >
            {data.label.length > 30 ? data.label.substr(0, 30) + '...' : data.label}
          </span>
          <span className="metric-address text-right" title={data.memoryAddress}>{cleanMemoryAddress(data.memoryAddress)}</span>
        </li>
      )
    })

    const SortableList = SortableContainer((props: any) => {
      return (
        <ul className={'metrics-list' + (!props.enabled ? ' ordering-disabled' : '')} id="metrics-list">
          {props.items &&
            props.items.length > 0 &&
            props.items.map((value: any, index: number) => (
              <SortableItem key={`item-${index}`} index={index} value={value} disabled={!props.enabled} />
            ))}
        </ul>
      )
    })

    return (
      <React.Fragment>
        <SortableList
          items={this.state.items}
          onSortEnd={this.onSortEnd}
          distance={10}
          helperClass={'metric-order-helper'}
          lockAxis={'y'}
          enabled={false} // this.props.reorderedData !== undefined
        />

        {this.state.showPreviewData && (
          <Modal visible={true} dialogClassName={'modal-lg'}>
            <div className="modal-header">
              {!this.state.fetchingPreviewData && (
                <h5 className="modal-title">
                  {this.state.previewName + ' ' + this.props.t('designer.node.metric.modal.titleSeparator')}{' '}
                  <small>
                    [{' '}
                    <span
                      onClick={() => this.copyText(String(this.state.previewMetric))}
                      className="tooltips copyable text-primary"
                      data-title={this.props.t('designer.copy.click')}
                    >
                      {this.state.previewMetric}
                    </span>{' '}
                    ]
                  </small>
                </h5>
              )}
            </div>
            <div className="modal-body">
              {this.state.fetchingPreviewData && this.state.previewData === null && <Loader />}

              {!this.state.fetchingPreviewData &&
                this.state.previewData !== null &&
                this.state.previewData.length === 0 && (
                  <div className="alert alert-info" role="alert">
                    {this.props.t('designer.node.metric.modal.noData')}
                  </div>
                )}

              {!this.state.fetchingPreviewData && this.state.previewData !== null && this.state.previewData.length > 0 && (
                <div className="preview-data">
                  {this.state.previewData && this.state.previewData.length > 0 && (
                    <ul>
                      {this.state.previewData.map((row: any[], i: number) => (
                        <li key={i}>
                          <span className="date">{row[0]}</span>
                          <span className="value">{row[1]}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary float-left"
                onClick={() => this.setState({ previewData: null, showPreviewData: false })}
              >
                {this.props.t('designer.node.metric.modal.close')}
              </button>
            </div>
          </Modal>
        )}
      </React.Fragment>
    )
  }

  private copyText(text: string) {
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('Copy')
    textArea.remove()

    popupNotification({
      text: this.props.t('designer.copy.success'),
      type: 'success'
    })
  }

  private async previewData(e: React.MouseEvent<HTMLSpanElement>) {
    const dataset = e.currentTarget.dataset

    if (!dataset.metric || !dataset.name) {
      this.setState({
        showPreviewData: false,
        previewData: null,
        fetchingPreviewData: false,
        previewName: null,
        previewMetric: null,
      })
    } else {
      this.setState({
        showPreviewData: true,
        fetchingPreviewData: true,
      })

      if (APIInflux) {
        const metric = dataset.metric || null
        const metricName = dataset.name || null
        const data = await APIInflux().request(
          '/influx/query?q=SELECT "measure" FROM "' + metric + '" ORDER BY DESC LIMIT 100'
        )

        let previewData = []

        if (data && data.results && data.results[0] && data.results[0].series && data.results[0].series[0]) {
          previewData = data.results[0].series[0].values.map((row: any) => {
            return [
              moment(row[0]).format('YYYY/MM/DD HH:mm:ss'),
              typeof row[1] === 'boolean' ? (row[1] ? 'true' : 'false') : row[1],
            ]
          })
        }

        this.setState({
          previewData,
          fetchingPreviewData: false,
          previewName: metricName,
          previewMetric: metric,
        })
      } else {
        this.setState({
          showPreviewData: false,
          previewData: [],
          fetchingPreviewData: false,
          previewName: null,
          previewMetric: null,
        })
      }
    }
  }

  private onSortEnd = (data: { oldIndex: number; newIndex: number; collection: number }) => {
    if (this.props.reorderedData) {
      const { oldIndex, newIndex } = data
      const items = JSON.parse(JSON.stringify(this.state.items))
      const orderedItems = arrayMove(items, oldIndex, newIndex)

      this.setState({
        items: orderedItems,
      })

      this.props.reorderedData(orderedItems)
    }
  }
}

export default withRouter<any, any>(withTranslation()(MetricsListComponent))
