// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

interface Props {
  error: string
}

const InvalidFeedback = ({ error }: Props) => (
  <div className="invalid-feedback" style={{ display: 'block' }}>
    {error}
  </div>
)

export default InvalidFeedback
