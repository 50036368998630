import * as React from 'react'
import Modal from 'react-bootstrap4-modal'
import { FileAttachment } from '../../../types/media'
import { getI18n, withTranslation, WithTranslation } from 'react-i18next'
import { i18n } from 'i18next'
import { i18nLabels } from '../../../types/i18n'
import FlagIcon from './FlagIcon'
import IconComponent from '../../../../inplant-components-fe/ui/components/MVIcon/Icon'

const i18nInstance: i18n = getI18n()

export interface OwnState {
  file: FileAttachment
}

export interface OwnProps {
  // t: TranslationFunction
  closeModal: (event: React.MouseEvent<HTMLButtonElement>) => void
  saveTranslation: (file: FileAttachment) => void
  file: FileAttachment
}

class AttachmentTranslationModal extends React.Component<OwnProps & WithTranslation, OwnState> {
  constructor(props: OwnProps & WithTranslation) {
    super(props)

    const thisFile = { ...this.props.file }
    if (i18nInstance && i18nInstance.options.resources) {
      if (!thisFile.hasOwnProperty('translations')) {
        thisFile.translations = {}
      }
      if (!thisFile.hasOwnProperty('label')) {
        thisFile.label = ''
      }
      Object.keys(i18nInstance.options.resources).map((lang: string) => {
        if (!thisFile.translations.hasOwnProperty(lang)) {
          thisFile.translations[lang] = ''
        }
      })
    }

    this.state = {
      file: thisFile,
    }

    this.saveTranslation = this.saveTranslation.bind(this)
  }

  public componentWillReceiveProps(nextProps: Readonly<OwnProps>, nextContext: any): void {
    const thisFile = { ...nextProps.file }
    if (i18nInstance && i18nInstance.options.resources) {
      if (!thisFile.hasOwnProperty('translations')) {
        thisFile.translations = {}
      }
      if (!thisFile.hasOwnProperty('label')) {
        thisFile.label = ''
      }
      Object.keys(i18nInstance.options.resources).map((lang: string) => {
        if (!thisFile.translations.hasOwnProperty(lang)) {
          thisFile.translations[lang] = ''
        }
      })
    }

    this.state = {
      file: thisFile,
    }
  }

  private saveTranslation() {
    const translations = { ...this.state.file.translations }

    Object.keys(translations).map((lang: string) => {
      if (lang && translations[lang] === '') {
        delete translations[lang]
      }
    })

    this.props.saveTranslation({ ...this.state.file, translations })
  }

  private updateTranslation(e: React.ChangeEvent<HTMLInputElement>, lang: null | string) {
    if (lang === null) {
      this.setState({
        file: { ...this.state.file, label: e.currentTarget.value },
      })
    } else {
      const translations = { ...this.state.file.translations }
      translations[lang] = e.currentTarget.value
      this.setState({
        file: { ...this.state.file, translations },
      })
    }
  }

  public render() {
    const defaultFileName = this.state.file.label
      ? this.state.file.label
      : this.state.file.name.indexOf('/') > -1
      ? this.state.file.name.split('/').pop()
      : this.state.file.name

    return (
      <Modal visible={true} dialogClassName={'modal-lg modal-attachment-translation'}>
        <div className="modal-header">
          <h5 className="modal-title">{this.props.t('designer.media.translationModal.title')}</h5>
        </div>
        <div className="modal-body">
          <div className="input-group mb-2">
            <input
              className="form-control"
              type="text"
              value={this.state.file.label}
              placeholder={defaultFileName}
              onChange={e => this.updateTranslation(e, null)}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <IconComponent icon={'globe'} />
              </span>
            </div>
          </div>
          {i18nInstance &&
            i18nInstance.options.resources &&
            Object.keys(i18nInstance.options.resources).map((lang: string, i: number) => {
              return (
                <div className="input-group mb-2" key={i}>
                  <input
                    className="form-control"
                    type="text"
                    value={
                      this.state.file.translations && this.state.file.translations.hasOwnProperty(lang)
                        ? this.state.file.translations[lang]
                        : undefined
                    }
                    onChange={e => this.updateTranslation(e, lang)}
                    placeholder={defaultFileName}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <FlagIcon code={i18nLabels[lang]} />
                    </span>
                  </div>
                </div>
              )
            })}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary float-right" onClick={this.props.closeModal}>
            {this.props.t('designer.media.translationModal.close')}
          </button>
          <button type="button" className="btn btn-primary float-right" onClick={this.saveTranslation}>
            {this.props.t('designer.media.translationModal.save')}
          </button>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(AttachmentTranslationModal)
