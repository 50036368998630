import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'

export const isFetching = (bool: boolean): AnyAction => {
  return {
    type: 'SMARTDOCS_IS_FETCHING',
    fetching: bool,
  }
}

export const fetchSuccess = (dataStructure: object): AnyAction => {
  return {
    type: 'SMARTDOCS_FETCH_SUCCESS',
    data: dataStructure,
  }
}

export const fetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'SMARTDOCS_FETCH_ERROR',
  }
}

export const fetchData = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isFetching(true))

    try {
      const url = '/model'
      const data = await API().request(url)

      return Promise.all([data]).then(values => {
        dispatch(isFetching(false))
        dispatch(fetchSuccess(values[0]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(isFetching(false))
      dispatch(fetchError(new Error(error)))
      throw error
    }
  }
}
