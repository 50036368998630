// import { Icon } from "../../inplant-components-fe/services/icon"

// TODO Verificare se usare Icon o mettere any

export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey?: string
  // TODO 
  icon?: any
  iconOverride?: string
  visible: boolean
  visibleInUserProfile?: boolean
  component?: any
  children?: RouteProps[]
  aclActionKey?: string
  external?: boolean
}

export interface UserParams {
  uuid: string
  username: string
  displayName: string
  roles: string[]
  forbiddenActions: string[]
}

export class User {
  public uuid: string
  public username: string
  public displayName: string
  public roles: string[]
  public forbiddenActions: string[]

  constructor(params: Partial<UserParams>) {
    this.uuid = params.uuid || ''
    this.username = params.username || ''
    this.displayName = params.displayName || ''
    this.roles = params.roles || []
    this.forbiddenActions = params.forbiddenActions || []
  }

  public can(action: string): boolean {
    return !this.forbiddenActions.includes(action)
  }
}
