import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { getDateRangeFiltering } from '@mv-submodules/inplant-core-fe/functions/date'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import {mappedInfluxIDs} from '@mv-submodules/inplant-config/config/graphics'
import { consoleNotification } from '@mv-submodules/inplant-core-fe/functions/notifications'
import { consoleLog } from '@mv-submodules/inplant-components-fe/mvfunctions/logs'

export const APIInflux = () => FetchWrapper.getInstance('influx')
export const APIProduction = () => FetchWrapper.getInstance('production')

/* OEE */

export const fetchOeeHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_OEE_ERROR',
    hasErrored: bool,
  }
}

export const fetchOeeIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_OEE_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchOeeSuccess = (data: object): AnyAction => {
  return {
    type: 'FETCH_OEE_SUCCESS',
    data,
    hasErrored: false,
  }
}

export const graphOeeIsInitialized = (bool: boolean): AnyAction => {
  return {
    type: 'GRAPH_OEE_INITIALIZED',
    graphInitialized: bool,
  }
}

export function fetchOeeData() {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { days, start, end } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)
    const groupBy = 'day'
    let parameters

    if(days > 31) {
      parameters =
        `SELECT mean("availability_mean") AS "availability", mean("quality_mean") AS "quality", ` +
        `mean("nperformance_mean") AS "performance" ` +
        `FROM "vMonthlyPlantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' `
    } else {
      parameters =
        `SELECT mean("availability") AS "availability", mean("quality") AS "quality", ` +
        `mean("nperformance") AS "performance" ` +
        `FROM "plantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' AND "productionday"=true ` +
        `GROUP BY ${groupBy} FILL(null)`
    }

    dispatch(fetchOeeIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters)

      return Promise.all([data]).then(values => {
        dispatch(fetchOeeIsRunning(false))
        dispatch(fetchOeeSuccess(values[0]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchOeeIsRunning(false))
      dispatch(fetchOeeHasError(true))
      throw  error
    }
  }
}

/* PLAN RUN */

export const fetchPlanRunHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_PLANRUN_ERROR',
    hasErrored: bool,
  }
}

export const fetchPlanRunIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_PLANRUN_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchPlanRunSuccess = (data: object): AnyAction => {
  return {
    type: 'FETCH_PLANRUN_SUCCESS',
    data,
    hasErrored: false,
  }
}

export function fetchPlanRunData() {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { days, group, start, end } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)
    let parameters

      if(days > 31) {
        parameters = `SELECT "plantime_mean", "runtime_mean", "opertime_mean" ` +
          `FROM "vMonthlyPlantStateTime" ` +
          `WHERE time >= '${start}' AND time < '${end}' `
      } else {
        parameters = `SELECT mean("plantime"), mean("runtime"), mean("opertime") ` +
        `FROM "plantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' ` +
        group
      }

    dispatch(fetchPlanRunIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters)

      return Promise.all([data]).then(values => {
        dispatch(fetchPlanRunIsRunning(false))
        dispatch(fetchPlanRunSuccess(values[0]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchPlanRunIsRunning(false))
      dispatch(fetchPlanRunHasError(true))

      throw  error
    }
  }
}

/* PLAN RUN OEE */

export const fetchPlanRunOeeHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_PLANRUNOEE_ERROR',
    hasErrored: bool,
  }
}

export const fetchPlanRunOeeIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_PLANRUNOEE_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchPlanRunOeeSuccess = (data: object, lineData: object): AnyAction => {
  return {
    type: 'FETCH_PLANRUNOEE_SUCCESS',
    data,
    lineData,
    hasErrored: false,
  }
}

export function fetchPlanRunOeeData() {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { group, start, end, days } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)

    let parameters
    let lineParameters

    if (days > 31) {
      parameters =
        `SELECT "plantime_mean", "runtime_mean", "opertime_mean" ` +
        `FROM "vMonthlyPlantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' `

      lineParameters =
        `SELECT "oee_mean" as "oee", "noee_mean" as "noee" ` +
        `FROM "vMonthlyPlantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' `
    } else {
      parameters =
        `SELECT max("plantime") as "plantime", max("runtime") as "runtime", max("opertime") as "opertime" ` +
        `FROM "plantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' ${group}`
      lineParameters =
        `SELECT max("oee") as "oee", max("noee") as "noee" ` +
        `FROM "plantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' ${group}`
    }

    dispatch(fetchPlanRunOeeIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters)
      const lineData = await APIInflux().request('/influx/query?q=' + lineParameters)

      return Promise.all([data, lineData]).then(values => {
        dispatch(fetchPlanRunOeeIsRunning(false))
        dispatch(fetchPlanRunOeeSuccess(values[0], values[1]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchPlanRunOeeIsRunning(false))
      dispatch(fetchPlanRunOeeHasError(true))

      throw  error
    }
  }
}

/* CONSUMPTION */

export const fetchConsumptionHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_CONSUMPTION_ERROR',
    hasErrored: bool,
  }
}

export const fetchConsumptionIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_CONSUMPTION_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchConsumptionSuccess = (data: object): AnyAction => {
  return {
    type: 'FETCH_CONSUMPTION_SUCCESS',
    data,
    hasErrored: false,
  }
}

export function fetchConsumptionData() {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { start, end } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)
    const parameters =
      `SELECT mean("plantActivePower") AS "ActivePower", ` +
      `mean("plantCarbonFootprint") AS "CarbonFootprint", ` +
      `mean("plantComprimedAir") AS "ComprimedAir", ` +
      `mean("plantGas") AS "Gas", ` +
      `mean("plantDemiWater") AS "DemiWater", ` +
      `mean("plantRawWater") AS "RawWater", ` +
      `mean("plantThermalEnergy") AS "ThermalEnergy",` +
      `mean("plantThermalEnergyH") AS "ThermalEnergyH",` +
      `mean("plantThermalEnergyC") AS "ThermalEnergyC",` +
      `mean("chemicalsPaste") as "Paste",` +
      `mean("chemicalsBinder") as "Binder",` +
      `mean("chemicalsSgsPd01") as "SgsPd01",` +
      `mean("chemicalsSg1Pd01") as "Sg1Pd01",` +
      `mean("chemicalsDisPd01") as "DisPd01",` +
      `mean("chemicalsConPd01") as "ConPd01" FROM "globalConsumptions"`
    const timeConditions = `WHERE time >= '${start}' AND time < '${end}'`

    dispatch(fetchConsumptionIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters + timeConditions)

      return Promise.all([data]).then(values => {
        dispatch(fetchConsumptionIsRunning(false))
        dispatch(fetchConsumptionSuccess(values[0]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchConsumptionIsRunning(false))
      dispatch(fetchConsumptionHasError(true))

      throw  error
    }
  }
}

/* QUALITY */

export const fetchQualityHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_QUALITY_ERROR',
    hasErrored: bool,
  }
}

export const fetchQualityIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_QUALITY_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchQualitySuccess = (data: object, lineData: object, lineDataPieces: object): AnyAction => {
  return {
    type: 'FETCH_QUALITY_SUCCESS',
    data,
    lineData,
    lineDataPieces,
    hasErrored: false,
  }
}

export function fetchQualityData() {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { group, start, end, days } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)

    let parameters =
      `SELECT /_sum/ ` + `FROM vDailyScraps ` + `WHERE time >= '${start}' AND time < '${end}' FILL(0)`
    let lineParameters =
      `SELECT max("quality") as "quality" from "plantStateTime" ` +
      `WHERE time >= '${start}' AND time < '${end}' ${group}`
    let lineParametersPieces =
      `SELECT "${mappedInfluxIDs.qualityMeasure}" as "pl" from "vDailyProductionDetails" ` + `WHERE time >= '${start}' AND time < '${end}' `

    if (days > 205) {
      parameters = `SELECT /_sum/ FROM vMonthlyScraps WHERE time >= '${start}' AND time < '${end}' `

      lineParameters =
        `SELECT "quality_mean" AS "quality" ` +
        `FROM "vMonthlyPlantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' `

      lineParametersPieces =
        `SELECT "${mappedInfluxIDs.qualityMeasure}_sum" ` +
        `FROM "vMonthlyProductionDetails" ` +
        `WHERE time >= '${start}' AND time < '${end}' `
    }

    dispatch(fetchQualityIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters)
      const lineData = await APIInflux().request('/influx/query?q=' + lineParameters)
      const lineDataPieces = await APIInflux().request('/influx/query?q=' + lineParametersPieces)

      return Promise.all([data, lineData, lineDataPieces]).then(values => {
        dispatch(fetchQualityIsRunning(false))
        dispatch(fetchQualitySuccess(values[0], values[1], values[2]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchQualityIsRunning(false))
      dispatch(fetchQualityHasError(true))

      throw  error
    }
  }
}

/* QUALITY DETAILS */

export const fetchQualityDetailsHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_QUALITYDETAILS_ERROR',
    hasErrored: bool,
  }
}

export const fetchQualityDetailsIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_QUALITY_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchQualityDetailsSuccess = (data: object): AnyAction => {
  return {
    type: 'FETCH_QUALITYDETAILS_SUCCESS',
    data,
    hasErrored: false,
  }
}
// QualityDetailsPageView.tsx NOT IN USE, check queries if you re-enable it
export function fetchQualityDetailsData() {
  consoleNotification('Widget was not ENABLED BEFORE, please check and update queries for `fetchQualityDetailsData`', 'CHECK QUERIES', 'danger')

  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { group, start, end, days } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)

    let parameters =
      `SELECT mean("impurity") as "impurity", mean("dent") as "dent", ` +
      `mean("coverage") as "coverage", mean("color") as "color", mean("other") as "other" ` +
      `FROM "plantStateTime" ` +
      `WHERE time >= '${start}' AND time < '${end}' ${group}`

    if (days > 205) {
      parameters =
        `SELECT sum("impurity") AS "impurity", sum("dent") AS "dent", ` +
        `sum("coverage") AS "coverage", sum("color") AS "color", sum("other") AS "other" ` +
        `FROM "vMonthlyPlantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}'`
    }

    dispatch(fetchQualityDetailsIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters)

      return Promise.all([data]).then(values => {
        dispatch(fetchQualityDetailsIsRunning(false))
        dispatch(fetchQualityDetailsSuccess(values[0]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchQualityDetailsIsRunning(false))
      dispatch(fetchQualityDetailsHasError(true))

      throw  error
    }
  }
}

/* AVAILABILITY */

export const fetchAvailabilityHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITY_ERROR',
    hasErrored: bool,
  }
}

export const fetchAvailabilityIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITY_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchAvailabilitySuccess = (data: object, lineData: object): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITY_SUCCESS',
    data,
    lineData,
    hasErrored: false,
  }
}

export function fetchAvailabilityData() {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { group, start, end, days } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)

    let parameters =
      `SELECT max("planloss") as "Planned Loss", max("unplanloss") as "Unplanned Loss" ` +
      `FROM "plantStateTime"` +
      `WHERE time >= '${start}' AND time < '${end}' `
    let lineParameters =
      `SELECT max("availability") as "availability", max("runtime") as "runtime"  ` +
      `FROM "plantStateTime" ` +
      `WHERE time >= '${start}' AND time < '${end}' `

    if (days > 205) {
      parameters =
        `SELECT "planloss_sum" as "Planned Loss", "unplanloss_sum" as "Unplanned Loss" ` +
        `FROM "vMonthlyPlantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' `
      lineParameters =
        `SELECT "availability_mean" as "availability", "runtime_mean" as "runtime" ` +
        `FROM "vMonthlyPlantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' `
    } else {
      parameters += group
      lineParameters += group
    }

    dispatch(fetchAvailabilityIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters)
      const lineData = await APIInflux().request('/influx/query?q=' + lineParameters)

      return Promise.all([data, lineData]).then(values => {
        dispatch(fetchAvailabilityIsRunning(false))
        dispatch(fetchAvailabilitySuccess(values[0], values[1]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchAvailabilityIsRunning(false))
      dispatch(fetchAvailabilityHasError(true))

      throw  error
    }
  }
}

/* AVAILABILITY DETAILS */

export const fetchAvailabilityDetailsHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITYDETAILS_ERROR',
    hasErrored: bool,
  }
}

export const fetchAvailabilityDetailsIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITYDETAILS_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchAvailabilityDetailsSuccess = (data: object): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITYDETAILS_SUCCESS',
    data,
    hasErrored: false,
  }
}

export function fetchAvailabilityDetailsData() {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { group, start, end, days } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)

    let parameters =
      `SELECT max("tplwu1") as "Warm-up", max("tplrdy1") as "ready", max("tplon1") as "start" ` +
      `FROM "plantStateTime"` +
      `WHERE time >= '${start}' AND time < '${end}' ${group}`

    if (days > 205) {
      parameters =
        `SELECT "tplwu1_mean" as "Warm-up", "tplrdy1_mean" as "ready", "tplon1_mean" as "start" ` +
        `FROM "vMonthlyPlantStateTime"` +
        `WHERE time >= '${start}' AND time < '${end}' `
    }

    dispatch(fetchAvailabilityDetailsIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters)

      return Promise.all([data]).then(values => {
        dispatch(fetchAvailabilityDetailsIsRunning(false))
        dispatch(fetchAvailabilityDetailsSuccess(values[0]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchAvailabilityDetailsIsRunning(false))
      dispatch(fetchAvailabilityDetailsHasError(true))

      throw  error
    }
  }
}

/* AVAILABILITY DETAILS ALARMS*/

export const fetchAvailabilityDetailsAlarmsHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITYDETAILS_ALARMS_ERROR',
    hasErroredAlarms: bool,
  }
}

export const fetchAvailabilityDetailsAlarmsIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITYDETAILS_ALARMS_REQUEST',
    isLoadingAlarms: bool,
    hasErroredAlarms: false,
  }
}

export const fetchAvailabilityDetailsAlarmsSuccess = (data: object): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITYDETAILS_ALARMS_SUCCESS',
    dataAlarms: data,
    hasErroredAlarms: false,
  }
}

export function fetchAvailabilityDetailsAlarmsData(searchField: string[]) {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { group, start, end, days } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)


    const queryParams = searchField.map((s, index) => `max("${s}") as "${s}"${index < searchField.length - 1 ? ',' : ''}`).join(' ')
    consoleLog(queryParams)

    let parameters =
      `SELECT ${queryParams} ` +
      `FROM "alarmsDuration" ` + // @todo usare daily?
      `WHERE time >= '${start}' AND time < '${end}' ${group}`

    if (days > 205) {
      const queryParamsCondition = searchField.map((s, index) => `"${s}_mean" as "${s}"${index < searchField.length - 1 ? ',' : ''}`).join(' ')
      parameters =
        `SELECT ${queryParamsCondition} ` +
        `FROM "vMonthlyAlarms" ` +
        `WHERE time >= '${start}' AND time < '${end}' `
    }

    dispatch(fetchAvailabilityDetailsAlarmsIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters)

      return Promise.all([data]).then(values => {
        dispatch(fetchAvailabilityDetailsAlarmsIsRunning(false))
        dispatch(fetchAvailabilityDetailsAlarmsSuccess(values[0]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchAvailabilityDetailsAlarmsIsRunning(false))
      dispatch(fetchAvailabilityDetailsAlarmsHasError(true))

      throw  error
    }
  }
}

/* PERFORMANCE */

export const fetchPerformanceHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_PERFORMANCE_ERROR',
    hasErrored: bool,
  }
}

export const fetchPerformanceIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_PERFORMANCE_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchPerformanceSuccess = (data: object, lineData: object): AnyAction => {
  return {
    type: 'FETCH_PERFORMANCE_SUCCESS',
    data,
    lineData,
    hasErrored: false,
  }
}

export function fetchPerformanceData() {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { group, start, end, days } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)

    let parameters =
      `SELECT max("${mappedInfluxIDs.pieces}") as "pieces" ` +
      `FROM "vDailyProductionDetails" ` +
      `WHERE time >= '${start}' AND time < '${end}' ${group}`
    let lineParameters =
      `SELECT max("nperformance"), max("performance")  ` +
      `FROM "plantStateTime" ` +
      `WHERE time >= '${start}' AND time < '${end}' ${group}`

    if (days > 205) {
      parameters = `SELECT "${mappedInfluxIDs.pieces}_sum" as "pieces" ` +
        `FROM "vMonthlyProductionDetails" ` +
        `WHERE time >= '${start}' AND time < '${end}'`
      lineParameters =
        `SELECT "nperformance_mean" AS "performance", "performance_mean" AS "runtime" ` +
        `FROM "vMonthlyPlantStateTime" ` +
        `WHERE time >= '${start}' AND time < '${end}' `
    }
    dispatch(fetchPerformanceIsRunning(true))

    try {
      const data = await APIInflux().request('/influx/query?q=' + parameters)
      const lineData = await APIInflux().request('/influx/query?q=' + lineParameters)

      return Promise.all([data, lineData]).then(values => {
        dispatch(fetchPerformanceIsRunning(false))
        dispatch(fetchPerformanceSuccess(values[0], values[1]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchPerformanceIsRunning(false))
      dispatch(fetchPerformanceHasError(true))

      throw  error
    }
  }
}



/*               AVAILABILITY ALARMS                      */

export const fetchAvailabilityAlarmsHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITYALARMS_ERROR',
    hasErrored: bool,
  }
}

export const fetchAvailabilityAlarmsIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITYALARMS_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchAvailabilityAlarmsSuccess = (data: object): AnyAction => {
  return {
    type: 'FETCH_AVAILABILITYALARMS_SUCCESS',
    data,
    hasErrored: false,
  }
}

export function fetchAvailabilityAlarmsData() {
  return async (dispatch: Dispatch, getState: any) => {
    const { dateFilters } = getState()
    const { start, end } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)

    const parameters: string[] = []

    if (dateFilters.dateStart) {
      parameters.push('dateStart=' + start)
    }

    if (dateFilters.dateEnd) {
      parameters.push('dateEnd=' + end)
    }

    dispatch(fetchAvailabilityAlarmsIsRunning(true))

    try {
      const data = await APIProduction().request('/production/alarms' + (parameters ? '?' + parameters.join('&') : ''))

      return Promise.all([data]).then(values => {
        dispatch(fetchAvailabilityAlarmsIsRunning(false))
        dispatch(fetchAvailabilityAlarmsSuccess(values[0]))
        return values[0]
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(fetchAvailabilityAlarmsIsRunning(false))
      dispatch(fetchAvailabilityAlarmsHasError(true))

      throw error
    }
  }
}
