import * as React from 'react'
import {
  MaintenanceJobPeriodicity,
  MaintenanceJobType,
  MaintenanceScheduledJob,
  MaintenanceStatus,
  ModuleConfig,
} from '../types'
import i18n from 'i18next'
import IconComponent from '../../inplant-components-fe/ui/components/MVIcon/Icon'

export const oldPresentTarget = (
  scheduledJob: MaintenanceScheduledJob,
  callback: Function,
  showTargetNameOnNullTarget = false
) => {
  const path: String[] = []
  const targetName = scheduledJob.targetName ? scheduledJob.targetName : null

  if (scheduledJob.macroarea) {
    path.push(scheduledJob.macroarea.name)
  }

  if (scheduledJob.component) {
    path.push(scheduledJob.component.name)
  }
  const pathEl = path.length > 0 ? <small>({path.join(' > ')})</small> : null

  const clickHandler = () => {
    if (scheduledJob.target) {
      callback(scheduledJob.target.id)
    }
  }

  return (
    <React.Fragment>
      {scheduledJob.target ? (
        <a href={""} onClick={clickHandler}>
          {scheduledJob.target.name}
        </a>
      ) : showTargetNameOnNullTarget ? (
        targetName
      ) : null}{' '}
      {pathEl}
    </React.Fragment>
  )
}

export const presentTarget = (
  scheduledJob: MaintenanceScheduledJob,
  callback: Function,
  showTargetNameOnNullTarget = false
) => {
  const path: String[] = []
  const targetName = scheduledJob.targetName ? scheduledJob.targetName : null

  if (scheduledJob.macroarea) {
    path.push(scheduledJob.macroarea.name)
  }

  if (scheduledJob.section) {
    path.push(scheduledJob.section.name)
  }

  if (scheduledJob.component) {
    path.push(scheduledJob.component.name)
  }

  const clickHandler = () => {
    if (scheduledJob.target) {
      callback(scheduledJob.target.id)
    }
  }

  const pathArray = scheduledJob.target && path.slice(-1)[0] === scheduledJob.target.name ? path.slice(0, -1) : path

  return (
    <React.Fragment>
      {pathArray.join(' > ')} {'> '}
      {scheduledJob.target ? (
        <a href="" onClick={clickHandler}>
          {scheduledJob.target.name}
        </a>
      ) : showTargetNameOnNullTarget ? (
        targetName
      ) : null}
    </React.Fragment>
  )
}

export const presentTargetByPath = (
  pathObject: Array<{ id: string; label: string; level: string }>,
  scheduledJob: MaintenanceScheduledJob,
  callback: Function
) => {
  if (!pathObject) {
    return null
  }

  const path: String[] = []
  pathObject.forEach(node => {
    path.push(node.label)
  })

  const clickHandler = () => {
    if (scheduledJob.target) {
      callback(scheduledJob.target.id)
    }
  }

  return (
    <React.Fragment>
      {path.slice(0, -1).join(' > ')} {'> '}
      {
        <a href="" onClick={clickHandler}>
          {path.pop()}
        </a>
      }
    </React.Fragment>
  )
}

export const haveTargetHours = (scheduledJob: MaintenanceScheduledJob) => {
  const target = scheduledJob.target
  return target && target.usageHours && target.usageHoursDate
}

export const presentTargetHours = (scheduledJob: MaintenanceScheduledJob) => {
  const target = scheduledJob.target
  if (target && target.usageHours && target.usageHoursDate) {
    return (
      <React.Fragment>
        {target.usageHours}
        <small>updated to {target.usageHoursDate.format('YYYY-MM-DD HH:mm')}</small>
      </React.Fragment>
    )
  }
  return null
}

export const ReactTablePreviousButton = (props: any) => (
  <button type="button" {...props} className="-btn -btn-custom">
    <IconComponent icon={'chevron-left'} />
  </button>
)

export const ReactTableNextButton = (props: any) => (
  <button type="button" {...props} className="-btn -btn-custom">
    <IconComponent icon={'chevron-right'} />
  </button>
)

export const Loader = () => (
  <div key={Date.now()} className="col-lg text-center">
    <IconComponent icon={'circle-notch'} size="2x" spin={true} />
  </div>
)

export const Error = () => (
  <div key={Date.now()} className="col-lg text-center">
    <IconComponent icon={'exclamation-triangle'} size="2x" spin={false} color="red" />
  </div>
)

export const capitalizeFirstLetter = (value: string) => {
  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  return value
}

export const capitalizeName = (obj: any) => {
  obj.name = capitalizeFirstLetter(obj.name)
  return obj
}

export const getScheduleJobUIStatus = (status: MaintenanceStatus) => {
  if (status === MaintenanceStatus.DONE) {
    return 'done'
  } else if (status === MaintenanceStatus.REJECTED) {
    return 'rejected'
  } else if (status === MaintenanceStatus.OVERDUE) {
    return 'overdue'
  } else if (status === MaintenanceStatus.POSTPONED) {
    return 'postponed'
  } else if (status === MaintenanceStatus.PLANNED) {
    return 'planned'
  } else if (status === MaintenanceStatus.UNHANDLED) {
    return 'unhandled'
  }

  return 'todo'
}

export const presentJobType = (
  scheduledJob: MaintenanceScheduledJob,
  showMaintenanceInfos = true,
  showLabel = true
) => {
  const { jobType, maintenance } = scheduledJob
  const label = i18n.t(`maintenance.jobType.${scheduledJob.jobType}`)

  if (jobType === MaintenanceJobType.MAINTENANCE) {
    const chunks: string[] = []
    if (maintenance && maintenance.periodicity) {
      chunks.push(i18n.t('maintenance.periodicity.' + MaintenanceJobPeriodicity[maintenance.periodicity]))
    }
    if (maintenance && maintenance.totalHours) {
      chunks.push(`h ${maintenance.totalHours}`)
    }

    const maintenanceInfos = showMaintenanceInfos && chunks.length ? <small>{chunks.join(' / ')}</small> : null

    return (
      <React.Fragment>
        {/* <IconComponent icon={'sync-alt'} rotation={90} /> */}
        <IconComponent icon={'sync-alt'}  />
        {showLabel ? ' ' + label : null} {maintenanceInfos}
      </React.Fragment>
    )
  }
  return label
}

export const getDateLocale = (): string => {
  // return i18n.language ? i18n.language : 'en' // TODO i18n refactoring
  return 'en'
}

export const getDateFormat = (): string => {
  switch (getDateLocale()) {
    case 'en':
      return 'YYYY/MM/DD'

    case 'it':
      return 'DD/MM/YYYY'

    default:
      return 'YYYY/MM/DD'
  }
}

export const getDateFormatFns = (): string => {
  switch (getDateLocale()) {
    case 'en':
      return 'yyyy/MM/dd'

    case 'it':
      return 'dd/MM/yyyy'

    default:
      return 'yyyy/MM/dd'
  }
}

export const trunc = (value: string, limit: number = 50) => {
  return value.length > limit ? value.substr(0, limit - 1) + ' ...' : value
}

export const getConfigParam = (config: ModuleConfig, key: string, defaultValue: any) => {
  return config.maintenance && config.maintenance[key] ? config.maintenance[key] : defaultValue
}

export const showScheduledJobCol = (config: ModuleConfig, colName: string): boolean => {
  const hiddenCols =
    config.maintenance && config.maintenance.scheduledJobsHiddenCols ? config.maintenance.scheduledJobsHiddenCols : []
  return !hiddenCols.includes(colName)
}

export const presentStatusBadge = (scheduledJob: MaintenanceScheduledJob) => {
  return (
    <span className="badge badge--status" style={{ backgroundColor: scheduledJob.color, color: '#FFF' }}>
      {i18n.t(`maintenance.status.${getScheduleJobUIStatus(scheduledJob.status)}`)}
    </span>
  )
}

export const findNodeByID = (id: string, currentNode: any): any | false => {
  let i
  let currentChild
  let result

  if (id === currentNode.data.id) {
    return currentNode
  } else {
    if (currentNode.children) {
      for (i = 0; i < currentNode.children.filter((e: any) => e).length; i += 1) {
        currentChild = currentNode.children.filter((e: any) => e)[i]

        result = findNodeByID(id, currentChild)
        if (result !== false) {
          return result
        }
      }
    }

    return false
  }
}
