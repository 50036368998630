const en = {
  i4environment: {
    module: 'Dashboard',
    carbonFootprint: 'Carbon Footprint',
    averageDailyConsumption: 'AVERAGE DAILY CONSUMPTION',
    fetch: {
      noData: 'No data available in the selected time range',
      noDataMdl: 'Materials configuration missing',
      fetching: 'Loading...',
      error: 'Error loading data',
    },
    tabs: {
      general: 'Chemicals and painting',
      plant: 'General',
      pretreatment: 'Pretreatment',
      electrophoresis: 'Electrophoresis',
      treatment: 'Treatment',
      cataphoresis: 'Cataphoresis',
      ovens: 'Ovens',
      booths: 'Booths',
      transport: 'Transport',
      auxiliary: 'Auxiliary',
      manualDataLoading: 'Manual data loading',
      power: 'Power',
      air: 'Air',
      gas: 'Gas',
    },
    metrics: {
      ActivePower: 'Electric Power',
      CarbonFootprint: 'Carbon Footprint',
      ComprimedAir: 'Compressed Air',
      RefillWater: 'Industrial Water',
      ThermalEnergy: 'Thermal Energy',
      RawWater: 'Raw Water',
      DemiWater: 'Demi Water',
      CataOvenActivePower: 'Cata Oven Active Power',
      PowderOvenActivePower: 'Powder Oven Active Power',
      PostBurnerActivePower: 'Post Burner Active Power',
      CataOvenGas: 'Cata Oven Gas',
      PowderOvenGas: 'Powder Oven Gas',
      PostBurnerGas: 'Post Burner Gas',
      runTimeOperatingTime: 'Run / Operating Time',
    },
    sections: {
      media: 'Media',
      chemicals_and_painting: 'Chemicals and painting',
    },
    modal: {
      cancel: 'Cancel',
      select: 'Submit',
      open: 'Manual Data Loading',
      title: 'Manual Data Loading',
      titleLoadMaterial: 'Load material',
      fields: {
        name: 'Name',
        quantityLiteral: 'Quantity',
        data: 'Date',
        type: {
          label: 'Select Type',
          value: {
            paste: 'Paste',
            resin: 'Resin',
            paint: 'Paint',
          },
        },
        qta: {
          label: 'Qta',
        },
        date: {
          label: 'Date',
        },
      },
    },
    download: {
      buttonLabel: 'DOWNLOAD',
    },
    consumptionWidget: {
      button: {
        label: 'Manual Data Loading',
      },
    },
    actions: {
      cancel: 'Cancel',
      save: 'Save',
    },
  },
}

export default en
