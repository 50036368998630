// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Button from "./Button";

interface Props {
  label: string
  onClick: () => void
  isLoading: boolean
  disabled: boolean
}

class DownloadCsvButton extends React.Component<Props>{
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <Button
        variant="secondary"
        {...this.props}
        icon={"file-excel"}
      />
    );
  }
}

export default DownloadCsvButton