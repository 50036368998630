import { Template } from '../types'
import { ComponentGroup } from './componentGroup'
import { Component } from './component'
import { Metric } from './metric'
import { Section } from './section'

export const SectionGroup: Template = {
  id: 'sectiongroup',
  name: 'SectionGroup',
  version: '0.1.0',
  date: '2019-02-21',
  fields: [
    {
      name: 'label',
      type: 'string',
      label: 'sectionGroup.label',
      width: 12,
      required: true,
      nodePath: 'data',
      translatable: true,
    },
    {
      name: 'pid',
      type: 'string',
      label: 'sectionGroup.pid',
      width: 4,
      nodePath: 'data',
      relationships: [{
        target: 'inUse',
        condition: 'required',
        targetValue: true
      }]
    },
    {
      type: 'space',
      name: '',
      label: '',
      width: 12
    },
    {
      name: 'hasQRCode',
      type: 'boolean',
      label: 'sectionGroup.hasQRCode',
      width: 4,
      nodePath: 'data',
      values: true
    },
    {
      name: 'inUse',
      type: 'boolean',
      label: 'sectionGroup.nodeIsActive',
      width: 4,
      nodePath: 'data',
      values: false,
      derivedValue: {
        condition: (targetValue, currentFieldValue) => {
          return (typeof targetValue !== 'undefined' && targetValue !== '') ? currentFieldValue : false
        },
        target: 'pid'
      }
    },
  ],
  copyable: true,
  allowedSubcomponents: [Section, ComponentGroup, Component, Metric],
}
