const it = {
  i4efficiency: {
    module: {
      "overview": "Overview",
      "oee-details": "Details",
      "availability-details": "Availability details",
    },
    download: {
      buttonLabel: 'DOWNLOAD',
      buttonLabelData: 'Dati',
      buttonLabelAlarms: 'Allarmi',
    },
    fetch: {
      noData: 'Dati non disponibili',
    },
    cardTitle: {
      avgDailyConsumption: 'CONSUMO MEDIO GIORNALIERO',
      avaibility: 'DISPONIBILITA\'',
      runTimeOperatingTime: 'TEMPO DI ESECUZIONE / TEMPO DI FUNZIONAMENTO',
      oee: 'OEE',
      quality: 'QUALITA\''
    },
    quality: {
      scraps: {
        time: 'time',
        quality: 'Quality',
        Scrap1: 'Neri',
        Scrap2: 'Con segni',
        Scrap3: 'Alette rotte',
        Scrap4: 'Marci',
        Scrap5: 'Sporchi anaf.',
        Scrap6: 'Bolle scoppiate',
        pl: 'Loaded Items',
      },
      total: 'Totale scarti',
    },
    labels: {
      qta: 'Qta',
      min: 'min',
    },
    performance: {
      performance: 'Performance',
      pieces: 'Pezzi'
    },
    modal: {
      labels: {
        close: 'Chiudi'
      },
      alarms: 'ALLARMI'
    }
  },
}

export default it
