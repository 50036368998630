import { AnyAction, Dispatch } from 'redux'
import * as moment from 'moment'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import {
  DateRange,
  getDateRangeFiltering,
  getDatesFromRange,
  getDaysDiff,
} from '@mv-submodules/inplant-core-fe/functions/date'
import { ManualDataLoading, ManualDataLoadingConsumes } from '@mv-submodules/inplant-environment-fe-imel/redux/types'
import { API_BASE_URL, API_USERNAME, API_PASSWORD } from '@mv-submodules/inplant-environment-fe-imel/types/costants'

export const APIInflux = () => FetchWrapper.getInstance('influx')
export const APICost = () => FetchWrapper.getInstance('cost')

export const fetchI4EnvironmentHasError = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_I4ENVIRONMENT_ERROR',
    hasErrored: bool,
  }
}

export const fetchI4EnvironmentIsRunning = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_I4ENVIRONMENT_REQUEST',
    isLoading: bool,
    hasErrored: false,
  }
}

export const fetchI4EnvironmentSuccess = (data: object): AnyAction => {
  return {
    type: 'FETCH_I4ENVIRONMENT_SUCCESS',
    data,
    hasErrored: false,
  }
}

export const graphI4EnvironmentIsInitialized = (bool: boolean): AnyAction => {
  return {
    type: 'GRAPH_I4ENVIRONMENT_INITIALIZED',
    graphInitialized: bool,
  }
}

export function fetchI4EnvironmentData() {
  const headers = new Headers()
  headers.append('Authorization', 'Basic ' + btoa(API_USERNAME + ':' + API_PASSWORD))

  return (dispatch: Dispatch<AnyAction>, getState: any) => {
    const { dateFilters } = getState()
    const dates: DateRange = getDatesFromRange(dateFilters.range)

    const startDate = dates
      ? dates.dateStart
      : moment()
          .subtract(7, 'day')
          .format('YYYY-MM-DD HH:mm:ss')
          .toString()
    const endDate = dates
      ? dates.dateEnd
      : moment()
          .format('YYYY-MM-DD HH:mm:ss')
          .toString()
    let parameters =
      `SELECT mean("ActivePower") as "ActivePower", ` +
      `mean("ComprimedAir") as "CompressedAir", mean("Gas") as "Gas", ` +
      `mean("RefillWater") as "RefillWater", mean("ThermalEnergy") as "ThermalEnergy" ` +
      `FROM "imeldemofiera"."autogen"."imel-demo-expo+consumption" ` +
      `WHERE time >= '${startDate}' AND time < '${endDate}'`
    let url

    switch (dateFilters.range) {
      case 'dateRange7Days':
      case 'dateRangeLastWeek':
        parameters += 'group by time(1d)'
        break

      case 'dateRange30Days':
      case 'dateRangeLastMonth':
        parameters += 'group by time(1d)'
        break

      case 'dateRange6Months':
        parameters += 'group by time(1d)'
        break

      case 'dateRangeLastYear':
      case 'dateRangeYear':
        parameters += 'group by time(30d)'
        break

      default:
        parameters += 'group by time(1d)'
        break
    }

    url = API_BASE_URL + encodeURIComponent(parameters)

    dispatch(fetchI4EnvironmentIsRunning(true))
    fetch(url, { headers })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        dispatch(fetchI4EnvironmentIsRunning(false))
        return response
      })
      .then(response => response.json())
      .then(items => {
        dispatch(fetchI4EnvironmentSuccess(items))
      })
      .catch(e => {
        console.log(e) //tslint:disable-line
        dispatch(fetchI4EnvironmentHasError(true))
      })
  }
}

export function fetchGraphData(measures: string[], labels: string[], grouped: boolean = true) {
  const headers = new Headers()
  headers.append('Authorization', 'Basic ' + btoa(API_USERNAME + ':' + API_PASSWORD))

  return (dispatch: Dispatch<AnyAction>, getState: any) => {
    const { dateFilters } = getState()
    // const dates: DateRange = getDatesFromRange(dateFilters.range)
    const result = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)
    let group = result.group
    const {start, end} = result
    /*    switch (dateFilters.range) {
          case 'dateRange7Days':
          case 'dateRangeLastWeek':
            group = 'group by time(1d)'
            break

          case 'dateRange30Days':
          case 'dateRangeLastMonth':
            group = 'group by time(1d)'
            break

          case 'dateRange6Months':
            group = 'group by time(1d)'
            break

          case 'dateRangeLastYear':
          case 'dateRangeYear':
            group = 'group by time(30d, ' + new Date().getDate() + 'd)'
            break

          default:
            group = 'group by time(1d)'
            break
        }*/

    const queries: string[] = []

    const days = getDaysDiff(moment(dateFilters.dateStart), moment(dateFilters.dateEnd))

    measures.map((measure: string, index: number) => {
      if (days === 1) {
        group = ''
        queries.push(`"` + measure + `" as "` + labels[index] + `"`)
      } else {
        queries.push(`mean("` + measure + `") as "` + labels[index] + `"`)
      }
    })

    let db = 'globalConsumptions'
    if (days === 1) {
      db += '_hourly'
    }

    const query =
      `SELECT ` +
      queries.join(',') +
      ` FROM ${db} ` +
      ` WHERE time >= '${start}' AND time < '${end}' ` +
      (grouped ? group : '')

    return Promise.all([APIInflux().request('/influx/query?q=' + query)])
      .then((data: any) => {
        return data
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
      })
  }
}

export const fetchManualDataLoadingData = () => {
  return (dispatch: Dispatch<AnyAction>, getState: any) => {
    const { dateFilters } = getState()
    const { group, start, end } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)
    const year = moment(start).get('year')
    return APICost()
      .request(`/manual-data-loading/year/${year}`, undefined, undefined, true)
      .then((mdl: ManualDataLoading[]) => {
        return Promise.all(
          mdl.reduce((acc, curr) => {
            acc.push(
              APIInflux().request(
                `/influx/query?q=SELECT sum(measure) FROM ${curr.influxMeasureId} WHERE time >= '${start}' AND time < '${end}' ${group} fill(0)`,
                undefined,
                undefined,
                true
              )
            )
            return acc
          }, [] as any)
        )
          .then((data: any) => {
            return data.reduce((acc: ManualDataLoadingConsumes[], curr: any, index: number) => {
              const series = getColumnsAndValuesFromInfluxSeries(curr)
              const columns = series && series.columns
              const values = series && series.values
              if (columns && values) {
                acc = [
                  ...acc,
                  ...values.reduce((mdls: ManualDataLoadingConsumes[], measure: any) => {
                    const measureValue = measure[columns.indexOf('sum')]
                    const timeValue = measure[columns.indexOf('time')]
                    if (measureValue !== undefined && timeValue !== undefined) {
                      mdls.push(new ManualDataLoadingConsumes(mdl[index], timeValue, measureValue))
                    }
                    return mdls
                  }, []),
                ]
              }
              return acc
            }, [])
          })
          .catch((error: any) => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              dispatch(logoutUser())
            }
          })
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
      })
  }
}

const getColumnsAndValuesFromInfluxSeries = (measure: any) => {
  const valid =
    measure &&
    measure.results &&
    measure.results.length > 0 &&
    measure.results[0].series &&
    measure.results[0].series.length > 0 &&
    measure.results[0].series[0]
  if (!valid) {
    return false
  }
  const values =
    (measure.results[0].series[0].values &&
      measure.results[0].series[0].values.length > 0 &&
      measure.results[0].series[0].values) ||
    []
  const columns =
    (measure.results[0].series[0].columns &&
      measure.results[0].series[0].columns.length > 0 &&
      measure.results[0].series[0].columns) ||
    []
  if (!columns || !values) {
    return false
  }
  return {
    columns,
    values,
  }
}

export const prepareDataForManualDataLoadingGraph = (mdl: ManualDataLoadingConsumes[]) => {
  const fallBackColors = [
    '#e6194b',
    '#3cb44b',
    '#ffe119',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#bcf60c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#808080',
    '#ffffff',
  ]
  const uniqueTimes = mdl.map(item => item.timeString).filter((value, index, self) => self.indexOf(value) === index)
  const uniqueMeasureId = mdl
    .map(item => item.influxMeasureId)
    .filter((value, index, self) => self.indexOf(value) === index)
  const data: any[] = []
  const bars: {} = {}
  uniqueTimes.forEach((time: string) => {
    const values = mdl
      .filter((measure: ManualDataLoadingConsumes) => measure.timeString === time)
      .reduce((acc, measure) => {
        acc[measure.influxMeasureId] = measure.consumption
        if (!bars[measure.influxMeasureId]) {
          bars[measure.influxMeasureId] = {
            name: measure.name,
            color: fallBackColors[uniqueMeasureId.indexOf(measure.influxMeasureId)],
            um: measure.um,
          }
        }
        return acc
      }, {})
    data.push({
      time,
      ...values,
    })
  })
  return {
    data,
    bars,
  }
}
