// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import BackButton from '../Button/BackButton'
import Heading from "../Typogaphy/Heading";
import '../../../styles/_index.scss'
import SubtitleButtonGroups, {SubtitleButtonGroupsConfig} from "./SubtitleButtonGroups";

interface Props {
  title: string | null
  subtitle?: React.ReactNode
  backButton?: true
  backButtonOnClick?: () => void
  backUrl?: string
  leftButtons?: React.ReactNode
  rightButtons?: React.ReactNode
  subtitleButtons?: SubtitleButtonGroupsConfig[]
  ref?: React.RefObject<any>
  isMobile?: boolean
}

class PageHeader extends React.Component<Props>{
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {props} = this
    return (
        <header className="page-header" ref={props.ref || ''}>
          <div className={"page-header-container d-flex justify-content-between align-items-start"}>
            <div className="fullwidth">
              <div className={"page-header-top d-flex justify-content-start align-items-center" + (props.isMobile && !props.leftButtons && " flex-row no-buttons" || "")}>
                {(props.backButton || props.leftButtons) && (
                  <div className="page-header-top-buttons mr-3">
                    {props.backButton && <BackButton backUrl={props.backUrl} onClick={props.backButtonOnClick}/>}
                    {props.leftButtons}
                  </div>
                )}
                <hgroup>
                  <Heading type="title" label={props.title} />
                  {props.subtitle && (
                    <small className="text-muted">{props.subtitle}</small>
                  )}
                </hgroup>
              </div>
              {props.subtitleButtons && (
                <SubtitleButtonGroups buttons={props.subtitleButtons} />
              )}
            </div>
            {props.rightButtons && <div className='page-header-bottom-buttons'>{props.rightButtons}</div>}
          </div>
        </header>
    )
  }
}


export default PageHeader
