import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  MaintenancePage,
  MaintenancePlanPdfFilterParam,
  MaintenancePlanPdfParams,
  MaintenanceScheduledJob,
  MaintenanceStatus, MaintenanceType,
} from '../../../../types'
import { setPageFilterSelectedValues } from '../../../../redux/actions'
import PageFiltersHelper from './PageFiltersHelper'
import {
  capitalizeFirstLetter,
  findNodeByID,
  getConfigParam,
  getDateFormat,
  getScheduleJobUIStatus,
  presentJobType,
  presentStatusBadge,
} from '../../../../functions/shared'
import ScheduledJobAddModalComponent from '../ScheduledJobAddModalComponent/ScheduledJobAddModalComponent'
import ScheduledJobModalComponent from '../ScheduledJobModalComponent/ScheduledJobModalComponent'
import ActionButtons from './ActionButtons'
import PrintPlan from './PrintPlan'
import { Option } from '../MultiselectComponent/Item'
import NotesTooltip from './NotesTooltip'
import { isJSON } from '@mv-submodules/inplant-core-fe/functions'
import {
  HtmlTooltip,
  PageHeader,
  Table,
  MainPageContent,
  ButtonGroupHeader,
  Button,
  Filters,
} from '@mv-submodules/inplant-components-fe'
import * as moment from 'moment'
import { fetchScheduledJobs } from '@mv-submodules/inplant-maintenance-fe/redux/actions/scheduledJob'
import { fetchPlantStatus } from '@mv-submodules/inplant-maintenance-fe/redux/actions/plant'
import { fetchMaintenanceTypes } from '@mv-submodules/inplant-maintenance-fe/redux/actions/types'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import { MaintenanceClientFiltersParams, MaintenancePageFilters } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface StateProps {
  config: any
  smartdocsExists: boolean
}

interface OwnProps extends RouteComponentProps<any> {
  pageConf: MaintenancePage
  pageFilters: MaintenancePageFilters[]
  // t: TranslationFunction
}

interface DispatchProps {
  fetchScheduledJobs: (
    pageSlug?: string | null,
    clientFilters?: MaintenanceClientFiltersParams | null,
  ) => Promise<MaintenanceScheduledJob[]>
  setPageFilterSelectedValues: (slug: string, name: string, selected: null | string[]) => void,
  fetchPlantStatus: () => Promise<any>,
  fetchMaintenanceTypes: () => Promise<any>
}

interface OwnStateProps {
  activeScheduledJob: null | MaintenanceScheduledJob
  selectedScheduledJobs: MaintenanceScheduledJob[]
  selectAll: number
  showNewScheduledJobModal: boolean
  data: MaintenanceScheduledJob[]
  plantStatus: any
  maintenanceTypes: MaintenanceType[]
  isFetching: boolean,
  fetchErrors: boolean
}

const mapStateToProps = (state: any) => {
  return {
    config: state.config,
    smartdocsExists: state.hasOwnProperty('smartdocs'),
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchScheduledJobs: (pageSlug?: string | null, clientFilters?: MaintenanceClientFiltersParams | null) =>
    dispatch(fetchScheduledJobs(pageSlug, clientFilters)),
  setPageFilterSelectedValues: (slug: string, name: string, selected: null | string[]) =>
    dispatch(setPageFilterSelectedValues(slug, name, selected)),
  fetchPlantStatus: () => dispatch(fetchPlantStatus()),
  fetchMaintenanceTypes: () => dispatch(fetchMaintenanceTypes()),
})

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

export class Plan extends React.Component<Props, OwnStateProps> {
  private tableRef: any

  constructor(props: Props) {
    super(props)
    this.state = {
      activeScheduledJob: null,
      selectedScheduledJobs: [],
      selectAll: 0,
      showNewScheduledJobModal: false,
      data: [],
      maintenanceTypes: [],
      fetchErrors: false,
      isFetching: false,
      plantStatus: undefined,
    }

    this.tableRef = React.createRef()

    // bindings
    this.applyClientFilters = this.applyClientFilters.bind(this)
    this.handleOpenNewScheduledJobModal = this.handleOpenNewScheduledJobModal.bind(this)
    this.handleCloseNewScheduledJobModal = this.handleCloseNewScheduledJobModal.bind(this)

    this.handleCloseActionModal = this.handleCloseActionModal.bind(this)

    this.handleOpenEditScheduledJobModal = this.handleOpenEditScheduledJobModal.bind(this)
    this.handleCloseEditScheduledJobModal = this.handleCloseEditScheduledJobModal.bind(this)

    this.getTableTdProps = this.getTableTdProps.bind(this)
    this.disableToggleAll = this.disableToggleAll.bind(this)

    this.getTableFilterComponent = this.getTableFilterComponent.bind(this)
    this.getPdfParams = this.getPdfParams.bind(this)
    this.getSelectedFilterOptionName = this.getSelectedFilterOptionName.bind(this)
  }

  public componentDidMount() {
    this.fetchData()
  }

  public toggleRow(scheduledJob: MaintenanceScheduledJob) {
    const selection = this.state.selectedScheduledJobs.slice(0)
    if (this.isSelected(scheduledJob)) {
      const removeIndex = selection.map(item => item.id).indexOf(scheduledJob.id)
      selection.splice(removeIndex, 1)
    } else {
      selection.push(scheduledJob)
    }
    this.setState({
      selectedScheduledJobs: selection,
      selectAll: selection.length > 0 ? 2 : 0,
    })
  }

  public isSelected(scheduledJob: MaintenanceScheduledJob) {
    return this.state.selectedScheduledJobs.some((item: MaintenanceScheduledJob) => item.id === scheduledJob.id)
  }

  public isSelectable(scheduledJob: MaintenanceScheduledJob) {
    return scheduledJob.status !== MaintenanceStatus.DONE && scheduledJob.status !== MaintenanceStatus.REJECTED
  }

  public render() {
    const { pageConf, config } = this.props

    const overdueAnomaliesDefaultSort = getConfigParam(config, 'sortOverdueAnomalies', false)

    const disabledActions = isJSON(this.props.config.maintenance.disabledActions) ? JSON.parse(this.props.config.maintenance.disabledActions) : null
    const pageDisabledActions = disabledActions ? disabledActions[this.getPageSlug()] : null
    const hiddenCols = this.props.pageConf.hiddenCols
    const colums = this.getColums(hiddenCols, pageDisabledActions)
    const pageFilters = this.props.pageFilters[this.props.pageConf.slug]

    const filters: FilterComponent[] = pageFilters ? [
      ...((hiddenCols === null || !hiddenCols.includes('macroarea')) && PageFiltersHelper.haveClientFilter(pageFilters, 'macroarea') ?
          this.getTableFilterComponent(pageFilters, 'macroarea') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('pid')) && PageFiltersHelper.haveClientFilter(pageFilters, 'component') ?
          this.getTableFilterComponent(pageFilters, 'component') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('target')) && PageFiltersHelper.haveClientFilter(pageFilters, 'target') ?
          this.getTableFilterComponent(pageFilters, 'target') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('operation')) && PageFiltersHelper.haveClientFilter(pageFilters, 'operation') ?
          this.getTableFilterComponent(pageFilters, 'operation') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('type')) && PageFiltersHelper.haveClientFilter(pageFilters, 'type') ?
          this.getTableFilterComponent(pageFilters, 'type') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('dueDate')) && PageFiltersHelper.haveClientFilter(pageFilters, 'dueDate') ?
          this.getTableFilterComponent(pageFilters, 'dueDate') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('plannedDate')) && PageFiltersHelper.haveClientFilter(pageFilters, 'plannedDate') ?
          this.getTableFilterComponent(pageFilters, 'plannedDate') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('creationDate')) && PageFiltersHelper.haveClientFilter(pageFilters, 'creationDate') ?
          this.getTableFilterComponent(pageFilters, 'creationDate') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('status')) && PageFiltersHelper.haveClientFilter(pageFilters, 'status') ?
          this.getTableFilterComponent(pageFilters, 'status') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('jobType')) && PageFiltersHelper.haveClientFilter(pageFilters, 'jobType') ?
          this.getTableFilterComponent(pageFilters, 'jobType') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('acknowledged')) && PageFiltersHelper.haveClientFilter(pageFilters, 'acknowledged') ?
          this.getTableFilterComponent(pageFilters, 'acknowledged') : []
      ),
    ] : []

    return (
      <div className="inplant-maintenance-fe">
        <PageHeader
          title={this.props.pageConf.name}
          subtitle={this.props.pageConf.description || undefined}
          rightButtons={(
            <ButtonGroupHeader>
              {(!pageDisabledActions || !pageDisabledActions.includes('printButton')) && (
                <PrintPlan
                  pageSlug={this.props.pageConf.slug}
                  disabled={this.state.isFetching}
                  label={this.props.t('maintenance.plan.print')}
                  getParams={this.getPdfParams}
                />
              )}
              {(!pageDisabledActions || !pageDisabledActions.includes('newButton')) && (
                <Button variant="primary" onClick={this.handleOpenNewScheduledJobModal}
                        label={this.props.t('maintenance.plan.new')}/>
              )}
            </ButtonGroupHeader>
          )}
        />

        <MainPageContent
          error={this.state.fetchErrors && this.props.t('maintenance.errors.somethingGoneWrong') || undefined}>
          {!this.state.fetchErrors && (
            <>
              <Filters fields={filters}/>
              <Table
                refElement={this.tableRef}
                columns={colums}
                isFetching={this.state.isFetching}
                data={this.applyClientFilters(this.state.data)}
                defaultSorted={pageConf.slug === 'overdueAnomalies' && overdueAnomaliesDefaultSort ? [{
                  id: overdueAnomaliesDefaultSort,
                  desc: true,
                }] : [{ id: 'plannedDate', desc: false }]}
                manualIsFetching={true}
                leftComponent={(!pageDisabledActions || !pageDisabledActions.includes('actions')) ? (
                  <ActionButtons
                    label="right"
                    scheduledJobs={this.applyClientFilters(this.state.data)}
                    selection={this.state.selectedScheduledJobs}
                    onClose={this.handleCloseActionModal}
                  />
                ) : undefined}
                showPaginationTop={true}
                noDataText={this.props.t('maintenance.table.noData')}
                pageText={this.props.t('maintenance.table.page')}
                ofText={this.props.t('maintenance.table.of')}
                rowsText={this.props.t('maintenance.table.rows')}
              />
            </>
          )}
        </MainPageContent>

        {this.state.showNewScheduledJobModal && (
          <ScheduledJobAddModalComponent
            isVisible={this.state.showNewScheduledJobModal}
            onClose={this.handleCloseNewScheduledJobModal}
            plantStatus={this.state.plantStatus}
            maintenanceTypes={this.state.maintenanceTypes}
          />
        )}

        {this.state.activeScheduledJob !== null && (
          <ScheduledJobModalComponent
            isVisible={true}
            scheduledJob={this.state.activeScheduledJob}
            onClose={this.handleCloseEditScheduledJobModal}
            plantStatus={this.state.plantStatus}
          />
        )}
      </div>
    )
  }


  private getPageSlug() {
    return this.props.pageConf.slug
  }

  private fetchData() {
    if (!this.state.isFetching) {
      this.setState({
        isFetching: true,
        fetchErrors: false,
      })
      Promise.all([
        this.props.fetchScheduledJobs(this.props.pageConf.slug),
        this.props.fetchPlantStatus(),
        this.props.fetchMaintenanceTypes(),
      ])
        .then(([data, plantStatus, maintenanceTypes]) => {
          this.setState({ data, plantStatus, maintenanceTypes })
        })
        .catch((error: any) => {
          this.setState({ fetchErrors: true })
        })
        .finally(() => {
          this.setState({ isFetching: false })
        })
    }
  }

  private handleOpenNewScheduledJobModal() {
    this.setState({ showNewScheduledJobModal: true })
  }

  private handleCloseNewScheduledJobModal(reload?: boolean) {
    this.setState({ showNewScheduledJobModal: false })
    if (reload) {
      this.fetchData()
    }
  }

  private handleCloseActionModal(reload?: boolean) {
    if (reload) {
      this.fetchData()
    }
  }

  private handleOpenEditScheduledJobModal(scheduledJob: MaintenanceScheduledJob) {
    this.setState({ activeScheduledJob: scheduledJob })
  }

  private handleCloseEditScheduledJobModal(reload?: boolean) {
    this.setState({ activeScheduledJob: null })
    if (reload) {
      this.fetchData()
    }
  }

  private getVisibleRows(data: MaintenanceScheduledJob[], page: number, pageSize: number) {
    return data.slice(page * pageSize, (page + 1) * pageSize)
  }

  private toggleSelectAll() {
    let selection: MaintenanceScheduledJob[] = []
    const current = this.tableRef.current as any

    if (current && this.state.selectAll === 0) {
      const tableState = current.getResolvedState()

      const visibleRows = this.getVisibleRows(
        tableState.sortedData.map((item: any) => {
          return item._original
        }),
        tableState.page,
        tableState.pageSize,
      )

      selection = visibleRows.filter((item: MaintenanceScheduledJob) => {
        return this.isSelectable(item)
      })
    }

    this.setState({
      selectedScheduledJobs: selection,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    })
  }

  private disableToggleAll() {
    return !this.applyClientFilters(this.state.data).some((item: MaintenanceScheduledJob) => {
      return this.isSelectable(item)
    })
  }

  private applyClientFilters(data: MaintenanceScheduledJob[]) {
    const pageFilters: MaintenancePageFilters[] = this.props.pageFilters[this.props.pageConf.slug]
    const beforeProcessValueCallback = (key: string, value: any): any => {
      if (key !== 'status') {
        return value
      }
      return value === MaintenanceStatus.PLANNED || value === MaintenanceStatus.POSTPONED ? 'todo' : value
    }
    return PageFiltersHelper.applyClientFilters(
      pageFilters,
      data,
      beforeProcessValueCallback,
    ) as MaintenanceScheduledJob[]
  }

  private getSelectedFilterOptionName(filter: MaintenancePageFilters): string[] {
    const { values, selected } = filter
    const filterOptionName: string[] = []
    if (filter.inputType === 'checkbox') {
      if (selected && values) {
        values.forEach(item => {
          if (item.name && item.value && selected.includes(item.value)) {
            filterOptionName.push(item.name)
          }
        })
      }
    } else if (filter.inputType === 'dateRangePicker') {
      if (selected) {
        selected.forEach(item => {
          filterOptionName.push(item)
        })
      }
    }

    return filterOptionName
  }

  private getPdfParams() {
    const pageFilters: MaintenancePageFilters[] = this.props.pageFilters[this.props.pageConf.slug]
    const current = this.tableRef.current as any
    let params: MaintenancePlanPdfParams = { targetIds: [] }

    if (current) {
      const printFilters: MaintenancePlanPdfFilterParam[] = []

      const rows = current.getResolvedState().sortedData

      pageFilters.forEach((filter: MaintenancePageFilters) => {
        const { name } = filter
        const nameParam = this.props.t(`maintenance.plan.tableCols.${name}`)
        const valueParam = this.getSelectedFilterOptionName(filter).join(' - ')
        printFilters.push({ name: nameParam, value: valueParam })
      })

      params = {
        targetIds:
          this.state.selectedScheduledJobs.length > 0
            ? this.state.selectedScheduledJobs.map((row: any) => row.id)
            : rows.map((row: any) => row._original.id),
        filters: printFilters,
      } as MaintenancePlanPdfParams
    }

    return params
  }

  private getTableTdProps(state: any, rowInfo: any, column: any) {
    return column.id === 'notes' ? { style: { overflow: 'visible' } } : {}
  }

  private getTableFilterComponent(pageFilters: MaintenancePageFilters[], name: string): FilterComponent[] {
    const index = pageFilters.findIndex((item: MaintenancePageFilters) => {
      return item.name === name
    })

    if (index === -1) {
      return []
    }

    const pageFilter = pageFilters[index]
    let values = index > -1 ? (pageFilter.values !== null ? pageFilter.values : []) : []
    const selected = index > -1 ? (pageFilter.selected !== null ? pageFilter.selected : []) : []
    const inputType = pageFilter.inputType

    if (name === 'status') {
      values = values.map((item: Option) => {
        item.name = this.props.t(`maintenance.status.${getScheduleJobUIStatus(item.value as MaintenanceStatus)}`)
        return item
      })
    }

    if (inputType === 'checkbox') {
      return [{
        type: 'MultiSelect',
        value: null,
        options: {
          items: values.map(o => ({
            label: o.name as string,
            value: o.value as string,
          })),
        },
        id: `multi_select_${name}`,
        label: this.props.t(`maintenance.plan.tableCols.${name}`),
        allSelectedLabel: this.props.t('maintenance.filters.allSelectedText'),
        headingLabel: this.props.t('maintenance.filters.headingText'),
        selectAllLabel: this.props.t('maintenance.filters.selectAllText'),
        selectedValues: selected as string[],
        onClickSelectAll: () => {
          if (selected.length === values.length) {
            this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, [])
          } else {
            this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, values.map(o => o.value as string))
          }
        },
        onClickItem: (value) => {
          if (selected.includes(value)) {
            this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, selected.filter(s => s !== value))
          } else {
            selected.push(value)
            this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, selected)
          }
        },
      }]
    } else if (inputType === 'dateRangePicker') {
      return [{
        type: 'DateRange',
        value: null,
        label: this.props.t(`maintenance.plan.tableCols.${name}`),
        selectedValues: selected || [],
        id: `date_range_${name}`,
        onDateSelectedChange: s => this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, s),
      }]
      /* return (
         <DateRangePickerComponent
           selected={selected as string[]}
           onSelectedChanged={(filters: string[]) => {
             this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, filters)
           }}
         />
       ) */
    }

    return []
  }

  private getNodeDataByPath(path: string) {
    if (this.state.plantStatus && path) {
      return findNodeByID(path, this.state.plantStatus)
    }

    return null
  }

  private getColums(hiddenCols: string[] | null, disabledActions: string[]) {
    const cols: any[] = [] // TODO refactor
    const { config } = this.props

    if (hiddenCols === null) {
      hiddenCols = []
    }

    if ((!disabledActions || !disabledActions.includes('actions'))) {
      cols.push({
        id: 'checkbox',
        accessor: '',
        Header: (x: any) => {
          return (
            <input
              type="checkbox"
              disabled={this.disableToggleAll()}
              checked={this.state.selectAll === 1}
              ref={input => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2
                }
              }}
              onChange={() => this.toggleSelectAll()}
            />
          )
        },
        Cell: (row: any) => {
          const scheduledJob = row.original as MaintenanceScheduledJob
          return this.isSelectable(scheduledJob) ? (
            <input
              type="checkbox"
              checked={this.isSelected(scheduledJob)}
              onChange={() => {
                this.toggleRow(scheduledJob)
              }}
            />
          ) : null
        },
        sortable: false,
        width: 50,
        filterable: false,
      })
    }


    if (!hiddenCols.includes('macroarea')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.macroarea'),
        accessor: 'macroarea.name',
        Cell: (row: any) => {
          if (row.value) {
            return capitalizeFirstLetter(row.value)
          }
          return null
        },
      } as any

      cols.push(col)
    }
    /*
        if (!hiddenCols.includes('component')) {
          let col = {
            Header: this.props.t('maintenance.plan.tableCols.component'),
            accessor: 'component.name',
            Cell: (row: any) => {
              if (row.value) {
                return capitalizeFirstLetter(row.value)
              }
              return null
            },
          } as any

          (PageFiltersHelper.haveClientFilter(pageFilters, 'component'))
            ? col.Filter = () => this.getTableFilterComponent(pageFilters, 'component')
            : col.filterable = false

          cols.push(col);
        }

        if (!hiddenCols.includes('pid')) {
          let col = {
            Header: this.props.t('maintenance.plan.tableCols.pid'),
            accessor: 'component.pid',
            Cell: (row: any) => {
              if (row.value) {
                return row.value
              }
              return null
            },
          } as any

          (PageFiltersHelper.haveClientFilter(pageFilters, 'component'))
            ? col.Filter = () => this.getTableFilterComponent(pageFilters, 'component')
            : col.filterable = false

          cols.push(col)
        }
    */
    if (this.props.smartdocsExists && !hiddenCols.includes('pid')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.pid'),
        accessor: 'component.pid',
        width: 80,
        Cell: (row: any) => {
          if (row.original.hasOwnProperty('component') && row.original.component) {
            const nodeData = this.getNodeDataByPath(row.original.component.id)
            if (nodeData) {
              return nodeData.data.pid
            }
          }
          return null
        },
      } as any

      cols.push(col)
    }

    const showTargetNameOnNullTarget: boolean = getConfigParam(config, 'showTargetNameOnNullTarget', false)

    if (!hiddenCols.includes('target')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.target'),
        accessor: 'target.name',
        Cell: (row: any) => {
          if (row.value) {
            return capitalizeFirstLetter(row.value)
          } else if (showTargetNameOnNullTarget) {
            return row.original.targetName
          }
          return null
        },
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('operation')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.operation'),
        accessor: 'operation',
        minWidth: 270,
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('userFullName')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.operatorName'),
        accessor: 'userFullName',
        filterable: false,
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('type')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.type'),
        accessor: 'type.name',
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('dueDate')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.dueDate'),
        id: 'dueDate',
        accessor: 'dueDate',
        width: 110,
        Cell: (row: any) => row.original.dueDate.format(getDateFormat()),
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('plannedDate')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.plannedDate'),
        id: 'plannedDate',
        accessor: 'plannedDate',
        width: 110,
        Cell: (row: any) => row.original.plannedDate.format(getDateFormat()),
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('creationDate')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.creationDate'),
        id: 'creationDate',
        accessor: 'creationDate',
        width: 110,
        Cell: (row: any) => {
          return row.original.creationDate && row.original.creationDate.format(getDateFormat()) || ''
        },
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('status')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.status'),
        id: 'status',
        accessor: (d: any) => d.status,
        width: 110,
        Cell: (row: any) => {
          return presentStatusBadge(row.original)
        },
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('jobType')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.jobType'),
        id: 'jobType',
        accessor: (d: any) => d.jobType,
        width: 180,
        Cell: (row: any) => {
          return presentJobType(row.original, false, false)
        },
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('acknowledged')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.acknowledged'),
        id: 'acknowledged',
        width: 110,
        accessor: (d: any) => d.acknowledged,
        Cell: (row: any) => {
          return (
            <span><IconComponent icon={'check-circle'} color={`${(row.value) ? 'green' : 'lightgray'}`}/></span>
          )
        },
      } as any

      cols.push(col)

    }

    if (!hiddenCols.includes('lastNotes')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.notes'),
        id: 'lastNotes',
        width: 80,
        accessor: (d: any) => d.lastNotes,
        Cell: (row: any) => {
          /* <NotesTooltip notes={row.value}/> */
          const noteWrapper = row.value ? (
            <IconComponent icon={'clipboard'}/>
          ) : (
            ''
          )

          return row.value ? (
            <HtmlTooltip
              content={
                row.value ? (
                  <div style={{ textAlign: 'left' }}>
                    <div>"{row.value.notes || '-'}"</div>
                    <div style={{ fontWeight: 600 }}>
                      {row.value.user || '-'}
                    </div>
                    <div style={{ fontWeight: 600 }}>
                      {row.value.date && moment(row.value.date).format(
                        'DD/MM/YYYY HH:mm',
                      ) || '-'}
                    </div>
                  </div>
                ) : (
                  ''
                )
              }
              target={noteWrapper}
            />
          ) : (
            <></>
          )
        },
        filterable: false,
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('notes')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.notes'),
        id: 'notes',
        accessor: (d: any) => d.notes,
        Cell: (row: any) => <NotesTooltip notes={row.value}/>,
        filterable: false,
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('action')) {
      const col = {
        Cell: (row: any) => (
          <Button
            icon={'eye'}
            spacing={{horizontal:false}}
            variant="secondary-alternate"
            size="sm"
            onClick={() => this.handleOpenEditScheduledJobModal(row.original as MaintenanceScheduledJob)}
          />
        ),
        width: 60,
        sortable: false,
        filterable: false,
      } as any

      cols.push(col)
    }

    return cols
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(Plan)),
)
