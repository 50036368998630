export interface Margin {
  horizontal?: boolean,
  vertical?: boolean
}

export const renderMargin = (marg?: Margin): string => {
  let horizontalMargin = marg && marg.horizontal
  let verticalMargin = marg && marg.vertical

  if (horizontalMargin === undefined) {
    horizontalMargin = true
  }
  if (verticalMargin === undefined) {

    verticalMargin = true
  }

  return `${horizontalMargin ? 'mx-2' : ''} ${verticalMargin ? 'my-2' : ''}`
}

export interface Padding {
  horizontal?: boolean,
  vertical?: boolean
}

export const renderPadding = (padd?: Padding): string => {
  let horizontalPadding = padd && padd.horizontal
  let verticalPadding = padd && padd.vertical

  if (horizontalPadding === undefined) {
    horizontalPadding = true
  }
  if (verticalPadding === undefined) {
    verticalPadding = true
  }

  return `${horizontalPadding ? 'px-2' : ''} ${verticalPadding ? 'py-2' : ''}`
}

export type TextVariants =
  'danger' |
  'primary' |
  'secondary' |
  'success' |
  'warning' |
  'info' |
  'light' |
  'dark' |
  'muted' |
  'white'

export  const renderTextVariants = (textVariant?: TextVariants):string => {
  const text = textVariant ? `text-${textVariant}` : ''
  // text = text + ((textVariant && (textVariant === 'light' || textVariant === 'white')) ? 'bg-dark' : '')
  return text
}

export type Color =
    'primary' |
    'secondary' |
    'success' |
    'warning' |
    'danger' |
    'info' |
    'light' |
    'dark' |
    'muted' |
    'white'
