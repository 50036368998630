// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Row from "../Grid/Row";
import Column from "../Grid/Column";


interface Props {
  type: 'warning' | 'danger' | 'primary' | 'secondary' | 'success' | 'info' | 'light' | 'dark'
  title?: string
  children?: string | React.ReactNode
}

const Alert = (props: Props) => (
  <Row horizontalAlignment={'center'}>
    <Column md={6}>
      <div className={`alert alert-${props.type}`} role="alert">
        {props.title && <h4 className="alert-heading text-center">{props.title}</h4>}
        {props.children && <div className={"text-center"}>{props.children}</div>}
      </div>
    </Column>
  </Row>

)

export default Alert
