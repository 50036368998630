import { Oee } from './oee'
// import {Pretrattamento} from './treatment'
// import {Anaforesi} from './cataphoresis'
// import {Forni} from './ovens'
// import {Sezioneapplicazionepolveri} from './booths'
import { Generic } from './generic'

export default {
  Oee,
  // Pretrattamento,
  // Anaforesi,
  // Forni,
  Generic,
}
