import * as React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'

export interface StateProps {
  modelData?: any
  modelError?: any
  modelFetching: Error
}

const mapStateToProps = (state: any): StateProps => ({
  modelData: state.smartdocs.smartdocs.data,
  modelError: state.smartdocs.smartdocs.error,
  modelFetching: state.smartdocs.smartdocs.fetching,
})

type Props = StateProps & WithTranslation

interface Window {
  player: () => {}
  overrideTag: (e: any, tags: any) => any
  GCVPlayer: any
}

const publicUrl = (window as any).PUBLIC_URL || process.env.PUBLIC_URL || window.location.host

class Plant3DViewer extends React.PureComponent<Props> {
  // private sidebarStatus: boolean = false
  private myRef: any

  constructor(props: Props) {
    super(props)

    this.initViewer = this.initViewer.bind(this)

    this.myRef = React.createRef()
  }

  public componentDidMount(): void {
    // carico i dati del modello con la funzione di smartdocs
    // this.sidebarStatus = this.props.sideBarIsOpen

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '/plant-3d-viewer-player/player.js'
    script.onload = (event) => {
      const timer = setInterval(() => {
        if (this.myRef && this.myRef.current) {
          this.initViewer()
          clearInterval(timer)
        }
      }, 100)
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  public componentWillUnmount(): void {
    // this.sidebarStatus ? this.props.sideBarShow() : this.props.sideBarHide()
  }

  public initViewer(): void {
    (window as unknown as Window).overrideTag = (e, tagsElement = null) => {
      const override = {
        'IMGLO17DVGQ4XEUL.IMGLO17DVGPY4ABJ': {}
      }

      const overrideKeys = Object.keys(override)
      const getIdFromLink = (l: any) => {
        const parts = l.split('id=')
        return parts && parts[1]
      }
      if (e && e.link && e.link.url) {
        const key = getIdFromLink(e.link.url)
        const link = {
          label: this.props.t('inplant3DViewer.viewInSmartDocs'),
          url: `//${publicUrl}/smartdocs?id=${key}`,
        }
        const content = `
          <div id="smartdoc-node-${key}" class="gcv-voh-tags__text-content smartdoc-node-content">
            <div class="gcv-voh-tags__title">${e.title}</div>
            <div class="gcv-voh-tags__description gcv-voh-font-karla">${e.content}</div>
            <a class="gcv-voh-tags__link gcv-voh-font-karla" target="_blank" href="${link.url}">${link.label}</a>
          </div>
        `
        if (overrideKeys.includes(key)) {
          return { ...e, ...override[key], ...{ content, type: 'custom' } }
        }
        return { ...e, ...{ link } }
      }
      return e
    }

    (window as unknown as Window).player = new (window as unknown as Window).GCVPlayer(
       this.myRef.current,
       {
         dangerouslyRunUserScripts: true,
         tour: 'TJXaOb1pNG6Scl-r0nt33Q',
         contentPathPrefix: 'https://i4paintshop-virtualtour3d.s3.amazonaws.com/',
         splashPath: 'embedded_files/splash/5s6VWn57vnfaUq4Rj0k-8.jpg',
       },
       {}, // debugOptions
       {} // configOptions
     )
  }

  public render() {
    const exitRoute = new URLSearchParams(window.location.search).get('exitRoute')

    return (
      <div id="plant-3d-viewer">
        <div className="plant-3d-viewer-btn-close">
          <Link to={exitRoute || '/smartdocs'}>
            <FontAwesomeIcon icon={faTimes} size={'2x'} className="text-white" />
          </Link>
        </div>
        <div className="player" id="player" ref={this.myRef} />
        {/* <iframe height="1000" width="100%" frameBorder={0} src={}></iframe> */}
      </div>
    )
  }
}

export default withRouter<any, any>(connect(mapStateToProps)(withTranslation()(Plant3DViewer)))
