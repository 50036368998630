import * as React from 'react'
import {withRouter, RouteComponentProps} from 'react-router'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {deleteJob} from '../../../../redux/actions'
import {MaintenanceJobDeleteParams, MaintenanceJob} from '../../../../types'
import {FormModal} from "@mv-submodules/inplant-components-fe/ui/components/Modal/Modal";

export interface DispatchProps {
  deleteJob: (params: MaintenanceJobDeleteParams) => Promise<any>
}

export interface OwnStateProps {
  error: string
}

export interface WarningMessage {
  warningMessage: string
}

export interface OwnProps extends RouteComponentProps<any> {
  isVisible: boolean
  job: MaintenanceJob | null
  onClose: Function | null
  warningMessage?: WarningMessage
  // t: TranslationFunction
}

export type Props = DispatchProps & OwnProps & WithTranslation

export const mapDispatchToProps = (dispatch: Function) => ({
  deleteJob: (params: MaintenanceJobDeleteParams) => dispatch(deleteJob(params)),
})

class JobDeleteModalComponent extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      error: '',
    }

    this.onModalClose = this.onModalClose.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  public resetState() {
    this.setState({error: ''})
  }

  public onModalClose(reload: boolean = false) {
    this.resetState()
    if (this.props.onClose) {
      this.props.onClose(reload)
    }
  }

  public onSubmit(e: any) {
    e.preventDefault()

    if (this.props.job !== null) {
      const params: MaintenanceJobDeleteParams = {
        id: this.props.job.id,
      }

      // reset errors
      this.resetState()

      this.props
        .deleteJob(params)
        .then(() => {
          this.onModalClose(true)
        })
        .catch(error => {
          this.setState({
            error: error.message,
          })
        })
    }
  }

  public render() {
    if (this.props.job === null) {
      return null
    }

    return (
      <form onSubmit={this.onSubmit}>
        <FormModal visible={this.props.isVisible} onClose={this.onModalClose}
                   title={this.props.t('maintenance.deleteJob.title')}
                   closeLabel={this.props.t('maintenance.deleteJob.close')} onSubmit={this.onSubmit}
                   submitButton={{label: this.props.t('maintenance.deleteJob.confirm'),variant: 'danger'}}>
          <div className="container">
            <div className="row justify-content-center">{this.props.t('maintenance.deleteJob.message')}</div>
            {this.props.warningMessage && <div
                className={"row justify-content-center p-2 mt-1 bg-warning text-dark"}>{this.props.warningMessage?.warningMessage}</div>}
            {this.state.error.length > 0 && <div className="row text-warning">{this.state.error}</div>}
          </div>
        </FormModal>
      </form>
    )
  }
}

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(withTranslation()(JobDeleteModalComponent))
)
