import { Template } from '../types'
import { ComponentGroup } from './componentGroup'
import { Component } from './component'
import { Metric } from './metric'

export const Section: Template = {
  id: 'section',
  name: 'Section',
  version: '0.1.1',
  date: '2019-02-21',
  fields: [
    {
      name: 'label',
      type: 'string',
      label: 'section.label',
      width: 12,
      required: true,
      nodePath: 'data',
      translatable: true,
    },
    {
      name: 'pid',
      type: 'string',
      label: 'section.pid',
      width: 4,
      nodePath: 'data',
      relationships: [{
        target: 'inUse',
        condition: 'required',
        targetValue: true
      }]
    },
    {
      type: 'space',
      name: '',
      label: '',
      width: 12
    },
    {
      name: 'hasQRCode',
      type: 'boolean',
      label: 'section.hasQRCode',
      width: 4,
      nodePath: 'data',
      values: true
    },
    {
      name: 'inUse',
      type: 'boolean',
      label: 'section.nodeIsActive',
      width: 4,
      nodePath: 'data',
      values: false,
      derivedValue: {
        condition: (targetValue, currentFieldValue) => {
          return (typeof targetValue !== 'undefined' && targetValue !== '') ? currentFieldValue : false
        },
        target: 'pid'
      }
    },
  ],
  copyable: true,
  allowedSubcomponents: [ComponentGroup, Component, Metric],
}
