import { combineReducers, ReducersMapObject } from 'redux'
import { userIndex, userDetails, userSchema } from './user'

const userReducersMap: ReducersMapObject = {
  new: userDetails,
  index: userIndex,
  details: userDetails,
  schemas: userSchema,
}

export default combineReducers(userReducersMap)
