import { combineReducers, ReducersMapObject } from 'redux'
import { designer } from './designer'
import { storage } from './storage'

const designerReducersMap: ReducersMapObject = {
  designer,
  storage,
}

export default combineReducers(designerReducersMap)
