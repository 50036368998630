import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { getImage } from '../../../../redux/actions'
import { Modal } from '@mv-submodules/inplant-components-fe'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface DispatchProps {
  getImage: Function
}

export interface OwnStateProps {
  loadingPreview: boolean
  loadingFile: boolean
  previewFileBlob: string | undefined
  fileBlob: string | undefined
  showModal: boolean
}

export interface OwnProps {
  // t: TranslationFunction
  imagePath: string
}

export type Props = DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    getImage: (filePath: string, options?: { preview: boolean }) => dispatch(getImage(filePath, options)),
  }
}

export class ImageComponent extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loadingPreview: false,
      loadingFile: false,
      previewFileBlob: undefined,
      fileBlob: undefined,
      showModal: false,
    }
    this.getPreviewImage = this.getPreviewImage.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  public componentDidMount() {
    this.getPreviewImage()
  }

  private async getPreviewImage() {
    this.setState(prevState => ({ ...prevState, loadingPreview: true }))
    try {
      const previewFileBlob = await this.props.getImage(this.props.imagePath, { preview: true })
      this.setState(prevState => ({ ...prevState, previewFileBlob, loadingPreview: false }))
    } catch (error) {
      this.setState(prevState => ({ ...prevState, loadingPreview: false }))
    }
  }

  private async getImage() {
    this.setState(prevState => ({ ...prevState, loadingFile: true }))
    try {
      const fileBlob = await this.props.getImage(this.props.imagePath)
      this.setState(prevState => ({ ...prevState, fileBlob, loadingFile: true }))
    } catch (error) {
      this.setState(prevState => ({ ...prevState, loadingFile: true }))
    }
  }

  public handleClick(e: any) {
    e.preventDefault()
    if (!this.state.fileBlob) {
      this.getImage()
    }
    this.setState({ showModal: true })
  }

  public onClose() {
    this.setState({ showModal: false })
  }

  public render() {
    const spinnerStyle = { minHeight: '100px' }

    if (this.state.loadingPreview && !this.state.previewFileBlob) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={spinnerStyle}>
          <IconComponent className="spinner" icon={'circle-notch'} spin={true} size="2x" />
        </div>
      )
    }

    if (this.state.previewFileBlob) {
      return (
        <React.Fragment>
          <a href="#" onClick={this.handleClick}>
            <img className="img-fluid d-block" src={URL.createObjectURL(this.state.previewFileBlob)} />
          </a>
          {this.state.showModal
            ? ReactDOM.createPortal(
                <Modal
                  visible={true}
                  onClose={() => this.onClose()}
                  width={50}
                  hideFooterCloseButton={false}
                  closeLabel={this.props.t('maintenance.close')}
                >
                  {this.state.loadingFile && !this.state.fileBlob ? (
                    <div className="d-flex justify-content-center align-items-center" style={spinnerStyle}>
                      <IconComponent className="spinner" icon={'circle-notch'} spin={true} size="2x" />
                    </div>
                  ) : (
                    <img className="img-fluid" src={URL.createObjectURL(this.state.fileBlob)} />
                  )}
                </Modal>,
                document.getElementById('modal-container') as Element
              )
            : null}
        </React.Fragment>
      )
    }

    return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImageComponent))
