import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FileUploader, Input } from '@mv-submodules/inplant-components-fe'
import { API } from '../../../../redux/actions'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface UploaderProps {
  onInputChange: (slug: string, value: any, template?: boolean) => void
  handleUploadFiles: (file: any, remove?: boolean) => void
  fieldValue: [object]
  canSend?: (state: boolean) => void
}

interface UploaderStatus {
  id: string | null // ids resultant from upload returned from server
  name: string | null
  description: string
  originalFileName: string | null
}

class FileUploaderField extends React.Component<UploaderProps & WithTranslation, UploaderStatus> {
  constructor(props: UploaderProps & WithTranslation) {
    super(props)
    this.upload = this.upload.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.state = {
      id: null,
      name: null,
      description: '',
      originalFileName: null,
    }

    if (Array.isArray(this.props.fieldValue) && this.props.fieldValue.length > 0) {
      this.state = {
        ...this.state,
        ...this.props.fieldValue[0],
      }
    }
  }

  private upload(data: FormData) {
    if (this.props.canSend) {
      this.props.canSend(false)
    }
    return API().request('/chrono-frame/attachments', {
      method: 'POST',
      body: data,
    })
  }

  private onSuccess(result: { id: string; name: string; originalFileName: string }) {
    this.setState({
      id: result.id,
      originalFileName: result.originalFileName, // the name of the file in the filesystem of the uploader user
      name: result.name || 'filePlaceholderName.jpg', // the name of the file returned by the server
    })
    if (this.props.canSend) {
      this.props.canSend(true)
    }
    this.props.handleUploadFiles(this.state) // to change this name since ids collides with another field in the post data object
  }

  private onError(result: Response) {
    // decide what to do
    console.warn(result) // tslint:disable-line
  }

  private deleteAttachment() {
    const toRemove = { ...this.state } // copy this.state to mantain a reference of the id to remove
    this.setState({
      id: null,
      name: null,
    })

    this.props.handleUploadFiles(toRemove, true) // second parameter is needed to tell the function to remove the 'file'
  }

  private handleDescriptionChange(value: string) {
    this.setState({ description: value }, () => {
      this.props.handleUploadFiles(this.state)
    })
  }

  public render() {
    const t = this.props.t
    // first span needs some alignment
    return (
      <React.Fragment>
        {this.state.id ? (
          <React.Fragment>
            <div className="col ">
              <span className="text-secondary">
                <IconComponent icon={'file'} size="sm" />
                &emsp;{this.state.name || this.state.id}
              </span>
            </div>
            <div className="col-6">
              <div className="input-group mb-3">
                <Input
                  type="text"
                  label={t('maintenance.dueDates.detail.description')}
                  placeholder={t('maintenance.dueDates.detail.description')}
                  value={this.state.description}
                  onChange={value => this.handleDescriptionChange(value as string)}
                />
              </div>
            </div>
            <div className="col ">
              <span className="btn btn-outline-danger" onClick={this.deleteAttachment}>
                <IconComponent icon={'trash-alt'} size="sm" />
              </span>
            </div>
          </React.Fragment>
        ) : (
          <div className="col d-flex justify-content-center">
            <FileUploader
              buttonText={t('maintenance.dueDates.detail.upload')}
              upload={this.upload}
              onSuccess={this.onSuccess}
              onError={this.onError}
              buttonClass={'btn-secondary'}
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default withTranslation()(FileUploaderField)
