const en = {
  maintenance: {
    module: 'Maintenance',
    navigation: {
      calendar: 'Calendar',
      plan: 'Plan',
      history: 'History',
      pending: 'Pending',
      setup: 'Setup',
    },
    fetching: 'Fetching',
    newIntervention: 'New',
    filters: {
      showFilters: 'Show Filters',
      headingText: 'Select Filters',
      allSelectText: 'All Selected',
      allSelectedText: 'All Selected',
      selectAllText: 'All Selected'
    },
    status: {
      done: 'DONE',
      overdue: 'OVERDUE',
      planned: 'TO DO',
      unhandled: 'UNHANDLED',
      postponed: 'TO DO',
      rejected: 'REJECTED',
      todo: 'TO DO',
    },
    periodicity: {
      '1_DAY': '1 Day',
      '1_WEEK': '1 Week',
      '2_WEEKS': '2 Weeks',
      '1_MONTH': '1 Month',
      '2_MONTHS': '2 Months',
      '3_MONTHS': '3 Months',
      '6_MONTHS': '6 Months',
      '1_YEAR': '1 Year',
      '2_YEARS': '2 Years',
    },
    jobType: {
      custom: 'On Condition',
      maintenance: 'Periodic',
      anomaly: 'Anomaly',
    },
    table: {
      noData: 'No data available',
      previous: 'Previous',
      next: 'Next',
      loading: 'Loading data...',
      page: 'Page',
      of: 'of',
      rows: 'rows',
    },
    calendar: {
      title: 'Calendar',
      new: 'New on condition intervention',
      pastUndoneMsg: 'There are some interventions TODO on previous months: ',
      rescheduleConfirm: 'Are you sure to move this intervention on ',
      today: 'today',
      month: 'month',
      week: 'week',
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      dayNames: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      more: 'more',
      legend: {
        legend: 'LEGEND:',
        done: 'DONE',
        rejected: 'REJECTED',
        overdue: 'OVERDUE',
        todo: 'TO DO',
        unhandled: 'UNHANDLED',
        periodic: 'PERIODIC',
        anticipated: 'ANTICIPATED',
        postponed: 'POSTPONED',
      },
    },
    plan: {
      title: 'Plan',
      new: 'New on condition intervention',
      print: 'Print',
      tableCols: {
        macroarea: 'Macro Area',
        section: 'Section',
        component: 'Component',
        target: 'Target',
        operation: 'Operation',
        operatorName: 'Operator',
        type: 'Op. Type',
        dueDate: 'Due Date',
        plannedDate: 'Planned Date',
        creationDate: 'Creation Date',
        status: 'Status',
        jobType: 'Job Type',
        acknowledged: 'Ack',
        notes: 'Notes',
        pid: 'P&ID',
      },
    },
    actionScheduledJob: {
      interventionOverview: 'Intervention overview',
      macroarea: 'Macro Area',
      section: 'Section',
      component: 'Component',
      target: 'Target',
      jobType: 'Job Type',
      operation: 'Operation',
      operatorName: 'Operator',
      type: 'Operation Type',
      dueDate: 'Due Date',
      plannedDate: 'Planned Date',
      status: 'Status',
      hours: 'Hours',
      sinceLastMaintenance: 'since last maintenance',
      acknowledged: 'Acknowledged',
      details: 'More details',
      notes: 'Notes',
      pid: 'P&ID',
      currentHours: 'Current hours'
    },
    addScheduledJob: {
      newIntervention: 'New on condition intervention',
      target: 'Target',
      targetPlaceholder: 'Please select the item from the list',
      operation: 'Operation',
      selectType: 'Select type',
      type: 'Operation Type',
      dueDate: 'Due Date',
      save: 'Save',
    },
    scheduledJobDetail: {
      title: 'Intervention details',
      buttons: {
        markAsDone: 'Mark as done',
        reschedule: 'Reschedule',
        reject: 'Reject',
      },
      modals: {
        markAsDone: {
          title: 'Mark as done intervention',
          dueDate: 'Closing Date',
          cost: 'Cost',
          time: 'Time',
          hours: 'Hours',
          notes: 'Notes',
          confirm: 'Confirm',
        },
        reschedule: {
          title: 'Reschedule intervention',
          dueDate: 'Due Date',
          notes: 'Notes',
          confirm: 'Confirm',
        },
        reject: {
          title: 'Reject intervention',
          notes: 'Notes',
          confirm: 'Confirm',
        },
      },
      recap: {
        title: 'Maintenance recap',
        macroarea: 'Macro Area',
        section: 'Section',
        component: 'Component',
        target: 'Target',
        hours: 'Hours',
        sinceLastMaintenance: 'since last maintenance',
        operation: 'Operation',
        type: 'Operation Type',
        operatorName: 'Operator name',
        dueDate: 'Due date',
        plannedDate: 'Planned date',
        status: 'Status',
        cost: 'Cost',
        time: 'Time',
      },
      historyActions: {
        on: 'on',
        to: 'to',
        type: {
          created: 'created',
          acknowledged: 'acknowledged',
          done: 'done',
          rescheduled: 'rescheduled',
          rejected: 'rescheduled',
        },
      },
      history: {
        title: 'History',
        user: 'User',
        action: 'Action',
        date: 'Date',
        notes: 'Notes',
        isSystem: 'System'
      },
      relatedJobs: {
        title: 'Periodic maintenance history',
        hours: 'hours',
        user: 'User',
        action: 'Action',
        date: 'Date',
        notes: 'Notes',
        noHistoryAvailable: 'No history data available',
        late: 'late',
      },
      images: 'Images',
    },
    setup: {
      title: 'Setup',
      new: 'New periodic intervention',
      print: 'Print',
      tableCols: {
        macroarea: 'Macro Area',
        section: 'Section',
        component: 'Component',
        operation: 'Operation',
        target: 'Target',
        type: 'Operation Type',
        periodicity: 'Periodicity',
        hours: 'Hours',
        notes: 'Notes',
      },
    },
    addJob: {
      title: 'New periodic intervention',
      target: 'Target',
      targetPlaceholder: 'Please select the item from the list',
      operation: 'Operation',
      type: 'Operation Type',
      selectType: 'Select op. type',
      periodicity: 'Periodicity',
      selectPeriodicity: 'Select periodicity',
      hours: 'Hours',
      notes: 'Notes',
      save: 'Save',
      lastDoneDate: 'Last Done Date',
      lastDoneHours: 'Last Done Hours',
      specialOperation: 'Special Operation'
    },
    editJob: {
      title: 'Edit periodic intervention',
      target: 'Target',
      operation: 'Operation',
      type: 'Operation Type',
      periodicity: 'Periodicity',
      selectPeriodicity: 'Select periodicity',
      hours: 'Hours',
      notes: 'Notes',
      save: 'Save',
      specialOperation: 'Special Operation'
    },
    deleteJob: {
      title: 'Delete periodic intervention',
      message: 'Are you sure to delete selected periodic intervention?',
      close: 'Close',
      confirm: 'Confirm',
      warningMessage: 'The related archived interventions will also be deleted.'
    },
    bulkActions: {
      button: 'Actions',
      actions: {
        done: 'Mark as done',
        rescheduled: 'Reschedule',
        rejected: 'Reject',
        acknowledged: 'Acknowledged',
      },
      modal: {
        title: 'Bulk Actions',
        date: 'Date',
        notes: 'Notes',
        confirm: 'Confirm',
        selectedOf: '{{selected}} of {{total}} items',
        acknowledgedMsg:
          'Are you sure you want to confirm the selected interventions?',
      },
    },
    target: {
      title: 'Component Maintenance Detail',
      periodicTitle: 'Periodic maintenance',
      variableTitle: 'On condition maintenance',
      archivedTitle: 'Archived maintenance (done and rejected)',
      noData: 'No data available',
      smartdocsLink: 'Link to Smartdoc',
      confirmed: {
        yes: 'YES',
        no: 'NO',
      },
      pagination: {
        page: 'Page',
        of: 'of',
      },
      scheduledJobsCols: {
        operation: 'Operation',
        type: 'Op. Type',
        operator: 'Operator',
        status: 'Status',
        jobType: 'Job Type',
        confirmed: 'Confirmed',
        date: 'Date',
        notes: 'Notes'
      },
      jobsCols: {
        operation: 'Operation',
        type: 'Op. Type',
        periodicity: 'Periodicity',
        hours: 'Hours',
        prev: 'Prev.',
        next: 'Next.',
      },
    },
    errors: {
      somethingGoneWrong: "We're sorry — something's gone wrong.",
      contactOurTeam: 'Plase contact our team to notify the problem',
      targetNotFound: 'Selected target seems not present or deleted',
    },
    close: 'Close',
  },
}

export default en
