import * as React from 'react'
import { connect } from 'react-redux'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'
import { ackScheduledJob } from '../../../../redux/actions'
import { MaintenanceScheduledJob } from '../../../../types'

interface DispatchProps {
  ackScheduledJob: (id: string) => Promise<any>
}

interface OwnStateProps {
  acknowledged: boolean
}

interface OwnProps {
  scheduledJob: MaintenanceScheduledJob
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: Function) => ({
  ackScheduledJob: (id: string) => dispatch(ackScheduledJob(id)),
})

export type Props = DispatchProps & OwnProps

class AcknowledgeComponent extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      acknowledged: this.props.scheduledJob.acknowledged,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  public handleClick() {
    if (!this.state.acknowledged) {
      this.props
        .ackScheduledJob(this.props.scheduledJob.id)
        .then(() => {
          this.setState({ acknowledged: true })
        })
        .catch(error => {
          console.error(error) // tslint:disable-line
        })
    }
  }

  public render() {
    const classes = ['acknowledge', 'acknowledge-hoverable']
    if (this.state.acknowledged) {
      classes.push('acknowledge-active')
    }
    return (
      <span onClick={this.handleClick} className={classes.join(' ')}>
        <IconComponent icon={'check-circle'} />
      </span>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcknowledgeComponent)
