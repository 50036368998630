export interface ErrorResponse extends Response {
  statusCode: number
  message: string
  detail: string
  errors?: { [key: string]: string[] }
  title?: string
}

export default class FetchError extends Error {
  public statusCode: number
  public errors: { [key: string]: string[] }

  constructor(message: string, detail: string, statusCode: number, errors?: any) {
    super(detail || message)
    this.name = 'FetchError'
    this.statusCode = statusCode
    this.errors = errors
  }
}
