import { Dispatch } from 'redux'
import { AnyAction } from 'redux'

import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { getDateRangeFiltering } from '@mv-submodules/inplant-core-fe/functions/date'

export const API = () => FetchWrapper.getInstance('influx')

export const TPRNCAlarmsHasErrored = ( ): AnyAction => {
  return {
    type: 'TPRNC_ALARMS_HAS_ERRORED',
  }
}

// ----------------------------------------------------

export const TPRNCAlarmsIsLoading = (): AnyAction => {
  return {
    type: 'TPRNC_ALARMS_IS_LOADING',
  }
}

// ----------------------------------------------------

export const TPRNCAlarmsItemsDataSuccess = (data: any[]): AnyAction => {
  return {
    type: 'TPRNC_ALARMS_ITEMS_FETCH_DATA_SUCCESS',
    data,
  }
}

// ----------------------------------------------------

export const TPRNCAlarmsFetchData = (dateStart: string, dateEnd: string): Function => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(TPRNCAlarmsIsLoading())

    const { group, start, end } = getDateRangeFiltering(dateStart, dateEnd)
    const query = `SELECT mean("measure") FROM "plantStatusOverTime" WHERE time >= '${start}' AND time < '${end}' and "measure"=12 ${group}`

    return API().request('/influx/query?q=' + query)
    .then((data: any) => {
      dispatch(TPRNCAlarmsItemsDataSuccess(data))
      return data
    })
    .catch((error: any) => {
      dispatch(TPRNCAlarmsHasErrored())
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      throw  error
    })
  }
}
