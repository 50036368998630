import { IconName } from '@mv-submodules/inplant-components-fe/services/icon'
// import PlanComponent from './components/PlanComponent/PlanComponent'
// import CalendarComponent from './components/CalendarComponent/CalendarComponent'
// import SetupComponent from './components/SetupComponent/SetupComponent'
import ScheduledJobDetailComponent from './components/views/ScheduledJobDetailComponent/ScheduledJobDetailComponentPageView'
// import TaskList from './components/TaskList/TaskList'
// import TaskDetail from './components/TaskDetail/TaskDetail'
// import TaskCalendar from './components/TaskCalendar/TaskCalendar'

export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey?: string
  icon?: IconName
  iconOverride?: string
  visible: boolean
  component?: any
  children?: RouteProps[]
  aclActionKey?: string
}

const routes: RouteProps = {
  path: '/maintenance/calendar',
  i18nkey: 'maintenance.module',
  icon: 'wrench',
  visible: true,
  children: [
    // {
    //   path: '/maintenance/calendar',
    //   exact: true,
    //   i18nkey: 'maintenance.navigation.calendar',
    //   component: CalendarComponent,
    //   visible: true,
    // },
    // {
    //   path: '/maintenance/plan',
    //   exact: true,
    //   i18nkey: 'maintenance.navigation.plan',
    //   component: PlanComponent,
    //   visible: true,
    // },
    // {
    //   path: '/maintenance/setup',
    //   exact: true,
    //   i18nkey: 'maintenance.navigation.setup',
    //   component: SetupComponent,
    //   visible: true,
    // },
    {
      path: '/maintenance/scheduled-job/:scheduledJobId',
      component: ScheduledJobDetailComponent,
      visible: false,
    },
  ],
}

export default routes
