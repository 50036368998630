const en = {
  costs: {
    navigation: {
      areaCostAllocation: 'Area Cost Allocation',
      costs: 'Costs',
      pending: 'Pending',
      settings: 'Settings',
      view: 'Setup',
      setup: 'Setup',
      cost: 'Cost: {{ name }}',
      manualDataLoadingCost: 'Manual Data Loading: {{name}}',
      budgetVsActual: 'Budget VS Actual',
      maintenanceCost: 'Maintenance Cost'
    },
    fetchErrors: 'Error fetching data, please retry or contact administrator',
    actions: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      add: 'Add',
      update: 'Update',
      delete: 'Delete',
      export: 'Export',
      editBudgetParams: 'Edit budget params',
      save: 'Save',
      edit: 'Edit',
      view: 'View',
      updateCost: 'Update cost',
      editHistoryCost: 'Edit history cost',
      editUnitaryCost: 'Edit unitary cost',
      loadMaterial: 'Load material'
    },
    confirm: {
      deleteHistoryEntry: 'Are you sure you want to delete this item?',
    },
    notifications: {
      submitSuccess: 'Data saved successfully',
      submitError: 'Error submitting data',
      allRequired: 'All fields are required',
      noDataForYear: 'No data available for {{year}}',
      noData: 'No data available'
    },
    modal: {
      title: {
        add: 'Add cost',
        addCostName: 'Add {{name}}',
        addRunningCostHistory: 'Add running cost history',
        update: 'Update {{name}} cost',
        editBudgetParams: 'Edit budget params - {{year}}',
        loadMaterial: 'Load material',
        addLineOperator: 'Add line operators',
        editLineOperator: 'Edit line operator',
        editMaintenanceOperations: 'Edit maintenance operations',
        editRunningCost: 'Edit running cost',
        editManualDataLoading: 'Edit manual data loading',
        edit: 'Edit {{name}}',
        editCost: 'Edit {{name}}',
        addManualDataLoading: 'Add manual data loading',
        removeUnitaryCost: 'Remove unitary cost'
      },
      fields: {
        date: 'From date',
        data: 'Date',
        name: 'Name',
        cost: 'Cost',
        unitMeasure: 'Unit of measure',
        refName: 'Ref. name',
        budget: 'Budget',
        budgetAnnualLoad: 'Budget Annual Load',
        shifts: 'Shifts',
        hoursShift: 'Hours/shift',
        workingDays: 'Working days',
        quantity: 'Nº',
        quantityLiteral: 'Quantity',
        type: 'Type',
        hourlyCost: 'Hourly Cost',
        yearlyBudget: 'Yearly Budget',
        budgetCost: 'Budget Cost',
        maintenanceOperatorCost: 'Maintenance Operator Cost',
        insertTotalYearlyBudget: 'Insert total yearly budget',
        insertAreaYearlyBudget: 'Insert yearly area budget',
        totalYearlyBudget: 'Total yearly budget',
        area: 'Area',
        periodicity: 'Periodicity',
        unitaryCost: 'Unitary Cost'
      },
      fieldsFormat: {
        costs: 'Please use only numbers and dot "."',
      },
    },
    view: {
      title: {
        pre: 'Cost',
      },
      updateCosts: 'Update cost',
      fields: {
        date: 'From date',
        fromDate: 'From date',
        name: 'Name',
        cost: 'Cost',
        value: 'Cost',
        unitMeasure: 'Unit of measurement',
        type: 'Ref. name',
      },
      history: {
        title: 'Cost history',
      },
    },
    labels: {
      actual: 'Actual',
      allCostActual: 'All costs are actual',
      area: 'Area',
      budget: 'Budget',
      budgetAnnualLoad: 'Budget Annual Load',
      compare: 'Compare with',
      cost: 'Cost',
      costHistory: 'Cost history',
      currency: '€',
      fromDate: 'From date',
      grandTotal: 'Grand total',
      maintenanceCost: 'Maintenance Cost',
      manpower: 'Manpower',
      material: 'Material',
      name: 'Name',
      refName: 'Ref.name',
      removeUnitaryCost: 'Are you sure you want to remove this unitary cost?',
      specialOperations: 'Special Operations',
      um: 'Unit of measure',
      unitaryCost: 'Unitary Cost',
      unitaryCostHistory: 'Unitary Cost History',
      yearlyBudget: 'Yearly budget',
      yearToDate: 'Year to date'
    },
    table: {
      noData: 'No data available',
      header: {
        quantity: 'Nº',
        type: 'Type',
        hourlyCost: 'Hourly Cost',
        yearlyBudget: 'Yearly Budget',
        yearlyCost: 'Yearly cost',
        actualCost: 'Actual cost',
        budgetCost: 'Budget Cost',
        budgetAnnualLoad: 'Budget Annual Load',
        name: 'Name',
        area: 'Area',
        unitaryValue: 'Unitary Value',
        periodicity: 'Periodicity',
        unitaryCost: 'Unitary Cost',
        fromDate: 'From date',
        cost: 'Cost',
        user: 'User',
        operatorCost: 'Operator cost',
        materialCost: 'Material cost',
        total: 'TOTAL',
        shifts: 'Shifts',
        budgetWorkingHours: 'Budget working hours',
        actualWorkedHours: 'Actual worked hours',
        role: 'Role',
        productionOnHours: 'Production ON hours'
      }
    },
    setup: {
      productivityData: 'Productivity Data',
      budget: 'Budget {{um}}',
      shifts: 'Shifts',
      hoursShift: 'Hours/shift',
      workingDays: 'Wording days',
      totalYearlyBudget: 'Total yearly budget',
      maintenanceOperatorCost: 'Maintenance operator cost'
    },
    types: {
      manual_loading: 'Manual loading',
      manualDataLoading: 'Manual data loading',
      operators: 'Operators',
      continue_loading: 'Continue loading',
      lineOperators: 'Line operators',
      maintenanceOperations: 'Maintenance Operations',
      runningCosts: 'Running Costs',
      specialOperations: 'Special Operations'
    },
    graph: {
      specialOperationCost: 'Special Operations',
      maintenanceOperationsCost: 'Maintenance Operations',
      lineOperatorsCost: 'Line Operators',
      runningCostsCost: 'Running Cost',
      manualDataLoadingCost: 'Manual Data Loading',
      actualCost: 'Actual Cost'
    },
    consumptionWidget: {
      button: {
        label: 'Manual Data Loading',
      },
      modal: {
        title: 'Manual Data Loading',
        fields: {
          type: 'Type',
          qta: 'Quantity',
          date: 'Date',
        },
        cancel: 'Cancel',
        submit: 'Submit',
      },
      notifications: {
        submitSuccess: 'Data saved successfully',
        submitError: 'Error submitting data',
        allRequired: 'All fields are required',
      },
    },
  },
}

export default en
