import { Container } from 'inversify'
import React from 'react'

export const getContainer: () => Container = () => new Container()

export const TYPES = {
  TranslationService: Symbol.for('TranslationService'),
  IconComponentService: Symbol.for('IconComponentService'),
}

interface ContainerContext {
  container: Container | null
}

export const InversifyContext = React.createContext<ContainerContext>({ container: null })

interface ContainerProviderPros {
  container: Container
}

/**
 * **Example of implementation**
 * ```js
 *   const container = getContainer()
 *   container.bind<TranslationService>(TYPES.TranslationService).toConstantValue(new TranslationService(i18next))
 *   container.bind<IconComponentService>(TYPES.IconComponentService).toConstantValue(new IconComponentService(FontAwesomeIcon))
 *   <ContainerProvider container={container}>
 *     {MyApplication}
 *   </ContainerProvider>
 * ```
 */

export const ContainerProvider: React.FC<ContainerProviderPros> = ({ container, children }) => (
  <InversifyContext.Provider value={{ container }}>{children}</InversifyContext.Provider>
)
