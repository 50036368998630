const en = {
  general: {
    mv4iot: 'MV4IoT',
    userLogout: 'Logout',
    userManagement: 'Users',
  },

  lastUpdate: 'Last update: {{count}} min ago',
  loginEmailLabel: 'Email',
  loginEmailPlaceholder: 'Insert your email',
  loginPasswordLabel: 'Password',
  loginPasswordPlaceholder: 'Insert your password',
  loginSubmitText: 'Login',
  navDashboardLinkText: 'Dashboard',
  navPlantStatusLinkText: 'Plant Status',
  navProductionControlLinkText: 'Production Control',
  navMaintenanceLinkText: 'Maintenance',
  navSettingsLinkText: 'Settings',

  navMaintenanceCalendarLinkText: 'Calendar',
  navMaintenancePlanLinkText: 'Plan',
  navMaintenanceManagementLinkText: 'Setup',

  maintenanceCalendar: 'Calendar',
  maintenanceManagement: 'Management',
  maintenanceNewIntervention: 'New Intervention',

  maintenanceOperation: 'Operation',
  maintenanceSection: 'Section',
  maintenanceType: 'Type',
  maintenancePeriodicity: 'Periodicity',
  maintenanceHours: 'Hours',
  maintenanceNotes: 'Notes',

  close: 'Close',
  save: 'Save',

  dateRangeToday: 'Today',
  dateRangeLastDay: 'Yesterday',
  dateRange7Days: '1 Week',
  dateRangeLastWeek: 'Last Week',
  dateRange30Days: '1 Month',
  dateRangeLastMonth: 'Last Month',
  dateRangeLast6Month: '6 Months',
  dateRangeLastYear: 'Last Year',
  dateRangeYear: 'This Year',

  performance: 'Performance',
  availability: 'Availability',
  quality: 'Quality',

  errors: {
    code404: {
      title: 'Not found',
      message: 'The page you are looking for cannot be found.',
    },
  },
  widget: {
    rtmeasures: {
      title: 'MONITOR',
    },
  },
  dateRangeFiler: {
    pleaseSelect: 'Seleziona date',
  },
}

export default en
