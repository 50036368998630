import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { fetchQualityDetailsData } from '../../../../redux/actions/graphs'
import GraphQualitySingle from '../../widgets/GraphQualitySingle/GraphQualitySingle'
import { Card, CardBody, PageHeader } from '@mv-submodules/inplant-components-fe';
import DateBaseFilters
  from "@mv-submodules/inplant-core-fe/ui/components/widgets/dateFiltersBase/components/DateBaseFilters";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface QualityDetailsStateProps {
  qualityDetailsData?: any
  hasErrored: boolean
  isLoading: boolean
  columns?: string[]
  lastDateUpdate: number | null
}

interface QualityDetailsDispatchProps {
  fetchData: () => Function
}

interface QualityDetailsOwnProps {
  // t: TranslationFunction
  history: any
  match: any
}

interface QualityDetailsOwnState {
  lastUpdate: number | null
  legend: any | null
}

const mapStateToProps = (state: any): QualityDetailsStateProps => ({
  qualityDetailsData: state.qualityDetailsData.qualityDetailsData,
  hasErrored: state.qualityDetailsData.hasErrored,
  isLoading: state.qualityDetailsData.isLoading,
  columns: state.qualityDetailsData.columns,
  lastDateUpdate: state.dateFilters.lastUpdate,
})

const mapDispatchToProps = (dispatch: Function): QualityDetailsDispatchProps => ({
  fetchData: () => dispatch(fetchQualityDetailsData()),
})

type QualityDetailsProps = QualityDetailsStateProps &
  QualityDetailsDispatchProps &
  QualityDetailsOwnProps &
  WithTranslation

class QualityDetailsPageView extends React.Component<QualityDetailsProps, QualityDetailsOwnState> {
  constructor(props: QualityDetailsProps) {
    super(props)
    this.state = {
      lastUpdate: null,
      legend: null,
    }
  }

  public componentWillMount() {
    this.props.fetchData()
  }

  public componentWillReceiveProps(newProps: QualityDetailsStateProps) {
    if (this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.props.fetchData()
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
    }
  }

  public render() {
    return (
      <React.Fragment>
        <PageHeader title={"Quality Details"} />

        <div className="content">
          <div id="filters-wrapper">
            <Row spacing={{vertical:false,horizontal:false}}>
              <Column xs={12} >
                <Card bgLight={true}>
                  <CardBody noGutters={true}>
                    <DateBaseFilters />
                  </CardBody>
                </Card>
              </Column>
            </Row>
          </div>

          <Row spacing={{vertical:false,horizontal:false}} >
            <Column xs={12}>
              <GraphQualitySingle fieldSelected={'color'} {...this.props} />
            </Column>
          </Row>

          <Row spacing={{vertical:false,horizontal:false}}>
            <Column xs={12}>
              <GraphQualitySingle fieldSelected={'coverage'} {...this.props} />
            </Column>
          </Row>

          <Row spacing={{vertical:false,horizontal:false}}>
            <Column xs={12}>
              <GraphQualitySingle fieldSelected={'dent'} {...this.props} />
            </Column>
          </Row>

          <Row spacing={{vertical:false,horizontal:false}}>
            <Column xs={12}>
              <GraphQualitySingle fieldSelected={'impurity'} {...this.props} />
            </Column>
          </Row>

          <Row spacing={{vertical:false,horizontal:false}}>
            <Column  xs={12}>
              <GraphQualitySingle fieldSelected={'other'} {...this.props} />
            </Column>
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(QualityDetailsPageView))
)
