import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'

export const API = () => FetchWrapper.getInstance('maintenance')

export { fetchJobs, addJob, patchJob, deleteJob } from './jobs'

export { fetchPageFilters, setPageFilterSelectedValues } from './pageFilters'

export {
  markScheduledJobAsDone,
  rescheduleScheduledJob,
  rejectScheduledJob,
  addScheduledJob,
  getImage,
  bulkActions,
  ackScheduledJob,
  fetchPlanPdf,
} from './scheduledJob'

export { fetchTargetInfo } from './plant'

export { sendUndo, sendValidate, sendFailValidate } from './deadlineActions'
