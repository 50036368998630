import { AnyAction } from 'redux'
import { DueDate } from '../../types/dueDates'
import { FormData } from '../../types/pageForm'

interface PageListState {
  isFetching: boolean
  data?: DueDate[]
  filters?: FormData
  error?: Error
}

const initialState: PageListState = {
  isFetching: false,
}

export function pageList(state: PageListState = initialState, action: AnyAction): PageListState {
  switch (action.type) {
    case 'TASK_LIST_IS_FETCHING':
      return {
        ...state,
        filters: action.filters,
        isFetching: true,
        data: undefined,
        error: undefined,
      }

    case 'TASK_LIST_FETCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        data: action.data,
      }

    case 'TASK_LIST_FETCH_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }

    case 'TASKS_ASSIGNMENT_SUCCESS':
      return {
        ...state,
        data:
          state.data &&
          state.data.map(task =>
            action.ids.includes(task.id) ? { ...task, assigneeUser: action.assigneeUser } : task
          ),
      }

    case 'TASKS_STATUS_CHANGE_SUCCESS':
      return {
        ...state,
        data:
          state.data &&
          state.data.map(task => (action.ids.includes(task.id) ? { ...task, status: action.status } : task)),
      }

    default:
      return state
  }
}
