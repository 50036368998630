import { icon, library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import * as Noty from 'noty'
import i18n from '@mv-submodules/inplant-config/config/i18n'

library.add(faExclamationTriangle, faCheck)

export const popupNotification = ( options: Noty.Options & { content?: string } ) => {
  const textContent = options.content || `${
    icon(options.type !== 'warning' && options.type !== 'alert' && options.type !== 'error' ? faCheck : faExclamationTriangle).html
  } <strong>${i18n.t(`designer.notification.${options.type}`)}</strong><br>${options.text}`
  new Noty({
    closeWith: ['click', 'button'],
    id: 'designer-noty-container',
    layout: 'topRight',
    text: textContent,
    theme: 'bootstrap-v4',
    timeout: 5000,
    type: options.type || 'information',
  }).show()
}

/**
 * Console notications
 *
 * It is usually used to notify missing configurations or setups to be completed
 * @param message string
 * @param title string
 * @param type string
 */
export const consoleNotification = (
  message: string,
  title?: string,
  type: 'alert' | 'success' | 'warning' | 'error' | 'danger' | 'info' | 'information' = 'warning'
) => {
  const consoleColors = {
    alert: '#dc8822',
    success: '#22aa33',
    warning: '#dc8822',
    error: '#cc2222',
    danger: '#cc2222',
    info: '#ccaa66',
    information: '#ccaa66'
  }

  if(title) {
    console.group( // tslint:disable-line
      `%c${title}`,
      `background-color: ${consoleColors[type]} ; color: #ffffff ; font-weight: bold ; padding: 4px ;`,
    )
  }
  console.log(`%c${message}`, `font-weight: bold ; padding: 4px ;`) // tslint:disable-line
  if (title){
    console.groupEnd() // tslint:disable-line
  }
}
