import * as React from 'react'
import * as moment from 'moment'
import {
  MaintenanceScheduledJob,
  MaintenanceRelatedJob,
  MaintenanceHistory,
  MaintenanceJobType,
  MaintenanceJobPeriodicity,
  MaintenanceStatus,
} from '../../../../types'
import { getDateFormat, trunc, getScheduleJobUIStatus } from '../../../../functions/shared'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Card, CardBody } from '@mv-submodules/inplant-components-fe'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface Props {
  scheduledJob: MaintenanceScheduledJob
}

class RelatedJobs extends React.Component<Props & WithTranslation> {
  constructor(props: Props & WithTranslation) {
    super(props)
    this.renderNote = this.renderNote.bind(this)
    this.getActionDescription = this.getActionDescription.bind(this)
    this.getDoneEntry = this.getDoneEntry.bind(this)
    this.getPeriodicity = this.getPeriodicity.bind(this)
    this.isDateHighlighted = this.isDateHighlighted.bind(this)
    this.getStatus = this.getStatus.bind(this)
    this.getDate = this.getDate.bind(this)
    this.getUser = this.getUser.bind(this)
    this.getNotes = this.getNotes.bind(this)
  }

  private renderNote(notes: string | null) {
    return notes && notes.length > 0 ? trunc(notes) : null
  }

  private getActionDescription(action: { type: string; rescheduleDate?: moment.Moment | null }) {
    let desctiption = this.props.t(`maintenance.scheduledJobDetail.historyActions.type.${action.type}`)
    if (action.rescheduleDate && action.rescheduleDate !== null) {
      desctiption += ` ${this.props.t(
        'maintenance.scheduledJobDetail.historyActions.on'
      )} ${action.rescheduleDate.format(getDateFormat())}`
    }
    return desctiption
  }

  private getDoneEntry(relatedJob: MaintenanceRelatedJob): MaintenanceHistory | null | undefined {
    const { history } = relatedJob
    let info
    for (const his of history) {
      const element = his as MaintenanceHistory
      if (element.action.type === 'done') {
        info = element
        break
      }
    }
    return info
  }

  private getStatus(relatedJob: MaintenanceRelatedJob): string {
    let status = getScheduleJobUIStatus(relatedJob.status)
    if (relatedJob.isDoneLate) {
      status += ' ' + this.props.t(`maintenance.scheduledJobDetail.relatedJobs.late`)
    }
    return status.toLocaleUpperCase()
  }

  private getDate(relatedJob: MaintenanceRelatedJob): string {
    const { status, dueDate } = relatedJob

    if (status !== MaintenanceStatus.DONE) {
      return dueDate.format(getDateFormat())
    }

    const doneEntry = this.getDoneEntry(relatedJob)
    if (doneEntry) {
      return moment(doneEntry.actionDate).format(getDateFormat())
    } else {
      return ''
    }
  }

  private getUser(relatedJob: MaintenanceRelatedJob): string {
    const doneEntry = this.getDoneEntry(relatedJob)
    if (doneEntry) {
      return doneEntry.userFullname
    } else {
      return ''
    }
  }

  private getNotes(relatedJob: MaintenanceRelatedJob): string {
    const doneEntry = this.getDoneEntry(relatedJob)
    if (doneEntry) {
      return doneEntry.notes
    } else {
      return ''
    }
  }

  private getPeriodicity() {
    const { maintenance } = this.props.scheduledJob
    const chunks: string[] = []
    if (maintenance && maintenance.periodicity) {
      chunks.push(this.props.t('maintenance.periodicity.' + MaintenanceJobPeriodicity[maintenance.periodicity]))
    }
    if (maintenance && maintenance.totalHours) {
      chunks.push(`${maintenance.totalHours} ${this.props.t('maintenance.scheduledJobDetail.relatedJobs.hours')}`)
    }
    return chunks.length ? <small>{chunks.join(' - ')}</small> : null
  }

  private isDateHighlighted(relatedJob: MaintenanceRelatedJob): boolean {
    const { status } = relatedJob
    return status === MaintenanceStatus.OVERDUE
  }

  public render() {
    const { relatedJobs, jobType } = this.props.scheduledJob

    if (jobType !== MaintenanceJobType.MAINTENANCE || relatedJobs.length === 0) {
      return null
    }

    return (
      <div className="related-jobs mt-5">
        <div className="mb-3">
          <div className="h5 mb-0">{this.props.t('maintenance.scheduledJobDetail.relatedJobs.title')}</div>
          <span className="text-muted">{this.getPeriodicity()}</span>
        </div>
        {relatedJobs.map((relatedJob: MaintenanceRelatedJob, index: number) => (
          <Card marginBottom={2} key={index}>
            <a className="card-header collapsed" data-toggle="collapse" href={`#related-job-${index}`}>
              <div className="infos">
                <span className="infos__indicator">
                  <IconComponent icon={'angle-right'} />
                </span>
                <span className="infos__status">{this.getStatus(relatedJob)}</span>
                <span className={`infos__date ${this.isDateHighlighted(relatedJob) ? 'infos--highlighted' : ''}`}>
                  {this.getDate(relatedJob)}
                </span>
                <span className="infos__user">{this.getUser(relatedJob)}</span>
                <span className="infos__notes">{this.getNotes(relatedJob)}</span>
              </div>
            </a>
            <div className="collapse" id={`related-job-${index}`} aria-expanded="false">
              <CardBody>
                {relatedJob.history.length > 0 && (
                  <table className="table table-sm table-fz-sm">
                    <thead>
                      <tr>
                        <th>{this.props.t('maintenance.scheduledJobDetail.relatedJobs.date')}</th>
                        <th>{this.props.t('maintenance.scheduledJobDetail.relatedJobs.user')}</th>
                        <th>{this.props.t('maintenance.scheduledJobDetail.relatedJobs.action')}</th>
                        <th>{this.props.t('maintenance.scheduledJobDetail.relatedJobs.notes')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {relatedJob.history.map((entry: MaintenanceHistory) => (
                        <tr key={index}>
                          <td>{entry.actionDate.format(`${getDateFormat()} HH:mm`)}</td>
                          <td>{entry.userFullname}</td>
                          <td>{this.getActionDescription(entry.action)}</td>
                          <td>{this.renderNote(entry.notes)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {relatedJob.history.length <= 0 && (
                  <small>{this.props.t('maintenance.scheduledJobDetail.relatedJobs.noHistoryAvailable')}</small>
                )}
              </CardBody>
            </div>
          </Card>
        ))}
      </div>
    )
  }
}

export default withTranslation()(RelatedJobs)
