import * as React from 'react'
import {itemsCostsFetchData, fetchLot} from '../../../../redux/actions/service'
import {LotCostListItem, ProductionListItem} from '../../../../types/ProductionListItem'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import * as moment from 'moment'
import {Modal, Table} from '@mv-submodules/inplant-components-fe'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface StateProps {
  item: number | string | null
  lotId: number | string | null
  closeModalFn: () => void
  // t: TranslationFunction
}

interface OwnState {
  lotNumber: number | string | null
  lot: ProductionListItem | null
  costs: any[]
  total: number | null
  isLoading: boolean
  currency: string
}

interface ProductionCostsStateProps {
  lot: ProductionListItem
  items: LotCostListItem[]
  hasErrored: boolean
  isLoading: boolean
}

interface ProductionCostsDispatchProps {
  fetchData: Function
  fetchLot: Function
}

const mapStateToProps = (state: any): ProductionCostsStateProps => {
  return {
    lot: state.production.lot,
    items: state.production.itemsCosts,
    hasErrored: state.production.hasCostsErrored,
    isLoading: state.production.isCostsLoading,
  }
}

const mapDispatchToProps = (dispatch: Function): ProductionCostsDispatchProps => {
  return {
    fetchData: (id: number) => dispatch(itemsCostsFetchData(id)),
    fetchLot: (id: number) => dispatch(fetchLot(id)),
  }
}

type Props = ProductionCostsStateProps & ProductionCostsDispatchProps & StateProps

class CostsModal extends React.Component<Props & WithTranslation, OwnState> {
  constructor(props: Props & WithTranslation) {
    super(props)

    this.state = {
      lotNumber: null,
      lot: null,
      costs: [],
      total: null,
      isLoading: true,
      currency: '',
    }
  }

  private static roundToDecimals(num: number, decimals: number): string {
    const divider = Math.round(Math.pow(10, decimals))
    return (Math.round(num * divider) / divider).toFixed(decimals)
  }

  // private static scientificNotation(num: number, decimals: number): string {
  //   return num.toExponential(decimals)
  // }

  public componentWillMount(): void {
    Promise.all([this.props.fetchData(this.props.item), this.props.fetchLot(this.props.item)]).then(() => {
      // todo don't assume currency is always the same!
      const total = this.props.items.reduce((a, b) => a + b.total, 0)
      let currency = ''
      if (this.props.items.length > 0) {
        currency = this.props.items[0].currency
      }

      this.setState({
        lot: this.props.lot,
        costs: this.props.items,
        total,
        lotNumber: this.props.item,
        isLoading: this.props.isLoading,
        currency,
      })
    })
  }

  public render() {
    const columns = [
      {
        Header: this.props.t('production.costsModal.name'),
        accessor: 'name',
        Cell: (row: any) => (
          <div>
            {row.value} ({row.original.type})
          </div>
        ),
      },
      {
        Header: this.props.t('production.costsModal.consumption'),
        accessor: 'consumption',
        Cell: (row: any) => (
          <div>
            {CostsModal.roundToDecimals(row.value / Math.max(1, this.state.lot ? this.state.lot.pieces : 1), 4)}
            &nbsp;
            {row.original.measure}
          </div>
        ),
      },
      {
        Header: this.props.t('production.costsModal.cost'),
        accessor: 'total',
        Cell: (row: any) => (
          <div>
            {row.original.currency}{' '}
            {CostsModal.roundToDecimals(row.value / Math.max(1, this.state.lot ? this.state.lot.pieces : 1), 2)}
          </div>
        ),
      },
    ]

    if (!this.state.costs) {
      return null
    }

    return (
      <Modal
        visible={true}
        title={`${this.props.t('production.costsModal.costsPerItemInLots')} ${this.state.lotNumber}`}
        closeLabel={this.props.t('production.costsModal.actions.close')}
        onClose={this.props.closeModalFn}
      >
        {this.state.lot !== null && (
          <Row>
            <Column sm={3}>{this.props.t('production.costsModal.timeLoadFirst')}</Column>
            <Column sm={9}>{moment(this.state.lot.startTime).format('YYYY-MM-DD HH:mm:ss')}</Column>
            <Column sm={3}>{this.props.t('production.costsModal.timeDownloadLast')}</Column>
            <Column sm={9}>{moment(this.state.lot.endTime).format('YYYY-MM-DD HH:mm:ss')}</Column>
            <Column sm={3}>{this.props.t('production.costsModal.pieces')}</Column>
            <Column sm={9}>{this.state.lot.pieces}</Column>
          </Row>
        )}
        {this.state.total !== null && (
          <Row>
            <Column sm={3}>{this.props.t('production.costsModal.totalCost')}</Column>
            <Column sm={9}>
              {this.state.currency}{' '}
              {CostsModal.roundToDecimals(
                this.state.total / Math.max(1, this.state.lot ? this.state.lot.pieces : 1),
                2,
              )}
            </Column>
          </Row>
        )}

        <Table
          columns={columns}
          data={this.state.costs}
          className="production-costs-table"
          isFetching={this.state.isLoading}
          showPagination={false}
          noDataText="No data"
        />
      </Modal>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CostsModal))
