const it = {
  production: {
    module: 'Dashboard',
    download: {
      buttonLabel: 'ESPORTA CSV',
    },
    fetch: {
      error: 'Errore durante il caricamento dei dati',
    },
    labels: {
      date: 'Data',
      startTime: 'Orario primo caricamento',
      endTime: 'Orario ultimo scarico',
      pieces: 'Pezzi',
      lot: 'Lotto',
      id: 'ID',
      serial: 'Seriale',
      scrapsInLine: 'Scarti in linea',
      startTimeHours: 'Primo caricamento',
      clear: 'Pulisci',
      download_time: 'Tempo scarico',
      booth: 'Booth',
      recipe: 'Codice Ricetta',
      color_code: 'Codice Colore',
      batch: 'Lotto',
      processTime: 'Tempo lavorazione',
      tunnelTime: 'Tempo Tunnel',
      rg_soc: 'Rag. Soc.',
      cod_parte: 'Part Code'
    },
    list: {
      actions: {
        'alarms-modal': 'Allarmi',
        'costs-modal': 'Costi',
        'details-modal': 'Dettagli',
      },
      buttonLabel: 'Azioni',
      title: 'Produzione',
    },
    detailsModal: {
      title: 'Dettagli lotto',
      actions: {
        close: 'Chiudi',
      },
    },
    costsModal: {
      costsPerItemInLots: 'COSTI PER OGGETTO PER LOTTO',
      name: 'Nome',
      consumption: 'Consumo',
      cost: 'Costo',
      timeLoadFirst: 'Tempo Primo Caricamento',
      timeDownloadLast: 'Tempo Ultimo Scaricamento',
      pieces: 'Pezzi',
      totalCost: 'Totale costo per pezzo',
      actions: {
        close: 'CHIUDI',
      },
    },
    alarmModal: {
      iTime: 'Entrata',
      aTime: 'Accettazione',
      oTime: 'Uscita',
      msgClass: 'Tipo',
      message: 'Testo',
      cabinet: 'Cabinet / componente',
      alarms: 'ALLARMI',
      actions: {
        close: 'CHIUDI',
      },
    },
    table: {
      summary: {
        showing: 'Visualizzo',
        of: 'di',
        from: 'da',
        to: 'a',
        rows: 'righe',
        totalPieces: "Total pieces",
        scrapsInLine: "Scraps in line"
      },
      general: {
        page: "Pagina"
      }
    },
    confirm: {
      downloadWithNoDateRangeSelected: 'Non hai selezionato un intervallo di date, sei sicuro di voler scaricare TUTTI i dati?'
    }
  },
}

export default it
