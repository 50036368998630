// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import '../../../styles/_index.scss'

export type BadgeType = 'positive' | 'informative' | 'warning' | 'negative' | 'neutral'

interface Props {
  type: BadgeType,
  value: string,
  subvalue?: string
}

class Badge extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {type, value, subvalue} = this.props
    return (
      <span className={`badge ${type}`}>
        {value}
        {subvalue && (
          <>
            <br/>
            <small className="font-weight-bold">{subvalue}</small>
          </>
        )}
      </span>
    )
  }
}

export default Badge