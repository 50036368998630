import * as React from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {fetchPerformanceData} from '../../../../redux/actions/graphs'
import DownloadDataButton
  from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import * as moment from 'moment'
import {Bar, ComposedChart, Label, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {timeRangeFileString, TPRNCAlarmsData} from '../../../../functions/shared'
import {AlertNoData} from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/SharedComponents/sharedComponents';
import {Card, CardBody, CardFooter, CardHeader} from '@mv-submodules/inplant-components-fe';
import {saveDataAsCSV} from "@mv-submodules/inplant-core-fe/functions";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface GraphPerformanceStateProps {
  range: null | string
  data?: any
  lineData?: any
  hasErrored: boolean
  isLoading: boolean
  columns?: string[]
  lastDateUpdate: number | null
  dateStart: string | null
  dateEnd: string | null
  measureUnits: object
  cliccable?: boolean
  TPRNCData: any
  TPRNCDataErrors: boolean
  TPRNCDataIsLoading: boolean
}

interface GraphPerformanceDispatchProps {
  fetchData: () => Function
}

interface GraphPerformanceOwnProps {
  // t: TranslationFunction
  history: any
}

interface GraphPerformanceOwnState {
  lastUpdate: number | null
}

const mapStateToProps = (state: any): GraphPerformanceStateProps => ({
  range: state.dateFilters.range,
  data: state.graphPerformanceData.data,
  lineData: state.graphPerformanceData.lineData,
  hasErrored: state.graphPerformanceData.hasErrored,
  isLoading: state.graphPerformanceData.isLoading,
  columns: state.graphPerformanceData.columns,
  lastDateUpdate: state.dateFilters.lastUpdate,
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
  measureUnits: state.config.generic.measureUnits || {},
  TPRNCData: state.graphTPRNCData.data,
  TPRNCDataErrors: state.graphTPRNCData.hasErrored,
  TPRNCDataIsLoading: state.graphTPRNCData.isLoading,
})

const mapDispatchToProps = (dispatch: Function): GraphPerformanceDispatchProps => ({
  fetchData: () => dispatch(fetchPerformanceData()),
})

type GraphPerformanceProps = GraphPerformanceStateProps &
  GraphPerformanceDispatchProps &
  GraphPerformanceOwnProps &
  WithTranslation

class GraphPerformance extends React.Component<GraphPerformanceProps, GraphPerformanceOwnState> {
  constructor(props: GraphPerformanceProps) {
    super(props)
    this.state = {
      lastUpdate: null,
    }

    this.CustomTooltip = this.CustomTooltip.bind(this)
  }

  public componentWillMount() {
    this.props.fetchData()
  }

  public componentWillReceiveProps(newProps: GraphPerformanceStateProps) {
    if (newProps.lastDateUpdate !== null && this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
      this.props.fetchData()
    }
  }

  private CustomTooltip({active, payload, label}: any) {
    if (active && payload && payload[0]) {
      const payloadData = payload[0].payload
      return (
        <div className="recharts-default-tooltip">
          <ul>
            <li className="title">
              <strong>
                {moment(label).format(
                  this.props.range &&
                  ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(
                    this.props.range,
                  )
                    ? 'MM/YY'
                    : 'DD/MM',
                )}
              </strong>
            </li>
            {Object.keys(payloadData)
              .filter(e => e !== 'time')
              .map((line: any, index: number) => {
                if (line === 'nperformance') {
                  return
                }

                return <li key={index}>
                  <strong
                    style={{
                      color: (line === 'performance' && payloadData.performance !== payloadData.nperformance ?
                          '#c1521f' :
                          'black'
                      )
                    }}>
                    {this.props.t('i4efficiency.performance.' + line, {defaultValue: line})}
                    {line === 'performance' ? ' (%)' : ''}
                  </strong>
                  <span className="value float-right">
                    {
                      payloadData[line] && line !== 'time' ?
                        payloadData[line].toFixed(2) :
                        payloadData[line]
                    }
                  </span>
                </li>
              })}
          </ul>
        </div>
      )
    }

    return null
  }

  public render() {
    const {dateStart, dateEnd, TPRNCData} = this.props
    let downloadData: string[][] =
      this.props.data // && this.props.lineData
        ? this.props.data.map((e: any, i: number) => {
          return [
            moment(e[0]).format('YYYY/MM/DD'),
            e[1],
            this.props.lineData && this.props.lineData[i] ? this.props.lineData[i][2] * 100 : null,
          ]
        })
        : []

    downloadData = [
      ['time', 'pieces', 'performance'].map((c: string) => `${c}${this.props.measureUnits[c] ? ` (${this.props.measureUnits[c]})` : ''}`),
    ].concat(downloadData)

    const timeFormat =
      this.props.range &&
      ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(this.props.range)
        ? 'MM/YYYY'
        : 'DD/MM'

    const data =
      this.props.data &&
      this.props.data.map((e: any, i: number) => {
        return {
          time: e[0],
          pieces: e[1],
          nperformance: this.props.lineData && this.props.lineData[i] ? this.props.lineData[i][1] * 100 : null,
          performance: this.props.lineData && this.props.lineData[i] ? this.props.lineData[i][2] * 100 : null,
        }
      })

    const tprncData = TPRNCAlarmsData(dateStart, dateEnd, data, TPRNCData)

    const CustomizedDot = (props: any) => {
      const {
        cx, cy, payload
      } = props;

      if (payload.performance !== payload.nperformance) {
        return (
          <svg x={cx - 9} y={cy - 30} width={70} height={140} fill="red" viewBox="0 0 10 20">
            <g
              transform="translate(0,-291.70832)">
              <g>
                <path
                  d="m 2.2678572,295.93103 a 0.93903458,0.93903458 0 0 1 -0.9390346,0.93903   0.93903458,0.93903458 0 0 1 -0.93903457,-0.93903 0.93903458,0.93903458 0 0 1 0.93903457,-0.93903 0.93903458,0.93903458 0 0 1 0.9390346,0.93903 z"
                  style={{fill: '#ffa44f', fillOpacity: 1, stroke: 'none', strokeWidth: 0.20929725, strokeOpacity: 1}}/>
                <g
                  style={{stroke: 'none'}}>
                  <path
                    style={{fill: '#dc3545', fillOpacity: 1}}
                    d="m 1.5567409,292.32452 q 0,0.0796 -0.00847,0.1542 -0.00847,0.0729 -0.022029,0.16437 l -0.1186192,0.86084 H 1.2432474 L 1.133101,292.70918 q -0.00847,-0.0593 -0.015251,-0.11014 -0.00678,-0.0508 -0.013556,-0.0966 -0.00678,-0.0458 -0.010167,-0.0898 -0.00339,-0.044 -0.00339,-0.0915 0,-0.20165 0.061004,-0.29486 0.062699,-0.0932 0.1762341,-0.0932 0.05592,0 0.098284,0.0203 0.042364,0.0186 0.071171,0.0644 0.030502,0.0458 0.044059,0.12201 0.015251,0.0746 0.015251,0.18471 z m 0.03728,1.60644 q 0,0.0559 -0.020335,0.10506 -0.020335,0.0491 -0.055921,0.0847 -0.035586,0.0356 -0.084728,0.0576 -0.047448,0.0203 -0.1016735,0.0203 -0.055921,0 -0.1050627,-0.0203 -0.049142,-0.022 -0.084728,-0.0576 -0.035586,-0.0356 -0.057615,-0.0847 -0.020335,-0.0491 -0.020335,-0.10506 0,-0.0542 0.020335,-0.10167 0.022029,-0.0491 0.057615,-0.0847 0.035586,-0.0356 0.084728,-0.0559 0.049142,-0.0203 0.1050627,-0.0203 0.054226,0 0.1016735,0.0203 0.049142,0.0203 0.084728,0.0559 0.035586,0.0356 0.055921,0.0847 0.020335,0.0474 0.020335,0.10167 z"/>
                </g>
              </g>
            </g>
          </svg>
        );
      }

      return null;
    };

    return (
      <div className={`DashboardWidget WidgetPerformance WidgetH50 ${this.props.cliccable ? 'cliccable' : ''}`}>
        <Card marginBottom={3}>
          <CardHeader>
            <span className="text-uppercase">{this.props.t('i4efficiency.performance.performance')}</span>
            {data && downloadData && (
              <DownloadDataButton
                saveDataAsCSV={saveDataAsCSV}
                data={downloadData}
                type={'csv'}
                label={'i4efficiency.download.buttonLabel'}
                className={'float-right'}
                fileName={'performance' + timeRangeFileString(this.props.dateStart, this.props.dateEnd)}
                addTime={false}
              />
            )}
          </CardHeader>
          <CardBody>
            <div className="container-fluid chart-container">
              <div className="full-h">
                <Row verticalAlignment={"center"}>
                  <Column sm={12}>
                    <div className={"text-center full-h"}>
                      <div className="chart-container">
                        {data ? (
                          <ResponsiveContainer>
                            <ComposedChart
                              width={700}
                              height={450}
                              data={data}
                              margin={{
                                top: 45,
                                right: 50,
                                left: 0,
                                bottom: 0,
                              }}
                              className={this.props.cliccable ? 'cliccable' : ''}
                            >
                              <XAxis
                                dataKey="time"
                                tickFormatter={e => {
                                  return moment(e).format(timeFormat)
                                }}
                                axisLine={false}
                              />
                              <YAxis axisLine={false} yAxisId={'left'}>
                                <Label position={'top'} offset={30}>
                                  Qta
                                </Label>
                              </YAxis>

                              <YAxis
                                axisLine={false}
                                yAxisId={'right'}
                                orientation={'right'}
                                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                width={20}
                                interval={0}
                              >
                                <Label position={'top'} offset={30}>
                                  %
                                </Label>
                              </YAxis>

                              <Tooltip content={<this.CustomTooltip/>} cursor={{fill: 'none'}}/>
                              {
                                tprncData && tprncData.map(d => <ReferenceArea
                                  key={d.key}
                                  x1={d.x1}
                                  x2={d.x2}
                                  yAxisId={'right'}
                                  stroke={d.stroke}
                                  strokeOpacity={d.strokeOpacity}
                                  fill={d.fill}
                                  fillOpacity={d.fillOpacity}
                                />)
                              }
                              <Bar
                                dataKey="pieces"
                                fill="#7b6888"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                yAxisId={'left'}
                              />

                              <Line
                                dataKey={'nperformance'}
                                stroke="#d0743c"
                                yAxisId={'right'}
                                strokeWidth={2}
                                isAnimationActive={false}
                                dot={<CustomizedDot/>}
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        ) : (
                          <AlertNoData message={this.props.t('i4efficiency.fetch.noData')}/>
                        )}
                      </div>
                    </div>

                  </Column>
                </Row>

              </div>
            </div>
          </CardBody>
          <CardFooter
            textMuted={true}
            textAlign={'right'}
            textSizeSmall={true}
          >
            <ul className="legend float-left">
              <li>
                <span
                  className="legend-color legend-operating-color"/> {this.props.t('i4efficiency.performance.pieces')}
              </li>
            </ul>
            <ul className="legend float-right">
              <li>
                <span className="legend-color legend-performance-color"/> <span
                className="text-uppercase">{this.props.t('i4efficiency.performance.performance')}</span>
              </li>
            </ul>
          </CardFooter>
        </Card>
      </div>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(GraphPerformance)),
)
