import { AnyAction } from 'redux'
import { initialPlanStatusOverTimeState, PlanStatusOverTimeState } from '../index'

export const planStatusOverTime = (
  state: PlanStatusOverTimeState = initialPlanStatusOverTimeState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'FETCH_PLANTSTATUSOVERTIME_SUCCESS':
      return {
        ...state,
        data: action.data.results && action.data.results[0].series && action.data.results[0].series[0].values,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_PLANTSTATUSOVERTIME_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_PLANTSTATUSOVERTIME_ERROR':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}
