import * as React from 'react'
import Button, { ButtonProps } from '../Button/Button'

interface OwnStateProps {
  clickCallback: Function
  buttons: any
  selecteds: string[]
}

class ButtonsGroup extends React.Component<OwnStateProps> {
  public render() {
    return (
      <div className="btn-group-vertical">
        {this.props.buttons &&
          this.props.buttons.map((button: ButtonProps, index: number) => (
            <Button
              selected={(button.name && this.props.selecteds.includes(button.name)) || false}
              name={button.name}
              unit={button.unit}
              value={button.value}
              clickCallback={this.props.clickCallback}
              key={index}
            />
          ))}
      </div>
    )
  }
}

export default ButtonsGroup
