import i18next from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const i18nextInit = (options: any) =>
  i18next
    .use(LngDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      initImmediate: true,
      react: {
        wait: true,
      },
      detection: {
        order: ['htmlTag', 'navigator', 'querystring', 'cookie', 'localStorage', 'path', 'subdomain'],
      },
      interpolation: {
        escapeValue: false,
      },
      appendNamespaceToMissingKey: true,
      parseMissingKeyHandler: (key: string) => `[i18n: ${key}]`,
      ns: ['translation'],
      defaultNS: 'translation',
      ...options,
    })

export {
  i18next,
  i18nextInit
}
