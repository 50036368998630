import Details from './components/views/Details/DetailsPageView'
import Edit from './components/views/Edit/EditPageView'
import List from './components/views/List/ListPageView'
import New from './components/views/New/NewPageView'

export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey?: string
  icon?: any
  iconOverride?: string
  visible: boolean
  visibleInUserProfile?: boolean
  component?: any
  children?: RouteProps[]
  counterStoreKey?: string
  aclActionKey?: string
}

const routes: RouteProps = {
  path: '/user/list',
  i18nkey: 'user',
  visible: false,
  aclActionKey: 'user.user.create',
  children: [
    {
      path: '/user/list',
      exact: true,
      i18nkey: 'user.navigation.list',
      component: List,
      visible: false,
      visibleInUserProfile: true,
      aclActionKey: 'user.user.create',
    },
    {
      path: '/user/new',
      exact: true,
      i18nkey: 'user.navigation.new',
      component: New,
      visible: false,
      aclActionKey: 'user.user.create',
    },
    {
      path: '/user/details/:id',
      component: Details,
      visible: false,
      aclActionKey: 'user.user.create',
    },
    {
      path: '/user/edit/:id',
      component: Edit,
      visible: false,
      aclActionKey: 'user.user.create',
    },
  ],
}

export default routes
