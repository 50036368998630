import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { addScheduledJob } from '../../../../redux/actions'
import { MaintenanceType, MaintenanceScheduledJobPostParams } from '../../../../types'
/*tslint:disable*/
// const DateRangePickerImport = require('react-bootstrap-daterangepicker')
// const DateRangePicker = DateRangePickerImport.default
/*tslint:enable*/
import * as moment from 'moment'
import { capitalizeFirstLetter, getDateFormat, getDateLocale } from '../../../../functions/shared'
import {
  Alert,
  Button,
  DateInput,
  Input,
  Loader,
  Modal,
  PlantTreeView,
  Select,
  TextArea,
} from '@mv-submodules/inplant-components-fe'
import { fetchMaintenanceTypes } from '@mv-submodules/inplant-maintenance-fe/redux/actions/types'
import { fetchPlantStatus } from '@mv-submodules/inplant-maintenance-fe/redux/actions/plant'
import { SelectOptions } from '@mv-submodules/inplant-components-fe/ui/components/Input/Select'

export interface DispatchProps {
  fetchPlantStatus: (url?: string) => Promise<any>
  fetchMaintenanceTypes: () => Promise<any>
  addScheduledJob: (params: MaintenanceScheduledJobPostParams) => Promise<any>
}

export interface OwnStateProps {
  plantStatus?: any
  plantCurrentSelection: any | null
  operation: string
  typeId: string
  dueDate: moment.Moment,
  notes: string,
  isSubmitting: boolean,
  maintenanceTypes: MaintenanceType[],
  isFetching: boolean,
  fetchErrors: boolean
}

export interface OwnProps extends RouteComponentProps<any> {
  isVisible: boolean
  onClose: Function | null,
  plantStatus?: any
  maintenanceTypes?: MaintenanceType[]
  // t: TranslationFunction
}

export type Props = DispatchProps & OwnProps & WithTranslation

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchPlantStatus: () => dispatch(fetchPlantStatus()),
  fetchMaintenanceTypes: () => dispatch(fetchMaintenanceTypes()),
  addScheduledJob: (params: MaintenanceScheduledJobPostParams) => dispatch(addScheduledJob(params)),
})

class ScheduledJobAddModalComponent extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = this.getInitialState()
    this.onModalClose = this.onModalClose.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.handleTreeClick = this.handleTreeClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.removeTarget = this.removeTarget.bind(this)
    this.formatTarget = this.formatTarget.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  public componentDidMount() {
    this.fetchData()
  }

  private getInitialState() {
    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    return {
      plantStatus: this.state && this.state.plantStatus || undefined,
      plantCurrentSelection: null,
      operation: '',
      typeId: '',
      dueDate: today,
      notes: '',
      isSubmitting: false,
      isFetching: false,
      fetchErrors: false,
      maintenanceTypes: this.state && this.state.maintenanceTypes || [],
    }
  }

  private fetchData() {
    this.setState({ isFetching: true })
    Promise.all([
      this.props.maintenanceTypes ? this.props.maintenanceTypes : this.props.fetchMaintenanceTypes(),
      this.props.plantStatus ? this.props.plantStatus : this.props.fetchPlantStatus(),
    ])
      .then(([maintenanceTypes, plantStatus]) => {
        this.setState({
          plantStatus,
          maintenanceTypes,
        })
      })
      .catch((error: any) => {
        this.setState({
          fetchErrors: true,
        })
      })
      .finally(() => {
        this.setState({ isFetching: false })
      })
  }

  public resetState() {
    this.setState(this.getInitialState())
  }

  public onModalClose(reload: boolean = false) {
    this.resetState()
    if (this.props.onClose) {
      this.props.onClose(reload)
    }
  }

  public onConfirm() {
    return
  }

  public onSubmit(e: any) {
    e.preventDefault()
    this.setState({ isSubmitting: true })
    const params: MaintenanceScheduledJobPostParams = {
      targetId: this.state.plantCurrentSelection ? this.state.plantCurrentSelection.data.id : null,
      operation: this.state.operation,
      dueDate: this.state.dueDate,
      typeId: this.state.typeId,
      notes: this.state.notes,
    }

    this.props.addScheduledJob(params).then(() => {
      this.onModalClose(true)
    })
      .finally(() => {
        this.setState({
          isSubmitting: false,
        })
      })
  }

  public removeTarget() {
    this.setState({
      plantCurrentSelection: null,
    })
  }

  public handleChange(e?: any, state?: { name: string, value: string }) {
    const change = {}
    change[e ? e.target.name : state ? state.name : e!.target.name] = e ? e.target.value : state ? state.value : e!.target.value
    this.setState(change)
  }

  public handleTreeClick(item: any) {
    this.setState({ plantCurrentSelection: item })
  }

  private formatTarget(target: any): string {
    if (target.data.path) {
      const path = Object.assign([], target.data.path)
      path.splice(0, 1)
      return path.map((item: any) => capitalizeFirstLetter(item)).join(' > ')
    }
    return ''
  }

  private getSelectOptions(sources: MaintenanceType[], defaultOption?: string): SelectOptions {
    const options: SelectOptions = {
      defaultOption: defaultOption || undefined,
      items: [],
    }
    sources.map(source => {
      options.items.push({
        label: source.name,
        value: source.id,
      })
    })
    return options
  }

  public render() {
    const { maintenanceTypes, isFetching, fetchErrors, isSubmitting, plantStatus } = this.state
    const showErrors = !isFetching && (fetchErrors || plantStatus === undefined || !maintenanceTypes || maintenanceTypes.length === 0)
    const showFormComponents = !showErrors && maintenanceTypes.length > 0
    moment.locale(getDateLocale())
    return (
      <form onSubmit={this.onSubmit}>
        <Modal
          visible={this.props.isVisible}
          onClose={() => this.onModalClose()}
          title={this.props.t('maintenance.addScheduledJob.newIntervention')}
          closeLabel={this.props.t('maintenance.close')}
          additionalFooterButtons={[{
            label: this.props.t('maintenance.addScheduledJob.save'),
            isLoading: isSubmitting,
            type: 'submit',
            variant: 'primary',
          }]}
          width={75}
        >
          <>
            {isFetching && (
              <Loader/>
            )}
            {showErrors && (
              <Alert type="danger" title={this.props.t('maintenance.errors.somethingGoneWrong')}/>
            )}
            {showFormComponents && (
              <>
                <div className="row">
                  <div className="col-sm-6 treeview-container">
                    <PlantTreeView
                      data={plantStatus.children}
                      currentSelection={this.state.plantCurrentSelection}
                      onClick={this.handleTreeClick}
                      height="small"
                    />
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="clearable-input col-sm-12">
                        <Input
                          label={this.props.t('maintenance.addScheduledJob.target')}
                          type="text"
                          disabled={true}
                          placeholder={this.props.t('maintenance.addScheduledJob.targetPlaceholder')}
                          value={
                            this.state.plantCurrentSelection ? this.formatTarget(this.state.plantCurrentSelection) : ''
                          }
                          // tslint:disable-next-line: no-empty
                          onChange={() => {
                          }}
                        />
                        {this.state.plantCurrentSelection ? (
                          <div className="input-group-append form-group">
                            <Button variant="primary" icon={'times'} onClick={this.removeTarget}/>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-12">
                        <Input
                          name="operation"
                          label={this.props.t('maintenance.addScheduledJob.operation')}
                          type="text"
                          id="operation"
                          value={this.state.operation}
                          onChange={(value) => this.handleChange(undefined, {
                            name: 'operation',
                            value: value as string,
                          })}
                          required={true}
                        />
                      </div>
                      <div className="form-group col-sm-12">
                        <Select
                          name="typeId"
                          id="typeId"
                          value={this.state.typeId}
                          onChange={(value) => this.handleChange(undefined, { name: 'typeId', value: value as string })}
                          label={this.props.t('maintenance.addScheduledJob.type')}
                          required={true}
                          options={this.getSelectOptions(
                            [{
                              name: this.props.t('maintenance.addScheduledJob.selectType'),
                              id: '',
                            }].concat(maintenanceTypes),
                          )}
                        />
                      </div>
                      <div className="form-group col-sm-12">
                        <DateInput
                          // singleDatePicker={true}
                          // locale={{
                          //   format: getDateFormat(),
                          // }}
                          label={this.props.t('maintenance.addScheduledJob.dueDate')}
                          // startDate={this.state.dueDate}
                          // endDate={this.state.dueDate}
                          // onApply={(event: any, date: any) => {
                          //   this.setState({dueDate: date.startDate})
                          // }}
                          onChange={(value) => {
                            this.setState({ dueDate: moment(value) })
                          }}
                          value={this.state.dueDate.format(getDateFormat())}
                          // className="form-control w-100"
                          required={true}
                          id="dueDate"
                        />

                      </div>
                      <div className="form-group col-sm-12">
                        <TextArea
                          id="notes"
                          label={this.props.t('maintenance.addJob.notes')}
                          name="notes"
                          value={this.state.notes}
                          onChange={(value) => this.handleChange(undefined, { name: 'notes', value: value as string })}
                          rows={3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </Modal>
      </form>
    )
  }
}

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(withTranslation()(ScheduledJobAddModalComponent)),
)