// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { TableProps } from 'react-table'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

interface Props extends TableProps {
  pageText: string
  ofText: string
  rowsText: string,
  isFetching: boolean,
  leftComponent?: React.ReactNode
}

interface OwnState {
  pageSizes: number[]
}

export const pageSizes = [10, 15, 25, 50, 100, 300]

class PaginationComponentManual extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    if (props.pageSize && !pageSizes.includes(props.pageSize)) {
      pageSizes.push(props.pageSize)
      pageSizes.sort((a: number, b: number) => a - b)
    }

    this.state = {
      pageSizes,
    }

    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
  }


  public render() {
    const props = this.props
    return props.pages ? (
        <div className={`-pagination align-items-center ${props.leftComponent !== undefined ? 'justify-content-between' : 'justify-content-end'}`}>
          {this.props.leftComponent !== undefined && (this.props.leftComponent)}
        <div className="d-flex">
          <div className="d-flex justify-content-right align-items-center">
            {props.pageText && <span className="mr-1">{props.pageText}</span>}
            <input
              className="form-control ml-1 mr-1"
              type="number"
              onChange={this.handlePageChange}
              min={1}
              max={props.pages}
              value={props.page!+1}
              style={{ maxWidth: 70 }}
              disabled={props.isFetching}
            />
            <span className="ml-1" style={{ flex: 'none' }}>
              {props.ofText} {props.pages}
            </span>
          </div>

          <div className="ml-3">
            <select
              className="form-control"
              onChange={this.handlePageSizeChange}
              value={props.pageSize}
              disabled={props.isFetching}
            >
              {this.state.pageSizes.map((rows: number) => (
                <option key={rows} value={rows}>
                  {rows} {props.rowsText}
                </option>
              ))}
            </select>
          </div>

          <div className="btn-group ml-4" role="group">
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => props.onPageChange!(props.page!-1)}
              disabled={!props.page || props.isFetching}
            >
              <IconComponent icon={"chevron-left"} className="text-muted"/>
            </button>
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => props.onPageChange!(props.page!+1)}
              // we add 1 cause of the offset server-side
              disabled={(props.page! + 1) >= props.pages || props.isFetching}
            >
              <IconComponent icon={"chevron-right"} className="text-muted"/>
            </button>
          </div>
        </div>
      </div>
    ) : null
  }

  private handlePageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value: number = parseInt(event.currentTarget.value, 10) - 1 || 0
    const pages: number = this.props.pages!
    const page = value < pages ? value : pages
    this.props.onPageChange!(page)
  }

  private handlePageSizeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const pageSize: number = parseInt(event.currentTarget.value, 10)
    this.props.onPageSizeChange!(pageSize, Math.ceil(pageSize * this.props.page! / this.props.pageSize!))
  }
}

export default PaginationComponentManual
