import * as React from 'react'
import IconComponent from '../../inplant-components-fe/ui/components/MVIcon/Icon'

export const Loader = () => (
  <div key={Date.now()} className="col-lg text-center">
    <IconComponent icon={'circle-notch'} size="2x" spin={true} />
  </div>
)

export const getPath = (object: any, search: string) => {
  const parts = search.split(':')
  const key = parts[0]
  const value = parts[1]
  let path = ''

  function iter(o: any, p: any): any {
    return Object.keys(o).some((k: any) => {
      if (k === key && o[k] === value) {
        path = p.concat(o[k]).join('.')
        return true
      }
      if (o[k] !== null && typeof o[k] === 'object') {
        return iter(
          o[k],
          k === 'subcomponents' && !o.data.id ? p : k === 'subcomponents' && o.data.id ? p.concat(o.data.id) : p
        )
      }
    })
  }

  iter(object, [])
  return path
}

export const buildColumnsData = (data: any, element: string, startPath: string = '') => {
  if (startPath === '') {
    startPath = getPath(data, 'id:' + element) || ''
  }
  const columns: any = []
  const root = data.data.id
  if (data && data.subcomponents) {
    data.subcomponents
      .filter((e: any) => e.level !== 'Metric')
      .forEach((e: any) => {
        e.path = root + '.' + e.data.id
      })
  }

  data.path = root

  function recurse(datas: any, keys: any) {
    let key: any = ''

    if (keys.length === 1) {
      const dataArray = datas.subcomponents.filter((ele: any) => {
        return ele.data && ele.data.id === keys[0] && ele.level !== 'Metric'
      })
      if (
        dataArray.length > 0 &&
        dataArray[0].subcomponents.length > 0 &&
        dataArray[0].subcomponents.filter((subcomponent: any) => {
          return subcomponent.level !== 'Metric'
        }).length > 0
      ) {
        dataArray[0].path = datas.path + '.' + dataArray[0].data.id
        columns.push(dataArray[0])
      }
    } else {
      key = keys.shift()
      if (
        datas.subcomponents.length > 0 &&
        key !== '' &&
        datas.subcomponents.filter((subcomponent: any) => {
          return subcomponent.level !== 'Metric'
        }).length > 0
      ) {
        datas.subcomponents
          .filter((subcomponent: any) => subcomponent.level !== 'Metric')
          .forEach((component: any, id: number) => {
            if (component.data && component.data.id === key) {
              component.path = datas.path + '.' + component.data.id
              columns.push(component)
              recurse(component, keys)
            }
          })
      }
    }
  }

  columns.push(data)

  if (startPath && element !== root) {
    const path = startPath.replace(root + '.', '')
    recurse(data, path.split('.'))
  }

  return columns
}

export const validPath = (pathToCheck: string, dataStructure: any): string => {
  if (!pathToCheck || !dataStructure || !dataStructure.content) {
    return ''
  }

  const recursiveCheck = (path: string[], data: any, out: string[]) => {
    if (path.length > 0) {
      const id = path[0]

      if (
        id &&
        data.data &&
        data.data.id &&
        id === data.data.id &&
        data.subcomponents &&
        data.subcomponents.filter((e: any) => e.level !== 'Metric').length > 0
      ) {
        out.push(id)
        data.subcomponents.forEach((e: any) => {
          return recursiveCheck(path.slice(1), e, out)
        })
      }
    }

    return out
  }
  const output = recursiveCheck(pathToCheck.split('.'), dataStructure.content, [])

  return output.join('.')
}

export const createDownloadTempLink = (uniqueUrl: string, download: string, target: string): HTMLAnchorElement => {
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = uniqueUrl
  tempLink.setAttribute('download', download)
  tempLink.setAttribute('target', target)

  return tempLink
}
