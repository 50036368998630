import * as moment from 'moment'

export const initialDateFiltersState = {
  range: 'dateRangeLastMonth',
  prevRange: '',
  dateStart: moment()
    .subtract(31, 'days')
    // .startOf('month')
    .format('YYYY-MM-DD HH:mm:ss')
    .toString(),
  dateEnd: moment()
    .subtract(1, 'day')
    // .endOf('month')
    .format('YYYY-MM-DD HH:mm:ss')
    .toString(),
  lastUpdate: moment().unix(),
} as DateFiltersState

export interface DateFiltersState {
  range: string
  prevRange: string
  dateStart: string | null
  dateEnd: string | null
  lastUpdate: number | null
}
