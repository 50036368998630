import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { Moment } from 'moment'
import {
  MaintenancePage,
  MaintenanceScheduledJob,
  MaintenanceScheduledJobPatchParams,
  MaintenanceType,
} from '../../../../types'
import { rescheduleScheduledJob, setPageFilterSelectedValues } from '../../../../redux/actions'
import { ContainerComponent } from '../ContainerComponent/ContainerComponent'
import ScheduledJobAddModalComponent from '../ScheduledJobAddModalComponent/ScheduledJobAddModalComponent'
import ScheduledJobModalComponent from '../ScheduledJobModalComponent/ScheduledJobModalComponent'
import { Error, getConfigParam, getDateFormat } from '../../../../functions/shared'
import { Button, ButtonGroupHeader, Loader, PageHeader } from '@mv-submodules/inplant-components-fe'
import PageFiltersHelper from './PageFiltersHelper'
import CalendarFilters from './CalendarFilters'
import PastScheduledJobsAlert from './PastScheduledJobsAlert'
import Legend from './Legend'

import FullCalendar, { CalendarApi } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import interactionPlugin from '@fullcalendar/interaction'

// tslint:disable-next-line:no-submodule-imports
import * as allLocales from '@fullcalendar/core/locales-all'
import Tooltip from 'tooltip.js'
import { fetchScheduledJobs } from '@mv-submodules/inplant-maintenance-fe/redux/actions/scheduledJob'
import { fetchMaintenanceTypes } from '@mv-submodules/inplant-maintenance-fe/redux/actions/types'
import { fetchPlantStatus } from '@mv-submodules/inplant-maintenance-fe/redux/actions/plant'
import CalendarActionModal
  from '@mv-submodules/inplant-maintenance-fe/ui/components/widgets/MaintenanceRouter/CalendarActionModal'
import { MaintenanceClientFiltersParams, MaintenancePageFilters } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'

const getCurrentLng = () => window.localStorage.getItem('i18nextLng') || 'en'

interface StateProps {
  config: any
}

interface OwnProps extends RouteComponentProps<any> {
  pageConf: MaintenancePage
  pageFilters: MaintenancePageFilters[]
  // t: TranslationFunction
}

interface DispatchProps {
  fetchScheduledJobs: (
    pageSlug?: string | null,
    clientFilters?: MaintenanceClientFiltersParams | null,
  ) => Promise<MaintenanceScheduledJob[]>
  rescheduleScheduledJob: (params: MaintenanceScheduledJobPatchParams) => Promise<any>
  setPageFilterSelectedValues: (slug: string, name: string, selected: null | string[]) => void
  fetchMaintenanceTypes: () => Promise<MaintenanceType[]>
  fetchPlantStatus: () => Promise<any>
}

interface OwnStateProps {
  activeScheduledJob: null | MaintenanceScheduledJob
  showNewScheduledJobModal: boolean
  tooltips: Tooltip[]
  taskModalVisible: boolean
  taskModalAction: null | string
  taskModalData?: {
    date: string
    notes: string
  }
  taskModalCloseAction: undefined | Function
  taskModalSubmitAction: undefined | Function
  data: MaintenanceScheduledJob[]
  fetching: boolean
  fetchErrors: boolean
  maintenanceTypes: MaintenanceType[]
  plantStatus: any
}

const mapStateToProps = (state: any) => {
  return {
    config: state.config,
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchScheduledJobs: (pageSlug?: string | null, clientFilters?: MaintenanceClientFiltersParams | null) =>
    dispatch(fetchScheduledJobs(pageSlug, clientFilters)),
  rescheduleScheduledJob: (params: MaintenanceScheduledJobPatchParams) => dispatch(rescheduleScheduledJob(params)),
  setPageFilterSelectedValues: (slug: string, name: string, selected: null | string[]) =>
    dispatch(setPageFilterSelectedValues(slug, name, selected)),
  fetchMaintenanceTypes: () => dispatch(fetchMaintenanceTypes()),
  fetchPlantStatus: () => dispatch(fetchPlantStatus()),
})

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

export class Calendar extends React.Component<Props, OwnStateProps> {
  // private allLocales: any = []
  private calendarRef = React.createRef<FullCalendar>()

  constructor(props: Props) {
    super(props)

    this.state = {
      activeScheduledJob: null,
      showNewScheduledJobModal: false,
      tooltips: [],
      taskModalVisible: false,
      taskModalAction: null,
      taskModalData: undefined,
      taskModalCloseAction: undefined,
      taskModalSubmitAction: undefined,
      data: [],
      fetchErrors: false,
      fetching: false,
      maintenanceTypes: [],
      plantStatus: undefined,
    }

    this.handleOpenNewScheduledJobModal = this.handleOpenNewScheduledJobModal.bind(this)
    this.handleCloseNewScheduledJobModal = this.handleCloseNewScheduledJobModal.bind(this)
    this.handleCloseEditScheduledJobModal = this.handleCloseEditScheduledJobModal.bind(this)
    this.handleCalendarFiltersChange = this.handleCalendarFiltersChange.bind(this)
    this.goToMouth = this.goToMouth.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  public componentDidMount() {
    this.fetchData()
  }

  public render() {
    const { pageConf, pageFilters, config } = this.props
    const { fetching, fetchErrors } = this.state
    const loaderIcon = fetching ? <Loader /> : null
    const errorIcon = fetchErrors ? <Error /> : null
    const clientFilters = PageFiltersHelper.getClientFilters(pageFilters[pageConf.slug])
    const legenHiddenItems = getConfigParam(config, 'calendarLegedHiddenItems', [])
    if (!getConfigParam(config, 'enableScheduledJobRejectAction', false)) {
      legenHiddenItems.push('rejected')
    }
    const notesRequired = getConfigParam(config, 'requiredNote', false)

    this.removeAllTooltips()

    const events = this.state.data.filter((event) => {
      const pagFilters: MaintenancePageFilters[] = this.props.pageFilters[this.props.pageConf.slug]
      return PageFiltersHelper.applyClientFilterOnItem(event as MaintenanceScheduledJob, pagFilters)
    })

    return (
      <ContainerComponent>
        <PageHeader
          title={this.props.pageConf.name}
          subtitle={this.props.pageConf.description}
          rightButtons={
            <ButtonGroupHeader>
              {this.props.pageConf.enableNewButton ? (
                <Button
                  variant='primary'
                  onClick={this.handleOpenNewScheduledJobModal}
                  label={this.props.t('maintenance.plan.new')}
                />
              ) : undefined}
            </ButtonGroupHeader>
          }
        />

        {loaderIcon}

        {errorIcon}

        <div className='content'>
          {!fetching && !fetchErrors && (
            <>
              {clientFilters && clientFilters.length > 0 && (
                <CalendarFilters
                  filters={clientFilters}
                  showFiltersText={this.props.t('maintenance.filters.showFilters')}
                  onSelectedChanged={selected => this.handleCalendarFiltersChange(selected)}
                />
              )}

              <PastScheduledJobsAlert
                message={this.props.t('maintenance.calendar.pastUndoneMsg')}
                scheduledJobs={this.state.data}
                onMouthClick={(mouth: Moment) => this.goToMouth(mouth)}
              />

              <Legend hidden={legenHiddenItems} />

              <div className={'calendar-component ' + (this.state.fetching ? 'd-none' : '')}>
                <FullCalendar
                  ref={this.calendarRef}
                  plugins={[dayGridPlugin, bootstrapPlugin, interactionPlugin]}
                  initialView={'dayGridMonth'}
                  dayMaxEventRows={3}
                  editable={true}
                  aspectRatio={1.7}
                  events={events}
                  themeSystem='bootstrap'
                  headerToolbar={{
                    center: 'dayGridMonth,dayGridWeek',
                    right: 'today,prev,next',
                  }}
                  views={{
                    dayGridWeek: {
                      eventLimit: 20,
                    },
                  }}
                  buttonText={{ today: this.props.t('maintenance.calendar.today') }}
                  locale={getCurrentLng()}
                  locales={allLocales}
                  moreLinkContent={this.props.t('maintenance.calendar.more')}
                  eventClick={(info: { el: HTMLElement; event: any; jsEvent: MouseEvent; view: any }) => {
                    const lookup = this.state.data.filter((scheduledJob: MaintenanceScheduledJob) => {
                      return scheduledJob.id === info.event.id
                    })
                    const activeScheduledJob = lookup.length === 1 ? lookup[0] : null

                    this.setState({ activeScheduledJob })
                  }}
                  eventDrop={(info: { event: any; oldEvent: any; delta: any; jsEvent: Event; revert: Function }) => {
                    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    if (!(moment(info.event.start).diff(today) >= 0)) {
                      info.revert()
                      return
                    }

                    if (notesRequired) {
                      let notes = '' // tslint:disable-line
                      const params: MaintenanceScheduledJobPatchParams = {
                        // tslint:disable-line
                        id: info.event.id,
                        date: moment(info.event.start),
                        notes,
                      }

                      const taskModalSubmitAction = (data: FormData) => {
                        // @ts-ignore
                        if (data.date) {
                          // @ts-ignore
                          params.date = moment(data.date)
                        }

                        // @ts-ignore
                        if (data.notes) {
                          // @ts-ignore
                          params.notes = data.notes
                        }

                        this.props.rescheduleScheduledJob(params).then(
                          () => {
                            this.fetchData(true)
                          },
                          () => {
                            info.revert()
                          },
                        )
                      }

                      this.setState({
                        taskModalVisible: true,
                        taskModalAction: 'reschedule',
                        taskModalData: {
                          // @ts-ignore
                          date: moment(info.event.start).format('YYYY-MM-DD'),
                          notes: info.event.extendedProps.notes,
                        },
                        taskModalCloseAction: info.revert,
                        taskModalSubmitAction,
                      })
                    } else {
                      const msg = `${this.props.t('maintenance.calendar.rescheduleConfirm')} ${moment(
                        info.event.start,
                      ).format(getDateFormat())}?`
                      const status = confirm(msg)
                      if (status) {
                        const params: MaintenanceScheduledJobPatchParams = {
                          id: info.event.id,
                          date: moment(info.event.start),
                          notes: '',
                        }
                        this.props.rescheduleScheduledJob(params).then(
                          () => {
                            this.fetchData(true)
                          },
                          () => {
                            info.revert()
                          },
                        )
                      } else {
                        info.revert()
                      }
                    }
                  }}
                  eventDidMount={(info: { event: any; el: any }) => {
                    // @ts-ignore
                    const tooltip: any = new Tooltip(info.el, {
                      placement: 'top',
                      trigger: 'hover',
                      container: '.calendar-component',
                      title: notesRequired ? info.event.extendedProps.notes : info.event.title,
                      closeOnClickOutside: true,
                    })

                  }}
                  eventDataTransform={(event) => {
                    return {
                      ...event,
                      title: `${event.extendedProps && event.extendedProps.icon || ''} ${event.title}`,
                    }
                  }
                  }
                />
              </div>
              {this.state.plantStatus !== undefined && (
                <ScheduledJobAddModalComponent
                  isVisible={this.state.showNewScheduledJobModal}
                  onClose={this.handleCloseNewScheduledJobModal}
                  maintenanceTypes={this.state.maintenanceTypes || undefined}
                  plantStatus={this.state.plantStatus}
                />
              )}
              {this.state.plantStatus !== undefined && (
                <ScheduledJobModalComponent
                  isVisible={this.state.activeScheduledJob !== null}
                  scheduledJob={this.state.activeScheduledJob}
                  onClose={this.handleCloseEditScheduledJobModal}
                  plantStatus={this.state.plantStatus}
                />
              )}
            </>
          )}
        </div>

        {this.state.taskModalVisible && (
          <CalendarActionModal
            visible={this.state.taskModalVisible}
            action={this.state.taskModalAction}
            data={this.state.taskModalData}
            onClose={() => {
              if (this.state.taskModalCloseAction) {
                this.state.taskModalCloseAction()
              }
              this.setState({ taskModalVisible: false })
            }}
            onSubmit={(a: any) => {
              return new Promise<void>((r, re) => {
                if (this.state.taskModalSubmitAction) {
                  this.state.taskModalSubmitAction(a)
                }
                r()
              }).then(() => this.setState({ taskModalVisible: false }))
            }}
          />
        )}
      </ContainerComponent>
    )
  }

  private removeAllTooltips() {
    // hack
    const tooltips = document.querySelectorAll('.calendar-component .tooltip')
    if (tooltips) {
      tooltips.forEach(e => e.parentNode && e.parentNode.removeChild(e))
    }
  }

  private handleOpenNewScheduledJobModal() {
    this.removeAllTooltips()
    this.setState({ showNewScheduledJobModal: true })
  }

  private handleCloseNewScheduledJobModal(reload?: boolean) {
    this.setState({ showNewScheduledJobModal: false })
    if (reload) {
      this.fetchData(true)
    }
  }

  private handleCloseEditScheduledJobModal(reload?: boolean) {
    this.setState({ activeScheduledJob: null })
    if (reload) {
      this.fetchData(true)
    }
  }

  private fetchData(refreshCalendar?: boolean) {
    if (!this.state.fetching) {
      this.setState({ fetching: true, fetchErrors: false })
      Promise.all([
        this.props.fetchScheduledJobs(this.props.pageConf.slug),
        this.props.fetchMaintenanceTypes(),
        this.props.fetchPlantStatus(),
      ])
        .then(([data, maintenanceTypes, plantStatus]) => {
          this.setState({ data, maintenanceTypes, plantStatus })
          if (refreshCalendar && this.calendarRef && this.calendarRef.current) {
            const calendarApi: CalendarApi = this.calendarRef.current.getApi()
            calendarApi.refetchEvents()
          }
        })
        .catch((error: any) => {
          this.setState({
            fetchErrors: true,
          })
        })
        .finally(() => {
          this.setState({ fetching: false })
        })
    }
    if (refreshCalendar) {
      this.removeAllTooltips()
    }
  }

  private handleCalendarFiltersChange(selected: { slug: string; selected: string[] }) {
    this.removeAllTooltips()
    // tslint:disable-next-line:no-shadowed-variable
    const { setPageFilterSelectedValues, pageConf } = this.props
    setPageFilterSelectedValues(pageConf.slug, selected.slug, selected.selected)
  }

  private goToMouth(mountStart: Moment) {
    this.removeAllTooltips()
    if (this.calendarRef && this.calendarRef.current) {
      const calendarApi: CalendarApi = this.calendarRef.current.getApi()
      calendarApi.gotoDate(mountStart.format())
    }
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(Calendar)),
)
