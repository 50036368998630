import { AnyAction } from 'redux'

const indexInitialState = {
  sparePartsData: null,
  sparePartsError: false,
  sparePartsFetching: false,
}

export const spareParts = (state = indexInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return Object.assign({}, { ...state })

    case 'SPAREPARTS_FILE_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          sparePartsError: false,
          sparePartsFetching: action.fetching,
        }
      )
    case 'SPAREPARTS_FILE_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          sparePartsData: action.data,
          sparePartsError: false,
          sparePartsFetching: false,
        }
      )
    case 'SPAREPARTS_FILE_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          sparePartsData: null,
          sparePartsError: action.payload,
          sparePartsFetching: false,
        }
      )

    default:
      return state
  }
}
