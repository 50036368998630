// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import withObserver, { WithObserverProps } from './withObserver'

interface Props {
  alt?: string
  style?: any
  src: string
  onError: (event: React.SyntheticEvent<any>) => void
}

const Image = (props: Props & WithObserverProps) => (
  <img
    className={props.className}
    src={props.src}
    alt={props.alt}
    onError={props.onError}
    style={props.style || {}}
  />
)

export default withObserver<Props>(Image)
