/* tslint:disable */ // TODO refactoring

import { MetricGroupTemplate } from './template'
import {
  ActivePower_KWh,
  BString,
  // Conductivity_miS,
  Consumption,
  Coulomb,
  Current_A,
  DFloat,
  DLong,
  ElectricConsumption_KWh,
  Equivalent,
  FlowRate,
  GasFlowRate_Nm3h,
  Label,
  Level_M,
  LoadCellFlowRate,
  OilConsumption,
  Partial,
  Ph,
  Power_KWh,
  Pressure_Bar,
  ProductConsumption,
  Reset_Bool,
  ResetPartialCounter,
  ResetTotalCounter,
  Temperature_C,
  Total,
  TotalMinutes,
  Type,
  Uptime_Min,
  WShort,
  XBoolean,
} from './plc'

const i4P_ElectricItem: MetricGroupTemplate = {
  name: 'i4P_ElectricItem',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: WShort,
      metric: Type,
      suffix: '_type',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Power_KWh,
      suffix: '_powerAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DLong,
      metric: Uptime_Min,
      suffix: '_minutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_ElectricItemMotor: MetricGroupTemplate = {
  name: 'i4P_ElectricItemMotor',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: WShort,
      metric: Type,
      suffix: '_type',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Power_KWh,
      suffix: '_powerAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DLong,
      metric: Uptime_Min,
      suffix: '_minutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_ElectricItemFan: MetricGroupTemplate = {
  name: 'i4P_ElectricItemFan',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: WShort,
      metric: Type,
      suffix: '_type',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Power_KWh,
      suffix: '_powerAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DLong,
      metric: Uptime_Min,
      suffix: '_minutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_ElectricItemPump: MetricGroupTemplate = {
  name: 'i4P_ElectricItemPump',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: WShort,
      metric: Type,
      suffix: '_type',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Power_KWh,
      suffix: '_powerAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DLong,
      metric: Uptime_Min,
      suffix: '_minutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_RectifierAnod: MetricGroupTemplate = {
  name: 'i4P_RectifierAnod',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Coulomb,
      suffix: '_coulumbAct',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_Rectifier: MetricGroupTemplate = {
  name: 'i4P_Rectifier',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: ElectricConsumption_KWh,
      suffix: '_consumption',
      type: 'Other',
      polling: true,
    },
    {
      multiple: 50,
      fixed: 50,
      metricType: i4P_RectifierAnod,
    },
    {
      memory: DLong,
      metric: Uptime_Min,
      suffix: '_minutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_PressureMeter: MetricGroupTemplate = {
  name: 'i4P_PressureMeter',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Pressure_Bar,
      suffix: '_temperatureValue', // @todo temp?? php
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_TemperatureMeter: MetricGroupTemplate = {
  name: 'i4P_TemperatureMeter',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Temperature_C,
      suffix: '_temperatureValue',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_LevelMeter: MetricGroupTemplate = {
  name: 'i4P_LevelMeter',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Level_M,
      suffix: '_level', // @todo temp?? php
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_BlastingMachine: MetricGroupTemplate = {
  name: 'i4P_BlastingMachine',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: ProductConsumption,
      suffix: '_product_consumption',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DLong,
      metric: TotalMinutes,
      suffix: '_totalMinutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_Boiler: MetricGroupTemplate = {
  name: 'i4P_Boiler',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: GasFlowRate_Nm3h,
      suffix: '_gasFlowRate',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Temperature_C,
      suffix: '_temperatureAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Pressure_Bar,
      suffix: '_pressureAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DLong,
      metric: TotalMinutes,
      suffix: '_totalMinutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_Burner: MetricGroupTemplate = {
  name: 'i4P_Burner',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: GasFlowRate_Nm3h,
      suffix: '_gasFlowRate',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DLong,
      metric: TotalMinutes,
      suffix: '_totalMinutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_CompressedAirMeter: MetricGroupTemplate = {
  name: 'i4P_CompressedAirMeter',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: FlowRate,
      suffix: '_flowRate',
      type: 'Other',
      polling: true,
    },
  ],
}
const i4P_ConductivityMeter: MetricGroupTemplate = {
  name: 'i4P_ConductivityMeter',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: FlowRate,
      suffix: '_conductivity',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_FlowMeter: MetricGroupTemplate = {
  name: 'i4P_FlowMeter',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: FlowRate,
      suffix: '_flowRate',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_Lubricator: MetricGroupTemplate = {
  name: 'i4P_Lubricator',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: OilConsumption,
      suffix: '_oilConsumption',
      type: 'Other',
      polling: true,
    },
    {
      memory: DLong,
      metric: TotalMinutes,
      suffix: '_totalMinutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_pHMeter: MetricGroupTemplate = {
  name: 'i4P_pHMeter',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Ph,
      suffix: '_ph',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_Chiller: MetricGroupTemplate = {
  name: 'i4P_Chiller',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: ActivePower_KWh,
      suffix: '_powerAct',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      multiple: 18,
      metricType: i4P_TemperatureMeter,
    },
    {
      memory: DLong,
      metric: TotalMinutes,
      suffix: '_totalMinutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_PostCombustor: MetricGroupTemplate = {
  name: 'i4P_PostCombustor',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: GasFlowRate_Nm3h,
      suffix: '_gasFlowRate',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Current_A,
      suffix: '_currentAct',
      type: 'Other',
      polling: true,
    },
    {
      multiple: 18,
      metricType: i4P_TemperatureMeter,
    },
    {
      memory: DLong,
      metric: TotalMinutes,
      suffix: '_totalMinutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_DosingPump: MetricGroupTemplate = {
  name: 'i4P_DosingPump',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: FlowRate,
      suffix: '_flowRate',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: LoadCellFlowRate,
      suffix: '_loadCellFlowRate',
      type: 'Other',
      polling: true,
    },
    {
      memory: DLong,
      metric: TotalMinutes,
      suffix: '_totalMinutes',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Equivalent,
      suffix: '_equivalent',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Partial,
      suffix: '_partial',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Total,
      suffix: '_total',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: ResetTotalCounter,
      suffix: '_resetTotalCounter',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: ResetPartialCounter,
      suffix: '_resetPartialCounter',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_UFMDosingPump: MetricGroupTemplate = {
  name: 'i4P_UFMDosingPump',
  metrics: [
    {
      memory: BString,
      metric: Label,
      suffix: '_label',
      type: 'Other',
      polling: true,
    },
    {
      memory: DFloat,
      metric: Consumption,
      suffix: '_consumption',
      type: 'Other',
      polling: true,
    },
    {
      memory: XBoolean,
      metric: Reset_Bool,
      suffix: '_resetHours',
      type: 'Other',
      polling: true,
    },
  ],
}

const i4P_Consumption: MetricGroupTemplate = {
  name: 'i4P_Consumption',
  metrics: [
    {
      memory: DFloat,
      metric: ElectricConsumption_KWh,
      suffix: '_consumption',
      type: 'Other',
      polling: true,
    },
  ],
}

export default {
  i4P_BlastingMachine,
  i4P_Boiler,
  i4P_Burner,
  i4P_Chiller,
  i4P_CompressedAirMeter,
  i4P_ConductivityMeter,
  i4P_Consumption,
  i4P_DosingPump,
  i4P_ElectricItem,
  i4P_ElectricItemFan,
  i4P_ElectricItemMotor,
  i4P_ElectricItemPump,
  i4P_FlowMeter,
  i4P_LevelMeter,
  i4P_Lubricator,
  i4P_pHMeter,
  i4P_PostCombustor,
  i4P_PressureMeter,
  i4P_Rectifier,
  i4P_RectifierAnod,
  i4P_TemperatureMeter,
  i4P_UFMDosingPump,
}
