import { AnyAction } from 'redux'
import {
  MaintenanceScheduledJobDetailStateProps,
  // MaintenanceScheduledJob,
} from '../../types'

const scheduledJobDetailInitialState = {
  fetching: false,
  error: null,
  scheduledJob: null,
}

export const scheduledJobDetail = (
  state: MaintenanceScheduledJobDetailStateProps = scheduledJobDetailInitialState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'MAINTENANCE_SCHEDULED_JOB_DETAIL_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: true,
        }
      )

    case 'MAINTENANCE_SCHEDULED_JOB_DETAIL_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: false,
          scheduledJob: action.payload.scheduledJob,
        }
      )

    case 'MAINTENANCE_SCHEDULED_JOB_DETAIL_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          error: action.payload,
          fetching: false,
        }
      )

    default:
      return state
  }
}
