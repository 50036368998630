import { AnyAction } from 'redux'
import { MaintenanceJobsStateProps } from '../../types'

const jobsInitialState = {
  fetching: false,
  error: null,
  data: [],
}

export const jobs = (state: MaintenanceJobsStateProps = jobsInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'MAINTENANCE_SETUP_SET_ACTIVE_SCHEDULED_JOB':
      return Object.assign(
        {},
        {
          ...state,
          scheduledJob: action.payload.scheduledJob,
        }
      )

    default:
      return state
  }
}
