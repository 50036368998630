import './_index.scss'
import FetchError, { ErrorResponse } from './FetchError'
import { popupNotification } from '../notifications'
import { library, icon } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'

library.add(faExclamationTriangle)


export default class FetchWrapper {
  private static instances: Map<string, FetchWrapper> = new Map()
  private baseURL: string
  private i18n: any

  /**
   * FetchWrapper constructor
   * @param {string} baseURL
   * @param {any} i18n
   */
  private constructor(baseURL: string, i18n: any) {
    this.baseURL = baseURL
    this.i18n = i18n
  }

  /**
   * Gets an instance of a FetchWrapper class or creates one
   * @param {string} id
   * @returns {FetchWrapper}
   */
  public static getInstance(id: string): any {
    // return this.instances.get(id) || this.configInstance(id)
    return this.instances.get(id) ? this.instances.get(id) : new Error()
  }

  /**
   * Configure and return a new instance of a FetchWrapper class
   * @param {string} id
   * @param {string} baseURL
   * @param {boolean} overwrite
   * @param {any} i18n
   * @returns {FetchWrapper}
   */
  public static configInstance(id: string, baseURL: string = 'http://localhost', overwrite?: boolean, i18n?: any): FetchWrapper {
    let instance = this.instances.get(id)
    if (instance) {
      if (overwrite) {
        instance.baseURL = baseURL
      }
      return instance
    } else {
      instance = new FetchWrapper(baseURL, i18n)
      this.instances.set(id, instance)
      return instance
    }
  }

  /**
   * A simple fetch() wrapper with error handling
   * @param {string} input The resource string
   * @param {RequestInit} init  RequestInit params
   * @param omitAuthorization
   * @param hidePopupNotification
   * @returns {Promise}
   * @throws {FetchError}
   */
  public async request(input: string, init?: RequestInit, omitAuthorization?: boolean, hidePopupNotification?: boolean, responseHeaders?: string[]): Promise<any> {
    const defaultHeaders: HeadersInit = {}

    // Send JWT token if present
    const requestJWTToken = localStorage.getItem('jwt')
    if (requestJWTToken && omitAuthorization !== true) {
      defaultHeaders.authorization = 'bearer ' + requestJWTToken
    }

    // Merge default headers with init headers if any
    const requestHeaders = { ...defaultHeaders, ...(init && init.headers ? init.headers : {}) }

    // Merge init params (if any) with headers
    const initParams: RequestInit = { ...(init ? init : {}), headers: requestHeaders }

    try {
      // Try to get a response
      const response = await fetch(this.baseURL + input, initParams)

      // Catch the errors to pass to the caller
      if (!response.ok) {
        if (response.status === 400 || response.status === 406 || response.status === 422 || response.status === 409) {
          const errorResponse: ErrorResponse = await response.json()
          const content = `
            ${icon(faExclamationTriangle).html}
            <strong>${this.i18n.t('api.errors.error')} [${errorResponse.statusCode || response.status}]</strong><br>
            ${errorResponse.detail || errorResponse.message || errorResponse.title || ''}
          `
          if (!hidePopupNotification) {
            popupNotification({
              content,
              type: 'error',
            })
          }
          throw new FetchError(errorResponse.message, errorResponse.detail, errorResponse.statusCode, errorResponse.errors)
        } else {
          const content = `
            ${icon(faExclamationTriangle).html}
            <strong>${this.i18n.t('api.errors.error')} [${response.status}]</strong><br>
            ${this.i18n.t(`api.errors.error_${response.status}`)}
          `
          if (!hidePopupNotification) {
            popupNotification({
              content,
              type: 'error',
            })
          }
          throw new FetchError(response.statusText || '', '', response.status || 500)
        }
      }

      // Renew JWT token if present
      const responseJWTToken = response.headers.get('JWT')
      if (responseJWTToken) {
        localStorage.setItem('jwt', responseJWTToken)
      }

      return this.handleReturn(response, response.headers.get('content-type') || undefined, responseHeaders)
    } catch (error) {
      // Pass the rejection to the caller
      throw error
    }
  }

  private async handleReturn(response: Response, contentType?: string, desiredHeaders?: string[]): Promise<any> {
    let value
    // Manage return values based on reponse's content-type
    if (contentType && contentType.indexOf('application/json') !== -1) {
      value = await response.json()
    }
    else if (contentType && contentType.indexOf('text') !== -1) {
      value = await response.text()
    }
    else {
      value = await response.blob()
    }
    if (!desiredHeaders) {
      return value
    }
    return {
      result: value,
      ...desiredHeaders.reduce((headers: { [k: string]: string | null }, curr) => {
        headers[curr] = response.headers.get(curr)
        return headers
      }, {}),
    }
  }
}
