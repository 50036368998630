import * as React from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
import { fetchPlanPdf } from '../../../../redux/actions'
import { MaintenancePlanPdfParams } from '../../../../types'
import PrintButton from '@mv-submodules/inplant-components-fe/ui/components/Button/PrintButton'

interface OwnProps {
  pageSlug: string
  label: string
  getParams: () => MaintenancePlanPdfParams,
  disabled?: boolean
}


interface DispatchProps {
  fetchPlanPdf: (params: MaintenancePlanPdfParams) => Promise<any>,
}

type Props = DispatchProps & OwnProps

const mapDispatchToProps = (dispatch: Function) => ({
  fetchPlanPdf: (params: MaintenancePlanPdfParams) => dispatch(fetchPlanPdf(params)),
})

interface AppState {
  loading: boolean,
  showActionModal: boolean,
  bulkActionType: any
}

class PrintPlan extends React.Component<Props, AppState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showActionModal: false,
      bulkActionType: null,
      loading: false,
    }

    this.onPrint = this.onPrint.bind(this)
  }

  public async onPrint() {
    this.setState({ loading: true })
    const params = this.props.getParams() as MaintenancePlanPdfParams
    try {
      const blob = await this.props.fetchPlanPdf(params)
      const objectURL = (window.URL ? URL : webkitURL).createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectURL
      link.download = 'plan_export_' + moment().unix() + '.pdf'
      link.click()
    } catch (error:any) {
      console.error('Error generating print PDF', error) // tslint:disable-line
      this.setState({ loading: false })
    }
    finally {
      this.setState({ loading: false })
    }
  }

  public render() {
    const { loading } = this.state
    return (
      <PrintButton
        isPrinting={loading}
        label={this.props.label}
        onClick={this.onPrint}
        disabled={this.props.disabled}
      />
    )
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(PrintPlan)