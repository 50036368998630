import * as React from 'react'
import {
  ManualDataLoadingGraphData,
} from '@mv-submodules/inplant-environment-fe-imel/redux/types'
import {
  Bar, BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {WithTranslation, withTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {Alert, Loader} from '@mv-submodules/inplant-components-fe'
import DownloadDataButton
  from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import {saveDataAsCSV} from "@mv-submodules/inplant-core-fe/functions";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";


interface StateProps {
  graphData?: ManualDataLoadingGraphData,
  isFetching: boolean
  timeRangeSeconds: number | null
  timeRangeMonths: number | null,
  range: string | null
}

type Props = StateProps & WithTranslation

class ManualDataLoadingConsumptionGraphData extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.renderTooltip = this.renderTooltip.bind(this)
  }

  private renderTooltip(a: any) {
    const data = this.props.graphData ? this.props.graphData.data.find((e: any) => e.time === a.label) : null
    return (
      <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
        <div
          className="recharts-default-tooltip"
          style={{
            margin: 0,
            padding: 10,
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid rgb(204, 204, 204)',
            whiteSpace: 'nowrap',
          }}
        >
          <p className="recharts-tooltip-label time-label" style={{margin: 0}}>
            {a.label ? format(new Date(a.label), 'd/M/yyyy') : ''}
          </p>
          {data && this.props.graphData && (
            Object.keys(this.props.graphData.bars).map(key => (
                <p
                  key={key}
                  className="recharts-tooltip-label"
                  style={{margin: 0, color: this.props.graphData!.bars[key].color}}>
                  {' ' + this.props.graphData!.bars[key].name}:{' '}
                  {Number.parseFloat(data[key]).toFixed(2)} {this.props.graphData!.bars[key].um}
                </p>
              ),
            ))}
        </div>
      </div>
    )
  }


  public render() {
    const {timeRangeSeconds, timeRangeMonths, graphData} = this.props
    const showGraph = graphData && graphData.data && graphData.data.length > 0
    const legend = graphData && graphData.bars && Object.keys(graphData.bars).map((key: string, index: number) => (
      <li
        //  className="filter-trigger"
        key={key}
      >
        <span
          className={'legend-color legend-' + key.toLowerCase() + '-color'}
          style={{backgroundColor: graphData.bars[key].color}}
        />
        {` ${graphData.bars[key].name} (${graphData.bars[key].um})`}
      </li>
    ))
    let downloadData
    let downloadFileName
    if (graphData && graphData.data.length > 0 && graphData.bars) {
      const keys = Object.keys(graphData.bars)
      downloadData = [
        ['time', ...keys.map(key => `${graphData.bars[key].name} (${graphData.bars[key].um})`)],
      ]
      downloadData = [
        ...downloadData,
        ...graphData.data.reduce((acc, curr) => {
          acc.push([
            format(new Date(curr.time), 'yyyy/MM/dd'),
            ...keys.reduce((values: string[], key) => {
              values.push(curr[key])
              return values
            }, []),
          ])
          return acc
        }, []),
      ]
      const timeDownload = `${downloadData[1][0]}_${downloadData[downloadData.length - 1][0]}`
      downloadFileName = `${this.props.t('i4environment.tabs.manualDataLoading').replace(' ', '_')}_${timeDownload}`
    }
    return (
      <div className="card mb-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={'nav-link active'}
              href="#"
            >
              {this.props.t('i4environment.tabs.manualDataLoading')}
            </a>
          </li>
        </ul>
        {downloadData && (
          <DownloadDataButton
            saveDataAsCSV={saveDataAsCSV}
            data={downloadData}
            type={'csv'}
            label={'i4environment.download.buttonLabel'}
            fileName={downloadFileName}
            className="position-absolute btn-over-tabs"
            addTime={false}
          />
        )}
        <div className="card-body">
          {!showGraph && !this.props.isFetching && (
            <Alert type={"warning"}>{this.props.t('i4environment.fetch.noData')}</Alert>
          )}
          {this.props.isFetching && (
            <Loader/>
          )}
          {showGraph && !this.props.isFetching && (
            <div className="container-fluid">
              <div className="full-h">
                <Row verticalAlignment={"center"}>
                  <Column sm={12}>
                    <div className="text-center full-h chart-wrapper">
                      <ResponsiveContainer>
                        <BarChart
                          data={graphData!.data}
                          margin={{top: 10, right: 40, left: -10, bottom: 10}}
                        >
                          <CartesianGrid strokeDasharray="3 3"/>
                          <XAxis
                            dataKey="time"
                            tickFormatter={(e: number) =>
                              timeRangeSeconds && timeRangeSeconds >= 2592100 && timeRangeMonths
                                ? timeRangeMonths > 5
                                ? format(new Date(e), 'MM/yyyy')
                                : format(new Date(e), 'MM/YY')
                                : format(new Date(e), 'dd/MM')
                            }
                            interval={
                              this.props.range &&
                              ['dateRangeLast6Month', 'dateRange6Month'].includes(this.props.range)
                                ? 30
                                : 0
                            }
                          />
                          <YAxis/>
                          <Tooltip content={this.renderTooltip}/>
                          {graphData && Object.keys(graphData.bars).map(key => (
                            <Bar dataKey={key} key={key} fill={graphData.bars[key].color}/>
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </Column>
                </Row>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer text-muted text-right small">
          <ul className="legend float-right">{legend}</ul>
        </div>
      </div>
    )
  }

}

export default (withTranslation()(ManualDataLoadingConsumptionGraphData))
