// * -------------------------------- NPM --------------------------------------
import * as React from "react";

interface Props {
    children: React.ReactNode
}

class ButtonGroupHeader extends React.Component<Props>{
    public render() {
        return (
            <div className="button-group-header d-flex">
                {this.props.children}
            </div>
        );
    }
}

export default ButtonGroupHeader