import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Prompt, RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { fetchData, fetchStorageData, submitData } from '../../../store/actions'
import './_DesignerComponent.scss'
import TreeNode from '../TreeNodeComponent/TreeNode'
import { NodeType, SideContentParams, Template, TemplateField } from '../../../types'
import NodeFormComponent from '../NodeFormComponent/NodeFormComponent'

import { popupNotification } from '@mv-submodules/inplant-core-fe/functions/notifications'

import { MacroArea, Metric, Plant } from '../../../templates'
import { MetricTemplate } from '../../../types/template'
import { MemoryAddressType, MemoryAddressTypes } from '../../../types/plc'
import { ModelObject } from '../../../types/designer'
import {
  addChild,
  buildColumnsData,
  cleanCopiedNodes,
  cleanNodes,
  deleteNode,
  findNodeByPath,
  generateNodeId,
  getAllQRCodes,
  hydrateNodes,
  // hydrateNodesMeasures,
  orderSubcomponents,
  saveAllIds,
  updateDataNode,
  validPath,
} from '../nodesFunctions'
import MetricsListComponent from '../NodeFormComponent/MetricsListComponent'
import * as arrayMove from 'array-move'
import Attachments from '../NodeFormComponent/Attachments'
import { DropdownButton } from '@mv-submodules/inplant-components-fe'
import { DropdownAction } from '@mv-submodules/inplant-components-fe/ui/components/Button/DropdownButton'
import { saveDataAsCSV, saveTextAsFile } from '../../../utils/file'
import { getActiveNodesExportData, getExportData } from '../../../utils/plc_export'
import IconComponent from '../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface StateProps {
  fetching: boolean
  error: Error
  data?: any
  details?: any
  lastDateUpdate: number | null
  plantMemoryRoot: string | null
  plantPrefix: string
  youtubeKey: string | null
  storageData: any
  storageLastUpdate: null | number
}

export interface DispatchProps {
  fetchData: () => Promise<any>
  fetchStorageData: () => Promise<any>
  submitData: (model: ModelObject) => Promise<any>
}

interface OwnStateProps {
  activeColumn: number
  activePath: string | null
  cardTitle: string
  columnNames: string[] | null
  columns: any
  columnsClass: string
  copiedNode: null | NodeType
  currentAllowedChilds: any[]
  currentId: string | null
  currentParent: string | null
  currentPath: string | null
  data: any
  docs: any
  errorFound: boolean
  hasModifications: boolean
  isNodeCut: boolean
  metric: any
  metricData: any
  metricErrors: any
  metricShowForm: null | string
  reorderedData: any
  sections: string[]
  showSideFormAdd: boolean
  showSideFormEdit: boolean
  sideFormAddChild: boolean
  treeHeight: string | number | undefined
}

export interface DesignerOwnProps extends RouteComponentProps<any, any> {
  // t: TranslationFunction
  router: object
  route: object
}

export type DesignerProps = StateProps & DispatchProps & DesignerOwnProps & WithTranslation
export let PLANT_PREFIX = ''
export let YOUTUBE_KEY: string | null = null

const mapStateToProps = (state: any): StateProps => ({
  data: state.designer.designer.data,
  error: state.designer.designer.error,
  fetching: state.designer.designer.fetching,
  lastDateUpdate: state.dateFilters.lastUpdate,
  plantMemoryRoot:
    state.config.designer && state.config.designer.plantMemoryRoot ? state.config.designer.plantMemoryRoot : null,
  plantPrefix: state.config.designer && state.config.designer.plantPrefix ? state.config.designer.plantPrefix : 'GLO',
  youtubeKey: state.config.designer && state.config.designer.youtubeKey ? state.config.designer.youtubeKey : null,
  storageData: state.designer.storage.data,
  storageLastUpdate: state.designer.storage.lastUpdate,
})

const mapDispatchToProps = (dispatch: Function) => ({
  fetchData: () => dispatch(fetchData()),
  fetchStorageData: () => dispatch(fetchStorageData()),
  submitData: (model: ModelObject) => dispatch(submitData(model)),
})

class DesignerComponent extends React.Component<DesignerProps, OwnStateProps> {
  private treeHeight: number | undefined = 400

  private jsonUpload: any

  constructor(props: DesignerProps) {
    super(props)

    this.state = {
      activeColumn: 0,
      activePath: null,
      cardTitle: '',
      columnNames: [],
      columns: [],
      columnsClass: '',
      copiedNode: null,
      currentAllowedChilds: [],
      currentId: null,
      currentParent: null,
      currentPath: null,
      data: null,
      docs: [],
      errorFound: false,
      hasModifications: false,
      isNodeCut: false,
      metric: null,
      metricData: [],
      metricErrors: {},
      metricShowForm: null,
      reorderedData: null,
      sections: ['metrics'],
      showSideFormAdd: false,
      showSideFormEdit: false,
      sideFormAddChild: false,
      treeHeight: this.treeHeight,
    }

    this.addChildNode = this.addChildNode.bind(this)
    this.changeAccordion = this.changeAccordion.bind(this)
    this.computeMemoryOffsets = this.computeMemoryOffsets.bind(this)
    this.disableSidecontent = this.disableSidecontent.bind(this)
    this.downloadModel = this.downloadModel.bind(this)
    this.editPlantMetric = this.editPlantMetric.bind(this)
    this.emptyModel = this.emptyModel.bind(this)
    this.handleClickAdd = this.handleClickAdd.bind(this)
    this.handleClickAddChild = this.handleClickAddChild.bind(this)
    this.handleClickCopy = this.handleClickCopy.bind(this)
    this.handleClickCut = this.handleClickCut.bind(this)
    this.handleClickDelete = this.handleClickDelete.bind(this)
    this.handleClickEdit = this.handleClickEdit.bind(this)
    this.handleClickLeft = this.handleClickLeft.bind(this)
    this.handleClickMove = this.handleClickMove.bind(this)
    this.handleClickPaste = this.handleClickPaste.bind(this)
    this.handleClickRight = this.handleClickRight.bind(this)
    this.handleMetricAdd = this.handleMetricAdd.bind(this)
    this.handleMetricCancel = this.handleMetricCancel.bind(this)
    this.handleMetricReorder = this.handleMetricReorder.bind(this)
    this.handleMetricSave = this.handleMetricSave.bind(this)
    this.handleModelUpload = this.handleModelUpload.bind(this)
    this.handleResetMetricReorder = this.handleResetMetricReorder.bind(this)
    this.loadModelData = this.loadModelData.bind(this)
    this.removePlantMetric = this.removePlantMetric.bind(this)
    this.resetHistory = this.resetHistory.bind(this)
    this.selectNode = this.selectNode.bind(this)
    this.setReorderedData = this.setReorderedData.bind(this)
    this.sidecontentChange = this.sidecontentChange.bind(this)
    this.submitModel = this.submitModel.bind(this)
    this.updateNode = this.updateNode.bind(this)
    this.updateRootDocuments = this.updateRootDocuments.bind(this)
    this.exportHasQRCode = this.exportHasQRCode.bind(this)
    this.exportPLC = this.exportPLC.bind(this)
    this.exportActiveNodes = this.exportActiveNodes.bind(this)

    this.jsonUpload = React.createRef()
  }

  public componentDidMount() {
    this.resetHistory()
    this.loadModelData()

    PLANT_PREFIX = this.props.plantPrefix
    YOUTUBE_KEY = this.props.youtubeKey

    if (
      this.props.storageData === [] ||
      !this.props.storageLastUpdate ||
      this.props.storageLastUpdate + 10800 < Math.floor(new Date().getTime() / 1000)
    ) {
      this.props.fetchStorageData()
    }

    window.addEventListener('resize', this.updateDimensions)
    window.onbeforeunload = () => this.props.t('designer.confirm.leave')
  }

  public componentWillUnmount() {
    window.onbeforeunload = null
    window.removeEventListener('resize', this.updateDimensions)
  }

  public componentDidUpdate(prevProps: DesignerProps) {
    this.updateDimensions()
  }

  public render() {
    if (this.props.error) {
      return (
        <div key={Date.now()} className="col-lg text-center">
          <i className="fas fa-2x fa-exclamation-triangle text-danger" />
        </div>
      )
    }
    if (this.props.fetching) {
      return (
        <div key={Date.now()} className="col-lg text-center">
          <i className="fas fa-2x fa-spin fa-circle-notch" />
        </div>
      )
    }

    if (this.state.errorFound) {
      return (
        <div className="alert alert-danger" role="alert">
          <strong>Ops...</strong> Something went wrong
        </div>
      )
    }

    const breadcrumbs: any = []
    const currentPath: string[] = []

    if (this.state.data && this.state.data.content && this.state.data.content.subcomponents) {
      if (this.state.columns) {
        this.state.columns.forEach((column: any, i: number) => {
          currentPath.push(column.data.id)
          breadcrumbs.push(
            <span
              className={'level-' + i}
              data-depth={i}
              data-path={currentPath.join('.')}
              key={i}
              onClick={e => this.handleBreadcrumnsClick(e)}
            >
              {column.data.label}
            </span>
          )
        })
      }
    }

    const sideContentOpen = (this.state.showSideFormEdit || this.state.showSideFormAdd) && this.state.currentId
    const rootDocuments =
      this.state.data &&
      this.state.data.content &&
      this.state.data.content.data &&
      this.state.data.content.data.documents
        ? this.state.data.content.data.documents
        : []

    const actionsDropdown: DropdownAction[] = [
      {
        label: this.props.t('designer.global.exports.hasQRCode'),
        onClick: this.exportHasQRCode,
      },
      /*
      {
        label: 'PLC',
        onClick: this.exportPLC,
      }, */
      {
        label: this.props.t('designer.global.exports.activeNodes'),
        onClick: this.exportActiveNodes
      }
    ]

    // @ts-ignore
    return (
      <div className={'inplant-designer-fe' + (sideContentOpen ? ' sidecontent-open' : '')}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="DashboardWidget WidgetDesigner WidgetH50">
                    <div className="col-sm-12 col-md-6 text-right top-actions">
                      {this.state.copiedNode && (
                        <button
                          className="btn btn-light mr-3 my-2 float-left"
                          title={this.state.copiedNode.data.id + ' - ' + this.state.copiedNode.data.label}
                        >
                          {this.state.isNodeCut ? (
                            <React.Fragment>
                              <IconComponent icon={'cut'} /> {this.props.t('designer.global.actions.cuttedNode')}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <IconComponent icon={'copy'} /> {this.props.t('designer.global.actions.copiedNode')}
                            </React.Fragment>
                          )}
                        </button>
                      )}

                      <DropdownButton actions={actionsDropdown} variant={"secondary"} >
                        {this.props.t('designer.global.actions.export')}
                      </DropdownButton>

                      <button className="btn btn-warning mr-3 my-2" onClick={this.emptyModel}>
                        <IconComponent icon={'trash-alt'} /> {this.props.t('designer.global.actions.resetModel')}
                      </button>

                      <input
                        accept="application/json, .json"
                        id="jsonUpload"
                        onChange={e => this.handleModelUpload(e.target.files)}
                        ref={ref => (this.jsonUpload = ref)}
                        style={{ display: 'none' }}
                        type="file"
                      />
                      <button className="btn btn-secondary mr-3 my-2" onClick={() => this.jsonUpload.click()}>
                        <IconComponent icon={'upload'} /> {this.props.t('designer.global.actions.upload')}
                      </button>

                      <button className="btn btn-secondary mr-3 my-2" onClick={() => this.downloadModel(true)}>
                        <IconComponent icon={'download'} /> {this.props.t('designer.global.actions.download')}
                      </button>

                      <button
                        className="btn btn-primary mr-3 my-2"
                        disabled={!this.state.hasModifications}
                        onClick={() => this.submitModel()}
                      >
                        <IconComponent icon={'save'} /> {this.props.t('designer.global.actions.save')}
                      </button>
                    </div>
                    <div className="mb-3 tree-container" style={{ height: this.state.treeHeight }}>
                      <div className="main-title d-none d-lg-block">
                        <h1>{this.props.t('designer.module')}</h1>
                      </div>
                      <div className="breadcrumb">{breadcrumbs}</div>

                      <div className="wrapper">
                        <div
                          className={'innerWrapper ' + this.state.columnsClass}
                          style={{
                            width: (window.innerWidth < 768 ? window.innerWidth - 30 : 460) * this.state.columns.length,
                          }}
                        >
                          {this.state.columns &&
                            this.state.columns.map((c: any, i: number) => (
                              <div
                                className={'column col-' + (this.state.columns.length - i) + '-' + i}
                                data-depth={i}
                                key={i}
                                ref={() => 'col-' + i}
                                style={
                                  i + 1 === this.state.columns.length
                                    ? {
                                        width: window.innerWidth < 768 ? window.innerWidth - 30 : 460,
                                      }
                                    : {}
                                }
                              >
                                <div className="column-level-wrapper">
                                  <h3 className="column-title" onClick={this.handleClickLeft}>
                                    {c.data.label}
                                  </h3>

                                  <div className="column-level-inner-wrapper">
                                    <div className="container-fluid">
                                      {c.subcomponents &&
                                        c.subcomponents
                                          .filter((row: any) => row.level !== 'Metric')
                                          .map((row: any) => {
                                            const active: boolean = !!(
                                              (sideContentOpen &&
                                                !this.state.showSideFormAdd &&
                                                row.data.id === this.state.currentId) ||
                                              (this.state.currentId &&
                                                this.state.currentId === row.data.id &&
                                                this.state.showSideFormAdd)
                                            )

                                            return (
                                              <TreeNode
                                                clickAdd={this.handleClickAddChild}
                                                clickCopy={this.handleClickCopy}
                                                clickCut={this.handleClickCut}
                                                clickDelete={this.handleClickDelete}
                                                clickEdit={this.handleClickEdit}
                                                clickLeft={this.handleClickLeft}
                                                clickMove={this.handleClickMove}
                                                clickRight={this.handleClickRight}
                                                currentActive={active}
                                                data={row}
                                                id={row.data.id}
                                                key={row.data.id}
                                                onSidecontentChange={this.sidecontentChange}
                                                parent={c.data.id}
                                                path={c.path}
                                                selectNode={this.selectNode}
                                                root={
                                                  this.state.data &&
                                                  this.state.data.content &&
                                                  this.state.data.content.path
                                                    ? this.state.data.content.path
                                                    : null
                                                }
                                              />
                                            )
                                          })}

                                      <div className={'column-actions ' + (this.state.copiedNode ? 'multiple' : '')}>
                                        <a
                                          className="btn btn-default add-sibling-node"
                                          onClick={() => this.handleClickAdd(c.data.id, c.parent, c.path)}
                                        >
                                          <span>
                                            <IconComponent icon={'plus'} />
                                          </span>
                                        </a>
                                        {this.state.copiedNode && this.state.copiedNode.level && (
                                          <a
                                            className={
                                              'btn btn-default paste-node ' +
                                              (c.descriptor.allowedSubcomponents.find(
                                                (sc: Template) => sc.name === this.state.copiedNode!.level
                                              )
                                                ? ''
                                                : 'disabled')
                                            }
                                            onClick={() => this.handleClickPaste(c.data.id, c.parent, c.path)}
                                          >
                                            <span>
                                              <IconComponent icon={'paste'} />
                                            </span>
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    {this.state.showSideFormEdit && this.state.currentId && (
                      <div className="d-none d-lg-block sidecontent" style={{ height: this.state.treeHeight }}>
                        <NodeFormComponent
                          action="edit"
                          allowedChilds={this.state.currentAllowedChilds}
                          callback={this.updateNode}
                          clickClose={this.disableSidecontent}
                          clickDelete={this.handleClickDelete}
                          data={this.state.columns[this.state.columns.length - 1]}
                          id={this.state.currentId}
                          model={this.state.data.content}
                          parent={this.state.currentParent}
                          path={this.state.currentPath}
                        />
                      </div>
                    )}

                    {this.state.showSideFormAdd && this.state.currentId && (
                      <div className="d-none d-lg-block sidecontent" style={{ height: this.state.treeHeight }}>
                        <NodeFormComponent
                          action="add"
                          allowedChilds={this.state.currentAllowedChilds}
                          callback={this.addChildNode}
                          clickClose={this.disableSidecontent}
                          data={this.state.columns[this.state.columns.length - 1]}
                          id={this.state.currentId}
                          isChild={this.state.sideFormAddChild}
                          model={this.state.data.content}
                          parent={this.state.currentParent}
                          path={this.state.currentPath}
                        />
                      </div>
                    )}

                    {!this.state.showSideFormAdd &&
                      !this.state.showSideFormEdit &&
                      this.state.data &&
                      this.state.data.content &&
                      this.state.data.content.subcomponents &&
                      this.state.currentPath === this.state.data.content.data.id && (
                        <div className="d-none d-lg-block sidecontent" style={{ height: this.state.treeHeight }}>
                          <h3
                            className={'section-header' + (this.state.sections.indexOf('metrics') > -1 ? ' open' : '')}
                            onClick={() => this.changeAccordion('metrics')}
                          >
                            {this.state.sections.indexOf('metrics') > -1 && <IconComponent icon={'caret-down'} />}
                            {!(this.state.sections.indexOf('metrics') > -1) && <IconComponent icon={'caret-right'} />}
                            {this.props.t('designer.plant.metrics')}
                          </h3>
                          <div
                            className={
                              'form-metrics form-metrics-global section-body' +
                              (this.state.sections.indexOf('metrics') > -1 ? ' open' : '')
                            }
                          >
                            <div className="section-wrapper">

                              {this.state.data.content.subcomponents.filter((e: NodeType) => e.level === 'Metric')
                                .length > 0 && (
                                <MetricsListComponent
                                  current={this.state.metric}
                                  data={
                                    this.state.reorderedData ||
                                    this.state.data.content.subcomponents.filter((e: NodeType) => e.level === 'Metric')
                                  }
                                  edit={this.editPlantMetric}
                                  editable={true}
                                  remove={this.removePlantMetric}
                                  reorderedData={this.setReorderedData}
                                />
                              )}
                            </div>
                          </div>

                          <h3
                            className={
                              'section-header' + (this.state.sections.indexOf('attachments') > -1 ? ' open' : '')
                            }
                            onClick={() => this.changeAccordion('attachments')}
                          >
                            {this.state.sections.indexOf('attachments') > -1 && <IconComponent icon={'caret-down'} />}
                            {!(this.state.sections.indexOf('attachments') > -1) && (
                              <IconComponent icon={'caret-right'} />
                            )}
                            {this.props.t('designer.node.attachments')}
                          </h3>
                          <div
                            className={
                              'section-body' + (this.state.sections.indexOf('attachments') > -1 ? ' open' : '')
                            }
                          >
                            <div className="section-wrapper">
                              <Attachments
                                documents={rootDocuments}
                                enableAdd={true}
                                update={this.updateRootDocuments}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay-focuser" />
        <Prompt when={this.state.hasModifications} message={this.props.t('designer.confirm.leave')} />
      </div>
    )
  }

  private updateDimensions() {
    const offsetPosElem = document.getElementById('topBar') || null
    if (offsetPosElem && offsetPosElem.clientHeight) {
      this.treeHeight = window.innerHeight - offsetPosElem.clientHeight - 100
    }
  }

  private loadModelData() {
    this.props.fetchData().then(modelData => {
      let nextState = {}

      if (
        modelData &&
        modelData.content
        // && this.state.currentPath === null
      ) {
        const path = this.state.currentPath || modelData.content.data.id
        const columnsData = buildColumnsData(modelData.content, '', path)
        const data = { ...modelData }

        saveAllIds(data.content)

        hydrateNodes(data.content)

        nextState = {
          currentPath: path,
          columns: columnsData,
          treeHeight: this.treeHeight,
          data,
        }

        if (nextState !== {}) {
          this.setState({
            ...nextState,
            ...{ treeHeight: this.treeHeight },
          })
        }
      }
    })
  }

  private computeMemoryOffsets(data: NodeType) {
    let curMacroArea: any = null
    let memDataByte: number = 0
    let memDataBits: number = 0
    let previousB: number = 0 // 8 bits or 1 bit
    let previousReset: number = 0 // 8 bits or 1 bit
    let curDataBlock: string = ''

    const findMacroArea = (path: NodeType) => {
      if (path.level === 'MacroArea') {
        if (path.data.id !== curMacroArea) {
          memDataByte = 0
          memDataBits = 0
          previousB = 0
          curMacroArea = path.data.id
          curDataBlock = path.data.dataBlock
        }
        findComponents(path)
      } else if (path.subcomponents) {
        path.subcomponents.forEach((e: NodeType | MetricTemplate) => findMacroArea(e as NodeType))
      }
    }

    const rootMetrics = (path: NodeType) => {
      if (path.level === 'Plant') {
        memDataByte = 0
        memDataBits = 0
        previousB = 0
        previousReset = 0
        curMacroArea = path.data.id
        curDataBlock = 'DB1000'
        findComponents(path)
      }
    }

    const findComponents = (path: NodeType) => {
      if (path.subcomponents) {
        path.subcomponents.forEach((e: NodeType | MetricTemplate) => {
          if (e.memory) {
            const memory: MemoryAddressType = e.memory

            if (memory.offset) {
              memDataByte += memory.offset
            }

            if (!memory.bits && previousB === 1 && previousReset !== 1) {
              if (memDataBits < 8) {
                memDataByte += 2
              } else {
                memDataByte += 1
              }
              memDataBits = 0
            }

            ;(e as MetricTemplate).memoryAddress = memory.bits
              ? memory.prefix + memDataByte + '.' + (memDataBits % 8) + memory.sufix
              : memory.prefix + memDataByte + memory.sufix
            ;(e as MetricTemplate).memoryAddress =
              this.props.plantMemoryRoot + curDataBlock + ',' + (e as MetricTemplate).memoryAddress
            // console.log(e); // tslint:disable-line
            if (memory.bits) {
              if ((e as MetricTemplate).type === 'Restarti4PService') {
                memDataBits = 0
                memDataByte += 2
              } else {
                memDataBits += memory.bits

                if (memDataBits === 8 * memory.bytes) {
                  memDataBits = 0
                }
                if (memDataBits % 8 === 0 && memDataBits > 0) {
                  memDataByte += 1
                }
              }

              previousB = 1
            } else {
              memDataBits = 0
              memDataByte += memory.bytes
              previousB = 8
            }
            previousReset = (e as MetricTemplate).type === 'Restarti4PService' ? 1 : 0
          }
        })
      }
      if (path.level !== 'Plant' && path.subcomponents) {
        // @ts-ignore
        path.subcomponents.filter((e: any) => e.level !== 'Metric').forEach(e => findComponents(e))
      }
    }

    rootMetrics(data)
    findMacroArea(data)
  }

  private handleClickEdit(id: string, parent: string, path: string) {
    const node: NodeType | false = findNodeByPath(path, this.state.data.content)

    if (node) {
      this.setState({
        showSideFormEdit: true,
        showSideFormAdd: false,
        activePath: path + '.' + id,
        currentId: id,
        currentParent: parent,
        currentAllowedChilds: node.descriptor.allowedSubcomponents || [],
      })
    }
  }

  private handleClickCopy(id: string, parent: string, path: string) {
    const node: NodeType | boolean = findNodeByPath(path + '.' + id, this.state.data.content) || false

    if (node) {
      this.setState({
        copiedNode: node,
        isNodeCut: false,
      })
    } else {
      this.setState({
        copiedNode: null,
        isNodeCut: true,
      })
    }
  }

  private handleClickCut(id: string, parent: string, path: string) {
    const node: NodeType | boolean = findNodeByPath(path + '.' + id, this.state.data.content) || false

    if (node) {
      this.setState({
        copiedNode: node,
        isNodeCut: true,
      })
    } else {
      this.setState({
        copiedNode: null,
        isNodeCut: false,
      })
    }
  }

  private handleClickAdd(id: string, parent: string, path: string) {
    // const pathNodes = path.split(".");
    const node: NodeType | boolean = findNodeByPath(path, this.state.data.content) || false

    if (node !== false) {
      this.setState({
        showSideFormEdit: false,
        showSideFormAdd: true,
        sideFormAddChild: false,
        currentId: id,
        currentParent: parent,
        currentAllowedChilds: node.descriptor.allowedSubcomponents || [],
      })
    }
  }

  private handleClickPaste(id: string, parent: string, path: string) {
    const node: NodeType | boolean = findNodeByPath(path, this.state.data.content) || false
    const model: ModelObject = JSON.parse(JSON.stringify(this.state.data))
    if (node !== false) {
      // @TODO check if can be child
      const newNode = JSON.parse(JSON.stringify(this.state.copiedNode))
      if (newNode) {
        if (this.state.isNodeCut) {
          deleteNode(newNode.path, model.content)
        } else {
          cleanCopiedNodes(newNode)
        }

        addChild(path, newNode, model.content)
        hydrateNodes(model.content)

        this.setState({
          columns: buildColumnsData(model.content, '', this.state.currentPath || undefined),
          copiedNode: null,
          data: model,
          hasModifications: true,
        })
      }
    }
  }

  private handleClickAddChild(id: string, parent: string, path: string) {
    const node: NodeType | boolean = findNodeByPath(path + '.' + id, this.state.data.content)

    if (node) {
      this.setState({
        currentAllowedChilds: node.descriptor.allowedSubcomponents || [],
        currentId: id,
        currentParent: parent,
        showSideFormAdd: true,
        showSideFormEdit: false,
        sideFormAddChild: true,
      })
    }
  }

  private handleClickDelete(nodePath: string) {
    if (nodePath && confirm(this.props.t('designer.confirm.delete'))) {
      let newStateData = { ...this.state.data.content } // tslint:disable-line

      deleteNode(nodePath, newStateData)
      hydrateNodes(newStateData)

      newStateData = {
        content: newStateData,
        version: this.state.data.version,
      }

      let newState = {}

      if (this.state.currentPath !== null) {
        const idElements = validPath(this.state.currentPath, newStateData).split('.')

        if (idElements.length > 0) {
          newState = {
            columns: buildColumnsData(newStateData.content, '', idElements.join('.')),
            currentPath: idElements.join('.'),
            data: newStateData,
            hasModifications: true,
            showSideFormAdd: false,
            showSideFormEdit: false,
            sideFormAddChild: false,
          }
        }
      }

      this.setState(newState)
    }
  }

  private handleClickRight(path: string) {
    if (this.state.data && this.state.data.content && this.state.data.content.subcomponents) {
      const newPath = validPath(path, this.state.data)

      this.setState({
        columns: buildColumnsData(this.state.data.content, '', newPath),
        columnsClass: 'animating',
        currentPath: newPath,
        showSideFormAdd: false,
        showSideFormEdit: false,
      })

      setTimeout(() => {
        this.setState({
          columnsClass: 'stopped',
        })
      }, 10)
    }
  }

  private handleClickMove(nodePath: string, direction: number) {
    let newStateData = { ...this.state.data.content } // tslint:disable-line
    const nodePathArray = nodePath.split('.')
    const nodeId = nodePathArray.pop()
    const nodeParent = findNodeByPath(nodePathArray.join('.'), newStateData) as NodeType

    if (nodeParent.subcomponents) {
      const metricSubcomponents = nodeParent.subcomponents.filter(
        (e: NodeType | MetricTemplate) => e.level === 'Metric'
      )
      const nodeSubcomponents = nodeParent.subcomponents.filter((e: NodeType | MetricTemplate) => e.level !== 'Metric')

      const ind = nodeSubcomponents.findIndex((e: NodeType | MetricTemplate) => (e as NodeType).data.id === nodeId)

      if (ind !== -1) {
        let newInd = ind + direction
        const maxInd = nodeSubcomponents.length

        if (newInd < 0) {
          newInd = 0
        } else if (newInd > maxInd) {
          newInd = maxInd
        }

        if (newInd !== ind) {
          arrayMove.mutate(nodeSubcomponents, ind, newInd)

          nodeParent.subcomponents = metricSubcomponents.concat(nodeSubcomponents)

          let newState = {}
          newStateData = {
            content: newStateData,
            version: this.state.data.version,
          }

          if (this.state.currentPath !== null) {
            const idElements = validPath(this.state.currentPath, newStateData).split('.')

            if (idElements.length > 0) {
              newState = {
                columns: buildColumnsData(newStateData.content, '', idElements.join('.')),
                currentPath: idElements.join('.'),
                data: newStateData,
                hasModifications: true,
                showSideFormAdd: false,
                showSideFormEdit: false,
                sideFormAddChild: false,
              }
            }
          }

          this.setState(newState)
        }
      }
    }
  }

  private resetHistory() {
    if (this.props.history) {
      this.props.history.push('/designer')
    }
  }

  private handleClickLeft() {
    if (this.state.data && this.state.data.content && this.state.data.content.subcomponents) {
      if (this.state.currentPath !== this.state.data.content.data.id && this.state.currentPath !== null) {
        const newPathArray = this.state.currentPath.split('.')
        const newPath = newPathArray.slice(0, newPathArray.length - 1).join('.')

        const cols = Object.keys(this.refs)
        const lastCol = ReactDOM.findDOMNode(this.refs[cols[cols.length - 1]]) as any

        if (lastCol) {
          lastCol.setAttribute('class', 'column to-hide')
        }

        setTimeout(() => {
          this.setState({
            columns: buildColumnsData(this.state.data.content, '', newPath),
            currentPath: newPath,
            showSideFormAdd: false,
            showSideFormEdit: false,
          })
        }, 300)
      }
    }
  }

  private handleBreadcrumnsClick(e: React.MouseEvent<HTMLSpanElement>) {
    this.setState({
      columns: buildColumnsData(this.state.data.content, '', e.currentTarget.dataset.path),
      currentPath: e.currentTarget.dataset.path || null,
      showSideFormAdd: false,
      showSideFormEdit: false,
    })
  }

  private sidecontentChange(params: SideContentParams) {
    this.setState({
      cardTitle: params.toDisable ? '' : params.title,
      columnNames: params.toDisable ? [] : params.columnNames,
      docs: params.toDisable ? [] : params.data,
      showSideFormEdit: !params.toDisable,
    })
  }

  private selectNode(id: string, parent: string) {
    let nextState = {}
    if (this.state.currentId === id && this.state.currentParent === parent) {
      nextState = {
        currentId: null,
        currentParent: null,
        showSideFormAdd: false,
        showSideFormEdit: false,
      }
    } else {
      nextState = {
        currentId: id,
        currentParent: parent,
        showSideFormAdd: false,
        showSideFormEdit: false,
      }
    }

    this.setState(nextState)
  }

  private emptyModel() {
    const structure = {
      content: {
        enabled: 'Enabled',
        data: { documents: [], id: 'plant', pid: '', fpid: '', label: 'Plant' },
        subcomponents: [
          /*            {
                        "data": { "label": "OEE", "id": "OEE", "dataBlock": "DB1000", "documents": [] },
                        "enabled": "Enabled",
                        "level": "MacroArea",
                        "subcomponents": [],
                        "path": "plant.OEE",
                        "descriptor": MacroArea
                      }, */
          {
            data: { label: 'Treatment', id: 'treatment', documents: [], dataBlock: 'DB1001' },
            enabled: 'Enabled',
            level: 'MacroArea',
            subcomponents: [],
            path: 'plant.treatment',
            descriptor: MacroArea,
          },
          {
            data: { label: 'Cataphoresis', id: 'cataphoresis', documents: [], dataBlock: 'DB1002' },
            enabled: 'Enabled',
            level: 'MacroArea',
            subcomponents: [],
            path: 'plant.cataphoresis',
            descriptor: MacroArea,
          },
          {
            data: { label: 'Ovens', id: 'ovens', documents: [], dataBlock: 'DB1003' },
            enabled: 'Enabled',
            level: 'MacroArea',
            subcomponents: [],
            path: 'plant.ovens',
            descriptor: MacroArea,
          },
          {
            data: { label: 'Booths', id: 'booths', documents: [], dataBlock: 'DB1004' },
            enabled: 'Enabled',
            level: 'MacroArea',
            subcomponents: [],
            path: 'plant.booths',
            descriptor: MacroArea,
          },
          {
            data: { label: 'Transport', id: 'transport', documents: [], dataBlock: 'DB1005' },
            enabled: 'Enabled',
            level: 'MacroArea',
            subcomponents: [],
            path: 'plant.transport',
            descriptor: MacroArea,
          },
          {
            data: { label: 'Auxiliary', id: 'auxiliary', documents: [], dataBlock: 'DB1006' },
            enabled: 'Enabled',
            level: 'MacroArea',
            subcomponents: [],
            path: 'plant.auxiliary',
            descriptor: MacroArea,
          },
          {
            data: { label: 'LoadUnload', id: 'loadunload', documents: [], dataBlock: 'DB1007' },
            enabled: 'Enabled',
            level: 'MacroArea',
            subcomponents: [],
            path: 'plant.loadunload',
            descriptor: MacroArea,
          },
        ],
        level: 'Plant',
        path: 'plant',
        descriptor: Plant,
      },
      version: 2,
    }

    if (confirm(this.props.t('designer.confirm.resetModel'))) {
      hydrateNodes(structure.content)

      this.setState({
        columns: buildColumnsData(structure.content, ''),
        currentPath: structure.content && structure.content.data.id ? structure.content.data.id : '',
        data: structure,
        hasModifications: true,
        showSideFormAdd: false,
        showSideFormEdit: false,
        sideFormAddChild: false,
      })

      this.resetHistory()
    }
  }

  private addChildNode(nodeDetails: any) {
    if (nodeDetails) {
      if (nodeDetails && this.state.currentId) {
        if (nodeDetails.data && !nodeDetails.data.id) {
          nodeDetails.data.id = generateNodeId()
        }

        let newStateData = { ...this.state.data.content } // tslint:disable-line

        if (!this.state.currentParent && newStateData.data.id === this.state.currentId) {
          // root node
          if (newStateData.subcomponents) {
            newStateData.subcomponents.push(nodeDetails)
          } else {
            newStateData.subcomponents = [nodeDetails]
          }
        } else {
          addChild(
            this.state.sideFormAddChild ? this.state.currentPath + '.' + this.state.currentId : this.state.currentPath,
            nodeDetails,
            newStateData
          )
        }

        const newStateDataFull = {
          content: newStateData,
          version: this.state.data.version,
        }
        hydrateNodes(newStateDataFull.content)

        let newState = {}

        const id =
          this.state.currentPath && this.state.currentPath !== this.state.currentId
            ? this.state.currentPath + '.' + this.state.currentId
            : newStateDataFull.content.data.id

        const idElements = validPath(id, newStateDataFull).split('.')

        if (idElements.length > 0) {
          newState = {
            columns: buildColumnsData(newStateDataFull.content, '', idElements.join('.')),
            currentPath: idElements.join('.'),
            data: newStateDataFull,
            hasModifications: true,
            showSideFormAdd: false,
            showSideFormEdit: false,
            sideFormAddChild: false,
          }

          popupNotification({
            text: this.props.t('designer.node.addChild.success'),
            type: 'success'
          })
        }

        this.setState(newState)
      } else {
        popupNotification({
          text: this.props.t('designer.node.addChild.notFound'),
          type: 'error'
        })
      }
    } else {
      popupNotification({
        text: this.props.t('designer.node.addChild.notFound'),
        type: 'error'
      })
    }
  }

  /*
@todo create custom component
*/

  private disableSidecontent() {
    this.setState({
      showSideFormAdd: false,
      showSideFormEdit: false,
    })
  }

  private handleMetricAdd() {
    this.setState({
      metricShowForm: 'add',
      metric: null,
    })
  }

  private handleMetricSave(metric: any) {
    const oldMetric = JSON.parse(JSON.stringify(this.state.metric))
    const plant = JSON.parse(JSON.stringify(this.state.data))

    if (!metric.level) {
      metric.level = 'Metric'
    }

    if (!metric.id) {
      metric.id = generateNodeId()
    }

    if (metric.dataType) {
      const memory = MemoryAddressTypes.find(
        (e: MemoryAddressType) => e.prefix + e.sufix + (e.sufixLabel ? ' - ' + e.sufixLabel : '') === metric.dataType
      )
      if (memory) {
        metric.memory = memory
      }
    }

    if (Metric.fields.filter((e: TemplateField) => e.name === 'savingMode').length > 0 && !metric.savingMode) {
      metric.savingMode = 'Other'
    }

    if (!metric.translations) {
      metric.translations = {}
    }

    if (metric.polling === 'true' || metric.polling === 'false') {
      metric.polling = metric.polling === 'true'
    }

    if (oldMetric === null) {
      plant.content.subcomponents.push(metric)
    } else {
      plant.content.subcomponents.forEach((subcomponent: any, i: number) => {
        if (subcomponent.level === 'Metric') {
          if (oldMetric.data && subcomponent.data) {
            if (oldMetric.data.id === subcomponent.data.id) {
              plant.content.subcomponents[i] = metric
            }
          } else if (oldMetric.id === subcomponent.id) {
            plant.content.subcomponents[i] = metric
          }
        }
      })
    }

    this.setState({
      metricShowForm: null,
      data: plant,
      hasModifications: true,
    })
  }

  private handleMetricCancel() {
    this.setState({
      metricShowForm: null,
    })
  }

  private removePlantMetric(e: React.MouseEvent<HTMLSpanElement>, metric: any) {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    if (this.state.data && this.state.data.content && confirm(this.props.t('designer.confirm.removeMetric'))) {
      const plant = JSON.parse(JSON.stringify(this.state.data))
      const index = plant.content.subcomponents.findIndex((m: any) => {
        return m.level === 'Metric' && m.id === metric.id
      })

      if (index !== -1) {
        plant.content.subcomponents.splice(index, 1)
      }

      this.setState({
        data: plant,
        hasModifications: true,
      })
    }
  }

  /*
  / @todo create custom component
  */

  private editPlantMetric(e: React.MouseEvent<HTMLSpanElement>, metric: any) {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    this.setState({
      metric,
      metricData: metric.data ? metric.data : metric,
      metricErrors: {},
      metricShowForm: 'edit',
    })
  }

  private downloadModel(full: boolean = false) {
    const dataCopy = JSON.parse(JSON.stringify(this.state.data)) // need deep copy
    if (full) {
      orderSubcomponents(dataCopy.content)
      // hydrateNodesMeasures(dataCopy.content)
      // this.computeMemoryOffsets(dataCopy.content)
    }
    cleanNodes(dataCopy.content)
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(dataCopy))
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', 'model_' + Date.now() + '.json')
    document.body.appendChild(downloadAnchorNode)
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }

  private submitModel() {
    if (confirm(this.props.t('designer.confirm.saveModel'))) {
      const dataCopy = JSON.parse(JSON.stringify(this.state.data))
      orderSubcomponents(dataCopy.content)
      // hydrateNodesMeasures(dataCopy.content)
      // this.computeMemoryOffsets(dataCopy.content)

      cleanNodes(dataCopy.content)

      this.props.submitData(dataCopy.content).then(() => {
        popupNotification({
          text: this.props.t('designer.response.saved'),
          type: 'success'
        })
        this.loadModelData()
      })
    }
  }

  private updateNode(nodeData: any) {
    const node = this.state.columns[this.state.columns.length - 1]

    if (node) {
      if (node.data && !node.data.id) {
        node.data.id = generateNodeId()
      }
      const nodeDetails = node.subcomponents.find(
        (e: any) => e.path === this.state.currentPath + '.' + this.state.currentId
      )
      if (nodeDetails && this.state.currentId) {
        let newStateData = { ...this.state.data.content } // tslint:disable-line

        updateDataNode(this.state.currentPath + '.' + this.state.currentId, newStateData, nodeData)

        const newStateDataFull = {
          content: newStateData,
          version: this.state.data.version,
        }

        hydrateNodes(newStateDataFull.content as NodeType)

        let newState = {}

        if (this.state.currentPath !== null) {
          const idElements = this.state.currentPath.split('.')

          if (idElements.length > 0) {
            newState = {
              columns: buildColumnsData(newStateDataFull.content, '', idElements.join('.')),
              currentPath: idElements.join('.'),
              data: newStateDataFull,
              hasModifications: true,
              showSideFormAdd: false,
              showSideFormEdit: false,
              sideFormAddChild: false,
            }

            popupNotification({
              text: this.props.t('designer.node.edit.success'),
              type: 'success'
            })
          }
        }

        this.setState(newState)
      } else {
        popupNotification({
          text: this.props.t('designer.node.edit.notFoundDetails'),
          type: 'error'
        })
      }
    } else {
      popupNotification({
        text: this.props.t('designer.node.edit.notFound'),
        type: 'error'
      })
    }
  }

  private handleModelUpload(files: FileList | null) {
    if (files) {
      const f = files[0]
      const reader = new FileReader()

      reader.onload = ((theFile: File) => {
        return (e: any) => {
          const JsonObj = e.target.result
          try {
            const data = JSON.parse(JsonObj)
            if (data) {
              // this.resetHistory();
              hydrateNodes(data.content)

              this.setState({
                data,
                currentPath: data.content.data ? data.content.data.id : '',
                columns: buildColumnsData(data.content, ''),
                hasModifications: true,
              })

              this.jsonUpload.value = ''
            }
          } catch (e) {
            console.log(e) // tslint:disable-line
          }
        }
      })(f)

      reader.readAsText(f, 'UTF-8')
    }
  }

  private handleMetricReorder() {
    if (confirm(this.props.t('designer.confirm.reorderMetrics'))) {
      const data = JSON.parse(JSON.stringify(this.state.data))
      data.content.subcomponents = this.state.reorderedData.concat(
        data.content.subcomponents.filter((e: NodeType) => e.level !== 'Metric')
      )

      this.setState({
        data,
        hasModifications: true,
        reorderedData: null,
      })
    }
  }

  private handleResetMetricReorder() {
    this.setState({
      reorderedData: null,
    })
  }

  private setReorderedData(reorderedData: any) {
    this.setState({
      reorderedData,
    })
  }

  private changeAccordion(label: string) {
    if (!label || label === '') {
      return
    }

    let sections = this.state.sections

    if (this.state.sections.indexOf(label) > -1) {
      sections.splice(this.state.sections.indexOf(label), 1)
    } else {
      sections = [label]
    }

    this.setState({
      sections,
    })
  }

  private updateRootDocuments(documents: any[]) {
    if (this.state.currentPath) {
      let newStateData = { ...this.state.data.content } // tslint:disable-line
      newStateData.data.documents = documents

      const newStateDataFull = {
        content: newStateData,
        version: this.state.data.version,
      }
      const idElements = this.state.currentPath.split('.')

      this.setState({
        columns: buildColumnsData(newStateDataFull.content, '', idElements.join('.')),
        currentPath: idElements.join('.'),
        data: newStateDataFull,
        hasModifications: true,
      })
    }
  }

  private exportHasQRCode() {
    const qrCodes = getAllQRCodes(this.state.data.content)
    saveDataAsCSV(qrCodes, 'qrcodes')
  }

  private exportPLC() {
    const plcData = getExportData(this.state.data)
    if (plcData.errorsText !== '') {
      alert(plcData.errorsText)
    }
    saveTextAsFile(plcData.plcText, 'plc', 'db')
  }

  private exportActiveNodes() {
    const activeNodesData = getActiveNodesExportData(this.state.data)
    saveDataAsCSV(activeNodesData, 'activeNodes', undefined, ',')
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(DesignerComponent))
)
