export const initialI4EnvironmentState = {
  data: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as I4EnvironmentState

export interface I4EnvironmentState {
  data: number[]
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}
