import { AnyAction } from 'redux'

export const indexInitialState = {
  error: null,
  fetching: false,
  data: [],
}

export const detailsInitialState = {
  error: null,
  fetching: false,
  data: [],
}

export const userIndex = (state = indexInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'USER_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: action.payload,
        }
      )
    case 'USER_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: false,
          data: action.payload,
        }
      )
    case 'USER_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          error: action.payload,
          fetching: false,
        }
      )
    default:
      return state
  }
}

export const userDetails = (state = detailsInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'USERDETAILS_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: action.payload,
        }
      )
    case 'USERDETAILS_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: false,
          data: action.payload,
        }
      )
    case 'USERDETAILS_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          error: action.payload,
          fetching: false,
        }
      )
    default:
      return state
  }
}

export const userSchema = (state = detailsInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'USERSCHEMA_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: action.payload,
        }
      )
    case 'USERSCHEMA_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: false,
          data: action.payload,
        }
      )
    case 'USERSCHEMA_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          error: action.payload,
          fetching: false,
        }
      )
    default:
      return state
  }
}
