import { ranges } from '@mv-submodules/inplant-core-fe/ui/components/widgets/dateFiltersBase/components/DateBaseFilters'

import * as moment from 'moment'

export interface DateRange {
  name: string
  dateStart: string
  dateEnd: string
}

export const getDatesFromRange = (range: string): DateRange => {
  const dates = ranges.find(r => r.name === range)
  let dateRange

  if (dates) {
    dateRange = {
      name: dates.name,
      dateStart: dates.start.format('YYYY-MM-DD HH:mm:ss').toString(),
      dateEnd: dates.end.format('YYYY-MM-DD HH:mm:ss').toString(),
    }
  } else {
    dateRange = getDatesFromRange('dateRange30Days')
  }

  return dateRange
}

export const getDaysDiff = (start: moment.Moment, end: moment.Moment): number => {
  return end.diff(start, 'days') + 1
}

export const getDateRangeFiltering = (
  dateStart: string,
  dateEnd: string,
  sixMonthsDetail = false
): { group: string; start: string; end: string; days: number, isDaily: boolean, groupingDays: number } => {
  let group = 'group by time(1d)'
  let days = 0
  let isDaily = true
  let groupingDays = 1

  const start = moment(dateStart)
  const end = moment(dateEnd)

  if (start && end) {
    days = getDaysDiff(start, end)

    switch (true) {
      case days <= 7:
        group = 'group by time(1d)'
        break

      case days > 7 && days <= 70:
        group = 'group by time(1d)'
        break

      case days > 70 && days < 201:
        group = sixMonthsDetail ? 'group by time(1w)' : 'group by time(30d, ' + new Date().getDate() + 'd)'
        groupingDays = sixMonthsDetail ? 7 : 30
        isDaily = false
        break

      case days > 201:
        group = 'group by time(30d, ' + new Date().getDate() + 'd)'
        groupingDays = 30
        isDaily = false
        break

      default:
        group = 'group by time(1d)'
        break
    }

    return {
      group,
      start: start.format('YYYY-MM-DD HH:mm:ss').toString(),
      end: end.format('YYYY-MM-DD HH:mm:ss').toString(),
      days,
      isDaily,
      groupingDays
    }
  }

  return {
    group,
    start: '',
    end: '',
    days,
    isDaily,
    groupingDays
  }
}
