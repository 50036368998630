// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import { saveDataAsCSV } from '../../../types/saveDateAsCSVInterface'
import { useComponentsTranslation } from '../../../services/translation'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

export interface Props {
  data: string[][]
  dataCallback?: () => Promise<any>
  type: string
  label: string
  className?: string
  fileName?: string
  addTime?: boolean
  text?: string
  saveDataAsCSV: saveDataAsCSV
}

const DownloadDataButton = (props: Props) => {
  const { t } = useComponentsTranslation()

  const download = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    let data = props.data

    if (undefined !== props.dataCallback) {
      data = await props.dataCallback()
    }

    switch (props.type) {
      case 'csv':
        props.saveDataAsCSV(
          data,
          props.fileName || undefined,
          props.type || undefined,
          props.addTime !== undefined ? props.addTime : undefined
        )
        break

      default:
        break
    }
  }

  return (
    <button
      onClick={download}
      className={'btn btn-outline-secondary btn-sm py-0 data-tooltips ' + props.className}
      data-title={t(props.label)}
    >
      <IconComponent icon={"download"} /> {props.text && t(props.text, { defaultValue: '' })}
    </button>
  )
}

export default DownloadDataButton
