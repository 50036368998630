import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  fetchAvailabilityDetailsAlarmsData,
  fetchAvailabilityDetailsData,
  fetchAvailabilityAlarmsData,
} from '../../../../redux/actions/graphs'
import GraphAvailabilityRSingle from '../../widgets/GraphAvailabilityRSingle/GraphAvailabilityRSingle'
import AlarmModal from '../../widgets/AlarmsModal/AlarmModal'
import * as moment from 'moment'
import ClickEvent = JQuery.ClickEvent
import { timeRangeFileString, ChartACL } from '../../../../functions/shared'
import { Alarms } from '../../../../types/AlarmListItem'
import DownloadDataButton from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import TPRNCWarnArea from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/ChartUtils/TPRNCWarnAreaLoader'
import { Card, CardBody, PageHeader } from '@mv-submodules/inplant-components-fe'
import DateRangeFilters from '@mv-submodules/inplant-core-fe/ui/components/widgets/dateFiltersBase/components/DateRangeFilters'
import { saveDataAsCSV } from '@mv-submodules/inplant-core-fe/functions'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { IGraphConfiguration } from '@mv-submodules/inplant-efficiency-fe-imel/types/GraphConfiguration'
import { EFFICIENCY_GRAPH_CONFIGURATION } from '@mv-submodules/inplant-efficiency-fe-imel/constants'
import { consoleLog } from '@mv-submodules/inplant-components-fe/mvfunctions/logs'

interface AvailabilityDetailsStateProps {
  data?: any
  dataAlarms?: any
  hasErrored: boolean
  hasErroredAlarms: boolean
  isLoading: boolean
  isLoadingAlarms: boolean
  columns: string[]
  columnsAlarms: string[]
  lastDateUpdate: number | null
  range: null | string
  dateStart: string | null
  dateEnd: string | null
}

interface AvailabilityDetailsDispatchProps {
  fetchData: () => Function
  fetchDataAlarms: (searchField: string[]) => Function
  fetchAlarmsData: () => Promise<Alarms[]>
}

interface AvailabilityDetailsOwnProps {
  // t: TranslationFunction
  history: any
  match: any
}

interface AvailabilityDetailsOwnState {
  lastUpdate: number | null
  legend: any | null
  data: any | null
  dataAlarms: any | null
  showDetailsItem: string | null
  efficiencyGraphConfiguration: IGraphConfiguration
}

const mapStateToProps = (state: any): AvailabilityDetailsStateProps => ({
  range: state.dateFilters.range,
  data: state.availabilityDetailsData.data,
  dataAlarms: state.availabilityDetailsAlarmsData.dataAlarms,
  hasErrored: state.availabilityDetailsData.hasErrored,
  hasErroredAlarms: state.availabilityDetailsAlarmsData.hasErroredAlarms,
  isLoading: state.availabilityDetailsData.isLoading,
  isLoadingAlarms: state.availabilityDetailsAlarmsData.isLoadingAlarms,
  columns: state.availabilityDetailsData.columns,
  columnsAlarms: state.availabilityDetailsAlarmsData.columnsAlarms,
  lastDateUpdate: state.dateFilters.lastUpdate,
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
})

const mapDispatchToProps = (dispatch: Function): AvailabilityDetailsDispatchProps => ({
  fetchData: () => dispatch(fetchAvailabilityDetailsData()),
  fetchDataAlarms: (searchField: string[]) => dispatch(fetchAvailabilityDetailsAlarmsData(searchField)),
  fetchAlarmsData: () => dispatch(fetchAvailabilityAlarmsData()),
})

type AvailabilityDetailsProps = AvailabilityDetailsStateProps &
  AvailabilityDetailsDispatchProps &
  AvailabilityDetailsOwnProps &
  WithTranslation

const msgClass = {
  1: 'process alarm',
  2: 'warning',
  64: 'safety stop',
  65: 'process stop',
  66: 'emergency stop',
  68: 'on demand stop',
}

class AvailabilityDetailsPageView extends React.Component<AvailabilityDetailsProps, AvailabilityDetailsOwnState> {
  constructor(props: AvailabilityDetailsProps) {
    super(props)
    this.state = {
      lastUpdate: null,
      legend: null,
      data: null,
      dataAlarms: null,
      showDetailsItem: null,
      efficiencyGraphConfiguration: EFFICIENCY_GRAPH_CONFIGURATION && Object.keys(EFFICIENCY_GRAPH_CONFIGURATION).includes('unplannedLoss') ? EFFICIENCY_GRAPH_CONFIGURATION : {
        unplannedLoss: { title: 'UNPLANNED LOSS', field: [{name:"alarms",color: "#66c2a5"},{name:"ondemand",color: "#8da0cb"},{name:"emergency",color: "#fc8d62"},{name:"safety",color: "#cb58ba"}]},
      },
    }

    consoleLog('EFFICIENCY_GRAPH_CONFIGURATION',EFFICIENCY_GRAPH_CONFIGURATION)
    this.openAlarmsModal = this.openAlarmsModal.bind(this)
    this.closeAlarmsModal = this.closeAlarmsModal.bind(this)
    this.getAlarmsData = this.getAlarmsData.bind(this)
  }

  public componentWillMount() {
    this.props.fetchData()
    this.props.fetchDataAlarms(this.state.efficiencyGraphConfiguration.unplannedLoss.field.map(f => f.name))
  }

  public componentWillReceiveProps(newProps: AvailabilityDetailsProps) {
    if (newProps.lastDateUpdate !== null && this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.props.fetchData()
      this.props.fetchDataAlarms(this.state.efficiencyGraphConfiguration.unplannedLoss.field.map(f => f.name))
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
    }

    if (
      newProps.lastDateUpdate === this.state.lastUpdate ||
      this.state.data !== newProps.data ||
      this.state.dataAlarms !== newProps.dataAlarms
    ) {
      this.setState({
        data: newProps.data,
        dataAlarms: newProps.dataAlarms,
      })
    }
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.showDetailsItem && (
          <AlarmModal date={this.state.showDetailsItem} closeModalFn={this.closeAlarmsModal} />
        )}

        <PageHeader title={'Availability Details'} />

        <div className="content">
          <div id="filters-wrapper">
            <Row spacing={{ vertical: false, horizontal: false }}>
              <Column xs={12}>
                <Card bgLight={true}>
                  <CardBody noGutters={true}>
                    <DateRangeFilters />
                  </CardBody>
                </Card>
              </Column>
            </Row>
          </div>

          <TPRNCWarnArea />

          <ChartACL chartName={'availability-details/planned-loss'}>
            <Row spacing={{ vertical: false, horizontal: false }}>
              <Column xs={12}>
                <GraphAvailabilityRSingle
                  fieldsSelected={[{name:'Warm-up'}, {name:'ready'}, {name:'start'}]}
                  title={'PLANNED LOSS'}
                  {...this.props}
                  dataSource={'data'}
                  unit={'min'}
                  valueMultiplier={0.01666666}
                />
              </Column>
            </Row>
          </ChartACL>

          <ChartACL chartName={'availability-details/unplanned-loss'}>
            <Row spacing={{ vertical: false, horizontal: false }}>
              <Column xs={12}>
                <GraphAvailabilityRSingle
                  fieldsSelected={this.state.efficiencyGraphConfiguration.unplannedLoss.field}
                  title={this.state.efficiencyGraphConfiguration.unplannedLoss.title}
                  {...this.props}
                  dataSource={'dataAlarms'}
                  unit={'min'}
                  valueMultiplier={0.01666666}
                  columnCallback={
                    this.props.range &&
                    ['dateRange7Days', 'dateRangeLastWeek', 'dateRange30Days', 'dateRangeLastMonth'].includes(
                      this.props.range
                    )
                      ? this.openAlarmsModal
                      : undefined
                  }
                  customButtons={[
                    <DownloadDataButton
                      saveDataAsCSV={saveDataAsCSV}
                      key={'alarmsDownload'}
                      data={[]}
                      dataCallback={this.getAlarmsData}
                      type={'csv'}
                      label={'i4efficiency.download.buttonLabel'}
                      className={'float-right mr-3'}
                      fileName={'availability_alarms_' + timeRangeFileString(this.props.dateStart, this.props.dateEnd)}
                      addTime={false}
                      text={'i4efficiency.download.buttonLabelAlarms'}
                    />,
                  ]}
                />
              </Column>
            </Row>
          </ChartACL>
        </div>
      </React.Fragment>
    )
  }

  private openAlarmsModal(e: any, index: number, event: React.MouseEvent<ClickEvent>) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      showDetailsItem: moment(e.time).format('YYYY-MM-DD'),
    })
  }

  private closeAlarmsModal() {
    this.setState({
      showDetailsItem: null,
    })
  }

  private async getAlarmsData() {
    const data = await this.props.fetchAlarmsData()
    let downloadDataSrc
    let downloadData

    if (data) {
      downloadDataSrc = data.map(alarm => {
        return [
          alarm.iTime && moment(alarm.iTime).isValid() ? moment(alarm.iTime).format('DD/MM/YYYY') : '',
          alarm.iTime && moment(alarm.iTime).isValid() ? moment(alarm.iTime).format('HH:mm:ss') : '--',
          alarm.aTime && moment(alarm.aTime).isValid() ? moment(alarm.aTime).format('HH:mm:ss') : '--',
          alarm.oTime && moment(alarm.oTime).isValid() ? moment(alarm.oTime).format('HH:mm:ss') : '--',
          alarm.duration,
          alarm.msgClass ? msgClass[alarm.msgClass] : '',
          alarm.message ? alarm.message.replace(/"\((.*)\) /, '').replace(/ \[(.*).\]"/, '') : '',
          alarm.cabinet + (alarm.component ? ' / ' + alarm.component : ''),
          alarm.msgNumber,
        ]
      })

      downloadData = [
        ['Date', 'In', 'Accepted', 'Out', 'Duration', 'Type', 'Text', 'Cabinet / Component', 'Alarm code'],
      ].concat(downloadDataSrc)

      return downloadData
    }

    return []
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AvailabilityDetailsPageView))
)
