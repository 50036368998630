import { AnyAction } from 'redux'
import { User } from '../../types/index'

export interface StateProps {
  fetching: boolean
  error: Error | null
  loggedIn: boolean
  user: User | null
  mobile: boolean
}

const initialState = {
  error: null,
  fetching: false,
  loggedIn: false,
  user: null,
  mobile: false,
}

export const auth = (state: StateProps = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload && action.payload.auth) {
        return {
          ...action.payload.auth,
          user: new User(action.payload.auth.user || {}),
        }
      } else {
        return state
      }
    case 'AUTH_HAS_ERRORED':
      return {
        ...state,
        error: action.payload,
        fetching: false,
        loggedIn: false,
        user: null,
      }
    case 'AUTH_IS_FETCHING':
      return {
        ...state,
        fetching: true,
        error: null,
        loggedIn: false,
        user: null,
      }
    case 'AUTH_SUCCESS':
      return {
        ...state,
        loggedIn: true,
        fetching: false,
        error: null,
        user: action.payload,
      }
    case 'AUTH_LOGOUT':
      return {
        ...state,
        loggedIn: false,
        fetching: false,
        error: null,
        user: null,
      }
    case 'AUTH_SET_MOBILE_FLAG':
      return {
        ...state,
        mobile: true,
      }
    default:
      return state
  }
}
