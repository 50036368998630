import * as React from 'react'
import {connect} from 'react-redux'
import Widget from '../../../../types/Widget'
import GraphGauges from '../../widgets/GraphGauges/GraphGauges'
import GraphPlanRunOee from '../../widgets/GraphPlanRunOee/GraphPlanRunOee'
import {Card, CardBody, PageHeader} from '@mv-submodules/inplant-components-fe';
import DateRangeFilters
  from "@mv-submodules/inplant-core-fe/ui/components/widgets/dateFiltersBase/components/DateRangeFilters";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface I4EfficiencyStateProps {
  widgets: Widget[]
  hasErrored: boolean
  isLoading: boolean
}

interface I4EfficiencyOwnProps {
  history: any
}

const mapStateToProps = (state: any): I4EfficiencyStateProps => ({
  widgets: state.widgets,
  hasErrored: state.widgetsHasErrored,
  isLoading: state.widgetsIsLoading,
})

const mapDispatchToProps = (dispatch: Function) => ({})

type I4EfficiencyProps = I4EfficiencyStateProps & I4EfficiencyOwnProps

class I4EfficiencyPageView extends React.Component<I4EfficiencyProps> {
  constructor(props: I4EfficiencyProps) {
    super(props)
    this.handlePlanRunClick = this.handlePlanRunClick.bind(this)
  }

  public handlePlanRunClick() {
    this.props.history.push('/i4efficiency/oee-details')
  }

  public render() {
    if (this.props.hasErrored) {
      return (
        <Column key={Date.now()}>
          <label className="text-center">
            <i className="fas fa-2x fa-exclamation-triangle text-danger"/>

          </label>
        </Column>
      )
    }
    if (this.props.isLoading) {
      return (
        <Column key={Date.now()}>
          <label className="text-center">
            <i className="fas fa-2x fa-spin fa-circle-notch"/>
          </label>
        </Column>
      )
    }
    return (
      <React.Fragment>
        <PageHeader title={"Efficiency"}/>

        <div className="content">
          <div id="filters-wrapper">
            <Row spacing={{vertical:false,horizontal:false}}>
              <Column>
                <Card bgLight={true}>
                  <CardBody noGutters={true}>
                    <DateRangeFilters/>
                  </CardBody>
                </Card>
              </Column>
            </Row>
          </div>

          <Row spacing={{vertical:false,horizontal:false}}>
            <Column xs={12}>
              <div className="row WidgetsOneLine">
                <Column lg={2}>
                  <GraphGauges/>
                </Column>
                <Column lg={10}>
                  <div onClick={this.handlePlanRunClick}>
                    <GraphPlanRunOee cliccable={true}/>
                  </div>
                </Column>
              </div>
            </Column>
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(I4EfficiencyPageView)
