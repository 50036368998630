import React, { useContext, useEffect, useState } from "react"
import { InversifyContext } from "./binding"
import { ComponentServiceError } from "./error"

export interface IComponentService<T extends {} = {}> {
  render(props: T): React.ReactElement<T>
}

export class ComponentService<T> implements IComponentService<T> {
  private _component: (props: T) => React.ReactElement<T>

  constructor(component: (props: T) => React.ReactElement<T>) {
    this._component = component
  }
  public render = (props: T): React.ReactElement<T, string | React.JSXElementConstructor<any>> => {
    return React.createElement(this._component, props)
  }
}

/**
 * @throws {ComponentServiceError}
 */
export function useComponent<T>(serviceId: symbol) {
  const { container } = useContext(InversifyContext)

  function getComponent() {
    if (!container) {
      throw new ComponentServiceError(
        `useComponent() required a ${serviceId.toString()} service, init it in the application root tree using ContainerProvider`
      )
    }
    try {
      return container.get<T>(serviceId)
    } catch (error) {
      throw new ComponentServiceError(
        `useComponent() required a ${serviceId.toString()} service, init it in the application root tree using ContainerProvider`
      )
    }
  }

  useEffect(() => {
    setComponent(getComponent())
  }, [])

  const [component, setComponent] = useState<T>()

  return component
}
