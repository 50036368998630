const en = {
  designer: {
    plantDesigner: 'Plant Designer',
    number: 'Number',
    notification: {
      success: 'Success',
      error: 'Error',
      info: 'Info',
      warning: 'Warning',
    },
    copy: {
      success: 'Copied in the clipboard!',
      click: 'Click to copy',
    },
    plant: {
      metrics: 'Global Metrics',
      attachments: 'Global Documents',
    },
    values: {
      true: 'True',
      false: 'False',
    },
    response: {
      saved: 'Model updated!',
    },
    media: {
      types: {
        Manual: 'Manual',
        Accessories: 'Accessories',
        TechnicalFile: 'Technical File',
        SpareParts: 'Spare Parts',
        Certification: 'Certification',
      },
      selectType: 'Select type',
      clickToEdit: 'Click to edit',
      translationModal: {
        title: 'Attachment translations',
        save: 'Save',
        close: 'Close',
      },
      hasLabelOrTranslation: 'Name was edited/translated',
      placeholder: {
        version: 'version'
      }
    },
    module: 'Designer',
    fetching: 'Fetching',
    i18n: {
      select: 'Generic',
    },
    fileBrowser: {
      modal: {
        title: 'Add file',
        cancel: 'Cancel',
        select: 'Select file',
        error: 'Error loading files',
      },
    },
    mediaPreview: {
      modal: {
        title: 'Add YouTube Video',
        cancel: 'Cancel',
        select: 'Add',
        error: 'Error loading media',
        placeholder: {
          url: 'video URL',
          title: 'video title',
        },
        previewTitle: 'Preview',
      },
      configureYouTubeKey: 'Please check YouTube API key',
    },
    modal: {
      search: 'Search file...',
    },
    node: {
      details: 'Details',
      metrics: 'Metrics',
      metric: {
        add: 'Add metric',
        edit: 'Edit metric',
        remove: 'Remove metric',
        cancel: 'Cancel',
        save: 'Save',
        saveOrder: 'Save Order',
        resetOrder: 'Restore',
        askSave: 'Please save order before adding new metrics',
        previewAction: 'Click to preview data',
        modal: {
          title: 'Metric preview (latests 100 entries)',
          titleSeparator: ' preview (latests 100 entries) ',
          close: 'Close',
          noData: 'No data available',
        },
      },
      attachments: 'Documents',
      media: 'Media',
      edit: {
        title: 'Edit node',
        submit: 'Save',
        delete: 'Delete',
        success: 'Node saved',
        notFoundDetails: 'Node details not found',
        notFound: 'Node not found',
      },
      add: {
        title: 'Add node',
        submit: 'Create',
      },
      addChild: {
        title: 'Add child node',
        submit: 'Create',
        success: 'Node added successfully',
        notFoundDetails: 'Node details not found',
        notFound: 'Node not found',
      },
      delete: {
        title: 'Delete node',
      },
      copy: {
        title: 'Copy node',
      },
      cut: {
        title: 'Cut node',
      },
      close: 'Cancel',
      move: {
        up: 'Move up',
        down: 'Move down',
      },
      attributes: {
        hasTranslations: 'Has Translations',
        hasQRCode: 'Has QR Code attached',
        checkboxDerivedState: 'Depends on "{{field}}"',
        node: {
          id: 'ID',
          label: 'Label',
          pid: 'P&D',
          fpid: 'Father P&D',
          dataBlock: 'Data Block',
          polling: 'Polling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
          nodeIsActive: 'Is Active'
        },
        plant: {
          id: 'ID',
          label: 'Label',
          pid: 'P&D',
          fpid: 'Father P&D',
          dataBlock: 'Data Block',
          polling: 'Polling',
          type: 'Type',
          nodeType: 'Node Type',
          nodeIsActive: 'Is Active',
        },
        macroarea: {
          id: 'ID',
          label: 'Label',
          pid: 'P&D',
          fpid: 'Father P&D',
          dataBlock: 'Data Block',
          polling: 'Polling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
          nodeIsActive: 'Is Active'
        },
        section: {
          id: 'ID',
          label: 'Label',
          pid: 'P&D',
          fpid: 'Father P&D',
          dataBlock: 'Data Block',
          polling: 'Polling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
          nodeIsActive: 'Is Active'
        },
        sectionGroup: {
          id: 'ID',
          label: 'Label',
          pid: 'P&D',
          fpid: 'Father P&D',
          dataBlock: 'Data Block',
          polling: 'Polling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
          nodeIsActive: 'Is Active'
        },
        componentGroup: {
          id: 'ID',
          label: 'Label',
          pid: 'P&D',
          fpid: 'Father P&D',
          dataBlock: 'Data Block',
          polling: 'Polling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
          nodeIsActive: 'Is Active'
        },
        component: {
          id: 'ID',
          label: 'Label',
          pid: 'P&D',
          fpid: 'Father P&D',
          dataBlock: 'Data Block',
          polling: 'Polling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
          nodeIsActive: 'Is Active'
        },
        metric: {
          id: 'ID',
          label: 'Label',
          pid: 'P&D',
          fpid: 'Father P&D',
          dataBlock: 'Data Block',
          memoryAddress: 'Data Block',
          polling: 'Polling',
          type: 'Type',
          nodeType: 'Node Type',
          savingMode: 'Saving Mode',
          dataType: 'Data Type',
        },
      },
      template: {
        select: 'Template',
        default: 'Select...',
      },
    },
    confirm: {
      delete: 'Are you sure you want to delete the node?',
      resetModel: 'Are you sure you want to reset the model?',
      saveModel:
        'WARNING!!!\n\nAre you sure you want to save the plant model?\n\nThe new plant model will be immediately used by the entire application.',
      removeAttachment: 'Are you sure you want to remove this attachment?',
      removeMedia: 'Are you sure you want to remove this media?',
      removeMetric: 'Are you sure you want to remove this metric?',
      reorderMetrics: 'Are you sure you want to save metrics order?',
      leave: 'WARNING!!!\n\nPlant model has changed, are you sure you want to leave?',
    },
    errors: {
      unique: 'should be unique',
      download: 'Error downloading file',
      invalidFPD: 'Invalid Father P&D, must exist',
      IDexists: 'ID already exists',
      formHasErrors: 'There are errors, please verify the data entered',
      relatedRequired: 'Required to edit "{{field}}"'
    },
    global: {
      actions: {
        upload: 'Upload',
        download: 'Download',
        downloadFull: 'Download Full',
        resetModel: 'Reset',
        save: 'Save',
        copiedNode: 'Copied',
        cuttedNode: 'Cutted',
        export: 'Export',
      },
      exports: {
        hasQRCode: 'QR Code',
        activeNodes: 'Active Nodes'
      },
    },
  },
  treeDetailsFilename: 'Name',
  treeDetailsVersion: 'Version',
  treeDetailsDate: 'Date',
  treeDetailsTitlenull: '',
  treeDetailsTitlemanuals: 'Manuals',
  treeDetailsTitlecertificates: 'Certificates',
  treeDetailsTitledataSheets: 'Data Sheets',
  treeDetailsTitlespareParts: 'Spare Parts',
  treeDetailsCode: 'Code',
  treeDetailscode: 'Code',
  treeDetailsDescription: 'Description',
  treeDetailsPrice: 'Price',
  treeDetailsprice: 'Price',
  treeDetailsTitlesparePartsList: 'Spare Parts Manuals',
  treeDetailsTitlemaintenances: 'Next Maintenances',
  treeDetailsoperation: 'Operation',
  treeDetailsdescription: 'Description',
  treeDetailsexpected: 'Expected',
  treeDetailsdocumentType: 'Type',
  treeDetailslink: 'Link',
  treeDetailslastEdit: 'Date',
}

export default en
