const en = {
  service: {
    module: 'Service',
    navigation: {
      list: 'List',
      new: 'New',
    },
    fetching: 'Loading',
    index: {
      title: 'Service',
      hasAttachments: 'Has attachments',
      table: {
        showAll: 'Show all',
        selectDateRange: 'Select date range',
        page: 'page',
        of: 'of',
        rows: 'rows',
        noData: 'No data',
      },
      new: 'New',
    },
    details: {
      openTimeEntriesModal: 'Time entries'
    },
    timeEntries: {
      title: 'Time entries',
      close: 'Close',
      table: {
        activity: 'Activity',
        hours: 'hours',
        created_on: 'Date',
        comments: 'Comments',
        noData: 'Non data',
        user: 'User'
      }
    },
    form: {
      all: 'all',
      assigned_to: 'Assignee',
      attachments: 'Attachments',
      author: 'Author',
      client_hours: 'Client hours',
      competence: 'Competence',
      create: 'Create',
      created_on: 'Created on',
      custom_fields: {
        '2': 'Contact person',
        '5': 'Warranty',
        '6': 'Expected cause',
        '7': 'Action type',
        '8': 'Onsite intervention',
        '9': 'Maker',
        '10': 'Action taken',
        '11': 'Action date',
        '12': 'Intervention date',
        '13': 'Closing notes',
        '14': 'Cause',
        '15': 'Closing date',
        '16': 'Competence',
        '17': 'Production order',
        '20': 'Contact person phone',
        '21': 'Customer Email',
      },
      description: 'Description',
      doneRatio: 'Done Ratio',
      done_ratio: 'Done Ratio',
      due_date: 'Due Date',
      estimated_hours: 'Estimated hours',
      id: 'ID',
      is_private: 'Privato',
      lastUpdate: 'Last Update',
      newTickedCreated: 'New ticket added successfully',
      newTicket: 'New Ticket',
      no: 'No',
      phone: 'Phone',
      priority: 'Priority',
      project: 'Project',
      requestType: 'Request Type',
      select: '- select -',
      spent_hours: 'Spent hours',
      startDate: 'Date',
      status: 'Status',
      subject: 'Subject',
      total_estimated_hours: 'Total estimated hours',
      tracker: 'Tracker',
      updated_on: 'Updated on',
      yes: 'Yes',
      watchers: 'Watchers'
    },
    journal: {
      title: 'Changes',
      modal: {
        close: 'Close'
      }
    },
    attachments: {
      preview: {
        title: 'Files to upload',
        download: 'Download',
      },
    },
  },
}

export default en
