import * as React from 'react'
import { connect } from 'react-redux'
import { isJSON } from '@mv-submodules/inplant-core-fe/functions'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { withTranslation, WithTranslation } from 'react-i18next'
import Timeout = NodeJS.Timeout
import { ChangeEvent } from 'react'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export const APIInflux = () => FetchWrapper.getInstance('influx')

interface StateProps {
  config: {
    rt: any
  }
}

interface OwnState {
  config: any
  reloading: boolean
  data: { [k: string]: any } | null
  previousData: { [k: string]: any } | null
  errors: boolean
  timerInterval: number
}

const mapStateToProps = (state: any): StateProps => ({
  config: state.config.widgets || null,
})

const parseInfluxData = (data: any) => {
  const parsedData =
    (data && data.results && data.results[0] && data.results[0].series && data.results[0].series[0]) || null

  if (parsedData && parsedData.values.length > 0) {
    return {
      name: parsedData.name,
      value: parsedData.values[0][1],
    }
  }
  return { [parsedData.name]: null }
}

type Props = StateProps & WithTranslation

class RTMeasures extends React.PureComponent<Props, OwnState> {
  private timer: Timeout | undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      config:
        this.props.config && this.props.config.rt && isJSON(this.props.config.rt)
          ? JSON.parse(this.props.config.rt)
          : [],
      reloading: false,
      data: null,
      previousData: null,
      errors: false,
      timerInterval: 30,
    }

    this.doRefresh = this.doRefresh.bind(this)
    this.handleChangeInterval = this.handleChangeInterval.bind(this)
  }

  public componentDidMount(): void {
    const selectedInterval = window.localStorage.getItem('widget-rtmeasures-interval')
    const timerInterval = selectedInterval
      ? parseInt(selectedInterval, 10)
      : this.state.config.interval
      ? this.state.config.interval
      : 30
    this.setState({ timerInterval })
    this.timer = setInterval(() => this.doRefresh(), timerInterval * 1000) // tslint:disable-line
    this.doRefresh()
  }

  public componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  public render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    const { metrics } = this.state.config
    const { data, previousData, reloading } = this.state

    if (this.state.config === [] || !data) {
      return null
    }

    return (
      <div
        className="card widget widget-box widget-box-rt_measures"
        style={{
          pointerEvents: this.state.reloading ? 'none' : 'auto',
        }}
      >
        <div className="card-header">
          <span>{this.props.t('widget.rtmeasures.title')}</span>
          <select
            className="select-interval custom-select custom-select-sm float-right"
            onChange={e => this.handleChangeInterval(e)}
            value={String(this.state.timerInterval)}
          >
            <option value="15">15s</option>
            <option value="30">30s</option>
            <option value="60">1m</option>
            <option value="300">5m</option>
            <option value="600">10m</option>
            <option value="900">15m</option>
          </select>
        </div>
        <div className="card-body">
          {data && (
            <ul className={this.state.reloading ? 'is-reloading' : undefined}>
              {data.map((m: { name: string; value: any }) => {
                const metric = Object.keys(metrics).find((me: string) => metrics[me].serie === m.name)
                const previousMetric =
                  (previousData && previousData.find((pd: { name: string; value: any }) => pd.name === m.name)) || null
                const trend =
                  previousMetric && (previousMetric.value > m.value ? -1 : previousMetric.value < m.value ? 1 : 0)

                return (
                  (metric && (
                    <li key={m.name}>
                      <span className="label">{metrics[metric].label} </span>
                      {metrics[metric].unit !== '' && (
                        <span className="unit">({metrics[metric].unit.trim()})</span>
                      )} : <span className={'value'}>{m.value}</span>{' '}
                      <span className={'trend trend-' + (trend > 0 ? 'up' : trend < 0 ? 'down' : 'equal')}>
                        {previousMetric && (trend > 0 ? '▲' : trend < 0 ? '▼' : undefined)}
                      </span>
                    </li>
                  )) ||
                  undefined
                )
              })}
            </ul>
          )}

          {reloading && <IconComponent className="spinner float-right" icon={'circle-notch'} spin={true} size="1x" />}
        </div>
      </div>
    )
  }

  private handleChangeInterval(e: ChangeEvent<HTMLSelectElement>) {
    window.localStorage.setItem('widget-rtmeasures-interval', e.currentTarget.value)
    if (this.timer) {
      clearInterval(this.timer)
    }
    const timerInterval = parseInt(e.currentTarget.value, 10)

    this.setState({ timerInterval })
    this.timer = setInterval(() => this.doRefresh(), timerInterval * 1000)
  }

  private async doRefresh() {
    if (this.state.config.metrics) {
      this.setState(
        {
          reloading: true,
          previousData: this.state.data,
        },
        () => {
          const query: any = []
          const { metrics } = this.state.config
          const data: Array<{ [k: string]: any }> = []

          Object.keys(this.state.config.metrics).map((m: string) => {
            query.push(APIInflux().request('/influx/query?q=' + `SELECT LAST("measure") FROM "${metrics[m].serie}"`))
          })

          Promise.all(query)
            .then((result: any) => {
              result.map((m: any) => {
                const singleData = parseInfluxData(m)
                data.push(singleData)
              })

              this.setState({ data, reloading: false })
            })
            .catch((error: any) => {
              this.setState({
                reloading: false,
                errors: true,
              })
              if (error.name === 'FetchError' && error.statusCode === 401) {
                logoutUser()
              }
            })
        }
      )
    }
  }
}

export default connect(mapStateToProps)(withTranslation()(RTMeasures))
