import { AnyAction, Dispatch } from 'redux'
import { User } from '../../types/index'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'

const API = () => FetchWrapper.getInstance('auth')

export const hasErrored = (error: Error): AnyAction => {
  return {
    type: 'AUTH_HAS_ERRORED',
    payload: error,
  }
}

export const isFetching = (): AnyAction => {
  return {
    type: 'AUTH_IS_FETCHING',
  }
}

export const success = (user: User): AnyAction => {
  return {
    type: 'AUTH_SUCCESS',
    payload: user,
  }
}

export const logoutUser = (): AnyAction => {
  localStorage.clear()
  return {
    type: 'AUTH_LOGOUT',
  }
}

export const loginUser = (username: string, password: string): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isFetching())
    try {
      await API().request('/auth/jwt', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      })
      const userParams = await API().request('/user')
      const user = new User(userParams)
      dispatch(success(user))
    } catch (error:any) {
      dispatch(hasErrored(error))
    }
  }
}

export const setMobileFlag = (): AnyAction => {
  return {
    type: 'AUTH_SET_MOBILE_FLAG',
  }
}

export const loginUserByJwt = (jwt: string): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      localStorage.setItem('jwt', jwt)
      const userParams = await API().request('/user')
      const user = new User(userParams)
      dispatch(success(user))
      dispatch(setMobileFlag())
    } catch (error) {
      throw error
    }
  }
}
