import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { setFileLanguage } from '../shared'
import { fetchFileData } from '../../../store/actions'
import { connect } from 'react-redux'
import MediaPlayerModal, { VideoData } from '../MediaPlayer/MediaPlayerModal'
import { MediaVideo } from '../../../types/media'
import { YOUTUBE_KEY } from '../DesignerComponent/DesignerComponent'
import { youtubeParser } from '../../../utils/youtube'
import IconComponent from '../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface OwnProps {
  media: any
  // t: TranslationFunction
  enableAdd: boolean
  update: (media: any[]) => void
}

export interface State {
  showPlayer: boolean
  media: any
  preview: {
    image: string | null
  }
  previewUrl: string | null
  previewImagesCache: { [k: string]: string }
}

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchFileData: (fileKey: string) => dispatch(fetchFileData(fileKey)),
})

export type Props = OwnProps & WithTranslation

class Attachments extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props)

    this.state = {
      showPlayer: false,
      media: this.props.media,
      preview: {
        image: '',
      },
      previewUrl: null,
      previewImagesCache: {},
    }

    this.addMedia = this.addMedia.bind(this)
    this.closeMediaPlayer = this.closeMediaPlayer.bind(this)
    this.removeVideo = this.removeVideo.bind(this)
    this.setMediaLanguageHandler = this.setMediaLanguageHandler.bind(this)
    this.showPlayer = this.showPlayer.bind(this)
    this.showPreviewListVideo = this.showPreviewListVideo.bind(this)
    this.hidePreviewListVideo = this.hidePreviewListVideo.bind(this)
  }

  public componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    this.setState({
      media: nextProps.media,
    })
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.showPlayer && (
          <MediaPlayerModal
            isVisible={this.state.showPlayer}
            closeModal={this.closeMediaPlayer}
            addMedia={this.addMedia}
          />
        )}

        <div className="form-media">
          {this.state.media && (
            <React.Fragment>
              <ul className="list-group list-group-video">
                {this.state.media.map((media: MediaVideo, index: number) => (
                  <li
                    className="list-group-item"
                    key={index}
                    onMouseEnter={() => this.showPreviewListVideo(media)}
                    onMouseLeave={this.hidePreviewListVideo}
                  >
                    <a href={media.link} target="_blank">
                      {media.label}
                    </a>
                    {this.state.preview && this.state.preview.image && this.state.previewUrl === media.link && (
                      <div id="preview-list-video">
                        <img src={this.state.preview.image} />
                      </div>
                    )}
                    <span className="media-list-action" onClick={() => this.removeVideo(media)}>
                      <IconComponent icon={'times'} />
                    </span>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
        </div>

        <div className="section-actions">
          <button
            className={'btn btn-secondary btn-sm float-right ml-3' + (!this.props.enableAdd ? ' hidden' : '')}
            onClick={this.showPlayer}
            disabled={!YOUTUBE_KEY}
            title={!YOUTUBE_KEY ? this.props.t('designer.mediaPreview.configureYouTubeKey') : undefined}
          >
            {this.props.t('designer.mediaPreview.modal.title')}
          </button>
        </div>
      </React.Fragment>
    )
  }

  private showPlayer(event: React.MouseEvent) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showPlayer: true,
    })
  }

  private closeMediaPlayer() {
    this.setState({
      showPlayer: false,
    })
  }

  private addMedia(mediaUrl: string, mediaTitle: string, mediaPreview: VideoData) {
    const media = [...this.state.media]

    media.push({
      mediaType: 'YouTubeLink',
      link: mediaUrl,
      label: mediaTitle,
      translations: {},
    })

    this.setState(
      {
        showPlayer: false,
        media,
      },
      () => this.props.update(this.state.media)
    )
  }

  private removeVideo(item: MediaVideo) {
    if (confirm(this.props.t('designer.confirm.removeMedia'))) {
      const media = JSON.parse(JSON.stringify(this.state.media))
      const index = media.findIndex((file: any) => {
        return file.link === item.link
      })

      if (index !== -1) {
        media.splice(index, 1)
      }

      this.setState(
        {
          media,
        },
        () => this.props.update(this.state.media)
      )
    }
  }

  private setMediaLanguageHandler(e: React.MouseEvent<HTMLSpanElement>, link: string, lang: string) {
    this.setState(
      {
        media: setFileLanguage(link, lang, this.state.media),
      },
      () => this.props.update(this.state.media)
    )
  }

  private showPreviewListVideo(media: MediaVideo) {
    if (YOUTUBE_KEY) {
      if (this.state.previewImagesCache[media.link]) {
        this.setState({
          preview: {
            image: this.state.previewImagesCache[media.link],
          },
          previewUrl: media.link,
        })
      } else {
        fetch(
          'https://www.googleapis.com/youtube/v3/videos?part=snippet&' +
            '' +
            'id=' +
            youtubeParser(media.link) +
            '&key=' +
            YOUTUBE_KEY
        )
          .then(response => response.json())
          .then((data: any) => {
            if (data && data.items && data.items.length > 0) {
              const video: any = data.items[0].snippet
              const previewImagesCache = { ...this.state.previewImagesCache }
              const image =
                video.thumbnails.standard ||
                video.thumbnails.high ||
                video.thumbnails.medium ||
                video.thumbnails.default
              previewImagesCache[media.link] = image.url

              this.setState({
                preview: {
                  image: image.url,
                },
                previewUrl: media.link,
                previewImagesCache,
              })
            }
          })
          .catch((error: any) => {
            console.log(error) // tslint:disable-line
          })
      }
    }
  }

  private hidePreviewListVideo() {
    this.setState({
      preview: {
        image: null,
      },
      previewUrl: null,
    })
  }
}

export default connect(null, mapDispatchToProps)(withTranslation()(Attachments))
