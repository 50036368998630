export { saveDataAsCSV } from './file'

export const isJSON = (str: any): boolean => {
  if (typeof str === 'object') {
    return str
  }

  try {
    return JSON.parse(str) && !!str
  } catch (e) {
    return false
  }
}
