import { RouteComponentProps, withRouter } from 'react-router-dom'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Loader, Modal } from '@mv-submodules/inplant-components-fe'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface MediaWidgetProps {
  showTitle: boolean
  loading: boolean
  ready: boolean
  data: any
}

interface OwnState {
  link: string | null
}

export type MediaWidgetOwnProps = MediaWidgetProps & RouteComponentProps & WithTranslation

const getYouTubeId = (url: null | string) => {
  if (!url) {
    return ''
  }
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)

  if (match && match[2].length === 11) {
    return match[2]
  } else {
    return ''
  }
}

class MediaWidget extends React.Component<MediaWidgetOwnProps, OwnState> {
  constructor(props: MediaWidgetOwnProps) {
    super(props)

    this.state = {
      link: null,
    }

    this.handleClick = this.handleClick.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  public render() {
    const {showTitle, loading, t, data, ready} = this.props

    return (
      <React.Fragment>
        {showTitle && <h4>{t('treeDetailsTitleMedia')}</h4>}
        {loading && <Loader />}
        {!loading && ready && (
          <div className="vertical-wrapper">
            <table className="table table-sm table-media">
              <tbody>
                {data && data.length > 0 ? (
                  data.map((media: any, i: number) => (
                    <tr key={i} onClick={(e: React.MouseEvent<HTMLTableRowElement>) => this.handleClick(e, media.link)}>
                      <td>
                        <Row>
                          <Column>
                            <a href={media.link}>{media.label}</a>
                          </Column>
                        </Row>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <span>{t('treeDetails.noMedia')}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {this.state.link && (
          <Modal
            visible={true}
            width={75}
            onClose={this.closeModal}
            title={t('moduleSmartdocs.media.modal.title')}
          >
            <Row>
              <Column>
                <div id={"video-preview"}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={'//www.youtube.com/embed/' + getYouTubeId(this.state.link)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                  />
                </div>
              </Column>
            </Row>
          </Modal>
        )}
      </React.Fragment>
    )
  }

  private handleClick(e: React.MouseEvent<HTMLTableRowElement>, link: string) {
    e.preventDefault()
    this.setState({
      link,
    })
  }

  private closeModal() {
    this.setState({
      link: null,
    })
  }
}

export default withRouter<any, any>(withTranslation()(MediaWidget))
