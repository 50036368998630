const it = {
  moduleSmartdocs: {
    module: 'Smartdoc',
    fetching: 'Caricamento',
    maintenances: 'Manutenzioni',
    maintenance: {
      setup: {
        periodicity: {
          '1_DAY': '1 giorno',
          '1_WEEK': '1 settimana',
          '2_WEEKS': '2 settimane',
          '1_MONTH': '1 mese',
          '2_MONTHS': '2 mesi',
          '3_MONTHS': '3 mesi',
          '6_MONTHS': '6 mesi',
          '1_YEAR': '1 anni',
          '2_YEARS': '2 anni',
        },
      },
      addJob: {
        target: 'Target',
        operation: 'Operazione',
        type: 'Tipo',
        periodicity: 'Periodicità',
        hours: 'Ore',
        notes: 'Note',
      },
      status: {
        done: 'FATTO',
        overdue: 'SCADUTO',
        planned: 'DA FARE',
        unhandled: 'DA CONFERMARE',
        postponed: 'DA FARE',
        rejected: 'RIFIUTATO',
      },
      jobType: {
        custom: 'Puntuale',
        maintenance: 'Adempimento',
        anomaly: 'Anomalia checklist',
      },
    },
    media: {
      types: {
        Manual: 'Manuale',
        Manuals: 'Manuali',
        Accessory: 'Accessorio',
        Accessories: 'Accessori',
        TechnicalFile: 'File Tecnico',
        TechnicalFiles: 'File Tecnici',
        SparePart: 'Ricambio',
        SpareParts: 'Ricambi',
        Certification: 'Certificazione',
        Certifications: 'Certificazioni',
        Maintenance: 'Manutenzione',
        Maintenances: 'Manutenzioni',
        Media: 'Media',
      },
      modal: {
        title: 'Anteprima media',
        cancel: 'Chiudi',
      },
    },
  },
  treeDetailsFilename: 'Nome',
  treeDetailsVersion: 'Versione',
  treeDetailsDate: 'Data',
  treeDetailsTitle: '',
  treeDetailsTitlenull: '',
  treeDetailsTitlemanuals: 'Manuali',
  treeDetailsTitlecertificates: 'Certificati',
  treeDetailsTitledataSheets: 'Schede tecniche',
  treeDetailsTitlespareParts: 'Ricambi',
  treeDetailsTitleaccessories: 'Accessori',
  treeDetailsTitleGlobal: 'Documenti Impianto',
  treeDetailsTitleMedia: 'Media',
  treeDetailsCode: 'Codice',
  treeDetailscode: 'Codice',
  treeDetailsDescription: 'Descrizione',
  treeDetailsPrice: 'Prezzo',
  treeDetailsprice: 'Prezzo',
  treeDetailsTitlesparePartsList: 'Esploso ricambi',
  treeDetailsTitlemaintenances: 'Prossime Manutenzioni',
  treeDetailsoperation: 'Operazione',
  treeDetailsdescription: 'Descrizione',
  treeDetailsexpected: 'Prevista',
  treeDetailsdocumentType: 'Tipologia',
  treeDetailsdocumentVersion: 'Versione',
  treeDetailslink: 'Link',
  treeDetailslastEdit: 'Data',
  treeDetails: {
    periodicMaintenance: 'Manutenzioni Periodiche',
    noMaintenances: 'Non ci sono manutenzioni',
    noMedia: 'Non ci sono media',
  },
}

export default it
