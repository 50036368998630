import * as React from 'react'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface Props {
  notes: string
}

export default class NotesTooltip extends React.Component<Props> {
  public render() {
    const { notes } = this.props

    if (notes && notes.length > 0) {
      return (
        <span className="text-muted" data-balloon={notes} data-balloon-pos="left">
          <IconComponent icon={'comment'} />
        </span>
      )
    }

    return null
  }
}
