import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ContainerComponent } from '../ContainerComponent/ContainerComponent'
import { fetchTargetInfo } from '../../../../redux/actions'
import { MaintenanceJobType, MaintenanceStatus } from '../../../../types'
import { capitalizeFirstLetter } from '../../../../functions/shared'
import { Loader } from '@mv-submodules/inplant-components-fe'
import TargetScheduledJobs from './TargetScheduledJobs'
import TargetJobs from './TargetJobs'
import { MaintenanceClientFiltersParams } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface TargetItem {
  id: string
  level: string
  label: string
}

interface StateProps {
  smartdocsExists: boolean
}

interface OwnState {
  loading: boolean
  error: Error | null
  targetData: TargetItem[] | null
}

interface OwnProps extends RouteComponentProps<any> {
  // t: TranslationFunction
}

interface DispatchProps {
  fetchTargetInfo: (targetId: string) => Promise<any>
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchTargetInfo: (targetId: string) => dispatch(fetchTargetInfo(targetId)),
})

const mapStateToProps = (state: any) => ({
  smartdocsExists: state.hasOwnProperty('smartdocs'),
})

type Props = DispatchProps & OwnProps & StateProps & WithTranslation

class TargetComponet extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      error: null,
      targetData: null,
    }
    this.getTargetParam = this.getTargetParam.bind(this)
    this.getFomattedTarget = this.getFomattedTarget.bind(this)
  }

  public componentDidMount() {
    if (this.props.smartdocsExists) {
      this.props
        .fetchTargetInfo(this.getTargetParam())
        .then(targetData => {
          this.setState({ loading: false, error: null, targetData })
        })
        .catch(error => {
          this.setState({ loading: false, error })
        })
    }
  }

  public render() {
    if (this.state.loading) {
      return (
        <div className="mt-5">
          <Loader />
        </div>
      )
    }

    if (this.state.error) {
      return (
        <ContainerComponent>
          <div className="row justify-content-center">
            <div className="col-10 col-md-8 col-lg-7 col-xl-6">
              <div className="alert alert-warning alert-internal-error my-5" role="alert">
                <h2>{this.props.t('maintenance.errors.somethingGoneWrong')}</h2>
                <p>{this.props.t('maintenance.errors.targetNotFound')}</p>
              </div>
            </div>
          </div>
        </ContainerComponent>
      )
    }

    const periodicParams = {
      targetId: this.getTargetParam(),
    } as MaintenanceClientFiltersParams

    const variableParams = {
      targetId: this.getTargetParam(),
      statuses: [
        MaintenanceStatus.OVERDUE,
        MaintenanceStatus.POSTPONED,
        MaintenanceStatus.PLANNED,
        MaintenanceStatus.UNHANDLED,
      ],
      jobTypes: [MaintenanceJobType.CUSTOM, MaintenanceJobType.ANOMALY],
    } as MaintenanceClientFiltersParams

    const archivedParams = {
      targetId: this.getTargetParam(),
      statuses: [MaintenanceStatus.DONE, MaintenanceStatus.REJECTED],
    } as MaintenanceClientFiltersParams

    return (
      <ContainerComponent>
        <header>
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <div className="d-flex flex-row justify-content-start align-items-center">
                <h1>
                  {this.props.t('maintenance.target.title')}
                  <div className="h6 mt-2">
                    {this.props.smartdocsExists ? this.getFomattedTarget() : this.oldGetFomattedTarget()}
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </header>

        <div className="content">
          <TargetJobs
            title={this.props.t('maintenance.target.periodicTitle')}
            params={periodicParams}
            highlightNext={true}
          />

          <TargetScheduledJobs
            title={this.props.t('maintenance.target.variableTitle')}
            params={variableParams}
            hiddenCols={['operator', 'jobType']}
            highlightDate={true}
            highlightConfirmed={true}
          />

          <TargetScheduledJobs
            title={this.props.t('maintenance.target.archivedTitle')}
            params={archivedParams}
            hiddenCols={['confirmed']}
          />
        </div>
      </ContainerComponent>
    )
  }

  private getTargetParam(): string {
    return this.props.match.params[0]
  }

  private oldGetFomattedTarget(): string {
    const { targetData } = this.state
    const formatterdTarget: string[] = []
    if (targetData) {
      targetData.splice(0, 1)
      targetData.forEach((item: TargetItem) => {
        formatterdTarget.push(capitalizeFirstLetter(item.label))
      })
    }
    return formatterdTarget.join(' > ')
  }

  private getFomattedTarget() {
    const { targetData } = this.state
    const formatterdTarget: string[] = []
    let lastItemLink: string = '#'
    let lastItem: TargetItem[] | null = null
    if (targetData) {
      targetData.splice(0, 1)
      lastItem = targetData.splice(-1, 1)
      targetData.forEach((item: TargetItem) => {
        formatterdTarget.push(capitalizeFirstLetter(item.label))
      })
      if (this.props.smartdocsExists) {
        lastItemLink = '/smartdocs?id=' + lastItem[0].id
      }
    }

    return (
      <React.Fragment>
        {formatterdTarget.join(' > ')}{' '}
        {lastItem ? (
          <React.Fragment>
            {'>'} {capitalizeFirstLetter(lastItem[0].label)}{' '}
            {lastItemLink !== '#' ? (
              <a
                className="text-primary data-tooltips"
                data-title={this.props.t('maintenance.target.smartdocsLink')}
                href={lastItemLink}
              >
                {' '}
                <sup>
                  <IconComponent icon={'external-link-alt'} />
                </sup>
              </a>
            ) : null}
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(TargetComponet))
)
