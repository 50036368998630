import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {faBook} from '@fortawesome/free-solid-svg-icons'
import SmartdocsComponent from './components/views/SmartdocsComponent/SmartdocsComponentPageView'

export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey?: string
  icon?: IconDefinition
  iconOverride?: string
  visible: boolean
  visibleInUserProfile?: boolean
  component?: any
  children?: RouteProps[]
}

const routes: RouteProps = {
  path: '/smartdocs',
  i18nkey: 'moduleSmartdocs.module',
  icon: faBook,
  visible: true,
  children: [
    {
      path: '/smartdocs',
      exact: true,
      i18nkey: 'moduleSmartdocs.module',
      component: SmartdocsComponent,
      visible: false,
    },
    {
      path: '/smartdocs/:id',
      i18nkey: 'moduleSmartdocs.module',
      component: SmartdocsComponent,
      visible: false,
    },
  ],
}

export default routes
