import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { MaintenanceScheduledJob } from '../../../../types'
import { getDateFormat, presentStatusBadge } from '../../../../functions/shared'
import { Alert, Loader } from '@mv-submodules/inplant-components-fe'
import * as moment from 'moment'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { fetchScheduledJobs } from '@mv-submodules/inplant-maintenance-fe/redux/actions/scheduledJob'
import { MaintenanceClientFiltersParams } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface OwnState {
  isFetching: boolean
  fetchErrors: boolean
  rows: MaintenanceScheduledJob[]
  pages: number
  page: number
}

interface OwnProps {
  title: string
  params: MaintenanceClientFiltersParams
  perPage?: number
  hiddenCols?: string[]
  highlightDate?: boolean
  highlightConfirmed?: boolean
  // t: TranslationFunction
}

interface DispatchProps {
  fetchScheduledJobs: (
    pageSlug?: string | null,
    clientFilters?: MaintenanceClientFiltersParams | null
  ) => Promise<MaintenanceScheduledJob[]>
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchScheduledJobs: (pageSlug?: string | null, clientFilters?: MaintenanceClientFiltersParams | null) =>
    dispatch(fetchScheduledJobs(pageSlug, clientFilters)),
})

type Props = DispatchProps & OwnProps & WithTranslation

interface DefaultProps {
  perPage: number
  hiddenCols: string[]
  highlightDate: boolean
  highlightConfirmed: boolean
}

type PropsWithDefaults = Props & DefaultProps

class TargetScheduledJobs extends React.Component<Props, OwnState> {
  public static defaultProps: DefaultProps = {
    perPage: 5,
    hiddenCols: [],
    highlightDate: false,
    highlightConfirmed: false,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      isFetching: false,
      fetchErrors: false,
      rows: [],
      pages: 0,
      page: 0,
    }
    this.haveNextPage = this.haveNextPage.bind(this)
    this.havePrevPage = this.havePrevPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.getPageRows = this.getPageRows.bind(this)
  }

  public componentDidMount() {
    const { params, perPage } = this.props as PropsWithDefaults
    this.setState({ isFetching: true })
    this.props
      .fetchScheduledJobs(null, params)
      .then((items: MaintenanceScheduledJob[]) => {
        const pages = Math.ceil(items.length / perPage)
        this.setState({
          rows: items,
          pages,
          page: items.length ? 1 : 0,
        })
      })
      .catch((error: any) => {
        this.setState({ fetchErrors: true })
      })
      .finally(() => {
        this.setState({
          isFetching: false,
        })
      })
  }

  private haveNextPage() {
    const { page, pages } = this.state
    return page + 1 <= pages
  }

  private havePrevPage() {
    const { page } = this.state
    return page - 1 > 0
  }

  private nextPage() {
    const { page } = this.state
    if (this.haveNextPage()) {
      this.setState({ page: page + 1 })
    }
  }

  private prevPage() {
    const { page } = this.state
    if (this.havePrevPage()) {
      this.setState({ page: page - 1 })
    }
  }

  private getPageRows(): MaintenanceScheduledJob[] {
    const { page, rows } = this.state
    const { perPage } = this.props as PropsWithDefaults
    return rows.slice((page - 1) * perPage, page * perPage)
  }

  public render() {
    const { title, t, hiddenCols, highlightDate, highlightConfirmed } = this.props as PropsWithDefaults
    const { page, pages } = this.state
    const today = moment()

    return (
      <div className="card mb-5 target-scheduled-jobs">
        <div className="card-header">
          {title}
          <span className="card-header--pagination">
            {t('maintenance.target.pagination.page')} {page} {t('maintenance.target.pagination.of')} {pages}
            <span
              className={`pagination-arrow pagination-arrow--left ${
                !this.havePrevPage() ? 'pagination-arrow--disable' : ''
              }`}
              onClick={this.prevPage}
            />
            <span
              className={`pagination-arrow pagination-arrow--right ${
                !this.haveNextPage() ? 'pagination-arrow--disable' : ''
              }`}
              onClick={this.nextPage}
            />
          </span>
        </div>
        <div className="card-body">
          <table className="table table-sm">
            <thead>
              <tr>
                {!hiddenCols.includes('operation') && (
                  <th className="th-operation">{t('maintenance.target.scheduledJobsCols.operation')}</th>
                )}
                {!hiddenCols.includes('type') && (
                  <th className="th-type">{t('maintenance.target.scheduledJobsCols.type')}</th>
                )}
                {!hiddenCols.includes('operator') && (
                  <th className="th-operator">{t('maintenance.target.scheduledJobsCols.operator')}</th>
                )}
                {!hiddenCols.includes('jobType') && (
                  <th className="th-jobtype">{t('maintenance.target.scheduledJobsCols.jobType')}</th>
                )}
                {!hiddenCols.includes('status') && (
                  <th className="th-status">{t('maintenance.target.scheduledJobsCols.status')}</th>
                )}
                {!hiddenCols.includes('confirmed') && (
                  <th className="th-confirmed">{t('maintenance.target.scheduledJobsCols.confirmed')}</th>
                )}
                {!hiddenCols.includes('date') && (
                  <th className="th-date">{t('maintenance.target.scheduledJobsCols.date')}</th>
                )}
                {!hiddenCols.includes('notes') && (
                  <th className="th-notes">{t('maintenance.target.scheduledJobsCols.notes')}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {this.getPageRows().map((row: MaintenanceScheduledJob, index: number) => (
                <tr key={index}>
                  {!hiddenCols.includes('operation') && <td>{row.operation}</td>}
                  {!hiddenCols.includes('type') && <td>{row.type.name}</td>}
                  {!hiddenCols.includes('operator') && <td>{row.userFullName}</td>}
                  {!hiddenCols.includes('jobType') && (
                    <td>
                      {row.maintenance && row.maintenance.periodicity ? (
                        <IconComponent icon={'sync-alt'}  />
                        // <IconComponent icon={'sync-alt'} rotation={90} />
                      ) : (
                        t('maintenance.jobType.custom')
                      )}
                    </td>
                  )}
                  {!hiddenCols.includes('status') && <td>{presentStatusBadge(row)}</td>}
                  {!hiddenCols.includes('confirmed') && (
                    <td className={`${highlightConfirmed && !row.acknowledged ? 'td-highlighted' : ''}`}>
                      {t(`maintenance.target.confirmed.${row.acknowledged ? 'yes' : 'no'}`)}
                    </td>
                  )}
                  {!hiddenCols.includes('date') && (
                    <td className={`${highlightDate && today.isAfter(row.dueDate, 'day') ? 'td-highlighted' : ''}`}>
                      {row.dueDate.format(getDateFormat())}
                    </td>
                  )}
                  {!hiddenCols.includes('notes') && (
                    <td>
                      {row.closingNotes && row.closingNotes.length > 0 && (
                        <Tooltip trigger={'mouseenter click'} position="bottom" title={row.closingNotes} arrow={true}>
                          <span className="text-muted" data-balloon-pos="left">
                            <IconComponent icon={'comment'} />
                          </span>
                        </Tooltip>
                      )}
                    </td>
                  )}
                </tr>
              ))}
              {!this.state.isFetching && !this.state.fetchErrors && this.state.rows.length === 0 && (
                <tr>
                  <td colSpan={100} className="noData">
                    {t('maintenance.target.noData')}
                  </td>
                </tr>
              )}
              {!this.state.isFetching && this.state.fetchErrors && (
                <tr>
                  <td colSpan={100} className="noData">
                    <Alert type="danger" title={this.props.t('maintenance.errors.somethingGoneWrong')} />
                  </td>
                </tr>
              )}
              {this.state.isFetching && (
                <tr>
                  <td colSpan={100}>
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(withTranslation()(TargetScheduledJobs))
