import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import FetchError from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper/FetchError'

export const maintenancePlantStatusLoaded = (plantStatus: any): AnyAction => {
  return {
    type: 'MAINTENANCE_PLANTSTATUS_LOADED',
    payload: {
      plantStatus,
    },
  }
}

const filterPlant = (node: any): any => {
  if (node.data.label) {
    node.data.name = node.data.label
    delete node.data.label
  }

  node.data.path = [node.data.name]

  if (node.data.parent) {
    node.data.id = node.data.parent + '/' + node.data.id
  }

  if (node.data.parentPath) {
    node.data.path = node.data.parentPath.concat(node.data.name)
  }

  if (node.subcomponents) {
    node.children = node.subcomponents
    delete node.subcomponents
    const checkChildrens = node.children.filter((c: any) => {
      return c.level !== 'Metric'
    })

    if (checkChildrens.length < 1) {
      delete node.children
    }
  }

  for (const i in node.children) {
    if (node.children.hasOwnProperty(i)) {
      if (node.children[i].level === 'Metric') {
        delete node.children[i]
      } else {
        node.children[i].data.parent = node.data.id
        node.children[i].data.parentPath = node.data.path
        filterPlant(node.children[i])
      }
    }
  }
}

export const fetchPlantStatus  = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    return API().request('/maintenances/model')
      .then((plant: any) => {
        let plantRoot: any = []
        if (plant.content) {
          plantRoot = plant.content
          filterPlant(plantRoot)
        }
        return plantRoot
      })
      .catch((error: FetchError) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}

export const fetchTargetInfo = (targetId: string): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    return API().request(`/model/path/${targetId}`, undefined, undefined, true)
      .then((subtree: any) => subtree)
      .catch((error: FetchError) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}
