import { AnyAction } from 'redux'
import { DueDatesConfig } from '../../types/pageConfig'

interface PageConfigState {
  pageSlug: string | null
  isFetching: boolean
  data?: DueDatesConfig
  error?: Error
}

const initialState: PageConfigState = {
  pageSlug: null,
  isFetching: false,
}

export function pageConfig(state: PageConfigState = initialState, action: AnyAction): PageConfigState {
  switch (action.type) {
    case 'PAGE_CONFIGURATION_IS_FETCHING': {
      return {
        ...state,
        pageSlug: action.pageSlug,
        isFetching: true,
        data: undefined,
        error: undefined,
      }
    }
    case 'PAGE_CONFIGURATION_SUCCESS': {
      return {
        ...state,
        isFetching: false,
        data: action.data,
      }
    }
    case 'PAGE_CONFIGURATION_FAILURE': {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    }
    default:
      return state
  }
}
