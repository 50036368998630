import * as React from 'react'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {withRouter} from 'react-router'
import {fetchPlanRunData} from '../../../../redux/actions/graphs'
import {Bar, ComposedChart, Label, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import * as moment from 'moment'
import DownloadDataButton
  from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import {timeRangeFileString, TPRNCAlarmsData} from '../../../../functions/shared'
import {DateRange, getDatesFromRange} from '@mv-submodules/inplant-core-fe/functions/date'
import TPRNCWarnAreaLoader
  from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/ChartUtils/TPRNCWarnAreaLoader'
import {AlertNoData} from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/SharedComponents/sharedComponents';
import {Card, CardBody, CardFooter, CardHeader} from '@mv-submodules/inplant-components-fe';
import {saveDataAsCSV} from "@mv-submodules/inplant-core-fe/functions";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface GraphPlanRunStateProps {
  data: any
  dateEnd: string | null
  dateStart: string | null
  isLoading: boolean
  lastDateUpdate: number | null
  prevRange: string | null
  range: string,
  measureUnits: object
  cliccable?: boolean
  TPRNCData: any
  TPRNCDataErrors: boolean
  TPRNCDataIsLoading: boolean
}

interface GraphPlanRunDispatchProps {
  fetchPlanRunData: () => Function
}

interface GraphPlanRunOwnProps {
  // t: TranslationFunction
  history: any
}

interface GraphPlanRunOwnState {
  lastUpdate: number | null
}

type GraphPlanRunProps = GraphPlanRunStateProps & GraphPlanRunDispatchProps & GraphPlanRunOwnProps & WithTranslation

const mapStateToProps = (state: any): GraphPlanRunStateProps => ({
  data: state.graphPlanRunData.data,
  dateEnd: state.dateFilters.dateEnd,
  dateStart: state.dateFilters.dateStart,
  isLoading: state.graphPlanRunData.isLoading,
  lastDateUpdate: state.dateFilters.lastUpdate,
  prevRange: state.dateFilters.prevRange,
  range: state.dateFilters.range,
  measureUnits: state.config.generic.measureUnits || {},
  TPRNCData: state.graphTPRNCData.data,
  TPRNCDataErrors: state.graphTPRNCData.hasErrored,
  TPRNCDataIsLoading: state.graphTPRNCData.isLoading,
})

const mapDispatchToProps = (dispatch: Function): GraphPlanRunDispatchProps => {
  return {
    fetchPlanRunData: () => dispatch(fetchPlanRunData()),
  }
}

const CustomTooltip = ({active, payload, label}: any) => {
  if (active) {
    return (
      <div className="recharts-default-tooltip">
        <ul>
          <li className="title">
            <strong>{moment(label).format('DD/MM')}</strong>
          </li>
          {payload &&
          payload.map((line: any, index: number) => (
            <li key={index}>
              <strong>{line.name === 'runTimeOperatingTime' ? 'Run/Operating Time %' : line.name}</strong>
              <span className="value float-right">
                  {typeof line.value === 'number' ? line.value.toFixed(2) : line.value}
                </span>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return null
}

class GraphPlanRun extends React.Component<GraphPlanRunProps, GraphPlanRunOwnState> {
  constructor(props: GraphPlanRunProps) {
    super(props)
    this.state = {
      lastUpdate: null,
    }
  }

  public componentWillMount() {
    this.props.fetchPlanRunData()
  }

  public componentWillReceiveProps(newProps: GraphPlanRunStateProps) {
    if (newProps.lastDateUpdate !== null && this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
      this.props.fetchPlanRunData()
    }
  }

  public render() {
    const {dateStart, dateEnd, TPRNCData} = this.props

    if (this.props.isLoading) {
      return (
        <Column key={Date.now()}>
          <label className="text-center">
            <i className="fas fa-2x fa-spin fa-circle-notch"/>
          </label>
        </Column>
      )
    }

    const data =
      this.props.data &&
      this.props.data.map((e: any, i: number) => {
        return {
          time: e[0],
          planned: e[1] / 3600,
          run: e[2] / 3600,
          operating: e[3] / 3600,
          runTimeOperatingTime: e[2] && e[3] ? (e[2] / e[3]) * 100 : '',
        }
      })

    const timeFormat =
      this.props.range &&
      ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(this.props.range)
        ? 'MM/YYYY'
        : 'DD/MM'

    const dataLabels = data && data.length > 0 ? Object.keys(data[0]).filter(e => e !== 'time') : []
    let downloadData: string[][] = data
      ? data.map((e: any) => {
        return [
          moment(e.time).format(timeFormat),
          e[dataLabels[0]] * 3600,
          e[dataLabels[1]] * 3600,
          e[dataLabels[2]] * 3600,
          e[dataLabels[3]],
        ]
      })
      : []

    downloadData = [
      ['time', ...dataLabels].map((c: string) => `${c}${this.props.measureUnits[c] ? ` (${this.props.measureUnits[c]})` : ''}`),
    ].concat(downloadData)

    const dates: DateRange = getDatesFromRange(this.props.range)
    const tprncData = TPRNCAlarmsData(dateStart, dateEnd, data, TPRNCData)

    return (
      <div className={"DashboardWidget WidgetPlanRun WidgetH50 " + (this.props.cliccable && 'cliccable')}>
        <Card marginBottom={3}>
          <CardHeader>
            {this.props.t('i4efficiency.cardTitle.runTimeOperatingTime')}
            {downloadData && (
              <DownloadDataButton
                saveDataAsCSV={saveDataAsCSV}
                data={downloadData}
                type={'csv'}
                label={'i4efficiency.download.buttonLabel'}
                className={'float-right'}
                fileName={'run_operating_time' + timeRangeFileString(dates.dateStart, dates.dateEnd)}
                addTime={false}
              />
            )}
          </CardHeader>
          <CardBody>
            <div className="container-fluid chart-container">
              <div className="full-h">
                <Row verticalAlignment={"center"}>
                  <Column sm={12}>
                    <div className="text-center full-h">
                      <div className="chart-container chartsPlanRunHome">
                        <TPRNCWarnAreaLoader/>
                        {data ? (
                          <ResponsiveContainer key={this.props.TPRNCData}>
                            <ComposedChart
                              width={700}
                              height={450}
                              data={data}
                              margin={{
                                top: 45,
                                right: 30,
                                left: 0,
                                bottom: 0,
                              }}
                              barGap={1}
                              className={this.props.cliccable ? "cliccable" : ""}
                            >
                              <XAxis
                                dataKey="time"
                                tickFormatter={e => {
                                  return moment(e).format(timeFormat)
                                }}
                                axisLine={false}
                              />
                              <YAxis axisLine={false} yAxisId={'left'}>
                                <Label position={'top'} offset={30}>
                                  time (h)
                                </Label>
                              </YAxis>
                              <YAxis
                                axisLine={false}
                                yAxisId={'right'}
                                orientation={'right'}
                                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                width={20}
                                interval={0}
                              >
                                <Label position={'top'} offset={30}>
                                  %
                                </Label>
                              </YAxis>
                              <Tooltip content={<CustomTooltip/>} cursor={{fill: 'none'}}/>

                              {
                                tprncData && tprncData.map(d => <ReferenceArea
                                  key={d.key}
                                  x1={d.x1}
                                  x2={d.x2}
                                  yAxisId={'right'}
                                  stroke={d.stroke}
                                  strokeOpacity={d.strokeOpacity}
                                  fill={d.fill}
                                  fillOpacity={d.fillOpacity}
                                />)
                              }

                              <Bar
                                dataKey="planned"
                                fill="#98abc5"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                yAxisId={'left'}
                              />
                              <Bar
                                dataKey="operating"
                                fill="#7b6888"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                yAxisId={'left'}
                              />

                              <Bar
                                dataKey="run"
                                fill="#8a89a6"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                yAxisId={'left'}
                              />
                              <Line
                                dataKey={'runTimeOperatingTime'}
                                stroke="#d0743c"
                                yAxisId={'right'}
                                strokeWidth={2}
                                isAnimationActive={false}
                                dot={false}
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        ) : (
                          <AlertNoData message={this.props.t('i4efficiency.fetch.noData')}/>
                        )}
                      </div>
                    </div>
                  </Column>
                </Row>
              </div>
            </div>
          </CardBody>
          <CardFooter
            textSizeSmall={true}
            textAlign={'right'}
            textMuted={true}
          >
            <ul className="legend float-left">
              <li>
                <span className="legend-color legend-planned-color legend-color-block"/> PLANNED
              </li>
              <li>
                <span className="legend-color legend-operating-color"/> OPERATING
              </li>
              <li>
                <span className="legend-color legend-run-color legend-color-block"/> RUN
              </li>
            </ul>
            <ul className="legend float-right">
              <li>
                <span className="legend-color legend-oee-color"/> RUN TIME / OPERATING TIME
              </li>
            </ul>
          </CardFooter>
        </Card>
      </div>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(GraphPlanRun))
)
