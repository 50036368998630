import {
  // AnyAction,
  combineReducers,
  ReducersMapObject,
} from 'redux'

import { service as serviceReducer } from './service'

const checklistReducersMap: ReducersMapObject = {
  service: serviceReducer,
}

export default combineReducers(checklistReducersMap)
