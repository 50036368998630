// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

// * -------------------------------- MODULE --------------------------------------
import '../../../styles/_index.scss'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

interface Props {
  dateFormat?: string
  dateValueFormat?: string
  id?: string,
  label?: string
  onApply?: ReactBootstrapDaterangepicker.EventHandler
  onSelectedChanged?: ( selected: string[] ) => void
  opens?: 'left' | 'right' | 'center'
  selected: string[]
  timePicker24Hour?: boolean
  timePicker?: boolean
}

class DateRangeInput extends React.Component<Props> {
  private dateFormat: string = this.props.dateFormat || 'DD/MM/YYYY'

  constructor( props: Props ) {
    super(props)
    this.onDateRangePickerEvent = this.onDateRangePickerEvent.bind(this)
    this.formatSelected = this.formatSelected.bind(this)
    this.handleOnSelectedChanged = this.handleOnSelectedChanged.bind(this)
    this.getInitialStartDate = this.getInitialStartDate.bind(this)
    this.getInitialEndDate = this.getInitialEndDate.bind(this)
  }

  public onDateRangePickerEvent( event: any, datePicker: any ) {
    if (event.type === 'apply') {
      this.handleOnSelectedChanged([datePicker.startDate.format(this.props.dateValueFormat || 'YYYY-MM-DD'), datePicker.endDate.format(this.props.dateValueFormat || 'YYYY-MM-DD')])
    }

    if (event.type === 'cancel') {
      datePicker.startDate = moment()
      datePicker.endDate = moment()
      this.handleOnSelectedChanged([])
    }
  }

  public render() {
    const { props } = this

    return (
      <DateRangePicker
        endDate={this.getInitialEndDate()}
        locale={{ format: this.dateFormat }}
        onApply={props.onApply}
        onEvent={this.onDateRangePickerEvent}
        opens={props.opens || 'right'}
        startDate={this.getInitialStartDate()}
        timePicker24Hour={props.timePicker24Hour}
        timePicker={props.timePicker}
      >
        {props.label && (<label htmlFor={props.id}>{props.label}</label>)}
        <div className="input-group">
          <div className="input-group-prepend">
            <IconComponent
              icon={"calendar"}
            />
          </div>
          <input type="text"
                 readOnly={true}
                 className="form-control daterangepicker-input"
                 value={this.formatSelected(props.selected)}/>
        </div>
      </DateRangePicker>
    )
  }

  private getInitialStartDate() {
    if (this.props.selected.length === 2) {
      return moment(this.props.selected[0])
    }
  }

  private getInitialEndDate() {
    if (this.props.selected.length === 2) {
      return moment(this.props.selected[1])
    }
  }

  private handleOnSelectedChanged( selected: string[] ) {
    const { onSelectedChanged } = this.props
    if (onSelectedChanged) {
      onSelectedChanged(selected)
    }
  }

  private formatSelected( selected: string[] ) {
    if (selected.length === 2) {
      const start = moment(selected[0]).format(this.dateFormat)
      const end = moment(selected[1]).format(this.dateFormat)
      return `${start} - ${end}`
    } else {
      return '-- --'
    }
  }
}

export default DateRangeInput
