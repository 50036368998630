import { combineReducers, ReducersMapObject } from 'redux'
import { jobs } from './jobs'
import { scheduledJobDetail } from './scheduledJobDetail'
import { scheduledJobs } from './scheduledJobs'
import { pageFilters } from './pageFilters'
import { pageConfig } from './pageConfig'
import { pageList } from './pageList'
import { pageDetail } from './pageDetail'
import { deadlineActions } from './deadlineActions'

const maintenanceReducersMap: ReducersMapObject = {
  jobs,
  scheduledJobDetail,
  scheduledJobs,
  pageFilters,
  pageConfig,
  pageList,
  pageDetail,
  deadlineActions,
}

export default combineReducers(maintenanceReducersMap)
