export const Generic = `DATA_BLOCK "\${areaNumber}.I4P.\${areaName}"
TITLE = i4P Process Data Store
{ S7_Optimized_Access := 'FALSE' }
AUTHOR : IMELSPA
FAMILY : i4P
NAME : i4P5002
VERSION : 0.1
//Data area process measures
  STRUCT
\${areaMetrics}
\${areaComponents}
  END_STRUCT;

BEGIN
  Restarti4PService := TRUE;
\${electricItemsTypes}
END_DATA_BLOCK
`
