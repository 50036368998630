// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import {ButtonVariants, renderButtonClass} from "./types";
import {Margin, renderMargin} from "../Utils/utils";
import WithLoader from "../Utils/withLoader";

interface Props {
  children: React.ReactNode
  actions: DropdownActions
  variant?: ButtonVariants,
  disabled?: boolean
  processing?: boolean
  alignRight?: boolean
  spacing?: Margin
  disableLight?: boolean
}

export interface DropdownAction {
  label: string
  onClick: () => void,
  disabled?: boolean
}

export type DropdownDivider = '-'

export type DropdownActions = Array<DropdownAction | DropdownDivider>

class DropdownButton extends React.Component<Props> {
  public render() {
    const {processing, alignRight = false, variant,spacing, ...props} = this.props
    const className = `${ variant && renderButtonClass(variant) || renderButtonClass("secondary") } dropdown-toggle ${renderMargin(spacing)} ${props.disableLight && 'disable-light' || ''}`
    let menuClassName = 'dropdown-menu'
    if (alignRight) {
      menuClassName += ' dropdown-menu-right'
    }
    return (
      <div className="btn-group" role="group">
        <button
          type="button"
          className={className}
          disabled={props.disabled}
          data-toggle="dropdown"
        >
          <WithLoader isLoading={processing}>{this.props.children}</WithLoader>
        </button>
        <div className={menuClassName}>
          {props.actions.map(
            (action, index) =>
              action === '-' ? (
                <div key={index} className="dropdown-divider"/>
              ) : (
                <button key={index} className="dropdown-item" {...action}>
                  {action.label}
                </button>
              )
          )}
        </div>
      </div>
    )
  }
}

export default DropdownButton
