import { Dispatch } from 'redux'
import { AnyAction } from 'redux'

import { AlarmListItem } from '../../types/AlarmListItem'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'

export const API = () => FetchWrapper.getInstance('production')

export const efficiencyAlarmsHasErrored = (bool: boolean): AnyAction => {
  return {
    type: 'EFFICIENCY_ALARMS_HAS_ERRORED',
    hasErrored: bool,
  }
}

// ----------------------------------------------------

export const efficiencyAlarmsIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'EFFICIENCY_ALARMS_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------

export const efficiencyAlarmsItemsDataSuccess = (items: AlarmListItem[]): AnyAction => {
  return {
    type: 'EFFICIENCY_ALARMS_ITEMS_FETCH_DATA_SUCCESS',
    items,
  }
}

// ----------------------------------------------------

export const efficiencyAlarmsFetchData = (date: string): Function => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(efficiencyAlarmsIsLoading(true))

    const itemsPromise = API().request('/production/alarms/' + date)

    return Promise.all([itemsPromise])
      .then(values => {
        const items = values[0] as AlarmListItem[]

        dispatch(efficiencyAlarmsItemsDataSuccess(items))
        dispatch(efficiencyAlarmsIsLoading(false))
        return { items }
      })
      .then((data: any) => {
        return data
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw  error
      })
  }
}

// ----------------------------------------------------
