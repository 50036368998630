import { AnyAction } from 'redux'
import { ServiceListItem, IdNameType } from '../../types'
import { TimeEntry } from '@mv-submodules/inplant-service-fe/types/serviceListItem'

export const initialServiceState = {
  hasErrored: false,
  timeEntriesHasErrored: false,
  isLoading: false,
  timeEntriesIsLoading: false,
  showNewItemMessage: false,
  items: [],
  timeEntries: [],
  trackers: [],
  priorities: [],
  competenze: [],
  tipoAzione: [],
} as ServiceState

export interface ServiceState {
  hasErrored: boolean
  timeEntriesHasErrored: boolean
  isLoading: boolean
  timeEntriesIsLoading: boolean
  showNewItemMessage: boolean
  items: ServiceListItem[]
  timeEntries: TimeEntry[]
  trackers: IdNameType[]
  priorities: IdNameType[]
  competenze: IdNameType[]
  tipoAzione: IdNameType[]
}

export const service = (state: ServiceState = initialServiceState, action: AnyAction) => {
  const newState = Object.assign({}, state)

  switch (action.type) {
    case 'persist/REHYDRATE':
      return { ...state }

    case 'SERVICE_HAS_ERRORED': {
      newState.hasErrored = action.hasErrored
      return newState
    }

    case 'SERVICE_IS_LOADING': {
      newState.isLoading = action.isLoading
      newState.hasErrored = false
      return newState
    }

    case 'SERVICE_ITEMS_FETCH_DATA_SUCCESS': {
      newState.items = action.items
      newState.hasErrored = false
      return newState
    }

    case 'SERVICE_ITEMS_FETCH_ATTRIBUTES_SUCCESS': {
      newState.trackers = action.trackers
      newState.priorities = action.priorities
      newState.competenze = action.competenze
      newState.tipoAzione = action.tipoAzione
      newState.hasErrored = false
      return newState
    }

    case 'SERVICE_SHOW_NEW_ITEM_MESSAGE': {
      newState.showNewItemMessage = action.showNewItemMessage
      return newState
    }

    case 'SERVICE_TIMEENTRIES_HAS_ERRORED': {
      newState.timeEntriesHasErrored = action.hasErrored
      return newState
    }

    case 'SERVICE_TIMEENTRIES_IS_LOADING': {
      newState.timeEntriesIsLoading = action.isLoading
      newState.timeEntriesHasErrored = false
      return newState
    }

    case 'SERVICE_TIMEENTRIES_FETCH_DATA_SUCCESS': {
      newState.timeEntries = action.items
      newState.timeEntriesHasErrored = false
      return newState
    }

    default:
      return state
  }
}
