import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import * as React from 'react'
import { MaintenancesProps, periodLabel } from '../../../../types'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Loader, Table, TableRowInfo } from '@mv-submodules/inplant-components-fe'

export type MaintenancesOwnProps = MaintenancesProps & RouteComponentProps & WithTranslation

class MaintenancesComponent extends React.Component<MaintenancesOwnProps> {
  public render() {
    const {t, data} = this.props

    const pLabel = ( period: string ) => {
      const label = periodLabel(period)
      if (label) {
        return t(label)
      }

      return ''
    }

    const maintenances =
      data && data.rows && data.rows.length > 0 ? (
        <Table
          data={data.rows}
          columns={[
            {
              Header: null,
              sortable: false,
              accessor: 'column1',
              Cell: (( {original}: TableRowInfo ) =>
                  <Link to={'/maintenance/target/' + original.targetId}>
                    {original.status === 'overdue' && (
                      <span className="status status-overdue badge badge-danger"/>
                    )}
                    <span className="planned">{original.plannedDate}</span> -{' '}
                    <span className="type">
                      {t(`moduleSmartdocs.maintenance.jobType.${original.jobType}`)}
                    </span>
                    <span className="operation">{original.operation}</span>
                    <span className="status">
                      {t(`moduleSmartdocs.maintenance.status.${original.status}`)}
                    </span>
                  </Link>
              )
            },
            {
              Header: null,
              sortable: false,
              accessor: 'maintenance',
              width: 100,
              Cell: (( {original}: TableRowInfo ) => <>
                {original.maintenance.length > 0 ? (
                  original.maintenance.map(( periodicMaint: any, pid: number ) => (
                    <React.Fragment key={pid}>
                      {periodicMaint.periodicity ? (
                        <span className="period">{pLabel(periodicMaint.periodicity)}</span>
                      ) : ''}
                      {periodicMaint.totalHours ? (
                        <span className="totalHours">
                            {periodicMaint.totalHours +
                            ' ' +
                            t(`moduleSmartdocs.maintenance.addJob.hours`)}
                          </span>
                      ) : ''}
                    </React.Fragment>
                  ))
                ) : (
                  <React.Fragment>
                    {original.maintenance.periodicity ? (
                      <span className="period">{pLabel(original.maintenance.periodicity)}</span>
                    ) : ''}
                    {original.maintenance.totalHours ? (
                      <span className="totalHours">
                          {original.maintenance.totalHours +
                          ' ' +
                          t(`moduleSmartdocs.maintenance.addJob.hours`)}
                        </span>
                    ) : ''}
                  </React.Fragment>
                )}
              </>),
            },
          ]}
          showPagination={false}
          showPaginationBottom={false}
          noDataText={''}
          isFetching={false}
          className={'table-maintenances'}
        />
      ) : (
        <span>{t('treeDetails.noMaintenances')}</span>
      )

    return (
      <React.Fragment>
        {this.props.showTitle && <h4>{this.props.t('treeDetailsTitlemaintenances')}</h4>}
        {this.props.loading && <Loader/>}
        {!this.props.loading && this.props.ready && <div className="vertical-wrapper">{maintenances}</div>}
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(withTranslation()(MaintenancesComponent))
