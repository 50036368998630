import { IconDefinition, faWrench } from '@fortawesome/free-solid-svg-icons'
import I4Environment from './components/views/I4Environment/I4EnvironmentPageView'

export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey?: string
  icon?: IconDefinition
  iconOverride?: string
  visible: boolean
  component?: any
  children?: RouteProps[]
  aclActionKey?: string
}

const routes: RouteProps = {
  path: '/i4environment',
  i18nkey: 'i4environment.module',
  icon: faWrench,
  visible: true,
  aclActionKey: 'environment.environment.list',
  children: [
    {
      path: '/i4environment',
      exact: true,
      i18nkey: 'i4environment.module',
      component: I4Environment,
      visible: false,
      aclActionKey: 'environment.environment.list',
    },
    {
      path: '/i4environment/:id',
      exact: false,
      i18nkey: 'i4environment.module',
      component: I4Environment,
      visible: false,
      aclActionKey: 'environment.environment.list',
    },
  ],
}

export default routes
