import * as React from 'react'

export interface ImagePreviewProps {
  src: string
  class: string
}

export interface ImagePreviewOwnState {
  data: any
  src: string
  className: undefined | string
  fetching: boolean
  loaded: boolean
}

class ImagePreview extends React.Component<ImagePreviewProps, ImagePreviewOwnState> {
  constructor(props: ImagePreviewProps) {
    super(props)

    this.state = {
      src: this.props.src,
      className: this.props.class,
      data: undefined,
      fetching: false,
      loaded: false,
    }

    this.getImagePreview = this.getImagePreview.bind(this)
  }

  private getImagePreview(srcUrl: string) {
    const requestJWTToken = localStorage.getItem('jwt')

    if (requestJWTToken) {
      const url = srcUrl.replace('http://', 'https://')
      this.setState({
        fetching: true,
        loaded: false,
      })
      fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'bearer ' + requestJWTToken,
        },
      })
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            this.setState({
              data: reader.result,
              fetching: false,
              loaded: true,
            })
          }
        })
    }
  }

  public componentWillMount() {
    if (!this.state.fetching) {
      this.getImagePreview(this.state.src)
    }
  }

  public componentWillReceiveProps(nextProps: ImagePreviewProps) {
    if (nextProps.src !== this.state.src && !this.state.fetching) {
      this.getImagePreview(nextProps.src)

      this.setState({
        src: nextProps.src,
      })
    }
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.data && this.state.loaded && <img src={this.state.data} className={this.state.className} />}
      </React.Fragment>
    )
  }
}

export default ImagePreview
