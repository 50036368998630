import { Moment } from 'moment'
import * as moment from 'moment'
import { capitalizeName, capitalizeFirstLetter } from '../functions/shared'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faForward } from '@fortawesome/free-solid-svg-icons/faForward'
import { faBackward } from '@fortawesome/free-solid-svg-icons/faBackward'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt'

export interface MaintenancePlantStateProps {
  plantStatus: any
}

export interface MaintenanceTypesStateProps {
  types: MaintenanceType[]
}

export interface MaintenanceLastNotes {
  user: string
  date: string
  notes: string
}

export enum MaintenanceStatus {
  DONE = 'done',
  OVERDUE = 'overdue',
  PLANNED = 'planned',
  UNHANDLED = 'unhandled',
  POSTPONED = 'postponed',
  REJECTED = 'rejected',
}
export enum MaintenanceJobType {
  CUSTOM = 'custom',
  MAINTENANCE = 'maintenance',
  ANOMALY = 'anomaly',
}

export enum MaintenanceColors {
  DONE = '#0097A7',
  REJECTED = '#757575',
  OVERDUE = '#E64A19',
  TODO = '#F57C00',
  UNHANDLED = '#EAA907',
}


export enum MaintenanceHistoryActionType {
  CREATED = 'created',
  ACKNOWLEDGED = 'acknowledged',
  DONE = 'done',
  RESCHEDULED = 'rescheduled',
  REJECTED = 'rejected',
}

// Params interfaces
export interface MaintenanceScheduledJobParams {
  id: string
  target?: MaintenanceTargetParams
  macroarea?: MaintenanceMacroArea
  section?: MaintenanceSection
  component?: MaintenanceComponent
  maintenance?: MaintenanceJobRefParams
  type: MaintenanceType
  operation: string
  plannedDate: string
  dueDate: string
  creationDate?: string
  status: MaintenanceStatus
  closingNotes: string
  history?: MaintenanceHistoryParams[]
  relatedJobs?: MaintenanceRelatedJobParams[]
  images?: string[]
  userId?: string
  userFullName?: string
  notes?: string
  lastNotes?: MaintenanceLastNotes
  cost?: number
  time?: number
  jobType: MaintenanceJobType
  acknowledged: boolean
  targetName?: string
  isDoneLate?: boolean
}

// type MaintenanceHistoryActionType = 'created' | 'acknowledged' | 'done' | 'rescheduled' | 'rejected'
export interface MaintenanceHistoryActionParams {
  type: MaintenanceHistoryActionType
  rescheduleDate?: string
}
export interface MaintenanceHistoryParams {
  action: MaintenanceHistoryActionParams
  actionDate: string
  userId: string
  userFullname: string
  notes: string
  data: {[k:string]: string} | null
  lastNotes?: MaintenanceLastNotes
}

export interface MaintenanceRelatedJobParams {
  id: string
  plannedDate: string
  dueDate: string
  status: MaintenanceStatus
  acknowledged: boolean
  history: MaintenanceHistoryParams[]
  isDoneLate: boolean
}

export interface MaintenanceTargetParams {
  id: string
  name: string
  usageHours?: number
  usageHoursDate?: string
}
export interface MaintenanceJobRefParams {
  id: string
  totalHours: number | null
  periodicity: number | null
  notes: string
  currentHours: number | null
}
export class MaintenanceTarget {
  public id: string
  public name: string
  public usageHours?: number | null
  public usageHoursDate?: Moment | null

  constructor(params: MaintenanceTargetParams) {
    this.id = params.id
    this.name = capitalizeFirstLetter(params.name)
    this.usageHours = params.usageHours ? params.usageHours : null
    this.usageHoursDate = params.usageHoursDate ? moment(`${params.usageHoursDate}Z`) : null
  }
}

export interface MaintenanceMacroArea {
  id: string
  name: string
}

export interface MaintenanceSection {
  id: string
  name: string
}

export interface MaintenanceComponent {
  id: string
  name: string
}

export interface MaintenanceType {
  id: string
  name: string
}

export interface MaintenancePlanPdfFilterParam {
  name: string
  value: string
}

export interface MaintenancePlanPdfParams {
  targetIds: string[]
  filters?: MaintenancePlanPdfFilterParam[]
  additionalLevels?: string[]
}

export class MaintenanceHistory {
  public action: { type: MaintenanceHistoryActionType; rescheduleDate?: Moment | null }
  public actionDate: Moment
  public userId: string
  public userFullname: string
  public notes: string
  public lastNotes: MaintenanceLastNotes | null
  public data: {[k:string]: string} | null

  constructor(params: MaintenanceHistoryParams) {
    this.action = {
      type: params.action.type,
      rescheduleDate: params.action.rescheduleDate ? moment(`${params.action.rescheduleDate}Z`) : null,
    }
    this.actionDate = moment(`${params.actionDate}Z`)
    this.userId = params.userId
    this.userFullname = params.userFullname
    this.notes = params.notes || ''
    this.lastNotes = params.lastNotes || null
    this.data = params.data || null
  }
}

export class MaintenanceRelatedJob {
  public id: string
  public plannedDate: Moment
  public dueDate: Moment
  public status: MaintenanceStatus
  public acknowledged: boolean
  public history: MaintenanceHistory[]
  public isDoneLate: boolean

  constructor(params: MaintenanceRelatedJobParams) {
    this.id = params.id
    this.plannedDate = moment(params.plannedDate)
    this.dueDate = moment(params.dueDate)
    this.status = params.status
    this.acknowledged = params.acknowledged
    this.history = params.history
      ? params.history.map((paramsHistory: MaintenanceHistoryParams) => new MaintenanceHistory(paramsHistory))
      : []
    this.isDoneLate = params.isDoneLate ? params.isDoneLate : false
  }
}

export class MaintenanceScheduledJob {
  public id: string
  public target?: MaintenanceTarget | null
  public macroarea?: MaintenanceMacroArea | null
  public section?: MaintenanceSection | null
  public component?: MaintenanceComponent | null
  public maintenance?: MaintenanceJobRefParams | null
  public type: MaintenanceType
  public extendedProps?: any

  public operation: string
  public plannedDate: Moment
  public dueDate: Moment
  public creationDate?: Moment
  public status: MaintenanceStatus
  public closingNotes: string
  public history: MaintenanceHistory[]
  public relatedJobs: MaintenanceRelatedJob[]
  public images?: string[]
  public userId?: string | null
  public userFullName?: string | null
  public notes: string
  public lastNotes?: MaintenanceLastNotes | null
  public cost?: number | null
  public time?: number | null
  public jobType: MaintenanceJobType
  public acknowledged: boolean
  public targetName?: string | null
  public isDoneLate: boolean

  public icon: string
  public color: string

  // fullcalendar
  public start: string
  public title: string
  public editable: boolean

  constructor(params: MaintenanceScheduledJobParams, config?: any) {
    this.id = params.id
    this.target = params.target ? new MaintenanceTarget(params.target) : null
    this.macroarea = params.macroarea ? capitalizeName(params.macroarea) : null
    this.section = params.section ? capitalizeName(params.section) : null
    this.component = params.component ? capitalizeName(params.component) : null
    this.maintenance = params.maintenance ? params.maintenance : null
    this.type = capitalizeName(params.type)
    this.operation = params.operation
    this.plannedDate = moment(params.plannedDate)
    this.dueDate = moment(params.dueDate)
    this.creationDate = params.creationDate ? moment(params.creationDate) : undefined
    this.status = params.status
    this.closingNotes = params.closingNotes
    this.history = params.history
      ? params.history.map((paramsHistory: MaintenanceHistoryParams) => new MaintenanceHistory(paramsHistory))
      : []
    this.relatedJobs = params.relatedJobs
      ? params.relatedJobs.map(
        (paramsRelatedJob: MaintenanceRelatedJobParams) => new MaintenanceRelatedJob(paramsRelatedJob)
      )
      : []
    this.images = params.images ? params.images : []
    this.userId = params.userId ? params.userId : null
    this.userFullName = params.userFullName ? params.userFullName : null
    this.notes = params.notes || ''
    this.lastNotes = params.lastNotes || null
    this.cost = params.cost ? params.cost : null
    this.time = params.time ? params.time : null
    this.jobType = params.jobType
    this.acknowledged = params.acknowledged
    this.targetName = params.targetName ? params.targetName : null
    this.isDoneLate = params.isDoneLate ? params.isDoneLate : false

    this.setIconAndColor()
    this.title = this.getTitle(config)
    this.start = this.dueDate.format('YYYY-MM-DD')
    this.editable = this.status === MaintenanceStatus.DONE || this.status === MaintenanceStatus.REJECTED ? false : true
    this.icon = ''
    this.color = ''
    this.setIconAndColor()
  }

  public getTitle = (config: any = undefined) => {
    const maintenanceConfig = config && config.maintenance
    let title = ''
    if (maintenanceConfig && maintenanceConfig.additionalLevels.length > 0) {
      const titleParts: string[] = []
      if (this.macroarea) {
        titleParts.push(this.macroarea.name)
      }
      if (this.component) {
        titleParts.push(this.component.name)
      }
      if (this.operation) {
        titleParts.push(this.operation)
      }
      /* if (this.target) {
        titleParts.push(this.target.name)
      } else {
        if (this.type) { titleParts.push(this.type.name) }
      } */
      title = titleParts.join(' - ')
    } else {
      title += this.operation
    }
    return title
  }

  private setIconAndColor = () => {
    this.icon =
      this.jobType === MaintenanceJobType.MAINTENANCE ? icon(faSyncAlt, { transform: { rotate: 90 } }).html[0] : ''

    if (this.status !== MaintenanceStatus.OVERDUE) {
      if (this.dueDate.isAfter(this.plannedDate, 'day')) {
        this.icon = icon(faForward).html[0]
      } else if (this.dueDate.isBefore(this.plannedDate, 'day')) {
        this.icon = icon(faBackward).html[0]
      }
    }

    switch (this.status) {
      case MaintenanceStatus.OVERDUE: {
        this.color = MaintenanceColors.OVERDUE
        break
      }
      case MaintenanceStatus.POSTPONED:
      case MaintenanceStatus.PLANNED: {
        this.color = MaintenanceColors.TODO
        break
      }
      case MaintenanceStatus.DONE: {
        this.color = MaintenanceColors.DONE
        break
      }
      case MaintenanceStatus.REJECTED: {
        this.color = MaintenanceColors.REJECTED
        break
      }
      case MaintenanceStatus.UNHANDLED: {
        this.color = MaintenanceColors.UNHANDLED
        break
      }
      default: {
        this.color = MaintenanceColors.UNHANDLED
        break
      }
    }
  }
}

export interface MaintenanceScheduledJobDetailStateProps {
  fetching: boolean
  error: Error | null
  scheduledJob: MaintenanceScheduledJob | null
}

export interface MaintenanceScheduledJobPatchParams {
  id: string
  date?: Moment
  notes: string
  cost?: number
  time?: number
}
export interface MaintenanceScheduledJobPostParams {
  targetId?: string | null
  operation: string
  typeId: string
  dueDate: Moment,
  notes?: string
}

export interface MaintenanceJobPostParams {
  targetId?: string | null
  operation: string
  typeId: string
  periodicity: number | null
  hours: number | null
  notes: string
  lastDoneDate: string | null
  isSpecialOperation: boolean
}
export interface MaintenanceJobPatchParams {
  id: string
  periodicity: number | null
  hours: number | null
  notes: string
  isSpecialOperation: boolean
}

export interface MaintenanceJobDeleteParams {
  id: string
}

export type MaintenanceLegendItem =
  | 'done'
  | 'rejected'
  | 'overdue'
  | 'todo'
  | 'unhandled'
  | 'periodic'
  | 'anticipated'
  | 'postponed'
export interface MaintenanceModuleConfig {
  currency?: string
  enablePlanPrint?: boolean
  enableAcknowledgment?: boolean
  enableScheduledJobRejectAction?: boolean
  enableScheduledJobConstAndTime?: boolean
  additionalLevels?: Array<{ level: string; showOnPlanList: boolean }>
  showTargetNameOnNullTarget?: boolean
  scheduledJobsHiddenCols?: string[]
  calendarLegedHiddenItems?: MaintenanceLegendItem[]
}

export interface ModuleConfig {
  maintenance: MaintenanceModuleConfig
  [key: string]: any
}

export type MaintenanceBulkAction = null | 'done' | 'rescheduled' | 'rejected' | 'acknowledged'
export interface MaintenanceScheduledJobBulkActionParams {
  ids: string[]
  action: MaintenanceBulkAction
  date?: Moment
  notes?: string
}
export interface MaintenancePage {
  name: string
  description?: string
  pagePath: string
  pageType: 'calendar' | 'plan' | 'setup'
  slug: string
  component: any
  hiddenCols: null | string[]
  enableNewButton: true
}
