import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import Webview from '../ui/components/widgets/Webview/Webview'

interface RouteProps {
  path: string
  exact?: boolean
  text?: string
  icon?: IconDefinition
  visible: boolean
  visibleInUserProfile?: boolean
  component?: any
  children?: RouteProps[]
}

const webviewRoute: RouteProps = {
  path: '/webview',
  visible: false,
  component: Webview,
}

export { webviewRoute }
