import { AnyAction } from 'redux'
import { ProductionState, initialProductionState } from '../index'

export const production = (state: ProductionState = initialProductionState, action: AnyAction) => {
  const newState = Object.assign({}, state)

  switch (action.type) {
    case 'persist/REHYDRATE':
      return Object.assign(
        {},
        {
          ...state,
        }
      )

    case 'PRODUCTION_HAS_ERRORED': {
      newState.hasErrored = action.hasErrored
      return newState
    }

    case 'PRODUCTION_IS_LOADING': {
      newState.isLoading = action.isLoading
      return newState
    }

    case 'PRODUCTION_ITEMS_FETCH_DATA_SUCCESS': {
      newState.items = action.items
      return newState
    }

    case 'PRODUCTION_DOWNLOAD_HAS_ERRORED': {
      newState.downloadHasErrored = action.hasErrored
      return newState
    }

    case 'PRODUCTION_DOWNLOAD_IS_LOADING': {
      newState.downloadIsLoading = action.isLoading
      return newState
    }

    case 'PRODUCTION_DOWNLOAD_ITEMS_FETCH_DATA_SUCCESS': {
      newState.downloadItems = action.items
      return newState
    }


    /*-----------*/
    case 'PRODUCTION_ALARMS_HAS_ERRORED': {
      newState.hasAlarmsErrored = action.hasErrored
      return newState
    }

    case 'PRODUCTION_ALARMS_IS_LOADING': {
      newState.isAlarmsLoading = action.isLoading
      return newState
    }

    case 'PRODUCTION_ALARMS_ITEMS_FETCH_DATA_SUCCESS': {
      newState.itemsAlarms = action.items
      return newState
    }

    case 'PRODUCTION_COSTS_ITEMS_FETCH_DATA_SUCCESS': {
      newState.itemsCosts = action.items
      return newState
    }

    case 'FETCH_LOT_IS_LOADING': {
      newState.isDetailsLoading = action.isDetailsLoading
      return newState
    }

    case 'FETCH_LOT_HAS_ERRORED': {
      newState.lot = null
      newState.isDetailsLoading = action.isDetailsLoading
      newState.hasDetailsErrored = action.hasDetailsErrored
      return newState
    }

    case 'FETCH_LOT_SUCCESS': {
      newState.lot = action.lot
      newState.hasDetailsErrored = action.hasDetailsErrored
      return newState
    }

    default:
      return state
  }
}
