import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  fetchGraphData,
  fetchI4EnvironmentData,
  fetchManualDataLoadingData,
  prepareDataForManualDataLoadingGraph,
} from '../../../../redux/actions'
// import { fetchData as fetchI4SmartdocData } from 'inplant-smartdocs-fe'
// import { multiline } from '../../i4efficiency/libs/Graphs'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
// import * as moment from 'moment'
import { differenceInMilliseconds, differenceInMonths, differenceInHours, format, getTime, parseISO } from 'date-fns'
import ConsumptionWidget from '../../widgets/ConsumptionWidget/ConsumptionWidget'
import { Alert, Card, CardBody, CardFooter, Loader, PageHeader } from '@mv-submodules/inplant-components-fe'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import DownloadDataButton from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import { timeRangeFileString } from '@mv-submodules/inplant-environment-fe-imel/functions/shared'
import {
  ManualDataLoadingConsumes,
  ManualDataLoadingGraphData,
} from '@mv-submodules/inplant-environment-fe-imel/redux/types'
import { isJSON, saveDataAsCSV } from '@mv-submodules/inplant-core-fe/functions'
import ManualDataLoadingConsumptionGraphData from '@mv-submodules/inplant-environment-fe-imel/ui/components/widgets/ManualDataLoadingConsumptionGraphData/ManualDataLoadingConsumptionGraphData'
import LoadManualDataLoadingMaterialModal from '@mv-submodules/inplant-environment-fe-imel/ui/components/widgets/LoadManualDataLoadingMaterialModal/LoadManualDataLoadingMaterialModal'
import DateRangeFilters from '@mv-submodules/inplant-core-fe/ui/components/widgets/dateFiltersBase/components/DateRangeFilters'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

const manualColors = {
  ActivePower: '#9467bd',
  ActivePower_Both: '#74478d',
  ActivePower_Chiller: '#54276d',
  Binder: '#48ff03',
  CarbonFootprint: '#803048',
  CompressedAir: '#1f77b4',
  ComprimedAir: '#1f77b4',
  DemiWater: '#0c400c',
  ExaustChaminsTemp: '#212fff',
  ExaustChaminsVol: '#19c9ff',
  Gas: '#ff7f0e',
  Gas_Both: '#aa4f0e',
  Gas_Chiller: '#7a1f0e',
  Paste: '#ff00fe',
  RawWater: '#1c601c',
  RefillWater: '#2ca02c',
  ThermalEnergy: '#d62728',
  ThermalEnergy_Both: '#d66441',
  ThermalEnergy_Chiller: '#f3a062',
}

interface I4EnvironmentStateProps {
  data?: any
  hasErrored: boolean
  isLoading: boolean
  columns?: string[]
  lastDateUpdate: number | null
  range: string | null
  // plantStructure?: any
  dateStart: string | null
  dateEnd: string | null
  measureUnits: object
  plantMetrics: []
  config?: {
    disabledActions?: string
  } | null
}

interface I4EnvironmentDispatchProps {
  fetchData: () => Function
  // fetchSmartdocData: () => Function
  fetchGraphData: (measures: string[], labels: string[], grouped: boolean) => Promise<any>
  fetchManualDataLoadingData: () => Promise<any>
}

interface I4EnvironmentOwnProps {
  history: any
  // t: TranslationFunction
  match: any
}

interface PercentData {
  min: number
  max: number
  diff: number
}

interface I4EnvironmentOwnState {
  activeSection: undefined | number
  activeSeries: string[]
  activeTab: null | number
  currentHover: null | string
  dataHasErrors: boolean
  dataIsLoading: boolean
  graphData: any
  graphDataMeans: { [k: string]: number }
  lastUpdate: number | null
  legend: any
  percentData: PercentData[]
  plantStructure: any
  series: string[] | null
  showLoadDataModal: boolean
  manualDataLoadingGraphData?: ManualDataLoadingGraphData
  isFetchingManualDataLoading: boolean
}

const mapStateToProps = (state: any): I4EnvironmentStateProps => ({
  // plantStructure: state.smartdocs.smartdocs.data,
  columns: state.graphI4EnvironmentData.columns,
  data: state.graphI4EnvironmentData.data,
  hasErrored: state.graphI4EnvironmentData.hasErrored,
  isLoading: state.graphI4EnvironmentData.isLoading,
  lastDateUpdate: state.dateFilters.lastUpdate,
  range: state.dateFilters.range,
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
  measureUnits: state.config.generic.measureUnits || {},
  plantMetrics: state.config.generic.plantMetrics || [],
  config: state.config.costs || null,
})

const mapDispatchToProps = (dispatch: Function): I4EnvironmentDispatchProps => ({
  fetchData: () => dispatch(fetchI4EnvironmentData()),
  // fetchSmartdocData: () => dispatch(fetchI4SmartdocData()),
  fetchGraphData: (measures, labels, grouped) => dispatch(fetchGraphData(measures, labels, grouped)),
  fetchManualDataLoadingData: () => dispatch(fetchManualDataLoadingData()),
})

type I4EnvironmentProps = I4EnvironmentStateProps & I4EnvironmentDispatchProps & I4EnvironmentOwnProps & WithTranslation

/*
const generateRandomColor = (): string => {
  return 'hsl(' + Math.floor(Math.random() * 180  + Math.random() * 180) + ', 90%, 50%)'
} */

const fallBackColors = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#ffffff',
]

class I4EnvironmentPageView extends React.Component<I4EnvironmentProps, I4EnvironmentOwnState> {
  constructor(props: I4EnvironmentProps) {
    super(props)
    this.state = {
      activeSection: 0,
      activeSeries: [],
      activeTab: 0,
      currentHover: null,
      dataHasErrors: false,
      dataIsLoading: false,
      graphData: null,
      graphDataMeans: {},
      lastUpdate: null,
      legend: null,
      percentData: [],
      plantStructure: this.props.plantMetrics,
      series: [],
      showLoadDataModal: false,
      isFetchingManualDataLoading: false,
    }

    this.changeSection = this.changeSection.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.clickSeries = this.clickSeries.bind(this)
    this.hoverLegendEnter = this.hoverLegendEnter.bind(this)
    this.hoverLegendLeave = this.hoverLegendLeave.bind(this)
    this.openLoadDataModal = this.openLoadDataModal.bind(this)
    this.renderTooltip = this.renderTooltip.bind(this)
    this.updateGraphs = this.updateGraphs.bind(this)
    this.closeLoadDataModal = this.closeLoadDataModal.bind(this)
  }

  public UNSAFE_componentWillMount() {
    const newState: any = {
      activeSection: 0,
      activeTab: 0,
      graphData: null,
      activeSeries: [],
    }

    const urlParams = new URLSearchParams(window.location.search)
    const section = parseInt(urlParams.get('section') || '0', 10)

    if (this.props.match.params && this.props.match.params.id) {
      newState.activeSection = section
      newState.activeSeries = [this.props.match.params.id]
    }

    this.setState(newState, () => this.updateGraphs())
  }

  public UNSAFE_componentWillReceiveProps(newProps: I4EnvironmentStateProps) {
    if (newProps.lastDateUpdate !== null && this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.setState(
        {
          lastUpdate: newProps.lastDateUpdate,
          graphData: null,
        },
        () => this.updateGraphs()
      )
    }
  }

  public render() {
    let legend

    const lines = this.state.series || []
    legend = lines.map((line: string, index: number) => (
      <li
        className="filter-trigger"
        key={line}
        data-filter={line}
        onMouseOver={() => this.hoverLegendEnter(line)}
        onMouseOut={() => this.hoverLegendLeave(line)}
      >
        <span
          className={'legend-color legend-' + line.toLowerCase() + '-color'}
          style={{ backgroundColor: manualColors[line] || fallBackColors[index] }}
        />
        {' ' + this.props.t('i4environment.metrics.' + line, { defaultValue: line })}
      </li>
    ))

    const buttons = lines.map((line: string) => {
      return {
        name: line,
        unit: this.props.measureUnits[line] || '',
        value: this.state.graphDataMeans[line] || 0,
      }
    })

    const timeRangeSrc =
      (this.state.graphData &&
        this.state.graphData.length > 0 && [
          this.state.graphData[this.state.graphData.length - 1].time,
          getTime(parseISO(this.state.graphData[0].time)),
        ]) ||
      null

    const singleLabel = buttons.find(e => e.name === this.state.activeSeries[0])
    const timeRangeMonths = timeRangeSrc ? differenceInMonths(parseISO(timeRangeSrc[0]), timeRangeSrc[1]) : null
    const timeRangeSeconds = timeRangeSrc
      ? differenceInMilliseconds(parseISO(timeRangeSrc[0]), timeRangeSrc[1]) / 1000
      : null
    const timeRangeHours = timeRangeSrc ? differenceInHours(parseISO(timeRangeSrc[0]), timeRangeSrc[1]) : null
    const activeSectionData =
      this.state.plantStructure &&
      this.state.plantStructure.find((section: any) => section.id === this.state.activeSection)
    let downloadColumns
    let downloadData

    if (this.state.graphData && this.state.graphData.length > 0) {
      downloadColumns = Object.keys(this.state.graphData[0]).map(
        (c: string) =>
          `${this.props.t(`i4environment.metrics.${c}`, { defaultValue: c })}${
            this.props.measureUnits[c] ? ` (${this.props.measureUnits[c]})` : ''
          }`
      )
      downloadData = [downloadColumns].concat(
        this.state.graphData.map((e: any) => {
          return Object.keys(e).map(f => {
            if (f === 'time') {
              if (timeRangeHours && timeRangeHours < 24) {
                return format(new Date(e[f]), 'yyyy/MM/dd HH:mm')
              }
              return format(new Date(e[f]), 'yyyy/MM/dd')
            }
            return e[f]
          })
        })
      )
    }

    const data = JSON.parse(JSON.stringify(this.state.graphData))

    if (
      this.state.activeSeries &&
      (this.state.activeSeries.length > 1 || this.state.activeSeries.length === 0) &&
      data
    ) {
      data.map((d: any, e: number) => {
        Object.keys(d).map((k: string) => {
          if (this.state.percentData && this.state.percentData[k] && this.state.percentData[k].max) {
            data[e][k] = (data[e][k] / this.state.percentData[k].max) * 100
          }
        })
      })
    }

    const downloadFileName =
      this.props
        .t('i4environment.tabs.' + activeSectionData.data.find((el: any) => el.id === this.state.activeTab).label)
        .replace(/\s/g, '_') + timeRangeFileString(this.props.dateStart, this.props.dateEnd)

    const disabledActions =
      this.props.config && this.props.config.disabledActions && isJSON(this.props.config.disabledActions)
        ? JSON.parse(this.props.config.disabledActions)
        : []

    return (
      <React.Fragment>
        <PageHeader title={'Environment'} />

        <div className="content">
          <div id="filters-wrapper">
            <Row spacing={{ vertical: false, horizontal: false }}>
              <Column xs={12}>
                <div className="card bg-light">
                  <div className="card-body row no-gutters">
                    <DateRangeFilters />
                  </div>
                </div>
              </Column>
            </Row>
          </div>

          {this.state.showLoadDataModal && this.props.dateStart && (
            <LoadManualDataLoadingMaterialModal
              visible={this.state.showLoadDataModal}
              closeModal={this.closeLoadDataModal}
            />
          )}

          <Row spacing={{ vertical: false, horizontal: false }}>
            <Column xs={12}>
              <Row spacing={{ horizontal: false }}>
                <Column lg={10}>
                  <div className="DashboardWidget WidgetEnvironment WidgetH50">
                    <Card marginBottom={3}>
                      <ul className="nav nav-tabs">
                        {activeSectionData &&
                          activeSectionData.data &&
                          activeSectionData.data.map((el: any) => (
                            <li className="nav-item" key={el.id}>
                              <a
                                className={'nav-link ' + (this.state.activeTab === el.id ? 'active' : '')}
                                href="#"
                                onClick={e => this.changeTab(e, el)}
                              >
                                {this.props.t('i4environment.tabs.' + el.label)}
                              </a>
                            </li>
                          ))}
                      </ul>
                      {downloadData && ( // @todo verificare dati scaricati dopo modifica percent
                        <DownloadDataButton
                          saveDataAsCSV={saveDataAsCSV}
                          data={downloadData}
                          type={'csv'}
                          label={'i4environment.download.buttonLabel'}
                          fileName={downloadFileName}
                          className="position-absolute btn-over-tabs"
                          addTime={false}
                        />
                      )}
                      <CardBody>
                        {this.props.hasErrored ? (
                          <Column key={Date.now()}>
                            <label className={'text-center'}>
                              <i className="fas fa-2x fa-exclamation-triangle text-danger" />
                              Invalid data range, please change selection
                            </label>
                          </Column>
                        ) : this.props.isLoading ? (
                          <Column key={Date.now()}>
                            <label className={'text-center'}>
                              <i className="fas fa-2x fa-spin fa-circle-notch" />
                            </label>
                          </Column>
                        ) : (
                          <div className="container-fluid chart-container">
                            <div className="full-h">
                              <Row verticalAlignment={'center'}>
                                <Column sm={12}>
                                  <div className="text-center full-h chart-wrapper">
                                    {this.state.activeSeries.length === 1 && (
                                      <div className="y-label">{singleLabel && singleLabel.unit}</div>
                                    )}
                                    {(this.state.activeSeries.length === 0 || this.state.activeSeries.length > 1) &&
                                      !(!data || data.length === 0 || this.state.dataHasErrors) && (
                                        <div className="y-label">%</div>
                                      )}
                                    <div className="chart-container">
                                      {data && data.length > 0 && (
                                        <ResponsiveContainer>
                                          <LineChart
                                            data={data}
                                            margin={{
                                              top: 10,
                                              right: 30,
                                              left: 0,
                                              bottom: 10,
                                            }}
                                          >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                              dataKey="time"
                                              tickFormatter={(e: number) =>
                                                timeRangeSeconds && timeRangeSeconds >= 2592100 && timeRangeMonths
                                                  ? timeRangeMonths > 5
                                                    ? format(new Date(e), 'MM/yyyy')
                                                    : format(new Date(e), 'MM/yy')
                                                  : timeRangeHours && timeRangeHours < 24
                                                  ? format(new Date(e), 'HH:00')
                                                  : format(new Date(e), 'dd/MM')
                                              }
                                              interval={
                                                this.props.range &&
                                                ['dateRangeLast6Month', 'dateRange6Month'].includes(this.props.range)
                                                  ? 30
                                                  : 0
                                              }
                                            />
                                            <YAxis
                                              type="number"
                                              yAxisId="left"
                                              name="Time"
                                              width={40}
                                              ticks={
                                                this.state.activeSeries.length === 1
                                                  ? undefined
                                                  : [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
                                              }
                                            />
                                            <Tooltip content={(a:any) => this.renderTooltip(a,timeRangeHours && timeRangeHours < 24 || false)} />
                                            {lines.map(
                                              (l: string, index: number) =>
                                                (this.state.activeSeries.includes(l) ||
                                                  this.state.activeSeries.length < 1) && (
                                                  <Line
                                                    dataKey={l}
                                                    name={l}
                                                    key={l}
                                                    stroke={manualColors[l] || fallBackColors[index]}
                                                    yAxisId={'left'}
                                                    dot={timeRangeMonths !== null && timeRangeMonths < 6}
                                                    opacity={
                                                      this.state.currentHover === l || this.state.currentHover === null
                                                        ? 1
                                                        : 0.1
                                                    }
                                                    isAnimationActive={false}
                                                  />
                                                )
                                            )}
                                          </LineChart>
                                        </ResponsiveContainer>
                                      )}

                                      {this.state.dataIsLoading ? (
                                        <Loader />
                                      ) : (
                                        (!data || data.length === 0 || this.state.dataHasErrors) && (
                                          <Alert type={'warning'}>{this.props.t('i4environment.fetch.noData')}</Alert>
                                        )
                                      )}

                                      <div id={'tooltip'}>
                                        <div className="content" />
                                      </div>
                                    </div>
                                  </div>
                                </Column>
                              </Row>
                            </div>
                          </div>
                        )}
                      </CardBody>
                      <CardFooter textAlign={'right'} textMuted={true} textSizeSmall={true}>
                        <ul className="legend float-right">{legend}</ul>
                      </CardFooter>
                    </Card>
                    {!disabledActions.includes('manual-data-loading') && this.state.activeSection === 1 && (
                      <ManualDataLoadingConsumptionGraphData
                        graphData={this.state.manualDataLoadingGraphData}
                        range={this.props.range}
                        isFetching={this.state.isFetchingManualDataLoading}
                        timeRangeMonths={timeRangeMonths}
                        timeRangeSeconds={timeRangeSeconds}
                      />
                    )}
                  </div>
                </Column>

                <Column lg={2}>
                  <ConsumptionWidget
                    buttons={buttons}
                    selecteds={this.state.activeSeries}
                    clickCallback={this.clickSeries}
                    carbonFootprint={this.state.graphDataMeans.CarbonFootprint}
                    plantStructure={this.state.plantStructure}
                    activeSection={this.state.activeSection}
                    changeSectionFn={this.changeSection}
                    isLoading={this.state.dataIsLoading}
                    openModalFn={this.openLoadDataModal}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </div>
      </React.Fragment>
    )
  }

  private changeTab(e: React.MouseEvent<HTMLAnchorElement>, element: { id: number; label: string }) {
    this.setState(
      {
        activeTab: element.id,
        graphData: null,
        activeSeries: [],
      },
      () => this.updateGraphs()
    )
  }

  private changeSection(e: React.MouseEvent<HTMLAnchorElement>, elementId: number) {
    this.setState(
      {
        activeSection: elementId,
        activeTab: 0,
        graphData: null,
        activeSeries: [],
      },
      () => this.updateGraphs()
    )
  }

  private updateGraphs() {
    const disabledActions =
      this.props.config && this.props.config.disabledActions && isJSON(this.props.config.disabledActions)
        ? JSON.parse(this.props.config.disabledActions)
        : []
    const fetchMdl = !disabledActions.includes('manual-data-loading')
    const section = this.state.plantStructure.find((e: any) => e.id === this.state.activeSection)

    if (section) {
      const area = section.data.find((e: any) => e.id === this.state.activeTab)
      if (area) {
        const dataMeasures = area.measures.map((m: { memory: string; name: string }) => m.memory)
        const dataLabels = area.measures.map((m: { memory: string; name: string }) => m.name)
        // const userSelections = this.state.activeSeries && this.state.activeSeries.length > 0
        const percentData: any[] = []

        this.setState({
          dataIsLoading: true,
        })

        this.props
          .fetchGraphData(dataMeasures, dataLabels, true)
          .then((data: any) => {
            let graphData: any = []
            let series: any = []

            if (data[0] && data[0].results && data[0].results[0] && data[0].results[0].series) {
              const dataResults: { columns: string[]; name: string; values: string[][] } = data[0].results[0].series[0]
              series = dataResults.columns.filter((e: string) => e !== 'time')
              graphData = dataResults.values.map(e => {
                const out = { time: e[0] }

                series.map((s: string, i: number) => {
                  if (s !== 'time') {
                    out[s] = e[i + 1]
                  }
                })

                return out
              })

              graphData.map((e: any[], i: number) => {
                Object.keys(e).map((v: any, l: number) => {
                  if (v !== 'time') {
                    const actualValue = e[v]
                    const existingValue = percentData[v]

                    if (existingValue) {
                      if (!existingValue.min || existingValue.min > actualValue) {
                        percentData[v].min = actualValue
                      }
                    } else {
                      percentData[v] = { min: actualValue }
                    }

                    if (existingValue) {
                      if (!existingValue.max || existingValue.max < actualValue) {
                        percentData[v].max = actualValue
                      }
                    } else {
                      percentData[v] = { max: actualValue }
                    }

                    if (existingValue && existingValue.min && existingValue.max) {
                      percentData[v].diff = existingValue.max - existingValue.min
                    }
                  }
                })
              })

              this.setState({
                graphData,
                dataIsLoading: false,
                dataHasErrors: false,
                series,
                percentData,
              })
            } else {
              this.setState({
                dataIsLoading: false,
                dataHasErrors: true,
              })
            }
          })
          .catch((error: any) => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
          })

        this.props
          .fetchGraphData(dataMeasures, dataLabels, false)
          .then((data: any) => {
            const graphDataMeans: { [k: string]: number } = {}

            if (
              data &&
              data[0] &&
              data[0] &&
              data[0].results &&
              data[0].results[0] &&
              data[0].results[0].series &&
              data[0].results[0].series[0]
            ) {
              const dataResult = data[0].results[0].series[0]
              dataResult.columns.map((c: string, i: number) => {
                graphDataMeans[c] = dataResult.values[0][i]
              })

              this.setState({
                graphDataMeans,
                dataHasErrors: false,
                dataIsLoading: false,
              })
            } else {
              this.setState({
                dataIsLoading: false,
                dataHasErrors: true,
              })
            }
          })
          .catch((error: any) => {
            if (error.name === 'FetchError' && error.statusCode === 401) {
              logoutUser()
            }
            console.log('error', error) // tslint:disable-line
          })

        if (fetchMdl && !this.state.isFetchingManualDataLoading) {
          this.setState({
            isFetchingManualDataLoading: true,
            manualDataLoadingGraphData: undefined,
          })
          this.props
            .fetchManualDataLoadingData()
            .then((manualDataLoadingGraphData: ManualDataLoadingConsumes[]) => {
              this.setState({
                manualDataLoadingGraphData: prepareDataForManualDataLoadingGraph(manualDataLoadingGraphData),
              })
            })
            .catch((error: any) => {
              if (error.name === 'FetchError' && error.statusCode === 401) {
                logoutUser()
              }
              console.log('error', error) // tslint:disable-line
            })
            .finally(() => {
              this.setState({
                isFetchingManualDataLoading: false,
              })
            })
        }
      }
    }
  }

  private renderTooltip(a: any,showHours:boolean) {
    const data = this.state.graphData ? this.state.graphData.find((e: any) => e.time === a.label) : null
    return (
      <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
        <div
          className="recharts-default-tooltip"
          style={{
            margin: 0,
            padding: 10,
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid rgb(204, 204, 204)',
            whiteSpace: 'nowrap',
          }}
        >
          <p className="recharts-tooltip-label time-label" style={{ margin: 0 }}>
            {a.label ? format(new Date(a.label), `d/M/yyyy ${showHours ? 'HH:mm' : ''}`) : ''}
          </p>
          {this.state.series &&
            data &&
            this.state.series.map((label: string, index: number) => (
              <p
                key={label}
                className="recharts-tooltip-label"
                style={{ margin: 0, color: manualColors[label] || fallBackColors[index] }} // @todo change
              >
                {' ' + this.props.t('i4environment.metrics.' + label, { defaultValue: label })}:{' '}
                {Number.parseFloat(data[label]).toFixed(2)} {this.props.measureUnits[label]}
              </p>
            ))}
        </div>
      </div>
    )
  }

  private hoverLegendEnter(l: string) {
    this.setState({
      currentHover: l,
    })
  }

  private hoverLegendLeave(l: string) {
    this.setState({
      currentHover: null,
    })
  }

  private clickSeries(s: string) {
    const state = Object.assign({}, this.state)

    if (state.activeSeries.includes(s)) {
      state.activeSeries.splice(this.state.activeSeries.indexOf(s), 1)
      this.setState({ activeSeries: state.activeSeries })
    } else {
      state.activeSeries.push(s)
      this.setState({
        activeSeries: state.activeSeries,
      })
    }
  }

  private openLoadDataModal() {
    this.setState({
      showLoadDataModal: true,
    })
  }

  private closeLoadDataModal(update?: boolean) {
    if (update) {
      this.updateGraphs()
    }
    this.setState({ showLoadDataModal: false })
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(I4EnvironmentPageView))
)
