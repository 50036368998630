// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import '../../../styles/_index.scss'

interface Props {
 //   justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around'
    //   alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch'
    //  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
    // alignSelf?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch',
    // children: React.ReactNode
    // flex?: 'flex' | 'd-flex'
    // direction?: 'row' | 'column'
    customType?: 'loginForm' | 'headerFilters'
}

class Wrapper extends React.Component<Props>{
    public render() {
        const {children, customType} = this.props
        let className = ""
        if (customType && customType === 'loginForm'){
            className = 'd-flex justify-content-center loginContainer'
        }
        if (customType && customType === 'headerFilters'){
            className = `${className} mb-2`
        }
        return (
            <div className={className}>
                {children}
            </div>
        )
    }
}

export default Wrapper