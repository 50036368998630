import * as React from 'react'
import Checkbox from './Checkbox'

export interface Option {
  value: any
  name: string
}

interface Props {
  title: string
  options: Option[]
  selected: string[]
  onSelectedChanged?: (selected: string[]) => void
}

class CheckboxGroup extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this)
    this.isChecked = this.isChecked.bind(this)
  }

  private handleSelectionChanged(option: Option, checked: boolean) {
    const { selected, onSelectedChanged } = this.props
    if (checked) {
      if (onSelectedChanged) {
        onSelectedChanged([...selected, option.value])
      }
    } else {
      const index = selected.indexOf(option.value)
      const removed = [...selected.slice(0, index), ...selected.slice(index + 1)]
      if (onSelectedChanged) {
        onSelectedChanged(removed)
      }
    }
  }

  private isChecked(name: string, option: Option): boolean {
    const { selected } = this.props
    if (typeof selected[name] !== undefined) {
      return selected[name].includes(option.value)
    }
    return false
  }

  public render() {
    const { title, options, selected } = this.props

    return (
      <React.Fragment>
        <h6 className="text-muted">{title}</h6>
        {options.map((option: Option, index: number) => (
          <Checkbox
            key={index}
            option={option}
            checked={selected.includes(option.value)}
            onSelectionChanged={(c: boolean) => this.handleSelectionChanged(option, c)}
          />
        ))}
      </React.Fragment>
    )
  }
}

export default CheckboxGroup
