import * as React from 'react'
import { Component } from 'react'
import * as ReactDOM from 'react-dom'
import { widgets } from '@mv-submodules/inplant-config/config/widgets'
import { RouteComponentProps, withRouter } from 'react-router';

interface OwnState {
  widgetAreas: any[]
}

type Props = RouteComponentProps<{}>

/* @todo TO RENAME FOR GLOBAL USE */
class SideBarBottomWidget extends React.Component<Props, OwnState> {
  constructor( props: any ) {
    super(props)

    this.state = {
      widgetAreas: [],
    }
    this.updateWidgets = this.updateWidgets.bind(this)
  }

  public componentDidMount(): void {
    this.updateWidgets()
  }

  public componentDidUpdate( prevProps: Readonly<any>, prevState: Readonly<OwnState>, snapshot?: any ): void {
    if(prevProps.location !== this.props.location) {
      this.updateWidgets();
    }
  }

  public render() {
    const {widgetAreas} = this.state

    if (widgetAreas && widgetAreas.length > 0) {
      return widgetAreas.map(wa => ReactDOM.createPortal(this.renderWidget(wa.widgets), wa.element))
    }

    return null
  }

  public renderWidget( widgetComponents: Component[] ) {
    return (
      widgetComponents &&
      Object.keys(widgetComponents).map(wco => {
        const WidgetComponent = widgetComponents[wco]
        return <WidgetComponent key={wco + window.location.pathname}/>
      })
    )

    return null
  }

  private updateWidgets(): void {
    const widgetAreas =
      widgets &&
      Object.keys(widgets)
      .map(( wa: string ) => {
        return {
          name: wa,
          element: document.getElementById(wa),
          widgets: widgets[wa],
        }
      })
      .filter(e => e.element !== null)

    this.setState({
      widgetAreas: widgetAreas !== null ? widgetAreas : [],
    })
  }
}

export default withRouter<any, any>(SideBarBottomWidget)

