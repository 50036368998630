import * as React from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {fetchAvailabilityData} from '../../../../redux/actions/graphs'
import * as moment from 'moment'
import DownloadDataButton
  from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import {Bar, ComposedChart, Label, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {timeRangeFileString, TPRNCAlarmsData} from '../../../../functions/shared'
import {AlertNoData} from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/SharedComponents/sharedComponents';
import {Card, CardBody, CardFooter, CardHeader} from '@mv-submodules/inplant-components-fe';
import {saveDataAsCSV} from "@mv-submodules/inplant-core-fe/functions";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface GraphAvailabilityStateProps {
  range: null | string
  data?: any
  lineData?: any
  hasErrored: boolean
  isLoading: boolean
  columns?: string[]
  lastDateUpdate: number | null
  dateStart: string | null
  dateEnd: string | null,
  measureUnits: object
  cliccable?: boolean
  TPRNCData: any
  TPRNCDataErrors: boolean
  TPRNCDataIsLoading: boolean
}

interface GraphAvailabilityDispatchProps {
  fetchData: () => Function
}

interface GraphAvailabilityOwnProps {
  // t: TranslationFunction
  history: any
  match: any
}

interface GraphAvailabilityOwnState {
  lastUpdate: number | null
  legend: any
  currentHover: string | null
}

const mapStateToProps = (state: any): GraphAvailabilityStateProps => ({
  range: state.dateFilters.range,
  data: state.graphAvailabilityData.data,
  lineData: state.graphAvailabilityData.lineData,
  hasErrored: state.graphAvailabilityData.hasErrored,
  isLoading: state.graphAvailabilityData.isLoading,
  columns: state.graphAvailabilityData.columns,
  lastDateUpdate: state.dateFilters.lastUpdate,
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
  measureUnits: state.config.generic.measureUnits || {},
  TPRNCData: state.graphTPRNCData.data,
  TPRNCDataErrors: state.graphTPRNCData.hasErrored,
  TPRNCDataIsLoading: state.graphTPRNCData.isLoading,
})

const mapDispatchToProps = (dispatch: Function): GraphAvailabilityDispatchProps => ({
  fetchData: () => dispatch(fetchAvailabilityData()),
})

type GraphAvailabilityProps = GraphAvailabilityStateProps &
  GraphAvailabilityDispatchProps &
  GraphAvailabilityOwnProps &
  WithTranslation

class GraphAvailabilityR extends React.Component<GraphAvailabilityProps, GraphAvailabilityOwnState> {
  constructor(props: GraphAvailabilityProps) {
    super(props)
    this.state = {
      lastUpdate: null,
      legend: null,
      currentHover: null,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseExit = this.handleMouseExit.bind(this)
    this.CustomTooltip = this.CustomTooltip.bind(this)
  }

  public componentWillMount() {
    this.props.fetchData()
  }

  public componentWillReceiveProps(newProps: GraphAvailabilityStateProps) {
    if (newProps.lastDateUpdate !== null && this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
      this.props.fetchData()
    }
  }

  private static explodeCamel(str: string) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (stri: string) => {
      return stri.toUpperCase()
    })
  }

  public render() {
    let legend
    const {dateStart, dateEnd, TPRNCData} = this.props

    legend = ['plannedLoss', 'unplannedLoss'].map((i: any, ind: any) => {
      if (i !== 'time') {
        return (
          <li
            className="filter-trigger"
            key={i}
            data-filter={i.replace(/ /g, '_')}
            onMouseEnter={() => this.handleMouseEnter(i)}
            onMouseLeave={() => this.handleMouseExit()}
          >
            <span className={'legend-color legend-' + i + '-color'}/>
            {GraphAvailabilityR.explodeCamel(i)}
          </li>
        )
      } else {
        return null
      }
    })

    let downloadData: string[][] =
      this.props.data && this.props.lineData
        ? this.props.data.map((e: any, i: number) => {
          return [
            moment(e[0]).format('YYYY/MM/DD'),
            e[1],
            e[2],
            this.props.lineData[i] ? this.props.lineData[i][1] : null,
          ]
        })
        : []

    downloadData = [
      ['time', 'planned loss', 'unplanned loss', 'availability', 'runtime'].map(
        (c: string) => `${c}${this.props.measureUnits[c] ? ` (${this.props.measureUnits[c]})` : ''}`,
      ),
    ].concat(downloadData)

    const timeFormat =
      this.props.range &&
      ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(this.props.range)
        ? 'MM/YYYY'
        : 'DD/MM'

    const data =
      this.props.data &&
      this.props.data.map((e: any, i: number) => {
        return {
          time: e[0],
          plannedLoss: e[1] / 60,
          unplannedLoss: e[2] / 60,
          availability: this.props.lineData[i] ? this.props.lineData[i][1] * 100 : null,
          runtime: this.props.lineData[i] ? this.props.lineData[i][2] / 60 : null,
        }
      })

    const tprncData = TPRNCAlarmsData(dateStart, dateEnd, data, TPRNCData)

    return (
      <div className={`DashboardWidget WidgetAvailabilityR WidgetH50 ${this.props.cliccable ? 'cliccable' : ''}`}>
        <Card marginBottom={3}>
          <CardHeader>
            {this.props.t('i4efficiency.cardTitle.avaibility')}
            {data && downloadData && (
              <DownloadDataButton
                saveDataAsCSV={saveDataAsCSV}
                data={downloadData}
                type={'csv'}
                label={'i4efficiency.download.buttonLabel'}
                className={'float-right'}
                fileName={'availability' + timeRangeFileString(this.props.dateStart, this.props.dateEnd)}
                addTime={false}
              />
            )}
          </CardHeader>
          <CardBody>
            <div className="container-fluid chart-container">
              <div className={"full-h"}>
                <Row verticalAlignment={"center"}>
                  <Column sm={12}>
                    <div className="text-center full-h">
                      <div className="chart-container" onClick={this.handleClick}>
                        {data ? (
                          <ResponsiveContainer>
                            <ComposedChart
                              width={700}
                              height={450}
                              data={data}
                              margin={{
                                top: 45,
                                right: 50,
                                left: 0,
                                bottom: 0,
                              }}
                              stackOffset="sign"
                              className={this.props.cliccable ? 'cliccable' : ''}
                            >
                              <XAxis
                                dataKey="time"
                                tickFormatter={e => {
                                  return moment(e).format(timeFormat)
                                }}
                                axisLine={false}
                              />
                              <YAxis axisLine={false} yAxisId={'left'}>
                                <Label position={'top'} offset={30}>
                                  time (min)
                                </Label>
                              </YAxis>

                              <YAxis
                                axisLine={false}
                                yAxisId={'right'}
                                orientation={'right'}
                                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                width={20}
                                interval={0}
                              >
                                <Label position={'top'} offset={30}>
                                  %
                                </Label>
                              </YAxis>

                              <Tooltip content={<this.CustomTooltip/>} cursor={{fill: 'none'}}/>
                              {
                                tprncData && tprncData.map(d => <ReferenceArea
                                  key={d.key}
                                  x1={d.x1}
                                  x2={d.x2}
                                  yAxisId={'right'}
                                  stroke={d.stroke}
                                  strokeOpacity={d.strokeOpacity}
                                  fill={d.fill}
                                  fillOpacity={d.fillOpacity}
                                />)
                              }
                              <Bar
                                dataKey="plannedLoss"
                                fill="#66c2a5"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                stackId="stack"
                                yAxisId={'left'}
                                onMouseEnter={() => this.handleMouseEnter('plannedLoss')}
                                onMouseLeave={() => this.handleMouseExit()}
                                opacity={
                                  this.state.currentHover !== null && this.state.currentHover !== 'plannedLoss' ? 0.3 : 1
                                }
                              />
                              <Bar
                                dataKey="unplannedLoss"
                                fill="#fc8d62"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                stackId="stack"
                                yAxisId={'left'}
                                onMouseEnter={() => this.handleMouseEnter('unplannedLoss')}
                                onMouseLeave={() => this.handleMouseExit()}
                                opacity={
                                  this.state.currentHover !== null && this.state.currentHover !== 'unplannedLoss' ? 0.3 : 1
                                }
                              />
                              <Line
                                dataKey={'availability'}
                                stroke="#d0743c"
                                yAxisId={'right'}
                                strokeWidth={2}
                                isAnimationActive={false}
                                dot={false}
                                opacity={this.state.currentHover !== null ? 0.3 : 1}
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        ) : (
                          <AlertNoData message={this.props.t('i4efficiency.fetch.noData')}/>
                        )}
                      </div>
                    </div>
                  </Column>

                </Row>
              </div>

            </div>
          </CardBody>
          <CardFooter
            textSizeSmall={true}
            textAlign={'right'}
            textMuted={true}
          >
            <ul className="legend float-left">{legend}</ul>
            <ul className="legend float-right">
              <li>
                <span className="legend-color legend-availability-color"/> AVAILABILIY
              </li>
            </ul>
          </CardFooter>
        </Card>
      </div>
    )
  }

  private handleClick() {
    this.props.history.push('/i4efficiency/availability-details')
  }

  private handleMouseEnter(category: string) {
    this.setState({
      currentHover: category,
    })
  }

  private handleMouseExit() {
    this.setState({
      currentHover: null,
    })
  }

  private CustomTooltip({active, payload, label, timeFormat}: any) {
    if (active && payload && payload.length > 0) {
      const payloadData = payload[0].payload
      return (
        <div className="recharts-default-tooltip">
          <ul>
            <li className="title">
              <strong>
                {moment(label).format(
                  this.props.range &&
                  ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(
                    this.props.range,
                  )
                    ? 'MM/YY'
                    : 'DD/MM',
                )}
              </strong>
            </li>
            {Object.keys(payloadData)
              .filter(e => e !== 'time')
              .map((line: any, index: number) => (
                <li key={index}>
                  <strong>
                    {GraphAvailabilityR.explodeCamel(line)}
                    {line === 'availability' ? ' (%)' : ''}
                  </strong>
                  <span className="value float-right">
                    {line !== 'time' ? payloadData[line].toFixed(2) : payloadData[line]}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      )
    }

    return null
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(GraphAvailabilityR)),
)
