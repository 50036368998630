import { Dispatch } from 'redux'
import { AnyAction } from 'redux'

import { ProductionListItem, LotCostListItem } from '../../types/ProductionListItem'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { consoleLog } from '../../../inplant-components-fe/mvfunctions/logs'

export const API = () => FetchWrapper.getInstance('production')

// const itemsData = require('../data/items.json') //tslint:disable-line
// const itemsAlarmsData = require('../data/alarms.json') //tslint:disable-line

// ----------------------------------------------------

export const productionHasErrored = (bool: boolean): AnyAction => {
  return {
    type: 'PRODUCTION_HAS_ERRORED',
    hasErrored: bool,
  }
}

// ----------------------------------------------------

export const productionIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'PRODUCTION_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------

export const productionItemsDataSuccess = (items: ProductionListItem[]): AnyAction => {
  return {
    type: 'PRODUCTION_ITEMS_FETCH_DATA_SUCCESS',
    items,
  }
}
// ----------------------------------------------------

export const productionFramesHasErrored = (bool: boolean): AnyAction => {
  return {
    type: 'PRODUCTION_FRAMES_HAS_ERRORED',
    hasErrored: bool,
  }
}

// ----------------------------------------------------

export const productionFramesIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'PRODUCTION_FRAMES_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------

export const productionFramesItemsDataSuccess = (items: ProductionListItem[]): AnyAction => {
  return {
    type: 'PRODUCTION_FRAMES_ITEMS_FETCH_DATA_SUCCESS',
    items,
  }
}

// ----------------------------------------------------

export const productionDownloadHasErrored = (bool: boolean): AnyAction => {
  return {
    type: 'PRODUCTION_DOWNLOAD_HAS_ERRORED',
    hasErrored: bool,
  }
}

// ----------------------------------------------------

export const productionDownloadIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'PRODUCTION_DOWNLOAD_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------

export const productionDownloadItemsDataSuccess = (items: ProductionListItem[]): AnyAction => {
  return {
    type: 'PRODUCTION_DOWNLOAD_ITEMS_FETCH_DATA_SUCCESS',
    items,
  }
}

// ----------------------------------------------------


export const itemsFetchData = (url?: string): Function => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(productionIsLoading(true))

    const itemsPromise = API().request('/production/lots')

    return Promise.all([itemsPromise])
      .then(values => {
        const items = values[0] as ProductionListItem[]

        dispatch(productionItemsDataSuccess(items))
        dispatch(productionIsLoading(false))
        return { items }
      })
      .then((data: any) => {
        return data
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
      })
  }
}

// added method to fetch the items with filters
export const itemsFetchDataFiltered = (filters: any): Function => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(productionIsLoading(true))
    const qs = filters
      ? Object.keys(filters)
        .filter(key => filters[key] !== null && filters[key] !== '')
        .map((key, index) => `${key}=${filters[key]}`)
      : []

    const itemsPromise = API().request('/production/lots?' + qs.join('&'))

    return Promise.all([itemsPromise])
      .then(values => {
        const items = values[0] as ProductionListItem[]

        dispatch(productionItemsDataSuccess(items))
        dispatch(productionIsLoading(false))
        return { items }
      })
      .then((data: any) => {
        return data
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
      })
  }
}

// added method to fetch the items with filters
export const itemsFetchDataFramesFiltered = (filters: any): Function => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(productionFramesIsLoading(true))
    const qs = filters
      ? Object.keys(filters)
        .filter(key => filters[key] !== null && filters[key] !== '')
        .map((key, index) => `${key}=${filters[key]}`)
      : []

    const itemsPromise = API().request('/production/frames/data?' + qs.join('&'))

    return Promise.all([itemsPromise])
      .then(values => {
        const items = values[0] as ProductionListItem[]

        dispatch(productionFramesItemsDataSuccess(items))
        dispatch(productionFramesIsLoading(false))
        return { items }
      })
      .then((data: any) => {
        return data
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
      })
  }
}

export const itemsFetchDownloadDataFiltered = (filters: any, useFrames?: boolean): Function => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(productionDownloadIsLoading(true))

    filters.offset = 0
    filters.fields = 'ALL'

    const qs = filters
      ? Object.keys(filters)
      .filter(key => filters[key] !== null && filters[key] !== '')
      .map((key, index) => `${key}=${filters[key]}`)
      : []

    const itemsPromise = API().request(`/production/${useFrames ? 'frames/data' : 'lots'}?${qs.join('&')}`)

    return Promise.all([itemsPromise])
    .then(values => {
      const items = values[0] as ProductionListItem[]

      dispatch(productionDownloadItemsDataSuccess(items))
      dispatch(productionDownloadIsLoading(false))
      return { items }
    })
    .then((data: any) => {
      return data
    })
    .catch((error: any) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
    })
  }
}

// ----------------------------------------------------
export const productionAlarmsHasErrored = (bool: boolean): AnyAction => {
  return {
    type: 'PRODUCTION_ALARMS_HAS_ERRORED',
    hasErrored: bool,
  }
}

// ----------------------------------------------------

export const productionAlarmsIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'PRODUCTION_ALARMS_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------

export const productionCostsIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'PRODUCTION_COSTS_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------

export const fetchLotIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'FETCH_LOT_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------
export const productionAlarmsItemsDataSuccess = (items: ProductionListItem[]): AnyAction => {
  return {
    type: 'PRODUCTION_ALARMS_ITEMS_FETCH_DATA_SUCCESS',
    items,
  }
}

// ----------------------------------------------------

export const productionCostsItemsDataSuccess = (items: LotCostListItem[]): AnyAction => {
  return {
    type: 'PRODUCTION_COSTS_ITEMS_FETCH_DATA_SUCCESS',
    items,
  }
}

// ----------------------------------------------------

export const fetchLotSuccess = (lot: ProductionListItem): AnyAction => {
  return {
    type: 'FETCH_LOT_SUCCESS',
    lot,
  }
}

// ----------------------------------------------------

export const itemsAlarmsFetchData = (id: number, useFrames?: boolean, dateStart?: string, dateEnd?: string): Function => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(productionAlarmsIsLoading(true))

    const itemsPromise = API().request(
      useFrames ?
        `/production/alarms?dateStart=${dateStart}&dateEnd=${dateEnd}` :
        `/production/lots/${id}/alarms-grouped`
    )

    return Promise.all([itemsPromise])
    .then(values => {
      const items = values[0] as ProductionListItem[]

      dispatch(productionAlarmsItemsDataSuccess(items))
      dispatch(productionAlarmsIsLoading(false))
      return { items }
    })
    .then((data: any) => {
      return data
    })
    .catch((error: any) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
    })
  }
}

// ----------------------------------------------------

export const itemsCostsFetchData = (id: number): Function => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(productionCostsIsLoading(true))

    const itemsPromise = API().request('/production/lots/' + id + '/costs')

    return Promise.all([itemsPromise])
      .then(values => {
        const items = values[0] as LotCostListItem[]

        dispatch(productionCostsItemsDataSuccess(items))
        dispatch(productionCostsIsLoading(false))
        return { items }
      })
      .then((data: any) => {
        return data
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
      })
  }
}

// ----------------------------------------------------

export const fetchLot = (id: number, frames?: boolean): Function => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(fetchLotIsLoading(true))
    consoleLog('frames', frames)
    return API().request(`/production/${frames ? 'frames' : 'lots'}/${id}`)
      .then((value: any) => {
        const lot = value as ProductionListItem

        dispatch(fetchLotSuccess(lot))
        dispatch(fetchLotIsLoading(false))
        return { lot }
      })
      .then((data: any) => {
        return data
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
      })
  }
}

export const fetchProductSheet = (id: string): Promise<any> => {
  return API().request(`/production/lots/${id}/sheet`)
    .then((data: any) => {
      return data
    })
    .catch((error: any) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
    })
}

// ----------------------------------------------------
