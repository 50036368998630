import * as React from 'react'
import { withRouter } from 'react-router'
import GraphPlanRun from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/GraphPlanRun/GraphPlanRun'
import ButtonsConsumptions from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/ButtonsConsumptions/ButtonsConsumptions'
import TimeEventsDownload from '@mv-submodules/inplant-core-fe/ui/components/widgets/TimeEvents/TimeEvents'
import { connect } from 'react-redux'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { isJSON } from '@mv-submodules/inplant-core-fe/functions'
import DateRangeFilters from '@mv-submodules/inplant-core-fe/ui/components/widgets/dateFiltersBase/components/DateRangeFilters'
import PlantStatusOverTime
  from "@mv-submodules/inplant-plantstatusovertime-fe-imel/ui/components/widgets/PlantStatusOvertTime/PlantStatusOverTime";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { Link } from 'react-router-dom'
import { mvDate } from '../../../inplant-components-fe/mvfunctions/helpers/dateHelper'

const APIInflux = () => FetchWrapper.getInstance('influx')

interface DashboardStateProps {
  hasErrored: boolean
  isLoading: boolean
}

interface DashboardOwnProps {
  history: any
}

interface DashboardStateProps {
  range: string
  bestOfConfig: any
}

interface OwnState {
  bestOf: any[]
}

const mapStateToProps = (state: any): DashboardStateProps =>
  ({
    range: state.dateFilters.range,
    bestOfConfig:
      state.config.dashboard && state.config.dashboard.bestOfConfig && isJSON(state.config.dashboard.bestOfConfig)
        ? JSON.parse(state.config.dashboard.bestOfConfig)
        : null,
  } as DashboardStateProps)

type DashboardProps = DashboardStateProps & DashboardOwnProps

class Dashboard extends React.Component<DashboardProps, OwnState> {
  constructor(props: DashboardProps) {
    super(props)

    this.state = {
      bestOf: [],
    }

    this.handlePlanRunClick = this.handlePlanRunClick.bind(this)
    this.getBestOf = this.getBestOf.bind(this)
  }

  public componentWillMount(): void {
    this.getBestOf()
  }

  public render() {
    if (this.props.hasErrored) {
      return (
        <div key={Date.now()} className="col-lg text-center">
          <i className="fas fa-2x fa-exclamation-triangle text-danger" />
        </div>
      )
    }
    if (this.props.isLoading) {
      return (
        <div key={Date.now()} className="col-lg text-center">
          <i className="fas fa-2x fa-spin fa-circle-notch" />
        </div>
      )
    }

    return (
      <React.Fragment>
        <header>
          <Row spacing={{vertical:false,horizontal:false}}>
            <Column sm={12} md={8} >
              <div className="d-flex flex-row justify-content-start align-items-center">
                <h1>Dashboard</h1>
              </div>
            </Column>
          </Row>
        </header>
        <div className="content">
          <div className="row" id="filters-wrapper">
            <div className="col-12 mb-3">
              <div className="card bg-light">
                <div className="card-body">
                  <Row noGutters={true} verticalAlignment={"center"} spacing={{vertical:false,horizontal:false}}>
                    <TimeEventsDownload />
                    <DateRangeFilters />
                  </Row>
                </div>
              </div>
            </div>
          </div>
          {/* nascosto */}
          <Row spacing={{vertical:false,horizontal:false}}>
            <Column xs={12} >
              <Row spacing={{vertical:false,horizontal:false}}>
                <Column lg={10} >
                  <Row spacing={{vertical:false,horizontal:false}}>
                    <div className='col'>
                      <div className="card mb-3">
                        <div className="card-header">PLANT 3d VIEWER</div>
                        <div className="card-body">
                          <Link to={'/plant-3d-viewer?exitRoute=/dashboard'} className="plant-3d-viewer-link-bg text-center">
                            <button className="btn btn-primary btn-lg">3D View</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row spacing={{vertical:false,horizontal:false}}>
                    <div className="col cliccable" onClick={this.handlePlanRunClick}>
                      <GraphPlanRun cliccable={true}/>
                    </div>
                  </Row>
                  <PlantStatusOverTime range={this.props.range} />
                </Column>
                <Column lg={2} >
                  <div id="widget-area-dashboard-topright" />
                  <ButtonsConsumptions />
                </Column>
              </Row>
            </Column>
          </Row>

          <div className="clearfix" />

          {this.props.bestOfConfig && (
            <Row spacing={{vertical:false,horizontal:false}}>
              <Column lg={8}>
                <div className="DashboardWidget WidgetBestOf">
                  <div className="card mb-3">
                    <div className="card-header">BEST OF</div>
                    <div className="card-body">
                      <div className="container-fluid">
                        <Row spacing={{vertical:false,horizontal:false}}>
                          {this.state.bestOf &&
                          this.props.bestOfConfig &&
                            this.state.bestOf.map((b: any) => (
                              <div className="col-md " key={b.measure}>
                                <span className="best-label mx-1">
                                  <i className="fa fa-trophy" /> {this.props.bestOfConfig.metrics[b.measure].label}
                                </span>
                                <span className="best-day mx-1">{mvDate(b.time).format('DD-MM-YYYY')}</span>
                                <span className="best-value mx-1">
                                  {this.props.bestOfConfig.metrics[b.measure].unit.indexOf('%') > -1
                                    ? b.value.toFixed(2)
                                    : b.value}
                                  {this.props.bestOfConfig.metrics[b.measure].unit}
                                </span>
                              </div>
                            ))}
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Column>
            </Row>
          )}
        </div>
      </React.Fragment>
    )
  }

  private async getBestOf() {
    // non è visibile perchè manca la configurazione
    if (this.props.bestOfConfig && this.props.bestOfConfig.metrics && this.props.bestOfConfig.fromDate) {
      const requests = await Promise.all(
        Object.keys(this.props.bestOfConfig.metrics).map((m: any) => {
          return APIInflux().request(
            '/influx/query?q=SELECT max("' +
              this.props.bestOfConfig.metrics[m].measure +
              '")  as "' +
              m +
              '" FROM "' +
              this.props.bestOfConfig.metrics[m].serie +
              '" where ' +
              (this.props.bestOfConfig.metrics[m].limit100
                ? this.props.bestOfConfig.metrics[m].measure + ' < 1 and'
                : '') +
              ' ' +
              (this.props.bestOfConfig.fromDate ? "time >= '" + this.props.bestOfConfig.fromDate + "'" : '')
          )
        })
      )
      const bestOf: any[] = []

      requests.map((r: any) => {
        if (r && r.results && r.results[0] && r.results[0].series && r.results[0].series[0]) {
          const measure = r.results[0].series[0]
          if (measure.values[0]) {
            bestOf.push({
              measure: measure.columns[1],
              time: measure.values[0][0],
              value: measure.values[0][1] * this.props.bestOfConfig.metrics[measure.columns[1]].multiplier,
            })
          }
        }
      })

      this.setState({ bestOf })

    }
  }

  private handlePlanRunClick() {
    this.props.history.push('/i4efficiency')
  }
}

// export default withRouter<any, any>(Dashboard)
export default withRouter<any, any>(
  connect(
    mapStateToProps,
    null
  )(Dashboard)
)
