import * as React from 'react'
import * as moment from 'moment'
import { efficiencyAlarmsFetchData } from '../../../../redux/actions/alarms'
import { AlarmListItem } from '../../../../types/AlarmListItem'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import DownloadDataButton from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import { Modal, Table } from '@mv-submodules/inplant-components-fe'
import { saveDataAsCSV } from '@mv-submodules/inplant-core-fe/functions'
import Row from '../../../../../inplant-components-fe/ui/components/Grid/Row'
import Column from '../../../../../inplant-components-fe/ui/components/Grid/Column'

interface StateProps {
  date: string
  closeModalFn: () => void
}

interface OwnState {
  alarms: any[]
}

interface ProductionAlarmsStateProps {
  items: AlarmListItem[]
  hasErrored: boolean
  isLoading: boolean
}

interface ProductionAlarmsDispatchProps {
  fetchData: Function
}

const mapStateToProps = (state: any): ProductionAlarmsStateProps => {
  return {
    items: state.availabilityDetailsData.itemsAlarms,
    hasErrored: state.availabilityDetailsData.hasAlarmsErrored,
    isLoading: state.availabilityDetailsData.isAlarmsLoading,
  }
}

const mapDispatchToProps = (dispatch: Function): ProductionAlarmsDispatchProps => {
  return {
    fetchData: (date: string) => dispatch(efficiencyAlarmsFetchData(date)),
  }
}

type Props = ProductionAlarmsStateProps & ProductionAlarmsDispatchProps & StateProps & WithTranslation

class AlarmModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      alarms: [],
    }
  }

  public componentWillMount(): void {
    this.props.fetchData(this.props.date).then(() => {
      this.setState({
        alarms: this.props.items,
      })
    })
  }

  public render() {
    const msgClass = {
      66: 'emergency stop',
      68: 'on demand stop',
      1: 'process alarm',
      65: 'process stop',
      64: 'safety stop',
      2: 'warning',
    }

    const colums = [
      {
        Header: 'Date',
        accessor: 'iTime',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {moment(row.value).isValid() ? moment(row.value).format('YYYY-MM-DD') : '--'}
          </div>
        ),
        minWidth: 40,
      },
      {
        Header: 'In',
        accessor: 'iTime',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {moment(row.value).isValid() ? moment(row.value).format('HH:mm:ss') : '--'}
          </div>
        ),
        minWidth: 40,
      },
      {
        Header: 'Accepted',
        accessor: 'aTime',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {moment(row.value).isValid() ? moment(row.value).format('HH:mm:ss') : '--'}
          </div>
        ),
        minWidth: 40,
      },
      {
        Header: 'Out',
        accessor: 'oTime',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {moment(row.value).isValid() ? moment(row.value).format('HH:mm:ss') : '--'}
          </div>
        ),
        minWidth: 40,
      },
      {
        Header: 'Type',
        accessor: 'msgClass',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {msgClass[row.value]}
          </div>
        ),
        minWidth: 60,
      },
      {
        Header: 'Text',
        accessor: 'message',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {row.value.replace(/"\((.*)\) /, '').replace(/ \[(.*).\]"/, '')}
          </div>
        ),
      },
    ]

    if (!this.state.alarms) {
      return null
    }

    if (
      this.state.alarms &&
      this.state.alarms[0] &&
      (this.state.alarms[0].hasOwnProperty('cabinet') || this.state.alarms[0].hasOwnProperty('component'))
    ) {
      colums.push({
        Header: 'Cabinet / component',
        accessor: 'cabinet',
        Cell: (row: any) => {
          return (
            <div
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {row.original.cabinet} {row.original.component ? '/ ' + row.original.component : ''}
            </div>
          )
        },
        minWidth: 60,
      })
    }

    let downloadDataSrc
    let downloadData

    if (this.state.alarms) {
      downloadDataSrc = this.state.alarms.map(alarm => {
        return [
          moment(alarm.iTime).isValid() ? moment(alarm.iTime).format('HH:mm:ss') : '--',
          moment(alarm.aTime).isValid() ? moment(alarm.aTime).format('HH:mm:ss') : '--',
          moment(alarm.oTime).isValid() ? moment(alarm.oTime).format('HH:mm:ss') : '--',
          msgClass[alarm.msgClass],
          alarm.message.replace(/"\((.*)\) /, '').replace(/ \[(.*).\]"/, ''),
          alarm.cabinet + (alarm.component ? ' / ' + alarm.component : ''),
        ]
      })

      downloadData = [['In', 'Accepted', 'Out', 'Type', 'Text', 'Cabinet / Component']].concat(downloadDataSrc)
    }

    return (
      <Modal
        visible={true}
        title={this.props.t('i4efficiency.modal.alarms') + this.props.date}
        width={75}
        onClose={this.props.closeModalFn}
        closeLabel={this.props.t('i4efficiency.modal.labels.close')}
      >
        <Row spacing={{ vertical: false, horizontal: false }}>
          <Column xs={12}>
            {downloadData && downloadDataSrc && downloadDataSrc.length > 0 && (
              <DownloadDataButton
                saveDataAsCSV={saveDataAsCSV}
                data={downloadData}
                type={'csv'}
                addTime={false}
                label={'production.download.buttonLabel'}
                fileName={'production_alarms_' + moment(this.props.date).format('YYYY-MM-DD')}
                className="btn-over-tabs float-right mb-3"
              />
            )}
          </Column>
          <Column xs={12}>
            <Table
              columns={colums}
              data={this.state.alarms}
              className="-striped production-alarms-table"
              showPagination={false}
              minRows={1}
              isFetching={this.props.isLoading}
              noDataText={this.props.t('i4efficiency.fetch.noData')}
            />
          </Column>
        </Row>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AlarmModal))
