import * as React from 'react'
import { capitalizeFirstLetter } from '../../../../functions/shared'
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup'
import { Card, CardBody } from '@mv-submodules/inplant-components-fe'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import { MaintenancePageFilters } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface Option {
  value: any
  name: string
}

interface Props {
  filters: MaintenancePageFilters[]
  onSelectedChanged?: (selected: { slug: string; selected: string[] }) => void
}

interface DefaultProps {
  showFiltersText: string
}

type PropsWithDefaults = Props & DefaultProps

class CalendarFilters extends React.Component<Props> {
  public static defaultProps: DefaultProps = {
    showFiltersText: 'Show filter',
  }

  constructor(props: Props) {
    super(props)
    this.getTitle = this.getTitle.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.getSelected = this.getSelected.bind(this)
    this.handleSelectedChange = this.handleSelectedChange.bind(this)
  }

  private getTitle(title: string | null) {
    if (title !== null) {
      return capitalizeFirstLetter(title)
    }
    return ''
  }

  private getOptions(filter: MaintenancePageFilters): Option[] {
    if (filter.values !== null) {
      return filter.values as Option[]
    }
    return []
  }

  private getSelected(filter: MaintenancePageFilters): string[] {
    if (filter.selected !== null) {
      return filter.selected as string[]
    }
    return []
  }

  private handleSelectedChange(name: string, selected: string[]) {
    const { onSelectedChanged } = this.props
    if (onSelectedChanged) {
      const tmp: any = {}
      tmp.slug = name
      tmp.selected = selected
      onSelectedChanged(tmp)
    }
  }

  public render() {
    const { showFiltersText, filters } = this.props as PropsWithDefaults

    if (filters.length === 0) {
      return null
    }

    return (
      <Card marginBottom={3}>
        <a
          href="#collapseFilters"
          className="card-header text-uppercase"
          data-toggle="collapse"
          aria-expanded="false"
          aria-controls="collapseFilters"
        >
          <IconComponent icon={'angle-right'} />
          {showFiltersText}
        </a>
        <div className="collapse" id="collapseFilters">
          <CardBody>
            <Row spacing={{horizontal:false,vertical:false}}>
              {filters.map((filter: MaintenancePageFilters, fIndex: number) => (
                <div key={fIndex} className="col-md">
                  <CheckboxGroup
                    key={fIndex}
                    title={this.getTitle(filter.title)}
                    options={this.getOptions(filter)}
                    selected={this.getSelected(filter)}
                    onSelectedChanged={selected => this.handleSelectedChange(filter.name, selected)}
                  />
                </div>
              ))}
            </Row>
          </CardBody>
        </div>
      </Card>
    )
  }
}

export default CalendarFilters
