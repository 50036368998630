import * as moment from 'moment-timezone'

const saveDataAsCSV = (data: string | string[][], fileName?: string, fileType?: string, addTime: boolean = true) => {
  let csvContent = ''
  if (Array.isArray(data)) {
    data.forEach((rowArray: any) => {
      rowArray.forEach((e: any, i: number) => {
        rowArray[i] = Number(e) === e && e % 1 !== 0 ? e.toString().replace('.', ',') : e
      })
      const row = rowArray.join(';')
      csvContent += row + '\r\n'
    })
  } else {
    csvContent = data
  }

  const a = document.createElement('a')
  a.innerHTML = 'Click to download'

  // IE11 & Edge
  if ((navigator as any).msSaveBlob) {
    const csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    a.href = '#'
    a.addEventListener('click', () => {
      (navigator as any).msSaveBlob(
        csvData,
        (fileName || 'download') +
          (addTime ? '_' + moment().format('YYYY-MM-DD_HH-mm-ss') : '') +
          '.' +
          (fileType || 'csv')
      )
    })
  } else {
    // In FF link must be added to DOM to be clicked
    a.href = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csvContent)
    a.target = '_blank'
    a.download =
      (fileName || 'download') +
      (addTime ? '_' + moment().format('YYYY-MM-DD_HH-mm-ss') : '') +
      '.' +
      (fileType || 'csv')
  }

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export { saveDataAsCSV }
