import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { History } from 'history'
import { withTranslation, WithTranslation } from 'react-i18next'
import { fetchData } from '../../../../redux/actions'
import {
  Button,
  ButtonGroupHeader,
  Filters,
  MainPageContent,
  PageHeader,
  Table,
  TableColumn,
} from '@mv-submodules/inplant-components-fe'
import { User, UserRole } from '@mv-submodules/inplant-user-fe/types/user'
import { exportData } from '@mv-submodules/inplant-user-fe/redux/actions/user'
import moment from 'moment'
import DownloadCsvButton from '@mv-submodules/inplant-components-fe/ui/components/Button/DownloadCsvButton'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { download } from '@mv-submodules/inplant-components-fe/mvfunctions/download'


interface StateProps {
  fetching: boolean
  error: Error
  showNewItemMessage: boolean
  items: User[]
  forbiddenActions: string[]
}

interface DispatchProps {
  fetchData: () => Function
  exportData: () => Promise<any>
}

interface OwnStateProps {
  roles: UserRole[]
  filters: { [k: string]: string | number | null }
  page: number
  isExporting: boolean
}

interface OwnProps {
  // t: TranslationFunction;
  history: History
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any) => ({
  fetching: state.user.index.fetching,
  error: state.user.index.error,
  items: state.user.index.data,
  showNewItemMessage: state.user.index.showNewItemMessage,
  forbiddenActions: state.auth.user.forbiddenActions,
})

const mapDispatchToProps = (dispatch: Function) => ({
  fetchData: () => dispatch(fetchData()),
  exportData: () => dispatch(exportData())
})

class ListPageView extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      roles: [],
      filters: {},
      page: 0,
      isExporting: false
    }

    this.handleClick = this.handleClick.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.filterItems = this.filterItems.bind(this)
    this.exportData = this.exportData.bind(this)
  }

  public componentWillMount() {
    this.props.fetchData()
  }

  public componentWillReceiveProps(newProps: Props) {
    const roles: any[] = []
    if (newProps.items) {
      newProps.items.forEach((user) => {
        if (user.roles) {
          user.roles.forEach((userRole) => {
            if (!roles.find((role: any) => role.id === userRole.id)) {
              roles.push(userRole)
            }
          })
        }
        // if (item.plants) {
        //   item.plants.forEach((plantKey: string, plantNum: number) => {
        //     if (!plants.find((plant: any) => plant.id === item.plants[plantNum].id)) {
        //       plants.push(item.plants[plantNum])
        //     }
        //   })
        // }
      })
    }

    this.setState({ roles })
  }

  public render() {
    if (this.props.error) {
      return (
        <Column key={Date.now()} lg={12}>
          <div className=" text-center mt-5">
            <i className="fas fa-2x fa-exclamation-triangle text-danger"/>
          </div>
        </Column>

      )
    }


    const alert = this.props.showNewItemMessage ? (
      <div className="alert alert-secondary" role="alert">
        {this.props.t('user.index.newUserCreated')}
      </div>
    ) : null

    const columns: TableColumn[] = [
      {
        Header: this.props.t('user.index.displayName'),
        accessor: 'displayName',
        Cell: (item: any) => {
          return <React.Fragment>{item.value}</React.Fragment>
        },
        minWidth: 50,
      },
      {
        Header: this.props.t('user.index.username'),
        accessor: 'username',
        Cell: (item: any) => {
          return <React.Fragment>{item.value}</React.Fragment>
        },
        minWidth: 50,
      },
      {
        Header: this.props.t('user.index.email'),
        accessor: 'email',
        Cell: (item: any) => {
          return <React.Fragment>{item.value}</React.Fragment>
        },
        minWidth: 50,
      },
      {
        Header: this.props.t('user.index.roles'),
        accessor: 'roles',
        Cell: (item: any) => {
          return <React.Fragment>{item.value.map((role: any) => role.name).join(', ')}</React.Fragment>
        },
        minWidth: 50,
        filterMethod: (filter: any, row: any) => {
          if (filter.value === 'all') {
            return true
          }

          const found = row[filter.id].filter((role: any) => {
            return role.name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
          })
          return found.length > 0
        },
      },
      {
        Header: this.props.t('user.index.enabled'),
        accessor: 'enabled',
        Cell: (item: any) => {
          return (
            <React.Fragment>
              {item.value ? this.props.t('user.index.enabled') : this.props.t('user.index.disabled')}
            </React.Fragment>
          )
        },
        minWidth: 50,
      },
    ]
    return (
      <div className="inplant-user inplant-user-list">
        <PageHeader
          title={this.props.t('user.index.title')}
          rightButtons={
            <ButtonGroupHeader>
              <DownloadCsvButton
                disabled={!(this.props.items.length > 0)}
                isLoading={this.props.fetching || this.state.isExporting}
                label={this.props.t('user.index.exportData')}
                onClick={() => this.exportData()}
              />
              {!this.props.forbiddenActions.includes('user.user.create') &&
              <Button variant="primary" label={this.props.t('user.index.new')} onClick={() => {
                this.props.history.push(('/user/new'))
              }}
              />}
            </ButtonGroupHeader>
          }
        />

        <MainPageContent>
          <Filters fields={[
            {
              customSize: 3,
              id: 'displayName',
              label: this.props.t('user.index.displayName'),
              name: 'displayName',
              onChange: (name, value) => this.onSearchChange(value && value as string || '', 'displayName'),
              type: 'SearchInput',
              disabled: this.props.fetching,
              value: '',
            },
            {
              customSize: 2,
              id: 'username',
              label: this.props.t('user.index.username'),
              name: 'username',
              onChange: (name, value) => this.onSearchChange(value && value as string || '', 'username'),
              type: 'SearchInput',
              disabled: this.props.fetching,
              value: '',
            },
            {
              customSize: 3,
              id: 'email',
              label: this.props.t('user.index.email'),
              name: 'email',
              onChange: (name, value) => this.onSearchChange(value && value as string || '', 'email'),
              type: 'SearchInput',
              disabled: this.props.fetching,
              value: '',
            },
            {
              customSize: 2,
              id: 'roles',
              label: this.props.t('user.index.roles'),
              name: 'roles',
              options: {
                defaultOptionLabel: this.props.t('user.index.table.showAll'),
                items: this.state.roles &&
                  this.state.roles.map((role) => (
                    { label: role.name, value: role.id }
                  )) || [],
              },
              onChange: (name, value) => this.onSearchChange(value && value as string || '', 'roles'),
              type: 'Select',
              disabled: this.props.fetching,
              value: '',
            },
            {
              customSize: 2,
              id: 'enabled',
              label: this.props.t('user.index.enabled'),
              name: 'enabled',
              options: {
                defaultOptionLabel: this.props.t('user.index.table.showAll'),
                items: [
                  {
                    value: 'true',
                    label: this.props.t('user.index.enabled'),
                  },
                  {
                    value: 'false',
                    label: this.props.t('user.index.disabled'),
                  },
                ],
              },
              onChange: (name, value) => this.onSearchChange(value && value as string || '', 'enabled'),
              type: 'Select',
              value: '',
            },
          ]}/>

          {alert}

          <Table
            className="user-table -striped -highlight"
            columns={columns}
            data={this.filterItems(this.props.items)}
            getTrProps={(state: any, rowInfo: any) => {
              return {
                onClick: (e: MouseEvent, handleOriginal: any) => {
                  if (!this.props.forbiddenActions.includes('user.user.view')) {
                    this.handleClick(rowInfo.original.id)

                    if (handleOriginal) {
                      handleOriginal()
                    }
                  }
                },
              }
            }}
            isFetching={this.props.fetching}
            manualIsFetching={true}
            noDataText={this.props.t('user.index.table.noData')}
            ofText={this.props.t('user.index.table.of')}
            page={this.state.page}
            pageSize={10}
            onPageChange={this.handlePageChange}
            pageText={this.props.t('user.index.table.page')}
            rowsText={this.props.t('user.index.table.rows')}
            showPaginationTop={true}
          />
        </MainPageContent>
      </div>
    )
  }

  private handleClick(id: number) {
    this.props.history.push('/user/edit/' + id)
    return false
  }

  private onSearchChange(search: string, field: string) {
    const filters = this.state.filters
    filters[field] = search
    this.setState({ filters, page: 0 })
  }

  private handlePageChange(page: number) {
    this.setState({ page })
  }

  private filterItems(users: User[]) {
    const { filters } = this.state
    const activeFilters = filters && Object.keys(filters).filter(f => filters[f] && filters[f] !== '') || []
    return users.filter(user => {
        let valid = true
        activeFilters.forEach(filterName => {
          if (
            (user[filterName] || filterName === "enabled") &&
            (
              !['roles', 'enabled'].includes(filterName) && (user[filterName].toLowerCase().indexOf(String(filters[filterName]).toLowerCase()) === -1) ||
              filterName === 'roles' && !user.roles.find(userRole => userRole.id === filters[filterName]) ||
              filterName === 'enabled' && (
                user.enabled && (filters[filterName] === 'false') ||
                !user.enabled && (filters[filterName] === 'true')
              )
            )
          ) {
            valid = false
          }
        })
        return valid
      },
    )
  }

  private exportData() {
    if (!this.state.isExporting) {
      this.setState({ isExporting: true })
      this.props.exportData()
        .then((result: any) => {
          // TODO take name from result['content-disposition']
          download(result.result, `users-${moment().format('YYYYMMDD')}.csv`)
        })
        .catch((error: any) => {
          console.log(error) //tslint:disable-line
        })
        .finally(() => {
          this.setState({ isExporting: false })
        })
    }
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ListPageView)),
)
