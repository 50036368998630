// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// from 'react-table/src/utils'
export const normalizeComponent = (Comp: any, params = {}, fallback = Comp) =>
  typeof Comp === 'function' ? (
    Object.getPrototypeOf(Comp).isReactComponent ? (
      <Comp {...params} />
    ) : (
      Comp(params)
    )
  ) : (
    fallback
  )
