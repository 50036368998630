// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import {Link} from 'react-router-dom'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

interface Props {
    className?: string
    backUrl?: string,
    onClick?: () => void
}

const BackButton = (props: Props) => {
    let className = 'btn back-button text-dark ml-0 pl-0'
    if (props.className) {
        className += ` ${props.className}`
    }

    // TODO: use default button
    return props.backUrl ? (
        <Link className={className} to={props.backUrl}>
            <IconComponent icon={"angle-left"} size="lg"/>
        </Link>
    ) : (
        <button
            type="button"
            className="btn btn-link text-muted ml-0 pl-0"
            onClick={() => {
                if (props.onClick) {
                    props.onClick()
                }
            }}>
            <IconComponent icon={"angle-left"} size="lg"/>
        </button>
    )
}

export default BackButton
