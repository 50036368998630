import { IconDefinition, faWrench } from '@fortawesome/free-solid-svg-icons'
import ProductionList from './components/views/List/ListPageView'

export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey?: string
  icon?: IconDefinition
  iconOverride?: string
  visible: boolean
  component?: any
  children?: RouteProps[]
  aclActionKey?: string
}

const routes: RouteProps = {
  path: '/production',
  i18nkey: 'production.module',
  icon: faWrench,
  visible: true,
  aclActionKey: 'production.production.list',
  children: [
    {
      path: '/production',
      exact: true,
      i18nkey: 'production.module',
      component: ProductionList,
      visible: false,
      aclActionKey: 'production.production.list',
    },
  ],
}

export default routes
