import { AnyAction } from 'redux'

const indexInitialState = {
  data: [],
  error: null,
  fetching: false,
  lastUpdate: null,
}

export const smartdocs = (state = indexInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return Object.assign({}, { ...state })
    case 'SMARTDOCS_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          error: false,
          fetching: action.fetching,
        }
      )
    case 'SMARTDOCS_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          data: action.data,
          error: false,
          fetching: false,
        }
      )
    case 'SMARTDOCS_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          data: [],
          error: action.payload,
          fetching: false,
        }
      )
    default:
      return state
  }
}
