import * as React from 'react'
import { connect } from 'react-redux'
import { TPRNCAlarmsFetchData } from '../../../../redux/actions/plantStatusOverTime'

interface TPRNCWarnAreaStateProps {
  dateStart: string | null
  dateEnd: string | null
  data: any
  TPRNCData: any
  TPRNCDataErrors: boolean
  TPRNCDataIsLoading: boolean
}

interface TPRNCWarnAreaDispatchProps {
  TPRNCAlarmsFetchData: (dateStart: string, dateEnd: string) => Function
}

const mapStateToProps = ( state: any ): TPRNCWarnAreaStateProps => ({
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
  data: state.graphPlanRunOeeData.data,
  TPRNCData: state.graphTPRNCData.data,
  TPRNCDataErrors: state.graphTPRNCData.hasErrored,
  TPRNCDataIsLoading: state.graphTPRNCData.isLoading,
})


const mapDispatchToProps = ( dispatch: Function ): TPRNCWarnAreaDispatchProps => {
  return {
    TPRNCAlarmsFetchData: (dateStart, dateEnd) => dispatch(TPRNCAlarmsFetchData(dateStart, dateEnd)),
  }
}

type Props = TPRNCWarnAreaDispatchProps & TPRNCWarnAreaStateProps

// this is a data loader only
class TPRNCWarnAreaLoader extends React.Component<Props, any> {
  public componentDidMount(): void {
    if (
      !this.props.TPRNCDataIsLoading  &&
      this.props.dateStart &&  this.props.dateEnd
    ) {
      this.props.TPRNCAlarmsFetchData(this.props.dateStart, this.props.dateEnd)
    }
  }

  public componentWillReceiveProps( nextProps: Props ): void {
    if (
      nextProps.dateStart && nextProps.dateEnd &&
      !this.props.TPRNCDataIsLoading &&
      (
        !this.props.TPRNCData ||
        this.props.dateStart !== nextProps.dateStart ||
        this.props.dateEnd !== nextProps.dateEnd
      )
    ) {
      this.props.TPRNCAlarmsFetchData(nextProps.dateStart, nextProps.dateEnd)
    }
  }


  public render() {
    return null
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TPRNCWarnAreaLoader)
