import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import Folder from './Folder'

export interface StateProps {
  data: any
}

export interface OwnProps extends RouteComponentProps<any> {
  data: any
  selectedFile: any
  selectFile: (fileData: any) => Function
  // t: TranslationFunction
}

export type Props = StateProps & OwnProps & WithTranslation // DispatchProps &

class FileBrowser extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    return (
      <ul className="list-group list-group-flush">
        <div className="list-folders">
          {this.props.data &&
            Object.keys(this.props.data)
              .sort()
              .map(folderName => (
                <Folder
                  data={this.props.data[folderName]}
                  folderName={folderName}
                  key={folderName}
                  selectedFile={this.props.selectedFile}
                  selectFile={this.props.selectFile}
                />
              ))}
        </div>
      </ul>
    )
  }
}

export default withRouter<any, any>(withTranslation()(FileBrowser))
