import { AnyAction } from 'redux'
import {
  GraphGaugesState,
  initialGraphGaugesState,
  GraphPlanRunState,
  initialGraphPlanRunState,
  GraphPlanRunOeeState,
  initialGraphPlanRunOeeState,
  GraphConsumptionState,
  initialGraphConsumptionState,
  GraphQualityState,
  initialGraphQualityState,
  QualityDetailsState,
  initialQualityDetailsState,
  GraphAvailabilityState,
  initialGraphAvailabilityState,
  AvailabilityDetailsState,
  initialAvailabilityDetailsState,
  GraphPerformanceState,
  initialGraphPerformanceState,
  TPRNCAlarmsState,
  initialTPRNCAlarmsState,
} from '../index'

// import * as moment from 'moment';

export function graphGaugesData(state: GraphGaugesState = initialGraphGaugesState, action: AnyAction) {
  switch (action.type) {
    case 'FETCH_OEE_SUCCESS':
      const data =
        action.data && action.data.results && action.data.results[0].series ? action.data.results[0].series[0] : null
      return {
        ...state,
        data: [data ? data.values[0][1] : null, data ? data.values[0][2] : null, data ? data.values[0][3] : null],
        columns: [data ? data.columns[1] : null, data ? data.columns[2] : null, data ? data.columns[3] : null],
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_OEE_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_OEE_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_OEE_NITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
        hasErrored: action.hasErrored,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function graphPlanRunData(state: GraphPlanRunState = initialGraphPlanRunState, action: AnyAction) {
  switch (action.type) {
    case 'FETCH_PLANRUN_SUCCESS':
      const data =
        action.data && action.data.results && action.data.results[0].series ? action.data.results[0].series[0] : null

      return {
        ...state,
        data: data ? data.values : null,
        columns: data ? data.columns : null,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_PLANRUN_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_PLANRUN_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_PLANRUN_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
        hasErrored: action.hasErrored,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function graphPlanRunOeeData(state: GraphPlanRunOeeState = initialGraphPlanRunOeeState, action: AnyAction) {
  switch (action.type) {
    case 'FETCH_PLANRUNOEE_SUCCESS':
      const data =
        action.data && action.data.results && action.data.results[0].series ? action.data.results[0].series[0] : null
      const lineData =
        action.lineData && action.lineData.results && action.lineData.results[0].series
          ? action.lineData.results[0].series[0]
          : null

      return {
        ...state,
        data: data ? data.values : null,
        lineData: lineData ? lineData.values : null,
        columns: data ? data.columns : null,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_PLANRUNOEE_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_PLANRUNOEE_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_PLANRUNOEE_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
        hasErrored: action.hasErrored,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function graphConsumptionData(state: GraphConsumptionState = initialGraphConsumptionState, action: AnyAction) {
  switch (action.type) {
    case 'FETCH_CONSUMPTION_SUCCESS':
      const data: any = []
      // @ts-ignore
      if (action.data && action.data.results && action.data.results[0].series) {
        action.data.results[0].series[0].columns.forEach((element: any, index: number) => {
          data[element] = action.data.results[0].series[0].values[0][index]
        })
      }

      return {
        ...state,
        data,
        columns:
          action.data && action.data.results && action.data.results[0].series
            ? action.data.results[0].series[0].columns
            : null,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_CONSUMPTION_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_CONSUMPTION_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_CONSUMPTION_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function graphQualityData(state: GraphQualityState = initialGraphQualityState, action: AnyAction) {
  switch (action.type) {
    case 'FETCH_QUALITY_SUCCESS':
      const data =
        action.data && action.data.results && action.data.results[0] && action.data.results[0].series
          ? action.data.results[0].series
          : null
      const lineData =
        action.lineData && action.lineData.results && action.lineData.results[0].series
          ? action.lineData.results[0].series[0]
          : null
      const lineDataPieces =
        action.lineDataPieces && action.lineDataPieces.results && action.lineDataPieces.results[0].series
          ? action.lineDataPieces.results[0].series[0]
          : null
      return {
        ...state,
        data: data ? data : null,
        lineData: lineData ? lineData.values : null,
        lineDataPieces: lineDataPieces ? lineDataPieces.values : null,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_QUALITY_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_QUALITY_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_QUALITY_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function qualityDetailsData(state: QualityDetailsState = initialQualityDetailsState, action: AnyAction) {
  switch (action.type) {
    case 'FETCH_QUALITYDETAILS_SUCCESS':
      const data =
        action.data && action.data.results && action.data.results[0].series ? action.data.results[0].series[0] : null
      return {
        ...state,
        qualityDetailsData: data ? data.values : null,
        columns: data ? data.columns : null,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_QUALITYDETAILS_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_QUALITYDETAILS_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_QUALITYDETAILS_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function graphAvailabilityData(
  state: GraphAvailabilityState = initialGraphAvailabilityState,
  action: AnyAction
) {
  switch (action.type) {
    case 'FETCH_AVAILABILITY_SUCCESS':
      const data =
        action.data && action.data.results && action.data.results[0].series ? action.data.results[0].series[0] : null
      const lineData =
        action.lineData && action.lineData.results && action.lineData.results[0].series
          ? action.lineData.results[0].series[0]
          : null
      return {
        ...state,
        data: data ? data.values : null,
        lineData: lineData ? lineData.values : null,
        columns: data ? data.columns : null,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_AVAILABILITY_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_AVAILABILITY_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_AVAILABILITY_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function availabilityDetailsData(
  state: AvailabilityDetailsState = initialAvailabilityDetailsState,
  action: AnyAction
) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'FETCH_AVAILABILITYDETAILS_SUCCESS':
      const data =
        action.data && action.data.results && action.data.results[0].series ? action.data.results[0].series[0] : null
      return {
        ...state,
        data: data ? data.values : null,
        columns: data ? data.columns : null,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_AVAILABILITYDETAILS_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_AVAILABILITYDETAILS_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_AVAILABILITYDETAILS_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
      }

    /*-----------*/
    case 'EFFICIENCY_ALARMS_HAS_ERRORED': {
      newState.hasAlarmsErrored = action.hasErrored
      return newState
    }

    case 'EFFICIENCY_ALARMS_IS_LOADING': {
      newState.isAlarmsLoading = action.isLoading
      return newState
    }

    case 'EFFICIENCY_ALARMS_ITEMS_FETCH_DATA_SUCCESS': {
      newState.itemsAlarms = action.items
      return newState
    }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function availabilityDetailsAlarmsData(
  state: AvailabilityDetailsState = initialAvailabilityDetailsState,
  action: AnyAction
) {
  switch (action.type) {
    case 'FETCH_AVAILABILITYDETAILS_ALARMS_SUCCESS':
      const data =
        action.dataAlarms && action.dataAlarms.results && action.dataAlarms.results[0].series
          ? action.dataAlarms.results[0].series[0]
          : null
      return {
        ...state,
        dataAlarms: data ? data.values : null,
        columns: data ? data.columns : null,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_AVAILABILITYDETAILS_ALARMS_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_AVAILABILITYDETAILS_ALARMS_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_AVAILABILITYDETAILS_ALARMS_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function graphPerformanceData(state: GraphPerformanceState = initialGraphPerformanceState, action: AnyAction) {
  switch (action.type) {
    case 'FETCH_PERFORMANCE_SUCCESS':
      const data =
        action.data && action.data.results && action.data.results[0].series ? action.data.results[0].series[0] : null
      const lineData =
        action.lineData && action.lineData.results && action.lineData.results[0].series
          ? action.lineData.results[0].series[0]
          : null

      return {
        ...state,
        data: data ? data.values : null,
        lineData: lineData ? lineData.values : null,
        columns: data ? data.columns : null,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_PERFORMANCE_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_PERFORMANCE_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_PERFORMANCE_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}

export function graphTPRNCData(state: TPRNCAlarmsState = initialTPRNCAlarmsState, action: AnyAction) {
  switch (action.type) {
    case 'TPRNC_ALARMS_ITEMS_FETCH_DATA_SUCCESS':
      const data =
        action.data && action.data.results && action.data.results[0].series ? action.data.results[0].series[0] : null

      return {
        ...state,
        data: data ? data.values : null,
        columns: data ? data.columns : null,
        lastUpdate: action.lastDateUpdate,
        isLoading: false,
        hasErrored: false,
      }

    case 'TPRNC_ALARMS_IS_LOADING':
      return {
        ...state,
        data: null,
        isLoading: true,
        hasErrored: false,
      }

    case 'TPRNC_ALARMS_HAS_ERRORED':
      return {
        ...state,
        data: null,
        isLoading: false,
        hasErrored: true,
      }

    default:
      return state
  }
}
