import * as React from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {fetchAvailabilityData} from '../../../../redux/actions/graphs'
import * as moment from 'moment'
import DownloadDataButton
  from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import {Bar, ComposedChart, Label, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {timeRangeFileString, TPRNCAlarmsData} from '../../../../functions/shared'
import {AlertNoData} from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/SharedComponents/sharedComponents';
import {Card, CardBody, CardFooter, CardHeader} from '@mv-submodules/inplant-components-fe';
import {saveDataAsCSV} from "@mv-submodules/inplant-core-fe/functions";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import { IField } from '@mv-submodules/inplant-efficiency-fe-imel/types/GraphConfiguration'

interface GraphAvailabilityStateProps {
  range: null | string
  lineData?: any
  hasErrored: boolean
  isLoading: boolean
  columns?: string[]
  lastDateUpdate: number | null
  dateStart: string | null
  dateEnd: string | null,
  measureUnits: object,
  cliccable?: boolean | false
  TPRNCData: any
  TPRNCDataErrors: boolean
  TPRNCDataIsLoading: boolean
}

interface GraphAvailabilityDispatchProps {
  fetchData: () => Function
}

interface GraphAvailabilityOwnProps {
  // t: TranslationFunction
  history: any
  match: any
  fieldsSelected: IField[]
  dataSource: string
  data: any
  title: string
  unit: string
  valueMultiplier?: number
  columnCallback?: () => void
  cliccable?: boolean
  customButtons?: React.Component[]
}

interface GraphAvailabilityOwnState {
  lastUpdate: number | null
  legend: any
  currentHover: string | null
}

const mapStateToProps = (state: any): GraphAvailabilityStateProps => ({
  range: state.dateFilters.range,
  lineData: state.graphAvailabilityData.lineData,
  hasErrored: state.graphAvailabilityData.hasErrored,
  isLoading: state.graphAvailabilityData.isLoading,
  columns: state.graphAvailabilityData.columns,
  lastDateUpdate: state.dateFilters.lastUpdate,
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
  measureUnits: state.config.generic.measureUnits || {},
  TPRNCData: state.graphTPRNCData.data,
  TPRNCDataErrors: state.graphTPRNCData.hasErrored,
  TPRNCDataIsLoading: state.graphTPRNCData.isLoading,
})

const mapDispatchToProps = (dispatch: Function): GraphAvailabilityDispatchProps => ({
  fetchData: () => dispatch(fetchAvailabilityData()),
})

type GraphAvailabilityProps = GraphAvailabilityStateProps &
  GraphAvailabilityDispatchProps &
  GraphAvailabilityOwnProps &
  WithTranslation

const COLORS = {
  'Warm-up': '#66c2a5',
  ready: '#fc8d62',
  start: '#8da0cb',
  alarms: '#66c2a5',
  emergency: '#fc8d62',
  ondemand: '#8da0cb',
  safety: '#cb58ba',
}

const CustomTooltip = ({active, payload, label}: any) => {
  if (active) {
    return (
      <div className="recharts-default-tooltip">
        <ul>
          <li className="title">
            <strong>{moment(label).format('DD/MM')}</strong>
          </li>
          {payload && payload.map((line: any, index: number) => (
            <li key={index}>
              <strong>
                {line.name}
                {line.name === 'availability' ? ' (%)' : ''}
              </strong>
              <span className="value float-right">{line.value.toFixed(2)}</span>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return null
}

class GraphAvailabilityRSingle extends React.Component<GraphAvailabilityProps, GraphAvailabilityOwnState> {
  constructor(props: GraphAvailabilityProps) {
    super(props)
    this.state = {
      lastUpdate: null,
      legend: null,
      currentHover: null,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseExit = this.handleMouseExit.bind(this)
  }

  public componentDidUpdate(prevProps: Readonly<GraphAvailabilityProps>, prevState: Readonly<GraphAvailabilityOwnState>, snapshot?: any) {
    if (prevProps.dateStart !== this.props.dateStart || prevProps.dateEnd !== this.props.dateEnd) {
      this.props.fetchData()
    }
  }

  public render() {
    const {dateStart, dateEnd, TPRNCData} = this.props
    let legend

    function explodeCamel(str: string) {
      return str.replace(/([A-Z])/g, ' $1').replace(/^./, (stri: string) => {
        return stri.toUpperCase()
      })
    }

    legend = this.props.fieldsSelected.map((i, ind: any) => {
      if (i.name !== 'time') {

        return (
          <li
            className="filter-trigger"
            key={i.name}
            data-filter={i.name.replace(/ /g, '_')}
            onMouseEnter={() => this.handleMouseEnter(i.name)}
            onMouseLeave={() => this.handleMouseExit()}
          >
            <span className={'legend-color'} style={{backgroundColor: i.color ? i.color : COLORS[i.name]}}/>
            {explodeCamel(i.name)}
          </li>
        )
      } else {
        return null
      }
    })

    let downloadData: string[][] =
      this.props[this.props.dataSource] && this.props.lineData
        ? this.props[this.props.dataSource].map((e: any, i: number) => {
          const out = [
            moment(e[0]).format('YYYY/MM/DD'),
            this.props.lineData[i] ? this.props.lineData[i][1] : null, // @todo fix
          ]

          this.props.fieldsSelected.map((f, l: number) => {
            out.push(e[1 + l])
          })

          return out
        })
        : []

    downloadData = [
      ['time', 'availability']
        .concat(this.props.fieldsSelected.map(field => field[0]))
        .map((c: string) => `${c}${this.props.measureUnits[c] ? ` (${this.props.measureUnits[c]})` : ''}`),
    ].concat(downloadData)

    const data =
      this.props[this.props.dataSource] &&
      this.props[this.props.dataSource].map((e: any, i: number) => {
        const out = {
          time: e[0],
          // plannedLoss: e[1] / 60,
          // unplannedLoss: e[2] / 60,
          availability: this.props.lineData && this.props.lineData[i] ? this.props.lineData[i][1] * 100 : null,
        }

        this.props.fieldsSelected.map((f, l: number) => {
          out[f.name] = e[1 + l] * (this.props.valueMultiplier || 1)
        })

        return out
      })

    const timeFormat =
      this.props.range &&
      ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(this.props.range)
        ? 'MM/YYYY'
        : 'DD/MM'

    const tprncData = TPRNCAlarmsData(dateStart, dateEnd, data, TPRNCData)

    return (
      <div className={`DashboardWidget WidgetAvailabilityR WidgetH50 ${this.props.cliccable ? 'cliccable' : ''}`}>
        <Card marginBottom={3}>
          <CardHeader>
            {this.props.title}
            {downloadData && (
              <DownloadDataButton
                saveDataAsCSV={saveDataAsCSV}
                data={downloadData}
                type={'csv'}
                label={'i4efficiency.download.buttonLabel'}
                className={'float-right'}
                fileName={
                  'availability_' +
                  this.props.title.replace(' ', '_').toLowerCase() +
                  timeRangeFileString(this.props.dateStart, this.props.dateEnd)
                }
                addTime={false}
                text={'i4efficiency.download.buttonLabelData'}
              />
            )}
            {this.props.customButtons}
          </CardHeader>

          <CardBody>
            <div className="container-fluid chart-container">
              <div className={"full-h"}>
                <Row verticalAlignment={"center"}>
                  <Column sm={12}>
                    <div className={"text-center full-h"}>
                      <div className="chart-container" onClick={this.handleClick}>
                        {data ? (
                          <ResponsiveContainer>
                            <ComposedChart
                              width={700}
                              height={450}
                              data={data}
                              margin={{
                                top: 45,
                                right: 50,
                                left: 0,
                                bottom: 0,
                              }}
                              className={this.props.cliccable ? 'cliccable' : ''}
                              stackOffset="sign"
                            >
                              <XAxis
                                dataKey="time"
                                tickFormatter={e => {
                                  return moment(e).format(timeFormat)
                                }}
                                axisLine={false}
                              />
                              <YAxis axisLine={false} yAxisId={'left'}>
                                <Label position={'top'} offset={30}>
                                  {this.props.unit}
                                </Label>
                              </YAxis>

                              <YAxis
                                axisLine={false}
                                yAxisId={'right'}
                                orientation={'right'}
                                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                width={20}
                                interval={0}
                              >
                                <Label position={'top'} offset={30}>
                                  %
                                </Label>
                              </YAxis>

                              <Tooltip content={<CustomTooltip/>} cursor={{fill: 'none'}}/>
                              {
                                tprncData && tprncData.map(d => <ReferenceArea
                                  key={d.key}
                                  x1={d.x1}
                                  x2={d.x2}
                                  yAxisId={'right'}
                                  stroke={d.stroke}
                                  strokeOpacity={d.strokeOpacity}
                                  fill={d.fill}
                                  fillOpacity={d.fillOpacity}
                                />)
                              }
                              {this.props.fieldsSelected.map(f => (
                                <Bar
                                  key={f.name}
                                  dataKey={f.name}
                                  fill={f.color ? f.color : COLORS[f.name]}
                                  onMouseOver={(a: any, b: any, c: any) => {
                                    if (c && c.native) {
                                      c.native.target.opacity = 0.3
                                    }
                                  }}
                                  stackId="stack"
                                  yAxisId={'left'}
                                  onMouseEnter={() => this.handleMouseEnter(f.color)}
                                  onMouseLeave={() => this.handleMouseExit()}
                                  onClick={this.props.columnCallback || undefined}
                                  style={{
                                    cursor: this.props.columnCallback ? 'pointer' : 'default',
                                  }}
                                  opacity={this.state.currentHover !== null && this.state.currentHover !== f.color ? 0.3 : 1}
                                />
                              ))}
                              <Line
                                dataKey={'availability'}
                                stroke="#d0743c"
                                yAxisId={'right'}
                                strokeWidth={2}
                                isAnimationActive={false}
                                dot={false}
                                opacity={this.state.currentHover !== null ? 0.3 : 1}
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        ) : (
                          <AlertNoData message={this.props.t('i4efficiency.fetch.noData')}/>
                        )}
                      </div>
                    </div>

                  </Column>
                </Row>
              </div>

            </div>
          </CardBody>
          <CardFooter
            textSizeSmall={true}
            textAlign={'right'}
            textMuted={true}
          >
            <ul className="legend float-left">{legend}</ul>
            <ul className="legend float-right">
              <li>
                <span className="legend-color legend-availability-color"/> AVAILABILIY
              </li>
            </ul>
          </CardFooter>
        </Card>
      </div>
    )
  }

  private handleClick() {
    this.props.history.push('/i4efficiency/availability-details')
  }

  private handleMouseEnter(category: string) {
    this.setState({
      currentHover: category,
    })
  }

  private handleMouseExit() {
    this.setState({
      currentHover: null,
    })
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(GraphAvailabilityRSingle)),
)
