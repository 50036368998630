import * as React from 'react'
import * as moment from 'moment'
import { itemsAlarmsFetchData } from '../../../../redux/actions/service'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Modal, Table, TableColumn } from '../../../../../inplant-components-fe'
import { isJSON, saveDataAsCSV } from '../../../../../inplant-core-fe/functions'
import { Alarm } from '@mv-submodules/inplant-production-fe-imel/types/Alarms'

interface StateProps {
  item: number | string | null
  lotId: number | string | null
  closeModalFn: () => void
  dateStart?: string
  dateEnd?: string
  // t: TranslationFunction
}

interface OwnState {
  alarms: Alarm[]
  downloadData: any
}

interface Column {
  visible?: boolean
  format?: 'string'
  displayName?: string
}

interface ProductionAlarmsStateProps {
  items: Alarm[]
  hasErrored: boolean
  isLoading: boolean
  config: {
    alarmsColumnsConfig: Record<string,Column>,
    dataSourceMapping?: string,
    dataSourceFrames?: string
  } 
}

interface ProductionAlarmsDispatchProps {
  fetchData: Function
}

const mapStateToProps = (state: any): ProductionAlarmsStateProps => {
  return {
    items: state.production.itemsAlarms,
    hasErrored: state.production.hasAlarmsErrored,
    isLoading: state.production.isAlarmsLoading,
    config: {
      alarmsColumnsConfig: isJSON(state.config.production?.alarmsColumnsConfig)
        ? JSON.parse(state.config.production?.alarmsColumnsConfig)
        : {},
      dataSourceFrames: state.config.production.dataSourceFrames,
      dataSourceMapping: state.config.production.dataSourceMapping,
    },
  }
}

const mapDispatchToProps = (dispatch: Function): ProductionAlarmsDispatchProps => {
  return {
    fetchData: (id: number, useFrames?: boolean, dateStart?: string, dateEnd?: string) =>
      dispatch(itemsAlarmsFetchData(id, useFrames, dateStart, dateEnd)),
  }
}

type Props = ProductionAlarmsStateProps & ProductionAlarmsDispatchProps & StateProps & WithTranslation

const msgClass = {
  66: 'emergency stop',
  68: 'on demand stop',
  1: 'process alarm',
  65: 'process stop',
  64: 'safety stop',
  2: 'warning',
}

class AlarmModal extends React.Component<Props, OwnState> {
  private useFrames = this.props.config?.dataSourceFrames && this.props.config.dataSourceFrames === 'true'

  constructor(props: Props) {
    super(props)

    this.state = {
      alarms: [],
      downloadData: null,
    }
    this.downloadCsvData = this.downloadCsvData.bind(this)
  }

  public componentWillMount(): void {
    this.props.fetchData(this.props.item,
      this.useFrames,
      this.props.dateStart,
      this.props.dateEnd).then((result: any) => {
      const items: any[] = result.items
      const allPossibleColumns: string[] = ['In', 'Accepted', 'Out', 'Type', 'Text', 'Cabinet / Component']

      const columnsToHide = Object.entries(this.props.config.alarmsColumnsConfig).reduce(
        (acc: Array<{ displayName: string; slug: string }>, [key, value]) => {
          if (value?.visible !== undefined && !value.visible && value.displayName) {
            return [...acc, { displayName: value.displayName, slug: key }]
          }
          return acc
        },
        []
      )

      items.forEach(alarm => {
        columnsToHide.forEach(col => {
          if (alarm[col.slug]) {
            delete alarm[col.slug]
          }
        })
      })

      const columnsToShow = allPossibleColumns.filter(col => !columnsToHide.map(c => c.displayName).includes(col))

      this.setState({
        alarms: this.props.items
      }, () => {
        this.setState({
          downloadData: [columnsToShow].concat(this.state.alarms.map(alarm => {
            return [
              moment(alarm.iTime).isValid() ? moment(alarm.iTime).format('YYYY-MM-DD HH:mm:ss') : '--',
              moment(alarm.aTime).isValid() ? moment(alarm.aTime).format('YYYY-MM-DD HH:mm:ss') : '--',
              moment(alarm.oTime).isValid() ? moment(alarm.oTime).format('YYYY-MM-DD HH:mm:ss') : '--',
              msgClass[alarm.msgClass],
              alarm.message.replace(/"\((.*)\) /, '').replace(/ \[(.*).\]"/, ''),
              alarm.cabinet + (alarm.component ? ' / ' + alarm.component : ''),
            ]
          }))
        })
      })
    })
  }

  private getColumnConfigField(field: string, columnId: any) {
    return columnId &&
      this.props.config.alarmsColumnsConfig &&
      typeof columnId === 'string' &&
      this.props.config.alarmsColumnsConfig.hasOwnProperty(columnId) &&
      this.props.config.alarmsColumnsConfig[columnId][field] !== undefined
      ? this.props.config.alarmsColumnsConfig[columnId][field]
      : null
  }
  private static formatDate( date: string) {
    return moment(date).isValid() ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '--';
  }

  private parseColumns(columns: TableColumn[]) {
    const columnsConfig = this.props.config.alarmsColumnsConfig
    if (!columnsConfig) {
      return columns
    }

    return columns
      .filter(col => {
        return this.getColumnConfigField('visible', col.accessor) !== false
      })
      .map(col => {
        const columnFormat = this.getColumnConfigField('format', col.accessor)
        switch (columnFormat) {
          case 'string':
            col.Cell = (row: any) => row.value
            return col

          default:
            return col
        }
      })
  }

  public render() {
    const columns = [
      {
        Header: this.props.t('production.alarmModal.iTime'),
        accessor: 'iTime',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {AlarmModal.formatDate(row.value)}
          </div>
        ),
        minWidth: 60,
      },
      {
        Header: this.props.t('production.alarmModal.aTime'),
        accessor: 'aTime',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {AlarmModal.formatDate(row.value)}
          </div>
        ),
        minWidth: 60,
      },
      {
        Header: this.props.t('production.alarmModal.oTime'),
        accessor: 'oTime',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {AlarmModal.formatDate(row.value)}
          </div>
        ),
        minWidth: 60,
      },
      {
        Header: this.props.t('production.alarmModal.msgClass'),
        accessor: 'msgClass',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {msgClass[row.value]}
          </div>
        ),
        minWidth: 60,
      },
      {
        Header: this.props.t('production.alarmModal.message'),
        accessor: 'message',
        Cell: (row: any) => (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {row.value.replace(/"\((.*)\) /, '').replace(/ \[(.*).\]"/, '')}
          </div>
        ),
      },
    ]

    if (!this.state.alarms) {
      return null
    }

    if (
      this.state.alarms &&
      this.state.alarms[0] &&
      (this.state.alarms[0].hasOwnProperty('cabinet') || this.state.alarms[0].hasOwnProperty('component'))
    ) {
      columns.push({
        Header: this.props.t('production.alarmModal.cabinet'),
        accessor: 'cabinet',
        Cell: (row: any) => {
          return (
            <div
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {row.original.cabinet} {row.original.component ? '/ ' + row.original.component : ''}
            </div>
          )
        },
        minWidth: 60,
      })
    }

    return (
      <Modal
        visible={true}
        width={75}
        title={this.props.t('production.alarmModal.alarms')}
        closeLabel={this.props.t('production.alarmModal.actions.close')}
        onClose={this.props.closeModalFn}
        additionalFooterButtons={[{
          variant: 'primary',
          icon: 'download',
          onClick: this.downloadCsvData,
          label: this.props.t('production.download.buttonLabel')
        }]}
      >
        <Table
          columns={this.parseColumns(columns)}
          data={this.state.alarms}
          className="-striped production-alarms-table"
          showPagination={false}
          minRows={1}
          noDataText={'no data'}
          isFetching={false}
        />
      </Modal>
    )
  }
  private downloadCsvData() {
    saveDataAsCSV(this.state.downloadData, 'production_alarms_' + this.props.item, 'csv', false)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AlarmModal))
