import { format } from 'd3-format'
import { arc, line, stack } from 'd3-shape'
import { scaleLinear, scaleOrdinal, scaleTime } from 'd3-scale'
import { select, event } from 'd3-selection'
import { easeElastic } from 'd3-ease'
import { axisBottom, axisLeft, axisRight } from 'd3-axis'
import { max, min, range, extent } from 'd3-array'
import { timeDay, timeMonth, timeHour, CountableTimeInterval } from 'd3-time'
import { timeFormat, timeParse } from 'd3-time-format'
import { schemeSet2, mouse } from 'd3'
import * as jquery from 'jquery'
// tslint:disable

export const gauge = function(container: string, configuration: object) {
  let that: any = {}
  let config = {
    size: 70,
    clipWidth: 70,
    clipHeight: 40,
    ringInset: 20,
    ringWidth: 20,

    pointerWidth: 10,
    pointerTailLength: 5,
    pointerHeadLengthPercent: 0.9,

    minValue: 0,
    maxValue: 100,

    minAngle: -120,
    maxAngle: 120,

    transitionMs: 750,

    majorTicks: 3,
    labelFormat: format(',g'),
    labelInset: 10,

    mainLabel: 'Gauge',

    arcColorFn: function(i: number) {
      let colors = ['#ff1111', '#ffcc11', '#11ff11']
      if (i < 0.333) {
        return colors[0]
      } else if (i < 0.66) {
        return colors[1]
      } else {
        return colors[2]
      }
    },
  }

  let rangeAng: any = undefined
  let r: any = undefined
  let pointerHeadLength: any = undefined

  let svg: any, arcGraph: any, scale: any, tickData: any, pointer: any

  function deg2rad(deg: number) {
    return (deg * Math.PI) / 180
  }

  function configure(configurations: object) {
    let prop: any = undefined

    for (prop in configurations) {
      if (configurations.hasOwnProperty(prop)) {
        config[prop] = configurations[prop]
      }
    }

    rangeAng = config.maxAngle - config.minAngle
    r = config.size / 2
    pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent)

    // a linear scale that maps domain values to a percent from 0..1
    scale = scaleLinear()
      .range([0, 1])
      .domain([config.minValue, config.maxValue])

    scale.ticks(config.majorTicks)
    tickData = range(config.majorTicks).map(function() {
      return 1 / config.majorTicks
    })

    arcGraph = arc()
      .innerRadius(r - config.ringWidth - config.ringInset)
      .outerRadius(r - config.ringInset)
      .startAngle(function(d: any, i: number) {
        let ratio = d * i
        return deg2rad(config.minAngle + ratio * rangeAng)
      })
      .endAngle(function(d: any, i: number) {
        let ratio = d * (i + 1)
        return deg2rad(config.minAngle + ratio * rangeAng)
      })
  }

  that.configure = configure

  function centerTranslation() {
    return 'translate(' + r + ',' + r + ')'
  }

  function isRendered() {
    return svg !== undefined
  }

  that.isRendered = isRendered

  function render(newValue: number) {
    select(container)
      .select('svg')
      .remove()
      .exit()

    svg = select(container)
      .append('svg:svg')
      .attr('class', 'gauge shadow')
      .attr('width', config.clipWidth)
      .attr('height', config.clipHeight)
      .attr('viewBox', '0 0 ' + config.clipWidth + ' ' + config.clipHeight)

    let centerTx = centerTranslation()

    let arcs = svg
      .append('g')
      .attr('class', 'arc')
      .attr('transform', centerTx)

    arcs
      .selectAll('path')
      .data(tickData)
      .enter()
      .append('path')
      .attr('fill', function(d: number, i: number) {
        return config.arcColorFn(d * i)
      })
      .attr('d', arcGraph)

    /*let lg = svg.append('g')
            .attr('class', 'label')
            .attr('transform', centerTx);*/

    let lineData = [
      [config.pointerWidth / 2, 0],
      [0, -pointerHeadLength],
      [-(config.pointerWidth / 2), 0],
      [0, config.pointerTailLength],
      [config.pointerWidth / 2, 0],
    ]
    let pointerLine = line()
    let pg = svg
      .append('g')
      .data([lineData])
      .attr('class', 'pointer')
      .attr('transform', centerTx)

    pointer = pg
      .append('path')
      .attr('d', pointerLine /*function(d) { return pointerLine(d) +'Z';}*/)
      .attr('transform', 'rotate(' + config.minAngle + ')')

    update(newValue === undefined ? 0 : newValue)
  }

  that.render = render

  function update(newValue: number, newConfiguration?: object) {
    if (newConfiguration !== undefined) {
      configure(newConfiguration)
    }
    let ratio: any = scale(newValue)
    let newAngleRotation = config.minAngle + ratio * rangeAng

    pointer
      .transition()
      .duration(config.transitionMs)
      .ease(easeElastic)
      .attr('transform', 'rotate(' + newAngleRotation + ')')
  }

  that.update = update

  configure(configuration)

  return that
}

export const stacks = function(element: string, data: any, columns: any, lineData?: any) {
  let $ele = jquery(element),
    margin = { top: 20, right: 30, bottom: 20, left: 50 },
    width = +$ele.width()! - margin.left - margin.right,
    height = +$ele.height()! - margin.top - margin.bottom,
    svg = select(element),
    parseDate = timeParse('%Y-%m-%dT%H:%M:%SZ')
  // valueline

  $ele.attr({
    width: $ele.width(),
    height: $ele.height(),
    viewBox: '0 0 ' + $ele.width() + ' ' + $ele.height(),
  })

  svg
    .selectAll('g, path')
    .remove()
    .exit()

  $ele.empty()

  if (data && data.length > 0) {
    data = data.map(function(d: any) {
      let obj: any = {}

      obj.date = timeHour.offset(parseDate(d[0])!, 0)
      // @TODO:
      /*
                Check daily save
                Sat Mar 24 2018 00:00:00 GMT+0100 (ora solare Europa occidentale)
                Graphs.ts:551 Sat Mar 24 2018 23:00:00 GMT+0100 (ora solare Europa occidentale)
            */
      for (let i = 1; i < columns.length; i++) {
        obj[columns[i]] = +d[i] / 60
      }
      return obj
    })

    if (lineData !== undefined) {
      lineData.forEach(function(d: any, i: number) {
        d.date = timeHour.offset(parseDate(d[0])!, 0)
        d.line = +d[1]
      })
    }

    let series = stack().keys(columns.slice(1))(data)

    let timeStart = new Date(
      min(
        data.map(function(d: any) {
          return d.date
        })
      )!
    ).getTime()
    let timeEnd = new Date(
      max(
        data.map(function(d: any) {
          return d.date
        })
      )!
    ).getTime()
    let timeDiff = timeEnd - timeStart

    let x = scaleTime()
      // .domain(data.map(function(d: any) { return d.date; }))
      .rangeRound([
        margin.left + width / data.length / 3 - (data.length < 8 ? 0 : 20),
        width - margin.right - width / data.length / 2,
      ])
      .domain(extent(data, function(d: any) {
        return d.date
      }) as any)

    const stackMin = function(serie: any) {
      return min(serie, function(d: any) {
        return +d[0]
      })
    }

    const stackMax = function(serie: any) {
      return max(serie, function(d: any) {
        return +d[1]
      })
    }

    let y = scaleLinear()
      .domain([min(series, stackMin)!, max(series, stackMax)!])
      .rangeRound([height, 0])

    let y2 = scaleLinear()
      .rangeRound([height, 0])
      .domain([0, 100])

    let z = scaleOrdinal(schemeSet2)

    svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ', 0)')
      .attr('class', 'graph main-graph')
      .selectAll('g')
      .data(series)
      .enter()
      .append('g')

      .attr('fill', function(d: any) {
        return z(d.key)
      })
      .attr('class', function(d: any) {
        return 'category category-' + d.key.replace(/ /g, '_')
      })
      .attr('transform', 'translate(0,' + margin.top + ')')
      .on('mouseover', function(d: any) {
        // svg.selectAll('.legend').classed('cat-disabled', true);
        svg.selectAll('.category').classed('cat-disabled', true)
        select(this).classed('cat-disabled', false)
        svg.selectAll('.category-' + d.key).classed('cat-disabled', false)
      })
      .on('mouseout', function(d: any) {
        svg.selectAll('.legend').classed('cat-disabled', false)

        svg.selectAll('.category').classed('cat-disabled', false)
      })
      .selectAll('rect')
      .data(function(d: any) {
        return d
      })
      .enter()
      .append('g')
      .append('rect')
      .attr('width', (width / data.length) * 0.9)

      .attr('x', function(d: any) {
        return x(d.data.date) - width / data.length / 2 + (width / data.length) * 0.05
      })
      .attr('y', function(d: any) {
        return +y(+d[1])
      })
      .attr('height', function(d: any) {
        return +(y(d[0]) - y(d[1]))
      })
      .append('g')
      .insert('text')
      .text('')

    let formatTimes: Function, formatTimesTooltip: Function, ticksTime: CountableTimeInterval, mainGraph: any

    mainGraph = svg.select('.main-graph')

    if (timeDiff > 2764800000) {
      formatTimes = timeFormat('%m/%Y')
      formatTimesTooltip = timeFormat('%d/%m/%Y')
      ticksTime = timeMonth
    } else {
      formatTimes = timeFormat('%d/%m')
      formatTimesTooltip = timeFormat('%d/%m')
      ticksTime = timeDay
    }

    svg
      .append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(' + margin.left + ',' + (height + margin.top) + ')')
      .call(
        axisBottom(x)
          .tickFormat(function(e: any) {
            return formatTimes(new Date(e))
          })
          .ticks(ticksTime, 1)
      )

    let yLabel = ''

    switch (element) {
      case '#chart-availability':
        yLabel = 'time (min)'
        break

      default:
        yLabel = 'pieces'
        break
    }

    svg
      .append('g')
      .attr('class', 'axis axis--y')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .call(axisLeft(y))
      .append('text')
      .attr('x', 20)

      .attr('y', 20)
      .attr('dx', '-1.2rem')
      .attr('dy', '-2rem')
      .attr('fill', '#000')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'end')
      .text(yLabel)

    if (lineData) {
      const dPoint = function(d: any) {
        return +x(d.date) + ',' + +y2(d.line * 100)
      }

      let path = lineData.reduce(function(accum: any, d: any, i: number) {
        return lineData[i - 1] && lineData[i - 1].line > 0 && d.line > 0 && i > 0
          ? accum + 'L' + dPoint(d)
          : accum + 'M' + dPoint(d)
      }, '')

      svg
        .append('path')
        .attr('class', 'line quality')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .attr('d', path)

      svg
        .append('g')
        .attr('class', 'axis axis--y2')
        .attr('transform', 'translate(' + (width + margin.right) + ' ,' + margin.top + ')')
        .call(axisRight(y2))
        .append('text')
        .attr('x', 20)
        .attr('y', margin.top)
        .attr('dx', '0rem')
        .attr('dy', '-2rem')
        .attr('fill', '#000')
        .attr('font-weight', 'bold')
        .attr('text-anchor', 'end')
        .text('%')
    }

    let $widget = $ele.parents('.DashboardWidget')

    $widget.on('mouseenter', '.filter-trigger', function() {
      let filter = jquery(this).data('filter')
      $widget.find('.category').addClass('cat-disabled-hover')
      jquery(this)
        .removeClass('cat-disabled-hover')
        .addClass('cat-enabled-hover')
      $widget
        .find('.category-' + filter)
        .removeClass('cat-disabled-hover')
        .addClass('cat-enabled-hover')
    })

    $widget.on('mouseleave', '.filter-trigger', function() {
      $widget.find('.filter-trigger, .category').removeClass('cat-disabled-hover cat-enabled-hover')
    })

    const removeTooltip = function() {
      if (tooltip) {
        tooltip.style('opacity', '0')
      }
    }

    const drawTooltip = function() {
      let dataSelected = data.filter((d: any) => {
        let date1 = new Date(d.date)

        let date2 = new Date(x.invert(mouse(mainGraph.node())[0] + width / data.length / 2))
        return date1.toDateString() === date2.toDateString()
      })

      if (dataSelected.length > 1) {
        dataSelected = [dataSelected[0]]
      }

      tooltip
        .html('')
        .style('opacity', '1')

        .style('left', +(event.layerX < width - 150 ? event.layerX + 30 : event.layerX + -150) + 'px')

        .style('top', +(event.layerY < height - 150 ? event.layerY : event.layerY - 120) + 'px')
        .selectAll()
        .data(dataSelected)
        .enter()
        .append('div')
        .html((d: any) => {
          return (
            '<ul>' +
            '<li class="title"><strong>' +
            formatTimesTooltip(new Date(d.date)) +
            '</strong></li>' +
            Object.keys(d)
              .map(function(el: string) {
                if (el !== 'date') {
                  return (
                    '<li><strong>' +
                    el +
                    '</strong> ' +
                    '<span class="value float-right">' +
                    d[el].toFixed(2) +
                    '</span></li>'
                  )
                }
                return ''
              })
              .join('') +
            '</ul>'
          )
        })
    }

    mainGraph.on('mousemove', drawTooltip).on('mouseout', removeTooltip)

    const tooltip = select($widget.find('.tooltip').get(0))

    return columns
      .slice()
      .filter(function(type: string) {
        return type !== 'time'
      })
      .map((e: string) => {
        return {
          name: e,
          color: z(e),
        }
      })
  }
}

export const bars = function(element: string, data: any, columns: string[] | undefined, fieldSelected: string) {
  let elementId = element,
    $ele = jquery(elementId),
    // formatTime = timeFormat('%d/%m')
    parseDate = timeParse('%Y-%m-%dT%H:%M:%SZ')

  if (data && data.length > 0) {
    $ele.attr({
      width: $ele.width(),
      height: $ele.height(),
      viewBox: '0 0 ' + $ele.width() + ' ' + $ele.height(),
    })

    data.forEach(function(d: any) {
      d.performance = 0

      d.date = parseDate(d[0])
      d.plantime = +d[1]
      d.opertime = +d[2]
      d.runtime = +d[3]

      if (d.opertime > 0) {
        d.performance = d.runtime / d.opertime
      }
    })

    let svg = select(elementId),
      margin = { top: 20, right: 20, bottom: 20, left: 50 },
      eleWidth: number = $ele.width() || 0,
      eleHeight: number = $ele.height() || 0,
      width = +eleWidth - margin.left - margin.right,
      height = +eleHeight - margin.top - margin.bottom

    svg
      .selectAll('g')
      .remove()
      .exit()
    svg
      .selectAll('rect')
      .remove()
      .exit()

    $ele.empty()

    let x = scaleTime().range([
      margin.left + width / data.length / 3 - (data.length < 10 ? (data.length < 6 ? -30 : -10) : 20),
      width - margin.right - width / data.length / 2,
    ])
    // .padding(0.1)

    let y = scaleLinear().range([height, 0])

    let g = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    data.forEach(function(d: any) {
      d.value = +d[columns!.indexOf(fieldSelected)]
    })

    x.domain(extent(data, function(d: any) {
      return d.date
    }) as any)

    y.domain([
      0,
      max(data, function(d: any) {
        return d.value
      }),
    ] as any)

    g.selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')

      .attr('x', function(d: any) {
        return x(d.date) - width / data.length / 2
      })
      .attr('width', (width / data.length) * 0.95 - 2)
      .attr('y', function(d: any) {
        return y(d.value)
      })
      .attr('height', function(d: any) {
        return height - y(d.value)
      })

    let formatTimes: Function, ticksTime: CountableTimeInterval

    if (new Date(x.domain()[1]).getTime() - new Date(x.domain()[0]).getTime() > 2764800000) {
      formatTimes = timeFormat('%m/%Y')
      ticksTime = timeMonth
    } else {
      formatTimes = timeFormat('%d/%m')
      ticksTime = timeDay
    }

    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0, ' + height + ')')
      .call(
        axisBottom(x)
          .tickFormat(function(e: any) {
            return formatTimes(e)
          })
          .ticks(ticksTime, 1)
      )
    g.append('g')
      .attr('class', 'axis axis--y')
      .call(axisLeft(y).ticks(y.domain()[1] > 10 ? 10 : y.domain()[1] > 10))
      .append('text')
      .attr('x', 20)

      .attr('y', 20)
      .attr('dx', '-1.1rem')
      .attr('dy', '-2rem')
      .attr('fill', '#000')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'end')
      .text('Scrap')

    const removeTooltip = function() {
      if (tooltip) {
        tooltip.style('opacity', '0')
      }
    }

    const drawTooltip = function() {
      let dataSelected = data.filter((d: any) => {
        let date1 = new Date(d.date)

        let date2 = new Date(x.invert(mouse(tipBox.node() as any)[0] + width / data.length / 2))
        return date1.toDateString() === date2.toDateString()
      })

      if (dataSelected.length > 1) {
        dataSelected = [dataSelected[0]]
      }

      tooltip
        .html('')
        .style('opacity', '1')

        .style('left', +(event.layerX < width - 150 ? event.layerX + 30 : event.layerX + -150) + 'px')

        .style('top', +(event.layerY < height - 50 ? event.layerY : event.layerY - 50) + 'px')
        .selectAll()
        .data(dataSelected)
        .enter()
        .append('div')
        .html((d: any) => {
          return (
            '<ul>' +
            '<li class="title"><strong>' +
            formatTimes(new Date(d.date)) +
            '</strong></li>' +
            '<li><strong>' +
            fieldSelected +
            '</strong> ' +
            '<span class="value float-right">' +
            d[columns!.indexOf(fieldSelected)].toFixed(2) +
            '</span></li>' +
            '</ul>'
          )
        })
    }

    let tipBox = svg
      .append('rect')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .attr('width', width)
      .attr('height', height)
      .attr('opacity', 0)
      .on('mousemove', drawTooltip)
      .on('mouseout', removeTooltip)

    let $widget = $ele.parents('.DashboardWidget')
    const tooltip = select($widget.find('.tooltip').get(0))
  }
}
