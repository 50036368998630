import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { logoutUser } from '../../../../redux/actions/auth'
import { User } from '../../../../types/index'
import { Link } from 'react-router-dom'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface MenuLink {
  path: string
  icon?: any
  iconOverride?: string
  i18nkey?: string
  visible: boolean
  visibleInUserProfile?: boolean
  children?: MenuLink[]
}

interface StateProps {
  user: User
  config: { legacyLogout: () => Promise<void> } | undefined
}

interface DispatchProps {
  logoutUser: () => Function
}

interface OwnProps {
  links?: MenuLink[]
  // t: TranslationFunction
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  user: state.auth.user,
  config: state.config.auth || undefined,
})

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  }
}

class UserProfileMenu extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.logoutUser = this.logoutUser.bind(this)
  }

  private async logoutUser() {
    this.props.logoutUser()
    if (this.props.config && this.props.config.legacyLogout) {
      try {
        await this.props.config.legacyLogout()
      } catch (error) {
        console.error(error) // tslint:disable-line
      }
    }
  }

  public render() {
    if (!this.props.user) {
      return null
    }
    return (
      <div className="dropdown">
        <a
          className="dropdown-toggle profile-toggler"
          href="#"
          id="userProfileButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <IconComponent icon={'user'} className="mr-1" />
          <span title={"Roles: " + this.props.user.roles.map(r => r).join(', ')}>{this.props.user.displayName}</span>
        </a>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userProfileButton">
          {this.props.links
            ? this.props.links.map(menuLink => (
                <Link key={menuLink.path} className="dropdown-item" to={menuLink.path}>
                  {menuLink.i18nkey ? this.props.t(menuLink.i18nkey) : '???'}
                </Link>
              ))
            : null}
          {this.props.links && this.props.links.length > 0 ? <div className="dropdown-divider" /> : null}
          <a href="#" className="dropdown-item" onClick={this.logoutUser}>
            {this.props.t('auth.logout')}
          </a>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserProfileMenu))
