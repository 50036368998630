// * -------------------------------- NPM --------------------------------------
import * as React from "react"

interface Props {
  children: React.ReactNode
  noGutters?: boolean
}

class CardBody extends React.Component<Props> {
  public render(){
    const {
      children,
      noGutters
    } = this.props

    return (
      <div className={
        "card-body" +
        (noGutters ? " no-gutters" : '')
      }>
        {children}
      </div>
    )
  }
}

export default CardBody
