import {useComponent} from "@mv-submodules/inplant-components-fe/services/component";
import {TYPES} from "@mv-submodules/inplant-components-fe/services/binding";
import {IconComponentService, IconComponentServiceProps} from "@mv-submodules/inplant-components-fe/services/icon";

const IconComponent = (props: IconComponentServiceProps) => {
    const component = useComponent<IconComponentService>(TYPES.IconComponentService)
    return component?.render(props) || null
}

export default IconComponent
