export type ButtonVariants =
    'primary'
  | 'success'
  | 'primary-alternate'
  | 'secondary'
  | 'secondary-alternate'
  | 'danger'
  | 'danger-alternate'
  | 'info'
  | 'info-alternate'
  | 'dismiss'
  | 'dismiss-alternate'
  | 'warning'
  | 'warning-alternate'
  | 'light'
  | 'light-alternate'
  | 'link'

export const renderButtonClass = (variant: ButtonVariants, size?: 'sm' | 'lg') => {
  const base = 'btn'
  const sizeString = size && `btn-${size}` || ''
  let variantString = variant.toString()
  let outline = false
  if (variant.includes('alternate')) {
    outline = true
    variantString = variantString.replace("-alternate","")
  }
  const colorString = `btn-${outline && 'outline-' || ''}${variantString}`
  return `${base} ${colorString} ${sizeString}`

}

export type DataBalloonPos = 'up' | 'left' | 'right' | 'down' | 'up-left' | 'up-right' | 'down-left' | 'down-right' | 'none'

export type ButtonTypes = 'button' | 'submit' | 'reset' | undefined
