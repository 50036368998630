export const plantMetrics = [
  {
    id: 0,
    label: 'media',
    type: 'section',
    data: [
      {
        id: 0,
        label: 'plant',
        measures: [
          { memory: 'plantActivePower', name: 'ActivePower', unit: 'kWh' },
          { memory: 'plantGas', name: 'Gas', unit: 'Nm³' },
          { memory: 'plantThermalEnergyH', name: 'ThermalEnergyH', unit: 'kWh' },
          { memory: 'plantThermalEnergyC', name: 'ThermalEnergyC', unit: 'kWh' },
          { memory: 'plantRawWater', name: 'RawWater', unit: 'm³' },
          { memory: 'plantDemiWater', name: 'DemiWater', unit: 'm³' },
        ],
      },
      {
        id: 1,
        label: 'treatment',
        measures: [
          { memory: 'treatmentActivePower', name: 'ActivePower' },
          { memory: 'treatmentThermalEnergyH', name: 'ThermalEnergyH', unit: 'kWh' },
          { memory: 'treatmentRawWater', name: 'RawWater' },
        ],
      },
      {
        id: 2,
        label: 'cataphoresis',
        measures: [
          { memory: 'cataphoresisActivePower', name: 'ActivePower' },
          { memory: 'cataphoresisThermalEnergyC', name: 'ThermalEnergyC', unit: 'kWh' },
          { memory: 'cataphoresisThermalEnergyH', name: 'ThermalEnergyH', unit: 'kWh' },
          { memory: 'cataphoresisDemiWater', name: 'DemiWater' },
        ],
      },
      {
        id: 3,
        label: 'ovens',
        measures: [
          { memory: 'ovensCataOvenActivePower', name: 'CataOvenActivePower' },
          { memory: 'ovensCataOvenGas', name: 'CataOvenGas' },
          { memory: 'ovensPowderOvenActivePower', name: 'PowderOvenActivePower' },
          { memory: 'ovensPowderOvenGas', name: 'PowderOvenGas' },
          { memory: 'ovensPostBurnerActivePower', name: 'PostBurnerActivePower' },
          { memory: 'ovensPostBurnerGas', name: 'PostBurnerGas' },
        ],
      },
      {
        id: 4,
        label: 'booths',
        measures: [
          { memory: 'boothsActivePower', name: 'ActivePower' },
          { memory: 'boothsThermalEnergyC', name: 'ThermalEnergyC', unit: 'kWh' },
          { memory: 'boothsThermalEnergyH', name: 'ThermalEnergyH', unit: 'kWh' },
        ],
      },
    ],
  },
  {
    id: 1,
    label: 'chemicals_and_painting',
    type: 'section',
    data: [
      {
        id: 0,
        label: 'general',
        measures: [
          { memory: 'chemicalsPaste', name: 'Paste', unit: 'kg' },
          { memory: 'chemicalsBinder', name: 'Binder', unit: 'kg' },
          { memory: 'chemicalsSgsPd01', name: 'SgsPd01', unit: 'l' },
          { memory: 'chemicalsSg1Pd01', name: 'Sg1Pd01', unit: 'l' },
          { memory: 'chemicalsDisPd01', name: 'DisPd01', unit: 'l' },
          { memory: 'chemicalsConPd01', name: 'ConPd01', unit: 'l' },
        ],
      },
    ],
  },
]

export const mappedInfluxIDs = {
  pieces: 'pcs',
  qualityMeasure: 'pcs'
}
