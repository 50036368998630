// * -------------------------------- NPM --------------------------------------
import * as React from "react"

interface Props {
  children: React.ReactNode
}

class CardHeader extends React.Component<Props> {
  public render(){
    const {
      children,
    } = this.props

    return (
      <div className={"card-header"}>
        {children}
      </div>
    )
  }
}

export default CardHeader
