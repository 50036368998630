import { Template } from '../types'
import { Section } from './section'
import { SectionGroup } from './sectiongroup'
import { ComponentGroup } from './componentGroup'
import { Component } from './component'
import { Metric } from './metric'

export const MacroArea: Template = {
  id: 'macroarea',
  name: 'MacroArea',
  version: '0.1.1',
  date: '2019-02-21',
  fields: [
    {
      name: 'label',
      type: 'string',
      label: 'macroarea.label',
      width: 9,
      required: true,
      nodePath: 'data',
      translatable: true,
    },
    {
      name: 'hasQRCode',
      type: 'boolean',
      label: 'macroarea.hasQRCode',
      width: 4,
      nodePath: 'data',
      values: true
    },
    {
      name: 'inUse',
      type: 'boolean',
      label: 'macroarea.nodeIsActive',
      width: 4,
      nodePath: 'data',
      values: false,
    },
  ],
  copyable: true,
  allowedSubcomponents: [SectionGroup, Section, ComponentGroup, Component, Metric],
}
