// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { Key } from 'react'

// * -------------------------------- MODULE --------------------------------------
import {Color} from "../Utils/utils";
import {renderBackgroundColor, renderBorderColor} from "./types";

interface Props {
  children: React.ReactNode
  marginBottom?: number
  bgLight?: boolean
  borderColor?: Color
  backgroundColor?: Color
  key?: Key
}

class Card extends React.Component<Props> {
  public render() {
    const {
      bgLight,
      children,
      marginBottom,
      borderColor,
      backgroundColor,
      key
    } = this.props

    return (
      <div
        key={key}
        className={`card mb-${marginBottom || 3} ${bgLight ? 'bg-light' : ''} ${borderColor && renderBorderColor(borderColor) || ''} ${backgroundColor && renderBackgroundColor(backgroundColor) || ''}`}>
        {children}
      </div>
    )
  }
}

export default Card
