import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import Maintenances from '../MaintenancesComponent/MaintenancesComponent'
import { periodLabel, SideContentParams } from '../../../../types'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { Loader, Table, TableRowInfo } from '@mv-submodules/inplant-components-fe'
import { fetchFileData } from '../../../../redux/actions'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import MediaWidget from '../MediaWidget/MediaWidget'
import * as moment from 'moment'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export const API = () => FetchWrapper.getInstance('maintenance')

export interface TreeNodeOwnProps {
  data: any
  key: string
  id: string
  className?: string
  clickLeft?: () => void
  clickRight?: (id: string) => void
  onSidecontentChange: (params: SideContentParams) => void
  isCollapsed: boolean
  toggleCollapsed: (id: string, parent: string) => void
  parent: any
  path: string
  // t: TranslationFunction
  root: string | null
  spareParts: any
}

export interface TreeNodeOwnState {
  showDetails: boolean
  showDetailsSection: string | null
  detailsLoading: boolean
  detailsReady: boolean
  docs: any
  media: any
  columns: string[]
  isCollapsed: boolean
  maintenances: any[]
  maintenancesPath: string
  maintenancesLoading: boolean
  maintenancesReady: boolean
}

export interface DispatchProps {
  fetchFileData: (fileKey: string) => Promise<any>
}

export type TreeNodeProps = RouteComponentProps<{}> & TreeNodeOwnProps & DispatchProps & WithTranslation

export const mapStateToProps = (state: any) => ({
  fileData: state.smartdocs.storage.fileData,
  fileFetching: state.smartdocs.storage.fileFetching,
  fileError: state.smartdocs.storage.fileError,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchFileData: (fileKey: string) => dispatch(fetchFileData(fileKey)),
})

const defaultColumns = ['Filename', 'documentType', 'Date']

class TreeNode extends React.Component<TreeNodeProps, TreeNodeOwnState> {
  private isFullyMounted: boolean = false

  constructor(props: TreeNodeProps) {
    super(props)
    this.state = {
      showDetails: false,
      showDetailsSection: null,
      detailsLoading: false,
      detailsReady: false,
      docs: [],
      columns: defaultColumns,
      isCollapsed: this.props.isCollapsed,
      maintenances: [],
      maintenancesPath: '',
      maintenancesLoading: false,
      maintenancesReady: false,
      media: [],
    }

    this.rightClick = this.rightClick.bind(this)
    this.leftClick = this.leftClick.bind(this)
    this.getMaintenances = this.getMaintenances.bind(this)
    this.showMaintenances = this.showMaintenances.bind(this)
    this.downloadFile = this.downloadFile.bind(this)
  }

  public get isMounted() {
    return this.isFullyMounted
  }

  public set isMounted(status: boolean) {
    this.isFullyMounted = status
  }

  public componentDidMount() {
    this.isMounted = true
    this.getMaintenances(true)
  }

  public componentWillReceiveProps(nextProps: TreeNodeProps) {
    if (nextProps.isCollapsed && window.innerWidth > 1024) {
      this.setState({
        detailsLoading: false,
        detailsReady: false,
        showDetailsSection: '',
      })
    }

    if (!nextProps.isCollapsed && (!this.state.maintenances || this.state.maintenances.length < 1)) {
      this.getMaintenances(true)
    }
  }

  public componentWillUnmount(): void {
    this.isMounted = false
  }

  public render() {
    const pLabel = (period: string) => {
      const label = periodLabel(period)
      if (label) {
        return this.props.t(label)
      }

      return ''
    }

    const path = this.props.path + '.' + this.props.id
    // const spareParts = this.props.spareParts && path in this.props.spareParts ? this.props.spareParts[path] : null

    let treeDetails

    switch (this.state.showDetailsSection) {
      case 'maintenances':
        treeDetails = (
          <Maintenances
            showTitle={false}
            loading={this.state.maintenancesLoading}
            ready={this.state.maintenancesReady}
            data={{
              rows: this.state.maintenances,
              componentPath: this.state.maintenancesPath,
            }}
          />
        )
        break

      case 'Media':
        treeDetails = <MediaWidget showTitle={false} loading={false} ready={true} data={this.state.media} />
        break

      case 'manuals':
      case 'certificates':
      case 'dataSheets':
      case 'sparePartsList':
      case 'accessories':
      case 'spareParts':
        treeDetails =
          this.state.showDetailsSection !== 'spareParts' ? (
            <Table
              data={this.state.docs}
              columns={[
                {
                  Header: null,
                  sortable: false,
                  accessor: 'column1',
                  Cell: ({ original }: TableRowInfo) => (
                    <div>
                      <a onClick={e => this.downloadFile(e, original.link)} href={original.link}>
                        <span>
                          {original.column1.indexOf('/') > -1 ? original.column1.split('/').pop() : original.column1}
                        </span>
                      </a>
                      <br />
                      <span className="float-left meta mt-0">
                        {this.props.t('moduleSmartdocs.media.types.' + original.column2)}
                      </span>
                    </div>
                  ),
                },
                {
                  Header: this.props.t('treeDetailsdocumentType'),
                  accessor: 'column2',
                  Cell: ({ original }: TableRowInfo) => (
                    <div className="meta">
                      <span className="float-right text-right">{moment(original.column3).format('L')}</span>
                    </div>
                  ),
                  width: 92,
                },
              ]}
              showPagination={false}
              showPaginationBottom={false}
              noDataText={''}
              isFetching={false}
            />
          ) : (
            <Table
              data={this.state.docs}
              columns={[
                {
                  Header: null,
                  sortable: false,
                  accessor: 'column1',
                  Cell: ({ original }: TableRowInfo) => (
                    <div>
                      <span>{original.column1}</span>
                      <span className="float-left">{original.column2}</span>
                    </div>
                  ),
                },
                {
                  Header: null,
                  sortable: false,
                  accessor: 'column3',
                  Cell: ({ original }: TableRowInfo) => (
                    <div className="meta">
                      <span className="float-right text-right">{original.column2}</span>
                    </div>
                  ),
                },
              ]}
              showPagination={false}
              showPaginationBottom={false}
              noDataText={''}
              isFetching={false}
            />
          )
        break
    }

    const params = new URLSearchParams(this.props.location.search)
    const nodeId = params.get('id')

    return (
      <div
        className={
          'cards card-level' +
          (this.hasAnyMedia() ||
          (this.state.maintenances && this.state.maintenances.length > 0) ||
          (this.props.spareParts && path in this.props.spareParts)
            ? ' has-media'
            : ' no-media') +
          (this.props.isCollapsed && this.props.data.data.id !== nodeId ? ' is-collapsed' : '') +
          (this.state.detailsReady ? ' data-open' : '') +
          (this.props.className ? ' ' + this.props.className : '')
        }
        data-id={this.props.data.data.id}
      >
        {this.props.data.data.hasQRCode && (
          <IconComponent
            icon={'qrcode'}
            className={'node-has-qrcode tooltips node-has-qrcode__absolute'}
            data-title={this.props.t('designer.node.attributes.hasQRCode')}
          />
        )}
        <div className="card-inner">
          <div className="card-title">
            <Row>
              <Column xs={12}>
                <div onClick={() => this.toggleCollapse()}>
                  <h4 title={this.props.data.data.label}>
                    {this.props.data.data.label.length > 32
                      ? this.props.data.data.label.substr(0, 32) + '...'
                      : this.props.data.data.label}
                  </h4>
                  {this.props.data.data.id && (
                    <p>
                      <small>
                        <strong>ID:</strong> {this.props.data.data.id}
                      </small>
                    </p>
                  )}
                  {this.props.data.data.pid && (
                    <p>
                      <strong>P&amp;ID:</strong> {this.props.data.data.pid}
                    </p>
                  )}

                  {(this.hasAnyMedia() || (this.state.maintenances && this.state.maintenances.length > 0)) &&
                    (this.props.isCollapsed ? (
                      <IconComponent className={'d-none d-xl-block'} icon={'plus'} />
                    ) : (
                      <IconComponent className={'d-none d-xl-block'} icon={'minus'} />
                    ))}
                </div>
              </Column>
            </Row>
          </div>
          {this.state.maintenances &&
            this.state.maintenances.filter((m: any) => {
              return m.maintenance && m.maintenance.id
            }).length > 0 && (
              <div className="card-details">
                <Row>
                  <Column xs={12}>
                    <h4>{this.props.t('treeDetails.periodicMaintenance')}</h4>
                    <ul className="list-group list-group-flush">
                      {this.state.maintenancesLoading && <Loader />}
                      {!this.state.maintenancesLoading &&
                        this.state.maintenancesReady &&
                        this.state.maintenances
                          .filter((m: any) => {
                            return m.maintenance && m.maintenance.id
                          })
                          .map((m: any, id: number) => (
                            <li className="list-group-item" key={id}>
                              <span className="label">{m.operation}</span>
                              <span className="value">
                                {m.maintenance.periodicity ? (
                                  <span className="period">{pLabel(m.maintenance.periodicity)}</span>
                                ) : (
                                  ''
                                )}
                                {m.maintenance.totalHours ? (
                                  <span className="totalHours">
                                    {m.maintenance.totalHours +
                                      ' ' +
                                      this.props.t(`moduleSmartdocs.maintenance.addJob.hours`)}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            </li>
                          ))}
                    </ul>
                  </Column>
                </Row>
              </div>
            )}

          <div className="row card-actions">
            <div className="col-3 row-actions__left">
              {this.props.data.data.hasQRCode && (
                <IconComponent
                  icon={'qrcode'}
                  className={'node-has-qrcode tooltips node-has-qrcode__inline'}
                  data-title={this.props.t('designer.node.attributes.hasQRCode')}
                />
              )}
            </div>
            <div className="col-9 row-actions__right text-center justify-content-md-between">
              {this.hasDocumentType('Manual') && (
                <a
                  onClick={() => this.handleDocs(this.getDocumentsByType('Manual'), 'manuals')}
                  target="_blank"
                  data-title={this.props.t('moduleSmartdocs.media.types.Manuals')}
                  className={
                    'btn btn-outline-secondary action-manuals tooltips' +
                    (this.state.showDetailsSection === 'manuals' ? ' active' : '')
                  }
                >
                  <IconComponent icon={'file-pdf'} />
                </a>
              )}

              {this.hasDocumentType('Certification') && (
                <a
                  onClick={() => this.handleDocs(this.getDocumentsByType('Certification'), 'certificates')}
                  target="_blank"
                  data-title={this.props.t('moduleSmartdocs.media.types.Certifications')}
                  className={
                    'btn btn-outline-secondary action-certificates tooltips' +
                    (this.state.showDetailsSection === 'certificates' ? ' active' : '')
                  }
                >
                  <IconComponent icon={'check-square'} />
                </a>
              )}

              {this.hasDocumentType('TechnicalFile') && (
                <a
                  onClick={() => this.handleDocs(this.getDocumentsByType('TechnicalFile'), 'dataSheets')}
                  target="_blank"
                  data-title={this.props.t('moduleSmartdocs.media.types.TechnicalFiles')}
                  className={
                    'btn btn-outline-secondary action-dataSheets tooltips' +
                    (this.state.showDetailsSection === 'dataSheets' ? ' active' : '')
                  }
                >
                  <IconComponent icon={'list'} />
                </a>
              )}

              {this.hasDocumentType('SpareParts') && (
                <a
                  onClick={() => this.handleDocs(this.getDocumentsByType('SpareParts'), 'sparePartsList')}
                  target="_blank"
                  data-title={this.props.t('moduleSmartdocs.media.types.SpareParts')}
                  className={
                    'btn btn-outline-secondary action-sparePartsList tooltips' +
                    (this.state.showDetailsSection === 'sparePartsList' ? ' active' : '')
                  }
                >
                  <IconComponent icon={'boxes'} />
                </a>
              )}

              {this.hasDocumentType('Accessories') && (
                <a
                  onClick={() => this.handleDocs(this.getDocumentsByType('Accessories'), 'accessories')}
                  target="_blank"
                  data-title={this.props.t('moduleSmartdocs.media.types.Accessories')}
                  className={
                    'btn btn-outline-secondary action-accessories tooltips' +
                    (this.state.showDetailsSection === 'accessories' ? ' active' : '')
                  }
                >
                  <IconComponent icon={'project-diagram'} />
                </a>
              )}

              {this.state.maintenances && this.state.maintenances.length > 0 && (
                <a
                  onClick={() => this.showMaintenances()}
                  target="_blank"
                  data-title={this.props.t('moduleSmartdocs.media.types.Maintenances')}
                  className={
                    'btn btn-outline-secondary action-maintenances tooltips' +
                    (this.state.showDetailsSection === 'maintenances' ? ' active' : '')
                  }
                >
                  <IconComponent icon={'wrench'} />
                </a>
              )}

              {this.props.spareParts && path in this.props.spareParts && (
                <a
                  onClick={() => this.handleDocs(this.props.spareParts[path], 'spareParts')}
                  data-title={this.props.t('moduleSmartdocs.media.types.SpareParts')}
                  className={
                    'btn btn-outline-secondary action-spareParts tooltips' +
                    (this.state.showDetailsSection === 'spareParts' ? ' active' : '')
                  }
                >
                  <IconComponent icon={'puzzle-piece'} />
                </a>
              )}

              {this.props.data.data && this.props.data.data.media && this.props.data.data.media.length > 0 && (
                <a
                  onClick={() => this.handleMedia(this.props.data.data.media)}
                  data-title={this.props.t('moduleSmartdocs.media.types.Media')}
                  className={
                    'btn btn-outline-secondary action-media tooltips' +
                    (this.state.showDetailsSection === 'Media' ? ' active' : '')
                  }
                >
                  <IconComponent icon={'file-video'} />
                </a>
              )}
            </div>
          </div>
        </div>
        {this.props.data.subcomponents &&
          this.props.data.subcomponents.filter((e: any) => e.level !== 'Metric').length > 0 &&
          this.props.clickRight && (
            <div onClick={this.rightClick} className="next">
              <span>
                <svg width="20" height="40" viewBox="0 0 20 40">
                  <path stroke="#000" fill="transparent" d="M0,0 l20,20 l-20,20" />
                </svg>
              </span>
            </div>
          )}

        {this.props.parent &&
          this.props.root !== null &&
          this.props.parent !== this.props.root &&
          this.props.clickLeft && (
            <div onClick={this.leftClick} className="prev">
              <span>
                <svg width="20" height="40" viewBox="0 0 20 40">
                  <path stroke="#000" fill="transparent" d="M20,0 l-20,20 l20,20" />
                </svg>
              </span>
            </div>
          )}

        <div
          className={
            'card-data ' + (this.state.detailsLoading ? 'loading' : '') + (this.state.detailsReady ? ' active' : '')
          }
        >
          <div className="card-data-inner">
            <h4>{this.props.t('treeDetailsTitle' + this.state.showDetailsSection)}</h4>
            {treeDetails}
          </div>
        </div>
      </div>
    )
  }

  private handleMedia(media: any[]) {
    const toDisable = 'Media' === this.state.showDetailsSection

    if (toDisable) {
      this.setState({
        showDetails: !toDisable,
        showDetailsSection: toDisable ? null : 'Media',
        detailsLoading: !toDisable,
        detailsReady: !toDisable,
      })
    } else {
      const selectedColumns = ['Name']

      this.setState({
        detailsLoading: false,
        detailsReady: true,
        media,
        columns: selectedColumns,
        showDetails: true,
        showDetailsSection: 'Media',
      })

      this.props.onSidecontentChange({
        show: true,
        title: this.props.data.data.label,
        type: 'Media',
        columnNames: selectedColumns,
        data: [],
        media,
        toDisable,
      })
    }
  }

  private handleDocs(doc: any, type: string) {
    const toDisable = type === this.state.showDetailsSection

    this.setState({
      showDetails: !toDisable,
      showDetailsSection: toDisable ? null : type,
      detailsLoading: !toDisable,
      detailsReady: !toDisable,
    })

    if (toDisable) {
      setTimeout(() => {
        this.setState({
          detailsLoading: false,
          detailsReady: false,
        })

        this.props.onSidecontentChange({
          show: false,
          title: '',
          type: '',
          columnNames: [],
          data: [],
          toDisable: true,
        })
      }, 300)
    } else {
      let data
      let selectedColumns

      if (type !== 'spareParts') {
        selectedColumns = Object.keys(doc[0])

        data = doc.map((d: any) => {
          return {
            column1: d.label ? d.label : d.name,
            column2: d.documentType,
            column3: d.lastEdit,
            isLink: true,
            link: d.link,
          }
        })

        this.setState({
          detailsLoading: false,
          docs: toDisable ? [] : data,
          columns: selectedColumns,
        })

        this.props.onSidecontentChange({
          show: true,
          title: this.props.data.data.label,
          type,
          columnNames: selectedColumns,
          data,
          toDisable,
        })
      } else {
        // spareParts
        selectedColumns = Object.keys(doc[0])

        data = doc.map((d: any) => {
          return {
            column1: String(d.code),
            column2: String(d.description),
            column3: String(d.price),
            isLink: false,
            link: null,
          }
        })

        this.setState({
          detailsLoading: false,
          docs: toDisable ? [] : data,
          columns: selectedColumns,
        })

        this.props.onSidecontentChange({
          show: true,
          title: this.props.data.data.label,
          type,
          columnNames: selectedColumns,
          data,
          toDisable,
        })
      }
    }
  }

  private rightClick() {
    if (this.props.clickRight) {
      this.props.clickRight(this.props.path + '.' + this.props.id)
    }
  }

  private leftClick() {
    if (this.props.clickLeft) {
      this.props.clickLeft()
    }
  }

  private downloadFile(e: React.MouseEvent<HTMLSpanElement>, link: string) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    this.props
      .fetchFileData(link)
      .then((uniqueUrl: string) => {
        window.open(uniqueUrl)
      })
      .catch((error: any) => {
        console.log('unique -  error', error) // tslint:disable-line
        // @todo notification
      })
  }

  private hasAnyMedia() {
    return (
      this.hasDocumentType('Manual') ||
      this.hasDocumentType('Certification') ||
      this.hasDocumentType('TechnicalFile') ||
      this.hasDocumentType('SpareParts') ||
      this.hasDocumentType('Accessories')
    )
  }

  private hasDocumentType(docType: string) {
    return this.props.data && this.props.data.data.documents.filter((d: any) => d.documentType === docType).length > 0
  }

  private getDocumentsByType(docType: string) {
    if (!this.props.data) {
      return []
    }

    return this.props.data.data.documents.filter((d: any) => d.documentType === docType)
  }

  private toggleCollapse() {
    this.props.toggleCollapsed(this.props.id, this.props.parent)
  }

  private async getMaintenances(periodicOnly: boolean = false) {
    try {
      if (this.props.path) {
        this.setState({
          detailsLoading: true,
          showDetails: false,
          maintenancesLoading: true,
          maintenancesReady: false,
        })

        const path = this.props.path.replace(/\./g, '/') + '/' + this.props.id
        const qs =
          '?clientFilters=' +
          encodeURIComponent(
            JSON.stringify({
              targetId: path,
              statuses: ['overdue', 'postponed', 'planned', 'unhandled'],
            })
          )
        const response = await API().request(`/maintenances/scheduled-jobs${qs}`)

        if (this.isMounted) {
          let newState = {}
          if (response && response.length > 0) {
            newState = {
              maintenances: response,
              maintenancesPath: path,
              showDetails: !periodicOnly,
              showDetailsSection: periodicOnly ? '' : 'maintenances',
              detailsLoading: false,
              detailsReady: !periodicOnly,
              maintenancesLoading: false,
              maintenancesReady: true,
            }
            this.props.onSidecontentChange({
              show: !periodicOnly,
              title: this.props.data.label,
              type: periodicOnly ? '' : 'maintenances',
              columnNames: [],
              data: {
                rows: response,
                componentPath: path,
              },
              toDisable: false,
            })
          } else {
            newState = {
              showDetailsSection: periodicOnly ? '' : 'maintenances',
              detailsLoading: false,
              detailsReady: !periodicOnly,
              showDetails: !periodicOnly,
              maintenancesLoading: false,
              maintenancesReady: true,
            }
          }

          this.setState(newState)
        }
      }
    } catch (error:any) {
      if (error.statusCode === 401) {
        logoutUser()
      }
      throw error
    }
  }

  private showMaintenances() {
    if (this.state.maintenances && this.state.maintenancesReady) {
      const path = this.props.path.replace(/\./g, '/') + '/' + this.props.id
      const newState = {
        maintenancesPath: path,
        showDetails: true,
        showDetailsSection: 'maintenances',
        detailsLoading: false,
        detailsReady: true,
        maintenancesLoading: false,
        maintenancesReady: true,
      }
      this.props.onSidecontentChange({
        show: true,
        title: this.props.data.label,
        type: 'maintenances',
        columnNames: [],
        data: {
          rows: this.state.maintenances,
          componentPath: path,
        },
        toDisable: false,
      })

      this.setState(newState)
    } else {
      this.getMaintenances()
    }
  }
}

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TreeNode)))
