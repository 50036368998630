import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Modal, Form, Alert } from '@mv-submodules/inplant-components-fe'
import GenericFieldset from './GenericFieldset'
import {
  getInputValueFromData,
  getInputFieldsRefs,
  appendRelFields,
  parseFieldValue,
  clearRelFields,
  stringifyData,
  RelFields,
} from './utils'
import { FetchError } from '../../../../types/error'
import { FormData, Fieldset, FieldsetField, FormFieldsMap, File } from '../../../../types/pageForm'

interface Props {
  visible: boolean
  title: string
  fields: Fieldset[]
  onClose: () => void
  onSubmit: (data: FormData) => Promise<any>
  submitTemplate?: (data: FormData) => Promise<any>
  submitLabel: string
  cancelLabel: string
  moreButtons?: React.ReactNode
  data?: FormData
}

interface OwnState {
  error?: FetchError
  data: FormData
  fieldRefs: FormFieldsMap<React.RefObject<any>>
  relFields: RelFields
  isSubmitting: boolean
}

class FormModal extends React.Component<Props & WithTranslation, OwnState> {
  constructor(props: Props & WithTranslation) {
    super(props)
    this.state = {
      data: props.data || {},
      error: undefined,
      fieldRefs: props.fields.reduce(
        (acc, fieldset) => ({
          ...acc,
          ...getInputFieldsRefs(fieldset.fields),
        }),
        {}
      ),
      isSubmitting: false,
      relFields: props.fields.reduce(appendRelFields, {}),
    }
    this.getInputValue = this.getInputValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleError = this.handleError.bind(this)
    this.renderFields = this.renderFields.bind(this)
    this.handleUploadFiles = this.handleUploadFiles.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  public render() {
    const props = this.props
    const errorMessage = this.state && this.state.error && this.state.error.message
    return (
      <Modal
        visible={props.visible}
        onClose={this.onClose}
        title={props.title} closeOnFocusOut={false}
        customModalBody={true}
      >
        <Form
          onSubmit={this.handleSubmit}
          onError={this.handleError}
          submitLabel={props.submitLabel}
          onCancel={this.onClose}
          cancelLabel={props.cancelLabel}
          moreButtons={props.moreButtons}
          isInsideModal={true}
        >
          {errorMessage && <Alert type="danger" title={errorMessage} />}
          {props.fields.map(this.renderFields)}
          {errorMessage && props.fields.length > 5 ? <Alert type="danger" title={errorMessage} /> : null}
        </Form>
      </Modal>
    )
  }

  private onClose() {
    this.setState({
      data: this.props.data || {},
      error: undefined,
      fieldRefs: this.props.fields.reduce(
        (acc, fieldset) => ({
          ...acc,
          ...getInputFieldsRefs(fieldset.fields),
        }),
        {}
      ),
      relFields: this.props.fields.reduce(appendRelFields, {}),
    })
    this.props.onClose()
  }

  private async handleSubmit() {
    try {
      const data: FormData = this.state.data
      await this.props.onSubmit(data)
    } catch (error:any) {
      this.setState({ error })
    }
  }

  private handleError() {
    this.setState({
      error: {
        name: this.props.t('maintenance.dueDates.formField.validationError.title'),
        statusCode: 400,
        message: this.props.t('maintenance.dueDates.formField.validationError.description'),
        errors: Object.keys(this.getInvalidRefs()).reduce((acc, key) => {
          acc[key] = [this.props.t('maintenance.dueDates.formField.validationError.invalidField')]
          return acc
        }, {}),
      },
    })
  }

  private getInvalidRefs() {
    return Object.keys(this.state.fieldRefs).reduce((acc: FormFieldsMap<React.RefObject<any>>, inputName: string) => {
      const input = this.state.fieldRefs[inputName]
      if (input && input.current && input.current.checkValidity && !input.current.checkValidity()) {
        acc[inputName] = input
      } else if (
        input &&
        input.current &&
        input.current.input &&
        input.current.input.checkValidity &&
        !input.current.input.checkValidity()
      ) {
        acc[inputName] = input
      } // DatePicker
      return acc
    }, {})
  }

  private handleInputChange(slug: string, value: any, template?: boolean) {
    // console.debug(`change ${slug}:`, value, template ? '(template)' : '') // tslint:disable-line // removed, it causes slow render in text fields
    const relFields: FieldsetField[] = []
    const pushRelatedField = (fieldSlug: string) => {
      // tslint:disable-line
      if (this.state.relFields[fieldSlug]) {
        relFields.push(...this.state.relFields[fieldSlug])
        this.state.relFields[fieldSlug].forEach(field => field['slug'] && pushRelatedField(field['slug'])) // tslint:disable-line
      }
    }
    ;[slug].forEach(pushRelatedField)

    this.setState(currentState => {
      let data = {
        ...currentState.data,
        ...parseFieldValue(slug, value, currentState.data),
      }
      let errors = currentState.error ? { ...currentState.error.errors } : {}
      if (errors[slug]) {
        delete errors[slug]
      }
      if (relFields.length) {
        data = relFields.reduce(clearRelFields, data)
        errors = (relFields as any).reduce(clearRelFields, errors)
      }
      if (template && this.props.submitTemplate) {
        this.props
          .submitTemplate(stringifyData(data))
          .then((additionalData: FormData | undefined) => {
            if (additionalData) {
              this.setState(state => ({
                // tslint:disable-line
                ...state,
                data: Object.keys(additionalData).reduce(
                  (acc, key) => parseFieldValue(key, additionalData[key], acc),
                  state.data
                ),
              }))
            }
          })
          .catch(console.warn) // tslint:disable-line
      }
      return {
        ...currentState,
        error:
          errors && Object.keys(errors)
            ? {
                ...(currentState.error as FetchError),
                message: '', // hide error message on input change
                errors,
              }
            : undefined,
        data,
      }
    })
  }

  private handleUploadFiles(file: File, remove?: boolean) {
    let data = {} // data to replace this.state.data with
    if (remove) {
      // if boolean remove is true the value needs to be removed from the array
      const index = this.state.data.attachments.findIndex((element: File) => element.id === file.id)
      if (index !== -1) {
        const attachments = this.state.data.attachments
        attachments.splice(index)
        data = {
          ...this.state.data,
          attachments,
        }
      }
    } else {
      // here is the insert or update
      if (Array.isArray(this.state.data.attachments) && this.state.data.attachments.length > 0) {
        // if there is at least one file in  attachments
        // if there is one attachments that has the same id of the file passed, update the info on that
        if (this.state.data.attachments.findIndex((element: File) => element.id === file.id) !== -1) {
          const attachments = this.state.data.attachments.map((element: File) =>
            element.id === file.id ? file : element
          )
          data = {
            ...this.state.data,
            attachments,
          }
        } else {
          // otherwise push on attachments the new file
          const attachments = this.state.data.attachments
          attachments.push(file)
          data = {
            ...this.state.data,
            attachments,
          }
        }
      } else {
        // if there isn't attachments create a new array of attachments with the file provided
        data = {
          ...this.state.data,
          attachments: [file],
        }
      }
    }
    this.setState(currentState => ({
      ...currentState,
      data,
    }))
  }

  private getInputValue(slug: string) {
    return getInputValueFromData(slug, this.state.data)
  }

  private renderFields(fieldset: Fieldset) {
    // to do remove switch
    // switch (fieldset.fieldset) {
    //   default:
    return (
      <GenericFieldset
        key={fieldset.fieldset}
        refs={this.state.fieldRefs}
        fields={fieldset.fields}
        errors={(this.state.error && this.state.error.errors) || undefined}
        hiddenFields={fieldset.hiddenFields}
        requiredFields={fieldset.requiredFields}
        getInputValue={this.getInputValue}
        onInputChange={this.handleInputChange}
        handleUploadFiles={this.handleUploadFiles}
      />
    )
    // }
  }
}

export default withTranslation()(FormModal)
