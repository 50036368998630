// * -------------------------------- NPM --------------------------------------
import * as React from "react";

// * -------------------------------- MODULE --------------------------------------
import PrintButton from "../Button/PrintButton";
import {ButtonVariants} from "../Button/types";
import Button from "../Button/Button";
import { Icon } from "@mv-submodules/inplant-components-fe/services/icon";

export interface SubtitleButtonGroupsConfig {
  id?: string,
  label?: string,
  icon?: Icon,
  variant?: ButtonVariants,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  isLoading?: boolean
  printButton?: boolean
  disabled?: boolean
}

interface Props {
  buttons: SubtitleButtonGroupsConfig[]
}

class SubtitleButtonGroups extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <div className="button-group-header d-flex subtitle">
        {this.props.buttons.map((button, index) => {
          if (!button.printButton && !button.variant) {
            return null
          }
          if (button.printButton) {
            return (
              <PrintButton
                key={`${button.id || button.label}${index * Math.random()}`}
                onClick={button.onClick}
                isPrinting={button.isLoading}
                disabled={button.disabled}
                size="btn-sm"
              />
            )
          }
          return (
            <Button
              key={`${button.id || button.label}${index * Math.random()}`}
              variant={button.variant!}
              label={button.label}
              icon={button.icon}
              onClick={button.onClick}
              disabled={button.disabled}
              size="sm"
              spacing={{horizontal:false}}
            />
          )
        })}
      </div>
    );
  }
}

export default SubtitleButtonGroups