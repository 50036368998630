import { IconDefinition, faWrench } from '@fortawesome/free-solid-svg-icons'
import I4Efficiency from './components/views/I4Efficiency/I4EfficiencyPageView'
import OeeDetails from './components/views/OeeDetails/OeeDetailsPageView'
import QualityDetails from './components/views/QualityDetails/QualityDetailsPageView'
import AvailabilityDetails from './components/views/AvailabilityDetails/AvailabilityDetailsPageView'

export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey?: string
  icon?: IconDefinition
  iconOverride?: string
  visible: boolean
  component?: any
  children?: RouteProps[]
  aclActionKey?: string
}

const routes: RouteProps = {
  path: '/i4efficiency',
  i18nkey: 'i4efficiency.module',
  icon: faWrench,
  component: I4Efficiency,
  visible: true,
  aclActionKey: 'efficiency.efficiency.list',
  children: [
    {
      path: '/i4efficiency',
      i18nkey: 'i4efficiency.module.overview',
      component: I4Efficiency,
      visible: true,
      exact: true,
      aclActionKey: 'efficiency.efficiency.list',
    },
    {
      path: '/i4efficiency/oee-details',
      i18nkey: 'i4efficiency.module.oee-details',
      component: OeeDetails,
      visible: true,
      exact: true,
      aclActionKey: 'efficiency.efficiency.list',
    },
    {
      path: '/i4efficiency/quality-details',
      i18nkey: 'i4efficiency.module',
      component: QualityDetails,
      visible: false,
      exact: true,
      aclActionKey: 'efficiency.efficiency.list',
    },
    {
      path: '/i4efficiency/availability-details',
      i18nkey: 'i4efficiency.module.availability-details',
      component: AvailabilityDetails,
      visible: true,
      exact: true,
      aclActionKey: 'efficiency.efficiency.list',
    },
  ],
}

export default routes
