import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
/*tslint:disable*/
// const DateRangePickerImport = require('react-bootstrap-daterangepicker')
// const DateRangePicker = DateRangePickerImport.default
/*tslint:enable*/
import * as moment from 'moment'
import * as CurrencyFormat from 'react-currency-format'
import { ModuleConfig, MaintenanceScheduledJob, MaintenanceScheduledJobPatchParams } from '../../../../types'
import { getConfigParam, getDateFormat } from '../../../../functions/shared'
import { markScheduledJobAsDone } from '../../../../redux/actions'
import { DateInput, Input, Modal, TextArea } from '@mv-submodules/inplant-components-fe'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

export interface StateProps {
  config: ModuleConfig
}

interface OwnStateProps {
  executedDate: moment.Moment
  executedCost: string
  executedTime: string
  executedNotes: string
  isSubmitting: boolean
}

interface OwnProps {
  isVisible: boolean
  scheduledJob: MaintenanceScheduledJob
  onClose?: Function | null
  // t: TranslationFunction
}

export const mapStateToProps = (state: any) => ({
  config: state.config,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  markScheduledJobAsDone: (params: MaintenanceScheduledJobPatchParams) => dispatch(markScheduledJobAsDone(params)),
})

export interface DispatchProps {
  markScheduledJobAsDone: (params: MaintenanceScheduledJobPatchParams) => Promise<any>
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

class MarkAsDoneModal extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = this.getInitialState()
    this.onSubmit = this.onSubmit.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.showCostAndTime = this.showCostAndTime.bind(this)
  }

  private getInitialState() {
    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const initialState = {
      executedDate: today.clone(),
      executedCost: '',
      executedTime: '',
      executedNotes: '',
      isSubmitting: false,
    }

    return initialState
  }

  private resetState() {
    this.setState(this.getInitialState())
  }

  private onSubmit(e: any) {
    e.preventDefault()
    this.setState({ isSubmitting: true })
    if (!this.props.scheduledJob) {
      return
    }

    const id = this.props.scheduledJob.id
    const params: MaintenanceScheduledJobPatchParams = {
      id,
      date: this.state.executedDate,
      notes: this.state.executedNotes,
    }

    if (this.showCostAndTime()) {
      if (this.state.executedCost) {
        params.cost = parseFloat(this.state.executedCost) * 100
      }

      if (this.state.executedTime) {
        params.time = parseFloat(this.state.executedTime)
      }
    }

    this.props
      .markScheduledJobAsDone(params)
      .then((data: any) => {
        this.onModalClose(true)
      })
      .catch(error => {
        console.error(error) // tslint:disable-line
      })
      .finally(() => {
        this.setState({ isSubmitting: false })
      })
  }

  public onModalClose(reload: boolean = false) {
    this.resetState()
    if (this.props.onClose) {
      this.props.onClose(reload)
    }
  }

  private showCostAndTime() {
    return getConfigParam(this.props.config, 'enableScheduledJobConstAndTime', false)
  }

  public render() {
    const { config, t } = this.props
    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const currency: string = getConfigParam(config, 'currency', '')

    return (
      <form onSubmit={this.onSubmit}>
        <Modal
          visible={this.props.isVisible}
          width={50}
          onClose={() => this.onModalClose()}
          title={t('maintenance.scheduledJobDetail.modals.markAsDone.title')}
          additionalFooterButtons={[
            {
              label: t('maintenance.scheduledJobDetail.modals.markAsDone.confirm'),
              variant: 'primary',
              type: 'submit',
              isLoading: this.state.isSubmitting,
            },
          ]}
          closeLabel={t('maintenance.close')}
        >
          <>
            <div className="form-group">
              <DateInput
                label={t('maintenance.scheduledJobDetail.modals.markAsDone.dueDate')}
                max={today.toString()}
                onChange={value => this.setState({ executedDate: moment(value) })}
                value={this.state.executedDate.format(getDateFormat())}
                id="executedDate"
              />
            </div>
            {this.showCostAndTime() && (
              <Row >
                <Column xs={6} formGroup={true}>
                  <label htmlFor="executedCost">{t('maintenance.scheduledJobDetail.modals.markAsDone.cost')}</label>
                  <CurrencyFormat
                    id="executedCost"
                    className="form-control"
                    value={this.state.executedCost}
                    allowNegative={false}
                    isNumericString={true}
                    suffix={currency}
                    placeholder={`ex. 12,0 ${currency}`}
                    onValueChange={(values: any) => {
                      const { value } = values
                      this.setState({ executedCost: value })
                    }}
                  />
                </Column>
                <Column xs={6}>
                  <Input
                    label={t('maintenance.scheduledJobDetail.modals.markAsDone.time')}
                    type="number"
                    min={0}
                    pattern={/[0-9]+([,\.][0-9]+)?/}
                    id="executedTime"
                    value={this.state.executedTime}
                    placeholder="ex. 0.5"
                    onChange={value => this.setState({ executedTime: value as string })}
                  />
                </Column>
              </Row>
            )}
            <div className="form-group">
              <TextArea
                label={t('maintenance.scheduledJobDetail.modals.markAsDone.notes')}
                id="executedNotes"
                value={this.state.executedNotes}
                onChange={value => this.setState({ executedNotes: value as string })}
                rows={3}
              />
            </div>
          </>
        </Modal>
      </form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MarkAsDoneModal))
