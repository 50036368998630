import { maintenanceReducer as maintenance, MaintenanceRouter } from '@mv-submodules/inplant-maintenance-fe'
import { serviceReducer as service } from '@mv-submodules/inplant-service-fe'
import { smartdocsReducer as smartdocs } from '@mv-submodules/inplant-smartdocs-fe'
import { designerReducer as designer } from '@mv-submodules/inplant-designer-fe'
import { userReducer as user } from '@mv-submodules/inplant-user-fe'
import { production } from '@mv-submodules/inplant-production-fe-imel/redux/reducers'
import { reducers as costs } from '@mv-submodules/inplant-costs-fe-imel/redux'
import { graphI4EnvironmentData } from '@mv-submodules/inplant-environment-fe-imel/redux/reducers'
import { dateFilters } from '@mv-submodules/inplant-core-fe/ui/components/widgets/dateFiltersBase/reducers'
import {
  graphGaugesData,
  graphPlanRunData,
  graphPlanRunOeeData,
  graphConsumptionData,
  graphQualityData,
  qualityDetailsData,
  availabilityDetailsData,
  graphAvailabilityData,
  availabilityDetailsAlarmsData,
  graphPerformanceData,
  graphTPRNCData,
} from '@mv-submodules/inplant-efficiency-fe-imel/redux/reducers'
import { authReducer } from '@mv-submodules/inplant-core-fe/auth'
import { measureUnits } from './data'
import { plantMetrics } from './graphics'

import { planStatusOverTime } from '@mv-submodules/inplant-plantstatusovertime-fe-imel/redux/reducers'

interface MaintenancePage {
  name: string
  i18nkey: string
  description?: string
  pagePath: string
  pageType: 'calendar' | 'plan' | 'setup'
  slug: string
  component: any
  hiddenCols: null | string[]
  enableNewButton: boolean
}

const pages: MaintenancePage[] = [
  {
    name: 'Calendar',
    i18nkey: 'maintenance.navigation.calendar',
    pagePath: 'calendar',
    pageType: 'calendar',
    slug: 'calendar',
    component: MaintenanceRouter,
    hiddenCols: null,
    enableNewButton: true,
  },
  {
    name: 'Plan',
    i18nkey: 'maintenance.navigation.plan',
    description: 'List of all on condition and periodic interventions',
    pagePath: 'plan',
    pageType: 'plan',
    slug: 'plan',
    component: MaintenanceRouter,
    hiddenCols: ['userFullName', 'acknowledged', 'notes', 'dueDate'],
    enableNewButton: true,
  },
  {
    name: 'History',
    i18nkey: 'maintenance.navigation.history',
    description: 'List of archived interventions',
    pagePath: 'history',
    pageType: 'plan',
    slug: 'history',
    component: MaintenanceRouter,
    hiddenCols: ['userFullName', 'acknowledged', 'notes', 'dueDate'],
    enableNewButton: true,
  },
  {
    name: 'Setup',
    i18nkey: 'maintenance.navigation.setup',
    description: 'List of periodic interventions rules',
    pagePath: 'setup',
    pageType: 'setup',
    slug: 'setup',
    component: MaintenanceRouter,
    hiddenCols: [],
    enableNewButton: true,
  },
]

export const config = {
  generic: {
    loginSuccessPath: '/dashboard',
    measureUnits,
    plantMetrics,
    updateNotification: (window as any).UPDATE_NOTIFICATION || process.env.REACT_APP_UPDATE_NOTIFICATION || null,
    version: (window as any).VERSION || process.env.REACT_APP_VERSION || null,
  },
  maintenance: {
    currency: '€',
    enablePlanPrint: true,
    enableAcknowledgment: false,
    enableScheduledJobRejectAction: false,
    enableScheduledJobConstAndTime: true,
    showTargetNameOnNullTarget: false,
    scheduledJobsHiddenCols: ['userFullName', 'acknowledged'],
    calendarLegedHiddenItems: ['unhandled', 'rejected'],
    additionalLevels: [
      {
        level: 'macroarea',
        showOnPlanList: true,
      },
      {
        level: 'section',
        showOnPlanList: true,
      },
      {
        level: 'component',
        showOnPlanList: true,
      },
    ],
    disabledActions: (window as any).MAINTENANCE_DISABLED_ACTIONS || process.env.REACT_APP_MAINTENANCE_DISABLED_ACTIONS || null,
    pages,
  },
  designer: {
    plantMemoryRoot: 'i4P.S7-1500.',
    plantPrefix: (window as any).PLANT_PREFIX || process.env.REACT_APP_PLANT_PREFIX || null,
    youtubeKey: (window as any).YOUTUBE_KEY || process.env.REACT_APP_YOUTUBE_KEY || null,
  },
  dashboard: {
    bestOfConfig: (window as any).BESTOF_CONFIG || process.env.REACT_APP_BESTOF_CONFIG || null,
  },
  production: {
    disabledActions:
      (window as any).PRODUCTION_DISABLED_ACTIONS || process.env.REACT_APP_PRODUCTION_DISABLED_ACTIONS || null,
    validJsonKeys:
      (window as any).PRODUCTION_VALID_JSON_KEYS || process.env.REACT_APP_PRODUCTION_VALID_JSON_KEYS || null,
  },
  environment: {
    disabledActions:
      (window as any).ENVIRONMENT_DISABLED_ACTIONS || process.env.REACT_APP_ENVIRONMENT_DISABLED_ACTIONS || null,
  },
  costs: {
    disabledActions: (window as any).COSTS_DISABLED_ACTIONS || process.env.REACT_APP_COSTS_DISABLED_ACTIONS || null,
  },
  widgets: {
    rt: (window as any).RTWIDGET_CONFIG || process.env.REACT_APP_RTWIDGET_CONFIG || null,
  },
  plantSelector: {
    isMultiPlant: (window as any).MULTI_PLANT || process.env.REACT_APP_MULTI_PLANT || false,
  },
}

// TODO check console.log
const requiredReducers: any[] = [config.designer.plantPrefix]
requiredReducers.forEach((r: any, index) => {
  if (!r) {
    throw Error(`${Object.keys(requiredReducers)[index]} var must be set!`)
  }
})
const optionalReducers: any[] = [config.designer.youtubeKey]
optionalReducers.forEach((r: any, index) => {
  if (!r) {
    console.log(`%c ${Object.keys(optionalReducers)[index]} var is not set!`, 'background: #fff; color: #f00;font-size: 14px') // tslint:disable-line
  }
})

export default {
  auth: authReducer,
  maintenance,
  service,
  production,
  graphI4EnvironmentData,
  smartdocs,
  user,
  designer,
  graphGaugesData,
  graphPlanRunData,
  graphPlanRunOeeData,
  graphConsumptionData,
  graphQualityData,
  qualityDetailsData,
  availabilityDetailsData,
  availabilityDetailsAlarmsData,
  graphAvailabilityData,
  graphPerformanceData,
  graphTPRNCData,
  dateFilters,
  planStatusOverTime,
  costs,
  config: () => config,
}
