import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface Props {
  code: number
  title?: string
  message?: string
}

class ErrorPage extends React.PureComponent<Props & WithTranslation> {
  public render() {
    const { code, title, message, t } = this.props

    return (
      <div className="error-page container">
        <div className="row">
          <div className="col vcenter-50h error-box mx-auto">
            <div className="row justify-content-center">
              <div className="d-flex justify-content-center align-items-center align-middle">
                <h1 className="error-code mr-3 pr-3 align-top border-right inline-block align-content-center">
                  {code}
                </h1>
                <div className="inline-block align-middle">
                  <h2 className="error-title font-weight-normal lead">
                    {title || t('errors.code' + code + '.title', { defaultValue: 'Error' })}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row text-center mt-4">
              <div className="col-12">
                <div className="error-message">
                  {message || t('errors.code' + code + '.message', { defaultValue: '' })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ErrorPage)
