import * as React from 'react'
import { connect } from 'react-redux'
import { changeRange } from '../actions/datefilters'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment-timezone'

export interface MomentRange {
  name: string
  start: moment.Moment
  end: moment.Moment
}

export const ranges: MomentRange[] = [
  {
    name: 'dateRange7Days',
    start: moment()
      .subtract(7, 'day')
      .startOf('day'),
    end: moment()
      .subtract(1, 'day')
      .endOf('day'),
  },
  {
    name: 'dateRangeLastWeek',
    start: moment()
      .subtract(1, 'weeks')
      .startOf('week')
      .startOf('day'),
    end: moment()
      .subtract(1, 'weeks')
      .endOf('week')
      .endOf('day'),
  },
  {
    name: 'dateRange30Days',
    start: moment().subtract(31, 'days'),
    end: moment().subtract(1, 'days'),
  },
  {
    name: 'dateRangeLastMonth',
    start: moment()
      .subtract(1, 'month')
      .startOf('month'),
    end: moment()
      .subtract(1, 'month')
      .endOf('month'),
  },
  {
    name: 'dateRangeLast6Month',
    start: moment()
      .subtract(6, 'month')
      .startOf('month'),
    end: moment()
      .subtract(1, 'day')
      .endOf('month'),
  },
  {
    name: 'dateRangeLastYear',
    start: moment()
      .subtract(1, 'year')
      .startOf('year'),
    end: moment()
      .subtract(1, 'year')
      .endOf('year'),
  },
  {
    name: 'dateRangeYear',
    start: moment()
      .startOf('year')
      .startOf('month')
      .startOf('day'),
    end: moment()
      .subtract(1, 'days')
      .endOf('month'),
  },
]

interface DateBaseFiltersStateProps {
  range: string
  dateStart: string | null
  dateEnd: string | null
}

interface DateBaseFiltersDispatchProps {
  changeRangeAction: (range: string, dateStart: string, dateEnd: string) => any
}

type DateBaseFiltersProps = DateBaseFiltersStateProps & DateBaseFiltersDispatchProps & WithTranslation

const mapStateToProps = (state: any): DateBaseFiltersStateProps => ({
  range: state.dateFilters.range,
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
})

const mapDispatchToProps = (dispatch: any): DateBaseFiltersDispatchProps => {
  return {
    changeRangeAction: (range: string, dateStart: string, dateEnd: string) =>
      dispatch(changeRange(range, dateStart, dateEnd)),
  }
}

class DateBaseFilters extends React.Component<DateBaseFiltersProps> {
  constructor(props: DateBaseFiltersProps) {
    super(props)

    this.handleOnChange = this.handleOnChange.bind(this)
  }

  public handleOnChange(e: React.FormEvent<HTMLSelectElement>) {
    const newValue = e.currentTarget.value
    const rangeObject: MomentRange | undefined = ranges.find((element: MomentRange) => {
      return element.name === newValue
    })
    if (rangeObject) {
      this.props.changeRangeAction(newValue, rangeObject.start.toISOString(), rangeObject.end.toISOString())
    }
  }

  public render() {
    return (
      <div className="DateFilters DateBaseFilters select-range col-lg-2 col-md-3 ml-auto mr-0">
        <select onChange={this.handleOnChange} value={this.props.range} className="custom-select form-control-sm">
          {ranges.map((item: any, index: number) => {
            return (
              <option key={index} value={item.name}>
                {this.props.t(item.name)}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DateBaseFilters))
