// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import InvalidFeedback from './InvalidFeedback'
import '../../../styles/_index.scss'
import { Icon} from '../../../services/icon'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

interface Props {
  appendValue?: string | Icon
  appendClickCallback?: () => void
  autoFocus?: boolean
  disabled?: boolean
  error?: string
  id?: string
  label?: string
  max?: number
  min?: number
  name?: string
  onBlur?: ( value: string | number ) => void
  onChange?: (value: string | number) => void
  pattern?: RegExp
  placeholder?: string
  prependValue?: string | Icon
  prependClickCallback?: () => void
  readOnly?: boolean
  required?: boolean
  size?: 'sm' | 'lg'
  step?: number
  title?: string
  type?: string
  value?: string | number
}

const Input = React.forwardRef(( props: Props, ref: React.Ref<HTMLInputElement> ) => {
  const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    const {value} = event.currentTarget
    if (props.onChange){
      props.onChange(
        props.type === 'number' && value
          ? parseFloat(value)
          : value
      )
    }
  }

  const handleBlur = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    if(props.onBlur) {
      const {value} = event.currentTarget
      props.onBlur(
        props.type === 'number' && value
          ? parseFloat(value)
          : value
      )
    }
  }

  const inputType: string = props.type || 'text'
  const pattern = props.pattern && props.pattern.toString().replace('/', '').replace('/', '') || undefined
  let className = 'form-control'
  if (props.error != null) {
    className += ' is-invalid'
  }

  if (props.size && ['sm', 'lg'].includes(props.size)) {
    className += ' form-contro-' + props.size
  }

  const getInput = () => (<input
    autoFocus={props.autoFocus}
    className={className}
    disabled={props.disabled}
    id={props.id}
    max={props.max}
    min={props.min}
    name={props.name}
    onBlur={handleBlur}
    onChange={handleChange}
    pattern={pattern}
    placeholder={props.placeholder}
    readOnly={props.readOnly}
    ref={ref}
    required={props.required}
    step={props.step}
    title={props.title}
    type={inputType}
    value={props.value || ''}
  />)

  return (
    <div className="form-group">
      {props.label && (
        <label htmlFor={props.id}>
          {props.label}
          {props.required && <abbr className="text-danger ml-1">*</abbr>}
        </label>
      )}
      {(props.appendValue || props.prependValue) ? (
        <div className="input-group">
          {props.prependValue && <div className="input-group-prepend" onClick={props.prependClickCallback}>
            <span className="input-group-text">{
              typeof props.prependValue === 'string' ?
              props.prependValue : <IconComponent
                  icon={props.prependValue}
                />
            }</span>
          </div>}
          {getInput()}
          {props.appendValue && <div className="input-group-append" onClick={props.appendClickCallback}>
            <span className="input-group-text">{
              typeof props.appendValue === 'string' ?
                props.appendValue : <IconComponent
                  icon={props.appendValue}
                />
            }</span>
          </div>}
        </div>
      ) : getInput()}
      {props.error && <InvalidFeedback error={props.error}/>}
    </div>
  )
})

export default Input
