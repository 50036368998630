// ! SISTEMARE
// TODO Tornare alla versione dei component 3.13.0 sistemando icone e quant'altro 
import { RouteProps } from '@mv-submodules/inplant-core-fe/types'

// import costsRoutes from '@mv-submodules/inplant-costs-fe-imel/ui/routes'
import dashboardRoutes from '../modules/Dashboard/routes'
import designerRoutes from '@mv-submodules/inplant-designer-fe/ui/routes'
import efficiencyRoutes from '@mv-submodules/inplant-efficiency-fe-imel/ui/routes'
import environmentRoutes from '@mv-submodules/inplant-environment-fe-imel/ui/routes'
import maintenanceRoutes from '@mv-submodules/inplant-maintenance-fe/ui/routes'
import productionRoutes from '@mv-submodules/inplant-production-fe-imel/ui/routes'
import serviceRoutes from '@mv-submodules/inplant-service-fe/ui/routes'
import smartdocsRoutes from '@mv-submodules/inplant-smartdocs-fe/ui/routes'
import userRoutes from '@mv-submodules/inplant-user-fe/ui/routes'
import { config } from '@mv-submodules/inplant-config/config/reducers'
import { ScheduledJobDetailComponent, TargetComponent } from '@mv-submodules/inplant-maintenance-fe'
import Plant3DViewer from '@mv-submodules/inplant-config/modules/Plant3DViewer/Plant3DViewer'
// import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

// ! Provvisorio 
// TODO Sostituire con l'interfaccia di core
// export interface RouteProps {
//   path: string
//   exact?: boolean
//   hiddenMobile?: boolean
//   i18nkey?: string
//   icon?: IconDefinition
//   iconOverride?: string
//   visible: boolean
//   visibleInUserProfile?: boolean
//   component?: any
//   children?: RouteProps[]
//   aclActionKey?: string
// }


const composeRoutes = ( moduleRoutes: RouteProps[] ): RouteProps[] => moduleRoutes.map(r => r)

environmentRoutes.iconOverride = '/i4environment.svg'
environmentRoutes.i18nkey = undefined
environmentRoutes.hiddenMobile = true

// costsRoutes.iconOverride = '/i4costs.svg'
// costsRoutes.i18nkey = undefined
// costsRoutes.hiddenMobile = true

designerRoutes.iconOverride = '/i4plantdesigner.svg'
designerRoutes.i18nkey = undefined
designerRoutes.hiddenMobile = true

efficiencyRoutes.iconOverride = '/i4efficiency.svg'
efficiencyRoutes.i18nkey = undefined
efficiencyRoutes.hiddenMobile = true

maintenanceRoutes.iconOverride = '/i4maintenance.svg'
maintenanceRoutes.i18nkey = undefined
maintenanceRoutes.hiddenMobile = true

productionRoutes.iconOverride = '/i4production.svg'
productionRoutes.i18nkey = undefined
productionRoutes.hiddenMobile = true

smartdocsRoutes.iconOverride = '/i4smartdoc.svg'
smartdocsRoutes.i18nkey = undefined
smartdocsRoutes.hiddenMobile = false

serviceRoutes.iconOverride = '/i4service.svg'
serviceRoutes.i18nkey = undefined
serviceRoutes.hiddenMobile = true

// TODO is this the right place?

if (config.maintenance.pages) {
  maintenanceRoutes.path = `/maintenance/${config.maintenance.pages[0].pagePath}`

  if (maintenanceRoutes.children) {
    maintenanceRoutes.children = []

    maintenanceRoutes.children.push({
      path: '/maintenance/scheduled-job/:scheduledJobId',
      component: ScheduledJobDetailComponent,
      visible: false,
    })

    maintenanceRoutes.children.push({
      path: '/maintenance/target/*',
      component: TargetComponent,
      visible: false,
    })

    config.maintenance.pages.map(page => {
      maintenanceRoutes.children!.push({
        path: `/maintenance/${page.pagePath}`,
        i18nkey: page.i18nkey,
        visible: true,
        exact: true,
        component: page.component,
      })
    })
  }
}

const plant3DViewerRoutes: RouteProps =
  {
    path: '/plant-3d-viewer',
    component: Plant3DViewer,
    visible: false,
  }


export const aclRoutes = ( sroutes: RouteProps[], forbiddenActions: string[], loginSuccessPath: string ) =>
  sroutes.filter(r => r)

export const aclRedirect = ( loginSuccessPath: string ) => loginSuccessPath

const routes: ( userRoles: string[] ) => RouteProps[] = () =>
  composeRoutes(
    [
      dashboardRoutes,
      environmentRoutes,
      productionRoutes,
      efficiencyRoutes,
      maintenanceRoutes,
      smartdocsRoutes,
      serviceRoutes,
      userRoutes,
      // costsRoutes,
      designerRoutes,
      plant3DViewerRoutes
    ]
  )

export default routes
