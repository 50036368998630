import { AnyAction } from 'redux'
import { DateFiltersState, initialDateFiltersState } from '../state'
import * as moment from 'moment'
// import { MomentRange, ranges } from '../components/DateBaseFilters'

export function dateFilters(state: DateFiltersState = initialDateFiltersState, action: AnyAction) {
  switch (action.type) {
    case 'CHANGE_RANGE': {
      const oldRange = state.range
      return Object.assign(
        {},
        {
          ...state,
          range: action.range,
          prevRange: oldRange,
          dateStart: action.dateStart,
          dateEnd: action.dateEnd,
          lastUpdate: moment().unix(),
        }
      )
    }

    default:
      return state
  }
}

export default dateFilters
