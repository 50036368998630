const it = {
  maintenance: {
    module: 'Manutenzioni',
    dueDateModule: 'Scadenziario',
    pageSlug: {
      manager: 'Impostazioni scadenze',
      ongoing: 'Scadenze in essere',
      overdue: 'Scadenze in ritardo',
      'to-validate': 'Scadenze da validare',
      archive: 'Archivio',
      calendar: 'Calendario',
    },
    navigation: {
      calendar: 'Calendario',
      list: 'Lista in essere',
      expiredList: 'In ritardo',
      plan: 'Piano',
      history: 'Storico',
      pending: 'In attesa',
      setup: 'Setup',
      title: 'Anomalie',
      toBePlanned: 'Da pianificare',
      anomalies: 'In essere',
      archive: 'Archivio',
    },
    fetching: 'Caricamento',
    newIntervention: 'Nuovo intervento',
    close: 'Chiudi',
    filters: {
      showFilters: 'Visualizza filtri',
      headingText: 'Seleziona filtro'
    },
    status: {
      done: 'FATTO',
      overdue: 'SCADUTO',
      planned: 'DA FARE',
      unhandled: 'DA CONFERMARE',
      postponed: 'DA FARE',
      rejected: 'RIFIUTATO',
      todo: 'DA FARE',
    },
    periodicity: {
      '1_DAY': '1 giorno',
      '1_WEEK': '1 settimana',
      '2_WEEKS': '2 settimane',
      '1_MONTH': '1 mese',
      '2_MONTHS': '2 mesi',
      '3_MONTHS': '3 mesi',
      '6_MONTHS': '6 mesi',
      '1_YEAR': '1 anni',
      '2_YEARS': '2 anni',
    },
    jobType: {
      custom: 'Puntuale',
      maintenance: 'Adempimento',
      anomaly: 'Anomalia checklist',
    },
    table: {
      noData: 'Nessun dato disponibile',
      previous: 'Precedente',
      next: 'Successiva',
      loading: 'Caricamento dati...',
      page: 'Pagina',
      of: 'di',
      rows: 'righe',
    },
    calendar: {
      title: 'Calendario',
      new: 'Nuovo',
      pastUndoneMsg: 'Ci sono alcuni interventi FA DARE sui mesi precedenti: ',
      rescheduleConfirm: 'Sei sicuro di spostare questo intervento al ',
      today: 'oggi',
      month: 'mese',
      week: 'settimana',
      monthNames: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      monthNamesShort: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
      dayNames: [
        'Domenica',
        'Lunedì',
        'Martedì',
        'Mercoledì',
        'Giovedì',
        'Venerdì',
        'Sabato',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      more: 'eventi',
      legend: {
        legend: 'LEGENDA:',
        done: 'FATTO',
        rejected: 'RIFIUTATO',
        overdue: 'SCADUTO',
        todo: 'DA FARE',
        unhandled: 'DA CONFERMARE',
        periodic: 'PERIODICO',
        anticipated: 'ANTICIPATO',
        postponed: 'POSTICIPATO',
      },
    },
    plan: {
      title: 'Piano',
      new: 'Nuovo',
      print: 'Stampa',
      tableCols: {
        macroarea: 'Macro Area',
        section: 'Sezione',
        component: 'Componente',
        target: 'Target',
        operation: 'Operazione',
        operatorName: 'Nome operatore',
        type: 'Tipo op.',
        dueDate: 'Previsto',
        plannedDate: 'Pianificato',
        creationDate: 'Creato',
        status: 'Stato',
        jobType: 'Tipo',
        acknowledged: 'Confermato',
        notes: 'Note',
      },
    },
    actionScheduledJob: {
      interventionOverview: 'Panoramica intervento',
      macroarea: 'Macro Area',
      section: 'Sezione',
      component: 'Componente',
      target: 'Target',
      jobType: 'Tipo manutenzione',
      operation: 'Operazione',
      operatorName: 'Nome operatore',
      type: 'Tipo operazione',
      dueDate: 'Previsto',
      plannedDate: 'Pianificato',
      creationDate: 'Creato',
      status: 'Status',
      hours: 'Ore',
      sinceLastMaintenance: "dall' ultima manutenzione",
      acknowledged: 'Confermato',
      details: 'Altri dettagli',
      notes: 'Note',
      pid: 'P&ID',
      currentHours: 'Ore attuali'
    },
    addScheduledJob: {
      newIntervention: 'Nuovo intervento',
      target: 'Target',
      targetPlaceholder: 'Selezionare la voce dalla lista',
      operation: 'Operazione',
      selectType: 'Seleziona tipo',
      type: 'Tipo operazione',
      dueDate: 'Previsto',
      save: 'Salva',
    },
    scheduledJobDetail: {
      title: 'Dettagli intervento',
      buttons: {
        markAsDone: 'Segna come fatto',
        reschedule: 'Riprogramma',
        reject: 'Rifiuta',
      },
      modals: {
        markAsDone: {
          title: "Segna come fatto l'intervento",
          dueDate: 'Data Chiusura',
          cost: 'Costo',
          time: 'Tempo',
          hours: 'Ore',
          notes: 'Note',
          confirm: 'Conferma',
        },
        reschedule: {
          title: "Riprogramma l'intervento",
          dueDate: 'Data',
          notes: 'Note',
          confirm: 'Conferma',
        },
        reject: {
          title: 'Rifiuta lintervento',
          notes: 'Note',
          confirm: 'Conferma',
        },
      },
      recap: {
        title: 'Recap',
        macroarea: 'Macro Area',
        section: 'Sezione',
        component: 'Componente',
        target: 'Target',
        hours: 'Ore',
        sinceLastMaintenance: "dall' ultima manutenzione",
        operation: 'Operazione',
        type: 'Tipo Op. Operazione',
        operatorName: 'Nome operatore',
        dueDate: 'Previsto',
        plannedDate: 'Pianificato',
        creationDate: 'Creato',
        status: 'Stato',
        cost: 'Costo',
        time: 'Tempo',
      },
      historyActions: {
        on: 'il',
        to: 'al',
        type: {
          created: 'creato',
          acknowledged: 'confermato',
          done: 'eseguito',
          rescheduled: 'riprogrammato',
          rejected: 'rifiutato',
        },
      },
      history: {
        title: 'Storia',
        user: 'Utente',
        action: 'Azione',
        date: 'Data',
        notes: 'Note',
        isSystem: 'Sistema'
      },
      relatedJobs: {
        title: 'Storico manutenzioni correlate',
        hours: 'hours',
        user: 'Utente',
        action: 'Azione',
        date: 'Data',
        notes: 'Note',
        noHistoryAvailable: 'No sono disponibili dati per questa manutenzione',
        late: 'in ritardo',
      },
      images: 'Immagini',
    },
    setup: {
      title: 'Setup',
      new: 'Nuova manutezione',
      print: 'Stampa',
      tableCols: {
        macroarea: 'Macro Area',
        section: 'Sezione',
        component: 'Componente',
        target: 'Target',
        operation: 'Operazione',
        type: 'Tipo Op.',
        periodicity: 'Periodicità',
        hours: 'Ore',
        notes: 'Note',
        pid: 'P&ID',
      },
    },
    addJob: {
      title: 'Nuovo intervento periodico',
      target: 'Target',
      targetPlaceholder: 'Selezionare la voce dalla lista',
      operation: 'Operazione',
      type: 'Tipo Operazione',
      selectType: 'Seleziona tipo op.',
      periodicity: 'Periodicità',
      selectPeriodicity: 'Seleziona periodicità',
      hours: 'Ore',
      notes: 'Note',
      save: 'Salva',
      lastDoneDate: 'Data ultima esecuzione',
      lastDoneHours: 'Ore ultima esecuzione'
    },
    editJob: {
      title: 'Modifica intervento periodico',
      target: 'Target',
      operation: 'Operazione',
      type: 'Tipo Operazione',
      periodicity: 'Periodicità',
      selectPeriodicity: 'Seleziona periodicità',
      hours: 'Ore',
      notes: 'Note',
      save: 'Salva',
    },
    deleteJob: {
      title: 'Elimina intervento periodico',
      message: "Eliminare l'intervento periodico selezionato?",
      close: 'Chiudi',
      confirm: 'Conferma',
      warningMessage: 'Anche gli interventi correlati verranno eliminati.'
    },
    bulkActions: {
      button: 'Azioni',
      actions: {
        done: 'Segna come fatto',
        rescheduled: 'Riprogramma',
        rejected: 'Rifiuta',
        acknowledged: 'Conferma',
      },
      modal: {
        title: 'Azioni multiple',
        date: 'Data',
        notes: 'Note',
        confirm: 'Esegui',
        selectedOf: '{{selected}} di {{total}} elementi',
        acknowledgedMsg:
          'Sicuro di voler confermare gli interventi selezionati?',
      },
    },
    target: {
      title: 'Dettaglio Manutenzioni Componente',
      periodicTitle: 'Manutenzioni periodioche',
      variableTitle: 'Manutenzioni Variabili',
      archivedTitle: 'Manutenzioni archiviate (fatte e rifiutate)',
      noData: 'Dati non disponibili',
      smartdocsLink: 'Link a Smartdoc',
      confirmed: {
        yes: 'SI',
        no: 'NO',
      },
      pagination: {
        page: 'Pagina',
        of: 'di',
      },
      scheduledJobsCols: {
        operation: 'Operazione',
        type: 'Tipo Op.',
        operator: 'Operatore',
        status: 'Status',
        jobType: 'Tipo',
        confirmed: 'Confermato',
        date: 'Data',
        notes: 'Note'
      },
      jobsCols: {
        operation: 'Operazione',
        type: 'Tipo Op.',
        periodicity: 'Periodicità',
        hours: 'Ore',
        prev: 'Prec.',
        next: 'Succ.',
      },
    },
    errors: {
      somethingGoneWrong: 'Spiacente — qualcosa è andato storto.',
      contactOurTeam:
        'Si prega di contattare il nostro team per notificare il problema',
      targetNotFound:
        'Il target selezionato sembra non essere presente oppure eliminato',
    },
    tasks: {
      table: {
        advancedFilters: 'Filtri avanzati',
        searchPlaceholder: 'Cerca fra i valori nella tabella',
        noData: 'Dati non disponibili',
        noItemFound: 'Nessun elemento trovato',
        page: 'Pagina',
        of: 'di',
        rows: 'righe',
        actions: 'Azioni',
        applyFilters: 'Filtra',
        cancel: 'Annulla',
        resetFilters: 'Ripristina filtri',
        checkAll: 'Seleziona tutto',
        uncheckAll: 'Deseleziona tutto',
      },
      form: {
        cancel: 'Annulla',
        save: 'Salva',
      },
      actions: {
        reject: 'Rifiuta',
        cancel: 'Cancella',
        reschedule: 'Riprogramma',
        done: 'Segna come fatto',
        validated: 'Segna come validato',
        failValidation: 'Validazione fallita',
        validate: 'Valida',
        undo: 'Annulla ultima azione', // rivedere traduzione
      },
    },
    dueDates: {
      title: 'Scadenze in essere',
      createNew: 'Crea nuova scadenza',
      newDueDates: 'Nuova scadenza',
      me: 'Me',
      tableList: {
        dueAtDate: 'Scadenza',
        effectiveFromDate: 'Emissione',
        status: 'Stato',
        category: 'Categoria',
        automaticRenewal: 'Rinnovo',
        attachments: 'Allegati',
        inChargeUser: 'Responsable',
        assigneeUser: 'Assegnatario',
        assignToMe: 'Prendi in carico',
        assignTo: 'Assegna a...',
        yes: 'Sì',
        no: 'No',
        customProperties: {
          costCenter: 'Centro di costo',
          costCentre: 'Centro di costo',
          area: 'Area',
          ambito: 'Ambito',
          plant: 'Impianto',
          sito: 'Sito',
          category: 'Categoria',
          categoria: 'Categoria',
          sottoCategoria: 'Sotto categoria',
          rifLegislativo: 'Rif. legislativo',
        },
      },
      formField: {
        validationError: {
          title: 'Errore di validazione',
          description: 'Verificare la correttezza dei dati immessi',
          invalidField: 'Il valore non è corretto',
        },
        destination: 'Impianto',
        dueAtDate: 'Data scadenza',
        effectiveFromDate: 'Data emissione',
        date: 'Data',
        context: 'Ambito',
        category: {
          area: 'Area',
          detail: 'Categoria',
        },
        target: {
          group: 'Gruppo cespiti',
          asset: 'Cespiti',
          dueDate: 'Data scadenza',
        },
        splitTargets: 'Crea una scadenza per ogni cespite',
        rolesWithVisibility: 'Visibilità ruoli',
        inChargeUser: 'Responsable',
        mustBeValidated: 'Richiede validazione',
        rolesWithValidation: 'Ruolo validatore',
        automaticRenewal: 'Rinnovo automatico',
        repetitionFromDueDate: 'Rinnovo alla data di scadenza',
        repetitionFromDoneDate: 'Rinnovo alla data in cui è stato fatto',
        repetitionRule: {
          method: 'Rinnovo automatico',
          period: 'Periodo di rinnovo',
          daysOrHours: 'A distanza di giorni o di ore',
          days: 'A distanza di giorni',
          hours: 'A distanza di ore',
        },
        noticeMessage: 'Invia preavviso',
        notificationDaysAdvance: 'Notifica giorni prima della scadenza',
        creatorNotes: 'Note',
        notes: 'Note',
        customProperties: {
          costCenter: 'Centro di costo',
          costCentre: 'Centro di costo',
          area: 'Area',
          ambito: 'Ambito',
          plant: 'Impianto',
          sito: 'Sito',
          category: 'Categoria',
          categoria: 'Categoria',
          sottoCategoria: 'Sotto categoria',
          rifLegislativo: 'Rif. legislativo',
        },
      },
      detail: {
        editDueDate: 'Modifica scadenza',
        destination: 'Impianto',
        details: 'Dettagli',
        actionHistory: 'Storico azioni',
        actions: 'Azioni',
        dueAtDate: 'Scadenza',
        effectiveFromDate: 'Emissione',
        status: 'Stato',
        category: {
          area: 'Area',
          detail: 'Categoria',
        },
        repetitionRule: 'Rinnovo automatico',
        target: 'Cespiti',
        attachments: 'Allegati',
        doneAttachments: "Allegati 'done'",
        validationAttachments: 'Allegati validazione',
        attachmentPosition: 'Originale',
        inChargeUser: 'Responsable',
        assigneeUser: 'Assegnatario',
        creatorNotes: 'Note',
        rolesWithVisibility: 'Visibilità ruoli',
        rolesWithValidation: 'Ruolo validatore',
        mustBeValidated: 'Validazione richiesta',
        yes: 'Sì',
        no: 'No',
        renewalPeriod: 'Rinnovo a distanza di',
        or: 'o',
        days: 'giorni',
        hours: 'ore',
        customProperties: {
          costCenter: 'Centro di costo',
          costCentre: 'Centro di costo',
          area: 'Area',
          ambito: 'Ambito',
          plant: 'Impianto',
          sito: 'Sito',
          category: 'Categoria',
          categoria: 'Categoria',
          sottoCategoria: 'Sotto categoria',
          rifLegislativo: 'Rif. legislativo',
        },
        action: {
          done: 'Imposta come fatto',
          reschedule: 'Riprogramma',
          reject: 'Rifiuta il task',
          cancel: 'Annulla il task',
          validation: 'Valida il completamento del task',
          validate: 'Valida il task', // ???
          'fail-validation': 'Annulla la validazione',
          undo: "Annulla l'ultima operazione",
        },
        upload: 'Aggiungi file',
        description: 'Descrizione',
      },
    },
  },
}

export default it
