import { Template } from '../types'
import {
  // BString,
  // DFloat,
  // DLong,
  MemoryAddressType,
  MemoryAddressTypes,
  // WLong,
  // WShort,
  // XBoolean
} from '../types/plc'

export const Metric: Template = {
  id: 'metric',
  name: 'Metric',
  version: '0.1.0',
  date: '2018-11-09',
  fields: [
    {
      name: 'label',
      type: 'string',
      label: 'metric.label',
      width: 12,
      required: true,
      nodePath: 'data',
    },
    /*{
      "name": "id",
      "type": "string",
      "label": "metric.id",
      "width": 6,
      "required": true,
      "nodePath": "data"
    }, */
    {
      name: 'dataType',
      type: 'enum',
      label: 'metric.dataType',
      width: 3,
      required: true,
      values: MemoryAddressTypes.map((e: MemoryAddressType) => e.prefix + e.sufix),
    },
    {
      name: 'polling',
      type: 'enum',
      label: 'metric.polling',
      width: 3,
      values: [true, false],
      nodePath: 'data',
      required: true,
    },
    {
      name: 'savingMode',
      type: 'enum',
      label: 'metric.savingMode',
      width: 3,
      values: ['Other', 'plantTime'],
      nodePath: 'data',
      required: true,
    },
    {
      name: 'type',
      type: 'enum',
      label: 'metric.type',
      width: 3,
      values: [
        'ActivePower',
        'AirFlowRate',
        'Binder',
        'CarbonFootprint',
        'ComprimedAir',
        'Conductivity',
        'Coulomb',
        'Current',
        'DemiWater',
        'ElectricConsumption',
        'ExhaustChaminsTemp',
        'ExhaustChaminsVol',
        'FlowRate',
        'FramePieces',
        'Gas',
        'GasFlowRate',
        'Level',
        'LoadCellFlowRate',
        'OilConsumption',
        'Paste',
        'Ph',
        'PlantStatusChange',
        'Power',
        'Pressure',
        'ProductConsumption',
        'Production',
        'RawWater',
        'ResetHour',
        'Restarti4PService',
        'Temperature',
        'ThermalEnergy',
        'TotalMinutes',
        'Type',
        'WasteCause',
      ],
      nodePath: 'data',
      required: true,
    },
  ],
  copyable: true,
  allowedSubcomponents: [],
}
