export const initialPlanStatusOverTimeState = {
  data: null,
  hasErrored: false,
  isLoading: false,
} as PlanStatusOverTimeState

export interface PlanStatusOverTimeState {
  data: any
  hasErrored: boolean
  isLoading: boolean
}
