import { IconDefinition, faWrench } from '@fortawesome/free-solid-svg-icons'
import ServiceList from './components/views/List/ListPageView'
import ServiceNew from './components/views/New/NewPageView'
import ServiceDetails from './components/views/Details/DetailsPageView'

export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey?: string
  icon?: IconDefinition
  iconOverride?: string
  visible: boolean
  visibleInUserProfile?: boolean
  component?: any
  children?: RouteProps[]
}

const routes: RouteProps = {
  path: '/service/list',
  i18nkey: 'service.module',
  icon: faWrench,
  visible: true,
  children: [
    {
      path: '/service/list',
      exact: true,
      i18nkey: 'service.navigation.list',
      component: ServiceList,
      visible: true,
    },
    {
      path: '/service/new',
      exact: true,
      i18nkey: 'service.navigation.new',
      component: ServiceNew,
      visible: true,
    },
    {
      path: '/service/list/:id',
      component: ServiceDetails,
      visible: false,
    },
  ],
}

export default routes
