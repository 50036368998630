// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import InvalidFeedback from './InvalidFeedback'


interface Props {
  disabled?: boolean
  error?: string
  id?: string
  label?: string
  name?: string
  onChange: (value: string) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  rows?: number
  title?: string
  value?: string
}

const TextArea = React.forwardRef((props: Props, ref: React.Ref<HTMLTextAreaElement>) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(event.currentTarget.value)
  }
  let className = 'form-control'
  if (props.error != null) {
    className += ' is-invalid'
  }

  return (
    <div className="form-group">
      {props.label && (
        <label htmlFor={props.id}>
          {props.label}
          {props.required && <abbr className="text-danger ml-1">*</abbr>}
        </label>
      )}
      <textarea
        className={className}
        defaultValue={props.value}
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        onChange={handleChange}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        ref={ref}
        required={props.required}
        rows={props.rows}
        title={props.title}
      />
      {props.error && <InvalidFeedback error={props.error} />}
    </div>
  )
})

export default TextArea
