import { AnyAction, Dispatch } from 'redux'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { getDateRangeFiltering } from '@mv-submodules/inplant-core-fe/functions/date'

export const APIInflux = () => FetchWrapper.getInstance('influx')

export const fetchPlanStatusOverTimeHasError = () => {
  return {
    hasErrored: true,
    isLoading: false,
    type: 'FETCH_PLANTSTATUSOVERTIME_ERROR',
    data: null,
  }
}

export const fetchPlanStatusOverTimeIsRunning = () => {
  return {
    hasErrored: false,
    isLoading: true,
    type: 'FETCH_PLANTSTATUSOVERTIME_REQUEST',
  }
}

export const fetchPlanStatusOverTimeSuccess = (data: object) => {
  return {
    data,
    hasErrored: false,
    isLoading: false,
    type: 'FETCH_PLANTSTATUSOVERTIME_SUCCESS',
  }
}
export function fetchPlanStatusOverTime() {
  return (dispatch: Dispatch<AnyAction>, getState: any) => {
    const { dateFilters } = getState()
    const { start, end } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)

    const query = `SELECT "measure" FROM "plantStatusOverTime" WHERE time >= '${start}' AND time < '${end}' `

    dispatch(fetchPlanStatusOverTimeIsRunning())

    return APIInflux().request('/influx/query?q=' + query)
      .then((data: any) => {
        dispatch(fetchPlanStatusOverTimeSuccess(data))
        return data
      })
      .catch((error: any) => {
        dispatch(fetchPlanStatusOverTimeHasError())

        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw  error
      })
  }
}
