import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'

const API_BASE_URL = (window as any).API_BASE_URL || process.env.REACT_APP_API_BASE_URL
const APIbaseURL = `${API_BASE_URL}/v1`

const initFetchWrapper = (i18n: any) => {
  FetchWrapper.configInstance('auth', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('redmine_api', APIbaseURL + '/service', true, i18n)
  FetchWrapper.configInstance('cost', `${APIbaseURL}/cost`, true, i18n)
  FetchWrapper.configInstance('model', `${APIbaseURL}/model`, true, i18n)
  FetchWrapper.configInstance('service', `${APIbaseURL}/service`, true, i18n)
  FetchWrapper.configInstance('maintenance', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('user', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('smartdocs', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('designer', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('designerStorage', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('production', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('influx', APIbaseURL, true, i18n) // TODO restore API env
  FetchWrapper.configInstance('data_collector', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('static', `/data`, true, i18n)
  FetchWrapper.configInstance('pdf', 'http://localhost:3005/v1', true, i18n)
}

export default initFetchWrapper
