import * as React from 'react'
import { MemoryAddressType, MemoryAddressTypes } from '../../types/plc'
import IconComponent from '../../../inplant-components-fe/ui/components/MVIcon/Icon'

export const formatBytes = (bytes: number, fixed: number = 2) => {
  if (0 === bytes) {
    return '0 Bytes'
  }
  const c = 1024
  const d = fixed || 2
  const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const f = Math.floor(Math.log(bytes) / Math.log(c))
  return parseFloat((bytes / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

export const Loader = () => (
  <div key={Date.now()} className="col-lg text-center">
    <IconComponent icon={'circle-notch'} size="2x" spin={true} />
  </div>
)

export const memoryFromAddress = (address: string) => {
  if (address && address !== '') {
    const memoryAddressSegments = address.split(',')
    const memorySearch = MemoryAddressTypes.find((e: MemoryAddressType) => {
      const memory = memoryAddressSegments[memoryAddressSegments.length - 1]
      return !!(memory.indexOf(e.prefix) === 0 && memory.indexOf(e.sufix) > -1)
    })
    return memorySearch || null
  }
  return null
}

export const prepareAttachment = (file: any) => {
  return {
    name: file.Key.split('/').pop(),
    version: '', // @todo add version from S3
    lastEdit: file.LastModified,
    documentType: file.fileType,
    link: file.Key,
    languages: [], // @todo defaults?
  }
}

export const setFileLanguage = (link: string, lang: string, allDocuments: any[]) => {
  const documents = JSON.parse(JSON.stringify(allDocuments))
  const fileIndex = documents.findIndex((f: any) => f.link === link)

  if (fileIndex > -1 && documents[fileIndex]) {
    if (documents[fileIndex].languages) {
      const language = documents[fileIndex].languages.find((l: string) => l === lang)
      if (language) {
        documents[fileIndex].languages.splice(documents[fileIndex].languages.indexOf(language), 1)
      } else {
        documents[fileIndex].languages.push(lang)
      }
    } else {
      documents[fileIndex].languages = [lang]
    }
  }

  return documents
}

export const createDownloadTempLink = (uniqueUrl: string, download: string, target: string): HTMLAnchorElement => {
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = uniqueUrl
  tempLink.setAttribute('download', download)
  tempLink.setAttribute('target', target)

  return tempLink
}
