const it = {
  user: {
    module: 'Utenti',
    navigation: {
      list: 'Lista Utenti',
      new: 'Nuovo Utente',
      edit: 'Modifica Utente',
    },
    fetching: 'Caricamento',
    index: {
      title: 'Utenti',
      name: 'Nome',
      surname: 'Cognome',
      displayName: 'Nome visualizzato',
      email: 'E-mail',
      username: 'Nome utente',
      roles: 'Ruoli',
      enabled: 'Abilitato',
      disabled: 'Disabilitato',
      table: {
        showAll: 'Tutti',
        page: 'pagina',
        of: 'di',
        rows: 'righe',
        noData: 'Non ci sono dati',
      },
      new: 'Nuovo',
      exportData: 'ESPORTA DATI'
    },
    form: {
      newUser: 'Nuovo Utente',
      newUserCreated: 'Nuovo Utente creato correttamente',
      status: 'Stato',
      select: '- seleziona -',
      create: 'Crea',
      update: 'Modifica',
    },
    errors: {
      required: 'Campo richiesto',
      minLength: 'NON può essere più CORTO di 1 carattere',
      minLength_plular: 'NON può essere più CORTO di {{count}} caratteri',
      maxLength: 'NON può essere più LUNGO di {{count}} caratteri',
      format: 'Deve essere nel formato: {{format}}',
    },
  },
}

export default it
