import {
  ManualDataLoadingLoadMaterial,
} from '../types/index'
import { AnyAction, Dispatch } from 'redux'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'


export const API = () => FetchWrapper.getInstance('cost')

export const loadManualDataLoading = (data: ManualDataLoadingLoadMaterial) => {
  return (dispatch: Dispatch<AnyAction>, getState: any) => {
    const query = '/manual-data-loading/history/material-load'
    return API().request(query, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}

export const fetchManualDataLoadings = (year: number) => {
  return (dispatch: Dispatch<AnyAction>, getState: any) => {
    const query = `/manual-data-loading/year/${year}`
    return API().request(query).catch((error: any) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
  }
}