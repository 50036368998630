import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Select, Input } from '@mv-submodules/inplant-components-fe'
import { FormFieldsMap } from '../../../../types/pageForm'

interface Props {
  getInputValue: (slug: string) => any
  onInputChange: (slug: string, value: any, template?: boolean) => void
  required?: boolean
  errors?: { [field: string]: string[] }
  refs?: FormFieldsMap<React.RefObject<any>>
}

interface OwnState {
  period: string
}

class AutomaticRenewalField extends React.Component<Props & WithTranslation, OwnState> {
  constructor(props: Props & WithTranslation) {
    super(props)
    this.state = {
      // period: this.getRepetitionPeriod(props)
      period: 'days', // TEMP per IDEAL
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handlePeriodInputChange = this.handlePeriodInputChange.bind(this)
    this.getFieldError = this.getFieldError.bind(this)
  }

  public render() {
    const t = this.props.t
    const props = this.props
    const { period } = this.state
    return (
      <div className="row">
        <div className="col">
          <Select
            label={t(`maintenance.dueDates.formField.repetitionRule.method`)}
            ref={props.refs && props.refs['repetitionRule.method']}
            id="repetitionRule.method"
            name="repetitionRule.method"
            required={props.required}
            options={{
              items: [
                { value: 'fromDueDate', label: t(`maintenance.dueDates.formField.repetitionFromDueDate`) },
                { value: 'fromDoneDate', label: t(`maintenance.dueDates.formField.repetitionFromDoneDate`) },
              ]
            }}
            value={props.getInputValue('repetitionRule.method')}
            onChange={this.handleInputChange('repetitionRule.method')}
            error={this.getFieldError('repetitionRule.method')}
          />
        </div>
        {false && (
          <div className="col">
            <Select
              label={t(`maintenance.dueDates.formField.repetitionRule.period`)}
              ref={props.refs && props.refs!['repetitionRule.period']}
              id="repetitionRule.period"
              name="repetitionRule.period"
              required={props.required}
              options={{
                items: [
                  { value: 'daysOrHours', label: t(`maintenance.dueDates.formField.repetitionRule.daysOrHours`) },
                  { value: 'days', label: t(`maintenance.dueDates.formField.repetitionRule.days`) },
                  { value: 'hours', label: t(`maintenance.dueDates.formField.repetitionRule.hours`) },
                ]
              }}
              value={period}
              onChange={this.handlePeriodInputChange}
              error={this.getFieldError('repetitionRule.period')}
            />
          </div>
        )}
        {period !== 'hours' && (
          <div className="col">
            <Input
              label={t(`maintenance.dueDates.formField.repetitionRule.days`)}
              ref={props.refs && props.refs['repetitionRule.days']}
              id="repetitionRule.days"
              name="repetitionRule.days"
              type="number"
              required={props.required}
              min={1}
              value={props.getInputValue('repetitionRule.days')}
              onChange={this.handleInputChange('repetitionRule.days')}
              error={this.getFieldError('repetitionRule.days')}
            />
          </div>
        )}
        {period !== 'days' && (
          <div className="col">
            <Input
              label={t(`maintenance.dueDates.formField.repetitionRule.hours`)}
              ref={props.refs && props.refs['repetitionRule.hours']}
              id="repetitionRule.hours"
              name="repetitionRule.hours"
              type="number"
              required={props.required}
              min={1}
              value={props.getInputValue('repetitionRule.hours')}
              onChange={this.handleInputChange('repetitionRule.hours')}
              error={this.getFieldError('repetitionRule.hours')}
            />
          </div>
        )}
        {period !== 'daysOrHours' && <div className="col" />}
      </div>
    )
  }

  private handleInputChange(slug: string, template?: boolean) {
    return (value: any) => this.props.onInputChange(slug, value, template)
  }

  private handlePeriodInputChange(period: string) {
    this.setState({ period })

    switch (period) {
      case 'days':
        return this.props.onInputChange('repetitionRule.hours', undefined)
      case 'hours':
        return this.props.onInputChange('repetitionRule.days', undefined)
    }
  }

  private getFieldError(slug: string) {
    return (this.props.errors && this.props.errors[slug] && this.props.errors[slug][0]) || undefined
  }

  // private getRepetitionPeriod(props: Props) {
  //   const days = props.getInputValue("repetitionRule.days")
  //   const hours = props.getInputValue("repetitionRule.hours")
  //   if (days != null && hours == null) return "days"
  //   else if (days == null && hours != null) return "hours"
  //   else return "daysOrHours"
  // }
}

export default withTranslation()(AutomaticRenewalField)
