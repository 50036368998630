// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import { Icon} from '../../../services/icon';
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

interface Element {
  label?: {
    icon?: Icon,
    text?: string
  },
  value?: {
    text?: string | null,
    icon?: Icon,
    component?: React.ReactNode
  }
}

export interface LeftDetailElements {
  row?: Element[],
  column?: Element[]
  element?: Element
}

interface Props {
  values: LeftDetailElements[]
}

class LeftDetailView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <div className="left-detail-view-components">
        {this.props.values.map((option, index1) => {
          if (option.row) {
            return (
              <Row key={index1} spacing={{vertical:false,horizontal:false}}>
                {option.row.map((rowElement, index2) => (
                  <Column xs={3} key={index1 + index2}>
                    {rowElement.label && (
                      <label>
                        {rowElement.label.icon && <IconComponent icon={rowElement.label.icon}
                                                                   className={`${rowElement.label.text && 'mr-1' || ''}`}/>}
                        {rowElement.label.text && rowElement.label.text}
                      </label>
                    )}
                    {rowElement.value && (rowElement.value.component ? (<div>{rowElement.value.component}</div>) : (
                      <p>
                        {rowElement.value.icon && <IconComponent icon={rowElement.value.icon}
                                                                   className={`${rowElement.value.text && 'mr-1' || ''}`}/>}
                        {rowElement.value.text && rowElement.value.text || '-'}
                      </p>
                    ))}
                  </Column>
                ))}
              </Row>
            )
          }
          if (option.column) {
            return (
              <div className="d-flex flex-column" key={index1}>
                {option.column.map((rowElement, index2) => (
                  <div key={index2 + index1}>
                    {rowElement.label && (
                      <label>
                        {rowElement.label.icon && <IconComponent icon={rowElement.label.icon}
                                                                   className={`${rowElement.label.text && 'mr-1' || ''}`}/>}
                        {rowElement.label.text && rowElement.label.text}
                      </label>
                    )}
                    {rowElement.value && (rowElement.value.component ? (<div>{rowElement.value.component}</div>) : (
                      <p>
                        {rowElement.value.icon && <IconComponent icon={rowElement.value.icon}
                                                                   className={`${rowElement.value.text && 'mr-1' || ''}`}/>}
                        {rowElement.value.text && rowElement.value.text || '-'}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            )
          } else if (option.element) {
            return (
              <React.Fragment key={index1}>
                {option.element.label && (
                  <label>
                    {option.element.label.icon && <IconComponent icon={option.element.label.icon}
                                                                   className={`${option.element.label.text && 'mr-1' || ''}`}/>}
                    {option.element.label.text && option.element.label.text}
                  </label>
                )}
                {option.element.value && (option.element.value.component ? (
                  <div>{option.element.value.component}</div>) : (
                  <p>
                    {option.element.value.icon &&
                    <IconComponent icon={option.element.value.icon}
                                     className={`${option.element.value.text && 'mr-1' || ''}`}/>}
                    {option.element.value.text && option.element.value.text || '-'}
                  </p>
                ))}
              </React.Fragment>
            )
          }
        })}
      </div>
    )
  }
}

export default LeftDetailView