const en = {
  user: {
    module: 'Users',
    navigation: {
      list: 'Users List',
      new: 'New User',
      edit: 'Edit User',
    },
    fetching: 'Loading',
    index: {
      title: 'Users',
      name: 'Name',
      surname: 'Surname',
      displayName: 'Display Name',
      email: 'E-mail',
      username: 'Username',
      roles: 'Roles',
      enabled: 'Enabled',
      disabled: 'Disabled',
      table: {
        showAll: 'Show all',
        page: 'page',
        of: 'of',
        rows: 'rows',
        noData: 'No data',
      },
      new: 'New',
      exportData: 'EXPORT DATA'
    },
    form: {
      newUser: 'New User',
      newUserCreated: 'New User added successfully',
      status: 'Status',
      select: '- select -',
      create: 'Create',
      update: 'Update',
    },
    errors: {
      required: 'Required field',
      minLength: 'should NOT be shorter than 1 character',
      minLength_plular: 'should NOT be shorter than {{count}} characters',
      maxLength: 'should NOT be longer than {{count}} characters',
      format: 'should match format : {{format}}',
    },
  },
}

export default en
