import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Column } from 'react-table'
import {
  MaintenanceJob,
  MaintenanceJobPeriodicity,
  MaintenancePage,
} from '../../../../types'
import { fetchJobs, setPageFilterSelectedValues } from '../../../../redux/actions'
import PageFiltersHelper from './PageFiltersHelper'
import { capitalizeFirstLetter, Error, findNodeByID } from '../../../../functions/shared'
import { ContainerComponent } from '../ContainerComponent/ContainerComponent'
import JobAddModalComponent from '../JobAddModalComponent/JobAddModalComponent'
import JobEditModalComponent from '../JobEditModalComponent/JobEditModalComponent'
import JobDeleteModalComponent from '../JobDeleteModalComponent/JobDeleteModalComponent'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import {
  Button,
  ButtonGroupHeader,
  Filters,
  HtmlTooltip,
  PageHeader,
  Table,
} from '@mv-submodules/inplant-components-fe'
import { fetchPlantStatus } from '@mv-submodules/inplant-maintenance-fe/redux/actions/plant'
import { MaintenanceClientFiltersParams, MaintenancePageFilters } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface StateProps {
  config: any
  smartdocsExists: boolean
}

interface OwnProps extends RouteComponentProps<any> {
  pageConf: MaintenancePage
  pageFilters: MaintenancePageFilters[]
  // t: TranslationFunction
}

interface DispatchProps {
  fetchJobs: (
    pageSlug?: string | null,
    clientFilters?: MaintenanceClientFiltersParams | null,
  ) => Promise<MaintenanceJob[]>
  setPageFilterSelectedValues: (slug: string, name: string, selected: null | string[]) => void,
  fetchPlantStatus: () => Promise<any>,
}

interface OwnStateProps {
  activeJob: null | MaintenanceJob
  activeDeleteJob: null | MaintenanceJob
  showNewJobModal: boolean
  fetching: boolean
  error: boolean
  data: MaintenanceJob[]
  plantStatus: any
}

const mapStateToProps = (state: any) => {
  return {
    config: state.config,
    smartdocsExists: state.hasOwnProperty('smartdocs'),
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchJobs: (pageSlug?: string | null, clientFilters?: MaintenanceClientFiltersParams | null) =>
    dispatch(fetchJobs(pageSlug, clientFilters)),
  fetchPlantStatus: () => dispatch(fetchPlantStatus()),
  setPageFilterSelectedValues: (slug: string, name: string, selected: null | string[]) =>
    dispatch(setPageFilterSelectedValues(slug, name, selected)),
})

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

export class Setup extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      activeJob: null,
      activeDeleteJob: null,
      showNewJobModal: false,
      fetching: false,
      error: false,
      data: [],
      plantStatus: undefined,
    }

    // bindings
    this.applyClientFilters = this.applyClientFilters.bind(this)
    this.getTableFilterComponent = this.getTableFilterComponent.bind(this)
    this.openSetupJobModal = this.openSetupJobModal.bind(this)
    this.closeNewJobModal = this.closeNewJobModal.bind(this)
    this.handleOpenEditJobModal = this.handleOpenEditJobModal.bind(this)
    this.closeEditJobModal = this.closeEditJobModal.bind(this)
    this.handleOpenDeleteJobModal = this.handleOpenDeleteJobModal.bind(this)
    this.handleOpenPlanDetail = this.handleOpenPlanDetail.bind(this)
    this.closeDeleteJobModal = this.closeDeleteJobModal.bind(this)
    this.getTableTdProps = this.getTableTdProps.bind(this)
  }

  public componentDidMount() {
    this.fetchData()
  }

  public openSetupJobModal() {
    this.setState({ showNewJobModal: true })
  }

  public closeNewJobModal(reload: boolean) {
    this.setState({ showNewJobModal: false })
    if (reload) {
      this.fetchData()
    }
  }

  public closeEditJobModal(reload: boolean) {
    this.setState({ activeJob: null })
    if (reload) {
      this.fetchData()
    }
  }

  public closeDeleteJobModal(reload: boolean) {
    this.setState({ activeDeleteJob: null })
    if (reload) {
      this.fetchData()
    }
  }

  public render() {
    if (this.state.error) {
      return (
        <div className='mt-3'>
          <Error />
        </div>
      )
    }

    const hiddenCols = this.props.pageConf.hiddenCols
    const colums = this.getColums(hiddenCols)
    const pageFilters = this.props.pageFilters[this.props.pageConf.slug]

    const filters: FilterComponent[] = pageFilters ? [
      ...((hiddenCols === null || !hiddenCols.includes('macroarea')) && PageFiltersHelper.haveClientFilter(pageFilters, 'macroarea') ?
          this.getTableFilterComponent(pageFilters, 'macroarea') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('target')) && PageFiltersHelper.haveClientFilter(pageFilters, 'target') ?
          this.getTableFilterComponent(pageFilters, 'target') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('operation')) && PageFiltersHelper.haveClientFilter(pageFilters, 'operation') ?
          this.getTableFilterComponent(pageFilters, 'operation') : []
      ),
      ...((hiddenCols === null || !hiddenCols.includes('type')) && PageFiltersHelper.haveClientFilter(pageFilters, 'type') ?
          this.getTableFilterComponent(pageFilters, 'type') : []
      ),
    ] : []

    return (
      <ContainerComponent>
        <PageHeader
          title={this.props.pageConf.name}
          subtitle={this.props.pageConf.description}
          rightButtons={this.props.pageConf.enableNewButton && (
            <ButtonGroupHeader>
              <Button variant='primary' onClick={() => this.openSetupJobModal()}
                      label={this.props.t('maintenance.setup.new')} />
            </ButtonGroupHeader>
          ) || undefined}
        />

        <div className='content'>
          <Filters fields={filters} />
          <Table
            columns={colums}
            isFetching={this.state.fetching}
            manualIsFetching={true}
            data={this.applyClientFilters(this.state.data)}
            className='-striped -highlight'
            showPaginationTop={false}
            noDataText={this.props.t('maintenance.table.noData')}
            pageText={this.props.t('maintenance.table.page')}
            ofText={this.props.t('maintenance.table.of')}
            rowsText={this.props.t('maintenance.table.rows')}

          />
        </div>

        <JobAddModalComponent
          isVisible={this.state.showNewJobModal}
          onClose={this.closeNewJobModal}
        />

        {this.state.activeJob !== null &&
        <JobEditModalComponent
          isVisible={this.state.activeJob !== null}
          job={this.state.activeJob}
          onClose={this.closeEditJobModal}
        />
        }

        {this.state.activeDeleteJob !== null &&
        <JobDeleteModalComponent
          isVisible={this.state.activeDeleteJob !== null}
          job={this.state.activeDeleteJob}
          onClose={this.closeDeleteJobModal}
        />
        }
      </ContainerComponent>
    )
  }


  private fetchData() {
    if (!this.state.fetching) {
      this.setState({ fetching: true })
      Promise.all([
        this.props.fetchJobs(this.props.pageConf.slug),
        this.props.fetchPlantStatus(),
      ])
        .then(([data, plantStatus]) => {
          this.setState({
            data,
            plantStatus,
          })
        })
        .catch(error => {
          this.setState({ error: true })
        })
        .finally(() => {
          this.setState({ fetching: false })
        })
    }
  }


  private handleOpenEditJobModal(job: MaintenanceJob) {
    this.setState({ activeJob: job })
  }

  private handleOpenDeleteJobModal(job: MaintenanceJob) {
    this.setState({ activeDeleteJob: job })
  }

  private handleOpenPlanDetail(job: MaintenanceJob) {
    this.props.history.push('/maintenance/scheduled-job/' + job.nextJob?.id)
  }


  private applyClientFilters(data: MaintenanceJob[]) {
    const pageFilters: MaintenancePageFilters[] = this.props.pageFilters[this.props.pageConf.slug]
    return PageFiltersHelper.applyClientFilters(pageFilters, data) as MaintenanceJob[]
  }

  private getTableFilterComponent(pageFilters: MaintenancePageFilters[], name: string): FilterComponent[] {
    const index = pageFilters.findIndex((item: MaintenancePageFilters) => {
      return item.name === name
    })

    if (index === -1) {
      return []
    }

    const pageFilter = pageFilters[index]
    const values = index > -1 ? (pageFilter.values !== null ? pageFilter.values : []) : []
    const selected = index > -1 ? (pageFilter.selected !== null ? pageFilter.selected : []) : []
    const inputType = pageFilter.inputType

    if (inputType === 'checkbox') {
      return [{
        type: 'MultiSelect',
        value: null,
        options: {
          items: values.map(o => ({
            label: o.name as string,
            value: o.value as string,
          })),
        },
        id: `multi_select_${name}`,
        label: this.props.t(`maintenance.plan.tableCols.${name}`),
        allSelectedLabel: this.props.t('maintenance.filters.allSelectedText'),
        headingLabel: this.props.t('maintenance.filters.headingText'),
        selectAllLabel: this.props.t('maintenance.filters.selectAllText'),
        selectedValues: selected as string[],
        onClickSelectAll: () => {
          if (selected.length === values.length) {
            this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, [])
          } else {
            this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, values.map(o => o.value as string))
          }
        },
        onClickItem: (value) => {
          if (selected.includes(value)) {
            this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, selected.filter(s => s !== value))
          } else {
            selected.push(value)
            this.props.setPageFilterSelectedValues(this.props.pageConf.slug, name, selected)
          }
        },
      }]
    }

    return []
  }

  private getTableTdProps(state: any, rowInfo: any, column: any) {
    return column.id === 'notes' ? { style: { overflow: 'visible' } } : {}
  }

  private getNodeDataByPath(path: string) {
    if (this.state.plantStatus && path) {
      return findNodeByID(path, this.state.plantStatus)
    }

    return null
  }

  private getColums(hiddenCols: null | string[]) {
    const cols: Column[] = []

    if (hiddenCols === null) {
      hiddenCols = []
    }

    if (!hiddenCols.includes('macroarea')) {
      const col = {
        Header: this.props.t('maintenance.setup.tableCols.macroarea'),
        accessor: 'macroarea.name',
        Cell: (row: any) => {
          if (row.value) {
            return capitalizeFirstLetter(row.value)
          }
          return null
        },
      } as any

      cols.push(col)
    }

    /*if (!hiddenCols.includes('component')) {
      let col = {
        Header: this.props.t('maintenance.setup.tableCols.component'),
        accessor: 'component.name',
        Cell: (row: any) => {
          if (row.value) {
            return capitalizeFirstLetter(row.value)
          }
          return null
        },
      } as any

      (PageFiltersHelper.haveClientFilter(pageFilters, 'component'))
        ? col.Filter = () => this.getTableFilterComponent(pageFilters, 'component')
        : col.filterable = false

      cols.push(col);
    }*/
    if (this.props.smartdocsExists && !hiddenCols.includes('pid')) {
      const col = {
        Header: this.props.t('maintenance.plan.tableCols.pid'),
        accessor: 'component.pid',
        width: 80,
        Cell: (row: any) => {
          if (row.original.hasOwnProperty('component') && row.original.component) {
            const nodeData = this.getNodeDataByPath(row.original.component.id)
            if (nodeData) {
              return nodeData.data.pid
            }
          }
          return null
        },
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('target')) {
      const col = {
        Header: this.props.t('maintenance.setup.tableCols.target'),
        accessor: 'target.name',
        Cell: (row: any) => {
          if (row.value) {
            return capitalizeFirstLetter(row.value)
          }
          return null
        },
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('operation')) {
      const col = {
        Header: this.props.t('maintenance.setup.tableCols.operation'),
        accessor: 'operation',
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('type')) {
      const col = {
        Header: this.props.t('maintenance.setup.tableCols.type'),
        accessor: 'type.name',
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('periodicity')) {
      const col = {
        Header: this.props.t('maintenance.setup.tableCols.periodicity'),
        id: 'periodicity',
        accessor: (d: any) => d.periodicity,
        width: 110,
        Cell: (row: any) => {
          if (row.value) {
            return this.props.t('maintenance.periodicity.' + MaintenanceJobPeriodicity[row.value])
          }
        },
        filterable: false,
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('hours')) {
      const col = {
        Header: this.props.t('maintenance.setup.tableCols.hours'),
        id: 'hours',
        accessor: (d: any) => d.hours,
        filterable: false,
        width: 80,
      } as any

      cols.push(col)
    }

    if (!hiddenCols.includes('notes')) {
      const col = {
        Header: this.props.t('maintenance.setup.tableCols.notes'),
        id: 'notes',
        accessor: (d: any) => d.notes,
        Cell: (row: any) => {
          const noteWrapper = row.value ? (
            <IconComponent icon={'clipboard'} />
          ) : (
            ''
          )

          return row.value ? (
            <HtmlTooltip
              content={
                row.value ? (
                  row.value
                ) : (
                  ''
                )
              }
              target={noteWrapper}
            />
          ) : (
            <></>
          )
        },
        filterable: false,
      } as any

      cols.push(col)
    }

    cols.push({
      Header: 'Action',
      id: 'delete',
      width: 140,
      Cell: (row: any) => (
        <div className='btn-group' role='group'>
          <Button
            icon={'edit'}
            spacing={{horizontal:false}}
            variant={"secondary-alternate"}
            size={"sm"}
            onClick={() => this.handleOpenEditJobModal(row.original as MaintenanceJob)}
          />
          <Button
            spacing={{horizontal:true}}
            variant={"danger-alternate"}
            icon={'times'}
            size={"sm"}
            onClick={() => this.handleOpenDeleteJobModal(row.original as MaintenanceJob)}
          />
          {(row.original as MaintenanceJob && row.original.nextJob?.id !== null) &&
          <Button
            variant={"secondary-alternate"}
            icon={'eye'}
            spacing={{horizontal:false}}
            size='sm'
            onClick={() => this.handleOpenPlanDetail(row.original as MaintenanceJob)}
          />
          }
        </div>
      ),
      filterable: false,
    })

    return cols
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(Setup)),
)