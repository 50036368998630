const it = {
  service: {
    module: 'Service',
    navigation: {
      list: 'Lista',
      new: 'Nuovo',
    },
    fetching: 'Caricamento',
    index: {
      title: 'Service',
      hasAttachments: 'Allegati',
      table: {
        showAll: 'Tutti',
        selectDateRange: 'Seleziona periodo',
        page: 'pagina',
        of: 'di',
        rows: 'righe',
        noData: 'Non ci sono dati',
      },
      new: 'Nuovo',
    },
    details: {
      openTimeEntriesModal: 'Log ore'
    },
    timeEntries: {
      title: 'Log ore',
      close: 'Chiudi',
      table: {
        activity: 'Attività',
        hours: 'Ore',
        created_on: 'Data',
        comments: 'Commenti',
        noData: 'Non ci sono dati',
        user: 'Utente'
      }
    },
    form: {
      all: 'tutti',
      assigned_to: 'Assegnatario',
      attachments: 'Allegati',
      author: 'Autore',
      client_hours: 'Ore in carico al cliente',
      competence: 'Competenza',
      create: 'Crea',
      created_on: 'Creato',
      custom_fields: {
        '2': 'Contatto',
        '5': 'Garanzia',
        '6': 'Causa attesa',
        '7': 'Tipo Azione',
        '8': 'Intervento On-Site',
        '9': 'Produttore',
        '10': 'Azione',
        '11': 'Data Azione',
        '12': 'Data Intervento',
        '13': 'Note Chiusura',
        '14': 'Causa',
        '15': 'Data Chiusura',
        '16': 'Competenza',
        '17': 'Ordine Produzione',
        '20': 'Tel. Contatto',
        '21': 'E-mail Cliente',
      },
      description: 'Descrizione',
      doneRatio: 'Completatamento',
      done_ratio: 'Completatamento',
      due_date: 'Scadenza',
      estimated_hours: 'Ore stimate',
      id: 'ID',
      is_private: 'Privato',
      lastUpdate: 'Ultima Modifica',
      newTickedCreated: 'Nuovo Ticker creato correttamente',
      newTicket: 'Nuovo Ticket',
      no: 'No',
      phone: 'Telefono',
      priority: 'Priorità',
      project: 'Progetto',
      requestType: 'Tipo Richiesta',
      select: '- seleziona -',
      spent_hours: 'Ore lavorate',
      startDate: 'Data',
      status: 'Stato',
      subject: 'Oggetto',
      total_estimated_hours: 'Totale ore stimate',
      tracker: 'Tracker',
      updated_on: 'Modificato',
      yes: 'Yes',
      watchers: 'Osservatori'
    },
    journal: {
      title: 'Modifiche',
      modal: {
        close: 'Chiudi'
      }
    },
    attachments: {
      preview: {
        title: 'File da caricare',
        download: 'Scarica',
      },
    },
  },
}

export default it
