import React, { Component } from 'react'
import { Modal, Table, TableRowInfo } from '@mv-submodules/inplant-components-fe'
import { withTranslation, WithTranslation } from 'react-i18next'
import { API } from '@mv-submodules/inplant-service-fe/redux/actions/service'
import { TimeEntry } from '@mv-submodules/inplant-service-fe/types/serviceListItem'
import { toDateTime } from '@mv-submodules/inplant-service-fe/functions/formatting'

interface OwnState {
  hasErrored: boolean
  isLoading: boolean
  timeEntries: TimeEntry[]
}

interface OwnProps {
  id: number
  onClose: () => void
  visible: boolean
}

type Props = OwnProps & WithTranslation

class TimeEntriesDetails extends Component<Props, OwnState> {
  constructor( props: Props ) {
    super(props)

    this.state = {
      hasErrored: false,
      isLoading: false,
      timeEntries: []
    }

    this._handleOnClose = this._handleOnClose.bind(this)
  }

  public componentDidUpdate( prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any ) {
    if(prevProps.id !== this.props.id || prevProps.visible !== this.props.visible) {
      this.setState({isLoading: true, hasErrored: false, timeEntries: []})
      API()
      .request(`/issues/${this.props.id}/time_entries`)
        .then((response: {time_entries: TimeEntry[]}) => {
          this.setState({timeEntries: response.time_entries, isLoading: false})
        })
        .catch( (e: any) => {
          console.log(e) // tslint:disable-line
          this.setState({hasErrored: true, isLoading: false})
        })
    }
  }

  public render() {
    const {t, visible} = this.props
    const { isLoading, timeEntries} = this.state

    return (
      <Modal
        visible={visible}
        onClose={this._handleOnClose}
        title={t('service.timeEntries.title')}
        width={75}
        closeLabel={t('service.timeEntries.close')}
      >
        <Table
          data={timeEntries}
          manualIsFetching={isLoading}
          columns={[
            {
              Header: t('service.timeEntries.table.activity'),
              id: 'activity',
              accessor: 'activity',
              Cell: (r: TableRowInfo) => {
                return r.original.activity && r.original.activity.name || ''
              }
            },
            {
              Header: t('service.timeEntries.table.hours'),
              id: 'hours',
              accessor: 'hours'
            },
            {
              Header: t('service.timeEntries.table.created_on'),
              id: 'created_on',
              accessor: 'created_on',
              Cell: (r: TableRowInfo) => toDateTime(r.original.created_on)
            },
            {
              Header: t('service.timeEntries.table.comments'),
              id: 'comments',
              accessor: 'comments'
            },
            {
              Header: t('service.timeEntries.table.user'),
              id: 'user',
              accessor: 'user',
              Cell: (r: TableRowInfo) => r.original.user && r.original.user.name || ''
            }
          ]}
          isFetching={isLoading}
          noDataText={t('service.timeEntries.table.noData')}
          />
      </Modal>

    )
  }

  private _handleOnClose() {
    this.setState({timeEntries: []}, () => this.props.onClose())
  }
}

export default withTranslation()(TimeEntriesDetails)
