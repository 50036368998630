import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { fetchTargetInfo } from '../../../../redux/actions'
import { MaintenanceHistory, MaintenanceScheduledJob, MaintenanceStatus, ModuleConfig } from '../../../../types'
import { ContainerComponent } from '../../widgets/ContainerComponent/ContainerComponent'
import ImageComponent from '../../widgets/ImageComponent/ImageComponent'
import * as moment from 'moment'
import {
  findNodeByID,
  getConfigParam,
  getDateFormat,
  haveTargetHours,
  oldPresentTarget,
  presentJobType,
  presentStatusBadge,
  presentTargetByPath,
  // presentTarget,
  presentTargetHours,
  showScheduledJobCol,
} from '../../../../functions/shared'
import RelatedJobs from './RelatedJobs'
import MarkAsDoneModal from './MarkAsDoneModal'
import RescheduleModal from './RescheduleModal'
import RejectModal from './RejectModal'
import { fetchPlantStatus } from '@mv-submodules/inplant-maintenance-fe/redux/actions/plant'
import { Alert, DetailView, Loader, PageHeader } from '@mv-submodules/inplant-components-fe'
import { fetchScheduledJobDetailDataNoRedux } from '@mv-submodules/inplant-maintenance-fe/redux/actions/scheduledJob'
import { ButtonVariants } from '@mv-submodules/inplant-components-fe/ui/components/Button/types'
import ActionLog from '@mv-submodules/inplant-components-fe/ui/components/ActionLog/ActionLog'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface StateProps {
  config: ModuleConfig
  fetching: boolean
  error: Error
  smartdocsExists: boolean
}

interface OwnStateProps {
  showMarkAsDoneModal: boolean
  showRescheduleModal: boolean
  showRejectModal: boolean
  targetData: any
  plantStatus: any
  isFetching: boolean
  fetchErrors?: string
  scheduledJob?: MaintenanceScheduledJob
}

interface DispatchProps {
  fetchScheduledJobDetailData: (id: string) => Promise<any>
  fetchPlantStatus: (url?: string) => Promise<any>
  fetchTargetInfo: (targetId: string) => Promise<any>
}

interface OwnProps extends RouteComponentProps<any> {
  // t: TranslationFunction
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any) => ({
  config: state.config,
  scheduledJob: state.maintenance.scheduledJobDetail.scheduledJob,
  smartdocsExists: state.hasOwnProperty('smartdocs'),
})

const mapDispatchToProps = (dispatch: Function) => ({
  fetchScheduledJobDetailData: (id: string) => dispatch(fetchScheduledJobDetailDataNoRedux(id)),
  fetchPlantStatus: () => dispatch(fetchPlantStatus()),
  fetchTargetInfo: (targetId: string) => dispatch(fetchTargetInfo(targetId)),
})

class ScheduledJobDetailComponentPageView extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = this.getInitialState()
    this.fetchData = this.fetchData.bind(this)
    this.showCostAndTime = this.showCostAndTime.bind(this)
    this.goToTargetMaintenances = this.goToTargetMaintenances.bind(this)
    this.openMarkAsDoneModal = this.openMarkAsDoneModal.bind(this)
    this.openRescheduleModal = this.openRescheduleModal.bind(this)
    this.openRejectModal = this.openRejectModal.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.showActions = this.showActions.bind(this)
  }

  public componentDidMount() {
    this.fetchData()
  }

  public showActions(): boolean {
    if (!this.state.scheduledJob) {
      return false
    }
    const { status } = this.state.scheduledJob!
    return status !== MaintenanceStatus.DONE && status !== MaintenanceStatus.REJECTED
  }

  public goToTargetMaintenances(target: string): void {
    this.props.history.push(`/maintenance/target/${target}`)
  }

  public render() {
    const { config, t } = this.props
    const { isFetching, fetchErrors } = this.state
    const enableScheduledJobRejectAction: boolean = getConfigParam(config, 'enableScheduledJobRejectAction', false)

    // const currency: string = getConfigParam(config, 'currency', '')
    //  const showTargetNameOnNullTarget: boolean = getConfigParam(config, 'showTargetNameOnNullTarget', false)

    let history
    if (
      showScheduledJobCol(config, 'history') &&
      this.state.scheduledJob &&
      this.state.scheduledJob.history &&
      this.state.scheduledJob.history.length > 0
    ) {
      history = (
        <ActionLog
          values={this.state.scheduledJob.history.map((entry: MaintenanceHistory, index: number) => ({
            user: entry.userFullname,
            date: entry.actionDate.format(`${getDateFormat()} HH:mm`),
            title: this.getActionDescription(entry.action),
            text: entry.notes,
          }))}
        />
      )
    }

    /*  let costTime
      if (this.showCostAndTime() && this.state.scheduledJob && this.state.scheduledJob.status === 'done') {
        costTime = (
          <React.Fragment>
            <tr>
              <th>{t('maintenance.scheduledJobDetail.recap.cost')}</th>
              <td>{this.state.scheduledJob.cost ? this.formatCost(this.state.scheduledJob.cost, currency) : ''}</td>
            </tr>
            <tr>
              <th>{t('maintenance.scheduledJobDetail.recap.time')}</th>
              <td>{this.state.scheduledJob.time ? this.formatTime(this.state.scheduledJob.time) : ''}</td>
            </tr>
          </React.Fragment>
        )
      }
      TODO INSERT
     */

    let images
    if (
      showScheduledJobCol(config, 'images') &&
      this.state.scheduledJob &&
      this.state.scheduledJob.images &&
      this.state.scheduledJob.images.length > 0
    ) {
      images = this.state.scheduledJob.images.map((filePath: string, index) => (
        <Column xs={6} sm={4} md={3} xl={2} key={index}>
          <ImageComponent imagePath={filePath} />
        </Column>
      ))
    }

    const nodeDetails =
      this.state.plantStatus && this.state.scheduledJob && this.state.scheduledJob.component
        ? findNodeByID(this.state.scheduledJob.component.id, this.state.plantStatus)
        : null
    const showTargetNameOnNullTarget: boolean = getConfigParam(config, 'showTargetNameOnNullTarget', false) // const enableAcknowledgment: boolean = getConfigParam(config, 'enableAcknowledgment', false)

    return (
      <ContainerComponent>
        <PageHeader
          backButton={true}
          backButtonOnClick={() => this.props.history.goBack()}
          title={t('maintenance.scheduledJobDetail.title')}
          subtitleButtons={[
            {
              label: t('maintenance.scheduledJobDetail.buttons.reschedule'),
              disabled: !this.showActions(),
              onClick: () => this.openRescheduleModal(),
              variant: 'primary-alternate',
            },
            ...((enableScheduledJobRejectAction && [
              {
                label: t('maintenance.scheduledJobDetail.buttons.reject'),
                disabled: !this.showActions(),
                onClick: () => this.openRejectModal(),
                variant: 'primary-alternate' as ButtonVariants,
              },
            ]) ||
              []),
            {
              label: t('maintenance.scheduledJobDetail.buttons.markAsDone'),
              disabled: !this.showActions(),
              onClick: () => this.openMarkAsDoneModal(),
              variant: 'primary',
            },
          ]}
        />
        <div className="content">
          {isFetching && <Loader />}
          {fetchErrors && <Alert type="danger" title={fetchErrors} />}
          {!isFetching && !fetchErrors && this.state.scheduledJob && (
            <>
              <DetailView
                leftComponent={[
                  ...((showScheduledJobCol(config, 'target') && [
                    {
                      element: {
                        label: { text: this.props.t('maintenance.actionScheduledJob.target') },
                        value: {
                          component: this.state.targetData ? (
                            <React.Fragment>
                              {presentTargetByPath(
                                this.state.targetData,
                                this.state.scheduledJob,
                                this.goToTargetMaintenances
                              )}{' '}
                              {this.props.smartdocsExists &&
                              this.state.scheduledJob &&
                              this.state.scheduledJob.target ? (
                                <a
                                  className="text-primary data-tooltips"
                                  data-title={this.props.t('maintenance.target.smartdocsLink')}
                                  href={'/smartdocs?id=' + this.state.scheduledJob.target.id.split('/').pop()}
                                >
                                  {' '}
                                  <sup>
                                    <IconComponent icon={'external-link-alt'} />
                                  </sup>
                                </a>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            oldPresentTarget(
                              this.state.scheduledJob,
                              this.goToTargetMaintenances,
                              showTargetNameOnNullTarget
                            )
                          ),
                        },
                      },
                    },
                  ]) ||
                    []),

                  ...((showScheduledJobCol(config, 'jobType') && [
                    {
                      element: {
                        label: { text: this.props.t('maintenance.actionScheduledJob.jobType') },
                        value: { component: presentJobType(this.state.scheduledJob) },
                      },
                    },
                  ]) ||
                    []),
                  ...((showScheduledJobCol(config, 'type') && [
                    {
                      element: {
                        label: { text: t('maintenance.scheduledJobDetail.recap.type') },
                        value: { text: this.state.scheduledJob.type.name },
                      },
                    },
                  ]) ||
                    []),
                  ...((haveTargetHours(this.state.scheduledJob) && [
                    {
                      element: {
                        label: {
                          text: t('maintenance.scheduledJobDetail.recap.hours'),
                          //       subText: t('maintenance.scheduledJobDetail.recap.sinceLastMaintenance'),
                        },
                        value: { component: presentTargetHours(this.state.scheduledJob) },
                      },
                    },
                  ]) ||
                    []),
                  ...((showScheduledJobCol(config, 'operation') && [
                    {
                      element: {
                        label: { text: t('maintenance.scheduledJobDetail.recap.operation') },
                        value: { text: this.state.scheduledJob.operation },
                      },
                    },
                  ]) ||
                    []),
                  ...((showScheduledJobCol(config, 'type') && [
                    {
                      element: {
                        label: { text: t('maintenance.scheduledJobDetail.recap.type') },
                        value: { text: this.state.scheduledJob.type.name },
                      },
                    },
                  ]) ||
                    []),
                  ...((showScheduledJobCol(config, 'userFullName') && [
                    {
                      element: {
                        label: { text: t('maintenance.scheduledJobDetail.recap.operatorName') },
                        value: { text: this.state.scheduledJob.userFullName },
                      },
                    },
                  ]) ||
                    []),
                  ...((showScheduledJobCol(config, 'notes') &&
                    this.state.scheduledJob &&
                    this.state.scheduledJob.notes &&
                    this.state.scheduledJob.notes.length > 0 && [
                      {
                        element: {
                          label: { text: t('maintenance.actionScheduledJob.notes') },
                          value: { text: this.state.scheduledJob.notes },
                        },
                      },
                    ]) ||
                    []),
                ]}
                rightComponent={[
                  ...((nodeDetails &&
                    nodeDetails.data &&
                    showScheduledJobCol(config, 'pid') && [
                      {
                        label: { text: this.props.t('maintenance.actionScheduledJob.pid') },
                        value: { text: nodeDetails.data.pid },
                        inline: true,
                      },
                    ]) ||
                    []),
                  ...((showScheduledJobCol(config, 'dueDate') && [
                    {
                      label: { text: t('maintenance.scheduledJobDetail.recap.dueDate') },
                      value: { text: this.state.scheduledJob.dueDate.format(getDateFormat()) },
                      inline: true,
                    },
                  ]) ||
                    []),
                  ...((showScheduledJobCol(config, 'plannedDate') && [
                    {
                      label: { text: t('maintenance.scheduledJobDetail.recap.plannedDate') },
                      value: { text: this.state.scheduledJob.plannedDate.format(getDateFormat()) },
                      inline: true,
                    },
                  ]) ||
                    []),
                  ...((showScheduledJobCol(config, 'status') && [
                    {
                      label: { text: t('maintenance.scheduledJobDetail.recap.status') },
                      value: { element: presentStatusBadge(this.state.scheduledJob) },
                      inline: true,
                    },
                  ]) ||
                    []),
                ]}
                fullComponent={[
                  ...((images && [
                    {
                      label: { text: t('maintenance.scheduledJobDetail.images') },
                      value: { component: images },
                    },
                  ]) ||
                    []),
                  ...((history && [
                    {
                      label: { text: t('maintenance.scheduledJobDetail.history.title') },
                      value: { component: history },
                    },
                  ]) ||
                    []),
                ]}
              />

              <RelatedJobs scheduledJob={this.state.scheduledJob} />

              <MarkAsDoneModal
                scheduledJob={this.state.scheduledJob}
                isVisible={this.state.showMarkAsDoneModal}
                onClose={this.onModalClose}
              />

              <RescheduleModal
                scheduledJob={this.state.scheduledJob}
                isVisible={this.state.showRescheduleModal}
                onClose={this.onModalClose}
              />

              <RejectModal
                scheduledJob={this.state.scheduledJob}
                isVisible={this.state.showRejectModal}
                onClose={this.onModalClose}
              />
            </>
          )}
        </div>
      </ContainerComponent>
    )
  }

  private fetchData() {
    if (!this.state.isFetching) {
      this.setState({ fetchErrors: undefined, isFetching: true })
      Promise.all([
        this.props.fetchPlantStatus(),
        this.props.fetchScheduledJobDetailData(this.props.match.params.scheduledJobId),
      ])
        .then(([plantStatus, scheduledJob]) => {
          this.setState({
            plantStatus,
            scheduledJob,
          })
          if (this.props.smartdocsExists && scheduledJob && scheduledJob.target) {
            return this.props
              .fetchTargetInfo(scheduledJob.target.id)
              .then((targetData: any) => {
                this.setState({ targetData })
              })
              .catch((error: any) => {
                console.log(error) //tslint:disable-line
              })
          }
        })
        .catch((error: any) => {
          this.setState({
            fetchErrors:
              error.statusCode === 404
                ? this.props.t('maintenance.errors.targetNotFound')
                : this.props.t('maintenance.errors.somethingGoneWrong'),
          })
        })
        .finally(() => {
          this.setState({
            isFetching: false,
          })
        })
    }
  }

  private getInitialState(): OwnStateProps {
    return {
      showMarkAsDoneModal: false,
      showRescheduleModal: false,
      showRejectModal: false,
      targetData: (this.state && this.state.targetData) || null,
      isFetching: false,
      fetchErrors: undefined,
      plantStatus: (this.state && this.state.plantStatus) || undefined,
      scheduledJob: (this.state && this.state.scheduledJob) || undefined,
    }
  }

  private openMarkAsDoneModal(): void {
    this.setState({ showMarkAsDoneModal: true })
  }

  private openRescheduleModal(): void {
    this.setState({ showRescheduleModal: true })
  }

  private openRejectModal(): void {
    this.setState({ showRejectModal: true })
  }

  private onModalClose(reload: boolean = false): void {
    this.setState({
      showMarkAsDoneModal: false,
      showRescheduleModal: false,
      showRejectModal: false,
    })

    if (reload) {
      this.fetchData()
    }
  }

  private showCostAndTime(): boolean {
    return getConfigParam(this.props.config, 'enableScheduledJobConstAndTime', false)
  }

  /* private formatCost(cost: number, currency: string): string {
     const c = cost / 100
     return `${c.toFixed(2)} ${currency}`
   }

   private formatTime(time: number): string {
     return `${time.toFixed(2)} h`
   }
 */

  private getActionDescription(action: { type: string; rescheduleDate?: moment.Moment | null }) {
    let desctiption = this.props.t(`maintenance.scheduledJobDetail.historyActions.type.${action.type}`)
    if (action.rescheduleDate && action.rescheduleDate !== null) {
      desctiption += ` ${this.props.t(
        'maintenance.scheduledJobDetail.historyActions.to'
      )} ${action.rescheduleDate.format(getDateFormat())}`
    }
    return desctiption
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ScheduledJobDetailComponentPageView))
)
