// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import Checkbox from './Checkbox'
import InvalidFeedback from './InvalidFeedback'

interface Props {
  id?: string
  label: string
  name?: string
  value?: boolean
  disabled?: boolean
  readOnly?: boolean
  error?: string
  onChange: (value: boolean) => void
}

const CheckInput = (props: Props) => {
  const handleChange = (currentTarget: HTMLInputElement) => {
    props.onChange(currentTarget.checked)
  }
  let className = 'form-check-input'
  if (props.error != null) {
    className += ' is-invalid'
  }

  return (
    <div className="form-group">
      <Checkbox
        id={props.id}
        className={className}
        label={props.label}
        name={props.name}
        checked={!!props.value}
        disabled={props.disabled}
        readOnly={props.readOnly}
        onChange={handleChange}
      />
      {props.error && <InvalidFeedback error={props.error} />}
    </div>
  )
}

export default CheckInput
