import { Template } from '../types'
import { Component } from './component'
import { Metric } from './metric'

export const ComponentGroup: Template = {
  id: 'componentGroup',
  name: 'ComponentGroup',
  version: '0.1.0',
  date: '2018-11-08',
  fields: [
    {
      name: 'label',
      type: 'string',
      label: 'componentGroup.label',
      width: 12,
      required: true,
      nodePath: 'data',
      translatable: true,
    },
    {
      type: 'space',
      name: '',
      label: '',
      width: 12
    },
    {
      name: 'hasQRCode',
      type: 'boolean',
      label: 'componentGroup.hasQRCode',
      width: 4,
      nodePath: 'data',
      values: true
    },
    {
      name: 'inUse',
      type: 'boolean',
      label: 'componentGroup.nodeIsActive',
      width: 4,
      nodePath: 'data',
      values: false,
    },
  ],
  copyable: true,
  allowedSubcomponents: [Component, Metric],
}
