const it = {
  general: {
    mv4iot: 'MV4IoT',
    userLogout: 'Logout',
    userManagement: 'Utenti',
  },

  lastUpdate: 'Ultimo aggiornamento: {{count}} min fa',
  loginEmailLabel: 'Email',
  loginEmailPlaceholder: 'Inserisci la tua email',
  loginPasswordLabel: 'Password',
  loginPasswordPlaceholder: 'Inserisci la tua password',
  loginSubmitText: 'Login',
  navDashboardLinkText: 'Dashboard',
  navPlantStatusLinkText: 'Stato Impianto',
  navProductionControlLinkText: 'Controllo Produzione',
  navMaintenanceLinkText: 'Manutenzione',
  navSettingsLinkText: 'Impostazioni',

  maintenanceCalendar: 'Calendario Manutenzioni',
  maintenanceNewIntervention: 'Nuovo Intervento',

  dateRangeToday: 'Oggi',
  dateRangeLastDay: 'Ieri',
  dateRange7Days: '1 Settimana',
  dateRangeLastWeek: 'Ultima Settimana',
  dateRange30Days: '1 Mese',
  dateRangeLastMonth: 'Ultimo Mese',
  dateRangeLast6Month: '6 Mesi',
  dateRangeLastYear: 'Ultimo Anno',
  dateRangeYear: "Quest'anno",

  performance: 'Prestazioni',
  availability: 'Disponibilità',
  quality: 'Qualità',

  errors: {
    code404: {
      title: 'Pagina non trovata',
      message: 'La pagina che stai cercando non è stata trovata.',
    },
  },
  widget: {
    rtmeasures: {
      title: 'MONITOR',
    },
  },
  dateRangeFiler: {
    pleaseSelect: 'Select dates',
  },
}

export default it
