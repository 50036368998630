import { AnyAction } from 'redux'

const indexInitialState = {
  fileData: null,
  fileError: false,
  fileFetching: false,
}

export const storage = (state = indexInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return Object.assign({}, { ...state })

    case 'SMARTDOCS_FILE_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          fileError: false,
          fileFetching: action.fetching,
        }
      )
    case 'SMARTDOCS_FILE_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          fileData: action.data,
          fileError: false,
          fileFetching: false,
        }
      )
    case 'SMARTDOCS_FILE_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          fileData: null,
          fileError: action.payload,
          fileFetching: false,
        }
      )

    default:
      return state
  }
}
