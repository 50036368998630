import * as React from 'react'

export class CustomizedTick extends React.Component<any> {
  public render() {
    const { x, y, payload, labels } = this.props

    return (
      <g transform={`translate(${x},${y})`} className="custom-y-tick">
        <text x={0} y={0} dy={0} textAnchor="end" fill="#333">
          {labels[payload.value]}
        </text>
      </g>
    )
  }
}
