import { AnyAction } from 'redux'
import { MaintenanceScheduledJob } from '../../types'
import * as _ from 'lodash'

interface SchedulesJobsStateInterface {
  fetching: boolean
  error: Error | null
  data: MaintenanceScheduledJob[]
}

const scheduledJobsInitialState = {
  fetching: false,
  error: null,
  data: [],
}

export const scheduledJobs = (state: SchedulesJobsStateInterface = scheduledJobsInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return Object.assign(
        {},
        {
          ...state,
        }
      )

    case 'MAINTENANCE_SCHEDULED_JOBS_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: true,
        }
      )

    case 'MAINTENANCE_SCHEDULED_JOBS_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          error: null,
          fetching: false,
          data: action.payload.data,
        }
      )

    case 'MAINTENANCE_SCHEDULED_JOBS_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          error: action.payload,
          fetching: false,
        }
      )

    case 'MAINTENANCE_SCHEDULED_JOB_ACK_SUCCESS': {
      const newState = _.cloneDeep(state)
      newState.data = newState.data.map(element => {
        if (element.id !== action.payload.scheduledJob.id) {
          return element
        }
        return { ...element, ...action.payload.scheduledJob }
      })
      return newState
    }

    default:
      return state
  }
}
