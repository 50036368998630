const en = {
  moduleSmartdocs: {
    module: 'Smartdoc',
    fetching: 'Fetching',
    maintenances: 'MaintenancesComponent',
    maintenance: {
      setup: {
        periodicity: {
          '1_DAY': '1 Day',
          '1_WEEK': '1 Week',
          '2_WEEKS': '2 Weeks',
          '1_MONTH': '1 Month',
          '2_MONTHS': '2 Months',
          '3_MONTHS': '3 Months',
          '6_MONTHS': '6 Months',
          '1_YEAR': '1 Year',
          '2_YEARS': '2 Years',
        },
      },
      addJob: {
        target: 'Target',
        operation: 'Operation',
        type: 'Type',
        periodicity: 'Periodicity',
        hours: 'Hours',
        notes: 'Notes',
      },
      status: {
        done: 'DONE',
        overdue: 'OVERDUE',
        planned: 'TO DO',
        unhandled: 'UNHANDLED',
        postponed: 'TO DO',
        rejected: 'REJECTED',
      },
      jobType: {
        custom: 'On condition',
        maintenance: 'Periodic',
        anomaly: 'Anomaly',
      },
    },
    media: {
      types: {
        Manual: 'Manual',
        Manuals: 'Manuals',
        Accessory: 'Accessory',
        Accessories: 'Accessories',
        TechnicalFile: 'Technical File',
        TechnicalFiles: 'Technical Files',
        SparePart: 'Spare Part',
        SpareParts: 'Spare Parts',
        Certification: 'Certification',
        Certifications: 'Certifications',
        Maintenance: 'Maintenance',
        Maintenances: 'Maintenances',
        Media: 'Media',
      },
      modal: {
        title: 'Media preview',
        cancel: 'Close',
      },
    },
  },
  treeDetailsFilename: 'Name',
  treeDetailsVersion: 'Version',
  treeDetailsDate: 'Date',
  treeDetailsTitle: '',
  treeDetailsTitlenull: '',
  treeDetailsTitlemanuals: 'Manuals',
  treeDetailsTitlecertificates: 'Certificates',
  treeDetailsTitledataSheets: 'Data Sheets',
  treeDetailsTitlespareParts: 'Spare Parts',
  treeDetailsTitleaccessories: 'Accessories',
  treeDetailsTitleGlobal: 'Plant Documents',
  treeDetailsTitleMedia: 'Media',
  treeDetailsCode: 'Code',
  treeDetailscode: 'Code',
  treeDetailsDescription: 'Description',
  treeDetailsPrice: 'Price',
  treeDetailsprice: 'Price',
  treeDetailsTitlesparePartsList: 'Spare Parts Manuals',
  treeDetailsTitlemaintenances: 'Next Maintenances',
  treeDetailsoperation: 'Operation',
  treeDetailsdescription: 'Description',
  treeDetailsexpected: 'Expected',
  treeDetailsdocumentType: 'Type',
  treeDetailsdocumentVersion: 'Version',
  treeDetailslink: 'Link',
  treeDetailslastEdit: 'Date',
  treeDetails: {
    periodicMaintenance: 'Periodic Maintenance',
    noMaintenances: 'No maintenance',
    noMedia: 'No media',
  },
}

export default en
