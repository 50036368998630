import { Dispatch } from 'redux'
import { AnyAction } from 'redux'

import { ServiceListItem, IdNameType } from '../../types'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { TimeEntry } from '@mv-submodules/inplant-service-fe/types/serviceListItem'

export const API = () => FetchWrapper.getInstance('service')

// ----------------------------------------------------

export const serviceHasErrored = (bool: boolean): AnyAction => {
  return {
    type: 'SERVICE_HAS_ERRORED',
    hasErrored: bool,
  }
}

// ----------------------------------------------------

export const serviceIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'SERVICE_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------

export const serviceShowNewItemMessage = (bool: boolean): AnyAction => {
  return {
    type: 'SERVICE_SHOW_NEW_ITEM_MESSAGE',
    showNewItemMessage: bool,
  }
}

// ----------------------------------------------------

export const serviceItemsDataSuccess = (items: ServiceListItem[]): AnyAction => {
  return {
    type: 'SERVICE_ITEMS_FETCH_DATA_SUCCESS',
    items,
  }
}

// ----------------------------------------------------

export const itemsFetchData = (): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(serviceIsLoading(true))

      const url = '/issues'
      const issues = await API().request(url)

      return Promise.all([issues]).then(values => {
        dispatch(serviceIsLoading(false))
        dispatch(serviceItemsDataSuccess(values[0].issues))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(serviceHasErrored(true))
      throw error
    }
  }
}

// ----------------------------------------------------

export const serviceItemsAttributesSuccess = (
  trackers: IdNameType[],
  priorities: IdNameType[],
  competenze: IdNameType[],
  tipoAzione: IdNameType[]
): AnyAction => {
  return {
    type: 'SERVICE_ITEMS_FETCH_ATTRIBUTES_SUCCESS',
    trackers,
    priorities,
    competenze,
    tipoAzione,
  }
}

// ----------------------------------------------------

export const itemsFetchAttributes = (): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(serviceIsLoading(true))
      const urlTrackers = '/trackers'
      const urlPriorities = '/priorities'
      const urlCompetenze = '/custom_fields/competence'
      const urlAzione = '/custom_fields/Action type'

      const attributeTrackers = await API().request(urlTrackers)
      const attributePriorities = await API().request(urlPriorities)
      const attributeCompetenze = await API().request(urlCompetenze)
      const attributeAzione = await API().request(urlAzione)

      return Promise.all([attributeTrackers, attributePriorities, attributeCompetenze, attributeAzione]).then(
        values => {
          dispatch(serviceIsLoading(false))
          dispatch(
            serviceItemsAttributesSuccess(
              values[0].trackers,
              values[1].issue_priorities,
              values[2].possible_values
                ? values[2].possible_values.map((item: any) => {
                    return {
                      id: item.value,
                      name: item.label,
                    }
                  })
                : [],
              values[3].possible_values
                ? values[3].possible_values.map((item: any) => {
                    return {
                      id: item.value,
                      name: item.label,
                    }
                  })
                : []
            )
          )
        }
      )
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(serviceHasErrored(true))
      throw  error
    }
  }
}

// ----------------------------------------------------

export const itemsFetchSingleData = (id: number): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(serviceIsLoading(true))
      const url = '/issues/' + id
      const serviceItemsData = await API().request(url)

      if (serviceItemsData) {
        dispatch(serviceIsLoading(false))
        return serviceItemsData
      }
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(serviceHasErrored(true))
      throw error
    }
  }
}


// ----------------------------------------------------

export const serviceTimeEntriesHasErrored = (bool: boolean): AnyAction => {
  return {
    type: 'SERVICE_TIMEENTRIES_HAS_ERRORED',
    hasErrored: bool,
  }
}

// ----------------------------------------------------

export const serviceTimeEntriesIsLoading = (bool: boolean): AnyAction => {
  return {
    type: 'SERVICE_TIMEENTRIES_IS_LOADING',
    isLoading: bool,
  }
}

// ----------------------------------------------------

export const serviceTimeEntriesDataSuccess = (items: TimeEntry[]): AnyAction => {
  return {
    type: 'SERVICE_TIMEENTRIES_FETCH_DATA_SUCCESS',
    items,
  }
}


// ----------------------------------------------------

export const fetchTimeEntriesData = (): Function => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(serviceTimeEntriesIsLoading(true))

      const url = '/time_entries'
      const timeEntries = await API().request(url)

      return Promise.all([timeEntries]).then(values => {
        dispatch(serviceTimeEntriesIsLoading(false))

        dispatch(serviceTimeEntriesDataSuccess(values[0].time_entries))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(serviceTimeEntriesHasErrored(true))
      throw  error
    }
  }
}
