// * -------------------------------- NPM --------------------------------------
import * as React from "react"

interface Props {
  children: React.ReactNode
  textMuted?: boolean
  textAlign?: 'left' | 'center' | 'right'
  textSizeSmall?: boolean
}

class CardFooter extends React.Component<Props> {
  public render(){
    const {
      children,
      textAlign,
      textMuted,
      textSizeSmall
    } = this.props

    return (
      <div className={
        "card-footer" +
        (textMuted ? " text-muted" : null) +
        (textAlign ? " text-" + textAlign : null) +
        (textSizeSmall ? " small" : null)
      }>
        {children}
      </div>
    )
  }
}

export default CardFooter
