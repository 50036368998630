// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import DatePicker from 'react-datepicker'
import { parseISO } from 'date-fns'

// * -------------------------------- MODULE --------------------------------------
import '../../../styles/_index.scss'
import SearchInput from '../Input/SearchInput'
import { generateComponentId } from '../../../functions/components'
import MultiSelect from '../Input/MultiSelect'
import DateRangeInput from '../Input/DateRangeInput'
import Button from '../Button/Button'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";


export type filterType = 'Select' | 'SearchInput' | 'Date' | 'MultiSelect' | 'DateRange'

// TODO SPLIT IN OBJECT KEYS FOR TYPE AND DEFINE ONLY PROPERTIES FOR THAT COMPONENT

export type gridWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export interface FilterComponent {
  allSelectedLabel?: string
  customSize?: gridWidth
  dateFormat?: string
  dateValueFormat?: string
  disabled?: boolean
  headingLabel?: string,
  id?: string // @todo refactoring: should be required to avoid duplicated undefined node keys
  label?: string
  max?: string
  min?: string
  name?: string
  notClearable?: boolean
  onChange?: (name: string, value: string | number | null) => void
  onClickItem?: (value: string) => void
  onClickSelectAll?: () => void
  onDateSelectedChange?: (selected: string[]) => void // @todo refactoring: should be required if datepicker
  options?: {
    defaultOptionLabel?: string,
    defaultOptionDisabled?: boolean
    items: Array<{
      label: string
      value: string
    }>
  }
  placeholder?: string
  selectAllLabel?: string
  selectedValues?: string[] // @todo refactoring: should be required if datepicker
  throttle?: number
  timePicker24Hour?: boolean
  timePicker?: boolean
  type: filterType
  value: string | number | null // @todo refactoring: should be NOT required if datepicker
}

interface Props {
  fields: FilterComponent[],
  advancedFilters?: {
    label: string
    onClick: () => void
  }
}

const getCustomColumnWidth = (component: FilterComponent) => `${component.customSize ? `col-md-${component.customSize}` : ''}`

class Filters extends React.Component<Props> {
  constructor(props: Props) {
    super(props)

    this.renderFilter = this.renderFilter.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }


  public renderFilter(component: FilterComponent) {
    switch (component.type) {
      case 'SearchInput':
        return (
          <div className={`form-group filter filter-search w-100-mobile ${getCustomColumnWidth(component)} `}
               key={`filter_${component.id}`}>
            <SearchInput
              id={component.id || generateComponentId('searchinput')}
              label={component.label}
              placeholder={component.placeholder}
              savedSearchString={component.value && component.value as string || undefined}
              throttle={component.throttle}
              clearable={!component.notClearable}
              onChange={value => this.handleChange(component.name || '', value, component.onChange)}
              disabled={component.disabled}
            />
          </div>
        )
      case 'Select':
        return (
          <div className={`form-group filter filter-select w-100-mobile ${getCustomColumnWidth(component)} `}
               key={`filter_${component.id}`}>
            <label htmlFor={component.id}>{component.label}</label>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <IconComponent
                  icon={"filter"}
                />
              </div>
              <select
                className='form-control'
                name={component.name}
                disabled={component.disabled}
                id={component.id || generateComponentId('select')}
                value={component.value || undefined}
                onChange={event => this.handleChange(event.target.name, event.target.value, component.onChange)}
              >
                {component.options && component.options.defaultOptionLabel && (
                  <option value='' disabled={component.options.defaultOptionDisabled}>
                    {component.options && component.options.defaultOptionLabel || ''}
                  </option>
                )}
                {component.options && component.options.items && component.options.items.map((option, index) => (
                  <option key={`filter-option-${option.value}${index}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )
      case 'Date':
        return (
          <div className={`form-group filter filter-date w-100-mobile ${getCustomColumnWidth(component)}`}
               key={`filter_${component.id}`}>
            <label htmlFor={component.id}>{component.label}</label>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <IconComponent
                  icon={"calendar"}
                />
              </div>
              <DatePicker
                onChange={date => this.handleChange(component.name || '', date && date instanceof Date && date.toISOString() || null, component.onChange)}
                selected={component.value ? parseISO(component.value as string) : null}
                dateFormat={'dd/MM/yyyy'}
                wrapperClassName='form-control'
                className='form-control'
                autoComplete='off'
                name={component.name}
                minDate={component.min && new Date(component.min) || undefined}
                maxDate={component.max && new Date(component.max) || undefined}
                disabled={component.disabled}
              />
              {component.value && !component.notClearable && (
                <div className='input-group-append'>
                  <button className='btn btn-link'>
                    <IconComponent
                      icon={"times-circle"}
                      size='sm'
                      onClick={() => this.handleChange(component.name || '', null, component.onChange)}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        )
      case 'MultiSelect':
        return (
          <div className={`filter filter-select w-100-mobile ${getCustomColumnWidth(component)} `}
               key={`filter_${component.id}`}>
            <MultiSelect
              selectedValues={component.selectedValues!}
              options={component.options!.items}
              headingLabel={component.headingLabel!}
              selectAllLabel={component.selectAllLabel!}
              allSelectedLabel={component.allSelectedLabel!}
              onClickSelectAll={() => component.onClickSelectAll!()}
              onClickItem={(value: string) => component.onClickItem!(value)}
              label={component.label}
              id={component.id || generateComponentId('multiselect')}
            />
          </div>
        )
      case 'DateRange':
        return (
          <div className={`form-group filter filter-select w-100-mobile ${getCustomColumnWidth(component)} `}
               key={`filter_${component.id || generateComponentId('daterangepicker')}`}>
            <DateRangeInput
              selected={component.selectedValues!}
              label={component.label}
              id={component.id}
              dateFormat={component.dateFormat}
              dateValueFormat={component.dateValueFormat}
              onSelectedChanged={selected => component.onDateSelectedChange!(selected)}
              timePicker24Hour={component.timePicker24Hour}
              timePicker={component.timePicker}
            />
          </div>
        )
      default:
        return null
    }
  }

  public render() {
    const { fields, advancedFilters } = this.props
    const fieldRendered = fields.map((field) => this.renderFilter(field))
    return (
      <div className='row table-filters'>
        <>
          {fieldRendered}
          {advancedFilters && (
            <div className='d-flex align-items-center form-group w-100-mobile filter-button'>
              <Button
                spacing={{ vertical: false, horizontal: false }}
                variant='secondary-alternate'
                label={advancedFilters.label}
                onClick={() => advancedFilters.onClick()}
              />
            </div>
          )}
        </>
      </div>
    )
  }

  private handleChange(name: string, value: string | number | null,
                       cb?: (name: string, value: string | number | null) => void) {
    if (cb) {
      cb(name, value)
    }
  }
}

export default Filters
