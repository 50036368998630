// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import RightDetailView, { RightDetailElements } from './RightDetailView'
import LeftDetailView, { LeftDetailElements } from './LeftDetailView'
import '../../../styles/_index.scss'
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import { Icon} from '../../../services/icon';
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

interface Props {
  leftComponent: LeftDetailElements[],
  rightComponent: RightDetailElements[],
  fullComponent?: Array<{
    label?: {
      icon?: Icon,
      text?: string
    },
    value?: {
      text?: string,
      icon?: Icon,
      component?: React.ReactNode
    }
  }>
}

class DetailView extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    return (
      <div>
        <Row spacing={{horizontal:false,vertical:false}}>
          <Column md={7}>
            <div className={'left-detail-view'}>
              <LeftDetailView values={this.props.leftComponent} />
            </div>
          </Column>
          <Column md={5}>
            <div className={'left-detail-view'}>
              <RightDetailView values={this.props.rightComponent} />
            </div>
          </Column>
        </Row>
        <div className='full-content'>
          {this.props.fullComponent && this.props.fullComponent.map((option,index) => (
            <div key={index}>
              {option.label && (
                <label>
                  {option.label.icon &&
                  <IconComponent icon={option.label.icon} className={`${option.label.text && 'mr-1' || ''}`} />}
                  {option.label.text && option.label.text}
                </label>
              )}
              {option.value && (option.value.component ? (option.value.component) : (
                <p>
                  {option.value.icon &&
                  <IconComponent icon={option.value.icon} className={`${option.value.text && 'mr-1' || ''}`} />}
                  {option.value.text && option.value.text || '-'}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default DetailView