import * as React from 'react'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {withRouter} from 'react-router'
import {fetchPlanRunOeeData} from '../../../../redux/actions/graphs'
import DownloadDataButton
  from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import * as moment from 'moment'
import {Bar, ComposedChart, Label, Line, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {timeRangeFileString, TPRNCAlarmDays} from '../../../../functions/shared'
import {getDateRangeFiltering} from '@mv-submodules/inplant-core-fe/functions/date'
import TPRNCWarnAreaLoader from '../ChartUtils/TPRNCWarnAreaLoader'
import {Card, CardBody, CardFooter, CardHeader} from '@mv-submodules/inplant-components-fe';
import {AlertNoData} from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/SharedComponents/sharedComponents';
import {saveDataAsCSV} from "@mv-submodules/inplant-core-fe/functions";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface GraphPlanRunOeeStateProps {
  range: null | string
  prevRange: string | null
  dateStart: string | null
  dateEnd: string | null
  data: any
  lineData: any
  isLoading: boolean
  lastDateUpdate: number | null,
  measureUnits: object
  cliccable?: boolean
  TPRNCData: any
  TPRNCDataErrors: boolean
  TPRNCDataIsLoading: boolean
}

interface GraphPlanRunOeeDispatchProps {
  fetchPlanRunOeeData: () => Function
}

interface GraphPlanRunOeeOwnProps {
  // t: TranslationFunction
  history: any
}

interface GraphPlanRunOeeOwnState {
  lastUpdate: number | null
}

type GraphPlanRunOeeProps = GraphPlanRunOeeStateProps &
  GraphPlanRunOeeDispatchProps &
  GraphPlanRunOeeOwnProps &
  WithTranslation

const mapStateToProps = (state: any): GraphPlanRunOeeStateProps => ({
  range: state.dateFilters.range,
  prevRange: state.dateFilters.prevRange,
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
  data: state.graphPlanRunOeeData.data,
  lineData: state.graphPlanRunOeeData.lineData,
  isLoading: state.graphPlanRunOeeData.isLoading,
  lastDateUpdate: state.dateFilters.lastUpdate,
  measureUnits: state.config.generic.measureUnits || {},
  TPRNCData: state.graphTPRNCData.data,
  TPRNCDataErrors: state.graphTPRNCData.hasErrored,
  TPRNCDataIsLoading: state.graphTPRNCData.isLoading,
})

const mapDispatchToProps = (dispatch: Function): GraphPlanRunOeeDispatchProps => {
  return {
    fetchPlanRunOeeData: () => dispatch(fetchPlanRunOeeData()),
  }
}

const CustomTooltip = ({active, payload, label}: any) => {
  if (active && payload && payload[0]) {
    const payloadData = payload[0].payload
    return (
      <div className="recharts-default-tooltip">
        <ul>
          <li className="title">
            <strong>{moment(label).format('DD/MM')}</strong>
          </li>
          {Object.keys(payloadData)
            .filter(e => e !== 'time')
            .map((line: any, index: number) => {
              if (line === 'noee') {
                return
              }

              return <li key={index}>
                <strong
                  style={{
                    color: (line === 'oee' &&
                      payloadData.noee !== payloadData.oee ?
                        '#c1521f' :
                        'black'
                    )
                  }}
                >
                  {line}
                  {line === 'oee' ? ' (%)' : ''}
                </strong>
                <span className="value float-right">
                  {
                    payloadData[line] && line !== 'time' ?
                      payloadData[line].toFixed(2) :
                      payloadData[line]
                  }
                </span>
              </li>
            })}
        </ul>
      </div>
    )
  }

  return null
}

class GraphPlanRunOee extends React.Component<GraphPlanRunOeeProps, GraphPlanRunOeeOwnState> {
  constructor(props: GraphPlanRunOeeProps) {
    super(props)
    this.state = {
      lastUpdate: null,
    }
  }

  public componentWillMount() {
    this.props.fetchPlanRunOeeData()
  }

  public componentWillReceiveProps(newProps: GraphPlanRunOeeStateProps) {
    if (newProps.lastDateUpdate !== null && this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
      this.props.fetchPlanRunOeeData()
    }
  }

  public render() {
    /*if (this.props.hasErrored) {
            return <p>Sorry! There was an error loading data</p>;
        }*/

    const {dateStart, dateEnd, TPRNCData} = this.props

    if (this.props.isLoading) {
      return <p>Loading…</p>
    }

    let downloadData: string[][] =
      this.props.data && this.props.lineData
        ? this.props.data.map((e: any, i: number) => {
          return [
            moment(e[0]).format('YYYY/MM/DD'),
            e[1],
            e[2],
            e[3],
            this.props.lineData[i]
              ? this.props.lineData[i][1] * 100
              : null,
          ]
        })
        : []

    downloadData = [
      ['time', 'planned', 'run', 'operating', 'oee'].map(
        (c: string) => `${c}${this.props.measureUnits[c] ? ` (${this.props.measureUnits[c]})` : ''}`,
      ),
    ].concat(downloadData)

    const data =
      this.props.data &&
      this.props.lineData &&
      this.props.data.map((e: any, i: number) => {
        return {
          time: e[0],
          planned: e[1] / 60,
          run: e[2] / 60,
          operating: e[3] / 60,
          oee: this.props.lineData[i]
            ? this.props.lineData[i][1] * 100
            : null,
          noee: this.props.lineData[i]
            ? this.props.lineData[i][2] * 100
            : null,
        }
      })

    const timeFormat =
      this.props.range &&
      ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(this.props.range)
        ? 'MM/YYYY'
        : 'DD/MM'

    const {groupingDays} = dateStart && dateEnd ? getDateRangeFiltering(dateStart, dateEnd) : {groupingDays: 1}
    const tprncWarnAreas = dateStart && dateEnd && data && TPRNCData && Object.keys(TPRNCAlarmDays(TPRNCData)).map((a: string) => {
        return <ReferenceArea
          key={a}
          x1={moment(a).format('YYYY-MM-DDT00:00:00[Z]')}
          x2={
            // we need to check if it's the last item, if so we need to declare x2 ad undefined (we don't have data after the end), otherwise x2 is 1 x-step (groupingDays) after
            data && data.length > 0 && moment(a).format('YYYY-MM-DDT00:00:00[Z]') === data[data.length - 1].time ?
              undefined :
              moment(a).add(groupingDays, 'd').format('YYYY-MM-DDT00:00:00[Z]')
          }
          yAxisId={'right'}
          stroke={'red'}
          strokeOpacity={0.4}
          fill={'#ff6020'}
          fillOpacity={0.2}
        />
      }
    )

    const CustomizedDot = (props: any) => {
      const {
        cx, cy, payload
      } = props;

      if (payload.oee !== payload.noee) {
        return (
          <svg x={cx - 9} y={cy - 30} width={70} height={140} fill="red" viewBox="0 0 10 20">
            <g
              transform="translate(0,-291.70832)">
              <g>
                <path
                  d="m 2.2678572,295.93103 a 0.93903458,0.93903458 0 0 1 -0.9390346,0.93903   0.93903458,0.93903458 0 0 1 -0.93903457,-0.93903 0.93903458,0.93903458 0 0 1 0.93903457,-0.93903 0.93903458,0.93903458 0 0 1 0.9390346,0.93903 z"
                  style={{fill: '#ffa44f', fillOpacity: 1, stroke: 'none', strokeWidth: 0.20929725, strokeOpacity: 1}}/>
                <g
                  style={{stroke: 'none'}}>
                  <path
                    style={{fill: '#dc3545', fillOpacity: 1}}
                    d="m 1.5567409,292.32452 q 0,0.0796 -0.00847,0.1542 -0.00847,0.0729 -0.022029,0.16437 l -0.1186192,0.86084 H 1.2432474 L 1.133101,292.70918 q -0.00847,-0.0593 -0.015251,-0.11014 -0.00678,-0.0508 -0.013556,-0.0966 -0.00678,-0.0458 -0.010167,-0.0898 -0.00339,-0.044 -0.00339,-0.0915 0,-0.20165 0.061004,-0.29486 0.062699,-0.0932 0.1762341,-0.0932 0.05592,0 0.098284,0.0203 0.042364,0.0186 0.071171,0.0644 0.030502,0.0458 0.044059,0.12201 0.015251,0.0746 0.015251,0.18471 z m 0.03728,1.60644 q 0,0.0559 -0.020335,0.10506 -0.020335,0.0491 -0.055921,0.0847 -0.035586,0.0356 -0.084728,0.0576 -0.047448,0.0203 -0.1016735,0.0203 -0.055921,0 -0.1050627,-0.0203 -0.049142,-0.022 -0.084728,-0.0576 -0.035586,-0.0356 -0.057615,-0.0847 -0.020335,-0.0491 -0.020335,-0.10506 0,-0.0542 0.020335,-0.10167 0.022029,-0.0491 0.057615,-0.0847 0.035586,-0.0356 0.084728,-0.0559 0.049142,-0.0203 0.1050627,-0.0203 0.054226,0 0.1016735,0.0203 0.049142,0.0203 0.084728,0.0559 0.035586,0.0356 0.055921,0.0847 0.020335,0.0474 0.020335,0.10167 z"/>
                </g>
              </g>
            </g>
          </svg>
        );
      }

      return null;
    };

    return (
      <div className={"DashboardWidget WidgetPlanRunOee WidgetH50 " + (this.props.cliccable && 'cliccable')}>
        <Card marginBottom={3}>
          <CardHeader>
            {this.props.t('i4efficiency.cardTitle.oee')}
            {downloadData && (
              <DownloadDataButton
                saveDataAsCSV={saveDataAsCSV}
                data={downloadData}
                type={'csv'}
                label={'i4efficiency.download.buttonLabel'}
                className={'float-right'}
                fileName={'planrunoee' + timeRangeFileString(this.props.dateStart, this.props.dateEnd)}
                addTime={false}
              />
            )}
          </CardHeader>
          <CardBody>
            <div className="container-fluid chart-container">
              <div className="full-h">
                <Row verticalAlignment={"center"}>
                  <Column sm={12}>
                    <div className="text-center full-h">
                      <div className="chart-container chartsPlanRunHome">
                        <TPRNCWarnAreaLoader/>
                        {data ? (
                          <ResponsiveContainer>
                            <ComposedChart
                              width={700}
                              height={450}
                              data={data}
                              margin={{
                                top: 45,
                                right: 30,
                                left: 0,
                                bottom: 0,
                              }}
                              className={this.props.cliccable ? 'cliccable' : ''}
                              barGap={1}
                            >
                              <XAxis
                                dataKey="time"
                                tickFormatter={e => {
                                  return moment(e).format(timeFormat)
                                }}
                                axisLine={false}
                              />
                              <YAxis axisLine={false} yAxisId={'left'}>
                                <Label position={'top'} offset={30}>
                                  time (min)
                                </Label>
                              </YAxis>
                              <YAxis
                                axisLine={false}
                                yAxisId={'right'}
                                orientation={'right'}
                                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                width={20}
                                interval={0}
                              >
                                <Label position={'top'} offset={30}>
                                  %
                                </Label>
                              </YAxis>
                              <Tooltip content={<CustomTooltip/>} cursor={{fill: 'none'}}/>
                              {tprncWarnAreas}
                              <Bar
                                dataKey="planned"
                                fill="#98abc5"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                yAxisId={'left'}
                              />
                              <Bar
                                dataKey="operating"
                                fill="#7b6888"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                yAxisId={'left'}
                              />

                              <Bar
                                dataKey="run"
                                fill="#8a89a6"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                yAxisId={'left'}
                              />
                              <Line
                                dataKey={'noee'}
                                stroke="#d0743c"
                                yAxisId={'right'}
                                strokeWidth={2}
                                isAnimationActive={false}
                                dot={<CustomizedDot/>}
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        ) : (
                          <AlertNoData message={this.props.t('i4efficiency.fetch.noData')}/>
                        )}
                      </div>
                    </div>
                  </Column>
                </Row>
              </div>
            </div>
          </CardBody>
          <CardFooter
            textAlign={'right'}
            textMuted={true}
            textSizeSmall={true}
          >
            <ul className="legend float-left">
              <li>
                <span className="legend-color legend-planned-color legend-color-block"/> PLANNED
              </li>
              <li>
                <span className="legend-color legend-operating-color"/> OPERATING
              </li>
              <li>
                <span className="legend-color legend-run-color legend-color-block"/> RUN
              </li>
            </ul>
            <ul className="legend float-right">
              <li>
                <span className="legend-color legend-oee-color"/> OEE
              </li>
            </ul>
          </CardFooter>
        </Card>
      </div>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(GraphPlanRunOee)),
)
