import * as React from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {patchJob} from '../../../../redux/actions'
import {MaintenanceJob, MaintenanceJobPatchParams, MaintenanceJobPeriodicity, MaintenanceType} from '../../../../types'

import {capitalizeFirstLetter} from '../../../../functions/shared'
import {fetchMaintenanceTypes} from '@mv-submodules/inplant-maintenance-fe/redux/actions/types'
import Loader from '@mv-submodules/inplant-components-fe/ui/components/Loader/Loader'
import {Alert, Checkbox, Input, Select, TextArea} from '@mv-submodules/inplant-components-fe'
import {SelectOptions} from '@mv-submodules/inplant-components-fe/ui/components/Input/Select'
import {FormModal} from "@mv-submodules/inplant-components-fe/ui/components/Modal/Modal";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

export interface DispatchProps {
  fetchMaintenanceTypes: () => Promise<any>
  patchJob: (params: MaintenanceJobPatchParams) => Promise<any>
}

export interface OwnStateProps {
  id: string
  periodicity: string
  hours: string
  notes: string
  isSpecialOperation: boolean
  errors: object,
  maintenanceTypes: MaintenanceType[],
  isSubmitting: boolean,
  isFetching: boolean,
  fetchErrors: boolean
}

export interface OwnProps extends RouteComponentProps<any> {
  isVisible: boolean
  job: MaintenanceJob | null
  onClose: Function | null,
  maintenanceTypes?: MaintenanceType[]
  // t: TranslationFunction
}

export type Props = DispatchProps & OwnProps & WithTranslation

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchMaintenanceTypes: (url?: string) => dispatch(fetchMaintenanceTypes()),
  patchJob: (params: MaintenanceJobPatchParams) => dispatch(patchJob(params)),
})

const presentJobTarget = (job: MaintenanceJob): string => {
  const path: string[] = []
  if (job.macroarea) {
    path.push(job.macroarea.name)
  }
  if (job.component) {
    path.push(job.component.name)
  }
  path.push(job.target.name)

  return path.map(capitalizeFirstLetter).join(' > ')
}

class JobEditModalComponent extends React.Component<Props, OwnStateProps> {
  public targetIdInputRef: React.RefObject<HTMLInputElement>
  public targetIdInvalidFeedbackRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.targetIdInputRef = React.createRef()
    this.targetIdInvalidFeedbackRef = React.createRef()
    this.state = {
      id: '',
      periodicity: '',
      hours: '',
      notes: '',
      isSpecialOperation: false,
      errors: {},
      isSubmitting: false,
      fetchErrors: false,
      isFetching: false,
      maintenanceTypes: [],
    }

    this.onModalClose = this.onModalClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.formatTarget = this.formatTarget.bind(this)
    this.getInvalidFeedbackMsg = this.getInvalidFeedbackMsg.bind(this)
    this.hasError = this.hasError.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  public static getDerivedStateFromProps(nextProps: Props, prevState: OwnStateProps) {
    if (nextProps.job && nextProps.job.id !== prevState.id) {
      const periodicity = nextProps.job && nextProps.job.periodicity ? nextProps.job.periodicity.toString() : ''
      const hours = nextProps.job && nextProps.job.hours ? nextProps.job.hours.toString() : ''
      const notes = nextProps.job && nextProps.job.notes ? nextProps.job.notes : ''
      const isSpecialOperation = nextProps.job && nextProps.job.isSpecialOperation || false
      const id = nextProps.job && nextProps.job.id || ''

      return {
        id, periodicity, hours, notes, isSpecialOperation
      }
    }
    return null
  }

  public componentDidMount() {
    this.fetchData()
  }

  public resetState() {
    this.setState({
      periodicity: '',
      hours: '',
      notes: '',
      errors: {},
    })
  }

  public onModalClose(reload: boolean = false) {
    this.resetState()
    if (this.props.onClose) {
      this.props.onClose(reload)
    }
  }

  public onSubmit(e: any) {
    e.preventDefault()

    if (this.props.job !== null) {
      const params: MaintenanceJobPatchParams = {
        id: this.props.job.id,
        periodicity: parseInt(this.state.periodicity, 10),
        hours: parseInt(this.state.hours, 10),
        notes: this.state.notes,
        isSpecialOperation: this.state.isSpecialOperation,
      }

      // reset errors
      this.setState({errors: {}})

      this.props
        .patchJob(params)
        .then(() => {
          this.onModalClose(true)
        })
        .catch(error => {
          if (error.errors) {
            this.setState({
              errors: error.errors,
            })
          }
        })
    }
  }

  public handleChange(e?: any, state?: { value: string | boolean; name: string }) {
    const change = {}
    if (e) {
      change[e.target.name] = e.target.name === 'isSpecialOperation' ? !this.state.isSpecialOperation : e.target.value
    } else if (state) {
      change[state.name] = state!.name === 'isSpecialOperation' ? !this.state.isSpecialOperation : state!.value
    }
    this.setState(change)
  }

  public formatTarget(target: any): string {
    if (target.data.path) {
      const path = Object.assign([], target.data.path)
      path.splice(0, 1)
      return path.map((item: any) => capitalizeFirstLetter(item)).join(' > ')
    }
    return ''
  }

  public getInvalidFeedbackMsg(field: string) {
    const {errors} = this.state
    if (errors && errors.hasOwnProperty(field)) {
      return errors[field].join('<br>')
    }
    return null
  }

  public hasError(field: string) {
    const {errors} = this.state
    return errors && errors.hasOwnProperty(field)
  }

  public render() {
    const periodicityList = Object.keys(MaintenanceJobPeriodicity).filter(
      (k: any) => typeof MaintenanceJobPeriodicity[k] === 'number',
    ) as string[]

    const {job} = this.props

    if (job === null) {
      return null
    }

    return (
      <FormModal
        visible={this.props.isVisible}
        closeLabel={this.props.t('maintenance.close')}
        onClose={() => this.onModalClose()}
        width={75}
        title={this.props.t('maintenance.editJob.title')}
        submitButton={{label:this.props.t('maintenance.editJob.save') }}
        onSubmit={this.onSubmit}
      >
        {this.state.isFetching && (
          <Loader/>
        )}
        {!this.state.isFetching && this.state.fetchErrors && (
          <Alert type="danger" title={this.props.t('maintenance.errors.somethingGoneWrong')}/>
        )}
        {!this.state.isFetching && !this.state.fetchErrors && (
          <>
            <Row>
              <Column sm={12}>
                <Row>
                  <Column sm={12}>
                    <Input
                      ref={this.targetIdInputRef}
                      type="text"
                      label={this.props.t('maintenance.editJob.target')}
                      disabled={true}
                      value={presentJobTarget(job)}
                      // tslint:disable-next-line: no-empty
                      onChange={() => {
                      }}
                    />
                  </Column>

                  <Column sm={12}>
                    <Row spacing={{horizontal:false,vertical:false}}>
                      <Column md={6} formGroup={true}>
                        <Input
                          name="operation"
                          type="text"
                          label={this.props.t('maintenance.editJob.operation')}
                          id="operation"
                          value={job.operation}
                          disabled={true}
                          readOnly={true}
                          // tslint:disable-next-line: no-empty
                          onChange={() => {
                          }}
                        />
                      </Column>

                      <Column md={6} formGroup={true}>
                        <Input
                          name="operation"
                          type="text"
                          label={this.props.t('maintenance.editJob.type')}
                          id="operation"
                          value={job.type.name}
                          disabled={true}
                          readOnly={true}
                          // tslint:disable-next-line: no-empty
                          onChange={() => {
                          }}
                        />
                      </Column>
                    </Row>
                  </Column>

                  <Column sm={12}>
                    <Row spacing={{horizontal:false,vertical:false}}>
                      <Column md={6} formGroup={true}>
                        <Select
                          name="periodicity"
                          id="periodicity"
                          label={this.props.t('maintenance.editJob.periodicity')}
                          value={this.state.periodicity}
                          onChange={(value) => this.handleChange(undefined, {
                            name: 'periodicity',
                            value: value as string,
                          })}
                          options={this.getSelectOptions(periodicityList, this.props.t('maintenance.editJob.selectPeriodicity'))}
                          error={this.getInvalidFeedbackMsg('periodicity')}
                        />
                      </Column>
                      <Column md={6} formGroup={true}>
                        <Input
                          name="hours"
                          label={this.props.t('maintenance.editJob.hours')}
                          type="number"
                          id="hours"
                          value={this.state.hours}
                          onChange={(value) => {
                            if (Number(value) >= 0) {
                              this.handleChange(undefined, {
                                name: 'hours',
                                value: value as string,
                              })
                            }

                          }}
                          error={this.getInvalidFeedbackMsg('hours')}
                        />
                      </Column>
                    </Row>
                  </Column>

                  <Column sm={12} formGroup={true}>
                    <TextArea
                      id="notes"
                      label={this.props.t('maintenance.editJob.notes')}
                      name="notes"
                      value={this.state.notes}
                      onChange={(value) => this.handleChange(undefined, {
                        name: 'notes',
                        value: value as string,
                      })}
                      rows={3}
                      error={this.getInvalidFeedbackMsg('notes')}
                    />
                  </Column>
                  <Column sm={12} formGroup={true}>
                    <Checkbox
                      label={this.props.t('maintenance.addJob.specialOperation')}
                      name="isSpecialOperation"
                      checked={this.state.isSpecialOperation}
                      onChange={element => this.handleChange(undefined, {
                        name: 'isSpecialOperation',
                        value: element.checked
                      })}
                      id="maintenanceIsSpecialOperation"
                      error={this.getInvalidFeedbackMsg('specialOperation')}
                    />
                  </Column>
                </Row>
              </Column>
            </Row>
          </>
        )}
      </FormModal>
    )
  }

  private fetchData() {
    if (!this.state.isFetching && !this.state.maintenanceTypes) {
      this.setState({isFetching: true, fetchErrors: false})
      Promise.all([
        this.props.maintenanceTypes ? this.props.maintenanceTypes : this.props.fetchMaintenanceTypes(),
      ])
        .then(([maintenanceTypes]) => {
          this.setState({maintenanceTypes})
        })
        .catch((error: any) => {
          this.setState({fetchErrors: false})
        })
        .finally(() => {
          this.setState({isFetching: false})
        })
    }
  }

  private getSelectOptions(
    sources: Array<{ name: string; id: string } | string>,
    defaultOption?: string
  ): SelectOptions {
    const options: SelectOptions = {
      defaultOption: defaultOption || undefined,
      items: [],
    }

    sources.map(source => {
      options.items.push({
        label: typeof source === 'string' ? this.props.t(`maintenance.periodicity.${source}`) : source.name,
        value: typeof source === 'string' ? MaintenanceJobPeriodicity[source] : source.id,
      })
    })

    return options
  }
}

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(withTranslation()(JobEditModalComponent)),
)
