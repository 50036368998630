import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
/*tslint:disable*/
const DateRangePickerImport = require('react-bootstrap-daterangepicker')
const DateRangePicker = DateRangePickerImport.default
/*tslint:enable*/
import * as moment from 'moment'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { saveDataAsCSV } from '../../../../functions'

const API = () => FetchWrapper.getInstance('production')

class TimeEvents extends React.Component<WithTranslation> {
  private handleDownloadReal(event: any, picker: any) {
    const startDate = moment(picker.startDate).format('YYYY-MM-DDTHH:mm:ss')
    const endDate = moment(picker.endDate).format('YYYY-MM-DDTHH:mm:ss')

    API().request('/production/time-events?' + 'dateStart=' + startDate + '&dateEnd=' + endDate)
      .then((data: any) => {
        saveDataAsCSV(data, 'time_events_data', 'csv') // @todo test IE
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        console.log('error', error) // tslint:disable-line
      })
  }

  public render() {
    return (
      <div className="time-events-download">
        <DateRangePicker
          timePicker={true}
          timePicker24Hour={true}
          locale={{
            format: 'DD/MM/YYYY hh:mm',
          }}
          maxDate={moment()}
          onApply={this.handleDownloadReal}
        >
          <button className="btn btn-sm btn-outline-secondary">Time &amp; Events</button>
        </DateRangePicker>
      </div>
    )
  }
}

export default withTranslation()(TimeEvents)
