import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, DateInput, Select, CheckInput, CheckList, TextArea } from '@mv-submodules/inplant-components-fe'
import AutomaticRenewalField from '../TaskFields/AutomaticRenewalField'
import FileUploaderField from '../TaskFields/FileUploaderField'
import GroupedFieldset from './GroupedFieldset'
import { FieldsetField, CheckListField, RelatedOption, FormFieldsMap, File } from '../../../../types/pageForm'

interface Props {
  fields: FieldsetField[]
  hiddenFields?: string[]
  requiredFields?: string[]
  refs?: FormFieldsMap<React.RefObject<any>>
  errors?: { [field: string]: string[] }
  className?: string
  childrenClass?: string
  getInputValue: (slug: string) => any
  onInputChange: (slug: string, value: any, template?: boolean) => void
  handleUploadFiles?: (file: File) => void
  // t: TranslationFunction
}

class GenericFieldset extends React.Component<Props & WithTranslation> {
  public render() {
    const props = this.props
    const fieldRefs = props.refs
    const handleInputChange = this.handleInputChange.bind(this)
    const fields =
      props.hiddenFields && props.hiddenFields.length
        ? props.fields.filter(
            (field: any) => !(field.hasOwnProperty('slug') && props.hiddenFields!.includes(field.slug))
          )
        : props.fields

    return (
      <div className={props.className || 'fieldset'}>
        {fields.map((field: any, index) => {
          let fieldValue = field.slug && props.getInputValue(field.slug)

          if (fieldValue && field.slug === 'rolesWithValidation') {
            fieldValue = fieldValue[0]
          } // VALUE WRAPPED INTO AN ARRAY

          const relValue = field.relSlug && props.getInputValue(field.relSlug)

          const isVisible = field.relType !== 'visible' || !!relValue

          const fieldError = (props.errors && props.errors[field.slug] && props.errors[field.slug][0]) || undefined

          // console.debug(field,relValue,isVisible,fieldError)
          switch (field.type) {
            case 'row':
              return (
                isVisible && (
                  <GenericFieldset {...props} key={index} fields={field.fields} className="row" childrenClass="col" />
                )
              )
            case 'group':
              return isVisible && <GroupedFieldset {...props} key={index} slug={field.slug} fields={field.fields} />
            case 'select':
              const options =
                field.relSlug && field.relType === 'options'
                  ? (field.options as RelatedOption[]).filter(option =>
                      relValue != null && option.rel instanceof Array
                        ? option.rel.includes(relValue)
                        : option.rel === relValue
                    )
                  : field.options

              if (field.relSlug && !options.find(({ value }: RelatedOption) => value === fieldValue)) {
                fieldValue = null
              }

              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <Select
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      options={options}
                      value={fieldValue}
                      required={field.required || (!!props.requiredFields && props.requiredFields.includes(field.slug))}
                      disabled={
                        !!(field.relSlug && (field.relType === 'options' || field.relType === 'disabled') && !relValue)
                      }
                      readOnly={field.readOnly}
                      onChange={handleInputChange(field.slug, field.template)}
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'textarea':
              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <TextArea
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      value={fieldValue}
                      required={field.required || (!!props.requiredFields && props.requiredFields.includes(field.slug))}
                      disabled={!!(field.relSlug && field.relType === 'disabled' && !relValue)}
                      readOnly={field.readOnly}
                      rows={field.rows}
                      onChange={handleInputChange(field.slug, field.template)}
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'number':
              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <Input
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      value={fieldValue}
                      required={field.required || (!!props.requiredFields && props.requiredFields.includes(field.slug))}
                      disabled={!!(field.relSlug && field.relType === 'disabled' && !relValue)}
                      readOnly={field.readOnly}
                      type={field.type}
                      min={field.min}
                      max={field.max}
                      onChange={handleInputChange(field.slug, field.template)}
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'text':
              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <Input
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      value={fieldValue}
                      required={field.required || (!!props.requiredFields && props.requiredFields.includes(field.slug))}
                      disabled={!!(field.relSlug && field.relType === 'disabled' && !relValue)}
                      readOnly={field.readOnly}
                      onChange={handleInputChange(field.slug, field.template)}
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'date':
              return (
                isVisible && (
                  <div key={field.slug} className={props.childrenClass}>
                    <DateInput
                      label={this.renderLabel(field.slug)}
                      id={field.slug}
                      ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                      name={field.slug}
                      value={fieldValue}
                      required={field.required || (!!props.requiredFields && props.requiredFields.includes(field.slug))}
                      disabled={!!(field.relSlug && field.relType === 'disabled' && !relValue)}
                      readOnly={field.readOnly}
                      onChange={handleInputChange(field.slug, field.template)}
                      min={field.min}
                      max={field.max}
                      isClearable={true}
                      error={fieldError}
                    />
                  </div>
                )
              )
            case 'checkbox':
              if (field.hasOwnProperty('options')) {
                const fieldOptions =
                  field.relSlug && field.relType === 'options'
                    ? (field.options as RelatedOption[]).filter(option => relValue != null && option.rel === relValue)
                    : field.options
                if (field.relSlug && !fieldOptions.find(({ value }: RelatedOption) => value === fieldValue)) {
                  fieldValue = null
                }
                return (
                  isVisible && (
                    <div key={field.slug} className={props.childrenClass}>
                      <CheckList
                        label={this.renderLabel(field.slug)}
                        id={field.slug}
                        name={field.slug}
                        value={fieldValue}
                        ref={fieldRefs ? fieldRefs[field.slug] : undefined}
                        required={
                          field.required || (!!props.requiredFields && props.requiredFields.includes(field.slug))
                        }
                        disabled={!!(field.relSlug && field.relType === 'disabled' && !relValue)}
                        readOnly={field.readOnly}
                        multiple={field.multiple}
                        inline={(field as CheckListField).inline}
                        inlineClassName="col col-md-1"
                        options={fieldOptions}
                        onChange={handleInputChange(field.slug, field.template)}
                        error={fieldError}
                      />
                    </div>
                  )
                )
              } else {
                return (
                  isVisible && (
                    <div key={field.slug} className={props.childrenClass}>
                      <CheckInput
                        label={this.renderLabel(field.slug)}
                        id={field.slug}
                        name={field.slug}
                        value={fieldValue}
                        disabled={!!(field.relSlug && field.relType === 'disabled' && !relValue)}
                        readOnly={field.readOnly}
                        onChange={handleInputChange(field.slug, field.template)}
                        error={fieldError}
                      />
                    </div>
                  )
                )
              }
            case 'automaticRenewal':
              return isVisible && <AutomaticRenewalField {...props} required={field.required} key="automaticRenewal" />
            case 'attachments':
              return (
                <FileUploaderField
                  handleUploadFiles={this.props.handleUploadFiles!}
                  key="fileUploaderKey"
                  fieldValue={fieldValue}
                  {...props}
                />
              ) // to add isVisible // check why in 'crea nuova scadenza' is visible dosn't let it show
            case 'empty':
              return <div key={index} className={field.size ? `col-${field.size}` : props.childrenClass} />
            default:
              return <div key={index} className={props.childrenClass} />
          }
        })}
      </div>
    )
  }

  private handleInputChange(slug: string, template?: boolean) {
    // return (value: any) => this.props.onInputChange(slug, value)
    return (value: any) =>
      slug === 'rolesWithValidation'
        ? this.props.onInputChange(slug, [value], template) // VALUE WRAPPED INTO AN ARRAY
        : this.props.onInputChange(slug, value, template)
  }

  private renderLabel(slug: string) {
    const t = this.props.t
    return t(`maintenance.dueDates.formField.${slug}`)
  }
}

export default withTranslation()(GenericFieldset)
