/* tslint:disable */ // TODO refactoring

/*****************************************************
 * Available memory data types
 */
export interface MemoryAddressType {
  prefix: string
  sufix: string
  bytes: number
  bits?: number
  offset?: number
  sufixLabel?: string
}

export const WShort: MemoryAddressType = {
  prefix: 'W',
  sufix: '@Short',
  bytes: 2,
}

export const WLong: MemoryAddressType = {
  prefix: 'W',
  sufix: '@Long',
  bytes: 4,
}

export const DFloat: MemoryAddressType = {
  prefix: 'D',
  sufix: '@Float',
  bytes: 4,
}

export const DLong: MemoryAddressType = {
  prefix: 'D',
  sufix: '@Long',
  bytes: 4,
}

export const XBoolean: MemoryAddressType = {
  prefix: 'X',
  sufix: '@Boolean',
  bytes: 2,
  bits: 1,
}

export const BString: MemoryAddressType = {
  prefix: 'B',
  sufix: '.12@String',
  bytes: 12,
  offset: 2,
}

export const MemoryAddressTypes = [WShort, WLong, DFloat, DLong, XBoolean, BString]

/*****************************************************
 * MetricType
 */

export interface MetricType {
  type: string
  unitOfMeasurement: string
}

export const Label: MetricType = {
  type: 'Label',
  unitOfMeasurement: '',
}

export const Type: MetricType = {
  type: 'Type',
  unitOfMeasurement: '',
}

export const ActivePower_KWh: MetricType = {
  type: 'ActivePower',
  unitOfMeasurement: 'KWh',
}

export const Power_KWh: MetricType = {
  type: 'Power',
  unitOfMeasurement: 'KWh',
}

export const Current_A: MetricType = {
  type: 'Current',
  unitOfMeasurement: 'A',
}

export const Ph: MetricType = {
  type: 'Ph',
  unitOfMeasurement: '',
}

export const Conductivity_miS: MetricType = {
  type: 'Conductivity',
  unitOfMeasurement: 'miS',
}
export const Coulomb: MetricType = {
  type: 'Coulomb',
  unitOfMeasurement: '',
}

export const RunningTime_Min: MetricType = {
  type: 'TotalMinutes',
  unitOfMeasurement: 'Min',
}

export const TotalMinutes: MetricType = {
  type: 'TotalMinutes',
  unitOfMeasurement: 'Min',
}

export const Reset_Bool: MetricType = {
  type: 'ResetHour',
  unitOfMeasurement: 'Bool',
}

export const GasConsumption_M3: MetricType = {
  type: 'GasConsumption',
  unitOfMeasurement: 'M3',
}

export const WaterConsumption_M3: MetricType = {
  type: 'WaterConsumption',
  unitOfMeasurement: 'M3',
}

export const DoughConsumption_Kg: MetricType = {
  type: 'DoughConsumption',
  unitOfMeasurement: 'Kg',
}

export const ResinConsumption_Kg: MetricType = {
  type: 'ResinConsumption',
  unitOfMeasurement: 'Kg',
}

export const CompressedAir_M3: MetricType = {
  type: 'CompressedAir',
  unitOfMeasurement: 'M3',
}

export const CarbonFootprint: MetricType = {
  type: 'CarbonFootprint',
  unitOfMeasurement: '',
}

export const ChimneyGasVolume_M3: MetricType = {
  type: 'ChimneyGasVolume',
  unitOfMeasurement: 'M3',
}

export const ChimneyGasTemperature_C: MetricType = {
  type: 'ChimneyGasTemperature',
  unitOfMeasurement: 'C',
}

export const Uptime_Min: MetricType = {
  type: 'TotalMinutes',
  unitOfMeasurement: 'Min',
}

export const WarmupTime_Min: MetricType = {
  type: 'WarmupTime',
  unitOfMeasurement: 'Min',
}

export const StartupTime_Min: MetricType = {
  type: 'StartupTime',
  unitOfMeasurement: 'Min',
}

export const ProductionTime_Min: MetricType = {
  type: 'ProductionTime',
  unitOfMeasurement: 'Min',
}

export const EndCycleTime_Min: MetricType = {
  type: 'EndCycleTime',
  unitOfMeasurement: 'Min',
}

export const PauseTime_Min: MetricType = {
  type: 'PauseTime',
  unitOfMeasurement: 'Min',
}

export const EmergencyTime_Min: MetricType = {
  type: 'EmergencyTime',
  unitOfMeasurement: 'Min',
}

export const NoProductionTime_Min: MetricType = {
  type: 'NoProductionTime',
  unitOfMeasurement: 'Min',
}

export const LoadedPiecesNumber: MetricType = {
  type: 'LoadedPiecesNumber',
  unitOfMeasurement: '',
}

export const UnloadedPiecesNumber: MetricType = {
  type: 'UnloadedPiecesNumber',
  unitOfMeasurement: '',
}

export const Painted_M2: MetricType = {
  type: 'Painted',
  unitOfMeasurement: 'M2',
}

export const DiscardedPiecesNumber: MetricType = {
  type: 'DiscardedPiecesNumber',
  unitOfMeasurement: '',
}

export const GasFlowRate_Nm3h: MetricType = {
  type: 'GasFlowRate',
  unitOfMeasurement: 'Nm3h',
}

export const Temperature_C: MetricType = {
  type: 'Temperature',
  unitOfMeasurement: 'C',
}

export const Pressure_Bar: MetricType = {
  type: 'Pressure',
  unitOfMeasurement: 'Bar',
}

export const Level_M: MetricType = {
  type: 'Level',
  unitOfMeasurement: 'M',
}

export const ElectricConsumption_KWh: MetricType = {
  type: 'ElectricConsumption',
  unitOfMeasurement: 'KWh',
}

export const ElectricCharge_C: MetricType = {
  type: 'ElectricCharge',
  unitOfMeasurement: 'C',
}

export const PlantStatusChange: MetricType = {
  type: 'PlantStatusChange',
  unitOfMeasurement: '',
}

export const FramePieces: MetricType = {
  type: 'FramePieces',
  unitOfMeasurement: '',
}

export const WasterCause: MetricType = {
  type: 'WasterCause',
  unitOfMeasurement: '',
}

export const AirFlowRate: MetricType = {
  type: 'AirFlowRate',
  unitOfMeasurement: '',
}

export const Binder: MetricType = {
  type: 'Binder',
  unitOfMeasurement: '',
}

export const ComprimedAir: MetricType = {
  type: 'ComprimedAir',
  unitOfMeasurement: '',
}

export const ExhaustChaminsTemp: MetricType = {
  type: 'ExhaustChaminsTemp',
  unitOfMeasurement: '',
}

export const ExhaustChaminsVol: MetricType = {
  type: 'ExhaustChaminsVol',
  unitOfMeasurement: '',
}

export const FlowRate: MetricType = {
  type: 'FlowRate',
  unitOfMeasurement: '',
}

export const Gas: MetricType = {
  type: 'Gas',
  unitOfMeasurement: '',
}

export const LoadCellFlowRate: MetricType = {
  type: 'LoadCellFlowRate',
  unitOfMeasurement: '',
}

export const OilConsumption: MetricType = {
  type: 'OilConsumption',
  unitOfMeasurement: '',
}

export const Paste: MetricType = {
  type: 'Paste',
  unitOfMeasurement: '',
}

export const ProductConsumption: MetricType = {
  type: 'ProductConsumption',
  unitOfMeasurement: '',
}

export const ResetHour: MetricType = {
  type: 'ResetHour',
  unitOfMeasurement: '',
}
export const Temperature: MetricType = {
  type: 'Temperature',
  unitOfMeasurement: '',
}

export const ThermalEnergy: MetricType = {
  type: 'ThermalEnergy',
  unitOfMeasurement: '',
}

export const WasteCause: MetricType = {
  type: 'WasteCause',
  unitOfMeasurement: '',
}

export const DemiWater: MetricType = {
  type: 'DemiWater',
  unitOfMeasurement: '',
}

export const RawWater: MetricType = {
  type: 'RawWater',
  unitOfMeasurement: '',
}

export const Equivalent: MetricType = {
  type: 'Equivalent',
  unitOfMeasurement: '',
}

export const Partial: MetricType = {
  type: 'Partial',
  unitOfMeasurement: '',
}

export const Total: MetricType = {
  type: 'Total',
  unitOfMeasurement: '',
}

export const ResetTotalCounter: MetricType = {
  type: 'ResetTotalCounter',
  unitOfMeasurement: '',
}

export const ResetPartialCounter: MetricType = {
  type: 'ResetPartialCounter',
  unitOfMeasurement: '',
}

export const Consumption: MetricType = {
  type: 'Consumption',
  unitOfMeasurement: '',
}

export const Production: MetricType = {
  type: 'Production',
  unitOfMeasurement: 'l/h',
}
