import * as React from 'react'
import * as Metrics from '../../../types/metrics'
import Attachments from './Attachments'
import FileBrowserModal from '../FileBrowser/FileBrowserModal'
import FlagIcon from './FlagIcon'
import Media from './Media'
import MediaPlayerModal, { VideoData } from '../MediaPlayer/MediaPlayerModal'
import MetricsListComponent from './MetricsListComponent'
import { Component, ComponentGroup, MacroArea, Metric, Plant, Section, SectionGroup } from '../../../templates'
import { connect } from 'react-redux'
import { fetchFileData } from '../../../store/actions'
import { findAttribute, generateNodeId } from '../nodesFunctions'
import { i18nLabels } from '../../../types/i18n'
import { MemoryAddressType, MemoryAddressTypes } from '../../../types/plc'
import { MultipleMetrics } from '../../../types/template'
import { NodeMedia, NodeMetricType } from '../../../types/designer'
import { NodeType, Template, TemplateField } from '../../../types'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import IconComponent from '../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface NodeFormStateProps {
  fileData: any
  fileError: boolean
  fileFetching: boolean
}

export interface NodeFormOwnProps extends RouteComponentProps<any> {
  action: string
  allowedChilds: any[]
  callback: (node: any) => void
  clickClose?: () => void
  clickDelete?: (path: string) => void
  data: any
  id: string
  isChild?: boolean
  model: NodeType
  parent: any
  path: string | null
  // t: TranslationFunction
}

export interface NodeFormOwnState {
  descriptor: any
  documents: any[]
  fieldsErrors: any
  fieldsValues: any
  fieldsAdditionalClasses: {[k:string]: string[]}
  languageSelected: null | string
  languageSelectorOpen: boolean
  media: NodeMedia[]
  metric: any
  metricData: any
  metricErrors: any
  metricShowForm: null | string
  node: any
  originalTemplate: Template | null
  reorderedData: any
  sections: string[]
  selectedTemplate: Template | null
  showFileBrowser: boolean
  showMediaPlayer: boolean
  templates: Template[]
  translations: { [lang: string]: string }
}

export interface DispatchProps {
  fetchFileData: (fileKey: string) => Promise<any>
}

const prepareAttachment = (file: any) => {
  return {
    name: file.Key.split('/').pop(),
    version: '', // @todo add version from S3
    lastEdit: file.LastModified,
    documentType: file.fileType,
    link: file.Key,
    languages: [], // @todo defaults?
  }
}

export type NodeFormProps = NodeFormStateProps & DispatchProps & NodeFormOwnProps & WithTranslation

export const mapStateToProps = (state: any) => ({
  fileData: state.designer.storage.fileData,
  fileFetching: state.designer.storage.fileFetching,
  fileError: state.designer.storage.fileError,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchFileData: (fileKey: string) => dispatch(fetchFileData(fileKey)),
})

class NodeFormComponent extends React.Component<NodeFormProps, NodeFormOwnState> {
  private nodeForm: React.RefObject<HTMLFormElement> = React.createRef()
  private nodeFormSubmit: React.RefObject<HTMLInputElement> = React.createRef()

  constructor(props: NodeFormProps) {
    super(props)

    this.state = {
      descriptor: null,
      documents: [],
      fieldsErrors: {},
      fieldsValues: [],
      fieldsAdditionalClasses: {},
      languageSelected: null,
      languageSelectorOpen: false,
      media: [],
      metric: null,
      metricData: [],
      metricErrors: {},
      metricShowForm: null,
      node: null,
      originalTemplate: null,
      reorderedData: null,
      sections: ['details'],
      selectedTemplate: null,
      showFileBrowser: false,
      showMediaPlayer: false,
      templates: [Component, ComponentGroup, MacroArea, Plant, Section, SectionGroup],
      translations: {},
    }

    this.addFile = this.addFile.bind(this)
    this.calculateDerivedValues = this.calculateDerivedValues.bind(this)
    this.changeAccordion = this.changeAccordion.bind(this)
    this.changeTemplate = this.changeTemplate.bind(this)
    this.closeFileBrowser = this.closeFileBrowser.bind(this)
    this.closeMediaPlayer = this.closeMediaPlayer.bind(this)
    this.editMetric = this.editMetric.bind(this)
    this.fileLanguage = this.fileLanguage.bind(this)
    this.getRequiredAttribute = this.getRequiredAttribute.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleMetricAdd = this.handleMetricAdd.bind(this)
    this.handleMetricCancel = this.handleMetricCancel.bind(this)
    this.handleMetricReorder = this.handleMetricReorder.bind(this)
    this.handleMetricSave = this.handleMetricSave.bind(this)
    this.handleResetMetricReorder = this.handleResetMetricReorder.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.removeFile = this.removeFile.bind(this)
    this.removeMedia = this.removeMedia.bind(this)
    this.removeMetric = this.removeMetric.bind(this)
    this.selectLanguage = this.selectLanguage.bind(this)
    this.setReorderedData = this.setReorderedData.bind(this)
    this.showFileBrowser = this.showFileBrowser.bind(this)
    this.showMediaPlayer = this.showMediaPlayer.bind(this)
    this.showRequiredFields = this.showRequiredFields.bind(this)
    this.toggleLanguageSelector = this.toggleLanguageSelector.bind(this)
    this.triggerSubmit = this.triggerSubmit.bind(this)
  }

  public static getDerivedStateFromProps(newProps: NodeFormProps, state: NodeFormOwnState) {
    if (newProps.action === 'edit' && !newProps.isChild && newProps.data && newProps.data.subcomponents) {
      const nodeDetails = newProps.data.subcomponents.find((e: any) => {
        return e.path && e.path === newProps.path + '.' + newProps.id
      })

      if (nodeDetails && nodeDetails.path && (!state.node || nodeDetails.path !== state.node.path)) {
        const nodeCopy = Object.assign({}, nodeDetails)

        return {
          fieldsValues: nodeCopy.data,
          selectedTemplate: nodeCopy.descriptor,
          node: nodeCopy,
          documents: nodeCopy.data.documents,
          media: nodeCopy.data.media,
          translations: nodeCopy.data.translations,
        }
      }

      return null
    }

    return null
  }

  public render() {
    const isTemplateSelected =
      this.state.selectedTemplate && this.state.selectedTemplate.fields && this.state.selectedTemplate.fields.length > 0

    const multipleMetrics: any[] = []

    if (
      this.state.fieldsValues.nodeType &&
      (this.state.fieldsValues.nodeType !== 'i4P_NoNodeType' && this.state.fieldsValues.nodeType !== null) &&
      Metrics.default[this.state.fieldsValues.nodeType]
    ) {
      Metrics.default[this.state.fieldsValues.nodeType].metrics.forEach((m: any) => {
        if (m.hasOwnProperty('multiple')) {
          multipleMetrics.push(m)
        }
      })
    }

    multipleMetrics.forEach((mm: any) => {
      {
        mm.html = []
        mm.number = null
        for (let i = 1; i <= mm.multiple; i++) {
          mm.html.push(
            <option value={i} key={i}>
              {i}
            </option>
          )
        }

        const metricRef = mm.metricType.metrics.find((e: any) => e.metric.type !== 'Label')

        if (metricRef && this.state.node && this.state.node.subcomponents && this.state.node.subcomponents.length > 0) {
          const re = new RegExp('_' + metricRef.suffix.replace('_', '') + '_([1-9]d*)_([1-9]d*)$')
          const metric = this.state.node.subcomponents.find((s: NodeMetricType) => s.label.search(re) > -1)
          if (metric) {
            mm.number = parseInt(metric.label.match(re)[2], 10)
          }
        }
      }
    })

    return (
      <React.Fragment>
        {this.state.showFileBrowser && (
          <FileBrowserModal
            isVisible={this.state.showFileBrowser}
            closeModal={this.closeFileBrowser}
            addFile={this.addFile}
          />
        )}

        {this.state.showMediaPlayer && (
          <MediaPlayerModal
            isVisible={this.state.showMediaPlayer}
            closeModal={this.closeMediaPlayer}
            addMedia={this.addMedia}
          />
        )}

        <div
          className={
            this.props.action +
            '-node' +
            (this.state.metricShowForm && this.state.sections.indexOf('metrics') > -1 ? ' metric-form-visible' : '')
          }
        >
          <h3>
            {this.props.t('designer.node.' + this.props.action + (this.props.isChild ? 'Child' : '') + '.title') +
              (this.state.fieldsValues.label ? ': ' + this.state.fieldsValues.label : '')}
            <div className={'language-select ' + (this.state.languageSelected ? 'language-selected' : '')}>
              <span className="selected" onClick={this.toggleLanguageSelector}>
                {this.state.languageSelected && <FlagIcon code={i18nLabels[this.state.languageSelected]} />}
                {!this.state.languageSelected && (
                  <span className="do-select">
                    <IconComponent icon={'globe'} />
                  </span>
                )}{' '}
                <IconComponent icon={'caret-left'} />
              </span>
              <ul>
                <li
                  className={this.state.languageSelected === null ? 'enabled' : ''}
                  onClick={(e: React.MouseEvent<HTMLElement>) => this.selectLanguage(e, null)}
                >
                  <IconComponent icon={'globe'} />
                </li>
                <li
                  className={this.state.languageSelected === 'en' ? 'enabled' : ''}
                  onClick={(e: React.MouseEvent<HTMLElement>) => this.selectLanguage(e, 'en')}
                >
                  <FlagIcon code={i18nLabels.en} />
                </li>
                <li
                  className={this.state.languageSelected === 'it' ? 'enabled' : ''}
                  onClick={(e: React.MouseEvent<HTMLElement>) => this.selectLanguage(e, 'it')}
                >
                  <FlagIcon code={i18nLabels.it} />
                </li>
              </ul>
            </div>
          </h3>
          <div className="mt-2 form-group">
            {this.props.t('designer.node.template.select')}:
            <select
              className="form-control"
              onChange={this.changeTemplate}
              value={this.state.selectedTemplate ? this.state.selectedTemplate.id : ''}
            >
              <option value="" key="none">
                {this.props.t('designer.node.template.default')}
              </option>
              {this.props.allowedChilds &&
                this.props.allowedChilds.map(
                  (template: Template) =>
                    template.id !== 'metric' && (
                      <option value={template.id} key={template.id}>
                        {template.name}
                      </option>
                    )
                )}
            </select>
          </div>
          {isTemplateSelected && (
            <React.Fragment>
              <h3
                className={'section-header' + (this.state.sections.indexOf('details') > -1 ? ' open' : '')}
                onClick={() => this.changeAccordion('details')}
              >
                {this.state.sections.indexOf('details') > -1 && <IconComponent icon={'caret-down'} />}
                {!(this.state.sections.indexOf('details') > -1) && <IconComponent icon={'caret-right'} />}
                {this.props.t('designer.node.details')}
              </h3>
              <div className={'section-body' + (this.state.sections.indexOf('details') > -1 ? ' open' : '')}>
                <div className="section-wrapper">
                  <div>
                    <form onSubmit={this.handleSubmit} ref={this.nodeForm} className="row">
                      <input type="submit" ref={this.nodeFormSubmit} style={{ display: 'none' }} />
                      {this.state.selectedTemplate!.fields.map((field: TemplateField, id: number) => (
                        <React.Fragment key={id}>
                          {field.type === 'string' && (
                            <div
                              className={
                                'form-group col-' + field.width + ' field-' + field.name +
                                (
                                  this.state.fieldsAdditionalClasses.hasOwnProperty(field.name) ?
                                    ' ' + this.state.fieldsAdditionalClasses[field.name].join(' ') : ''
                                )
                              }
                              key={id + (this.state.languageSelected || '')}
                            >
                              <label className="mr-2" htmlFor={field.name}>
                                {this.props.t('designer.node.attributes.' + field.label)}
                              </label>
                              {field.translatable &&
                                this.state.translations &&
                                Object.keys(this.state.translations).length !== 0 && (
                                  <div className="input-translations">
                                    <span onClick={(e: React.MouseEvent<HTMLElement>) => this.selectLanguage(e, null)}>
                                      {this.props.t('designer.i18n.select')}
                                    </span>
                                    {Object.keys(this.state.translations).map((lang: string, index: number) => (
                                      <span
                                        key={index}
                                        onClick={(e: React.MouseEvent<HTMLElement>) => this.selectLanguage(e, lang)}
                                      >
                                        {lang}
                                      </span>
                                    ))}
                                  </div>
                                )}
                              <div className="input-group">
                                <input
                                  id={field.name}
                                  className={
                                    'form-control' +
                                    (this.state.fieldsErrors && this.state.fieldsErrors[field.name]
                                      ? ' is-invalid'
                                      : '')
                                  }
                                  type="text"
                                  name={field.name}
                                  required={field.required || this.getRequiredAttribute(field)}
                                  value={
                                    this.state.languageSelected && field.name === 'label'
                                      ? this.state.translations[this.state.languageSelected] || ''
                                      : this.state.fieldsValues[field.name] || ''
                                  }
                                  onChange={event => this.handleInputChange(event, field)}
                                  onBlur={this.handleInputBlur}
                                />
                                {this.state.fieldsErrors && this.state.fieldsErrors[field.name] && (
                                  <div className="invalid-feedback">{this.state.fieldsErrors[field.name]}</div>
                                )}
                                {this.state.languageSelected && field.translatable && (
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <FlagIcon code={i18nLabels[this.state.languageSelected]} />
                                    </span>
                                  </div>
                                )}
                                {!this.state.languageSelected &&
                                  field.translatable &&
                                  this.state.translations &&
                                  Object.keys(this.state.translations).length !== 0 && (
                                    <div className="input-group-append">
                                      <span
                                        className="input-group-text tooltips"
                                        data-title={this.props.t('designer.node.attributes.hasTranslations')}
                                      >
                                        <IconComponent icon={'globe'} />
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                          {field.type === 'boolean' && (
                            <div
                              className={'form-check form-group col-' + field.width + ' field-' + field.name +
                              (
                                this.state.fieldsAdditionalClasses.hasOwnProperty(field.name) ?
                                  ' ' + this.state.fieldsAdditionalClasses[field.name].join(' ') : ''
                              )}
                              key={id + (this.state.languageSelected || '')}
                              title={field.derivedValue ? this.props.t('designer.node.attributes.checkboxDerivedState', {field: field.derivedValue.target, condition: field.derivedValue.condition}): undefined}
                            >
                              <input
                                id={field.name}
                                className={"form-check-input " + (field.derivedValue ? 'input-derived-value' : '')}
                                type="checkbox"
                                name={field.name}
                                required={field.required || this.getRequiredAttribute(field)}
                                readOnly={field.readOnly || false}
                                checked={ this.state.fieldsValues[field.name] || false}
                                onChange={field.readOnly ? undefined : event => this.handleInputChange(event, field)}
                              />
                              <label
                                className="mr-2 form-check-label" htmlFor={field.name}
                              >
                                {this.props.t('designer.node.attributes.' + field.label)}
                              </label>
                            </div>
                          )}
                          {field.type === 'enum' && field.values && (
                            <div
                              className={'form-group col-' + field.width + ' field-' + field.name +
                              (
                                this.state.fieldsAdditionalClasses.hasOwnProperty(field.name) ?
                                  ' ' + this.state.fieldsAdditionalClasses[field.name].join(' ') : ''
                              )}
                              key={id + (this.state.languageSelected || '')}
                            >
                              <label className="mr-2" htmlFor={field.name}>
                                {this.props.t('designer.node.attributes.' + field.label)}
                              </label>
                              <select
                                id={field.name}
                                className="form-control"
                                name={field.name}
                                required={field.required}
                                onChange={event => this.handleInputChange(event, field)}
                                value={this.state.fieldsValues[field.name] || ''}
                              >
                                {field.name === 'nodeType' && <option />}
                                {Array.isArray(field.values) && field.values.map((en: string) => (
                                  <option value={en} key={en}>
                                    {en}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          {field.type === 'space' && (
                            <div className={'col-' + field.width} />
                          )}
                        </React.Fragment>
                      ))}

                      {multipleMetrics && multipleMetrics.length > 0 && (
                        <div className="metric-multiple input-group">
                          {multipleMetrics
                            .filter(mm => !mm.hasOwnProperty('fixed'))
                            .map((mm: MultipleMetrics & { html: any; number: null | number }, metridId: number) => (
                              <div className="form-group col-md-12" key={metridId}>
                                <label className="mr-2" htmlFor={mm.metricType.name}>
                                  {mm.metricType.name} - {this.props.t('designer.number')}
                                </label>
                                <select
                                  id={mm.metricType.name}
                                  key={mm.metricType.name}
                                  className="form-control"
                                  name={mm.metricType.name}
                                  required={true}
                                  onChange={this.handleInputChange}
                                  value={
                                    this.state.fieldsValues[mm.metricType.name]
                                      ? this.state.fieldsValues[mm.metricType.name]
                                      : mm.number || 1
                                  }
                                >
                                  {mm.html}
                                </select>
                              </div>
                            ))}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          {isTemplateSelected && this.state.selectedTemplate && this.state.selectedTemplate.name !== 'Metric' && (
            <React.Fragment>
              <h3
                className={'section-header' + (this.state.sections.indexOf('attachments') > -1 ? ' open' : '')}
                onClick={() => this.changeAccordion('attachments')}
              >
                {this.state.sections.indexOf('attachments') > -1 && <IconComponent icon={'caret-down'} />}
                {!(this.state.sections.indexOf('attachments') > -1) && <IconComponent icon={'caret-right'} />}
                {this.props.t('designer.node.attachments')}
              </h3>
              <div className={'section-body' + (this.state.sections.indexOf('attachments') > -1 ? ' open' : '')}>
                <div className="section-wrapper">
                  <Attachments
                    documents={this.state.documents}
                    enableAdd={isTemplateSelected}
                    update={(documents: any[]) => this.setState({ documents })}
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          {isTemplateSelected && this.state.selectedTemplate && this.state.selectedTemplate.name !== 'Metric' && (
            <React.Fragment>
              <h3
                className={'section-header' + (this.state.sections.indexOf('media') > -1 ? ' open' : '')}
                onClick={() => this.changeAccordion('media')}
              >
                {this.state.sections.indexOf('media') > -1 && <IconComponent icon={'caret-down'} />}
                {!(this.state.sections.indexOf('media') > -1) && <IconComponent icon={'caret-right'} />}
                {this.props.t('designer.node.media')}
              </h3>
              <div className={'section-body' + (this.state.sections.indexOf('media') > -1 ? ' open' : '')}>
                <div className="section-wrapper">
                  <Media
                    media={this.state.media}
                    enableAdd={isTemplateSelected}
                    update={(media: any[]) => this.setState({ media })}
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          {isTemplateSelected && this.state.selectedTemplate && this.state.selectedTemplate.name !== 'Metric' && (
            <div className={'form-metrics mt-3'}>
              <h3
                className={'section-header' + (this.state.sections.indexOf('metrics') > -1 ? ' open' : '')}
                onClick={() => this.changeAccordion('metrics')}
              >
                {this.state.sections.indexOf('metrics') > -1 && <IconComponent icon={'caret-down'} />}
                {!(this.state.sections.indexOf('metrics') > -1) && <IconComponent icon={'caret-right'} />}
                {this.props.t('designer.node.metrics')}
              </h3>
              <div className={'section-body' + (this.state.sections.indexOf('metrics') > -1 ? ' open' : '')}>
                <div className="section-wrapper">

                  {this.state.node &&
                    this.state.node.subcomponents &&
                    this.state.node.subcomponents.filter((e: NodeType) => e.level === 'Metric').length > 0 && (
                      <MetricsListComponent
                        data={
                          this.state.reorderedData ||
                          this.state.node.subcomponents.filter((e: NodeType) => e.level === 'Metric')
                        }
                        edit={this.editMetric}
                        remove={this.removeMetric}
                        current={this.state.metric}
                        editable={
                          /*this.state.selectedTemplate &&
                          (this.state.selectedTemplate.name !== 'Component' ||
                            (this.state.fieldsValues &&
                              (this.state.fieldsValues.nodeType === '' ||
                                this.state.fieldsValues.nodeType === 'i4P_NoNodeType')))*/
                          false
                        }
                        reorderedData={
                          this.state.fieldsValues.nodeType === 'i4P_NoNodeType' ||
                          this.state.fieldsValues.nodeType === null
                            ? this.setReorderedData
                            : undefined
                        }
                      />
                    )}
                </div>
              </div>
            </div>
          )}

          <div className="form-actions mt-3">
            {typeof this.props.clickDelete === 'function' && (
              <button
                className={'btn btn-danger btn-sm' + (!isTemplateSelected ? ' hidden' : '')}
                onClick={this.handleDelete}
              >
                {this.props.t('designer.node.edit.delete')}
              </button>
            )}
            <button
              onClick={this.triggerSubmit}
              className={'btn btn-primary btn-sm float-right ml-3' + (!isTemplateSelected ? ' hidden' : '')}
            >
              {this.props.t('designer.node.' + this.props.action + (this.props.isChild ? 'Child' : '') + '.submit')}
            </button>
            <button className="btn btn-warning btn-sm float-right" onClick={this.handleClose}>
              {this.props.t('designer.node.close')}
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  private handleInputChange(event: React.ChangeEvent<any>, field?: TemplateField) {
    const target = event.currentTarget
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'label' && this.state.languageSelected) {
      const translations = this.state.translations || {}

      if (value && value !== '') {
        translations[this.state.languageSelected] = value
      } else {
        delete translations[this.state.languageSelected]
      }
      this.setState({
        translations,
      })
    } else {
      const fieldsValues = {...this.state.fieldsValues}
      fieldsValues[name] = value
      const derivedValues = this.calculateDerivedValues(fieldsValues)

      this.setState({
        fieldsValues: derivedValues
      })

      if(this.state.selectedTemplate) {
        const fieldAttributes = this.state.selectedTemplate.fields.find(f => f.name === name)
        if(fieldAttributes) {
          this.showRequiredFields(fieldAttributes, derivedValues, value)
        }
      }
    }
  }

  private calculateDerivedValues(desiredValues: {[k:string]: string | boolean | number}) {
    const calculatedValues = {...desiredValues}

    Object.keys(desiredValues).forEach(k => {
      if(this.state.selectedTemplate) {
        const fieldAttributes = this.state.selectedTemplate.fields.find(f => f.name === k)
        if(
          fieldAttributes && fieldAttributes.derivedValue &&
          typeof fieldAttributes.derivedValue.condition === 'function'
        ) {
          const derivedValue = fieldAttributes.derivedValue.condition(
            desiredValues[fieldAttributes.derivedValue.target],
            calculatedValues[k]
          )

          if(derivedValue) {
            calculatedValues[k] = derivedValue
          } else{
            calculatedValues[k] = false
          }
        }
      }
    })

    return calculatedValues
  }

  private getRequiredAttribute(field: TemplateField): boolean {
    let output = false

    if(field.relationships && field.relationships.length > 0) {
      field.relationships.forEach(r => {
        if(
          r.condition === 'required' &&
          (
            this.state.fieldsValues.hasOwnProperty(r.target) &&
            this.state.fieldsValues[r.target] === r.targetValue
          )
        ) {
          output = true
        }
      })
    }

    return output
  }

  private handleInputBlur(event: React.ChangeEvent<any>) {
    const target = event.currentTarget
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'fpid') {
      if (value !== '') {
        const father = findAttribute(value, 'pid', this.props.model)

        if (!father) {
          const fieldsErrors = this.state.fieldsErrors
          fieldsErrors.fpid = this.props.t('designer.errors.invalidFPD')

          this.setState({
            fieldsErrors,
          })
        } else {
          const fieldsErrors = this.state.fieldsErrors
          delete fieldsErrors.fpid

          this.setState({
            fieldsErrors,
          })
        }
      } else {
        const fieldsErrors = this.state.fieldsErrors
        delete fieldsErrors.fpid

        this.setState({
          fieldsErrors,
        })
      }
    }

    if (name === 'pid') {
      const fieldsErrors = {...this.state.fieldsErrors}
      delete fieldsErrors.pid

      this.setState({
        fieldsErrors,
      })
    }

    if (name === 'id') {
      if (value !== '') {
        const id = findAttribute(value, 'id', this.props.model)

        if (id) {
          const fieldsErrors = this.state.fieldsErrors
          fieldsErrors.id = this.props.t('designer.errors.IDexists')

          this.setState({
            fieldsErrors,
          })
        } else {
          const fieldsErrors = this.state.fieldsErrors
          delete fieldsErrors.id

          this.setState({
            fieldsErrors,
          })
        }
      } else {
        const fieldsErrors = this.state.fieldsErrors
        delete fieldsErrors.id

        this.setState({
          fieldsErrors,
        })
      }
    }

    return true
  }

  private handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    const errors = Object.keys(this.state.fieldsErrors)

    if (errors.length > 0) {
      alert(this.props.t('designer.errors.formHasErrors'))
    } else {
      const nodeData = {
        data: {
          ...this.state.fieldsValues,
          documents: this.state.documents,
          translations: this.state.translations,
          media: this.state.media,
        },
        enabled: 'Enabled',
        level: this.state.selectedTemplate ? this.state.selectedTemplate.name : 'Component',
        subcomponents: this.props.action === 'add' ? [] : this.state.node.subcomponents,
        descriptor: this.state.selectedTemplate,
      }

      this.props.callback(nodeData)
    }
  }

  private handleDelete() {
    if (typeof this.props.clickDelete === 'function') {
      this.props.clickDelete(this.props.path + '.' + this.props.id)
    }
  }

  private handleClose() {
    if (typeof this.props.clickClose === 'function') {
      this.props.clickClose()
    }
  }

  private handleMetricAdd() {
    this.setState({
      metricShowForm: 'add',
      metric: null,
    })
  }

  private handleMetricSave(metric: any) {
    const oldMetric = JSON.parse(JSON.stringify(this.state.metric))
    const node = JSON.parse(JSON.stringify(this.state.node))

    if (node) {
      if (!metric.level) {
        metric.level = 'Metric'
      }

      if (!metric.id) {
        metric.id = generateNodeId()
      }

      if (metric.dataType) {
        const memory = MemoryAddressTypes.find((e: MemoryAddressType) => e.prefix + e.sufix === metric.dataType)
        if (memory) {
          metric.memory = memory
        }
      }

      if (Metric.fields.filter((e: TemplateField) => e.name === 'savingMode').length > 0 && !metric.savingMode) {
        metric.savingMode = 'Other'
      }

      if (!metric.translations) {
        metric.translations = {}
      }

      if (metric.polling === 'true' || metric.polling === 'false') {
        metric.polling = metric.polling === 'true'
      }

      if (oldMetric === null) {
        if (node.subcomponents) {
          node.subcomponents.push(metric)
        } else {
          node.subcomponents = [metric]
        }
      } else {
        if (node.subcomponents) {
          node.subcomponents.forEach((subcomponent: any, i: number) => {
            if (subcomponent.level === 'Metric') {
              if (oldMetric.data && subcomponent.data) {
                if (oldMetric.data.id === subcomponent.data.id) {
                  node.subcomponents[i] = metric
                }
              } else if (oldMetric.id === subcomponent.id) {
                node.subcomponents[i] = metric
              }
            }
          })
        }
      }

      this.setState({
        metricShowForm: null,
        node,
      })
    }
  }

  private handleMetricCancel() {
    this.setState({
      metricShowForm: null,
    })
  }

  private changeTemplate(e: React.ChangeEvent<HTMLSelectElement>) {
    const selectedTemplate: Template | undefined = this.props.allowedChilds.find(
      (t: Template) => t.id === e.target.value
    )

    if (selectedTemplate) {
      const fieldsValues = {...this.state.fieldsValues}

      selectedTemplate.fields.forEach(f => {
        if(f.type === 'boolean' && f.hasOwnProperty('values') && typeof f.values !== 'undefined') {
          fieldsValues[f.name] = f.values
        }
      })

      this.setState({
        selectedTemplate,
        fieldsValues
      })
    }
  }

  private showFileBrowser(event: React.MouseEvent) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showFileBrowser: true,
    })
  }

  private closeFileBrowser() {
    this.setState({
      showFileBrowser: false,
    })
  }

  private showMediaPlayer(event: React.MouseEvent) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showMediaPlayer: true,
    })
  }

  private showRequiredFields(
    field: TemplateField,
    fieldValues: {[k:string]: string | boolean | number},
    desiredValue: string | boolean | number
   ) {
    const classes = {} // Object.assign({}, this.state.fieldsAdditionalClasses)
    const fields = Object.assign({}, this.state.selectedTemplate?.fields)
    const conditions = Object.values(fields).filter(f => f.relationships && f.relationships.find(r => r.target === field.name))
    const fieldsErrors = Object.assign({}, this.state.fieldsErrors)

    conditions.forEach(c => {
      if(c.relationships) {
        c.relationships.forEach(r => {
          if (
            r.condition === 'required' &&
            (!fieldValues[c.name] || fieldValues[c.name] === '') &&
            fieldValues[field.name] !== desiredValue
          ) {
            if (!classes.hasOwnProperty(c.name)) {
              classes[c.name] = []
            }
            classes[c.name].push('relation-required')

            fieldsErrors[c.name] =  this.props.t('designer.errors.relatedRequired', {field: c.name})
          }
        })
      }
    })

    this.setState({
      fieldsAdditionalClasses: classes,
      fieldsErrors
    }, () => {
      setTimeout(() => {
        this.setState({
          fieldsAdditionalClasses: {}
        })
      }, 2000 )
    })
  }

  private closeMediaPlayer() {
    this.setState({
      showMediaPlayer: false,
    })
  }

  private addFile(fileData: any, fileType: string) {
    const documents = JSON.parse(JSON.stringify(this.state.documents))

    if (!this.state.documents.find((e: any) => e.link === fileData.Key)) {
      fileData.fileType = fileType
      documents.push(prepareAttachment(fileData))
    }

    this.setState({
      showFileBrowser: false,
      documents,
    })
  }

  private addMedia(mediaUrl: string, mediaTitle: string, mediaPreview: VideoData) {
    const media = [...this.state.media]

    media.push({
      mediaType: 'YouTubeLink',
      link: mediaUrl,
      label: mediaTitle,
      translations: {},
    })

    this.setState({
      showMediaPlayer: false,
      media,
    })
  }

  private removeFile(e: React.MouseEvent<HTMLSpanElement>, fileData: any) {
    if (confirm(this.props.t('designer.confirm.removeAttachment'))) {
      const documents = JSON.parse(JSON.stringify(this.state.documents))
      const index = documents.findIndex((file: any) => {
        return file.link === fileData.link
      })

      if (index !== -1) {
        documents.splice(index, 1)
      }

      this.setState({
        documents,
      })
    }
  }

  private removeMedia(e: React.MouseEvent<HTMLSpanElement>, mediaData: any) {
    if (confirm(this.props.t('designer.confirm.removeAttachment'))) {
      const medias = JSON.parse(JSON.stringify(this.state.media))
      const index = medias.findIndex((media: any) => {
        return media.link === mediaData.link
      })

      if (index !== -1) {
        medias.splice(index, 1)
      }

      this.setState({
        media: medias,
      })
    }
  }

  // private downloadFile( e: React.MouseEvent<HTMLSpanElement>, fileData: any ) {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //
  //   this.props.fetchFileData(fileData.link)
  //   .then(( uniqueUrl: string ) => {
  //     const tempLink = document.createElement("a");
  //     tempLink.style.display = "none";
  //     tempLink.href = uniqueUrl;
  //     tempLink.setAttribute("download", fileData.link.split("/").pop());
  //     tempLink.setAttribute("target", "_blank");
  //
  //     document.body.appendChild(tempLink);
  //     tempLink.click();
  //     document.body.removeChild(tempLink);
  //   })
  //   .catch(( error: any ) => {
  //     notification(this.props.t("designer.error.download"));
  //   });
  // }

  private removeMetric(e: React.MouseEvent<HTMLSpanElement>, metric: any) {
    if (confirm(this.props.t('designer.confirm.removeMetric'))) {
      const subcomponents = JSON.parse(JSON.stringify(this.state.node.subcomponents))
      const index = subcomponents.findIndex((m: any) => {
        return m.level === 'Metric' && m.id === metric.id
      })

      if (index !== -1) {
        subcomponents.splice(index, 1)
      }

      const node = this.state.node
      node.subcomponents = subcomponents

      this.setState({
        node,
      })
    }
  }

  private editMetric(e: React.MouseEvent<HTMLSpanElement>, metric: any) {
    this.setState({
      metricShowForm: 'edit',
      metricData: metric.data ? metric.data : metric,
      metricErrors: {},
      metric,
    })
  }

  private toggleLanguageSelector() {
    this.setState({
      languageSelectorOpen: !this.state.languageSelectorOpen,
    })
  }

  private selectLanguage(e: React.MouseEvent<HTMLElement>, lang: string | null) {
    this.setState({
      languageSelectorOpen: false,
      languageSelected: this.state.languageSelected === lang || lang === null ? null : lang,
    })
  }

  private fileLanguage(e: React.MouseEvent<HTMLSpanElement>, link: string, lang: string, allDocuments: any[]) {
    const documents = JSON.parse(JSON.stringify(allDocuments))
    const fileIndex = documents.findIndex((f: any) => f.link === link)

    if (fileIndex > -1 && documents[fileIndex]) {
      if (documents[fileIndex].languages) {
        const language = documents[fileIndex].languages.find((l: string) => l === lang)
        if (language) {
          documents[fileIndex].languages.splice(documents[fileIndex].languages.indexOf(language), 1)
        } else {
          documents[fileIndex].languages.push(lang)
        }
      } else {
        documents[fileIndex].languages = [lang]
      }
    }

    this.setState({
      documents,
    })
  }

  private changeAccordion(label: string) {
    if (!label || label === '') {
      return
    }

    let sections = this.state.sections

    if (this.state.sections.indexOf(label) > -1) {
      sections.splice(this.state.sections.indexOf(label), 1)
    } else {
      sections = [label]
    }

    this.setState({
      sections,
    })
  }

  private triggerSubmit() {
    if (this.nodeFormSubmit.current) {
      this.nodeFormSubmit.current.click()
    }
  }

  private handleMetricReorder() {
    if (confirm(this.props.t('designer.confirm.reorderMetrics'))) {
      const data = JSON.parse(JSON.stringify(this.state.node))

      data.subcomponents = data.subcomponents
        .filter((e: NodeType) => e.level !== 'Metric')
        .concat(this.state.reorderedData)

      this.setState({
        node: data,
        reorderedData: null,
      })
    }
  }

  private handleResetMetricReorder() {
    this.setState({
      reorderedData: null,
    })
  }

  private setReorderedData(reorderedData: any) {
    this.setState({
      reorderedData,
    })
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(NodeFormComponent))
)
