import { AnyAction } from 'redux'

const indexInitialState = {
  data: [],
  error: null,
  fetching: false,
  lastUpdate: null,
  fileData: null,
  fileError: false,
  fileFetching: false,
}

export const storage = (state = indexInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return Object.assign({}, { ...state })
    case 'DESIGNER_STORAGE_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          error: false,
          fetching: action.fetching,
        }
      )
    case 'DESIGNER_STORAGE_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          data: action.data,
          error: false,
          fetching: false,
          lastUpdate: Math.floor(new Date().getTime() / 1000),
        }
      )
    case 'DESIGNER_STORAGE_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          data: [],
          error: action.payload,
          fetching: false,
        }
      )

    case 'DESIGNER_FILE_IS_FETCHING':
      return Object.assign(
        {},
        {
          ...state,
          fileError: false,
          fileFetching: action.fetching,
        }
      )
    case 'DESIGNER_FILE_FETCH_SUCCESS':
      return Object.assign(
        {},
        {
          ...state,
          fileData: action.data,
          fileError: false,
          fileFetching: false,
        }
      )
    case 'DESIGNER_FILE_FETCH_ERROR':
      return Object.assign(
        {},
        {
          ...state,
          fileData: null,
          fileError: action.payload,
          fileFetching: false,
        }
      )

    default:
      return state
  }
}
