// * -------------------------------- NPM --------------------------------------
import ReactTable, {
  Column,
  SortingRule,
  ComponentPropsGetterR,
  Resize,
  RowInfo,
  DefaultFilterFunction,
} from 'react-table'
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import TableHeadColumn from './TableHeadColumn'
import TableLoading from './TableLoading'
import TablePagination  from './TablePagination'
import TablePaginationManual  from './TablePaginationManual'
import '../../../styles/_index.scss'

export type TableRowInfo = RowInfo
export type TableColumn = Column

export interface TableListStateProps {
  page: number,
  pageSize: number
  rows: number
  sort: string[]
}

export interface Props {
  className?: string
  columns: Column[]
  currentAppliedFilter?: string
  data?: any[]
  defaultFilterMethod?: DefaultFilterFunction
  defaultSorted?: SortingRule[]
  getTrProps?: ComponentPropsGetterR
  isFetching: boolean
  lastAppliedFilter?: string
  lastSearchString?: string
  manual?: boolean
  manualIsFetching?: boolean
  noDataText: string
  ofText?: string
  onFetchData?: (state: any, instance: any) => void
  onPageChange?: (pageNumber: number) => void
  onPageSizeChange?: (pageSize: number, pageIndex: number) => void
  onResizedChange?: (newResized: Resize[], event: any) => void
  onRowClick?: (data: any) => void
  page?: number
  pages?: number
  pageSize?: number
  pageText?: string
  resized?: Resize[]
  rows?: number
  rowsText?: string
  minRows?: number
  saveLastAppliedFilter?: (appliedFilter: string) => void
  saveSearchString?: (searchString: string) => void
  showPageSizeOptions?: boolean
  showPagination?: boolean
  showPaginationBottom?: boolean
  showPaginationTop?: boolean
  refElement?: any,
  leftComponent?: React.ReactNode,
  onSortingChange?: (sorting: string[]) => void
}

interface OwnState {
  page: number
  sorted?: SortingRule[]
  search?: string
  resized?: Resize[]
  pageSize?: number
  loading: boolean,
  minRows: number
}


class Table extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      page:
        this.props.page &&
        this.props.currentAppliedFilter === this.props.lastAppliedFilter
          ? this.props.page
          : 0,
      pageSize:
        props.rows ||
        this.props.pageSize || 50,
      resized: this.props.resized || [],
      loading: false,
      minRows: this.props.minRows ? this.props.minRows : 3,
    }


    this.handleTablePageChange = this.handleTablePageChange.bind(this)
    this.handleTablePageSizeChange = this.handleTablePageSizeChange.bind(this)
    this.handleTableSortedChange = this.handleTableSortedChange.bind(this)
    this.getTrProps = this.getTrProps.bind(this)
    this.onResizedChange = this.onResizedChange.bind(this)
  }

  public componentWillUnmount() {
    if (this.props.saveLastAppliedFilter && this.props.currentAppliedFilter) {
      this.props.saveLastAppliedFilter(this.props.currentAppliedFilter)
    }
    if (this.props.saveSearchString) {
      this.props.saveSearchString(this.props.lastSearchString || '')
    }
  }

  public componentDidMount() {
    if (this.props.onPageChange && this.state.page === 1 && !this.props.manual) {
      this.props.onPageChange(this.state.page)
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any): void {
    if (this.props.page !== this.state.page && prevProps.page === this.state.page) {
      this.setState({ page: this.props.page! })
    }
  }


  public render() {
    const { isFetching, data } = this.props
    const { pageSize, minRows } = this.state
    return (
      <ReactTable

        ref={this.props.refElement || undefined}
        columns={this.props.columns}
        data={!isFetching ? data : undefined}
        defaultFilterMethod={this.props.defaultFilterMethod}
        defaultPageSize={pageSize}
        defaultSorted={this.props.defaultSorted}
        filterable={false}
        getTrProps={this.getTrProps}
        loading={
          this.props.manual || this.props.manualIsFetching ? this.props.isFetching : this.state.loading
        }
        LoadingComponent={TableLoading}
        manual={this.props.manual}
        //   minRows={data && data.length ? minRows : 4}
        minRows={data && data.length ? minRows : this.props.isFetching && minRows ? minRows : 4}
        noDataText={this.props.noDataText}
        ofText={this.props.ofText}
        onFetchData={this.props.onFetchData}
        onPageChange={this.handleTablePageChange}
        onPageSizeChange={this.handleTablePageSizeChange}
        onResizedChange={this.onResizedChange}
        onSortedChange={this.handleTableSortedChange}
        page={this.props.page}
        pages={this.props.manual ? this.props.pages : undefined}
        pageText={this.props.pageText}
        PaginationComponent={props => this.props.manual ? (
          <TablePaginationManual {...props} leftComponent={this.props.leftComponent}/>
        ) : <TablePagination {...props} leftComponent={this.props.leftComponent}/>}
        resized={this.state.resized}
        rowsText={this.props.rowsText}
        showPagination={this.props.showPagination}
        showPaginationTop={this.props.showPaginationTop}
        showPaginationBottom={this.props.showPaginationBottom}
        showPageSizeOptions={this.props.showPageSizeOptions}
        ThComponent={TableHeadColumn}
        className={this.props.className}
      />
    )
  }

  private handleTablePageChange(page: number) {
    this.setState({ page }, () => {
      if (this.props.onPageChange) {
        this.props.onPageChange(page)
      }
    })
  }

  private handleTablePageSizeChange(pageSize: number, index: number) {
    this.setState({
      page: index, pageSize,
    }, () => {
      if (this.props.onPageSizeChange) {
        this.props.onPageSizeChange(pageSize, index)
      }
    })
  }


  private handleTableSortedChange(sorted: SortingRule[]) {
    this.setState({ sorted })
    if (this.props.onSortingChange) {
      this.props.onSortingChange(sorted.reduce((acc: string[], sort: SortingRule) => {
        acc.push(`${sort.desc && '-' || ''}${sort.id}`)
        return acc
      }, []))
    }
  }

  private onResizedChange(newResized: any, event: any) {
    this.setState(
      {
        resized: newResized,
      },
      () => {
        if (this.props.onResizedChange) {
          this.props.onResizedChange(newResized, event)
        }
      },
    )
  }

  private getTrProps(state: any, data: any) {
    const props: any = (this.props.getTrProps && this.props.getTrProps(state, data)) || {}
    if (this.props.onRowClick) {
      return {
        ...props,
        style: { ...props.style, cursor: 'pointer' },
        onClick: (event: any) => {
          if (this.props.onRowClick && event.target.className.includes('rt-td')) {
            this.props.onRowClick(data.original)
          } else if (props.onClick) {
            props.onClick(data.original)
          }
        },
      }
    }
    return props
  }
}

export default Table
