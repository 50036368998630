import * as React from 'react'
import {bars} from '../../../../types/Graphs'
import {withTranslation, WithTranslation} from 'react-i18next'
import {Card, CardBody, CardFooter, CardHeader} from '@mv-submodules/inplant-components-fe';
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

interface GraphQualitySingleStateProps {
  qualityDetailsData?: any
  lineData?: any
  hasErrored: boolean
  isLoading: boolean
  columns?: string[]
  lastDateUpdate: number | null
  fieldSelected: string | any,
  cliccable?: boolean | false
}

interface GraphQualitySingleDispatchProps {
  fetchData: () => Function
}

interface GraphQualitySingleOwnProps {
  // t: TranslationFunction
  history: any
  match: any
}

interface GraphQualitySinglePassedProps {
  fieldSelected: string
}

interface GraphQualitySingleOwnState {
  lastUpdate: number | null
  legend: any | null
  data: any | null
}

type GraphQualitySingleProps = GraphQualitySingleStateProps &
  GraphQualitySingleDispatchProps &
  GraphQualitySingleOwnProps &
  GraphQualitySinglePassedProps &
  WithTranslation

class GraphQualitySingle extends React.Component<GraphQualitySingleProps, GraphQualitySingleOwnState> {
  constructor(props: GraphQualitySingleProps) {
    super(props)
    this.state = {
      lastUpdate: null,
      legend: null,
      data: null,
    }
  }

  public componentDidUpdate() {
    this.draw()
  }

  public componentWillReceiveProps(newProps: GraphQualitySingleProps) {
    this.setState({
      data: newProps.qualityDetailsData,
      lastUpdate: newProps.lastDateUpdate,
    })
  }

  public shouldComponentUpdate(newProps: GraphQualitySingleProps, newState: GraphQualitySingleOwnState) {
    return this.state.data !== newProps.fetchData
  }

  public draw() {
    bars(
      '#' + 'chart-quality-' + this.props.fieldSelected,
      this.props.qualityDetailsData,
      this.props.columns,
      this.props.fieldSelected,
    )
  }

  public render() {
    let legend

    function explodeCamel(str: string) {
      return str.replace(/([A-Z])/g, ' $1').replace(/^./, (stri: string) => {
        return stri.toUpperCase()
      })
    }

    if (undefined !== this.state.legend && null !== this.state.legend) {
      // @ts-ignore
      legend = this.state.legend.map((i: any, ind: any) => {
        if (i !== 'time') {
          return (
            <li className="filter-trigger" key={i.name} data-filter={i.name}>
              <span
                className={'legend-color legend-' + i.name.toLowerCase() + '-color'}
                style={{backgroundColor: i.color}}
              />
              {explodeCamel(i.name)}
            </li>
          )
        } else {
          return null
        }
      })
    }

    return (
      <div className={`DashboardWidget WidgetQualitySingle WidgetH50 ${this.props.cliccable ? 'cliccable' : ''}`}>
        <Card marginBottom={3}>
          <CardHeader>{this.props.fieldSelected.toUpperCase()}</CardHeader>
          <CardBody>
            <div className="container-fluid chart-container">
              <div className="full-h">
                <Row verticalAlignment={"center"}>
                  <Column sm={12}>
                    <div className="text-center full-h">
                      <div className="chart-container chartsPlanRunHome">
                        <svg id={'chart-quality-' + this.props.fieldSelected} preserveAspectRatio="xMinYMin meet"/>
                        <div className={'tooltip'}>
                          <div className="content"/>
                        </div>
                      </div>
                    </div>
                  </Column>
                </Row>

              </div>
            </div>
          </CardBody>
          <CardFooter
            textSizeSmall={true}
            textAlign={'right'}
            textMuted={true}
          >
            <ul className="legend float-left">{legend}</ul>
            <ul className="legend float-right">
              <li>
                <span className="legend-color legend-single-color"/>
                {this.props.fieldSelected}
              </li>
            </ul>
          </CardFooter>
        </Card>
      </div>
    )
  }
}

export default withTranslation()(GraphQualitySingle)
