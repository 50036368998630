import * as React from 'react'
import { fetchLot } from '../../../../redux/actions/service'
import { ProductionListItem } from '../../../../types/ProductionListItem'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Loader, Modal } from '@mv-submodules/inplant-components-fe'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import { isJSON } from '@mv-submodules/inplant-core-fe/functions'
import { consoleLog } from '../../../../../inplant-components-fe/mvfunctions/logs'

interface StateProps {
  item: string | number
  lotId: string | number | null
  closeModalFn: () => void
  // t: TranslationFunction
}

interface OwnState {
  lot: null | ProductionListItem
}

interface ProductionDetailsStateProps {
  lot: ProductionListItem
  lotDetailKeys: string[]
  hasErrored: boolean
  isLoading: boolean
  config: {
    hiddenAttributes?: string[]
    dataSourceFrames?: string
    dataSourceMapping?: string
  } | null,
}

interface ProductionDetailsDispatchProps {
  fetchData: Function
}

const mapStateToProps = (state: any): ProductionDetailsStateProps => {
  consoleLog('mapStateToProps', state.config.production)
  return {
    lot: state.production.lot,
    hasErrored: state.production.hasDetailsErrored,
    isLoading: state.production.isDetailsLoading,
    config: {
      dataSourceFrames: state.config.production.dataSourceFrames,
      dataSourceMapping: state.config.production.dataSourceMapping,
      hiddenAttributes: isJSON(state.config.production?.hiddenCounters)
        ? JSON.parse(state.config.production?.hiddenCounters)
        : '',
    },
    lotDetailKeys: isJSON(state.config.production?.lotDetailKeys)
      ? JSON.parse(state.config.production?.lotDetailKeys)
      : null,
  }
}

const mapDispatchToProps = (dispatch: Function): ProductionDetailsDispatchProps => {
  return {
    fetchData: (id: number, frames?: boolean) => dispatch(fetchLot(id, frames)),
  }
}

type Props = ProductionDetailsStateProps & ProductionDetailsDispatchProps & StateProps

class DetailsModal extends React.Component<Props & WithTranslation, OwnState> {
  constructor(props: Props & WithTranslation) {
    super(props)

    this.state = {
      lot: null,
    }
  }

  public componentWillMount(): void {
    consoleLog('this.props.config?.dataSourceFrames', this.props.config?.dataSourceFrames)
    const useFrames = this.props.config?.dataSourceFrames && this.props.config.dataSourceFrames === 'true'
    this.props.fetchData(this.props.item, useFrames).then(() => {
      this.setState({
        lot: this.props.lot,
      })
    })
  }

  public render() {
    const { config, lot, isLoading, hasErrored, t } = this.props
    const jsonData: { [key: string]: any } | null = (lot && lot.jsonData && JSON.parse(lot.jsonData)) || null

    const mainAttributes = ['id', 'scrapsInLine', 'startTime', 'endTime', 'pieces']

    let rightPanelKeys: string[] = []
    if (this.props.lotDetailKeys) {
      rightPanelKeys = this.props.lotDetailKeys
    } else if (jsonData) {
      rightPanelKeys = Object.keys(jsonData)
    }

    const useFrames = config?.dataSourceFrames && config.dataSourceFrames === 'true'

    return (
      <Modal
        visible={true}
        title={`${this.props.t('production.detailsModal.title')} ${lot && lot.serial ? `: ${lot.serial}` : ''}`}
        closeLabel={this.props.t('production.detailsModal.actions.close')}
        onClose={this.props.closeModalFn}
        width={75}

      >
        {isLoading && <Loader />}
        {!isLoading && hasErrored && (
          <div className={'alert alert-warning w-auto text-center'}>{this.props.t('production.fetch.error')}</div>
        )}
        {!isLoading && !hasErrored && lot && (
          <div className="container py-0">
            <Row>
              <Column md={6}>
                <table className="table table-sm table-borderless table-striped">
                  <tbody>
                    {
                      useFrames ?
                        <>
                          {
                            Object.keys(lot).filter(k =>
                              !['uuid'].includes(k) &&
                              (k.indexOf('json') !== 0) &&
                              lot[k] &&
                              (lot[k] !== '')
                            ).map(k =>
                              <tr key={k}>
                                <th className="th-label">{t(`production.labels.${k}`, {defaultValue: k})}</th>
                                <td>{lot[k]}</td>
                              </tr>
                            )
                          }
                        </>
                      : mainAttributes.map(
                      attr =>
                        (!config?.hiddenAttributes ||
                          config.hiddenAttributes.length === 0 ||
                          !config.hiddenAttributes.includes(attr)) && (
                          <tr key={attr}>
                            <th className="th-label">{t(`production.labels.${attr}`)}</th>
                            <td>{lot[attr]}</td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </Column>
              <Column md={6}>
                {jsonData && (
                  <div className="card">
                    <div className="card-body">
                      <table className="table table-sm table-borderless table-striped">
                        <tbody>
                          {rightPanelKeys.map(attribute => {
                            const attributeValue = getDataFromObject(attribute, jsonData)
                            console.log('attributeValue', attributeValue) //tslint:disable-line
                            return (
                              (useFrames && attributeValue) || !useFrames ?
                              <tr key={attribute}>
                                <th className="th-label">
                                  {t('production.labels.' + attribute.split('.').pop(), {
                                    defaultValue: attribute.replace('_', ' '),
                                  })}
                                </th>
                                <td>
                                  {attributeValue instanceof Object
                                    ? JSON.stringify(attributeValue)
                                    : String(attributeValue)}
                                </td>
                              </tr> : null
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </Column>
            </Row>
          </div>
        )}
      </Modal>
    )
  }
}

const getDataFromObject = <T,>(slug: string, data: T): any =>
  data &&
  slug
    .split('.')
    .reduce((acc: any, inputName: string) => (acc && acc[inputName] != null ? acc[inputName] : undefined), data)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DetailsModal))
