import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { fetchJobs } from '../../../../redux/actions'
import { MaintenanceJob } from '../../../../types'
import { getDateFormat } from '../../../../functions/shared'
import { Loader } from '@mv-submodules/inplant-components-fe'
import * as moment from 'moment'
import { MaintenanceClientFiltersParams } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'

interface OwnState {
  loading: boolean
  rows: MaintenanceJob[]
  pages: number
  page: number
}

interface OwnProps {
  title: string
  params: MaintenanceClientFiltersParams
  perPage?: number
  hiddenCols?: string[]
  highlightNext?: boolean
  // t: TranslationFunction
}

interface DispatchProps {
  fetchJobs: (
    pageSlug?: string | null,
    clientFilters?: MaintenanceClientFiltersParams | null
  ) => Promise<MaintenanceJob[]>
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchJobs: (pageSlug?: string | null, clientFilters?: MaintenanceClientFiltersParams | null) =>
    dispatch(fetchJobs(pageSlug, clientFilters)),
})

type Props = DispatchProps & OwnProps & WithTranslation

interface DefaultProps {
  perPage: number
  hiddenCols: string[]
  highlightNext: boolean
}

type PropsWithDefaults = Props & DefaultProps

class TargetJobs extends React.Component<Props, OwnState> {
  public static defaultProps: DefaultProps = {
    perPage: 5,
    hiddenCols: [],
    highlightNext: false,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      rows: [],
      pages: 0,
      page: 0,
    }
    this.haveNextPage = this.haveNextPage.bind(this)
    this.havePrevPage = this.havePrevPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.getPageRows = this.getPageRows.bind(this)
  }

  public componentDidMount() {
    const { params, perPage } = this.props as PropsWithDefaults

    this.setState({ loading: true })
    this.props
      .fetchJobs(null, params)
      .then((items: MaintenanceJob[]) => {
        const pages = Math.ceil(items.length / perPage)
        this.setState({
          loading: false,
          rows: items,
          pages,
          page: items.length ? 1 : 0,
        })
      })
      .catch(error => {
        console.error(error) // tslint:disable-line
      })
  }

  private haveNextPage() {
    const { page, pages } = this.state
    return page + 1 <= pages
  }

  private havePrevPage() {
    const { page } = this.state
    return page - 1 > 0
  }

  private nextPage() {
    const { page } = this.state
    if (this.haveNextPage()) {
      this.setState({ page: page + 1 })
    }
  }

  private prevPage() {
    const { page } = this.state
    if (this.havePrevPage()) {
      this.setState({ page: page - 1 })
    }
  }

  private getPageRows(): MaintenanceJob[] {
    const { page, rows } = this.state
    const { perPage } = this.props as PropsWithDefaults
    return rows.slice((page - 1) * perPage, page * perPage)
  }

  public render() {
    const { title, t, hiddenCols, highlightNext } = this.props as PropsWithDefaults
    const { page, pages } = this.state
    const today = moment()

    return (
      <div className="card mb-5 target-scheduled-jobs">
        <div className="card-header">
          {title}
          <span className="card-header--pagination">
            {t('maintenance.target.pagination.page')} {page} {t('maintenance.target.pagination.of')} {pages}
            <span
              className={`pagination-arrow pagination-arrow--left ${
                !this.havePrevPage() ? 'pagination-arrow--disable' : ''
              }`}
              onClick={this.prevPage}
            />
            <span
              className={`pagination-arrow pagination-arrow--right ${
                !this.haveNextPage() ? 'pagination-arrow--disable' : ''
              }`}
              onClick={this.nextPage}
            />
          </span>
        </div>
        <div className="card-body">
          <table className="table table-sm">
            <thead>
            <tr>
              {!hiddenCols.includes('operation') && (
                <th className="th-operation">{t('maintenance.target.jobsCols.operation')}</th>
              )}
              {!hiddenCols.includes('type') && <th className="th-type">{t('maintenance.target.jobsCols.type')}</th>}
              {!hiddenCols.includes('periodicity') && (
                <th className="th-periodicity">{t('maintenance.target.jobsCols.periodicity')}</th>
              )}
              {!hiddenCols.includes('hours') && (
                <th className="th-hours">{t('maintenance.target.jobsCols.hours')}</th>
              )}
              {!hiddenCols.includes('prev') && <th className="th-prev">{t('maintenance.target.jobsCols.prev')}</th>}
              {!hiddenCols.includes('next') && <th className="th-next">{t('maintenance.target.jobsCols.next')}</th>}
            </tr>
            </thead>
            <tbody>
            {this.getPageRows().map((row: MaintenanceJob, index: number) => {
              return (
                <tr key={index}>
                  {!hiddenCols.includes('operation') && <td>
                    {row.operation}
                  </td>}
                  {!hiddenCols.includes('type') && <td>{row.type.name}</td>}
                  {!hiddenCols.includes('periodicity') && <td>{row.periodicity}</td>}
                  {!hiddenCols.includes('hours') && <td>{row.hours}</td>}
                  {!hiddenCols.includes('prev') && (
                    <td>{row.previousJob ? row.previousJob.dueDate.format(getDateFormat()) : null}</td>
                  )}
                  {!hiddenCols.includes('next') && (
                    <td
                      className={`${
                        highlightNext && row.nextJob && today.isAfter(row.nextJob.dueDate, 'day')
                          ? 'td-highlighted'
                          : ''
                      }`}
                    >
                      {row.nextJob ? row.nextJob.dueDate.format(getDateFormat()) : null}
                    </td>
                  )}
                </tr>
              )
            })}
            {!this.state.loading && this.state.rows.length === 0 && (
              <tr>
                <td colSpan={100} className="noData">
                  {t('maintenance.target.noData')}
                </td>
              </tr>
            )}
            {this.state.loading && (
              <tr>
                <td colSpan={100}>
                  <Loader/>
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation()(TargetJobs))
