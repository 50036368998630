import { IconDefinition, faWrench } from '@fortawesome/free-solid-svg-icons'
import Dashboard from './Dashboard'

export interface RouteProps {
  path: string
  exact?: boolean
  i18nkey?: string
  icon?: IconDefinition
  iconOverride?: string
  visible: boolean
  component?: any
  children?: RouteProps[]
}

const routes: RouteProps = {
  path: '/dashboard',
  i18nkey: 'dashboard',
  icon: faWrench,
  component: Dashboard,
  visible: false,
  children: [
    {
      path: '/dashboard',
      i18nkey: 'dashboard',
      component: Dashboard,
      visible: false,
      exact: true,
    },
  ],
}

export default routes
