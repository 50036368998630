import { IconDefinition, faWrench } from '@fortawesome/free-solid-svg-icons'
import DesignerComponent from './components/DesignerComponent/DesignerComponent'

export interface RouteProps {
  path: string
  exact?: boolean
  hiddenMobile?: boolean
  i18nkey?: string
  icon?: IconDefinition
  iconOverride?: string
  visible: boolean
  visibleInUserProfile?: boolean
  component?: any
  children?: RouteProps[]
  aclActionKey?: string
}

const routes: RouteProps = {
  path: '/designer',
  i18nkey: 'moduleDesigner.module',
  icon: faWrench,
  visible: true,
  aclActionKey: 'designer.designer.create',
  children: [
    {
      path: '/designer',
      exact: true,
      i18nkey: 'moduleDesigner.module',
      component: DesignerComponent,
      visible: false,
    },
    {
      path: '/designer/:id',
      i18nkey: 'moduleDesigner.module',
      component: DesignerComponent,
      visible: false,
    },
  ],
}

export default routes
