import { MaintenanceScheduledJob, MaintenanceJob } from '../../../../types'
import * as moment from 'moment'
import { MaintenancePageFilters } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'

export default class PageFiltersHelper {
  public static getUpfrontFilters(filters: MaintenancePageFilters[]): MaintenancePageFilters[] {
    return filters.filter((filter: MaintenancePageFilters | MaintenanceJob) => {
      return filter.type === 'upfront'
    })
  }

  public static getClientFilters(filters: MaintenancePageFilters[]): MaintenancePageFilters[] {
    return filters.filter((filter: MaintenancePageFilters | MaintenanceJob) => {
      return filter.type === 'client'
    })
  }

  public static haveClientFilters(filters: MaintenancePageFilters[]): boolean {
    return PageFiltersHelper.getClientFilters(filters).length > 0
  }

  public static haveClientFilter(filters: MaintenancePageFilters[], name: string): boolean {
    return (
      filters.filter((filter: MaintenancePageFilters) => {
        return filter.name === name
      }).length > 0
    )
  }

  public static applyClientFilterOnItem = (
    item: MaintenanceScheduledJob | MaintenanceJob,
    filters: MaintenancePageFilters[],
    beforeProcessValueCallBack?: Function
  ) => {
    const statuses: boolean[] = []
    let include = true

    if (filters.length < 1) {
      return include
    }

    filters.forEach((filter: MaintenancePageFilters) => {
      const itemPropKey = filter.name
      let itemPropValue

      if (item.extendedProps) {
        itemPropValue =
          typeof item.extendedProps[itemPropKey] === 'object' && item.extendedProps[itemPropKey].hasOwnProperty('id')
            ? item.extendedProps[itemPropKey].id
            : item.extendedProps[itemPropKey]
      } else {
        itemPropValue =
          typeof item[itemPropKey] === 'object' && item[itemPropKey].hasOwnProperty('id')
            ? item[itemPropKey].id
            : item[itemPropKey]
      }

      if (beforeProcessValueCallBack) {
        itemPropValue = beforeProcessValueCallBack(itemPropKey, itemPropValue, filter.inputType)
      }

      if (filter.selected === null) {
        statuses.push(true)
      } else {
        if (filter.inputType === 'checkbox') {
          const values = filter.selected
          statuses.push(values.includes(itemPropValue))
        } else if (filter.inputType === 'dateRangePicker') {
          const values = filter.selected
          if (moment.isMoment(itemPropValue) && values.length === 2) {
            const dateStart = moment(values[0])
            const dateEnd = moment(values[1])
            statuses.push(itemPropValue.isBetween(dateStart, dateEnd, undefined, '[]'))
          } else {
            statuses.push(true)
          }
        }
      }
    })

    include = statuses.reduce((accumulator: boolean, currentValue: boolean) => {
      return accumulator && currentValue
    }, true)

    return include
  }

  public static getClientFilterValues(filters: MaintenancePageFilters[], name: string): boolean {
    return (
      filters.filter((filter: MaintenancePageFilters) => {
        return filter.name === name
      }).length > 0
    )
  }

  public static applyClientFilters(
    filters: MaintenancePageFilters[],
    data: Array<MaintenanceScheduledJob | MaintenanceJob>,
    beforeProcessValueCallBack?: Function
  ): Array<MaintenanceScheduledJob | MaintenanceJob> {
    const upfront = PageFiltersHelper.getClientFilters(filters)
    return data.filter(d => {
      return PageFiltersHelper.applyClientFilterOnItem(d, upfront, beforeProcessValueCallBack)
    })
  }
}
