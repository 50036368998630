export const measureUnits = {
  'planned loss': 's',
  'unplanned loss': 's',
  'Warm-up': 's',
  ActivePower: 'kWh',
  ActivePower_Both: 'kWh',
  ActivePower_Chiller: 'kWh',
  availability: '',
  Binder: 'kg',
  CarbonFootprint: '',
  CataOvenActivePower: 'kWh',
  CataOvenGas: 'm³',
  CompressedAir: 'm³',
  ComprimedAir: 'm³',
  ConPd01: 'l',
  DemiWater: 'l',
  DisPd01: 'l',
  ExaustChaminsTemp: '°C',
  ExaustChaminsVol: 'm³',
  ExhaustChaminsTemp: '°C',
  ExhaustChaminsVol: 'm³',
  Gas: 'Nm³',
  Gas_Both: 'Nm³',
  Gas_Chiller: 'Nm³',
  oee: '',
  operating: 's',
  Paste: 'kg',
  performance: '',
  pieces: 'n',
  planned: 's',
  PostBurnerActivePower: 'kWh',
  PostBurnerGas: 'm³',
  PowderOvenActivePower: 'kWh',
  PowderOvenGas: 'm³',
  quality: '',
  RawWater: 'l',
  ready: 's',
  RefillWater: 'l',
  run: 's',
  runTimeOperatingTime: '%',
  scrap: 'n',
  Sg1Pd01: 'l',
  SgsPd01: 'l',
  start: 's',
  Temperature_temperatureValue: '°C',
  ThermalEnergy: 'kWh',
  ThermalEnergyH: 'kWh',
  ThermalEnergyC: 'kWh',
  ThermalEnergy_Both: 'kWh',
  ThermalEnergy_Chiller: 'kWh',
}
