import { getContainer, TYPES } from '@mv-submodules/inplant-components-fe/services/binding'
import { TranslationService } from '@mv-submodules/inplant-components-fe/services/translation'
import { i18next } from '@mv-submodules/inplant-core-fe/i18n/i18n'
import { IconComponentService, IconComponentServiceProps } from '@mv-submodules/inplant-components-fe/services/icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import React from 'react'

const initServices = () => {
  const container = getContainer()
  container.bind<TranslationService>(TYPES.TranslationService).toConstantValue(new TranslationService(i18next))
  library.add(fas, far)
  const WrapFontAwesomeIcon = (props: IconComponentServiceProps) => {
    function familyToPrefix(f: 'regular' | 'solid' | undefined): IconPrefix {
      if (f === 'regular') {
        return 'far'
      }
      return 'fas'
    }

    const { icon, ...otherProps } = props
    let family
    let name

    if (Array.isArray(props.icon)) {
      family = props.icon[0]
      name = props.icon[1]
    } else {
      name = props.icon
    }

    const newProps: { icon: [IconPrefix, IconName] } = { icon: [familyToPrefix(family), name as IconName] }

    return <FontAwesomeIcon {...newProps} {...otherProps} />
  }
  container
    .bind<IconComponentService>(TYPES.IconComponentService)
    .toConstantValue(new IconComponentService(WrapFontAwesomeIcon))
  return container
}

export default initServices
