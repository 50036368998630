import * as React from 'react'
import * as moment from 'moment'
import FormModal from '../TaskFormModal/FormModal'
import { FormData, Fieldset } from '../../../../types/pageForm'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ModuleConfig } from '@mv-submodules/inplant-maintenance-fe/types'
import { connect } from 'react-redux'
import { getConfigParam } from '@mv-submodules/inplant-maintenance-fe/functions/shared'

export interface StateProps {
  config: ModuleConfig
}

interface Props {
  visible: boolean
  action: string | null
  onClose: () => void
  onSubmit: (data: FormData) => Promise<any>
  data?: FormData
}

export const mapStateToProps = (state: any) => ({
  config: state.config,
})

const CalendarActionModal = (props: Props & WithTranslation & StateProps) => {
  const t = props.t

  // react.fragment is needed to fix the instantiation of an empty formModal, react behaviour is broken somewhere
  return props.action ? <FormModal
    {...props}
    title={t(`maintenance.dueDates.detail.action.${props.action}`)}
    data={serializeData(props.action, props.data)}
    fields={getFields(props.action, props.config)}
    submitLabel={t('maintenance.tasks.form.save')}
    cancelLabel={t('maintenance.tasks.form.cancel')}
  /> : <React.Fragment />
}


function serializeData(action: string | null, data?: FormData) {
  return action && data ? ({
    ...data,
  }) : undefined
}

function getFields(action: string | null, config: ModuleConfig): Fieldset[] {
  // configures what to output in the modal, components are imported from GenericFieldset
  const notesRequired = getConfigParam(config,  'requiredNote', false)

  switch(action) {
    case "done":
      return [
        {
          "fieldset": "custom",
          "fields": [
            {
              "type": "row",
              "fields": [
                {
                  "type": "date",
                  "slug": "date",
                  "required": true,
                  "max": moment().format('YYYY-MM-DD')
                },
                {
                  "type": "empty"
                }
              ]
            },
            {
              "type": "row",
              "fields": [
                {
                  "type": "textarea",
                  "slug": "notes",
                },
                {
                  "type": "empty"
                }
              ]
            },
            {
              "type": "row",
              "fields": [
                {
                  "type": "attachments"
                }
              ]
            }
          ]
        }
      ]
    case "reschedule":
      return [
        {
          "fieldset": "custom",
          "fields": [
            {
              "type": "row",
              "fields": [
                {
                  "type": "date",
                  "slug": "date",
                  "required": notesRequired,
                },
                {
                  "type": "empty"
                }
              ]
            },
            {
              "type": "row",
              "fields": [
                {
                  "type": "textarea",
                  "slug": "notes",
                  "required": notesRequired,
                },
                {
                  "type": "empty"
                }
              ]
            }
          ]
        }
      ]
    case "reject":
    case "cancel":
    case 'undo':
    case "validation":
      return [
        {
          "fieldset": "custom",
          "fields": [
            {
              "type": "row",
              "fields": [
                {
                  "type": "textarea",
                  "slug": "notes",
                  "required": notesRequired,
                },
                {
                  "type": "empty"
                }
              ]
            }
          ]
        }
      ]
    case 'validate':
      return [{
        fieldset: "custom",
        fields:[{
          type: "row",
          fields:[{
            type: "textarea",
            slug: "notes"
          }]
        },{
          type:"row",
          fields:[{
            type: "attachments"
          }]
        }]
      }]
    default:
      return []
  }
}


export default connect(
  mapStateToProps)(withTranslation()(CalendarActionModal))
