const it = {
  i4environment: {
    module: 'Dashboard',
    carbonFootprint: 'Impronta Carbonio',
    averageDailyConsumption: 'CONSUMO MEDIO GIORNALIERO',
    fetch: {
      noData: 'Non ci sono dati disponibili nel periodo selezionato',
      fetching: 'Caricamento...',
      error: 'Errore caricando i dati',
    },
    tabs: {
      general: 'Chimici e pitture',
      plant: 'Generale',
      pretreatment: 'Pretrattamento',
      electrophoresis: 'Elettroforesi',
      treatment: 'Pretrattamento',
      cataphoresis: 'Cataforesi',
      ovens: 'Forni',
      booths: 'Cabine',
      transport: 'Trasporto',
      auxiliary: 'Ausiliario',
      power: 'Energia elettrica',
      gas: 'Gas',
      air: 'Aria compressa'
    },
    metrics: {
      ActivePower: 'Electric Power',
      CarbonFootprint: 'Carbon Footprint',
      ComprimedAir: 'Compressed Air',
      RefillWater: 'Industrial Water',
      ThermalEnergy: 'Thermal Energy',
      RawWater: 'Raw Water',
      DemiWater: 'Demi Water',
      CataOvenActivePower: 'Cata Oven Active Power',
      PowderOvenActivePower: 'Powder Oven Active Power',
      PostBurnerActivePower: 'Post Burner Active Power',
      CataOvenGas: 'Cata Oven Gas',
      PowderOvenGas: 'Powder Oven Gas',
      PostBurnerGas: 'Post Burner Gas',
      runTimeOperatingTime: 'Run / Operating Time',
    },
    sections: {
      media: 'Media',
      chemicals_and_painting: 'Chimici e vernici',
    },
    modal: {
      cancel: 'Annulla',
      select: 'Salva',
      open: 'Caricamento dati',
      title: 'Caricamento dati',
      fields: {
        type: {
          label: 'Seleziona Tipo',
          value: {
            paste: 'Pasta',
            resin: 'Resina',
            paint: 'Vernice',
          },
        },
        qta: {
          label: 'Qta',
        },
        date: {
          label: 'Data',
        },
      },
    },
    download: {
      buttonLabel: 'DOWNLOAD',
    },
  },
}

export default it
