import * as React from 'react'

export interface OwnState {
  isOpen: boolean
}

export interface StateProps {
  isOpen: boolean
  sideBarToggle: (open: boolean) => void
}

export type Props = StateProps

export class SideBarOverlay extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  public componentDidMount() {
    setTimeout(() => {
      this.setState({ isOpen: true })
    })
  }

  public componentWillUnmount() {
    // TODO delay animation by 300ms!
    this.setState({ isOpen: false })
  }

  public render() {
    return (
      <div
        className={`sidebar-overlay${this.state.isOpen ? ' is-open' : ''}`}
        onClick={() => this.props.sideBarToggle(false)}
      />
    )
  }
}

export default SideBarOverlay
