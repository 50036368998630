import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as jquery from 'jquery'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { fetchData, fetchFileData, fetchSpareParts } from '../../../../redux/actions'
import '../../../../styles/_index.scss'
import TreeNode from '../../widgets/TreeNodeComponent/TreeNode'
import Maintenances from '../../widgets/MaintenancesComponent/MaintenancesComponent'
import { SideContentParams } from '../../../../types'
import { buildColumnsData, getPath, validPath } from '../../../../functions/shared'
import MediaWidget from '../../widgets/MediaWidget/MediaWidget'
import * as moment from 'moment'
import { Table } from '@mv-submodules/inplant-components-fe';
import { TableRowInfo } from '@mv-submodules/inplant-components-fe';
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

export interface StateProps {
  data?: any
  details?: any
  error: Error
  fetching: boolean
  fileData: any
  fileError: boolean
  fileFetching: boolean
  lastDateUpdate: number | null
  maintenance?: any
  sparePartsData: any
  sparePartsError: boolean
  sparePartsFetching: boolean
}

export interface DispatchProps {
  fetchData: () => Function
  fetchFileData: ( fileKey: string ) => Promise<any>
  fetchSpareParts: () => Promise<any>
}

interface OwnStateProps {
  activeColumn: number
  cardTitle: string
  columnNames: string[] | null
  columns: any
  columnsClass: string
  currentId: string | null
  currentParent: string | null
  currentPath: string | null
  data: any
  docs: any
  media: any
  isMobileWebview: boolean
  maintenacesPath?: string
  maintenances?: any
  showDetailsSection: string | null
  treeHeight: string | number | undefined | null
  wrapperHeight: null | number
}

export interface SmartdocsOwnProps extends RouteComponentProps<any, any> {
  // t: TranslationFunction
}

export type SmartdocsProps = StateProps & DispatchProps & SmartdocsOwnProps & WithTranslation

const mapStateToProps = ( state: any ): StateProps => ({
  data: state.smartdocs.smartdocs.data,
  error: state.smartdocs.smartdocs.error,
  fetching: state.smartdocs.smartdocs.fetching,
  fileData: state.smartdocs.storage.fileData,
  fileError: state.smartdocs.storage.fileError,
  fileFetching: state.smartdocs.storage.fileFetching,
  lastDateUpdate: state.smartdocs.lastUpdate,
  maintenance: state.maintenance,
  sparePartsData: state.smartdocs.spareParts.sparePartsData,
  sparePartsError: state.smartdocs.spareParts.sparePartsError,
  sparePartsFetching: state.smartdocs.spareParts.sparePartsFetching,
})

const mapDispatchToProps = ( dispatch: Function ) => ({
  fetchData: () => dispatch(fetchData()),
  fetchFileData: ( fileKey: string ) => dispatch(fetchFileData(fileKey)),
  fetchSpareParts: () => dispatch(fetchSpareParts()),
})

const getDepth = ( obj: any ) => {
  let depth = 0
  if (obj.subcomponents) {
    obj.subcomponents.forEach(( d: any ) => {
      const tmpDepth = getDepth(d)
      if (tmpDepth > depth) {
        depth = tmpDepth
      }
    })
  }
  return 1 + depth
}

const getElementHeight = ( elmID: string ) => {
  const elem = document.getElementById(elmID)
  if (elem) {
    const nodeStyle = window.getComputedStyle(elem)
    return (
      parseInt(nodeStyle.getPropertyValue('height'), 10) +
      parseInt(nodeStyle.getPropertyValue('margin-bottom'), 10) +
      parseInt(nodeStyle.getPropertyValue('margin-top'), 10) +
      parseInt(nodeStyle.getPropertyValue('padding-top'), 10) +
      parseInt(nodeStyle.getPropertyValue('padding-top'), 10)
    )
  }
  return 0
}

class SmartdocsComponentPageView extends React.Component<SmartdocsProps, OwnStateProps> {
  // private treeHeight: number | undefined = 400;
  private resizeTimer: any
  private initialized: boolean = false

  constructor( props: SmartdocsProps ) {
    super(props)

    this.state = {
      activeColumn: 0,
      cardTitle: '',
      columnNames: [],
      columns: [],
      columnsClass: '',
      currentId: null,
      currentParent: null,
      currentPath: null,
      data: null,
      docs: [],
      media: [],
      isMobileWebview: navigator.userAgent.toLowerCase().match(/(android|mobile|ipad|webview)/g) !== null,
      maintenacesPath: '',
      maintenances: null,
      showDetailsSection: null,
      treeHeight: undefined,
      wrapperHeight: null,
    }

    this.downloadFile = this.downloadFile.bind(this)
    this.handleClickLeft = this.handleClickLeft.bind(this)
    this.handleClickRight = this.handleClickRight.bind(this)
    this.handleColumnsClick = this.handleColumnsClick.bind(this)
    this.onResizeUpdate = this.onResizeUpdate.bind(this)
    this.sidecontentChange = this.sidecontentChange.bind(this)
    this.toggleCollapsed = this.toggleCollapsed.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  public componentDidMount() {
    this.props.fetchData()
    // this.props.fetchSpareParts()
    window.addEventListener('resize', this.onResizeUpdate)
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeUpdate)
  }

  public componentWillReceiveProps(
    nextProps: Readonly<StateProps & DispatchProps & SmartdocsOwnProps>,
    nextContext: any
  ): void {
    if (JSON.stringify(this.state.data) !== JSON.stringify(nextProps.data)) {
      this.setState({data: nextProps.data})
    }
  }

  public componentDidUpdate() {
    let nextState = {}

    if (this.props.data && this.props.data.content && this.state.currentPath === null) {
      const columnsData = buildColumnsData(this.props.data.content, this.props.data.content.data.id)
      const params = new URLSearchParams(this.props.location.search)
      const id = params.get('id')

      if (id === undefined || id === null) {
        nextState = {
          currentPath: this.props.data.content.data.id,
          columns: columnsData,
          // treeHeight: this.treeHeight,
          data: this.props.data,
        }
      } else {
        let idElements: string[] = []

        if (id.indexOf('.') > -1) {
          idElements = id.split('.')
        } else if (id.indexOf('.') === -1 && id.substr(0, 2) === 'IM') {
          idElements = getPath(this.props.data.content, 'id:' + id).split('.')
        }

        if (idElements.length > 0) {
          const lastChild = idElements[idElements.length - 1]
          // idElements.splice(-1, 1)
          if (idElements.length >= 1) {
            setTimeout(() => {
              const $ele = jquery('.card-level[data-id="' + lastChild + '"]')
              let $parent: any
              $ele.addClass('selected')
              if ($ele && $ele.length > 0) {
                $parent = $ele.parents('.column-level-inner-wrapper')
                if ($parent && $parent.length > 0) {
                  $parent.css('overflow', 'hidden')
                  $parent.addClass('srolled').scrollTop($ele.position().top - $parent.position().top)
                  $parent.css('overflow', 'auto')
                }
              }
            }, 500)
          }

          nextState = {
            currentPath: idElements.join('.'),
            columns: buildColumnsData(this.props.data.content, '', idElements.join('.')),
          }
        }
      }

      if (nextState !== {}) {
        this.setState({
          ...nextState,
        })

        if (!this.initialized) {
          setTimeout(() => {
            this.updateDimensions(true)
          }, 300)
          this.initialized = true
        }
      }
    }
  }

  public render() {
    if (this.props.error) {
      return (
        <div key={Date.now()} className="col-lg text-center">
          <i className="fas fa-2x fa-exclamation-triangle text-danger"/>
        </div>
      )
    }
    if (this.props.fetching) {
      return (
        <div key={Date.now()} className="col-lg text-center">
          <i className="fas fa-2x fa-spin fa-circle-notch"/>
        </div>
      )
    }

    const breadcrumbs: any = []
    const currentPath: string[] = []
    const rootDocs =
      this.state.data &&
      this.state.data.content &&
      this.state.data.content &&
      this.state.data.content.data &&
      this.state.data.content.data.documents
      .sort(( a: any, b: any ) => a.documentType > b.documentType)
      .map(( d: any ) => {
        return {
          column1: d.label ? d.label : d.name,
          column2: d.documentType,
          column3: d.lastEdit,
          isLink: true,
          link: d.link,
          version: d.version,
          i18n:
            'Name: ' +
            (d.label || '') +
            '\n' +
            'Filename: ' +
            d.name +
            '\n\n' +
            (d.hasOwnProperty('translations') && Object.keys(d.translations).length > 0
              ? '--------------\nTranslations\n' +
              Object.keys(d.translations)
              .map(( lang: string ) => {
                return lang + ': ' + d.translations[lang] + '\n'
              })
              .join('---\n')
              : ''),
        }
      })

    if (this.props.data && this.props.data.content && this.props.data.content.subcomponents) {
      if (this.state.columns) {
        this.state.columns.forEach(( column: any, i: number ) => {
          currentPath.push(column.data.id)
          breadcrumbs.push(
            <span
              className={'level-' + i}
              data-depth={i}
              data-path={currentPath.join('.')}
              key={i}
              onClick={e => this.handleBreadcrumnsClick(e)}
            >
              {column.data.label}
            </span>
          )
        })
      }
    }

    let detailsSection

    switch (this.state.showDetailsSection) {
      case 'maintenances':
        detailsSection = <Maintenances data={this.state.docs} loading={false} ready={true} showTitle={true}/>
        break

      case 'manuals':
      case 'certificates':
      case 'dataSheets':
      case 'sparePartsList':
      case 'accessories':
      case 'spareParts':
        detailsSection =
          this.state.showDetailsSection !== 'spareParts'
            ? this.state.columnNames && (
            <React.Fragment>
              <h4>{this.props.t('treeDetailsTitle' + this.state.showDetailsSection)}</h4>
              <Table
                data={this.state.docs}
                columns={[
                  {
                    Header: this.props.t('treeDetailsFilename'),
                    accessor: 'column1',
                    Cell: (( {original}: TableRowInfo ) => {
                      return (
                        <a onClick={e => this.downloadFile(e, original.link)} href={original.link}>
                          <span>
                            {original.column1.indexOf('/') > -1 ? original.column1.split('/').pop() : original.column1}
                          </span>
                        </a>
                      )
                    })
                  },
                  {
                    Header: this.props.t('treeDetailsdocumentType'),
                    accessor: 'column2',
                    Cell: (( {original}: TableRowInfo ) => this.props.t('moduleSmartdocs.media.types.' + original.column2)),
                    width: 92
                  },
                  {
                    Header: this.props.t('treeDetailsDate'),
                    accessor: 'column3',
                    Cell: (( {original}: TableRowInfo ) => moment(original.column3).format('L')),
                    width: 92
                  },
                ]}
                showPagination={false}
                showPaginationBottom={false}
                noDataText={''}
                isFetching={false}
              />
            </React.Fragment>
          )
            : this.state.columnNames && (
            <React.Fragment>
              <h4>{this.props.t('treeDetailsTitle' + this.state.showDetailsSection)}</h4>
              <Table
                data={this.state.docs}
                columns={[
                  {
                    Header: this.props.t('treeDetailsCode'),
                    accessor: 'column1',
                  },
                  {
                    Header: this.props.t('treeDetailsDescription'),
                    accessor: 'column2',
                  },
                  {
                    Header: this.props.t('treeDetailsPrice'),
                    accessor: 'column3',
                    className: 'text-right'
                  },
                ]}
                showPagination={false}
                showPaginationBottom={false}
                noDataText={''}
                isFetching={false}
              />
            </React.Fragment>
          )
        break

      case 'Media':
        detailsSection = <MediaWidget data={this.state.media} loading={false} ready={true} showTitle={true}/>
        break
    }

    return (
      <div className={'inplant-smartdocs' + (this.state.isMobileWebview ? ' isMobileWebview' : '')}>
        <div className="container-fluid">
          <Row spacing={{vertical:false,horizontal:false}}>
            <Column xs={12}>
              <Row spacing={{vertical:false,horizontal:false}}>
                <Column lg={12}>
                  <div className="DashboardWidget WidgetSmartdoc WidgetH50">
                    <div
                      className="mb-3 tree-container"
                      style={{
                        height:
                          this.state.treeHeight && !this.state.isMobileWebview ? this.state.treeHeight : undefined,
                      }}
                    >
                      <div className="main-title d-none d-lg-block" id="main-title-smartdocs">
                        <h1>{this.props.t('moduleSmartdocs.module')}</h1>
                      </div>
                      <div className="breadcrumb" id="breadcrumbs">
                        {breadcrumbs}
                      </div>

                      <div className="wrapper" style={{height: this.state.wrapperHeight || undefined}}>
                        <div
                          className={'innerWrapper ' + this.state.columnsClass}
                          style={{
                            width:
                              (window.innerWidth < 1200 ? window.innerWidth - 45 : 460) * this.state.columns.length, // @todo check width
                          }}
                        >
                          {this.state.columns &&
                          this.state.columns.map(( c: any, i: number ) => (
                            <div
                              className={'column col-' + (this.state.columns.length - i) + '-' + i}
                              data-depth={i}
                              key={i}
                              ref={() => 'col-' + i}
                              style={
                                i + 1 === this.state.columns.length
                                  ? {
                                    width: window.innerWidth < 1200 ? window.innerWidth - 45 : 460, // @todo check width
                                  }
                                  : {}
                              }
                            >
                              <div className="column-level-wrapper">
                                <h3 className="column-title" onClick={() => this.handleColumnsClick(c.path)}>
                                  {c.data.label}
                                </h3>
                                <div className="column-level-inner-wrapper">
                                  <div className="container-fluid column-level-inner-scroller">
                                    {i === 0 &&
                                    this.props.data &&
                                    this.props.data.content &&
                                    window.innerWidth < 1200 && (
                                      <TreeNode
                                        className={'mb-5 root-node'}
                                        clickLeft={null}
                                        clickRight={null}
                                        data={this.props.data.content}
                                        id={this.props.data.content.data.id}
                                        isCollapsed={this.isNodeCollapsed(
                                          this.props.data.content.data.id,
                                          c.data.id
                                        )}
                                        key={this.props.data.content.data.id}
                                        onSidecontentChange={this.sidecontentChange}
                                        parent={c.data.id}
                                        path={c.path}
                                        spareParts={[]}
                                        t={this.props.t}
                                        toggleCollapsed={this.toggleCollapsed}
                                        root={
                                          this.props.data && this.props.data.content && this.props.data.content.path
                                            ? this.props.data.content.path
                                            : null
                                        }
                                      />
                                    )}
                                    {c.subcomponents &&
                                    c.subcomponents
                                    .filter(( row: any ) => row.level !== 'Metric')
                                    .map(( row: any ) => {
                                      return (
                                        <TreeNode
                                          clickLeft={this.handleClickLeft}
                                          clickRight={this.handleClickRight}
                                          data={row}
                                          id={row.data.id}
                                          isCollapsed={this.isNodeCollapsed(row.data.id, c.data.id)}
                                          key={row.data.id}
                                          onSidecontentChange={this.sidecontentChange}
                                          parent={c.data.id}
                                          path={c.path}
                                          spareParts={[]}
                                          t={this.props.t}
                                          toggleCollapsed={this.toggleCollapsed}
                                          root={
                                            this.props.data &&
                                            this.props.data.content &&
                                            this.props.data.content.path
                                              ? this.props.data.content.path
                                              : null
                                          }
                                        />
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {!this.state.showDetailsSection &&
                    this.props.data &&
                    this.props.data.content &&
                    this.props.data.content.data &&
                    this.state.currentPath === this.props.data.content.data.id &&
                    rootDocs &&
                    rootDocs.length > 0 && (
                      <div
                        className="d-none d-xl-block sidecontent"
                        style={{
                          height:
                            this.state.treeHeight && !this.state.isMobileWebview ? this.state.treeHeight : undefined,
                        }}
                      >
                        <h4>{this.props.t('treeDetailsTitleGlobal')}</h4>
                        <Table
                          data={rootDocs}
                          columns={[
                            {
                              Header: this.props.t('treeDetailsFilename'),
                              accessor: 'column2',
                              Cell: (( {original}: TableRowInfo ) => {
                                return (
                                  <a
                                    onClick={e => this.downloadFile(e, original.link)}
                                    href={original.link}
                                    data-balloon-length="medium"
                                    data-balloon={original.i18n}
                                    data-balloon-pos="up"
                                    data-baloon-break={true}
                                  >
                                      <span>
                                        {original.column1.indexOf('/') > -1 ? original.column1.split('/').pop() : original.column1}
                                      </span>
                                  </a>
                                )
                              })
                            },
                            {
                              Header: this.props.t('treeDetailsdocumentType'),
                              accessor: 'column2',
                              Cell: (( {original}: TableRowInfo ) => this.props.t('moduleSmartdocs.media.types.' + original.column2)),
                              width: 92
                            },
                            {
                              Header: this.props.t('treeDetailsdocumentVersion'),
                              accessor: 'version',
                              width: 80
                            },
                            {
                              Header: this.props.t('treeDetailsDate'),
                              accessor: 'column3',
                              Cell: (( {original}: TableRowInfo ) => moment(original.column3).format('L')),
                              width: 92
                            },
                          ]}
                          showPagination={false}
                          showPaginationBottom={false}
                          noDataText={''}
                          isFetching={false}
                        />
                      </div>
                    )}
                    {this.state.showDetailsSection && (
                      <div
                        className="d-none d-xl-block sidecontent"
                        style={{
                          height:
                            this.state.treeHeight && !this.state.isMobileWebview ? this.state.treeHeight : undefined,
                        }}
                      >
                        {detailsSection}
                      </div>
                    )}
                  </div>
                </Column>
              </Row>
            </Column>
          </Row>
        </div>
      </div>
    )
  }

  private updateDimensions( updateState: boolean = false ) {
    if (document.getElementById('breadcrumbs')) {
      if (this.state.isMobileWebview) {
        this.setState({
          wrapperHeight: window.innerHeight - getElementHeight('breadcrumbs') - 15,
        })
      } else if (updateState) {
        this.setState({
          wrapperHeight:
            window.innerHeight -
            getElementHeight('topBar') -
            getElementHeight('main-title-smartdocs') -
            getElementHeight('breadcrumbs') -
            15,
        })
      }
    }
  }

  private onResizeUpdate() {
    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.updateDimensions(true)
    }, 250)
  }

  private handleClickRight( path: string ) {
    if (this.props.data && this.props.data.content && this.props.data.content.subcomponents) {
      const newPath = validPath(path, this.props.data)
      this.setState({
        columns: buildColumnsData(this.props.data.content, '', newPath),
        currentPath: newPath,
        columnsClass: 'animating',
      })

      setTimeout(() => {
        this.setState({
          columnsClass: 'stopped',
        })
        this.updateDimensions(true)
      }, 300)

      if (this.props.history) {
        this.props.history.push('/smartdocs?id=' + newPath)
      }
    }
  }

  private handleClickLeft() {
    if (this.props.data && this.props.data.content && this.props.data.content.subcomponents) {
      if (this.state.currentPath !== 'imel-plant' && this.state.currentPath !== null) {
        const newPathArray = this.state.currentPath.split('.')
        const newPath = newPathArray.slice(0, newPathArray.length - 1).join('.')

        const cols = Object.keys(this.refs)
        const lastCol = ReactDOM.findDOMNode(this.refs[cols[cols.length - 1]]) as any

        if (lastCol) {
          lastCol.setAttribute('class', 'column to-hide')
        }

        setTimeout(() => {
          this.setState({
            columns: buildColumnsData(this.props.data.content, '', newPath),
            currentPath: newPath,
            showDetailsSection: null,
          })
          this.updateDimensions(true)

          if (this.props.history) {
            this.props.history.push('/smartdocs?id=' + newPath)
          }
        }, 300)
      }
    }
  }

  private handleColumnsClick( path: string ) {
    this.setState({
      columns: buildColumnsData(this.props.data.content, '', path),
      currentPath: path || null,
      showDetailsSection: null
    });

    setTimeout(() => {
      this.updateDimensions(true);
    }, 300);

    if (this.props.history) {
      this.props.history.push('/smartdocs?id=' + path);
    }
  }

  private handleBreadcrumnsClick( e: React.MouseEvent<HTMLSpanElement> ) {
    this.setState({
      columns: buildColumnsData(this.props.data.content, '', e.currentTarget.dataset.path),
      currentPath: e.currentTarget.dataset.path || null,
      showDetailsSection: null,
    })

    setTimeout(() => {
      this.updateDimensions(true)
    }, 300)

    if (this.props.history) {
      this.props.history.push('/smartdocs?id=' + e.currentTarget.dataset.path)
    }
  }

  private sidecontentChange( params: SideContentParams ) {
    this.setState({
      cardTitle: params.toDisable ? '' : params.title,
      columnNames: params.toDisable ? [] : params.columnNames,
      docs: params.toDisable ? [] : params.data,
      showDetailsSection: params.toDisable ? null : params.type,
      media: params.toDisable || !params.hasOwnProperty('media') ? [] : params.media,
    })
  }

  private downloadFile( e: React.MouseEvent<HTMLSpanElement>, link: string ) {
    if (e) {
      e.preventDefault()
    }

    this.props
    .fetchFileData(link)
    .then(( uniqueUrl: string ) => {
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = uniqueUrl
      tempLink.setAttribute('download', link.split('/').pop() || '')
      tempLink.setAttribute('target', '_blank')

      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
    })
    .catch(( error: any ) => {
      console.log('unique -  error', error) // tslint:disable-line
      // @todo notification
    })
  }

  private isNodeCollapsed( id: string, parent: string ) {
    return !(this.state.currentId === id && this.state.currentParent === parent)
  }

  private toggleCollapsed( id: string, parent: string ) {
    let nextState
    if (this.state.currentId === id && this.state.currentParent === parent) {
      nextState = {
        currentId: null,
        currentParent: null,
        showDetailsSection: null,
      }
    } else {
      nextState = {
        currentId: id,
        currentParent: parent,
        showDetailsSection: null,
      }
    }

    this.setState(nextState)
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(SmartdocsComponentPageView))
)
