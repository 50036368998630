import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { addJob } from '../../../../redux/actions'
import { MaintenanceJobPeriodicity, MaintenanceJobPostParams, MaintenanceType } from '../../../../types'
import * as moment from 'moment'
import { capitalizeFirstLetter, getDateFormat, getDateLocale } from '../../../../functions/shared'
import { fetchPlantStatus } from '@mv-submodules/inplant-maintenance-fe/redux/actions/plant'
import { fetchMaintenanceTypes } from '@mv-submodules/inplant-maintenance-fe/redux/actions/types'
import {
  Alert,
  Checkbox,
  DateInput,
  Input,
  Loader,
  Modal, PlantTreeView,
  Select,
  TextArea,
} from '@mv-submodules/inplant-components-fe'
import { SelectOptions } from '@mv-submodules/inplant-components-fe/ui/components/Input/Select'

/*tslint:disable*/

export interface DispatchProps {
  fetchPlantStatus: (url?: string) => Promise<any>
  fetchMaintenanceTypes: () => Promise<any>
  addJob: (params: MaintenanceJobPostParams) => Promise<any>
}

export interface OwnStateProps {
  plantStatus: any
  plantCurrentSelection: any | null
  operation: string
  typeId: string
  periodicity: string
  hours: string
  notes: string
  isSpecialOperation: boolean
  errors: object
  lastDoneDate: moment.Moment
  maintenanceTypes: MaintenanceType[]
  isFetching: boolean
  isSubmitting: boolean
  fetchErrors: boolean
}

export interface OwnProps extends RouteComponentProps<any> {
  isVisible: boolean
  onClose: Function | null
  plantStatus?: any
  maintenanceTypes?: MaintenanceType[]
}

export type Props = DispatchProps & OwnProps & WithTranslation

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchPlantStatus: (url?: string) => dispatch(fetchPlantStatus()),
  fetchMaintenanceTypes: (url?: string) => dispatch(fetchMaintenanceTypes()),
  addJob: (params: MaintenanceJobPostParams) => dispatch(addJob(params)),
})

class JobAddModalComponent extends React.Component<Props, OwnStateProps> {
  public targetIdInputRef: React.RefObject<HTMLInputElement>
  public targetIdInvalidFeedbackRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.targetIdInputRef = React.createRef()
    this.targetIdInvalidFeedbackRef = React.createRef()

    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    this.state = {
      plantStatus: undefined,
      plantCurrentSelection: null,
      operation: '',
      typeId: '',
      periodicity: '',
      hours: '',
      notes: '',
      errors: {},
      isSpecialOperation: false,
      lastDoneDate: today,
      isFetching: false,
      fetchErrors: false,
      maintenanceTypes: [],
      isSubmitting: false,
    }

    this.onModalClose = this.onModalClose.bind(this)
    this.handleTreeClick = this.handleTreeClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.removeTarget = this.removeTarget.bind(this)
    this.formatTarget = this.formatTarget.bind(this)
    this.getInvalidFeedbackMsg = this.getInvalidFeedbackMsg.bind(this)
    this.hasError = this.hasError.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  public UNSAFE_componentWillUpdate( nextProps: Readonly<Props>, nextState: Readonly<OwnStateProps>, nextContext: any ) {
    if(!this.props.isVisible && nextProps.isVisible) {
      this.fetchData()
    }
  }

  public resetState() {
    this.setState({
      plantStatus: this.props.plantStatus,
      plantCurrentSelection: null,
      operation: '',
      typeId: '',
      periodicity: '',
      hours: '',
      notes: '',
      isSpecialOperation: false,
      errors: {},
    })
  }

  public onModalClose(reload: boolean = false) {
    this.resetState()
    if (this.props.onClose) {
      this.props.onClose(reload)
    }
  }

  public onSubmit(e: any) {
    e.preventDefault()
    // console.warn(this.state)
    const params: MaintenanceJobPostParams = {
      targetId: this.state.plantCurrentSelection ? this.state.plantCurrentSelection.data.id : null,
      operation: this.state.operation,
      typeId: this.state.typeId,
      periodicity: parseInt(this.state.periodicity, 10),
      hours: parseInt(this.state.hours, 10),
      notes: this.state.notes,
      isSpecialOperation: this.state.isSpecialOperation,
      lastDoneDate: this.state.lastDoneDate.format(getDateFormat()),
    }
    this.setState({ errors: {}, isSubmitting: true })
    // reset errors
    this.props
      .addJob(params)
      .then(() => {
        this.onModalClose(true)
      })
      .catch(error => {
        console.log('catch 1', error, error.errors ) // tslint:disable-line
        this.setState({
          errors: error.errors,
        })
      })
      .finally(() => {
        this.setState({ isSubmitting: false })
      })
  }

  public removeTarget() {
    this.setState({
      plantCurrentSelection: null,
    })
  }

  public handleChange(e?: any, state?: { value: string | boolean; name: string }) {
    const change = {}
    if (e) {
      change[e.target.name] = e.target.name === 'isSpecialOperation' ? !this.state.isSpecialOperation : e.target.value
    } else if (state) {
      change[state.name] = state!.name === 'isSpecialOperation' ? !this.state.isSpecialOperation : state!.value
    }
    this.setState(change)
  }

  public handleTreeClick(item: any) {
    this.setState({ plantCurrentSelection: item })
  }

  public formatTarget(target: any): string {
    if (target.data.path) {
      const path = Object.assign([], target.data.path)
      path.splice(0, 1)
      return path.map((item: any) => capitalizeFirstLetter(item)).join(' > ')
    }
    return ''
  }

  public getInvalidFeedbackMsg(field: string) {
    const { errors } = this.state
    if (errors && errors.hasOwnProperty(field)) {
      return errors[field].join('<br>')
    }
    return null
  }

  private getSelectOptions(
    sources:  Array<{ name: string; id: string } | string>,
    defaultOption?: string
  ): SelectOptions {
    const options: SelectOptions = {
      defaultOption: defaultOption || undefined,
      items: [],
    }

    sources.map(source => {
      options.items.push({
        label: typeof source === 'string' ? this.props.t(`maintenance.periodicity.${source}`) : source.name,
        value: typeof source === 'string' ? MaintenanceJobPeriodicity[source] : source.id,
      })
    })

    return options
  }

  public hasError(field: string) {
    const { errors } = this.state
    return errors && errors.hasOwnProperty(field)
  }

  private fetchData() {
    this.setState({ isFetching: true })
    Promise.all([
      this.props.maintenanceTypes ? this.props.maintenanceTypes : this.props.fetchMaintenanceTypes(),
      this.props.plantStatus ? this.props.plantStatus : this.props.fetchPlantStatus(),
    ])
      .then(([maintenanceTypes, plantStatus]) => {
        this.setState({
          plantStatus,
          maintenanceTypes,
        })
      })
      .catch((error: any) => {
        this.setState({
          fetchErrors: true,
        })
      })
      .finally(() => {
        this.setState({ isFetching: false })
      })
  }

  public render() {
    const { maintenanceTypes, isFetching, fetchErrors, plantStatus } = this.state
    const showErrors = !isFetching && (fetchErrors || plantStatus === undefined || !maintenanceTypes || maintenanceTypes.length === 0)

    const periodicityList = Object.keys(MaintenanceJobPeriodicity)
    .filter(
      (k: any) => typeof MaintenanceJobPeriodicity[k] === 'number'
    ).map(mp => ({
      id: mp,
      name:  this.props.t('maintenance.periodicity.' + mp)
    }))

    const today = moment()
    moment.locale(getDateLocale())
    if (!this.props.isVisible){
      return null
    }

    return (
      <form onSubmit={this.onSubmit}>
        <Modal
          visible={this.props.isVisible}
          onClose={() => this.onModalClose()}
          title={this.props.t('maintenance.addJob.title')}
          width={75}
          closeLabel={this.props.t('maintenance.close')}
          additionalFooterButtons={[{
            label: this.props.t('maintenance.addJob.save'),
            isLoading: this.state.isSubmitting,
            variant: 'primary',
            type: 'submit'
          }]}
        >
          {this.state.isFetching && <Loader />}
          {showErrors && (
            <Alert title={this.props.t('maintenance.errors.somethingGoneWrong')} type="danger" />
          )}
          {!this.state.isFetching && !this.state.fetchErrors && this.state.plantStatus && (
            <div className="row">
              <div className="col-sm-6 treeview-container">
                <PlantTreeView
                  data={this.state.plantStatus.children}
                  currentSelection={this.state.plantCurrentSelection}
                  onClick={this.handleTreeClick}
                  height="small"
                />
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <div className="form-group col-sm-12">
                    <div className="input-group mb-3">
                      <Input
                        disabled={true}
                        appendValue={this.state.plantCurrentSelection ? 'times' : undefined}
                        appendClickCallback={this.state.plantCurrentSelection ? this.removeTarget : undefined}
                        label={this.props.t('maintenance.addJob.target')}
                        onChange={() => {}}
                        placeholder={this.props.t('maintenance.addJob.targetPlaceholder')}
                        ref={this.targetIdInputRef}
                        required={true}
                        type="text"
                        value={
                          this.state.plantCurrentSelection ? this.formatTarget(this.state.plantCurrentSelection) : ''
                        }
                        error={this.getInvalidFeedbackMsg('targetId')}
                      />
                    </div>
                  </div>

                  <div className="form-group col-sm-12">
                    <Input
                      name="operation"
                      type="text"
                      id="operation"
                      value={this.state.operation}
                      onChange={value => this.handleChange(undefined, { name: 'operation', value: value as string })}
                      required={true}
                      label={this.props.t('maintenance.addJob.operation')}
                      error={this.getInvalidFeedbackMsg('operation')}
                    />

                  </div>
                  <div className="form-group col-sm-12">
                    <Select
                      name="typeId"
                      id="typeId"
                      options={this.getSelectOptions(
                        [{ name: this.props.t('maintenance.addJob.selectType'), id: '' }].concat(
                          this.state.maintenanceTypes
                        )
                      )}
                      label={this.props.t('maintenance.addJob.type')}
                      value={this.state.typeId}
                      onChange={value => this.handleChange(undefined, { name: 'typeId', value: value as string })}
                      required={true}
                      error={this.getInvalidFeedbackMsg('typeId')}
                    />
                  </div>
                  <div className="form-group col-sm-12">
                    <Select
                      name="periodicity"
                      id="periodicity"
                      value={this.state.periodicity}
                      onChange={value =>
                        this.handleChange(undefined, { name: 'periodicity', value: value as string })
                      }
                      label={this.props.t('maintenance.addJob.periodicity')}
                      options={this.getSelectOptions(periodicityList, this.props.t('maintenance.addJob.selectPeriodicity'))}
                      error={this.getInvalidFeedbackMsg('periodicity')}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Input
                      name="hours"
                      type="number"
                      label={this.props.t('maintenance.addJob.hours')}
                      // className={['form-control', this.hasError('hours') ? ' is-invalid' : ''].join(' ')}
                      id="hours"
                      value={this.state.hours}
                      onChange={value => this.handleChange(undefined, { value: value as string, name: 'hours' })}
                      error={this.getInvalidFeedbackMsg('hours')}
                    />
                  </div>

                  <div className="form-group col-sm-6">
                    <DateInput
                      label={this.props.t('maintenance.addJob.lastDoneDate')}
                      max={today.toString()}
                      onChange={value => this.setState({ lastDoneDate: moment(value) })}
                      value={this.state.lastDoneDate.format(getDateFormat())}
                      required={true}
                      id="dueDate"
                      name="dueDate"
                      error={this.getInvalidFeedbackMsg('dueDate')}
                    />
                  </div>

                  <div className="form-group col-sm-12">
                    <TextArea
                      id="notes"
                      label={this.props.t('maintenance.addJob.notes')}
                      name="notes"
                      value={this.state.notes}
                      onChange={value => this.handleChange(undefined, { name: 'notes', value: value as string })}
                      rows={2}
                      error={this.getInvalidFeedbackMsg('notes')}
                    />
                  </div>

                  <div className="form-group col-sm-12">
                    <Checkbox
                      label={this.props.t('maintenance.addJob.specialOperation')}
                      name="isSpecialOperation"
                      checked={this.state.isSpecialOperation}
                      onChange={element => this.handleChange(undefined, { name: 'isSpecialOperation', value: element.checked })}
                      id="maintenanceIsSpecialOperation"
                      error={this.getInvalidFeedbackMsg('isSpecialOperation')}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </form>
    )
  }
}

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps
  )(withTranslation()(JobAddModalComponent))
)
