import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
// import {History} from 'history'
import { fetchData, fetchSingleData } from '../../../../redux/actions'
import { Link } from 'react-router-dom'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

export interface StateProps {
  fetching: boolean
  error: Error
  item: any
}

export interface DispatchProps {
  fetchData: Function
  fetchSingleData: Function
}

export interface OwnStateProps {
  item: any
}

export type Props = RouteComponentProps<any> & StateProps & DispatchProps & WithTranslation

const mapStateToProps = (state: any) => ({
  fetching: state.user.details.fetching,
  error: state.user.details.error,
  item: state.user.details.data,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  fetchData: () => dispatch(fetchData()),
  fetchSingleData: (id: string) => dispatch(fetchSingleData(id)),
})

class DetailsPageView extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = {
      item: null,
    }
  }

  public componentWillMount() {
    if (this.props.match.params.id) {
      this.props.fetchSingleData(this.props.match.params.id)
    }
  }

  public componentWillReceiveProps(newProps: Props) {
    this.setState({
      item: newProps.item,
    })
  }

  public render() {
    if (this.props.error) {
      return (
        <div key={Date.now()} className="col-lg text-center">
          <i className="fas fa-2x fa-exclamation-triangle text-danger" />
        </div>
      )
    }
    if (this.props.fetching) {
      return (
        <div key={Date.now()} className="col-lg text-center">
          <i className="fas fa-2x fa-spin fa-circle-notch" />
        </div>
      )
    }

    return (
      <div className="inplant-user">
        <Row horizontalAlignment={'between'}>
          <Column >
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={'/user/list'}>{this.props.t('user.index.title')}</Link>
                </li>
                <li className="breadcrumb-item active">{this.state.item && this.state.item.name}</li>
              </ol>
            </nav>
            <h2>{this.state.item && this.state.item.name}</h2>
          </Column>
        </Row>
        {this.state.item && (
          <React.Fragment>
            <Row horizontalAlignment={'between'}>
              <Column>
                <table className="table table-sm table-borderless">
                  <tbody>
                    <tr>
                      <th className="w-10">{this.props.t('user.index.displayName')}</th>
                      <td className="w-40">{this.state.item.displayName}</td>

                      <th className="w-10">{this.props.t('user.index.username')}</th>
                      <td className="w-40">{this.state.item.username}</td>
                    </tr>
                    <tr>
                      <th className="w-10">{this.props.t('user.index.email')}</th>
                      <td className="w-40">{this.state.item.email}</td>

                      <th className="w-10">{this.props.t('user.index.name')}</th>
                      <td className="w-40">{this.state.item.name}</td>
                    </tr>
                    <tr>
                      <th className="w-10">{this.props.t('user.index.surname')}</th>
                      <td className="w-40">{this.state.item.surname}</td>
                    </tr>
                  </tbody>
                </table>
              </Column>
            </Row>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(DetailsPageView))
)
