import { APIStatic } from './index'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'

export const isSparePartsFetching = (bool: boolean): AnyAction => {
  return {
    type: 'SPAREPARTS_FILE_IS_FETCHING',
    fetching: bool,
  }
}

export const fetchSparePartsSuccess = (dataStructure: object): AnyAction => {
  return {
    type: 'SPAREPARTS_FILE_FETCH_SUCCESS',
    data: dataStructure,
  }
}

export const fetchSparePartsError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'SPAREPARTS_FILE_FETCH_ERROR',
  }
}

export const fetchSpareParts = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isSparePartsFetching(true))

    try {
      const data = await APIStatic().request('/spareParts.json')

      dispatch(isSparePartsFetching(false))
      dispatch(fetchSparePartsSuccess(data))

      return data
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(isSparePartsFetching(false))
      dispatch(fetchSparePartsError(new Error(error)))

      throw  error
    }
  }
}
