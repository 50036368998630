export const Oee = `DATA_BLOCK "0.I4P.OEE"
TITLE = i4P OEE Area
{ S7_Optimized_Access := 'FALSE' }
AUTHOR : IMELSPA
FAMILY : i4P
NAME : i4P5000
VERSION : 0.1
//Data area to evaluate Global OEE and local machine OEE
   STRUCT 
      Restarti4PService : Bool;   //  Restart i4Paintshop service command
      "Global OEE" : Struct   //  Global OEE
         "Time" : Struct   //  Time Storage
\${timeMetrics}
         END_STRUCT;
         Production : Struct   //  Production Data
            Frames : Struct   //  Frames
               "F.Loaded" : DInt;   //  Loaded
               "F.Produced" : DInt;   //  Produced
               "F.Unloaded" : DInt;   //  Unloaded
               "F.Scraped" : DInt;   //  Scraped
            END_STRUCT;
            Sqm : Struct   //  Square meters
               "S.Loaded" : Real;   //  Loaded
               "S.Produced" : Real;   //  Produced
               "S.Unloaded" : Real;   //  Unloaded
               "S.Scraped" : Real;   //  Scraped
            END_STRUCT;
            Kg : Struct   //  Kg
               "K.Loaded" : Real;   //  Loaded
               "K.Produced" : Real;   //  Produced
               "K.Unloaded" : Real;   //  Unloaded
               "K.Scraped" : Real;   //  Scraped
            END_STRUCT;
            Pieces : Struct   //  Pieces
               "P.Loaded" : DInt;   //  Loaded
               "P.Produced" : DInt;   //  Produced
               "P.Unloaded" : DInt;   //  Unloaded
               "P.Scraped" : DInt;   //  Scraped
            END_STRUCT;
            "Scrap Daily Cause Counter" : Struct   //  Scrap Daily Cause counter area
               "Cause 1" : Int;   //  Cause 1
               "Cause 2" : Int;   //  Cause 2
               "Cause 3" : Int;   //  Cause 3
               "Cause 4" : Int;   //  Cause 4
               "Cause 5" : Int;   //  Cause 5
               "Cause 6" : Int;   //  Cause 6
            END_STRUCT;
         END_STRUCT;
      END_STRUCT;
      "Global Consumption" : Struct   //  Global Consumption
\${globalMetrics}
      END_STRUCT;
   END_STRUCT;


BEGIN
   Restarti4PService := TRUE;

END_DATA_BLOCK`
