import * as React from 'react'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link, LinkProps, Route } from 'react-router-dom'
import store from '../../../../../../store/configureStore' // TODO reverse dependency
import { User } from '@mv-submodules/inplant-core-fe/auth'
import { withTranslation, WithTranslation } from 'react-i18next'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface StateProps {
  user: User
}

export interface DispatchProps {
  sideBarToggle: () => AnyAction
}

interface RouteCounter {
  storeKey: string
  pageSlug: string
}

export interface SidebarCounter {
  pageSlug?: string
  color?: string
  count?: number
  alwaysVisible?: boolean
}

export interface OwnProps extends LinkProps {
  path: string
  hiddenMobile?: boolean
  icon?: any
  iconOverride?: string
  i18nkey: string
  visible: boolean
  children?: OwnProps[]
  counterStoreKey?: string
  counter?: any
  aclActionKey?: string
  sideBarToggle: ( open: boolean ) => void
  external?: boolean
}

export interface OwnState {
  opened: boolean
  lastUpdate: number
}

const mapStateToProps = ( state: any ): StateProps => {
  return {
    user: state.auth.user,
  }
}

export type Props = RouteComponentProps<{}> & DispatchProps & OwnProps & StateProps & WithTranslation

export class SideBarNavItem extends React.Component<Props, OwnState> {
  constructor( props: Props ) {
    super(props)
    this.state = {
      opened: this.areChildrenInRoute(this.props.location.pathname),
      lastUpdate: Date.now(),
    }
    this.isActive = this.isActive.bind(this)
    this.areChildrenInRoute = this.areChildrenInRoute.bind(this)
    this.getCounterForStoreKey = this.getCounterForStoreKey.bind(this)
    this.renderSideBarNavItem = this.renderSideBarNavItem.bind(this)
  }

  public componentDidUpdate( prevProps: Props, prevState: OwnState ) {
    // open when and not opened, leave open when opened
    if (this.areChildrenInRoute(this.props.location.pathname) && !prevState.opened) {
      this.setState(prev => ({ ...prev, opened: true }))
    }
    // close children if clicking on a different route
    if (
      this.areChildrenInRoute(prevProps.location.pathname) &&
      !this.areChildrenInRoute(this.props.location.pathname) &&
      prevState.opened
    ) {
      this.setState(prev => ({ ...prev, opened: false }))
    }
  }

  public isActive() {
    if (this.props.children) {
      return this.props.children.map(sub => sub.path).includes(this.props.location.pathname) || this.areChildrenRoutesManagedByComponent(this.props.location.pathname)
    }
    return this.props.path === this.props.location.pathname
  }

  public areChildrenInRoute( route: string ) {
    const childRoutes = this.props.children
      ? this.props.children.map(child => child.path)
      : []
    const areChildrenInRoute = childRoutes.includes(route)

    return areChildrenInRoute || this.areChildrenRoutesManagedByComponent(route)
  }

  public render(): JSX.Element | null {
    const { visible, path, hiddenMobile, iconOverride, icon, i18nkey, children, counterStoreKey, counter, aclActionKey, user, external } = this.props
    const { opened } = this.state
    const visibleChildren =
      children && children.length > 0
        ? children.filter(( r: OwnProps ) => {
          return (
            r.visible &&
            (!r.aclActionKey || (r.aclActionKey && !this.props.user.forbiddenActions.includes(r.aclActionKey)))
          )
        })
        : []
    const sidebarCounter: string | SidebarCounter = counterStoreKey
      ? this.getCounterForStoreKey(counterStoreKey)
      : counter
        ? this.getCounterForStoreKey(counter)
        : undefined

    if (!visible || (aclActionKey && user && user.forbiddenActions.includes(aclActionKey))) {
      return null
    }

    const linkInnerContent = <>
      {iconOverride ? (
        <img className="nav-item-image" src={iconOverride}/>
      ) : icon ? (
        <IconComponent icon={icon} className="icon"/>
      ) : null}
      <span>{this.props.t(i18nkey)}</span>
      <div className="sidebar-menu-submenu-toggler ml-auto">
        {visibleChildren.length > 0 ? (
          <IconComponent icon={opened ? 'angle-up' : 'angle-down'} />
        ) : sidebarCounter && typeof sidebarCounter === 'number' && sidebarCounter > 0 ? (
          <span className="badge badge-pill badge-danger">{sidebarCounter}</span>
        ) : sidebarCounter && typeof sidebarCounter === 'object' ? (
          <span className={`badge badge-pill badge-${sidebarCounter.color}`}>{sidebarCounter.count}</span>
        ) : null
        }
      </div>
    </>

    return (
      <li className={`sidebar-menu-li${this.isActive() ? ' active' : ''}${hiddenMobile? ' d-none d-md-inline-block' : ''}`}>
        {
          external ?
            <a
              href={path}
              target="_blank"
              className="sidebar-menu-a d-flex flex-row justify-content-between align-items-center"
              onClick={() => this.props.sideBarToggle(false)}
            >
              {linkInnerContent}
            </a> :
            <Link
              to={path}
              className="sidebar-menu-a d-flex flex-row justify-content-between align-items-center"
              onClick={() => this.props.sideBarToggle(false)}
            >
              {linkInnerContent}
            </Link>
        }
        {opened && children ? (
          <ul className="sidebar-menu-submenu">
            {children
            .filter(( r: OwnProps ) => {
              return (
                r.visible &&
                (!r.aclActionKey || (r.aclActionKey && !this.props.user.forbiddenActions.includes(r.aclActionKey)))
              )
            })
            .map(( item: OwnProps, index: number ) => (
              <Route key={index} render={this.renderSideBarNavItem(item)}/>
            ))}
          </ul>
        ) : null}
      </li>
    )
  }

  private areChildrenRoutesManagedByComponent( route: string ): boolean {
    const childRoutes = this.props.children
    const routeChunks = route.split('/')
    let managed = false
    if (childRoutes &&
      childRoutes.length > 1 &&
      childRoutes[0].path.includes('/' + routeChunks[1]) &&
      (childRoutes.findIndex(r => {
        const pathChunks = r.path.split('/')
        if (pathChunks.length > 0) {
          return pathChunks[pathChunks.length - 1].indexOf(':') === 0
        }

        return false
      }) !== -1)
    ) {
      managed = true
    }
    return managed
  }

  private getCounterForStoreKey(key: string | RouteCounter) {
    const state = store.getState()
    return this.getObjectValueByString(state, key)
  }

  private getObjectValueByString( o: any, counter: any ) {
    let s = typeof counter === 'string' ? counter : counter.storeKey
    try {
      s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
      s = s.replace(/^\./, '') // strip a leading dot
      const a = s.split('.')
      for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i]
        if (k in o) {
          o = o[k]
        } else {
          return
        }
      }
      return typeof counter === 'string' ? o : o.find(( element: any ) => element.pageSlug === counter.pageSlug)
    } catch {
      return undefined
    }
  }

  private renderSideBarNavItem( item: OwnProps ) {
    return ( routeProps: RouteComponentProps<any> ) => {
      const itemProps = {
        t: this.props.t,
        i18n: this.props.i18n,
        tReady: this.props.tReady,
        ...item,
        ...routeProps,
        sideBarToggle: this.props.sideBarToggle,
        user: this.props.user,
      }
      return <SideBarNavItem {...itemProps} />
    }
  }
}

export default withRouter<any, any>(connect(mapStateToProps)(withTranslation()(SideBarNavItem)))
