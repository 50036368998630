export interface ManualDataLoading {
  id: number,
  year: number,
  name: string,
  um: string,
  influxMeasureId: string,
}

export class ManualDataLoadingConsumes implements ManualDataLoading {
  public id: number
  public year: number
  public name: string
  public um: string
  public influxMeasureId: string
  public timeString: any
  public consumption: number

  constructor(manualDataLoading: ManualDataLoading, time: any, value: number) {
    this.id = manualDataLoading.id
    this.year = manualDataLoading.year
    this.name = manualDataLoading.name
    this.um = manualDataLoading.um
    this.influxMeasureId = manualDataLoading.influxMeasureId
    this.timeString = time
    this.consumption = value || 0
  }
}

export interface ManualDataLoadingGraphData {
  data: any[],
  bars: {
    [k: string]: {
      name: string,
      color: string,
      um: string
    }
  }
}

export interface ManualDataLoadingLoadMaterial {
  date: string
  quantity: number | undefined
  measureId: string
}