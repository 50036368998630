const it = {
  plantStatuOverTime: {
    title: 'PLANT STATUS OVER TIME',
    fetch: {
      noData: 'Dati non disponibili',
    },
    timeRange: 'Il periodo selezionato deve essere uguale o inferiore ad una settimana',
    labels: {
      TPE: 'End Production',
      TPLMNT: 'Maintenance',
      TPLON: 'Start',
      TPLRDY: 'Ready',
      TPLUP: 'Up time',
      TPLWU: 'Warm Up',
      TPP: 'Pause production',
      TPROFF: 'Off production',
      TPRON: 'Production On',
      TPS: 'Stop production',
      TSON: 'Switch On',
      TPRNC: 'T. Prod. Non Conforme',
    },
  },
}

export default it
