import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { MaintenanceType } from '../../types'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import FetchError from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper/FetchError'

export const fetchMaintenanceTypes = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    return API().request('/maintenances/types')
      .then((types: MaintenanceType[]) => types)
      .catch((error: FetchError) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}