const en = {
  production: {
    module: 'Dashboard',
    download: {
      buttonLabel: 'EXPORT CSV',
    },
    fetch: {
      error: 'Error loading data',
    },
    labels: {
      date: 'Date',
      startTime: 'Time Load First',
      endTime: 'Time Download Last',
      pieces: 'Pieces',
      lot: 'Lot',
      id: 'ID',
      serial: 'Serial',
      scrapsInLine: 'Scraps in line',
      clear: 'Clear',
      TotalSqm: "Total Sqm",
      TotalKg: "Total Kg",
      BatchCode: "Batch Code",
      Description: "Description",
      TotalFrames: "Total Frames",
      startTimeHours: 'Load First',
      download_time: 'Download Time',
      booth: 'Booth',
      recipe: 'Recipe Code',
      color_code: 'Color Code',
      batch: 'Batch',
      processTime: 'Process Time',
      tunnelTime: 'Tunnel Time',
      rg_soc: 'Business Name',
      cod_parte: 'Part Code'
    },
    list: {
      actions: {
        'alarms-modal': 'Alarms',
        'costs-modal': 'Costs',
        'details-modal': 'Details',
        'product-sheet': 'Product Sheet'
      },
      buttonLabel: 'Actions',
      title: 'Production list',
    },
    detailsModal: {
      title: 'Details',
      actions: {
        close: 'Close',
      },
    },
    costsModal: {
      costsPerItemInLots: 'COSTS PER ITEM IN LOT',
      name: 'Name',
      consumption: 'Consumption',
      cost: 'Cost',
      timeLoadFirst: 'Time Load First',
      timeDownloadLast: 'Time Download Last',
      pieces: 'Pieces',
      totalCost: 'Total cost per item',
      actions: {
        close: 'CLOSE',
      },
    },
    alarmModal: {
      iTime: 'In',
      aTime: 'Accepted',
      oTime: 'Out',
      msgClass: 'Type',
      message: 'Text',
      cabinet: 'Cabinet / component',
      alarms: 'ALARMS',
      actions: {
        close: 'CLOSE',
      },
    },
    table: {
      summary: {
        showing: 'Showing',
        of: 'of',
        from: 'from',
        to: 'to',
        rows: 'rows',
        totalPieces: "Sum pieces",
        scrapsInLine: "Scraps pieces"
      },
      general: {
        page: "Page"
      }
    },
    confirm: {
      downloadWithNoDateRangeSelected: 'You don\'t have selected a date range, are you sure you want to download ALL the data?'
    }
  },
}

export default en
