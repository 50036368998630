// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

const Loader = () => (
  <div className="mt-5 text-center">
    <IconComponent icon={"circle-notch"} spin={true} size="2x" className="text-muted" />
  </div>
)

export default Loader