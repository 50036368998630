import * as React from 'react'
import { connect } from 'react-redux'
import { itemsFetchAttributes, itemsFetchData, serviceShowNewItemMessage } from '../../../../redux/actions'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { History } from 'history'
import { IdNameType, ServiceListItem } from '../../../../types'
import * as moment from 'moment-timezone'
import {
  Alert,
  Filters,
  Loader,
  PageHeader,
  Table,
  TableColumn,
  TableRowInfo,
} from '@mv-submodules/inplant-components-fe'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import { fetchTimeEntriesData } from '@mv-submodules/inplant-service-fe/redux/actions/service'
import { TimeEntry } from '@mv-submodules/inplant-service-fe/types/serviceListItem'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface ListStateProps {
  items: ServiceListItem[]
  timeEntries: TimeEntry[]
  hasErrored: boolean
  timeEntriesHasErrored: boolean
  isLoading: boolean
  timeEntriesIsLoading: boolean
  showNewItemMessage: boolean
}

export interface ListDispatchProps {
  fetchData: () => Promise<ServiceListItem[]>
  fetchTimeEntriesData: () => Promise<TimeEntry[]>
  toggleNewItemMessage: (status: boolean) => void
  fetchAttributes: () => Promise<any>
}

export interface ListOwnProps {
  history: History
}

export interface ListStateProps {
  priorities: IdNameType[]
  tipoAzione: IdNameType[]
  trackers: IdNameType[]
}

interface ListOwnState {
  statuses: IdNameType[]
  filters: {
    [k: string]: {
      isSelect?: boolean
      customFieldId?: null | number
      value: string | number | null
    }
  }
  dateFilters: {
    startDate: string | null
    endDate: string | null
  }
}

export type ListProps = ListStateProps & ListDispatchProps & ListOwnProps & WithTranslation

const mapStateToProps = (state: any): ListStateProps => {
  return {
    items: state.service.service.items,
    timeEntries: state.service.service.timeEntries,
    timeEntriesHasErrored: state.service.service.timeEntriesHasErrored,
    hasErrored: state.service.service.hasErrored,
    isLoading: state.service.service.isLoading,
    timeEntriesIsLoading: state.service.service.timeEntriesIsLoading,
    showNewItemMessage: state.service.service.showNewItemMessage,
    priorities: state.service.service.priorities,
    tipoAzione: state.service.service.tipoAzione,
    trackers: state.service.service.trackers,
  }
}

const mapDispatchToProps = (dispatch: Function): ListDispatchProps => {
  return {
    fetchData: () => dispatch(itemsFetchData()),
    fetchTimeEntriesData: () => dispatch(fetchTimeEntriesData()),
    toggleNewItemMessage: (show: boolean) => dispatch(serviceShowNewItemMessage(show)),
    fetchAttributes: () => dispatch(itemsFetchAttributes()),
  }
}

const priorityBadges = {
  1: 'light',
  2: 'info',
  3: 'success',
  4: 'warning',
  5: 'danger',
}

class ListPageView extends React.Component<ListProps, ListOwnState> {
  private dateFormat = 'DD/MM/YYYY hh:mm'

  constructor(props: ListProps) {
    super(props)

    this.state = {
      statuses: [
        {
          id: 1,
          name: 'New',
        },
        {
          id: 2,
          name: 'In Progress',
        },
        {
          id: 5,
          name: 'Closed',
        },
      ],
      filters: {},
      dateFilters: {
        startDate: null,
        endDate: null,
      },
    }

    this.handleClick = this.handleClick.bind(this)
    this.updateFilter = this.updateFilter.bind(this)
    this.updateDateRangeFilter = this.updateDateRangeFilter.bind(this)
    this.getCustomFieldValue = this.getCustomFieldValue.bind(this)
  }

  public componentDidMount() {
    this.props.fetchData()
    this.props.fetchTimeEntriesData()

    if (!this.props.priorities || this.props.priorities.length < 1) {
      this.props.fetchAttributes()
    }

    setTimeout(this.props.toggleNewItemMessage, 3000, false)
  }

  public render() {
    const { timeEntries } = this.props
    const issueTimeEntries = timeEntries.reduce((acc: { [k: number]: number }, curr) => {
      if (!acc[curr.issue.id]) {
        acc[curr.issue.id] = curr.hours
      } else {
        acc[curr.issue.id] = acc[curr.issue.id] + curr.hours
      }

      return acc
    }, {})

    if (this.props.hasErrored) {
      return (
        <Row>
          <Column lg={12}>
            <div className={'text-center'}>
              <IconComponent icon={'exclamation-triangle'} size="2x" className="text-danger" />
            </div>
          </Column>
        </Row>
      )
    }
    if (this.props.isLoading) {
      return (
        <Row horizontalAlignment={'center'}>
          <Column lg={12}>
            <Loader />
          </Column>
        </Row>
      )
    }

    const alert = this.props.showNewItemMessage ? (
      <Alert type={'secondary'}>{this.props.t('service.form.newTickedCreated')}</Alert>
    ) : null

    /* @todo refactoring: customSize not working */
    const filters: FilterComponent[] = [
      {
        id: 'subject',
        name: 'subject',
        type: 'SearchInput',
        value: null,
        label: this.props.t('service.form.subject'),
        onChange: this.updateFilter,
      },
      {
        id: 'tracker',
        name: 'tracker',
        type: 'Select',
        value: null,
        label: this.props.t('service.form.requestType'),
        onChange: (name, value) => this.updateFilter(name, value, true),
        options: {
          defaultOptionLabel: this.props.t('service.index.table.showAll'),
          defaultOptionDisabled: false,
          items:
            this.props.trackers &&
            this.props.trackers.map((model: any) => ({
              label: model.name,
              value: model.id,
            })),
        },
      },
      {
        id: 'priority',
        name: 'priority',
        value: null,
        label: this.props.t('service.form.priority'),
        type: 'Select',
        onChange: (name, value) => this.updateFilter(name, value, true),
        options: {
          defaultOptionLabel: this.props.t('service.index.table.showAll'),
          items:
            this.props.priorities &&
            this.props.priorities.map((model: any) => ({
              label: model.name,
              value: model.id,
            })),
        },
      },
      {
        id: 'author',
        name: 'author',
        type: 'SearchInput',
        onChange: (name, value) => this.updateFilter(name, value, false, 2),
        value: null,
        label: this.props.t('service.form.author'),
      },
      {
        id: 'assigned_to',
        name: 'assigned_to',
        type: 'SearchInput',
        onChange: this.updateFilter,
        value: null,
        label: this.props.t('service.form.assigned_to'),
      },
      {
        id: 'status',
        name: 'status',
        type: 'Select',
        value: null,
        label: this.props.t('service.form.status'),
        onChange: (name, value) => this.updateFilter(name, value, true),
        options: {
          defaultOptionLabel: this.props.t('service.index.table.showAll'),
          items:
            this.state.statuses &&
            this.state.statuses.map((model: any) => ({
              label: model.name,
              value: model.id,
            })),
        },
      },
      {
        id: 'lastUpdate',
        name: 'lastUpdate',
        type: 'DateRange',
        dateFormat: this.dateFormat,
        dateValueFormat: 'YYYY-MM-DD hh:mm',
        onDateSelectedChange: this.updateDateRangeFilter,
        selectedValues:
          (this.state.dateFilters.startDate &&
            this.state.dateFilters.endDate && [
              String(this.state.dateFilters.startDate),
              String(this.state.dateFilters.endDate),
            ]) ||
          [],
        timePicker: true,
        timePicker24Hour: true,
        value: null /* @todo refactoring: no sense required value */,
      },
    ]

    const columns: TableColumn[] = [
      {
        id: 'id',
        Header: this.props.t('service.form.id'),
        accessor: (item: ServiceListItem) => item.id || '',
        minWidth: 50,
      },
      {
        Header: this.props.t('service.form.subject'),
        accessor: 'subject',
        Cell: (item: any) => {
          return (
            <React.Fragment>
              {item.original.subject}{' '}
              {item.original.attachments && item.original.attachments.length > 0 && (
                <span
                  className="float-right"
                  title={this.props.t('service.index.hasAttachments') + ': ' + item.original.attachments.length}
                >
                  <IconComponent icon={'paperclip'} className="color-gray" />
                </span>
              )}
            </React.Fragment>
          )
        },
        minWidth: 50,
      },
      {
        id: 'trackerName',
        Header: this.props.t('service.form.requestType'),
        accessor: (item: ServiceListItem) => item.tracker.name,
        minWidth: 50,
      },
      {
        id: 'priority',
        Header: this.props.t('service.form.priority'),
        accessor: (item: ServiceListItem) => item.priority.name,
        Cell: (item: TableRowInfo) => {
          return (
            <React.Fragment>
              <span className={'badge badge-' + priorityBadges[item.original.priority.id]}>
                {item.original.priority.name}
              </span>
            </React.Fragment>
          )
        },
        minWidth: 50,
      },
      {
        id: 'assigned_to',
        Header: this.props.t('service.form.assigned_to'),
        accessor: (item: ServiceListItem) => (item.assigned_to && item.assigned_to.name) || '',
        minWidth: 50,
      },
      {
        id: 'author',
        Header: this.props.t('service.form.author'),
        accessor: (item: ServiceListItem) => this.getCustomFieldValue(item, 2),
        minWidth: 50,
      },
      {
        id: 'status',
        Header: this.props.t('service.form.status'),
        accessor: (item: ServiceListItem) => item.status.name,
        minWidth: 50,
      },
      {
        id: 'updated_on',
        Header: this.props.t('service.form.lastUpdate'),
        accessor: (item: ServiceListItem) => item.updated_on,
        Cell: (item: TableRowInfo) => {
          return moment(item.original.updated_on).format(this.dateFormat)
        },
        minWidth: 60,
      },
      {
        id: 'client_hours',
        Header: this.props.t('service.form.client_hours'),
        minWidth: 60,
        accessor: (item: ServiceListItem) => issueTimeEntries[item.id],
        Cell: (item: TableRowInfo) => {
          const warranty =
            item.original.custom_fields && item.original.custom_fields.find((cf: IdNameType) => cf.id === 5)
          return warranty && ['0', 'false'].includes(warranty.value) ? issueTimeEntries[item.original.id] : ''
        },
      },
    ]

    const data = this.props.items.filter(element => {
      let valid = true
      if (this.state.dateFilters.startDate && this.state.dateFilters.endDate) {
        const compareDate = moment(element.updated_on)
        const startDate = this.state.dateFilters.startDate
        const endDate = this.state.dateFilters.endDate

        if (!compareDate.isBetween(startDate, endDate)) {
          return false
        }
      }

      Object.keys(this.state.filters).forEach(filter => {
        const filterObj = this.state.filters[filter]
        let stringToLookInto

        if (filterObj.customFieldId) {
          const customField = element.custom_fields.find(f => f.id === filterObj.customFieldId)
          if (customField) {
            stringToLookInto = customField.value
          }
        } else {
          stringToLookInto =
            typeof element[filter] === 'object' ? element[filter].name || element[filter].value : element[filter]
        }

        if (filterObj && filterObj.value && filterObj.value !== '') {
          if (filterObj.hasOwnProperty('isSelect') && filterObj.isSelect) {
            const value = String(filterObj.value)

            if (
              value &&
              !(
                (element[filter] &&
                  typeof element[filter] === 'object' &&
                  element[filter].hasOwnProperty('id') &&
                  String(element[filter].id) === value) ||
                String(element[filter]) === value
              )
            ) {
              valid = false
            }
          } else {
            if (
              !stringToLookInto ||
              stringToLookInto === '' ||
              String(stringToLookInto)
                .toLowerCase()
                .indexOf(String(filterObj.value).toLowerCase()) === -1
            ) {
              valid = false
            }
          }
        }
      })

      return valid
    })

    return (
      <div className="inplant-service">
        <PageHeader
          title={this.props.t('service.index.title')}
          rightButtons={
            <Link className="btn btn-primary" to="/service/new">
              {this.props.t('service.index.new')}
            </Link>
          }
        />
        <div className="content">
          {alert}
          <Filters fields={filters} />
          <Table
            data={data}
            columns={columns}
            /* @todo refactoring: il filtraggio prima era case insensitive */
            defaultFilterMethod={this.filterCaseInsensitive}
            pageSize={10}
            className="service-table -striped -highlight"
            noDataText={this.props.t('service.index.table.noData')}
            isFetching={this.props.isLoading}
            showPaginationTop={true}
            /* @todo refactoring: tutta la riga cliccabile */
            getTrProps={(state: any, rowInfo: any) => {
              return {
                onClick: (e: MouseEvent, handleOriginal: any) => {
                  this.handleClick(rowInfo.original.id)

                  if (handleOriginal) {
                    handleOriginal()
                  }
                },
              }
            }}
          />
        </div>
      </div>
    )
  }

  private handleClick(id: number) {
    this.props.history.push('/service/list/' + id)
    return false
  }

  private filterCaseInsensitive(filter: any, row: any) {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  private updateFilter(
    name: string,
    value: string | number | null | { id: number; name: string },
    select = false,
    customFieldId: null | number = null
  ) {
    const newState = Object.assign({}, this.state)

    if (!newState.filters[name]) {
      newState.filters[name] = {
        value: null,
      }
    }

    if (customFieldId) {
      newState.filters[name].customFieldId = customFieldId
    }

    if (select) {
      newState.filters[name].isSelect = true
      // @ts-ignore
      newState.filters[name].value = value && typeof value === 'object' && value.hasOwnProperty('id') ? value.id : value
    } else {
      // @ts-ignore
      newState.filters[name].value = value
    }

    this.setState(newState)
  }

  private updateDateRangeFilter(selected: string[]) {
    const newState = Object.assign({}, this.state)

    newState.dateFilters.startDate = selected[0]
    newState.dateFilters.endDate = selected[1]

    this.setState(newState)
  }

  private getCustomFieldValue(item: ServiceListItem, id: number) {
    // custom_field with id === 2 is Contact Person
    const customField = item.custom_fields.find(c => c.id === id)

    if (customField) {
      return customField.value
    }

    return null
  }
}

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPageView)))
