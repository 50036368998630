import * as React from 'react'
import { withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import GraphQuality from '../../widgets/GraphQuality/GraphQuality'
import GraphPerformance from '../../widgets/GraphPerformance/GraphPerformance'
import GraphAvailabilityR from '../../widgets/GraphAvailabilityR/GraphAvailabilityR'
import TPRNCWarnAreaLoader from '../../widgets/ChartUtils/TPRNCWarnAreaLoader'
import { Card, CardBody, PageHeader } from '@mv-submodules/inplant-components-fe';
import DateRangeFilters
  from "@mv-submodules/inplant-core-fe/ui/components/widgets/dateFiltersBase/components/DateRangeFilters";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import { ChartACL } from '@mv-submodules/inplant-efficiency-fe-imel/functions/shared'

interface OeeDetailsOwnProps {
  history: any
}

type OeeDetailsProps = OeeDetailsOwnProps & WithTranslation

class OeeDetailsPageView extends React.Component<OeeDetailsProps> {
  constructor(props: OeeDetailsProps) {
    super(props)
  }

  public render() {
    return (
      <React.Fragment>
        <PageHeader title={"Oee Details"} />

        <div className="content">
          <div id="filters-wrapper">
            <Row spacing={{vertical:false,horizontal:false}}>
              <Column xs={12}>
                <Card bgLight={true}>
                  <CardBody noGutters={true}>
                    <DateRangeFilters />
                  </CardBody>
                </Card>
              </Column>
            </Row>
          </div>

          <ChartACL chartName={'oee-details/quality'}>
            <Row spacing={{vertical:false,horizontal:false}}>
              <Column xs={12}>
                <GraphQuality/>
              </Column>
            </Row>
          </ChartACL>

          <ChartACL chartName={'oee-details/availability'}>
            <Row spacing={{vertical:false,horizontal:false}}>
              <Column xs={12}>
                <GraphAvailabilityR cliccable={true} />
              </Column>
            </Row>
          </ChartACL>

          <ChartACL chartName={'oee-details/performance'}>
            <Row spacing={{vertical:false,horizontal:false}}>
              <Column xs={12}>
                <GraphPerformance />
              </Column>
            </Row>
          </ChartACL>

        </div>

        <TPRNCWarnAreaLoader />
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(withTranslation()(OeeDetailsPageView))
