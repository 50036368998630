import * as React from 'react'
import { capitalizeFirstLetter } from '../../../../functions/shared'

export interface Option {
  value: any
  name: string
}

interface Props {
  option: Option
  checked: boolean
  onSelectionChanged: (checked: boolean) => void
}

export default class Checkbox extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.toggleChecked = this.toggleChecked.bind(this)
  }

  private toggleChecked = () => {
    const { checked, onSelectionChanged } = this.props
    onSelectionChanged(!checked)
  }

  private handleChange() {
    this.toggleChecked()
  }

  public render() {
    const { option, checked } = this.props

    return (
      <div className="custom-control custom-checkbox custom-control-inline filter-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={`${option.name}-ck`}
          value={option.value}
          checked={checked}
          onChange={this.handleChange}
        />
        <label className="custom-control-label" htmlFor={`${option.name}-ck`}>
          {capitalizeFirstLetter(option.name)}
        </label>
      </div>
    )
  }
}
