import * as React from 'react'
import Modal from 'react-bootstrap4-modal'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fetchStorageData } from '../../../store/actions'
import { withTranslation, WithTranslation } from 'react-i18next'
import FileBrowser from './FileBrowser'
import { Loader } from '@mv-submodules/inplant-components-fe'
import { mediaTypes } from '../../../types/media'

export interface StateProps {
  data: any
  error: boolean
  fetching: boolean
  lastUpdate: null | number
}

export interface DispatchProps {
  fetchStorageData: () => Promise<any>
}

export interface OwnStateProps {
  data: any
  selectedFile: any
  selectedType: null | string
}

export interface OwnProps extends RouteComponentProps<any> {
  addFile: Function
  closeModal: (event: React.MouseEvent<HTMLButtonElement>) => void
  isVisible: boolean
  // t: TranslationFunction
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

export const mapStateToProps = (state: any) => ({
  data: state.designer.storage.data,
  error: state.designer.storage.error,
  fetching: state.designer.storage.fetching,
  lastUpdate: state.designer.storage.lastUpdate,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchStorageData: () => dispatch(fetchStorageData()),
})

class FileBrowserModal extends React.Component<Props, OwnStateProps> {
  private fileType: any

  constructor(props: Props) {
    super(props)
    this.state = {
      data: this.props.data || [],
      selectedFile: null,
      selectedType: null,
    }

    this.confirmSelection = this.confirmSelection.bind(this)
    this.selectFile = this.selectFile.bind(this)
    this.selectType = this.selectType.bind(this)
    this.textSearch = this.textSearch.bind(this)
  }

  public componentDidMount() {
    if (
      this.props.data === [] ||
      !this.props.lastUpdate ||
      this.props.lastUpdate + 10800 < Math.floor(new Date().getTime() / 1000)
    ) {
      this.props.fetchStorageData().then(() => {
        this.setState({
          data: this.props.data,
        })
      })
    }
  }

  public render() {
    return (
      <Modal visible={true} dialogClassName={'modal-lg'}>
        <div className="modal-header">
          <h5 className="modal-title">{this.props.t('designer.fileBrowser.modal.title')}</h5>
          {/*<input type="text" className="form-control col-sm-5 ml-auto mr-0"
                 placeholder={this.props.t("designer.modal.search")}/> */}
        </div>
        <div className="modal-body">
          {this.props.fetching && <Loader />}
          {!this.props.fetching && !this.props.error && (
            <FileBrowser data={this.state.data} selectedFile={this.state.selectedFile} selectFile={this.selectFile} />
          )}
        </div>
        <div className="modal-footer">
          <div className="form-inline ml-0 mr-auto">
            <label className="ml-0 mr-2" htmlFor="inlineFormInputType1">
              {this.props.t('designer.media.selectType')}
            </label>
            <select
              className="form-control"
              id="inlineFormInputType1"
              onChange={this.selectType}
              required={true}
              ref={input => {
                this.fileType = input
              }}
            >
              <option value={''} />
              {mediaTypes.map((key: string) => (
                <option key={key} value={key}>
                  {this.props.t('designer.media.types.' + key)}
                </option>
              ))}
            </select>
          </div>
          <button type="button" className="btn btn-secondary float-left" onClick={this.props.closeModal}>
            {this.props.t('designer.fileBrowser.modal.cancel')}
          </button>
          <button
            disabled={!this.state.selectedFile || !this.state.selectedType}
            onClick={this.confirmSelection}
            type="button"
            className="btn btn-primary"
          >
            {this.props.t('designer.fileBrowser.modal.select')}
          </button>
        </div>
      </Modal>
    )
  }

  private selectFile(fileData: any) {
    this.setState({
      selectedFile: fileData,
    })

    this.fileType.focus()
  }

  private confirmSelection(e: React.MouseEvent<HTMLButtonElement>) {
    if (e) {
      e.preventDefault()
    }

    if (!this.state.selectedType) {
      this.fileType.focus()
    }

    this.props.addFile(this.state.selectedFile, this.state.selectedType)
  }

  private selectType(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      selectedType: e.currentTarget.value && e.currentTarget.value !== '' ? e.currentTarget.value : null,
    })
  }

  private textSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const filterText =
      e.currentTarget.value && e.currentTarget.value !== '' ? e.currentTarget.value.toLocaleLowerCase() : null

    if (filterText) {
      const findFiles = (text: string, folders: any) => {
        const subfolders = Object.keys(folders).filter((fo: any) => fo !== 'files')

        if (folders.files) {
          const searchFiles = folders.files.filter((file: { Key: string }) => file.Key.includes(text))
          if (searchFiles && searchFiles.length > 0) {
            folders.files = searchFiles
          } else {
            delete folders.files
          }
        }

        if (subfolders) {
          subfolders.forEach((subfolder: any) => findFiles(text, folders[subfolder]))
        }
      }

      const cleanFolders = (folders: any) => {
        const subfolders = Object.keys(folders).filter((fo: any) => fo !== 'files')

        if (subfolders) {
          subfolders.forEach((subfolder: any, index: number) => {
            if (Object.keys(folders[subfolder]).length < 1) {
              delete folders[subfolder]
            } else {
              cleanFolders(folders[subfolder])
            }
          })
        }
      }

      const data = JSON.parse(JSON.stringify(this.props.data))

      findFiles(filterText, data)

      this.setState({
        data,
      })
    } else {
      this.setState({
        data: this.props.data,
      })
    }
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(FileBrowserModal))
)
