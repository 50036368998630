import * as moment from 'moment'
import { getDateRangeFiltering } from '@mv-submodules/inplant-core-fe/functions/date'
import { useSelector } from 'react-redux'

export const timeRangeFileString = ( dateStart: string | null, dateEnd: string | null ): string => {
  return (
    (dateStart ? '_' + moment(dateStart).format('YYYY-MM-DD') : '') +
    (dateEnd ? '_' + moment(dateEnd).format('YYYY-MM-DD') : '')
  )
}

/**
 * Tests if the string is a valid JSON
 *
 * @param {string} str String to parse
 * @param {boolean} [returnJson] if true it will return the parsed object, boolean otherwise
 */
export const isJSON = ( str: any, returnJson?: boolean ): boolean => {
  if (typeof str === 'object') {
    return str
  }

  try {
    return returnJson ? JSON.parse(str) : JSON.parse(str) && !!str
  } catch (e) {
    return false
  }
}

export const TPRNCAlarmDays = ( data: Array<[string, number]> ): any => {
  return data && data.reduce(( acc, curr ) => {
    const date = moment(curr[0]).format('YYYY-MM-DD')

    if (curr[1] !== null) {
      if (!acc[date]) {
        acc[date] = []
      }

      acc[date].push(curr)
    }
    return acc
  }, []) || []
}

export const TPRNCAlarmsData = (
  dateStart: string | undefined | null,
  dateEnd: string | undefined | null,
  data: any[] | undefined | null,
  TPRNCData: any[] | undefined | null,
): Array<{
  key: string
  x1: string
  x2: string | undefined
  stroke: string
  strokeOpacity: number
  fill: string
  fillOpacity: number
}> | null => {
  if (!dateStart || !dateEnd || !data || !TPRNCData) {
    return null
  }

  const { groupingDays } = dateStart && dateEnd ? getDateRangeFiltering(dateStart, dateEnd) : { groupingDays: 1 }
  return Object.keys(TPRNCAlarmDays(TPRNCData)).map(( a: string ) => ({
      key: a,
      x1: moment(a).format('YYYY-MM-DDT00:00:00[Z]'),
      // we need to check if it's the last item, if so we need to declare x2 ad undefined (we don't have data after the end), otherwise x2 is 1 x-step (groupingDays) after
      x2: data && data.length > 0 && moment(a).format('YYYY-MM-DDT00:00:00[Z]') === data[data.length - 1][0] ?
        undefined :
        moment(a).add(groupingDays, 'd').format('YYYY-MM-DDT00:00:00[Z]'),
      stroke: 'red',
      strokeOpacity: 1,
      fill: '#ff6020',
      fillOpacity: 0.3,
    }),
  )
}

/**
 * [HOC] Checks if children chart can be rendered
 * @param props
 * @constructor
 */
export const ChartACL: React.FC<{
  chartName: string
  children: JSX.Element
}> = props => {
  const disabledCharts = isJSON(useSelector((state: any) => state.config?.efficiency?.disabledCharts), true)
  const enabled = !disabledCharts || !props.chartName ||
    !Array.isArray(disabledCharts) || !disabledCharts.includes(props.chartName)

  return enabled ? props.children : null
}
