const it = {
  costs: {
    menu: {
      costs: 'Costs',
      pending: 'Pending',
      settings: 'Settings',
      view: 'Setup',
      setup: 'Setup',
      budgetVsActual: 'Budget VS Actual'
    },
    actions: {
      cancel: 'Annulla',
      add: 'Aggiungi',
      update: 'Modifica',
      view: 'Visualizza',
      delete: 'Elimina',
      save: 'Salva'
    },
    confirm: {
      deleteHistoryEntry: 'Sei sicuro di voler eliminare questo elemento?',
    },
    notifications: {
      submitSuccess: 'Dati salvati con successo',
      submitError: 'Errore inviando i dati',
      allRequired: 'Tutti i campi sono necessari',
    },
    modal: {
      title: {
        add: 'Aggiungi costo',
        update: 'Modifica costo',
      },
      fields: {
        date: 'Dalla data',
        name: 'Nome',
        cost: 'Costo',
        unitMeasure: 'Unità di misura',
        type: 'Riferimento',
      },
      fieldsFormat: {
        costs: 'Utilizzare solo numeri e il punto "."',
      },
    },
    view: {
      title: {
        pre: 'Costo',
      },
      updateCosts: 'Aggiorna costo',
      fields: {
        date: 'Dalla data',
        fromDate: 'Dalla data',
        name: 'Nome',
        cost: 'Costo',
        value: 'Costo',
        unitMeasure: 'Unità di misura',
        type: 'Riferimento',
      },
      history: {
        title: 'Cost history',
      },
    },
    types: {
      manual_loading: 'Manuale',
      operators: 'Operatori',
      continue_loading: 'Continuo',
    },
    labels: {
      budget: 'Budget',

    },
    consumptionWidget: {
      button: {
        label: 'Caricamento Manuale Dati',
      },
      modal: {
        title: 'Caricamento Manuale Dati',
        fields: {
          type: 'Tipo',
          qta: 'Quantità',
          date: 'Data',
        },
        cancel: 'Annulla',
        submit: 'Invia',
      },
      notifications: {
        submitSuccess: 'Dati salvati con successo',
        submitError: 'Errore inviando i dati',
        allRequired: 'Tutti i campi sono necessari',
      },
    },
  },
}

export default it
