import * as React from 'react'
import GenericFieldset from './GenericFieldset'
import { getInputValueFromData } from './utils'
import { RowField, InputField, EmptyField } from '../../../../types/pageForm'
import {Button} from "@mv-submodules/inplant-components-fe";

interface Props {
  slug: string
  fields: Array<InputField | EmptyField | RowField>
  errors?: { [field: string]: string[] }
  hiddenFields?: string[]
  getInputValue: (slug: string) => any
  onInputChange: (slug: string, value: any, template?: boolean) => void
}

interface OwnState {
  values: any[]
}

class GroupedFieldset extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      values: [],
    }
    this.addNewRow = this.addNewRow.bind(this)
    this.removeRow = this.removeRow.bind(this)
    this.getInputValue = this.getInputValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  public getDerivedStateFromProps(props: Props) {
    const values = props.getInputValue(props.slug) || []
    return {
      values: values.length ? values : [{}],
    }
  }

  // TODO ALIGN BUTTONS

  public render() {
    const props = this.props
    const { values } = this.state

    return (
      <>
        {values.map((_, index) => (
          <div key={index} className="row">
            <GenericFieldset
              {...props}
              getInputValue={this.getInputValue(index)}
              onInputChange={this.handleInputChange(index)}
              className="col subset"
              fields={props.fields}
            />
            <div className="col-1 d-flex align-items-center">
              <Button variant={"link"}  onClick={() => this.removeRow(index)} icon={'minus-circle'}/>
            </div>
          </div>
        ))}
        <div className="form-group">
          <Button variant={"secondary"} onClick={this.addNewRow} icon={'plus'}/>
        </div>
      </>
    )
  }

  private getInputValue(index: number) {
    return (inputSlug: string) => {
      const slug = inputSlug.replace(`${this.props.slug}.`, '')
      return getInputValueFromData(slug, this.state.values[index])
    }
  }

  private handleInputChange(index: number) {
    return (inputSlug: string, value: any, inputTemplate?: boolean) => {
      const slug = inputSlug.replace(`${this.props.slug}.`, '')
      const { values } = this.state

      this.props.onInputChange(
        this.props.slug,
        [
          ...values.slice(0, index),
          {
            ...(values[index] || {}),
            [slug]: value,
          },
          ...values.slice(index + 1),
        ],
        inputTemplate
      )
    }
  }

  private addNewRow() {
    // here duplicate inputs
    this.props.onInputChange(this.props.slug, [...this.state.values, {}])
  }

  private removeRow(index: number) {
    this.props.onInputChange(this.props.slug, [
      ...this.state.values.slice(0, index),
      ...this.state.values.slice(index + 1),
    ])
  }
}

export default GroupedFieldset
