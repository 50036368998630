import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { getI18n, WithTranslation, withTranslation } from 'react-i18next'
import { fetchSchema, fetchSingleData } from '../../../../redux/actions'
// @ts-ignore // TODO refactoring
import Form from 'react-jsonschema-form'
import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { History } from 'history'
import { Breadcrumbs, Loader, MainPageContent, PageHeader } from '@mv-submodules/inplant-components-fe'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

const i18nInstance = getI18n()

const API = () => FetchWrapper.getInstance('user')

interface StateProps {
  fetching: boolean
  error: Error
  schemas: any
  formData: any
  forbiddenActions: string[]
}

interface DispatchProps {
  fetchSchema: Function
  fetchSingleData: Function
  logoutUser: Function
}

interface OwnStateProps {
  formData: any
  errors: any
}

interface OwnProps {
  history: History
}

type Props = RouteComponentProps<any> & StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = ( state: any ) => ({
  fetching: state.user.schemas.fetching && state.user.details.fetching,
  error: state.user.schemas.error || state.user.details.error,
  schemas: state.user.schemas.data,
  formData: state.user.details.data,
  forbiddenActions: state.auth.user.forbiddenActions,
})

const mapDispatchToProps = ( dispatch: Function ): DispatchProps => ({
  fetchSchema: () => dispatch(fetchSchema()),
  fetchSingleData: ( id: string ) => dispatch(fetchSingleData(id)),
  logoutUser: () => dispatch(logoutUser()),
})

class EditPageView extends React.Component<Props, OwnStateProps> {
  constructor( props: Props ) {
    super(props)
    this.state = {
      formData: undefined,
      errors: undefined,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.validate = this.validate.bind(this)
    this.transformErrors = this.transformErrors.bind(this)
  }

  public componentWillMount() {
    if (this.props.match.params.id) {
      this.props.fetchSingleData(this.props.match.params.id)
      this.props.fetchSchema()
    }
  }

  public componentWillReceiveProps( newProps: Props ) {
    if (newProps.formData) {
      const formData = Object.assign({}, newProps.formData)
      if (newProps.formData && newProps.formData.roles) {
        formData.roles = newProps.formData.roles.map(( role: any ) => {
          return role.id
        })
      }

      if (newProps.formData && newProps.formData.plants) {
        formData.plants = newProps.formData.plants.map(( plant: any ) => {
          return plant.id
        })
      }

      if (newProps.formData && newProps.schemas && newProps.schemas.edit && newProps.schemas.edit.schema) {
        const schema = newProps.schemas.edit.schema.properties
        Object.keys(newProps.formData).forEach(( key: string ) => {
          if (formData[key] === null && schema[key] && schema[key].type === 'string') {
            if (schema[key].format && schema[key].format === 'email') {
              formData[key] = undefined
            } else {
              formData[key] = ''
            }
          }
        })
      }

      this.setState({ formData })
    }
  }

  public render() {
    if (this.props.error) {
      return (
        <Column lg={12} key={Date.now()} >
          <div className={"text-center mt-5"}>
            <i className="fas fa-2x fa-exclamation-triangle text-danger"/>
          </div>
        </Column>
      )
    }
    if (this.props.fetching) {
      return (
        <Column lg={12} key={Date.now()} >
          <div className={"text-center mt-5"}>
            <Loader/>
          </div>
        </Column>
      )
    }

    return (
      <div className="inplant-user inplant-user-form">
        {this.props.schemas && this.props.schemas.edit && this.props.schemas.edit.schema && this.props.schemas.edit.ui && (
          <Form
            schema={this.props.schemas.edit.schema}
            uiSchema={this.props.schemas.edit.ui}
            formData={this.state.formData}
            FieldTemplate={this.fieldTpl}
            ObjectFieldTemplate={this.objectFieldTemplate}
            liveValidate={false}
            showErrorList={false}
            onSubmit={this.onSubmit}
            onChange={this.onChange}
            validate={this.validate}
            transformErrors={this.transformErrors}
          >
            {!this.props.forbiddenActions.includes('user.user.edit') ? (
              <div className='form-actions'>
                <button className='float-right btn btn-primary' type='submit'>
                  {this.props.t('user.form.update')}
                </button>
              </div>
            ) : (
              <div className='form-actions'/>
            )}
          </Form>
        )}
      </div>
    )
  }

  private fieldTpl = ( props: any ) => {
    const { id, classNames, label, help, required, description, rawErrors = [], children } = props
    return <div className={classNames}>
      {
        id !== 'root' &&
        <>
          <label htmlFor={id}>
            {label}
            {required ? '*' : null}
          </label>
          {description}
        </>
      }
      {children}
      {rawErrors.map(( error: string, errorId: number ) => (
        <div key={errorId} style={{ color: '#e94e1b' }}>
          {error}
        </div>
      ))}
      {help}
    </div>
  }

  private objectFieldTemplate = ( props: any ) => {
    const { properties, title, description } = props
    return (
      <div>
        <PageHeader title={description}/>

        <Breadcrumbs path={[
          {
            href: '/user/list',
            label: title,
          },
          {
            label: description,
          },
        ]}/>

        <MainPageContent>
          <Row>
            {properties.map(( prop: any ) => (
              <Column md={6} key={prop.content.key}>
                {prop.content}
              </Column>
            ))}
          </Row>
        </MainPageContent>
      </div>
    )
  }

  private transformErrors( errors: any ) {
    if (i18nInstance.language === 'it') {
      return errors.map(( error: any ) => {
        if (error.name === 'minLength') {
          error.message = this.props.t('user.errors.minLength', {
            count: error.params.limit,
          })
        }

        if (error.name === 'maxLength') {
          error.message = this.props.t('user.errors.maxLength', {
            count: error.params.limit,
          })
        }

        if (error.name === 'format') {
          error.message = this.props.t('user.errors.format', {
            format: error.params.format,
          })
        }

        if (error.name === 'required') {
          error.message = this.props.t('user.errors.required')
        }

        return error
      })
    }

    return errors
  }

  private validate( formData: any, errors: any ): any {
    if (this.state.errors) {
      const fieldList = Object.getOwnPropertyNames(this.state.errors)
      fieldList.forEach(( fieldName: string ) => {
        const errorList = Object.getOwnPropertyNames(this.state.errors[fieldName])
        errorList.forEach(( codeName: string ) => {
          errors[fieldName].addError(this.state.errors[fieldName][codeName])
        })
      })
    }
    return errors
  }

  private boolReplacer( key: string, value: any ): any {
    if (typeof value === 'boolean') {
      return String(value)
    }
    return value
  }

  private onSubmit( data: any ) {
    const dataObj = Object.assign({}, data)
    const fieldsSchema = dataObj.schema.properties

    Object.keys(fieldsSchema).forEach(( k: string ) => {
      if (fieldsSchema[k].type === 'boolean') {
        if (dataObj.formData[k]) {
          dataObj.formData[k] = 'true'
        } else {
          dataObj.formData[k] = 'false'
        }
      }

      if (fieldsSchema[k].type === 'string') {
        if (dataObj.formData[k] === '' || dataObj.formData[k] === undefined) {
          dataObj.formData[k] = null
        }
      }
    })

    API().request('/manage/users/' + this.props.match.params.id, {
      method: 'PUT',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataObj.formData, this.boolReplacer),
    })
    .then(() => {
      // this.props.toggleNewItemMessage(true)
      this.props.history.push('/user/list')
    })
    .catch(( error: any ) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        this.props.logoutUser()
        return null
      } else if (error.name === 'FetchError' && error.statusCode === 422) {
        this.setState({
          ...this.state,
          errors: error.errors,
        })

        return null
      } else {
        throw Error(error.toString())
      }
    })
  }

  private onChange = ( { formData }: any ) => {
    const errors = Object.assign({}, this.state.errors)
    Object.keys(formData).forEach(( key: string ) => {
      if (this.state.formData && formData[key] !== this.state.formData[key]) {
        delete errors[key]
      }
    })

    this.setState(state => {
      return Object.assign({}, state, { formData }, { errors })
    })
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(EditPageView)),
)
