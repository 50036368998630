import { AnyAction } from 'redux'

export const changeRange = (range: string, dateStart: string, dateEnd: string): AnyAction => {
  return {
    type: 'CHANGE_RANGE',
    range,
    dateStart,
    dateEnd,
  }
}
