import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { MaintenancePage } from '../../../../types'
import Plan from './Plan'
import Calendar from './Calendar'
import Setup from './Setup'
import { fetchPageFilters } from '../../../../redux/actions'
import { Error } from '../../../../functions/shared'
import { Loader } from '@mv-submodules/inplant-components-fe'
import { MaintenancePageFilters } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'

interface StateProps {
  config: any
  fetching: boolean
  error: Error
  pageFilters: MaintenancePageFilters[]
}

interface DispatchProps {
  fetchPageFilters: (slug: string) => Promise<any>
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchPageFilters: (slug: string) => dispatch(fetchPageFilters(slug)),
})

const mapStateToProps = (state: any) => {
  return {
    config: state.config,
    fetching: state.maintenance.pageFilters.fetching,
    error: state.maintenance.pageFilters.error,
    pageFilters: state.maintenance.pageFilters.filters,
  }
}

export const getPageConf = (pathname: string, config: any): MaintenancePage => {
  let pageConf = {}
  if (config.maintenance && config.maintenance.pages) {
    const pages: MaintenancePage[] = config.maintenance.pages
    const pagePath = pathname.split('/').pop()
    for (const page of pages) {
      if (page.pagePath === pagePath) {
        pageConf = page
        break
      }
    }
  }
  return pageConf as MaintenancePage
}

type Props = StateProps & DispatchProps & RouteComponentProps<any>

class MaintenanceRouter extends React.Component<Props> {
  private pageConf: MaintenancePage

  constructor(props: Props) {
    super(props)
    this.pageConf = getPageConf(this.props.location.pathname, this.props.config)
  }

  public componentDidMount() {
    this.props.fetchPageFilters(this.pageConf.slug).catch(error => {
      console.error(error) // tslint:disable-line
    })
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.pageConf = getPageConf(this.props.location.pathname, this.props.config)
      this.props.fetchPageFilters(this.pageConf.slug).catch(error => {
        console.error(error) // tslint:disable-line
      })
    }
  }

  public render() {
    const { pageFilters } = this.props

    if (this.props.error) {
      return (
        <div className="mt-3">
          <Error />
        </div>
      )
    }

    if (this.props.fetching || typeof this.props.pageFilters[this.pageConf.slug] === 'undefined') {
      return (
        <div className="mt-3">
          <Loader />
        </div>
      )
    }

    if (this.pageConf.pageType === 'plan') {
      return <Plan pageConf={this.pageConf} pageFilters={pageFilters} />
    }

    if (this.pageConf.pageType === 'calendar') {
      return <Calendar pageConf={this.pageConf} pageFilters={pageFilters} />
    }

    if (this.pageConf.pageType === 'setup') {
      return <Setup pageConf={this.pageConf} pageFilters={pageFilters} />
    }

    return null
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MaintenanceRouter)
)
