import { AlarmListItem } from '../types/AlarmListItem'

export const initialGraphGaugesState = {
  data: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as GraphGaugesState

export interface GraphGaugesState {
  data: number[]
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}

export const initialGraphPlanRunState = {
  data: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as GraphPlanRunState

export interface GraphPlanRunState {
  data: any
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}

export const initialGraphPlanRunOeeState = {
  data: [],
  lineData: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as GraphPlanRunOeeState

export interface GraphPlanRunOeeState {
  data: any
  lineData: any
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}

export const initialGraphConsumptionState = {
  data: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as GraphConsumptionState

export interface GraphConsumptionState {
  data: number[]
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}

export const initialGraphQualityState = {
  data: [],
  lineData: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as GraphQualityState

export interface GraphQualityState {
  data: any
  lineData: any
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}

export const initialQualityDetailsState = {
  qualityDetailsData: [],
  lineData: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as QualityDetailsState

export interface QualityDetailsState {
  qualityDetailsData: any
  lineData: any
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}

export const initialGraphAvailabilityState = {
  data: [],
  lineData: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as GraphAvailabilityState

export interface GraphAvailabilityState {
  data: any
  lineData: any
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}

export const initialAvailabilityDetailsState = {
  data: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
  hasAlarmsErrored: false,
  isAlarmsLoading: false,
  itemsAlarms: [],
} as AvailabilityDetailsState

export interface AvailabilityDetailsState {
  data: any
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
  hasAlarmsErrored: boolean
  isAlarmsLoading: boolean
  itemsAlarms: AlarmListItem[]
}

export const initialGraphPerformanceState = {
  data: [],
  lineData: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as GraphPerformanceState

export interface GraphPerformanceState {
  data: any
  lineData: any
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}

export const initialTPRNCAlarmsState = {
  data: [],
  hasErrored: false,
  isLoading: false,
  columns: [],
  lastUpdate: null,
} as TPRNCAlarmsState

export interface TPRNCAlarmsState {
  data: any
  hasErrored: boolean
  isLoading: boolean
  columns: string[]
  lastUpdate: number | null
}
