// * -------------------------------- NPM --------------------------------------
import { Icon} from '../../../services/icon';
import * as React from 'react'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

export interface RightDetailElements {
  label?: {
    icon?: Icon,
    text?: string,
    subText?: string
  },
  value?: {
    text?: string,
    icon?: Icon,
    element?: React.ReactNode
  },
  inline?: boolean
}

interface Props {
  values: RightDetailElements[]
}

class RightDetailView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <div className="right-detail-view-content">
        <table className="table table-borderless w-auto">
          <tbody>
          {this.props.values.map((option,index) => {
            if (option.inline) {
              return (
                <tr key={index}>
                  {option.label && (
                    <td className="label">
                      {option.label.icon &&
                      <IconComponent icon={option.label.icon} className={`${option.label.text && 'mr-1' || ''}`}/>}
                      {option.label.text && (option.label.text)}
                      {option.label.subText && <small>{option.label.subText}</small>}
                    </td>
                  )}
                  {option.value && (option.value.element && (
                    <td>
                      {option.value.element}
                    </td>
                  ) || (
                    <td>
                      {option.value.icon &&
                      <IconComponent icon={option.value.icon} className={`${option.value.text && 'mr-1' || ''}`}/>}
                      {option.value.text && (option.value.text) || '-'}
                    </td>
                  ))}
                </tr>
              )
            }
          })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default RightDetailView