// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

interface Props {
  isLoading: boolean | undefined
  iconColor?: string
  children: React.ReactNode
}

class WithLoader extends React.Component<Props> {
    public render() {
      const {isLoading, iconColor} = this.props;
      return isLoading ? (
        <IconComponent
          icon={"circle-notch"}
          spin={true}
          color={iconColor}
        />
      ) : this.props.children
    }
  }

export default WithLoader