export const download = (
  data: string | ArrayBuffer | ArrayBufferView | Blob,
  filename: string,
  mime?: string,
  bom?: string
) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
    ;(window.navigator as any).msSaveBlob(blob, filename)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    downloadDataURL(blobURL, filename)
  }
}

export const downloadDataURL = (dataURL: string, filename: string) => {
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = dataURL
  tempLink.setAttribute('download', filename)
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(dataURL)
}
