import * as React from 'react'
import { Route } from 'react-router-dom'
import { Login, PrivateRoute, Webview, webviewRoute } from '@mv-submodules/inplant-core-fe/auth'
import { Switch, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { RouteProps } from '@mv-submodules/inplant-core-fe/types'
import ErrorPage from '../Errors/ErrorPage'
import { aclRoutes, aclRedirect } from '@mv-submodules/inplant-config/config/routes'

export interface MainStateProps {
  forbiddenActions: string[]
  loginSuccess: boolean
  loginSuccessPath: string
}

export interface OwnProps {
  routes: RouteProps[]
}

export type Props = MainStateProps & OwnProps

const mapStateToProps = (state: any): MainStateProps => ({
  loginSuccess: state.auth.loggedIn,
  forbiddenActions: state.auth.user ? state.auth.user.forbiddenActions : [],
  loginSuccessPath: state.config.generic.loginSuccessPath,
})

function composeRoutes(moduleRoutes: RouteProps[]): React.ReactNode | void {
  return moduleRoutes.map(route => {
    if (!route.children) {
      return <PrivateRoute key={route.path} exact={route.exact} path={route.path} component={route.component} />
    }
    return composeRoutes(route.children)
  })
}

function Main(props: Props) {
  const { loginSuccess, forbiddenActions, routes, loginSuccessPath } = props
  return (
    <main className={`main-container ${loginSuccess ? '' : 'is-fullwidth'}`}>
      <Switch>
        {composeRoutes(aclRoutes(routes, forbiddenActions, loginSuccessPath))}
        <Route
          key="/"
          exact={true}
          path="/"
          render={() => <Login successPath={aclRedirect(loginSuccessPath)} image="/logo-login.png" />}
        />
        <Route exact={true} path={webviewRoute.path} component={Webview} />
        <Route render={() => <ErrorPage code={404} />} />
      </Switch>
    </main>
  )
}

export default withRouter<any, any>(connect(mapStateToProps)(Main))
