import * as React from 'react'
import i18n from 'i18next'
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";

interface OwnStateProps {
  hasError: boolean
}

export class ContainerComponent extends React.Component<{}, OwnStateProps> {
  constructor(props: {}) {
    super(props)
    this.state = { hasError: false }
  }

  public componentDidCatch(
    error: Error,
    info: {
      componentStack: string
    }
  ) {
    this.setState({ hasError: true })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="inplant-maintenance-fe">
          <Row horizontalAlignment={"center"}>
            <Column xs={10} md={8} lg={7} xl={6}>
              <div className="alert alert-warning alert-internal-error my-5" role="alert">
                <h2>{i18n.t('maintenance.errors.somethingGoneWrong')}</h2>
                <p>{i18n.t('maintenance.errors.contactOurTeam')}</p>
              </div>
            </Column>
          </Row>
        </div>
      )
    }
    return <div className="inplant-maintenance-fe">{this.props.children}</div>
  }
}
