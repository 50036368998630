import * as React from 'react'
import {
  Brush,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import * as moment from 'moment'
import {CustomizedTick} from '../CustomizedTick/CustomizedTick'
import {withTranslation, WithTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {fetchPlanStatusOverTime} from '../../../../redux/actions'
import {Alert, Card, CardHeader, Loader} from '@mv-submodules/inplant-components-fe'
import DownloadDataButton
  from '@mv-submodules/inplant-components-fe/ui/components/DownloadDataButton/DownloadDataButton'
import {getDateRangeFiltering} from '@mv-submodules/inplant-core-fe/functions/date'
import {saveDataAsCSV} from "@mv-submodules/inplant-core-fe/functions";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";

export const AlertNoData = ({message}: {message: string}) => <Alert type={"warning"}>{message}</Alert>

export const timeRangeFileString = ( dateStart: string | null, dateEnd: string | null ): string => {
  return (
    (dateStart ? '_' + moment(dateStart).format('YYYY-MM-DD') : '') +
    (dateEnd ? '_' + moment(dateEnd).format('YYYY-MM-DD') : '')
  )
}

export interface OwnProps {
  range: string
  // t: TranslationFunction
}


export interface OwnState {
  graphData: any
  lastUpdate: number | null
  plantStatus: {
    overLegends: string[]
  }
}

export interface StateProps {
  dateEnd: string | null
  dateStart: string | null
  graphData: any
  isLoading: boolean
  lastDateUpdate: number | null
  measureUnits: object
}

export interface DispatchProps {
  fetchPlanStatusOverTime: () => Function
}

const stateLabels = {
  1: 'TPE',
  2: 'TPLMNT',
  3: 'TPLON',
  4: 'TPLRDY',
  5: 'TPLUP',
  6: 'TPLWU',
  7: 'TPP',
  8: 'TPROFF',
  9: 'TPRON',
  10: 'TPS',
  11: 'TSON',
  12: 'TPRNC',
}

const stateLabelsOrder = {
  9: 1,
  7: 2,
  1: 3,
  3: 4,
  4: 5,
  6: 6,
  10: 7,
  8: 8,
  5: 9,
  2: 10,
  11: 11,
  12: 12,
}

const stateLabelsOrdered = {
  1: 'TPRON',
  2: 'TPP',
  3: 'TPE',
  4: 'TPLON',
  5: 'TPLRDY',
  6: 'TPLWU',
  7: 'TPS',
  8: 'TPROFF',
  9: 'TPLUP',
  10: 'TPLMNT',
  11: 'TSON',
  12: 'TPRNC',
}

const stateColors = {
  9: 'green',
  1: 'green',
  7: 'orange',
  3: 'yellow',
  4: 'yellow',
  6: 'yellow',
  10: 'red',
  8: 'blue',
  5: 'blue',
  2: 'blue',
  11: 'blue',
  12: 'green',
}

export type Props = OwnProps & DispatchProps & StateProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  dateEnd: state.dateFilters.dateEnd,
  dateStart: state.dateFilters.dateStart,
  graphData: state.planStatusOverTime.data,
  isLoading: state.planStatusOverTime.isLoading,
  lastDateUpdate: state.dateFilters.lastUpdate,
  measureUnits: state.config.generic.measureUnits || {}
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  fetchPlanStatusOverTime: () => dispatch(fetchPlanStatusOverTime()),
})

class PlantStatusOverTime extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      graphData: null,
      lastUpdate: null,
      plantStatus: {
        overLegends: [],
      },
    }

    this.handlePlantStatusLegendEnter = this.handlePlantStatusLegendEnter.bind(this)
    this.handlePlantStatusLegendLeave = this.handlePlantStatusLegendLeave.bind(this)
    this.isLabelActive = this.isLabelActive.bind(this)
    this.renderTooltip = this.renderTooltip.bind(this)
    this.validDateRange = this.validDateRange.bind(this)
  }

  public componentWillMount(): void {
    if (this.validDateRange(this.props)) {
      this.props.fetchPlanStatusOverTime()
    }
  }

  public componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    let lastDateUpdate = this.state.lastUpdate
    let doUpdate = false

    if (nextProps.lastDateUpdate !== null && lastDateUpdate !== nextProps.lastDateUpdate) {
      lastDateUpdate = nextProps.lastDateUpdate
      doUpdate = true
    }

    this.setState({
      graphData:
        nextProps.graphData &&
        nextProps.graphData.map((d: any) => {
          return {
            time: moment(d[0]).unix(),
            value: d[1],
            order: stateLabelsOrder[d[1]],
            h100: 100,
          }
        }),
      lastUpdate: lastDateUpdate,
    })

    if (doUpdate) {
      if (this.validDateRange(nextProps)) {
        this.props.fetchPlanStatusOverTime()
      }
    }
  }

  public render() {
    const {dateStart, dateEnd} = this.props
    const dataLabels =
      this.state.graphData && this.state.graphData.length > 0
        ? Object.keys(this.state.graphData[0]).filter(e => e !== 'time' && e !== 'order' && e !== 'h100')
        : []
    let downloadData: string[][] = this.state.graphData
      ? this.state.graphData.map((e: any) => {
        return [moment.unix(e.time).format('YYYY/MM/DD HH:mm:ss'), e.value, stateLabelsOrdered[e.value]]
      })
      : []

    downloadData = [
      ['time', ...dataLabels, 'label'].map((c: string) => `${c}${this.props.measureUnits[c] ? ` (${this.props.measureUnits[c]})` : ''}`),
    ].concat(downloadData)
    const {days} = dateStart && dateEnd && getDateRangeFiltering(dateStart, dateEnd) || {days: null}

    return (
      <Row spacing={{horizontal:false,vertical:false}}>
        <Column>
          <Card marginBottom={3}>
            <CardHeader>
              {this.props.t('plantStatusOverTime.title')}
              {downloadData && (days && days <= 8) && (
                <DownloadDataButton
                  saveDataAsCSV={saveDataAsCSV}
                  data={downloadData}
                  type={'csv'}
                  label={'i4efficiency.download.buttonLabel'}
                  className={'float-right'}
                  fileName={
                    this.props
                      .t('plantStatusOverTime.title')
                      .toLowerCase()
                      .replace(' ', '_') + timeRangeFileString(this.props.dateStart, this.props.dateEnd)
                  }
                  addTime={false}
                />
              )}
            </CardHeader>

            {/* @todo refactoring */}
            <div className="card-body plant-over-time pb-3" style={{width: '100%', height: 400, paddingBottom: 50}}>
              {this.props.isLoading ? (
                <Loader/>
              ) : days && days <= 8 ? (
                this.state.graphData ? (
                  <React.Fragment>
                    <ResponsiveContainer maxHeight={330}>
                      <ComposedChart
                        data={this.state.graphData}
                        margin={{top: 20, bottom: 20, left: 10, right: 10}}
                        syncId="syncId"
                      >
                        <XAxis
                          dataKey="time"
                          tickMargin={10}
                          type={'number'}
                          domain={['dataMin', 'dataMax']}
                          tickFormatter={(timeStr: string) => {
                            return moment.unix(parseInt(timeStr, 10)).format('DD/MM HH:mm')
                          }}
                          tickCount={20}
                        />
                        <YAxis
                          type={'number'}
                          width={60}
                          ticks={Object.keys(stateLabels).map(l => parseInt(l, 10))}
                          tickMargin={20}
                          domain={[1, 11]}
                          interval={0}
                          reversed={true}
                          tick={
                            <CustomizedTick labels={stateLabelsOrdered} active={this.state.plantStatus.overLegends}/>
                          }
                        />
                        <CartesianGrid stroke="#eee"/>
                        <Line type="stepAfter" dataKey="order" stroke="#A45A4D" dot={false} strokeWidth={1.5}/>
                        <Tooltip content={this.renderTooltip}/>

                        <Brush
                          dataKey="time"
                          height={30}
                          stroke="#8884d8"
                          tickFormatter={(timeStr: string) => {
                            return moment.unix(parseInt(timeStr, 10)).format('DD/MM HH:mm')
                          }}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                    <ResponsiveContainer maxHeight={15}>
                      <ComposedChart
                        data={this.state.graphData}
                        margin={{top: 20, bottom: 20, left: 10, right: 10}}
                        syncId="syncId"
                      >
                        <XAxis
                          dataKey="time"
                          tickMargin={0}
                          type={'number'}
                          domain={['dataMin', 'dataMax']}
                          tick={false}
                          tickCount={20}
                          height={0}
                        />
                        <YAxis
                          type={'number'}
                          width={60}
                          ticks={Object.keys(stateLabels).map(l => parseInt(l, 10))}
                          tickMargin={0}
                          domain={[0, 20]}
                          interval={0}
                          reversed={true}
                          tick={false}
                        />

                        {this.state.graphData.map((entry: any, index: number) => {
                          const nextTime = this.state.graphData[
                            index === this.state.graphData.length - 1 ? this.state.graphData.length - 1 : index + 1
                            ].time
                          return (
                            <ReferenceArea
                              key={index}
                              x1={entry.time}
                              x2={nextTime}
                              y1={0}
                              y2={20}
                              fill={stateColors[entry.value] || 'gray'}
                              fillOpacity={[null, true].includes(this.isLabelActive(String(entry.value))) ? 1 : 0.3}
                              stroke="#ddd"
                              strokeOpacity={1}
                            />
                          )
                        })}
                      </ComposedChart>
                    </ResponsiveContainer>
                  </React.Fragment>
                ) : (
                  <AlertNoData message={this.props.t('plantStatusOverTime.fetch.noData')}/>
                )
              ) : (
                <AlertNoData message={this.props.t('plantStatusOverTime.timeRange')}/>
              )}
            </div>

            {/* @todo refactoring */}
            <div className="card-footer text-muted small plant-over-time__legend">
              <div className="legend row">
                {Object.keys(stateLabels).map(e => (
                  <Column md={2}>
                    <div
                      className={(this.isLabelActive(e) === null ? '' : this.isLabelActive(e) ? 'status-on' : 'status-off')}
                      key={e}
                      onMouseEnter={this.handlePlantStatusLegendEnter}
                      onMouseLeave={this.handlePlantStatusLegendLeave}
                      data-color={stateColors[e]}
                    >
                      <span className={'legend-color legend-color-block legend-' + stateColors[e] + '-color'}/>
                      <strong>{stateLabels[e]}</strong>: {this.props.t('plantStatusOverTime.labels.' + stateLabels[e])}
                    </div>
                  </Column>
                ))}
              </div>
            </div>
          </Card>
        </Column>
      </Row>
    )
  }

  private isLabelActive(k: string) {
    return this.state.plantStatus.overLegends.length === 0 ? null : this.state.plantStatus.overLegends.includes(k)
  }

  private validDateRange(props: Props) {
    const {dateStart, dateEnd} = props
    const {days} = dateStart && dateEnd && getDateRangeFiltering(dateStart, dateEnd) || {days: null}

    return days && days <= 8
  }

  private renderTooltip(a: any) {
    const data = this.state.graphData && this.state.graphData.find((e: any) => e.time === a.label)

    return (
      <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom">
        <div
          className="recharts-default-tooltip"
          style={{
            margin: 0,
            padding: 10,
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid rgb(204, 204, 204)',
            whiteSpace: 'nowrap',
          }}
        >
          <p className="recharts-tooltip-label time-label" style={{margin: 0}}>
            {moment.unix(a.label).format('D/M/YYYY HH:mm')}
          </p>

          <p>
            {data ? (
              <React.Fragment>
                <strong>{stateLabels[data.value]}</strong>
                <br/>
                {this.props.t('plantStatusOverTime.labels.' + stateLabels[data.value])}
              </React.Fragment>
            ) : (
              ''
            )}
          </p>
        </div>
      </div>
    )
  }

  private handlePlantStatusLegendEnter(event: React.MouseEvent<HTMLDivElement>) {
    const overLegends: string[] = []

    if (event !== null) {
      const color = event.currentTarget.dataset.color

      if (color !== null) {
        Object.keys(stateColors).filter((k: string) => {
          if (stateColors[k] === color) {
            overLegends.push(k)
          }
        })
      }
    }

    this.setState({
      plantStatus: {
        overLegends,
      },
    })
  }

  private handlePlantStatusLegendLeave() {
    this.setState({
      plantStatus: {
        overLegends: [],
      },
    })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PlantStatusOverTime))
