import * as React from 'react'
import {connect} from 'react-redux'
import {fetchConsumptionData} from '../../../../redux/actions/graphs'
import {withRouter} from 'react-router'
import {History} from 'history'
import Button from '@mv-submodules/inplant-environment-fe-imel/ui/components/widgets/Button/Button'
import {withTranslation, WithTranslation} from 'react-i18next'
import {Card, CardBody, CardFooter, CardHeader} from '@mv-submodules/inplant-components-fe';

interface ButtonsConsumptionsStateProps {
  hasErrored: boolean
  isLoading: boolean
  lastDateUpdate: number | null
  ActivePower: number
  CarbonFootprint: number
  ComprimedAir: number
  Gas: number
  Paint: number
  RawWater: number
  DemiWater: number
  ThermalEnergy: number
  ChemicalsPaiting: number | null
  consumptionData: any,
  plantMetrics: []
}

interface ButtonsConsumptionsDispatchProps {
  fetchData: () => Function
}

interface ButtonsConsumptionsOwnProps {
  history: History
  match: any
  // t: TranslationFunction
}

interface ButtonsConsumptionsOwnState {
  lastUpdate: number | null
  selected: any
  section: number
}

/* tslint:disable:no-string-literal */
const mapStateToProps = (state: any): ButtonsConsumptionsStateProps => ({
  hasErrored: state.widgetsHasErrored,
  isLoading: state.widgetsIsLoading,
  lastDateUpdate: state.dateFilters.lastUpdate,
  ActivePower: state.graphConsumptionData.data['ActivePower'],
  CarbonFootprint: state.graphConsumptionData.data['CarbonFootprint'],
  ComprimedAir: state.graphConsumptionData.data['ComprimedAir'],
  Gas: state.graphConsumptionData.data['Gas'],
  Paint: state.graphConsumptionData.data['Paint'],
  RawWater: state.graphConsumptionData.data['RawWater'],
  DemiWater: state.graphConsumptionData.data['DemiWater'],
  ThermalEnergy: state.graphConsumptionData.data['ThermalEnergy'],
  ChemicalsPaiting: null,
  consumptionData: state.graphConsumptionData.data,
  plantMetrics: state.config.generic.plantMetrics || [],
})
/* tslint:enable:no-string-literal */

const mapDispatchToProps = (dispatch: Function): ButtonsConsumptionsDispatchProps => ({
  fetchData: () => dispatch(fetchConsumptionData()),
})

type ButtonsConsumptionsProps = ButtonsConsumptionsStateProps &
  ButtonsConsumptionsDispatchProps &
  ButtonsConsumptionsOwnProps &
  WithTranslation

class ButtonsConsumptions extends React.Component<ButtonsConsumptionsProps, ButtonsConsumptionsOwnState> {
  constructor(props: ButtonsConsumptionsProps) {
    super(props)
    this.state = {
      lastUpdate: null,
      selected: this.props.match.params.id ? [this.props.match.params.id] : [],
      section: 0,
    }

    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.changeSection = this.changeSection.bind(this)
  }

  public componentWillMount() {
    this.props.fetchData()
  }

  public componentWillReceiveProps(newProps: ButtonsConsumptionsStateProps) {
    if (this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
      this.props.fetchData()
    }
  }

  public handleButtonClick(e: string, section: number) {
    this.props.history.push('/i4environment/' + e + '?section=' + section)
  }

  private changeSection(section: number) {
    this.setState({section})
  }

  public render() {
    if (this.props.hasErrored) {
      return <p>Sorry! There was an error loading data</p>
    }

    if (this.props.isLoading) {
      return <p>Loading…</p>
    }

    const buttons =
      this.props.plantMetrics &&
      this.props.plantMetrics.length > 0 &&
      this.props.plantMetrics.map((e: any) => {
        return {
          label: e.label,
          data: e.data[0].measures,
        }
      })

    return (
      <div className="DashboardWidget WidgetButtons WidgetH50 cliccable">
        <Card marginBottom={3}>
          <CardHeader>{this.props.t('i4efficiency.cardTitle.avgDailyConsumption')}</CardHeader>
          <CardBody>
            <div className="container chart-container">
              <div className={"text-center"}>
                {buttons &&
                buttons.length > 0 &&
                buttons.map((buttonsGroup: any, section: number) => (
                  <div
                    className={'buttons-section' + (this.state.section === section ? ' active' : '')}
                    key={section}
                  >
                    <a className="button-header" onClick={() => this.changeSection(section)}>
                      {this.props.t('i4environment.sections.' + buttonsGroup.label)}
                    </a>
                    <div className="buttons-section-content">
                      {this.state.section === section && (
                        <div className="btn-group-vertical">
                          {buttonsGroup &&
                          buttonsGroup.data &&
                          buttonsGroup.data.map(
                            (bg: { memory: string; name: string; unit: string }, index: number) => (
                              <Button
                                selected={false}
                                name={bg.name}
                                unit={bg.unit}
                                value={
                                  this.props.consumptionData.hasOwnProperty(bg.name)
                                    ? this.props.consumptionData[bg.name]
                                    : ''
                                }
                                clickCallback={() => this.handleButtonClick(bg.name, section)}
                                key={index}
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CardBody>
          <CardFooter
            textSizeSmall={true}
            textAlign={'right'}
            textMuted={true}
          >&nbsp;</CardFooter>
        </Card>
      </div>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ButtonsConsumptions))
)
