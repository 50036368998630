const en = {
  i4efficiency: {
    module: {
      "overview": "Overview",
      "oee-details": "Details",
      "availability-details": "Availability details",
    },
    download: {
      buttonLabel: 'DOWNLOAD',
      buttonLabelData: 'Data',
      buttonLabelAlarms: 'Alarms',
    },
    fetch: {
      noData: 'No data available',
    },
    cardTitle: {
      avgDailyConsumption: 'AVERAGE DAILY CONSUMPTION',
      avaibility: 'AVAILABILIY',
      runTimeOperatingTime: 'RUN TIME / OPERATING TIME',
      oee: 'OEE',
      quality: 'QUALITY'
    },
    quality: {
      scraps: {
        time: 'time',
        quality: 'Quality',
        Scrap1: 'Neri',
        Scrap2: 'Con segni',
        Scrap3: 'Alette rotte',
        Scrap4: 'Marci',
        Scrap5: 'Sporchi anaf.',
        Scrap6: 'Bolle scoppiate',
        pl: 'Pezzi caricati',
      },
      total: 'Total scraps',
    },
    labels: {
      qta: 'Qty',
      min: 'min',
    },
    performance: {
      performance: 'Performance',
      pieces: 'Pieces'
    },
    modal: {
      labels: {
        close: 'Close'
      },
      alarms: 'ALARMS'
    }
  },
}

export default en
