import { AnyAction } from 'redux'
import moment from 'moment'

const initialState = {
  year: moment().get('year')
}

interface SetupState {
  year: number
}

type SetupReducer = (
  state: SetupState,
  action: AnyAction
) => SetupState

export const setup: SetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_YEAR':
      return {
        ...state,
        year: action.year
      }
    default:
      return state
  }
}