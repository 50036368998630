import { createStore, applyMiddleware, Middleware, compose, combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import reducers from '@mv-submodules/inplant-config/config/reducers'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

const middlewares: Middleware[] = [thunk]

const persistConfig = {
  key: 'inplant',
  storage,
  blacklist: ['checklist', 'maintenance', 'plantHalts','designer'],
}

const persistedReducer = persistReducer(persistConfig, combineReducers(reducers))

const configureStore = (initialState?: any) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  return createStore(persistedReducer, initialState || {}, composeEnhancers(applyMiddleware(...middlewares)))
}

const store = configureStore()

export default store
