import { I4EnvironmentState, initialI4EnvironmentState } from '../index'
import { AnyAction } from 'redux'

export const graphI4EnvironmentData = (state: I4EnvironmentState = initialI4EnvironmentState, action: AnyAction) => {
  switch (action.type) {
    case 'FETCH_I4ENVIRONMENT_SUCCESS':
      return {
        ...state,
        data: action.data.results[0].series[0].values,
        columns: action.data.results[0].series[0].columns,
        lastUpdate: action.lastDateUpdate,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_I4ENVIRONMENT_REQUEST':
      return {
        ...state,
        isLoading: action.isLoading,
        hasErrored: action.hasErrored,
      }

    case 'FETCH_I4ENVIRONMENT_ERROR':
      return {
        ...state,
        hasErrored: action.hasErrored,
      }

    case 'GRAPH_I4ENVIRONMENT_INITIALIZED':
      return {
        ...state,
        graphInitialized: action.graphInitialized,
      }

    case 'CHANGE_RANGE':
      return state

    default:
      return state
  }
}
