// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import '../../../styles/_index.scss'
import IconComponent from "@mv-submodules/inplant-components-fe/ui/components/MVIcon/Icon";

export interface TreeViewConfig {
  alwaysOpen: string[]
  notCliccableLevel: string[]
}

export interface TreeViewProps {
  data: any
  currentSelection: any | null
  onClick: Function
  options?: TreeViewConfig
  height: 'small' | 'medium' | 'large'
}

export interface TreeViewOwnState {
  data: any
}

const capitalizeFirstLetter = (value: any) => {
  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  return value
}

export default class PlantTreeView extends React.Component<TreeViewProps, TreeViewOwnState>{
  constructor(props: TreeViewProps) {
    super(props)

  }

  public render() {
    return (
      <div className={`treeview-container height-${this.props.height}`}>
        <PlantTreeViewComponent {...this.props} />
      </div>
    )
  }
}

class PlantTreeViewComponent extends React.Component<TreeViewProps, TreeViewOwnState> {
  constructor(props: TreeViewProps) {
    super(props)

    this.handleClickToggle = this.handleClickToggle.bind(this)

    this.state = {
      data: props.data,
    }
  }

  public componentWillReceiveProps(newProps: TreeViewProps) {
    if (newProps.data !== this.props.data) {
      this.setState({
        data: newProps.data,
      })
    }
  }

  public toggleStatus(data: any, id: any) {
    if (data.data && data.data.id === id) {
      data.open = !data.open
    }
    for (const ii in data) {
      if (typeof data[ii].data === 'object') {
        this.toggleStatus(data[ii], id)
      }
    }
  }

  public handleClickToggle(item: any, e: React.MouseEvent<HTMLSpanElement>) {
    e.stopPropagation()
    const state = Object.assign({}, this.state)
    this.toggleStatus(state.data, item.data.id)
    this.setState(state)
  }

  public render() {
    const thisComp = this
    if (!thisComp.state.data) {
      return null
    }

    const { currentSelection, options } = this.props
    return (
      <ul className="PlantTreeViewComponent">
        {thisComp.state.data &&
        thisComp.state.data.map((item: any) => {
          const isSelected = currentSelection && currentSelection.data && currentSelection.data.id === item.data.id
          const isOpened = item.open || (options && options.alwaysOpen.includes(item.level))
          const isCliccable = !options || options && !options.notCliccableLevel.includes(item.level)
          return (
            <li
              key={item.data.id}
              className={[
                isOpened ? 'opened' : '',
                item.children ? 'has-childrens' : 'no-childrens',
                isSelected ? 'selected' : '',
                !isCliccable ? 'not-cliccable' : '',
              ].join(' ')}
            >
              {item.children ? (
                <span onClick={e => thisComp.handleClickToggle(item, e)} className="icon">
                    <IconComponent icon={"chevron-right"}/>
                  </span>
              ) : (
                <span className="icon">
                    <IconComponent icon={"circle"}/>
                  </span>
              )}{' '}
              {isCliccable ? (
                <span className="name" onClick={e => this.props.onClick(item)}>
                  {item.data.pid && <strong>[ {item.data.pid} ] </strong>}
                  {capitalizeFirstLetter(item.data.name)}
                </span>
              ) : (
                <span className="name">
                  {item.data.pid && <strong>[ {item.data.pid} ] </strong>}
                  {capitalizeFirstLetter(item.data.name)}
                </span>
              )}
              <PlantTreeViewComponent {...this.props} data={item.children}/>
            </li>
          )
        })}
      </ul>
    )
  }
}
