import IconComponent from '../../../../inplant-components-fe/ui/components/MVIcon/Icon'
import * as React from 'react'
import File from './File'

export interface StateProps {
  data: any
}

export interface OwnProps {
  data: any
  folderName: string
  selectedFile: any
  selectFile: (fileData: any) => Function
}

export interface OwnState {
  open: boolean
}

export type Props = StateProps & OwnProps

class Folder extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      open: false,
    }

    this.toggleFolder = this.toggleFolder.bind(this)
  }

  public render() {
    const folders = Object.keys(this.props.data).filter(e => e !== 'files')
    const files = this.props.data.files

    return (
      <li className={'list-group-item list-folder ' + (this.state.open ? 'open' : '')} onClick={this.toggleFolder}>
        {this.state.open && <IconComponent icon={'folder-open'} />}
        {!this.state.open && <IconComponent icon={'folder'} />}{' '}
        <span className="folder-name">{this.props.folderName}</span>
        {folders && folders.length > 0 && (
          <ul className="list-group list-group-flush">
            <div className="list-folders">
              {folders.sort().map((folderName: string) => (
                <Folder
                  data={this.props.data[folderName]}
                  folderName={folderName}
                  key={folderName}
                  selectedFile={this.props.selectedFile}
                  selectFile={this.props.selectFile}
                />
              ))}
            </div>
          </ul>
        )}
        {files && (
          <ul className="list-group list-group-flush">
            <div className="list-files">
              {files
                .filter((file: any) => file.Size !== 0)
                .sort()
                .map((file: any) => (
                  <File
                    data={file}
                    key={file.ETag + file.Key}
                    selectedFile={this.props.selectedFile}
                    selectFile={this.props.selectFile}
                  />
                ))}
            </div>
          </ul>
        )}
      </li>
    )
  }

  private toggleFolder(e: React.MouseEvent<HTMLLIElement>) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.setState({ open: !this.state.open })
  }
}

export default Folder
