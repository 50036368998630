import { AnyAction } from 'redux'
import { cloneDeep } from 'lodash'
import { MaintenancePageFilters, MaintenancePageFiltersStateProps } from '@mv-submodules/inplant-maintenance-fe/types/pageFilters'

const pageFiltersInitialState = {
  fetching: false,
  error: null,
  filters: {},
}

export const pageFilters = (state: MaintenancePageFiltersStateProps = pageFiltersInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'MAINTENANCE_PAGE_FILTERS_IS_FETCHING':
      return cloneDeep({
        ...state,
        fetching: true,
        error: null,
      })

    case 'MAINTENANCE_PAGE_FILTERS_FETCH_SUCCESS':
      const newState = cloneDeep(state)
      if (typeof state.filters[action.payload.slug] === 'undefined') {
        newState.filters[action.payload.slug] = action.payload.filters.filter((filter: any) => {
          return filter.type === 'client'
        })
      }
      newState.fetching = false
      newState.error = null
      return newState

    case 'MAINTENANCE_PAGE_FILTERS_FETCH_ERROR':
      return cloneDeep({
        ...state,
        fetching: false,
        error: action.payload,
      })

    case 'MAINTENANCE_PAGE_FILTERS_SET_SELECTED_VALUES':
      if (typeof state.filters[action.payload.slug] !== 'undefined') {
        console.log(  //tslint:disable-line
          action.payload.slug,
          state.filters
        )
        const index = state.filters[action.payload.slug].findIndex((item: MaintenancePageFilters) => {
          return item.name === action.payload.name
        })
        if (index >= 0) {
          const newState = cloneDeep(state) // tslint:disable-line
          newState.filters[action.payload.slug][index].selected = [...action.payload.selected]
          return newState
        }
      }

      return state

    default:
      return state
  }
}
