// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { Ref } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Checkbox from './Checkbox'
import InvalidFeedback from './InvalidFeedback'


export interface CheckOption {
  value: any
  label: string
}

interface Props<T> {
  id?: string
  label: string
  name?: string
  value?: T
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  multiple?: boolean
  inline?: true
  inlineClassName?: string
  options: CheckOption[]
  error?: string
  internalRef: React.RefObject<HTMLInputElement>
  onChange: (value: T) => void
}

class CheckListInternal extends React.Component<Props<string | string[]>, any> {
  constructor(props: Props<string | string[]>) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      values: new Set(props.value || []),
    }
  }

  private handleChange(currentTarget: HTMLInputElement) {
    const props = this.props
    if (props.multiple) {
      if (currentTarget.checked) {
        this.state.values.add(currentTarget.value) // improve this
      } else {
        this.state.values.delete(currentTarget.value)
      }
      props.onChange(Array.from(this.state.values))
    } else {
      props.onChange(currentTarget.value)
    }
  }

  public render() {
    const props = this.props
    let className = 'form-check-input'
    if (props.error != null) {
      className += ' is-invalid'
    }
    const getOptionId = (option: CheckOption): string =>
      `${props.id || props.name}_${option.value}`
    const isOptionChecked = (option: CheckOption): boolean =>
      props.value != null && props.multiple
        ? props.value.indexOf(option.value) >= 0
        : props.value === option.value

    // input with type text is used to validate the undelying list of checkboxes. The min value of 1 was chooses because was needed at least one selected for validation.
    // It can be a good idea to make this customizable with props
    return (
      <div className="form-group">
        {props.label && (
          <label htmlFor={props.id}>
            {props.label}
            {props.required && <abbr className="text-danger ml-1">*</abbr>}
            {props.required && (
              <input
                type="text"
                value={
                  this.state.values.size === 0 ? '' : this.state.values.size
                }
                style={{ display: 'none' }}
                required={true}
                min="1"
                onChange={e => e}
                ref={this.props.internalRef}
              />
            )}
          </label>
        )}
        <div className={props.inline && 'row'}>
          {props.options.map(option => (
            <div
              key={option.value}
              className={(props.inline && props.inlineClassName) || 'col mr-3'}
            >
              <Checkbox
                id={getOptionId(option)}
                className={className}
                label={option.label}
                name={props.name}
                value={option.value}
                checked={isOptionChecked(option)}
                disabled={props.disabled}
                readOnly={props.readOnly}
                multiple={props.multiple}
                onChange={this.handleChange}
              />
            </div>
          ))}
        </div>
        {props.error && <InvalidFeedback error={props.error} />}
      </div>
    )
  }
}

const CheckList = React.forwardRef(
  (props: any, ref: Ref<HTMLInputElement>) => {
    return <CheckListInternal {...props} internalRef={ref} />
  }
)

export default CheckList
