export interface MaintenancesProps {
  showTitle: boolean
  loading: boolean
  ready: boolean
  data: {
    rows: Object[]
    componentPath: string
  }
}

export enum MaintenanceJobPeriodicity {
  '1_DAY' = 1,
  '1_WEEK' = 7,
  '2_WEEKS' = 14,
  '1_MONTH' = 30,
  '2_MONTHS' = 60,
  '3_MONTHS' = 90,
  '6_MONTHS' = 180,
  '1_YEAR' = 365,
  '2_YEARS' = 730,
}

export const periodLabel = (period: string): string => {
  const label = Object.keys(MaintenanceJobPeriodicity).find((key: string) => MaintenanceJobPeriodicity[key] === period)

  if (label) {
    return `moduleSmartdocs.maintenance.setup.periodicity.${label}`
  }

  return ''
}
