import * as React from 'react'
import FlagIcon from './FlagIcon'
import { i18nLabels } from '../../../types/i18n'
import { withTranslation, WithTranslation } from 'react-i18next'
import FileBrowserModal from '../FileBrowser/FileBrowserModal'
import { prepareAttachment, setFileLanguage } from '../shared'
import { fetchFileData } from '../../../store/actions'
import { connect } from 'react-redux'
import { FileAttachment } from '../../../types/media'
import AttachmentTranslationModal from './AttachmentTranslationModal'
import { popupNotification } from '@mv-submodules/inplant-core-fe/functions/notifications'
import { createDownloadTempLink } from '../shared'
import IconComponent from '../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface OwnProps {
  documents: FileAttachment[]
  enableAdd: boolean
  // t: TranslationFunction
  update: ( documents: any[] ) => void
}

export interface DispatchProps {
  fetchFileData: ( fileKey: string ) => Promise<any>
}

export interface State {
  currentFile: null | FileAttachment
  documents: FileAttachment[]
  showFileBrowser: boolean
  showTranslationModal: boolean
}

export const mapDispatchToProps = ( dispatch: Function ) => ({
  fetchFileData: ( fileKey: string ) => dispatch(fetchFileData(fileKey)),
})

export type Props = OwnProps & DispatchProps & WithTranslation

class Attachments extends React.Component<Props, State> {
  public constructor( props: Props ) {
    super(props)

    this.state = {
      currentFile: null,
      documents: this.props.documents,
      showFileBrowser: false,
      showTranslationModal: false,
    }

    this.addFile = this.addFile.bind(this)
    this.closeAttachmentTranslationModal = this.closeAttachmentTranslationModal.bind(this)
    this.closeFileBrowser = this.closeFileBrowser.bind(this)
    this.downloadFile = this.downloadFile.bind(this)
    this.editFileLabel = this.editFileLabel.bind(this)
    this.removeFile = this.removeFile.bind(this)
    this.saveAttachmentTranslations = this.saveAttachmentTranslations.bind(this)
    this.changeAttachmentAttribute = this.changeAttachmentAttribute.bind(this)
    this.setFileLanguageHandler = this.setFileLanguageHandler.bind(this)
    this.showFileBrowser = this.showFileBrowser.bind(this)
  }

  public componentWillReceiveProps( nextProps: Readonly<Props>, nextContext: any ): void {
    this.setState({
      documents: nextProps.documents,
    })
  }

  public render() {
    const { t } = this.props
    const { currentFile, showFileBrowser, showTranslationModal, documents } = this.state

    return (
      <React.Fragment>
        {showFileBrowser && (
          <FileBrowserModal
            isVisible={showFileBrowser}
            closeModal={this.closeFileBrowser}
            addFile={this.addFile}
          />
        )}

        {showTranslationModal && currentFile && (
          <AttachmentTranslationModal
            file={currentFile}
            closeModal={this.closeAttachmentTranslationModal}
            saveTranslation={this.saveAttachmentTranslations}
          />
        )}

        <div className="form-attachments">
          {documents && documents.length > 0 && (
            <React.Fragment>
              <ul className="list-group">
                {documents.map(( file: FileAttachment ) => (
                  <li className="list-group-item" key={file.link}>
                    {((file.hasOwnProperty('translations') && Object.keys(file.translations).length > 0) ||
                      (file.label && file.label.length > 0)) && (
                      // <IconComponent icon={'language'} title={t('designer.media.hasLabelOrTranslation')}/>
                      <IconComponent icon={'language'} />
                    )}
                    <span
                      className="file-name"
                      title={
                        (file.name.indexOf('/') > -1 ? file.name.split('/').pop() : file.name) +
                        ' - ' +
                        t('designer.media.clickToEdit')
                      }
                      onClick={() => this.editFileLabel(file)}
                    >
                      {(file.label
                          ? file.label
                          : (file.name.indexOf('/') > -1 ? file.name.split('/').pop() : file.name) + ''
                      ).substr(0, 60)}
                    </span>
                    <span className="file-type">{t('designer.media.types.' + file.documentType)}</span>
                    <span className="file-version">
                      <input
                        type="text"
                        className="file-version form-control form-control-sm"
                        value={file.version}
                        placeholder={t('designer.media.placeholder.version')}
                        onChange={e => this.changeAttachmentAttribute(e, file, 'version')}
                        onBlur={() => this.props.update(documents)}
                      />
                    </span>
                    <span className="file-langs">
                      <span
                        className={
                          'file-lang-single ' +
                          (file.languages && file.languages.length > 0 ? 'has-translations ' : '') +
                          (file.languages && file.languages.indexOf('en') > -1 ? 'enabled' : '')
                        }
                        onClick={() =>
                          this.setFileLanguageHandler(file.link, 'en')
                        }
                      >
                        <FlagIcon code={i18nLabels.en}/>
                      </span>
                      <span
                        className={
                          'file-lang-single ' +
                          (file.languages && file.languages.length > 0 ? 'has-translations ' : '') +
                          (file.languages && file.languages.indexOf('it') > -1 ? 'enabled' : '')
                        }
                        onClick={() =>
                          this.setFileLanguageHandler(file.link, 'it')
                        }
                      >
                        <FlagIcon code={i18nLabels.it}/>
                      </span>
                    </span>
                    <span className="file-list-action" onClick={() => this.removeFile(file)}>
                      <IconComponent icon={'times'}/>
                    </span>
                    <span className="file-list-action" onClick={e => this.downloadFile(e, file)}>
                      <IconComponent icon={'download'}/>
                    </span>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
        </div>

        <div className="section-actions">
          <button
            className={'btn btn-secondary btn-sm float-right ml-3' + (!this.props.enableAdd ? ' hidden' : '')}
            onClick={this.showFileBrowser}
          >
            {t('designer.fileBrowser.modal.title')}
          </button>
        </div>
      </React.Fragment>
    )
  }

  private showFileBrowser( event: React.MouseEvent ) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showFileBrowser: true,
    })
  }

  private closeFileBrowser() {
    this.setState({
      showFileBrowser: false,
    })
  }

  private closeAttachmentTranslationModal() {
    this.setState({
      showTranslationModal: false,
      currentFile: null,
    })
  }

  private saveAttachmentTranslations( file: FileAttachment ) {
    const documents = [...this.state.documents]
    for (const i in documents) {
      if (documents[i].link === file.link) {
        documents[i] = file
      }
    }

    this.setState(
      {
        showTranslationModal: false,
        currentFile: null,
        documents,
      },
      () => this.props.update(this.state.documents),
    )
  }

  private changeAttachmentAttribute( e: any, file: FileAttachment, field: string ) {
    const documents = JSON.parse(JSON.stringify(this.state.documents))
    const fileIndex = documents.findIndex(( f: any ) => f.link === file.link)

    if (fileIndex > -1 && documents[fileIndex]) {
      documents[fileIndex][field] = e.currentTarget.value

      this.setState({
        documents,
      })
    }
  }

  private addFile( fileData: any, fileType: string ) {
    const documents = JSON.parse(JSON.stringify(this.state.documents))

    if (!this.state.documents.find(( e: any ) => e.link === fileData.Key)) {
      fileData.fileType = fileType
      documents.push(prepareAttachment(fileData))
    }

    this.setState(
      {
        showFileBrowser: false,
        documents,
      },
      () => this.props.update(this.state.documents),
    )
  }

  private removeFile( fileData: any ) {
    if (confirm(this.props.t('designer.confirm.removeAttachment'))) {
      const documents = JSON.parse(JSON.stringify(this.state.documents))
      const index = documents.findIndex(( file: any ) => {
        return file.link === fileData.link
      })

      if (index !== -1) {
        documents.splice(index, 1)
      }

      this.setState(
        {
          documents,
        },
        () => this.props.update(this.state.documents),
      )
    }
  }

  private downloadFile( e: React.MouseEvent<HTMLSpanElement>, fileData: any ) {
    if (e) {
      e.preventDefault()
    }

    this.props
    .fetchFileData(fileData.link)
    .then(( uniqueUrl: string ) => {
      createDownloadTempLink(uniqueUrl, fileData.link.split('/').pop(),'_blank')
      const tempLink = createDownloadTempLink(uniqueUrl, fileData.link.split('/').pop(),'_blank')

      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
    })
    .catch(() => {
      popupNotification({
        text: this.props.t('designer.error.download'),
      })
    })
  }

  private setFileLanguageHandler( link: string, lang: string ) {
    this.setState(
      {
        documents: setFileLanguage(link, lang, this.state.documents),
      },
      () => this.props.update(this.state.documents),
    )
  }

  private editFileLabel( currentFile: FileAttachment ) {
    this.setState({
      currentFile,
      showTranslationModal: true,
    })
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation()(Attachments))
