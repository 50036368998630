const en = {
  versionChecker: {
    confirmUpdate: 'Are you sure you want to update to new version? All unsaved work will be lost.',
    currentVersion: 'Current Version',
    newVersionTitle: 'New version',
    newVersion: 'There is a new version available. Please update.',
    pleaseUpdate: 'UPDATE',
    resetting: 'Please wait while updating...',
  },
  auth: {
    usernameLabel: 'Username',
    usernamePlaceholder: 'Enter your username...',
    passwordLabel: 'Password',
    passwordPlaceholder: 'Enter your password...',
    submitText: 'Login',
    logout: 'Logout',
  },
  api: {
    errors: {
      error: 'API Error',
      error_400: 'The server cannot or will not process the request due to an apparent client error',
      error_401: 'The user does not have the necessary credentials',
      error_403: 'The user might not have the necessary permissions for accessing the resource',
      error_404: 'The requested resource could not be found',
      error_405: 'The request method is not supported for the requested resource',
      error_406: 'Validation error',
      error_422: 'The request is unprocessable',
      error_500: 'The server failed to fulfil the request',
      error_501: 'The server failed to fulfil the request',
      error_502: 'The server failed to fulfil the request',
      error_503: 'The server is currently unreachable',
      error_504: 'The server failed to fulfil the request',
      error_505: 'The server failed to fulfil the request',
      error_509: 'The server failed to fulfil the request',
    },
  },
  plantSelectorWidget: {
    options: {
      disabled: 'Module is disabled for this plant',
      null: 'Select plant...',
    },
    plantChangedToAllowed:
      "I dati per l'impianto di {{fromPlant}} non sono disponibili.\nVengono visualizzati i dati dell'impianto di {{toPlant}}",
  },
}

export default en
