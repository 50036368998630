// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import moment from 'moment'

// * -------------------------------- MODULE --------------------------------------
import Button from '../Button/Button'
import { logError } from '../../../functions/log'

interface Props {
  className?: string
  onSubmit: () => Promise<any>
  onError: (customError?: any) => void
  submitLabel: string
  onCancel?: () => void
  cancelLabel: string
  moreButtons?: React.ReactNode
  children: React.ReactNode,
  isInsideModal?: boolean
}

interface OwnState {
  formRef: React.RefObject<HTMLFormElement>
  isSubmitting: boolean
  isValid: boolean
}

class Form extends React.Component<Props, OwnState> {
  private mounted: boolean | undefined

  constructor(props: Props) {
    super(props)
    this.state = {
      formRef: React.createRef(),
      isSubmitting: false,
      isValid: true,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.toggleSendButton = this.toggleSendButton.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
  }

  public componentWillUnmount() {
    this.mounted = false
  }

  public render() {
    const props = this.props
    const { formRef, isSubmitting, isValid } = this.state
    const childrenWithProps = React.Children.toArray(props.children)
      .filter(o => o)
      // @ts-ignore
      .map((child: React.ReactElement<any>) => {
        return React.cloneElement(child, { canSend: this.toggleSendButton })
      })

    return (
      <form
        ref={formRef}
        className={`needs-validation ${props.className}${
          !isValid ? ' was-validated' : ''
        }`}
        noValidate={true}
        onSubmit={this.handleSubmit}
      >
        <div className={`${props.isInsideModal && 'modal-body' || ''} form-fields`}>{childrenWithProps}</div>
        <div className={`${props.isInsideModal && 'modal-footer' || 'mt-3'} form-actions`}>
          {props.moreButtons}
          {props.onCancel && (
            <Button
              variant="secondary-alternate"
              onClick={props.onCancel}
              disabled={isSubmitting}
              label={props.cancelLabel}
            />
          )}
          <Button
            variant="primary"
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid}
            type="submit"
            label={props.submitLabel}
          />
        </div>
      </form>
    )
  }

  private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    this.submitForm()
  }

  public toggleSendButton(state: boolean) {
    // if state.isSubmitting is true, buttons are disabled
    this.setState({
      isSubmitting: !state,
    })
  }

  private async submitForm() {
    const isValid =
      this.state.formRef.current && this.state.formRef.current.checkValidity()
    const datesAreValid =
      this.state.formRef.current &&
      this.state.formRef.current.effectiveFromDate &&
      this.state.formRef.current.dueAtDate
        ? moment(
        this.state.formRef.current.effectiveFromDate.value,
        'DD-MM-YYYY',
        ).isBefore(
        moment(this.state.formRef.current.dueAtDate.value, 'DD-MM-YYYY'),
        )
        : true
    const repetitionDaysAreValid =
      this.state.formRef.current &&
      this.state.formRef.current['repetitionRule.days'] &&
      this.state.formRef.current['repetitionRule.days'].value
        ? this.state.formRef.current['repetitionRule.days'].value < 100000
        : true
    const notificationDaysAdvanceAreValid =
      this.state.formRef.current &&
      this.state.formRef.current.notificationDaysAdvance &&
      this.state.formRef.current.notificationDaysAdvance.value
        ? this.state.formRef.current.notificationDaysAdvance.value < 100000
        : true
    if (!isValid) {
      return this.props.onError()
    }
    if (!repetitionDaysAreValid) {
      return this.props.onError({
        name:
          'chronoframe.dueDates.formField.repetitionDaysValidationError.title',
        statusCode: 400,
        message:
          'chronoframe.dueDates.formField.repetitionDaysValidationError.description',
        errors: {
          ['repetitionRule.days']:
            'chronoframe.dueDates.formField.repetitionDaysValidationError.invalidField',
        },
      })
    }
    if (!notificationDaysAdvanceAreValid) {
      return this.props.onError({
        name:
          'chronoframe.dueDates.formField.repetitionDaysValidationError.title',
        statusCode: 400,
        message:
          'chronoframe.dueDates.formField.repetitionDaysValidationError.description',
        errors: {
          notificationDaysAdvance:
            'chronoframe.dueDates.formField.repetitionDaysValidationError.invalidField',
        },
      })
    }
    if (!datesAreValid) {
      return this.props.onError({
        name: 'chronoframe.dueDates.formField.dateValidationError.title',
        statusCode: 400,
        message:
          'chronoframe.dueDates.formField.dateValidationError.description',
        errors: {
          dueAtDate:
            'chronoframe.dueDates.formField.dateValidationError.invalidField',
        },
      })
    }
    try {
      this.setState({ isSubmitting: true })
      await this.props.onSubmit()
      if (this.mounted) {
        this.setState({ isSubmitting: false })
      }
    } catch (error) {
      logError(error)
      if (this.mounted) {
        this.setState({ isSubmitting: false })
      }
    }
  }
}

export default Form
