import * as React from 'react'
import { connect } from 'react-redux'
import { itemsFetchAttributes, itemsFetchSingleData } from '../../../../redux/actions'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { History } from 'history'
import { IdNameType, IssueAttachment, IssueJournal, ServiceListItem } from '../../../../types'
import { toDateTime } from '../../../../functions/formatting'
import ImagePreview from '../../widgets/ImagePreview/ImagePreview'
import {Breadcrumbs, Button, Loader, Modal, PageHeader} from '@mv-submodules/inplant-components-fe'
import TimeEntriesDetails from '@mv-submodules/inplant-service-fe/ui/components/views/Details/TimeEntriesDetails'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column"
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface DetailsOwnProps {
  history: History
  match: any
}

export interface DetailsStateProps {
  items: ServiceListItem[]
  hasErrored: boolean
  isLoading: boolean
  competenze: IdNameType[]
  trackers: IdNameType[]
  tipoAzione: any
}

export interface DetalisOwnState {
  zoomImage: null | IssueAttachment
  item: null | ServiceListItem
  isTimeEntriesModalOpen: boolean
}

export interface DetailsDispatchProps {
  fetchAttributes: Function
  fetchSingleData: Function
}

const mapStateToProps = ( state: any ): DetailsStateProps => {
  return {
    items: state.service.items,
    hasErrored: state.service.hasErrored,
    isLoading: state.service.isLoading,
    competenze: state.service.service.competenze,
    trackers: state.service.service.trackers,
    tipoAzione: state.service.service.tipoAzione,
  }
}

const mapDispatchToProps = ( dispatch: Function ): DetailsDispatchProps => {
  return {
    fetchAttributes: () => dispatch(itemsFetchAttributes()),
    fetchSingleData: ( id: number ) => dispatch(itemsFetchSingleData(id)),
  }
}

export type DetailsProps = DetailsStateProps & DetailsDispatchProps & DetailsOwnProps & WithTranslation

const priorityBadges = {
  1: 'light',
  2: 'info',
  3: 'success',
  4: 'warning',
  5: 'danger',
}

const additionalFields: Array<{id: string, formatter?: Function}> = [
  {id: 'created_on',formatter: toDateTime},
  {id: 'due_date', formatter: toDateTime  },
  {id: 'estimated_hours'},
  {id: 'is_private',formatter: (e: boolean) => e && <IconComponent icon={'check'} /> || <IconComponent icon={'times'} />},
  {id: 'total_estimated_hours'},
  {id: 'updated_on',formatter: toDateTime },
  {id: 'watchers', formatter: (ws: Array<{id: number, name: string}>) => ws.map(w => w.name).join(', ')},
  {id: 'spent_hours'}
]

class DetailsPageView extends React.Component<DetailsProps, DetalisOwnState> {
  public downloadLink: any

  constructor( props: DetailsProps ) {
    super(props)

    this.state = {
      zoomImage: null,
      item: null,
      isTimeEntriesModalOpen: false
    }

    this.getCustomFieldValue = this.getCustomFieldValue.bind(this)
    this._handleTimeEntriesModal = this._handleTimeEntriesModal.bind(this)
  }

  public componentDidMount() {
    if (!this.props.trackers || this.props.trackers.length < 1 || this.props.competenze.length < 1) {
      this.props.fetchAttributes()
    }

    const id = parseInt(this.props.match.params.id, 10)
    this.props.fetchSingleData(id).then(( data: any ) => {
      if (data) {
        this.setState({
          item: data.issue,
        })
      }
    })
  }

  public render() {
    const {t, hasErrored, isLoading} = this.props
    const {item, zoomImage} = this.state

    const generateAdditionalFieldsHTML = () => {
      const additionalFieldsHtml: JSX.Element[] = []
      let rowCounter = 0
      let currentRow: Array<string | JSX.Element | null> = []

      additionalFields.forEach(f => {
        if(currentRow && item && item[f.id]) {
          currentRow.push(<React.Fragment key={f.id}><th>{t('service.form.' + f.id)}</th><td>{f.formatter ? f.formatter(item[f.id]) : item[f.id]}</td></React.Fragment>)

          if(rowCounter === 1) {
            additionalFieldsHtml.push(<tr key={'row' + f.id}>{currentRow}</tr>)
            currentRow = []
            rowCounter = 0
          } else {
            rowCounter++
          }
        }
      })

      return additionalFieldsHtml
    }

    const generateCustomFieldsHTML = () => {
      const additionalFieldsHtml: JSX.Element[] = []
      let rowCounter = 0
      let currentRow: Array<string | JSX.Element | null> = []

      if(item && item.custom_fields) {
        item.custom_fields.forEach(f => {
          if (currentRow && f.value && (Array.isArray(f.value) && f.value.length > 0 || !Array.isArray(f.value) && f.value !== '')) {
            const competenceCF = item.custom_fields.find(cf => cf.id === 16)
            const actionTypeCF = item.custom_fields.find(cf => cf.id === 7)
            const competence = competenceCF ? this.props.competenze.find(c => String(c.id) === competenceCF.value as string) : false
            const actionType = actionTypeCF ? (actionTypeCF.value as string[]).map(aType => {
              const tipoAzione = this.props.tipoAzione.find((ta: {id: string, name: string}) => ta.id === aType)
              return tipoAzione ? tipoAzione.name : ''
            }) : false


            currentRow.push(<React.Fragment key={f.id}>
              <th>{t('service.form.custom_fields.' + f.id, {defaultValue: f.name})}</th>
              <td>{
                f.multiple && Array.isArray(f.value) && (f.id !== 7) ?
                  f.value.join(', ') :
                  [5, 8].includes(f.id) ? t('service.form.' + (f.value === '1' ? 'yes' : 'no')): (
                    f.id === 16 && competence ? competence.name : (
                      f.id === 7 && actionType ? actionType.join(', ') : f.value
                    )
                  )
              }</td>
            </React.Fragment>)

            if (rowCounter === 1) {
              additionalFieldsHtml.push(<tr key={'row' + f.id}>{currentRow}</tr>)
              currentRow = []
              rowCounter = 0
            } else {
              rowCounter++
            }
          }
        })
      }

      return additionalFieldsHtml
    }

    if (hasErrored) {
      return (
        <Row>
          <Column lg={12}>
            <div className={"text-center"}>
              <IconComponent icon={'exclamation-triangle'} size="2x" className="text-danger"/>
            </div>
          </Column>
        </Row>
      )
    }
    if (isLoading) {
      return (
        <Row horizontalAlignment={"center"}>
          <Column lg={12}>
            <Loader/>
          </Column>
        </Row>
      )
    }

    return (
      <div className="inplant-service">

        <PageHeader
          title={item && item.subject}
          rightButtons={
            <Button
              key={'openTimeEntriesModal'}
              onClick={() => this._handleTimeEntriesModal(true)}
              variant={'info'}
              label={t('service.details.openTimeEntriesModal')}
            />
          }
        />
        <Breadcrumbs path={[
          {
            label: t('service.index.title'),
            href: '/service/list'
          },
          {
            label: item && item.project.name || '',
          }
        ]}/>

        {
          this.state.item &&
          <TimeEntriesDetails
            visible={this.state.isTimeEntriesModalOpen}
            onClose={() => this._handleTimeEntriesModal(false)}
            id={this.state.item.id}
          />
        }

        <div className="content">
          {item && (
            <React.Fragment>
              <Row horizontalAlignment={"between"} >
                <Column >
                  <table className="table table-sm table-borderless">
                    <tbody>
                    <tr>
                      <th className="w-10">{t('service.form.priority')}</th>
                      <td className="w-40">
                          <span className={'badge badge-' + priorityBadges[item.priority.id]}>
                            {item.priority.name}
                          </span>
                      </td>
                      <th className="w-10">{t('service.form.author')}</th>
                      <td className="w-40">{this.getCustomFieldValue(item, 2)}</td>
                    </tr>
                    <tr>
                      <th>{t('service.form.startDate')}</th>
                      <td>{item.start_date}</td>
                      <th>{t('service.form.assigned_to')}</th>
                      <td>{item.assigned_to && item.assigned_to.name}</td>
                    </tr>

                    <tr>
                      <th>{t('service.form.doneRatio')}</th>
                      <td className="align-items-center d-flex">
                        <div className="progress w-50 float-left mr-2">
                          <div
                            className="progress-bar bg-success"
                            style={{width: item.done_ratio + '%'}}
                          />
                        </div>
                        {item.done_ratio}
                      </td>
                      <th>{t('service.form.status')}</th>
                      <td>{item.status.name}</td>
                    </tr>

                    <tr>
                      <th>{t('service.form.requestType')}</th>
                      <td>{item.tracker.name}</td>
                    </tr>

                    {generateAdditionalFieldsHTML()}
                    {generateCustomFieldsHTML()}

                    <tr className="border-top border-bottom pt-2 pb-5 mt-2">
                      <td colSpan={4}>
                        <h5>{t('service.form.description')}</h5>
                        <div className="item-description">{item.description}</div>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </Column>
              </Row>
              <div className="issue-attachments">
                <Row>
                  <Column>
                    {item.attachments &&
                    item.attachments.map(( attachment: IssueAttachment ) =>
                      /^image\/(png|jpg)$/.test(attachment.content_type) ? (
                        <figure
                          key={attachment.id}
                          data-toggle="modal"
                          data-target="#attachmentModal"
                          onClick={() => this.setState({zoomImage: attachment})}
                          className="m-2 float-left"
                        >
                          <ImagePreview src={attachment.thumbnail_url} class="img-thumbnail"/>
                        </figure>
                      ) : (
                        <a
                          className="btn btn-sm m-2 float-left"
                          key={attachment.id}
                          data-toggle="modal"
                          data-target="#attachmentModal"
                          onClick={() => this.setState({zoomImage: attachment})}
                        >
                          <IconComponent icon={'file'}/> {attachment.filename}
                        </a>
                      )
                    )}
                  </Column>
                </Row>
              </div>
              {item.journals && item.journals.length > 0 && (
                <div className="issue-journal">
                  <Row>
                    <Column>
                      <h3 className="border-top mt-3 pt-3">{t('service.journal.title')}</h3>
                      {item.journals.map(
                        ( journalItem: IssueJournal ) =>
                          journalItem.notes &&
                          journalItem.notes !== '' && (
                            <div key={journalItem.id} className="card issue-journal-item mb-2">
                              <div className="card-header">
                                {journalItem.user.name} @ {toDateTime(journalItem.created_on)}
                              </div>
                              <div className="card-body">{journalItem.notes}</div>
                              {journalItem.details && journalItem.details.length > 0 && (
                                <div className="card-footer">
                                  {journalItem.details.map(
                                    ( detail, index ) =>
                                      detail.property === 'attachment' && (
                                        <span key={index} className="item-attachment-list">
                                        {detail.new_value}
                                      </span>
                                      )
                                  )}
                                </div>
                              )}
                            </div>
                          )
                      )}
                    </Column>
                  </Row>
                </div>
              )}
            </React.Fragment>
          )}

          {
            zoomImage &&
            <Modal
              onClose={() => this.setState({zoomImage: null})}
              closeLabel={t('service.journal.modal.close')}
              visible={true}
              title={zoomImage && zoomImage.description}
              additionalFooterContent={
                <h6>{zoomImage && zoomImage.filename}</h6>
              }
            >
              {(/^image\/(png|jpg)$/.test(zoomImage.content_type) ? (
                <a href={zoomImage.content_url} onClick={this.downloadAttachment}>
                  <ImagePreview src={zoomImage.content_url} class="img-fluid img-thumbnail"/>
                </a>
              ) : (
                <a href={zoomImage.content_url} onClick={this.downloadAttachment}>
                  <Button variant={"primary"} icon={'download'} label={t('service.attachments.preview.download')}/>

                </a>
              ))}
              <a ref={el => (this.downloadLink = el)} href="#" style={{opacity: 0}}/>
            </Modal>
          }
        </div>
      </div>
    )
  }

  private downloadFile( data: any, filename: string, mime: string ) {
    const blob = new Blob([data], {type: mime || 'application/octet-stream'})
    if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
      (window.navigator as any).msSaveBlob(blob, filename)
    } else {
      const blobURL = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      tempLink.setAttribute('download', filename)

      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }

      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }
  }

  private downloadAttachment = ( e: any ) => {
    e.persist()
    e.preventDefault()

    const requestJWTToken = localStorage.getItem('jwt')

    if (requestJWTToken) {
      const url = e.currentTarget.href.replace('http://', 'https://')
      fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'bearer ' + requestJWTToken,
        },
      })
      .then(response => response.blob())
      .then(blob => {
        this.downloadFile(blob, url.split('/').pop(), blob.type)
      })
    }
  }

  private getCustomFieldValue( item: ServiceListItem, id: number ) {
    // custom_field with id === 2 is Contact Person
    const customField = item.custom_fields.find(c => c.id === id)

    if (customField) {
      return customField.value
    }

    return null
  }

  private _handleTimeEntriesModal(isOpen: boolean) {
    this.setState({isTimeEntriesModalOpen: isOpen})
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(DetailsPageView))
)
