import * as React from 'react'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
/*tslint:disable*/
// const DateRangePickerImport = require('react-bootstrap-daterangepicker')
// const DateRangePicker = DateRangePickerImport.default
/*tslint:enable*/
import * as moment from 'moment'
import {bulkActions} from '../../../../redux/actions'
import {
  MaintenanceScheduledJob,
  MaintenanceScheduledJobBulkActionParams,
  MaintenanceBulkAction,
  ModuleConfig,
} from '../../../../types'
import {getDateFormat, getDateLocale, getConfigParam} from '../../../../functions/shared'
import {DateInput, TextArea} from '@mv-submodules/inplant-components-fe'
import {FormModal} from "@mv-submodules/inplant-components-fe/ui/components/Modal/Modal";

export interface StateProps {
  config: ModuleConfig
}

export interface DispatchProps {
  bulkActions: (params: MaintenanceScheduledJobBulkActionParams) => Promise<any>
}

export interface OwnStateProps {
  date: moment.Moment
  notes: string
}

export interface OwnProps {
  isVisible: boolean
  onClose: Function | null
  scheduledJobs: MaintenanceScheduledJob[]
  action: MaintenanceBulkAction
  // t: TranslationFunction
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

export const mapStateToProps = (state: any) => ({
  config: state.config,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  bulkActions: (params: MaintenanceScheduledJobBulkActionParams) => dispatch(bulkActions(params)),
})

class ActionModal extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)
    this.state = this.getInitialState()
    this.onModalClose = this.onModalClose.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  private getInitialState() {
    const today = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0})
    return {
      date: today,
      notes: '',
    }
  }

  public resetState() {
    this.setState(this.getInitialState())
  }

  public onModalClose(reload: boolean = false) {
    this.resetState()
    if (this.props.onClose) {
      this.props.onClose(reload)
    }
  }

  public onConfirm() {
    return
  }

  public async onSubmit(e: any) {
    e.preventDefault()

    const ids = this.props.scheduledJobs.map((item: MaintenanceScheduledJob) => {
      return item.id
    })

    if (ids.length > 0 && this.props.action !== null) {
      const params: MaintenanceScheduledJobBulkActionParams = {action: this.props.action, ids}

      if (this.props.action === 'done' || this.props.action === 'rescheduled') {
        params.date = this.state.date
        params.notes = this.state.notes
      } else if (this.props.action === 'rejected') {
        params.notes = this.state.notes
      }

      try {
        await this.props.bulkActions(params)
        this.onModalClose(true)
      } catch (error:any) {
        console.error(error) // tslint:disable-line
      }
    }
  }

  public handleChange(e: any) {
    const change = {}
    change[e.target.name] = e.target.value
    this.setState(change)
  }

  public render() {
    const today = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0})
    const title =
      this.props.t('maintenance.bulkActions.modal.title') +
      ' - ' +
      this.props.t(`maintenance.bulkActions.actions.${this.props.action}`) +
      ' (' +
      this.props.scheduledJobs.length +
      ')'
    const enableAcknowledgment: boolean = getConfigParam(this.props.config, 'enableAcknowledgment', false)

    moment.locale(getDateLocale())

    const dateEl =
      this.props.action === 'done' || this.props.action === 'rescheduled' ? (
        <div className="form-group">
          <DateInput
            // singleDatePicker={true}
            // locale={{
            //   format: getDateFormat(),
            // }}
            label={this.props.t('maintenance.bulkActions.modal.date')}
            min={this.props.action === 'rescheduled' ? today.toString() : undefined}
            max={this.props.action === 'done' ? today.toString() : undefined}
            // startDate={this.state.date}
            // endDate={this.state.date}
            // onApply={(event: any, date: any) => {
            //   this.setState({ date: date.startDate })
            // }}
            onChange={value => this.setState({date: moment(value)})}
            value={this.state.date.format(getDateFormat())}
            // className="form-control w-100"
            required={true}
            id="date"
          />
        </div>
      ) : null

    const notesEl =
      this.props.action !== 'acknowledged' ? (
        <div className="form-group">
          <TextArea
            label={this.props.t('maintenance.bulkActions.modal.notes')}
            id="rescheduleNotes"
            // className="form-control"
            value={this.state.notes}
            onChange={value => this.setState({notes: value as string})}
            rows={3}
          />
        </div>
      ) : null

    const ackMsgEl =
      enableAcknowledgment && this.props.action === 'acknowledged' ? (
        <h5>{this.props.t('maintenance.bulkActions.modal.acknowledgedMsg')}</h5>
      ) : null

    return (
      <FormModal visible={this.props.isVisible} title={title} onClose={() => this.onModalClose()}
                 onSubmit={this.onSubmit} submitButton={{label: this.props.t('maintenance.bulkActions.modal.confirm')}}>
        {dateEl}
        {notesEl}
        {ackMsgEl}
      </FormModal>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ActionModal))
