import FetchWrapper from '@mv-submodules/inplant-core-fe/functions/fetch-wrapper'

export const API = () => FetchWrapper.getInstance('smartdocs')
export const APIStorage = () => FetchWrapper.getInstance('designerStorage')
export const APIStatic = () => FetchWrapper.getInstance('static')

export { fetchData } from './smartdocs'

export { fetchFileData } from './storage'

export { fetchSpareParts } from './spareParts'
