// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

// * -------------------------------- MODULE --------------------------------------
import InvalidFeedback from './InvalidFeedback'

export interface SelectOptions {
  defaultOption?: string,
  disableDefaultOption?: boolean,
  items: Array<{
    label: string,
    value: string
    disabled?: boolean
  }>
}
interface Props {
  disabled?: boolean
  error?: string
  id?: string
  label?: string
  name?: string
  onChange: (value: string) => void
  options: SelectOptions
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  title?: string
  size?: 'sm' | 'lg'
  value?: string | number
}

const Select = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLSelectElement>) => {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      props.onChange(event.currentTarget.value)
    }
    let className = 'form-control'
    if (props.error != null) {
      className += ' is-invalid'
    }
    if (props.size && ['sm', 'lg'].includes(props.size)) {
      className += ' form-control-' + props.size
    }

    return (
      <div className="form-group">
        {props.label && (
          <label htmlFor={props.id}>
            {props.label}
            {props.required && <abbr className="text-danger ml-1">*</abbr>}
          </label>
        )}
        <select
          className={className}
          disabled={props.disabled || props.readOnly}
          id={props.id}
          name={props.name}
          onChange={handleChange}
          ref={ref}
          required={props.required}
          title={props.title}
          value={props.value || ''}
        >
          {/* {!isSet && (
            <option disabled value="">
              {props.placeholder || '–'}
            </option>
          )} */}

          {(props.options.defaultOption !== undefined) && (
            <option value="" key="-1" disabled={props.options.disableDefaultOption}>
              {props.options.defaultOption}
            </option>
          )}

          {props.options.items.map(option => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}

        </select>
        {props.error && <InvalidFeedback error={props.error} />}
      </div>
    )
  }
)

export default Select
