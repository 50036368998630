import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { MaintenanceScheduledJob, MaintenanceStatus, ModuleConfig } from '../../../../types'
import {
  findNodeByID,
  getConfigParam,
  getDateFormat,
  haveTargetHours,
  oldPresentTarget,
  presentJobType,
  presentStatusBadge,
  // presentTarget,
  presentTargetByPath,
  presentTargetHours,
  showScheduledJobCol,
} from '../../../../functions/shared'
import AcknowledgeComponent from '../AcknowledgeComponent/AcknowledgeComponent'
import ImageComponent from '../ImageComponent/ImageComponent'
import { fetchTargetInfo } from '../../../../redux/actions'
import { fetchPlantStatus } from '@mv-submodules/inplant-maintenance-fe/redux/actions/plant'
import { Card, CardBody, CardHeader, Modal } from '@mv-submodules/inplant-components-fe'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export interface StateProps {
  config: ModuleConfig
  smartdocsExists: boolean
}

export interface OwnStateProps {
  targetData: any
  plantStatus: any
  isFetching: boolean
  fetchErrors: boolean
}

export interface OwnProps extends RouteComponentProps<any> {
  isVisible: boolean
  scheduledJob: MaintenanceScheduledJob | null
  onClose: Function | null
  plantStatus?: any
  // t: TranslationFunction
}

export interface DispatchProps {
  fetchTargetInfo: (targetId: string) => Promise<any>
  fetchPlantStatus: () => Promise<any>
}

export type Props = StateProps & OwnProps & DispatchProps & WithTranslation

export const mapStateToProps = (state: any) => ({
  config: state.config,
  smartdocsExists: state.hasOwnProperty('smartdocs'),
})

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchTargetInfo: (targetId: string) => dispatch(fetchTargetInfo(targetId)),
  fetchPlantStatus: () => dispatch(fetchPlantStatus()),
})

class ScheduledJobModalComponent extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)

    this.state = {
      targetData: null,
      plantStatus: this.props.plantStatus,
      isFetching: false,
      fetchErrors: false,
    }

    this.goToEventDetail = this.goToEventDetail.bind(this)
    this.goToTargetMaintenances = this.goToTargetMaintenances.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.showActions = this.showActions.bind(this)
    this.showCostAndTime = this.showCostAndTime.bind(this)
    this.showRejectedActionTab = this.showRejectedActionTab.bind(this)
  }

  public componentDidMount() {
    if (!this.state.plantStatus) {
      this.setState({ isFetching: true, fetchErrors: false })
      this.props
        .fetchPlantStatus()
        .then((plantStatus: any) => {
          this.setState({ plantStatus })
        })
        .catch((error: any) => {
          this.setState({
            fetchErrors: true,
          })
        })
        .finally(() => {
          this.setState({ isFetching: false })
        })
    }
  }

  public componentWillReceiveProps(nextProps: Readonly<StateProps & OwnProps & DispatchProps>, nextContext: any): void {
    if (
      !nextProps.scheduledJob ||
      !nextProps.scheduledJob.target ||
      !this.props.scheduledJob ||
      !this.props.scheduledJob.target ||
      nextProps.scheduledJob.target.id !== this.props.scheduledJob.target.id
    ) {
      this.setState({
        targetData: null,
      })
    }

    if (this.props.smartdocsExists && nextProps.scheduledJob && nextProps.scheduledJob.target) {
      const id = nextProps.scheduledJob.target.id
      if (id) {
        nextProps
          .fetchTargetInfo(id)
          .then((targetData: any) => {
            this.setState({ targetData })
          })
          .catch((error: any) => {
            this.setState({ targetData: null })
          })
      }
    }
  }

  public onModalClose(reload: boolean = false) {
    if (this.props.onClose) {
      this.props.onClose(reload)
    }
  }

  public showActions(status: string) {
    return status !== MaintenanceStatus.DONE && status !== MaintenanceStatus.REJECTED
  }

  public showRejectedActionTab() {
    return getConfigParam(this.props.config, 'enableScheduledJobRejectAction', false)
  }

  public showCostAndTime() {
    return getConfigParam(this.props.config, 'enableScheduledJobConstAndTime', false)
  }

  public goToEventDetail() {
    this.onModalClose()
    if (this.props.scheduledJob !== null) {
      this.props.history.push('/maintenance/scheduled-job/' + this.props.scheduledJob.id)
    }
  }

  public goToTargetMaintenances(target: string) {
    this.props.history.push(`/maintenance/target/${target}`)
  }

  public render() {
    if (this.props.scheduledJob === null) {
      return null
    }

    const { config } = this.props
    const showTargetNameOnNullTarget: boolean = getConfigParam(config, 'showTargetNameOnNullTarget', false) // const enableAcknowledgment: boolean = getConfigParam(config, 'enableAcknowledgment', false)

    let images
    if (
      showScheduledJobCol(config, 'images') &&
      this.props.scheduledJob.images &&
      this.props.scheduledJob.images.length > 0
    ) {
      images = (
        <Card marginBottom={0}>
          <CardHeader>{this.props.t('maintenance.scheduledJobDetail.images')}</CardHeader>
          <CardBody>
            <Row spacing={{ horizontal: false, vertical: false }}>
              {this.props.scheduledJob.images.map((filePath: string, index: number) => (
                <div key={index} className="col-6 col-sm-4 col-md-3 col-xl-2 mb-4">
                  <ImageComponent imagePath={filePath} />
                </div>
              ))}
            </Row>
          </CardBody>
        </Card>
      )
    }

    const nodeDetails =
      this.state.plantStatus && this.props.scheduledJob.component
        ? findNodeByID(this.props.scheduledJob.component.id, this.state.plantStatus)
        : null

    return (
      <Modal
        visible={this.props.isVisible}
        onClose={() => this.onModalClose()}
        title={this.props.t('maintenance.actionScheduledJob.interventionOverview')}
        width={50}
        additionalFooterButtons={[
          {
            variant: 'primary',
            onClick: () => this.goToEventDetail(),
            label: this.props.t('maintenance.actionScheduledJob.details'),
          },
        ]}
        closeLabel={this.props.t('maintenance.close')}
      >
        <table className="table table-sm table-borderless">
          <tbody>
            {(showScheduledJobCol(config, 'target') && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.target')}</th>
                <td>
                  {this.state.targetData ? (
                    <React.Fragment>
                      {presentTargetByPath(this.state.targetData, this.props.scheduledJob, this.goToTargetMaintenances)}{' '}
                      {this.props.smartdocsExists && this.props.scheduledJob && this.props.scheduledJob.target ? (
                        <a
                          className="text-primary data-tooltips"
                          data-title={this.props.t('maintenance.target.smartdocsLink')}
                          href={'/smartdocs?id=' + this.props.scheduledJob.target.id.split('/').pop()}
                        >
                          {' '}
                          <sup>
                            <IconComponent icon={'external-link-alt'} />
                          </sup>
                        </a>
                      ) : null}
                    </React.Fragment>
                  ) : (
                    oldPresentTarget(this.props.scheduledJob, this.goToTargetMaintenances, showTargetNameOnNullTarget)
                  )}
                </td>
              </tr>
            )) ||
              null}
            {(nodeDetails && nodeDetails.data && showScheduledJobCol(config, 'pid') && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.pid')}</th>
                <td>{nodeDetails && nodeDetails.data && nodeDetails.data.pid}</td>
              </tr>
            )) ||
              null}
            {(showScheduledJobCol(config, 'jobType') && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.jobType')}</th>
                <td>{presentJobType(this.props.scheduledJob)}</td>
              </tr>
            )) ||
              null}
            {(haveTargetHours(this.props.scheduledJob) && (
              <tr>
                <th className="th-label">
                  {this.props.t('maintenance.actionScheduledJob.hours')}
                  <small>{this.props.t('maintenance.actionScheduledJob.sinceLastMaintenance')}</small>
                </th>
                <td>{presentTargetHours(this.props.scheduledJob)}</td>
              </tr>
            )) ||
              null}
            {showScheduledJobCol(config, 'operation') && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.operation')}</th>
                <td>{this.props.scheduledJob.operation}</td>
              </tr>
            )}

            {(showScheduledJobCol(config, 'type') && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.type')}</th>
                <td>{this.props.scheduledJob.type.name}</td>
              </tr>
            )) ||
              null}
            {(showScheduledJobCol(config, 'userFullName') && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.operatorName')}</th>
                <td>{this.props.scheduledJob.userFullName}</td>
              </tr>
            )) ||
              null}
            {(showScheduledJobCol(config, 'dueDate') && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.dueDate')}</th>
                <td>{this.props.scheduledJob.dueDate.format(getDateFormat())}</td>
              </tr>
            )) ||
              null}
            {(showScheduledJobCol(config, 'plannedDate') && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.plannedDate')}</th>
                <td>{this.props.scheduledJob.plannedDate.format(getDateFormat())}</td>
              </tr>
            )) ||
              null}
            {showScheduledJobCol(config, 'status') && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.status')}</th>
                <td>{presentStatusBadge(this.props.scheduledJob)}</td>
              </tr>
            )}
            {(showScheduledJobCol(config, 'notes') && this.props.scheduledJob.notes && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.notes')}</th>
                <td>{this.props.scheduledJob.notes}</td>
              </tr>
            )) ||
              null}

            {showScheduledJobCol(config, 'acknowledged') && this.props.scheduledJob && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.acknowledged')}</th>
                <td>
                  <AcknowledgeComponent scheduledJob={this.props.scheduledJob} />
                </td>
              </tr>
            )}
            {(this.props.scheduledJob.maintenance && this.props.scheduledJob.maintenance.currentHours && (
              <tr>
                <th className="th-label">{this.props.t('maintenance.actionScheduledJob.currentHours')}</th>
                <td>{this.props.scheduledJob.maintenance.currentHours}</td>
              </tr>
            )) ||
              null}
          </tbody>
        </table>
        {images}
      </Modal>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ScheduledJobModalComponent)))
