import * as React from 'react'
import { connect } from 'react-redux'
import { changeRange } from '../actions/datefilters'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment-timezone'
/*tslint:disable*/
const DateRangePickerImport = require('react-bootstrap-daterangepicker')
const DateRangePicker = DateRangePickerImport.default
/*tslint:enable*/
import 'bootstrap-daterangepicker/daterangepicker.css'
import { Button } from '@mv-submodules/inplant-components-fe';

export interface MomentRange {
  [k: string]: moment.Moment[]
}

interface DateBaseFiltersStateProps {
  range: string
  dateStart: string | null
  dateEnd: string | null
}

interface DateBaseFiltersDispatchProps {
  changeRangeAction: (range: string, dateStart: string, dateEnd: string) => any
}

type DateBaseFiltersProps = DateBaseFiltersStateProps & DateBaseFiltersDispatchProps & WithTranslation

const mapStateToProps = (state: any): DateBaseFiltersStateProps => ({
  range: state.dateFilters.range,
  dateStart: state.dateFilters.dateStart,
  dateEnd: state.dateFilters.dateEnd,
})

const mapDispatchToProps = (dispatch: any): DateBaseFiltersDispatchProps => {
  return {
    changeRangeAction: (range: string, dateStart: string, dateEnd: string) =>
      dispatch(changeRange(range, dateStart, dateEnd)),
  }
}

class DateRangeFilters extends React.Component<DateBaseFiltersProps> {
  constructor(props: DateBaseFiltersProps) {
    super(props)

    this.handleOnChange = this.handleOnChange.bind(this)
  }

  public handleOnChange(
    e: React.FormEvent<HTMLSelectElement>,
    data: { chosenLabel: string; startDate: moment.Moment; endDate: moment.Moment },
  ) {
    const rangeReverseKeys = {
      [this.props.t('dateRange7Days')]: 'dateRange7Days',
      [this.props.t('dateRangeLastWeek')]: 'dateRange7Days',
      [this.props.t('dateRange30Days')]: 'dateRange7Days',
      [this.props.t('dateRangeLastMonth')]: 'dateRange7Days',
      [this.props.t('dateRangeLast6Month')]: 'dateRange7Days',
      [this.props.t('dateRangeLastYear')]: 'dateRange7Days',
      [this.props.t('dateRangeYear')]: 'dateRange7Days',
    }

    const { chosenLabel, startDate, endDate } = data
    this.props.changeRangeAction(rangeReverseKeys.hasOwnProperty(chosenLabel) ? rangeReverseKeys[chosenLabel] : chosenLabel, startDate.toISOString(), endDate.toISOString())
  }

  public render() {
    const { t } = this.props

    const ranges: MomentRange = {
      [t('dateRange7Days')]: [
        moment()
          .subtract(7, 'day')
          .startOf('day'),
        moment()
          .subtract(1, 'day')
          .endOf('day'),
      ],
      [t('dateRangeLastWeek')]: [
        moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .startOf('day'),
        moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .endOf('day'),
      ],
      [t('dateRange30Days')]: [moment().subtract(31, 'days'), moment().subtract(1, 'days')],
      [t('dateRangeLastMonth')]: [
        moment()
          .subtract(1, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month'),
      ],
      [t('dateRangeLast6Month')]: [
        moment()
          .subtract(6, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'day')
          .endOf('month'),
      ],
      [t('dateRangeLastYear')]: [
        moment()
          .subtract(1, 'year')
          .startOf('year'),
        moment()
          .subtract(1, 'year')
          .endOf('year'),
      ],
      [t('dateRangeYear')]: [
        moment()
          .startOf('year')
          .startOf('month')
          .startOf('day'),
        moment()
          .subtract(1, 'days')
          .endOf('month'),
      ],
    }

    return (
      <div className="DateFilters DateBaseFilters select-range col-lg-3 col-md-3 ml-auto mr-0 text-right pr-3">
        <DateRangePicker
          timePicker={false}
          timePicker24Hour={false}
          locale={{
            format: 'DD/MM/YYYY',
          }}
          maxDate={moment()}
          onApply={this.handleOnChange}
          showDropdowns={true}
          alwaysShowCalendars={true}
          ranges={ranges}
          opens={'left'}
        >
          <Button
            variant="secondary-alternate"
            label={
              (
                !this.props.dateStart && !this.props.dateEnd && this.props.t('dateRangeFiler.pleaseSelect') || ''
              ) +
              (this.props.dateStart && moment(this.props.dateStart).format('YYYY-MM-DD') || '') +
              ' - ' +
              (
                this.props.dateEnd && moment(this.props.dateEnd).format('YYYY-MM-DD') || ''
              )
            }
            icon={"calendar"}
          />
        </DateRangePicker>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DateRangeFilters))
